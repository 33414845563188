import React from 'react';
import styled from '@emotion/styled';
import { Headline, TextFormatted, LinkButton, Icons } from 'imdshared';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 320px;
  margin-top: 32px;
  margin-bottom: 104px;

  @media (min-width: 600px) {
    max-width: 360px;
  }

  ${Headline} {
    margin: 32px 0 24px 0;
  }
`;

const Image = styled.img`
  width: 256px;
  height: 256px;
  user-select: none;
  pointer-events: none;
`;

const DescriptionTextFormatted = styled(TextFormatted)`
  margin-bottom: 24px;
`;

const ChildrenWrapper = styled.div`
  width: 256px;

  > * {
    width: 100%;
  }

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const BadCaseTemplate = ({
  title,
  description,
  imageSrc,
  children,
}: {
  title: string;
  description: string;
  imageSrc: string;
  children?: React.ReactNode;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Image src={imageSrc} />

        <Headline>{title}</Headline>

        <DescriptionTextFormatted text={description} />

        <ChildrenWrapper>
          <LinkButton
            to="/"
            text={t('start-using-imusician')}
            icon={Icons.categories.dashboard}
            primary={true}
          />

          {children}
        </ChildrenWrapper>
      </Wrapper>
    </Container>
  );
};

export default BadCaseTemplate;
