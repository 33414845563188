import React from 'react';
import styled from '@emotion/styled';
import { IconButton, LinkButton, Icons } from 'imdshared';
import type { ReduxState } from 'imddata';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  z-index: 1000000;
  position: fixed;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 24px;
  background-color: #ccccccaa;
  border-radius: 0 0 4px 4px;
  transform: translate(-50%, -80%);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  animation: jump 0.2s ease-in;

  @keyframes jump {
    from {
      transform: translate(-50%, 0);
    }
    to {
      transform: translate(-50%, -80%);
    }
  }

  :hover {
    transform: translate(-50%, 0);
  }
`;

type Props = {
  id: string;
  email?: string;
  onClickClose: () => void;
};

const ImpersonationNotice = ({ id, email, onClickClose }: Props) => {
  const devmode = useSelector((state: ReduxState) => state.dev.devmode);
  return (
    <Wrapper>
      {devmode && <LinkButton to="/devdebug" text="dev" />}
      {email ? 'Impersonation ENABLED' : 'USE ADMIN CREDENTIALS'}:{' '}
      {email || `(${id})`}
      <IconButton onClick={onClickClose} icon={Icons.actions.close} />
    </Wrapper>
  );
};

export default ImpersonationNotice;
