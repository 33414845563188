import React from 'react';
import styled from '@emotion/styled';
import { Clickable, Content, Caption } from 'imdui';

interface UserListItemProps {
  testId?: string;
  title: string;
  subtitle: string;
  subscription?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Wrapper = styled(Clickable)`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: flex-start;
  align-items: center;
  padding: 8px 24px;
  border-radius: 0;
  cursor: pointer;
  box-shadow: inset 0 -1px 0 0 #e1e1e1;

  > ${Content}, > ${Caption} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default function UserListItem({
  testId,
  title,
  subtitle,
  onClick,
  disabled,
  subscription,
  style,
}: UserListItemProps): JSX.Element {
  return (
    <Wrapper
      testId={testId}
      onClick={onClick}
      className={disabled ? 'disabled' : undefined}
      style={style}
    >
      <Content>{title}</Content>
      <span />
      <Caption>{subtitle}</Caption>
      <Caption>{subscription}</Caption>
    </Wrapper>
  );
}
