import type { WrappedFieldArrayProps } from 'redux-form';
import { Field, FieldArray } from 'redux-form';
import type { FormValues } from './types';
import { actions as uiActions } from 'imddata/actionTypes/ui/selection';
import { Item, ItemCollection, EnabledRowField } from './components';
import { useTranslation } from 'react-i18next';
import { Body, LinkButton } from 'imdui';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

const NoReleasesWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 24px;
  align-items: center;
  flex-direction: column;
`;

const ReleaseCollection = ({
  artistId,
  fields,
}: { artistId: number } & WrappedFieldArrayProps<
  FormValues['releases'][number]
>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  if (!fields.length) {
    return (
      <NoReleasesWrapper>
        <Body>{t('no-published-releases-in-library')}</Body>
        <LinkButton
          size="small"
          position="center"
          to="/order/release/new"
          text={t('create-new-release')}
          onClick={() => {
            dispatch(
              uiActions.updateSelection({ entity: 'artists', selected: [] })
            );
          }}
          state={{
            artistIds: [artistId],
          }}
        />
      </NoReleasesWrapper>
    );
  }
  return (
    <ItemCollection>
      {fields.map((entry, index) => {
        const v = fields.get(index);
        return (
          <Item key={index}>
            <Field
              name={`${entry}.enabled`}
              title={v.title}
              component={EnabledRowField}
            />
          </Item>
        );
      })}
    </ItemCollection>
  );
};
export function ArtistPageReleasesForm({ id }: { id: number }) {
  return (
    <>
      <FieldArray name="releases" component={ReleaseCollection} artistId={id} />
    </>
  );
}
