import React, { useContext, Component } from 'react';
import { find } from 'ramda';
import { Form, Field, reduxForm } from 'redux-form';
import {
  createInputWithStyles,
  Button,
  HelperText,
  Icons,
  TextFormatted,
} from 'imdui';
import { Translation } from 'react-i18next';
import styled from '@emotion/styled';
import { useQuery, useCreateEntity, usePayoutRequest } from 'imddata';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ImpersonateContext } from '../../logic';
import { NewInputField } from '../../fields';
import ButtonDropdown from '../ButtonDropdown';

const StyledMaskedInput = createInputWithStyles(MaskedInput);

const localNumberMask = createNumberMask({
  prefix: '~ ',
  allowDecimal: true,
  includeThousandsSeparator: false,
});

const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: false,
});

const renderEuro = (props) => (
  <StyledMaskedInput {...props} guide={false} mask={numberMask} />
);
const renderLocal = (props) => (
  <StyledMaskedInput {...props} guide={false} mask={localNumberMask} />
);
const AlignWithdraw = styled.div`
  grid-column: span 2;
  display: flex;
`;

const normalizeAmount = (value) =>
  value?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

const getValue = ({ id }) => id;

const mapAddress = (methods, t) => (address) => {
  const { wasConfirmed, label, payoutMethodId } = address;
  const method = methods[payoutMethodId];
  const enoughForPayment = !method || !method.enoughForPayment;
  const disabled = !enoughForPayment || !wasConfirmed;
  const fee = method && method.feeFormatted;
  return {
    ...address,
    label: `${wasConfirmed ? '' : `(${t('not-confirmed')}) `}${label}, ${t(
      'fee'
    )}: ${fee}`,
    disabled,
  };
};

const InputsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content max-content;
  gap: 16px;
  & > * {
    min-width: 0%;
    margin-bottom: 0 !important;
  }
`;

const Wrapper = styled.div``;

const RequestForm = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
})(
  ({
    disabled,
    onChangeAddress,
    addresses,
    hasPayoutAddress,
    currencyRate,
    localCurrency,
    // TODO: figure out Fee for
    // fee,
    change,
    loading,
    initialValues,
    payoutAddressId,
    handleSubmit,
  }) => (
    <Translation>
      {(t) => (
        <Form onSubmit={handleSubmit}>
          <InputsRow>
            <div>
              <Field
                normalize={(value) => {
                  const formatted = normalizeAmount(value);
                  const initial = initialValues.amount;
                  if (!formatted) return 0;
                  if (parseFloat(formatted) > parseFloat(initial)) {
                    return initial;
                  }
                  return formatted;
                }}
                props={{
                  disabled: disabled || loading || !hasPayoutAddress,
                  label: 'EUR',
                  acceptValidation: false,
                  renderInput: renderEuro,
                }}
                onChange={(event, value) => {
                  const parsedValue = (
                    Number.parseFloat(value) * currencyRate
                  ).toFixed(2);
                  const finalValue =
                    parseFloat(parsedValue) > parseFloat(initialValues.amount)
                      ? initialValues.amountLocal
                      : parsedValue;
                  change('amountLocal', finalValue);
                }}
                component={NewInputField}
                name="amount"
              />
            </div>
            {currencyRate !== false && (
              <div>
                <Field
                  props={{
                    disabled: disabled || loading || !hasPayoutAddress,
                    label: localCurrency.toUpperCase(),
                    acceptValidation: false,
                    renderInput: renderLocal,
                  }}
                  parse={(value) => value.substring(2)}
                  component={NewInputField}
                  onChange={(event, value) => {
                    const parsedValue = (
                      Number.parseFloat(value) / currencyRate
                    ).toFixed(2);
                    const finalValue =
                      parseFloat(parsedValue) > parseFloat(initialValues.amount)
                        ? initialValues.amount
                        : parsedValue;
                    change('amount', finalValue);
                  }}
                  name="amountLocal"
                />
              </div>
            )}

            <ButtonDropdown
              style={{ gridColumn: 'span 2' }}
              disabled={disabled}
              onChange={onChangeAddress}
              getValue={getValue}
              data={addresses}
              value={payoutAddressId}
              label={t('select-address')}
            />
            <AlignWithdraw>
              <Button
                disabled={disabled || !hasPayoutAddress || loading}
                primary={true}
                icon={Icons.actions.withdraw}
                type="submit"
                text={t('withdraw')}
              />
            </AlignWithdraw>
            {!hasPayoutAddress && (
              <AlignWithdraw>
                <HelperText
                  text={
                    <TextFormatted
                      text={t('add-bank-or-paypal-information-on-billing-page')}
                    />
                  }
                />
              </AlignWithdraw>
            )}
          </InputsRow>
        </Form>
      )}
    </Translation>
  )
);

class PayoutAddressSelector extends Component {
  constructor(props) {
    super(props);

    const { payoutAddresses } = props;
    const payoutAddress = payoutAddresses
      ? find((address) => address && address.wasConfirmed, payoutAddresses)
      : null;
    this.state = {
      payoutAddressId: payoutAddress && payoutAddress.id,
    };
  }

  render() {
    const { children, methods, payoutAddresses } = this.props;
    const { payoutAddressId } = this.state;

    const payoutAddress = payoutAddresses
      ? find((address) => address.id === payoutAddressId, payoutAddresses)
      : null;

    const payoutMethodId = payoutAddress && payoutAddress.payoutMethodId;
    const method = methods?.[payoutMethodId];
    const fee = method && method.feeFormatted;

    return (
      <Translation>
        {(t) => (
          <div>
            {children({
              addresses: payoutAddresses?.map(mapAddress(methods, t)),
              payoutAddressId,
              onChangeAddress: (address) => {
                this.setState({ payoutAddressId: address });
              },
              fee,
              payoutAddress,
              payoutMethodId,
            })}
          </div>
        )}
      </Translation>
    );
  }
}

const PayoutForm = ({
  className,
  style,
  disabled,
  formId = 'requestBankPayment',
  componentId,
}) => {
  const impersonate = useContext(ImpersonateContext);
  const { query, queryHash } = useQuery({ query: impersonate });
  const {
    entry: data,
    request: { loading },
  } = usePayoutRequest({ query, queryHash, requestStoreKey: componentId });

  const { createEntry } = useCreateEntity({ entity: 'payoutRequest' });

  const {
    availableCashCredit,
    localizedAvailableCashCredit,
    localizedCashCreditExchangeRate,
    localizedCashCreditCurrency,
    cashCreditCurrency,
    payoutAddresses,
  } = data || {};
  const currencyRate =
    cashCreditCurrency === localizedCashCreditCurrency
      ? false
      : localizedCashCreditExchangeRate;
  return (
    <Wrapper className={className} style={style}>
      <PayoutAddressSelector
        methods={data?.methods}
        payoutAddresses={payoutAddresses}
      >
        {({
          fee,
          addresses,
          onChangeAddress,
          payoutAddressId,
          payoutAddress,
          payoutMethodId,
        }) => (
          <RequestForm
            disabled={disabled}
            addresses={addresses}
            onChangeAddress={onChangeAddress}
            hasPayoutAddress={!!payoutAddress}
            payoutAddressId={payoutAddressId}
            fee={fee}
            loading={loading}
            form={formId}
            localCurrency={localizedCashCreditCurrency}
            currencyRate={currencyRate}
            onSubmit={(values) => {
              createEntry(
                {
                  formId,
                  componentKey: componentId,
                  data: {
                    amount: values.amount,
                    payoutMethodId,
                    payoutAddressId: payoutAddress && payoutAddress.id,
                  },
                },
                { impersonate }
              );
            }}
            initialValues={{
              amount:
                Number(availableCashCredit) < 0
                  ? 0
                  : normalizeAmount(availableCashCredit || '0'),
              amountLocal:
                Number(availableCashCredit) < 0
                  ? 0
                  : normalizeAmount(localizedAvailableCashCredit || '0'),
            }}
          />
        )}
      </PayoutAddressSelector>
    </Wrapper>
  );
};

export default PayoutForm;
