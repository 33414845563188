import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { gridGapsCss } from 'imdui';
import styled from '@emotion/styled';
import { InvoiceList, Invoice } from './screens';
import { FillProfileActionCard, ReturnNavigation } from 'components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${gridGapsCss}
`;

const Invoices = ({ match }: RouteComponentProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('page-title-invoices')}</title>
      </Helmet>

      <Route path={`${match.url}/:id`}>
        {({ match: routeMatch }: RouteComponentProps<{ id: string }>) =>
          routeMatch ? (
            <ReturnNavigation
              text={
                routeMatch
                  ? `${t('invoice')} #${routeMatch.params.id}`
                  : t('invoice')
              }
              to={match.url}
            />
          ) : null
        }
      </Route>

      <Grid>
        <FillProfileActionCard
          text={t('alert-fill-profile-for-invoice-download')}
        />

        <Switch>
          <Route exact={true} path={match.url} component={InvoiceList} />
          <Route path={`${match.url}/:id`} component={Invoice} />
        </Switch>
      </Grid>
    </div>
  );
};

export default Invoices;
