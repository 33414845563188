//
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Body } from 'imdshared';

const ItemTitleWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;

  *:first-child {
    margin-bottom: 4px;
  }

  @media (min-width: 600px) {
    flex-direction: row;

    *:first-child {
      margin-bottom: 0px;
    }
  }
`;

const CurrencyWrapper = styled.div`
  flex: 1;
  text-align: right;
`;

const CalculatingPlaceholder = styled.div`
  flex: 1;
  display: flex;

  *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ItemRowWrapper = styled.div`
  display: flex;
  padding: 8px 24px;
`;

const ItemRow = ({
  loading,
  description,
  label,
  value,
}: {
  loading?: boolean;
  description?: string;
  label: string;
  value: string;
}) => {
  const { t } = useTranslation();

  return (
    <ItemRowWrapper>
      <ItemTitleWrapper>
        <Body
          css={css`
            flex: 1;
          `}
        >
          {label}
        </Body>

        <Body
          css={css`
            flex: 1;
            width: auto;
            text-align: left;
            font-size: 12px;
            line-height: 16px;

            @media (min-width: 600px) {
              font-size: 16px;
              line-height: 20px;
            }
          `}
        >
          {description || '-'}
        </Body>
      </ItemTitleWrapper>

      {!loading ? (
        <CurrencyWrapper>{value}</CurrencyWrapper>
      ) : (
        <CalculatingPlaceholder>
          <Body>{t('calculating')}</Body>
        </CalculatingPlaceholder>
      )}
    </ItemRowWrapper>
  );
};

export default ItemRow;
