import { useState, useCallback, useEffect } from 'react';

export default (isOpen) => {
  const [isDrawerOpen, setDrawerOpen] = useState(() => isOpen);
  const [isHidden, hideNavDrawer] = useState(false);
  // Add effect to close on breakpoints

  const openNavDrawer = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setDrawerOpen(true);
    },
    [isDrawerOpen]
  );
  const closeNavDrawer = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setDrawerOpen(false);
    },
    [isDrawerOpen]
  );

  useEffect(() => {
    setDrawerOpen(isOpen);
  }, [isOpen]);

  return {
    hideNavDrawer,
    isDrawerOpen: !isHidden && isDrawerOpen,
    openNavDrawer,
    closeNavDrawer,
  };
};
