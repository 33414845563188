// @ts-nocheck
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  LinkButton,
  Button,
  LoadingIndicator,
  BundleUpdateFeed,
  Centered,
  LayoutContext,
  CenterWrapper,
} from 'imdshared';
import { BodySmall, Icon, IconsCollection } from 'imdui';
import { useCreateOrder, useOverview, useEntries } from 'imddata';
import { PageTemplate } from 'components';
import ProductCollapsible from './components/ProductCollapsible';
import RevertAllButton from './components/RevertAllButton';

const Wrapper = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    fill: ${({ theme }): string => theme.darks[4]};
  }

  span {
    margin-top: 16px;
    color: ${({ theme }): string => theme.darks[4]};
  }
`;

function ListEmptyNotice({
  text,
  className,
  style,
}: {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element {
  return (
    <Wrapper className={className} style={style}>
      <Icon d={IconsCollection.empty} size={32} />

      {text && <BodySmall>{text}</BodySmall>}
    </Wrapper>
  );
}

const UPDATE_OVERVIEW_KEY = 'UPDATE_OVERVIEW';

const PageWrapper = styled.div`
  padding-bottom: 104px;
`;

const Toolbar = styled(CenterWrapper)<{
  left: number;
  bottomPadding: number;
}>`
  position: fixed;
  width: auto;
  display: flex;
  background-color: #fafafa;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.06);
  left: ${({ left }): number => left}px;
  right: 0;
  bottom: 0;

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;
    padding-bottom: ${({ bottomPadding }): number => bottomPadding + 12}px;
  }
`;

const StyledOrderButton = styled(Button)`
  margin-left: 16px;
`;

const LoadingIndicatorWrapper = styled(Centered)`
  margin-top: 16px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DeliveryUpdateContent: React.FC = () => {
  const { t } = useTranslation();

  const {
    loadMore,
    entries,
    request: { loading, errorData },
  } = useEntries({ entity: 'deliveryBundleUpdates' });

  const { overview, refresh } = useOverview({
    entity: 'deliveryBundleUpdateOverview',
    componentKey: UPDATE_OVERVIEW_KEY,
  });

  useEffect(() => {
    loadMore();
    refresh();
  }, []);

  const {
    createOrder,
    request: { creating },
  } = useCreateOrder({
    entity: 'deliveryBundleUpdates',
  });

  if (loading) {
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator size="large" />
      </LoadingIndicatorWrapper>
    );
  }

  if (errorData && errorData.orderId) {
    return (
      <ErrorWrapper>
        <ListEmptyNotice text={t('please-finish-ordering-confirmed-updates')} />
        <LinkButton to="/order/overview" text={t('finish-order')} />
      </ErrorWrapper>
    );
  }

  if (!entries.length) {
    return <ListEmptyNotice text={t('you-have-no-pending-updates')} />;
  }

  return (
    <PageWrapper>
      <div>
        {entries.map((updateEntry) => {
          const priceObj =
            (overview &&
              overview.groups.bundleUpdate &&
              overview.groups.bundleUpdate.items.find(
                (item) =>
                  item?.data?.deliveryBundleId === updateEntry.deliveryBundleId
              )) ||
            {};

          const { releases, tracks } = updateEntry;

          const IS_CID = releases.length === 0;
          const title = IS_CID ? tracks[0].title : releases[0].title;

          return (
            <ProductCollapsible
              key={updateEntry.deliveryBundleId}
              type={IS_CID ? 'youtube-content-id' : 'music-distribution'}
              title={title}
              price={priceObj.price}
            >
              <BundleUpdateFeed changes={updateEntry.changes} />
            </ProductCollapsible>
          );
        })}
      </div>

      <LayoutContext.Consumer>
        {({ safeConstraints: { left, bottom } }): JSX.Element => (
          <Toolbar left={left} bottomPadding={bottom}>
            <RevertAllButton />

            <StyledOrderButton
              text={t('confirm')}
              primary={true}
              onClick={(): void => {
                createOrder();
              }}
              disabled={creating}
              showLoading={creating}
            />
          </Toolbar>
        )}
      </LayoutContext.Consumer>
    </PageWrapper>
  );
};

const DeliveryUpdateWrapper: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageTemplate
      title={t('update-delivery-order')}
      description={t('update-delivery-order-description')}
    >
      <DeliveryUpdateContent />
    </PageTemplate>
  );
};

export default DeliveryUpdateWrapper;
