import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteList } from 'components';
import type { EntityModels } from 'imddata';
import { useInvoices } from 'imddata';
import type { InfiniteListRowRenderer } from 'imdshared';
import { Card, DownloadInvoiceButton, Icons } from 'imdshared';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import { PayInvoiceButton } from '../../shared';
import { AccountNavListItem } from '../../../../shared';

const InvoiceListItem: InfiniteListRowRenderer<EntityModels.Invoice> = ({
  index,
  item,
  item: { id, file, createdAt, totalValueFormatted, statusText },
  style,
}) => {
  const { t } = useTranslation();
  return (
    <AccountNavListItem
      // TODO: delete once NavListItem is typed
      // @ts-ignore
      testId={`Invoice-${index}`}
      to={`/account/invoices/${id}`}
      title={`${t('invoice')} #${id}`}
      subtitle={file?.size ? prettyBytes(file.size) : undefined}
      price={totalValueFormatted}
      status={statusText}
      createdAt={moment(createdAt).format('lll')}
      style={style}
    >
      <PayInvoiceButton invoice={item} />
      {/* TODO: delete once DownloadInvoiceButton is typed
     // @ts-ignore */}
      <DownloadInvoiceButton id={item.id} />
    </AccountNavListItem>
  );
};

const rowRenderer: InfiniteListRowRenderer<EntityModels.Invoice> = (props) => (
  <InvoiceListItem {...props} />
);

const InvoicesList = () => {
  const { t } = useTranslation();
  const {
    entries,
    loadMore,
    request: { loading, hasMorePages },
  } = useInvoices();
  return (
    <Card>
      <InfiniteList
        data={entries}
        hasNextPage={hasMorePages}
        loadNextPage={loadMore}
        loading={loading}
        rowRenderer={rowRenderer}
        emptyPlaceholderIcon={Icons.categories.invoice}
        emptyPlaceholder={t('no-invoices')}
        getRowHeight={() => 80}
      />
    </Card>
  );
};

export default InvoicesList;
