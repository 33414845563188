import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  LinkButton,
  Button,
  CenterWrapper,
  Icon,
  IconsCollection,
  H1,
  TextFormatted,
  Card,
} from 'imdui';

type Props = {
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
};

const wrapperStyle = css`
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const cardStyle = css`
  padding: 24px;

  @media (min-width: 728px) {
    padding: 40px 80px;
  }
`;

const contentStyle = css`
  display: flex;
  flex-direction: column;

  > svg {
    margin-right: 64px;
    flex-shrink: 0;
  }
`;

const titleStyle = css`
  margin: 40px 0 24px;
`;

const buttonRowStyle = css`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  > button:not(:last-child) {
    margin-right: 16px;
  }
`;

const FURIntroPage: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <CenterWrapper css={wrapperStyle}>
      <Card css={cardStyle}>
        <div css={contentStyle}>
          <Icon d={IconsCollection.receiveFeedbackBig} size={128} />

          <div>
            <H1 css={titleStyle}>{t('field-update-requests')}</H1>
            <TextFormatted text={t('fur-intro-description')} />
          </div>
        </div>

        <div css={buttonRowStyle}>
          <LinkButton
            external={true}
            text={t('open-guidelines')}
            to={t('open-guidelines-url')}
          />
          <Button primary={true} text={t('got-it')} onClick={onClose} />
        </div>
      </Card>
    </CenterWrapper>
  );
};

export default FURIntroPage;
