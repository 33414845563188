import React from 'react';
import styled from '@emotion/styled';
import type { EntityModels } from 'imddata';
import { DevComponent } from 'components';
import { gridGapsCss } from 'imdui';
import {
  TotalOverview,
  Transactions,
  ReferralRewards,
  Link,
  Splits,
} from './components';

const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${gridGapsCss}

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

type Props = {
  loadNextTransactions: () => void;
  transactions: Array<EntityModels.Transaction>;
  hasMoreTransactions: boolean;
  loadingTransactions: boolean;
  testId?: string;
  translationPrefix: string;
  tokenUsedCount: number;
  totalRevenue: React.ReactNode;
  referralSignupReward?: string;
  referralReward?: string;
  linkReady?: boolean;
  link: string;
  split?: {
    bonus: number;
    imdShare: number;
    loyalty: number;
  };
};

export const AffiliateReferralTemplate: React.FC<Props> = ({
  linkReady = true,
  split,
  referralReward,
  referralSignupReward,
  transactions,
  loadingTransactions,
  hasMoreTransactions,
  translationPrefix,
  tokenUsedCount,
  totalRevenue,
  loadNextTransactions,
  link,
  testId,
}) => {
  return (
    <LayoutGrid data-test-id={testId}>
      <TotalOverview
        translationPrefix={translationPrefix}
        tokenUsedCount={tokenUsedCount}
        revenue={totalRevenue}
      />

      <Link
        translationPrefix={translationPrefix}
        link={link}
        linkReady={linkReady}
      />

      <DevComponent>
        <Transactions
          loadMore={loadNextTransactions}
          data={transactions}
          translationPrefix={translationPrefix}
          loading={loadingTransactions}
          hasMorePages={hasMoreTransactions}
        />
      </DevComponent>
      {referralSignupReward || referralReward ? (
        <ReferralRewards
          referralReward={referralReward}
          referralSignupReward={referralSignupReward}
        />
      ) : null}
      {split && <Splits translationPrefix={translationPrefix} {...split} />}
    </LayoutGrid>
  );
};
