import React, { useCallback, useState, memo } from 'react';
import styled from '@emotion/styled';
import { AdaptiveSelectSideWindow, SideWindow } from 'imdshared';
import { DropdownScrollProvider } from 'imdui';
import { SideEditorHeader } from '../../atoms';

const SideEditorContainerStyled = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const SideEditorContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [wrapper, setWrapper] = useState(null);
  const setWrapperRef = useCallback((node) => {
    if (node !== null) {
      setWrapper(node);
    }
  }, []);
  return (
    <DropdownScrollProvider value={wrapper}>
      <SideEditorContainerStyled ref={setWrapperRef}>
        {children}
      </SideEditorContainerStyled>
    </DropdownScrollProvider>
  );
};

const StyledSideWindow = styled(SideWindow)`
  display: flex;
  flex-direction: column;
  background: var(--surface-container-medium, #fffcf9);

  /* Shadow/Modal */
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.06);
`;

type Props = {
  form: string;
  label: string;
  actionsTabLabel?: 'actions' | 'edit';
  isSelectionActive: boolean;
  isAdaptive?: boolean;
  onClearSelection: () => void;
  children?: React.ReactNode;
  // @deprecated
  components?: {
    Edit: any;
  };
};

const SideEditor = ({
  label,
  isSelectionActive,
  isAdaptive,
  onClearSelection,
  children,
  components: { Edit } = { Edit: undefined },
}: Props) => {
  return (
    <AdaptiveSelectSideWindow
      label={label}
      isAdaptive={isAdaptive}
      isSelectionActive={isSelectionActive}
      onClearSelection={onClearSelection}
    >
      {({ isOpen, close }) => (
        <StyledSideWindow testId="TrackSideEditor" isOpen={isOpen}>
          <SideEditorHeader label={label} onClickClose={close} />

          <SideEditorContainer>{Edit || children}</SideEditorContainer>
        </StyledSideWindow>
      )}
    </AdaptiveSelectSideWindow>
  );
};

export default memo(SideEditor);
