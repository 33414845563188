//
import React, { memo, useContext } from 'react';
import styled from '@emotion/styled';
import { LayoutContext } from '../ScreenLayout/contexts';

const HeaderBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 32px;
`;

const FooterWrapper = styled.div`
  box-shadow: ${({ isLayoutCollapsed }) =>
    isLayoutCollapsed ? 'inset 0 1px rgba(0, 0, 0, 0.1)' : 'none'};
`;

const NavContainer = styled.nav`
  background: ${({ isLayoutCollapsed }) =>
    isLayoutCollapsed ? '#fff' : 'none'};

  display: flex;
  flex-direction: column;
  width: 216px;
  height: 100%;
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 40px;
`;

const NavSidebar = ({ children, components: { NavHeader, NavFooter } }) => {
  const { isLayoutCollapsed } = useContext(LayoutContext);

  return (
    <NavContainer isLayoutCollapsed={isLayoutCollapsed}>
      <HeaderBar>
        <NavHeader />
      </HeaderBar>

      <ChildrenWrapper>{children}</ChildrenWrapper>

      <FooterWrapper>
        <NavFooter />
      </FooterWrapper>
    </NavContainer>
  );
};

export default memo(NavSidebar);
