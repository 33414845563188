import type { WrappedFieldProps } from 'redux-form';
import { ArtistAvatar } from '@imus/base-ui';
import type { UploadableHandlers } from 'imddata';
import { ImageField } from './ImageField';
import { useTranslation } from 'react-i18next';

const imageComponent = ({ url }: { url?: string }) => (
  <ArtistAvatar src={url} />
);

export const AvatarField = (
  props: WrappedFieldProps & {
    handlerId: number | string;
    handler: UploadableHandlers;
  }
) => {
  const { t } = useTranslation();
  return (
    <ImageField
      {...props}
      style={{ borderRadius: '50%', width: '80px', height: '80px' }}
      imageComponent={imageComponent}
      actionText={t('set-profile-image')}
    />
  );
};
