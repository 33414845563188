import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { H3 } from '../../text';
import { Card } from '../base';
import { FieldGroup } from '../field';

export const AnimatedDim = styled(motion.div)`
  z-index: 103;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a7a7a780;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const WindowCard = styled(Card.withComponent(motion.div))`
  width: 100%;
  max-width: 576px;
  max-height: 90%;
  margin: 24px;
  cursor: default;
  overflow: auto;
  ${FieldGroup} {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
  }
  margin-bottom: 32px;
`;

const headerHeight = 96;

export const WindowHeader = styled.div`
  z-index: 1;
  position: sticky;
  flex-direction: row-reverse;
  top: 0;
  display: flex;
  align-items: center;
  height: ${headerHeight}px;
  padding: 0px 32px 0 32px;

  > ${H3} {
    font-weight: 700 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const WindowChildren = styled.div<{ hideTitleBar: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ hideTitleBar }) =>
    !hideTitleBar ? `calc(100% - ${headerHeight}px)` : '100%'};
`;
