import { useOrdersOverview } from 'imddata';

type Props = {
  children: (unreadCount: number) => JSX.Element | null;
};
const OrdersCountProvider = ({ children }: Props) => {
  const {
    overview,
    request: { failed },
  } = useOrdersOverview({
    componentKey: 'OrdersCountProvider',
  });
  const ordersCount =
    overview && !failed ? Object.keys(overview.groups).length : 0;
  return children(ordersCount);
};

export default OrdersCountProvider;
