//
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TrackNested } from 'imddata';
import { useEntitySales } from 'imddata';
import { Content, getTrackIsrc } from 'imdshared';

import { ReportingListItem } from '../../shared';

const RevenueComponent = ({ cellData }: { cellData: string }) => (
  <Content>{cellData}</Content>
);

export default function TrackSalesRow({
  track,
  queryHash,
}: {
  track: TrackNested;
  queryHash?: string;
}) {
  const { t } = useTranslation();

  const fields = useMemo(
    () => [
      {
        dataKey: 'label',
        label: '',
      },
      {
        dataKey: 'trackStreams',
        textAlign: 'right',
        label: t('streams'),
      },
      {
        dataKey: 'trackDownloads',
        textAlign: 'right',
        label: t('downloads'),
      },
      {
        dataKey: 'monetizations',
        textAlign: 'right',
        label: t('youtube-content-id'),
      },
      {
        dataKey: 'totalRevenue',
        textAlign: 'right',
        label: t('total'),
        cellRenderer: RevenueComponent,
      },
    ],
    [t]
  );

  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
  });

  const itemProps = useMemo(
    () => ({
      title: track?.title || '',
      rightText: getTrackIsrc(track) || '',
    }),
    [track]
  );
  const sales = useEntitySales({ entity: 'tracks', id: track.id, queryHash });

  const items = useMemo(() => {
    if (!sales?.summary) return [];
    const {
      trackStreams,
      trackStreamsRevenue,
      trackDownloads,
      trackDownloadsRevenue,
      ringtoneDownloads,
      ringtoneDownloadsRevenue,
      monetizations,
      monetizationsRevenue,
      viewsRevenue,
      views,
      totalRevenue,
      totalRevenueCurrency,
    } = sales.summary;
    return [
      {
        id: 'quantity',
        label: t('quantity'),
        trackStreams: trackStreams + views,
        trackDownloads: trackDownloads + ringtoneDownloads,
        monetizations,
      },
      {
        id: 'revenue',
        label: `${t('revenue')} (${totalRevenueCurrency})`,
        trackStreams: formatter.format(
          Number(trackStreamsRevenue) + Number(viewsRevenue)
        ),
        trackDownloads: formatter.format(
          Number(trackDownloadsRevenue) + Number(ringtoneDownloadsRevenue)
        ),
        monetizations: monetizationsRevenue,
        totalRevenue,
      },
    ];
  }, [sales]);

  const tableProps = useMemo(() => ({ fields, items }), [fields, items]);

  return <ReportingListItem itemProps={itemProps} tableProps={tableProps} />;
}
