import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import type { RouteComponentProps } from 'react-router-dom';
import { useParams, useHistory, Route, Switch } from 'react-router-dom';
import { PageNavigation } from 'imdshared';
import { ErrorBoundaryRoute, PageTemplate } from 'components';
import { useCurrentCustomer } from 'imddata';
import {
  Downloads,
  PaymentMethods,
  Profile,
  Invoices,
  CustomerProfileEdit,
  Affiliate,
  Referral,
  AccountDelete,
} from './screens';

const routes = [
  {
    testId: 'NavItem-Subscriptions',
    to: '',
    exact: true,
    text: 'profile',
  },
  {
    to: 'invoices',
    text: 'invoices',
    testId: 'NavItem-Invoices',
  },
  {
    testId: 'NavItem-Downloads',
    to: 'downloads',
    text: 'downloads',
  },
];

const RedirectToRevenue = () => {
  const { id, token } = useParams<any>();
  const history = useHistory();
  useEffect(() => {
    history.push(`/revenue/billing/${id}/${token}`);
  }, []);

  return null;
};

const RedirectToBilling = () => {
  const { id } = useParams<any>();
  const history = useHistory();
  useEffect(() => {
    history.push(`/revenue/billing/${id}`);
  }, []);

  return null;
};

type Props = RouteComponentProps;

const Toolbar = ({ match }: { match: Props['match'] }) => {
  const { entry: customer } = useCurrentCustomer();
  const fullRoutes = useMemo(() => {
    return [
      ...routes,
      ...(customer?.isAffiliatePartner
        ? [
            {
              to: `affiliate`,
              text: 'affiliate-dashboard',
            },
          ]
        : []),
      ...(customer?.referralToken && customer?.referralReward
        ? [
            {
              testId: 'NavItem-Affiliate',
              to: `referral`,
              text: 'referral-dashboard',
            },
          ]
        : []),
    ];
  }, [
    customer?.referralToken,
    customer?.referralReward,
    customer?.isAffiliatePartner,
  ]);

  return <PageNavigation match={match} routes={fullRoutes} />;
};

const Account: React.FC<Props> = ({ match }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page-title-account')}</title>
      </Helmet>

      <PageTemplate title={t('account')}>
        <Toolbar match={match} />
        <Switch>
          <Route exact={true} path={`${match.url}`} component={Profile} />

          <ErrorBoundaryRoute
            path={`${match.url}/downloads`}
            component={Downloads}
          />
          <ErrorBoundaryRoute
            path={`${match.url}/profile/delete`}
            component={AccountDelete}
          />
          <ErrorBoundaryRoute
            path={`${match.url}/profile`}
            component={CustomerProfileEdit}
          />
          <ErrorBoundaryRoute
            path={`${match.url}/payment-methods`}
            component={PaymentMethods}
          />

          <ErrorBoundaryRoute
            path={`${match.url}/invoices`}
            component={Invoices}
          />

          <ErrorBoundaryRoute
            path={`${match.url}/subscriptions`}
            component={Profile}
          />

          <ErrorBoundaryRoute
            path={`${match.url}/affiliate`}
            component={Affiliate}
          />
          <ErrorBoundaryRoute
            path={`${match.url}/referral`}
            component={Referral}
          />
          <Route
            exact={true}
            path={`${match.url}/billing/:id/:token`}
            component={RedirectToRevenue}
          />
          <Route
            exact={true}
            path={`${match.url}/billing/:id`}
            component={RedirectToBilling}
          />
        </Switch>
      </PageTemplate>
    </>
  );
};

export default Account;
