//
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import constants from '../constants';
import { createJoinTagField } from '../../helpers';
import ArtistJoin, { makeData } from '../ArtistJoin';

const TrackArtistJoin = ({ ...props }) => {
  const { t } = useTranslation();
  const finalData = useMemo(() => {
    return makeData(constants.artists.trackRoles, t);
  }, []);
  return <ArtistJoin data={finalData} {...props} />;
};

export const TrackArtistJoinField = createJoinTagField(
  TrackArtistJoin,
  'artists'
);

export default TrackArtistJoin;
