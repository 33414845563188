import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import {
  useFetchEntity,
  useInvoice,
  useEntryProvider,
  useCreateEntity,
  useOverview,
  useEntries,
} from 'imddata';
import { Button, Caption, Headline, OptionTabs } from 'imdui';
import { PaymentCard, columnStyle, detailsStyle } from '../../../shared';
import type { PaymentMethod, PaymentData } from './types';
import { PaymentMethodContainer } from './PaymentMethodContainer';
import { PaymentProcessor } from './PaymentProcessor';
import PayCancelButtons from './PayCancelButtons';
import PaymentOverviewDetails from './PaymentOverviewDetails';
import config from 'imdconfig';
import { postPayment } from './postPayment';
import type { OverviewGroupItem, SubscriptionId } from 'imddata/types/entities';
import { getBaseSubscriptionId } from 'components';

const TermsCaption = styled(Caption)`
  text-align: center;
  display: block;
  width: 100%;

  margin: 32px 0 0 0;
`;

const SelectPaymentButton = styled(Button)`
  width: 100%;
`;

const OrderHeadline = styled(Headline)`
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
`;

const OptionTabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
`;

const Container = styled.div`
  ${columnStyle}
`;

export type SuccessHandler = (id: number) => void;

export type ErrorHandler = (
  type: 'unpayable' | 'failed_payment',
  id?: number,
  message?: string
) => void;

const defaultMakeReturnUrl = (id: number) =>
  config.imdfront.appUrl + '/order/complete?invoiceId=' + id;

const useMakePayment = (
  ids: number[] | undefined,
  entity: 'invoices' | 'orders',
  onComplete: SuccessHandler,
  onError: ErrorHandler,
  makeReturnUrl: (id: number) => string = defaultMakeReturnUrl
) => {
  const sessionId = useRef(Date.now());

  const [paymentInitData, setPaymentInitData] = useState<Record<string, any>>();
  const [invoiceId, setInvoiceId] = useState<number>();
  const { reload: reloadPaymentMethods } = useFetchEntity({
    entity: 'customerPaymentMethods',
  });

  const {
    createEntry: createInvoice,
    request: invoiceRequest,
    createdId: createdInvoiceId,
  } = useCreateEntity({
    entity: 'invoices',
    query: { with: 'items' },
  });

  useEffect(() => {
    if (entity === 'invoices' && ids) {
      setInvoiceId(ids[0]);
      return;
    }
    if (createdInvoiceId) {
      setInvoiceId(createdInvoiceId as number);
    }
  }, [createdInvoiceId, ids]);

  const {
    createEntry: makePayment,
    request,
    createdId,
  } = useCreateEntity({
    entity: 'invoicePayments',
    componentKey: `InvoicePayment-${invoiceId}-${sessionId.current}`,
  });

  // TODO: define result as an object return somehow and avoid using createdId field for this
  // @ts-ignore
  const transactionId = createdId?.transaction;

  const transaction = useEntryProvider<EntityModels.Transaction>({
    entity: 'transactions',
    id: transactionId,
  });

  const { entry: invoice } = useInvoice({
    id: invoiceId,
    passive: !invoiceId,
  });

  useEffect(() => {
    if (transaction) {
      const { redirectUrl, redirectMethod, redirectData } = transaction;
      if (redirectUrl) {
        if (redirectMethod.toUpperCase() === 'POST') {
          postPayment(redirectUrl, redirectData);
          return;
        }
        window.location.href = redirectUrl;
        return;
      }
      if (
        transaction.status === 'authorization_failed' ||
        transaction.status === 'settlement_failed' ||
        transaction.status === 'failed'
      ) {
        onError('failed_payment', invoiceId, transaction.statusText);
        return;
      }
    }

    if (invoice && invoiceId) {
      if (invoice.status === 'paid' || invoice.status === 'finalized') {
        reloadPaymentMethods();
        onComplete(invoiceId);
      }
    }
  }, [transaction, invoice]);

  const onPayment = useCallback(
    (data = {}) => {
      setPaymentInitData({ ...data });
    },
    [makePayment, invoiceId]
  );
  useEffect(() => {
    if (entity === 'orders' && !invoiceId && paymentInitData) {
      createInvoice({
        data: {
          orderIds: ids,
        },
      });
    }
    if (invoiceId && paymentInitData) {
      makePayment({
        id: invoiceId,
        data: {
          ...paymentInitData,
          returnUrl: makeReturnUrl(invoiceId),
        },
      });
    }
  }, [paymentInitData, invoiceId, ids]);

  return {
    onPayment,
    request,
    paying: request.creating || invoiceRequest.creating,
  };
};

const emptyTabs: Array<{
  label: string;
  value: PaymentMethod;
  testId?: string;
}> = [];

type PaymentOption = {
  label: string;
  value: PaymentMethod;
  testId?: string;
};

export const PaymentFlow = ({
  id,
  entity,
  onComplete,
  onError,
  makeReturnUrl,
  loading,
  terms,
  action,
}: {
  loading?: boolean;
  id?: number;
  terms?: React.ReactNode;
  action?: React.ReactNode;
  entity: 'invoices' | 'orders';
  onComplete: SuccessHandler;
  onError: ErrorHandler;
  makeReturnUrl?: (id: number) => string;
}) => {
  const attempt = useMemo(
    () => Date.now() + (loading ? '-passive' : '-real'),
    [loading]
  );
  const { t } = useTranslation();

  const overviewEntity =
    entity === 'invoices' ? 'invoicesOverview' : 'ordersOverview';

  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const [confirmedPaymentMethod, setConfirmedPaymentMethod] =
    useState<null | PaymentMethod>(null);
  const [focusedPaymentMethod, setFocusedPaymentMethod] =
    useState<PaymentMethod>();

  const { entries: customerPaymentMethodsUnordered, reload } =
    useEntries<EntityModels.CustomerPaymentMethod>({
      entity: 'customerPaymentMethods',
    });
  useEffect(() => {
    reload();
  }, []);

  const customerPaymentMethods = customerPaymentMethodsUnordered?.sort(
    (a, b) =>
      new Date(b.lastManualUseAt).getTime() -
      new Date(a.lastManualUseAt).getTime()
  );

  const defaultMethod = customerPaymentMethods?.[0] || null;

  const [paymentData, setPaymentData] = useState<PaymentData>({
    paymentMethod: 'card',
    id: null,
    isReady: false,
  });

  const overviewData = useMemo(() => {
    return {
      ...(entity === 'orders' && id ? { orderIds: [id] } : {}),
      ...(confirmedPaymentMethod
        ? { paymentMethodId: confirmedPaymentMethod }
        : {}),
    };
  }, [confirmedPaymentMethod, entity, id]);

  const orderData = useMemo(
    () => ({
      ...(entity === 'orders' && id ? { orderIds: [id] } : {}),
    }),
    []
  );
  const { overview: paymentOverview } = useOverview({
    entity: overviewEntity,
    id: id,
    data: overviewData,
    componentKey: `${id}-${confirmedPaymentMethod}-${attempt}`,
  });

  const { request: baseOverviewRequest, overview: baseOverview } = useOverview({
    entity: overviewEntity,
    id: id,
    data: orderData,
    componentKey: `${id}-${attempt}`,
  });

  const [paymentOptions, setPaymentOptions] = useState<Array<PaymentOption>>(
    () => emptyTabs
  );

  useEffect(() => {
    if (!baseOverview || paymentOptions.length > 0 || loading) return;
    const tabs: Array<{
      label: string;
      value: PaymentMethod;
      testId?: string;
    }> = [];

    if (baseOverview && !baseOverview.requiresExternalTransactions) {
      setPaymentOptions([{ value: 'credit', label: t('credit') }]);
      setConfirmedPaymentMethod('credit');
      return;
    }
    if (baseOverview.availablePaymentMethods.card) {
      tabs.push({ value: 'card', label: t('credit-card') });
    }
    if (baseOverview.availablePaymentMethods.paypal) {
      tabs.push({
        value: 'paypal',
        label: t('paypal'),
        testId: 'PaymentGate-Paypal',
      });
    }
    if (!tabs.length) {
      return;
    }
    if (defaultMethod) {
      setConfirmedPaymentMethod(defaultMethod.paymentMethodId);
      setPaymentData({
        id: defaultMethod.id,
        paymentMethod: defaultMethod.paymentMethodId,
        isReady: true,
        data: {
          customerPaymentMethodId: defaultMethod.id,
        },
      });
    }
    setFocusedPaymentMethod(tabs[0].value);
    setPaymentOptions(tabs);
  }, [baseOverview, paymentOptions, loading]);

  useEffect(() => {
    if (
      !confirmedPaymentMethod &&
      defaultMethod &&
      paymentOptions?.length &&
      paymentOptions.find((po) => po.value === defaultMethod.paymentMethodId)
    ) {
      setConfirmedPaymentMethod(defaultMethod.paymentMethodId);
      setPaymentData({
        id: defaultMethod.id,
        paymentMethod: defaultMethod.paymentMethodId,
        isReady: true,
        data: {
          customerPaymentMethodId: defaultMethod.id,
        },
      });
    }
  }, [defaultMethod, paymentOptions]);

  const onSelectionEnable = useCallback(() => {
    setSelectionEnabled(true);
    setConfirmedPaymentMethod(null);
    // setPaymentData({ isReady: false, id: null });
  }, []);

  const canChangePaymentMethod = paymentOptions.length > 1;

  useEffect(() => {
    if (
      baseOverviewRequest.failed &&
      !baseOverviewRequest.loading &&
      !loading
    ) {
      onError('unpayable', id, entity);
      return;
    }

    if (baseOverview && !baseOverview.canBePaid && !loading) {
      onError('unpayable', id, entity);
    }
  }, [baseOverview, loading]);

  const ids = useMemo(() => {
    if (!baseOverview) return undefined;

    return entity === 'orders' && !id
      ? baseOverview.includedOrders
      : id
        ? [id]
        : undefined;
  }, [entity, baseOverview?.includedOrders, id]);

  const orderedItems = Object.values(baseOverview?.groups || []).reduce<
    OverviewGroupItem[]
  >((acc, g) => [...acc, ...g.items], []);

  const { onPayment, paying } = useMakePayment(
    ids,
    entity,
    onComplete,
    onError,
    makeReturnUrl
  );

  const disabledPayment = paying || !confirmedPaymentMethod || !paymentOverview;

  const subItem = orderedItems.find(
    (oi) => oi.type.toLowerCase() === 'subscription'
  );

  return (
    <>
      <OrderHeadline>
        {subItem
          ? t('subscribe-to', {
              subscription: t(
                getBaseSubscriptionId(subItem.id as SubscriptionId)
              ),
              defaultValue: 'Subscribe to {{subscription}}',
            })
          : t('checkout')}
      </OrderHeadline>
      <PaymentCard>
        <Container>
          {!confirmedPaymentMethod && !selectionEnabled ? (
            <PaymentMethodContainer
              selectionEnabled={false}
              confirmedPaymentMethod="card"
              id="card"
            >
              <SelectPaymentButton
                size="small"
                testId={canChangePaymentMethod ? 'SelectPaymentButton' : ''}
                disabled={!canChangePaymentMethod}
                text={canChangePaymentMethod ? t('select-payment-method') : ''}
                showLoading={!canChangePaymentMethod || !baseOverview}
                onClick={onSelectionEnable}
              />
            </PaymentMethodContainer>
          ) : null}

          {paymentOptions.map((pm) => {
            const { fee, data } = baseOverview?.availablePaymentMethods?.[
              pm.value
            ] || { fee: 0, data: {} };
            return (
              <PaymentMethodContainer
                id={pm.value}
                selectionEnabled={selectionEnabled}
                focusedPaymentMethod={focusedPaymentMethod}
                confirmedPaymentMethod={confirmedPaymentMethod}
                key={pm.value}
              >
                <div>
                  {selectionEnabled && (
                    <OptionTabsContainer>
                      <OptionTabs<PaymentOption>
                        value={focusedPaymentMethod}
                        onChange={setFocusedPaymentMethod}
                        options={paymentOptions}
                      />
                    </OptionTabsContainer>
                  )}
                  <PaymentProcessor
                    currencyId={baseOverview?.currency?.id}
                    customerPaymentMethods={customerPaymentMethods}
                    paymentMethodData={{
                      fee,
                      data: {
                        ...data,
                        enforceStorePaymentMethod:
                          baseOverview?.storedPaymentMethodRequired,
                      },
                    }}
                    paymentMethod={pm.value}
                    paymentData={paymentData}
                    selectionEnabled={selectionEnabled}
                    onSelect={(selectedPm, pd) => {
                      setPaymentData(pd);
                      setConfirmedPaymentMethod(selectedPm);
                      setSelectionEnabled(false);
                    }}
                  />
                </div>
                {canChangePaymentMethod && confirmedPaymentMethod ? (
                  <div>
                    <Button
                      size="small"
                      text={t('change')}
                      onClick={onSelectionEnable}
                    />
                  </div>
                ) : null}
              </PaymentMethodContainer>
            );
          })}

          {!selectionEnabled && !loading ? (
            <>
              {paymentOverview && (
                <div css={detailsStyle}>
                  <PaymentOverviewDetails
                    paymentMethod={confirmedPaymentMethod}
                    overview={paymentOverview}
                  />
                  {action}
                </div>
              )}

              {confirmedPaymentMethod ? (
                <>
                  <PayCancelButtons
                    testId={`PayButton-${
                      disabledPayment ? 'disabled' : 'enabled'
                    }`}
                    position="center"
                    style={{ width: '100%', maxWidth: '380px' }}
                    type="button"
                    primary="black"
                    showLoading={!paymentOverview}
                    label={t('pay')}
                    onClick={() => onPayment(paymentData?.data)}
                    disabled={disabledPayment}
                  />

                  {terms && <TermsCaption>{terms}</TermsCaption>}
                </>
              ) : null}
            </>
          ) : null}
        </Container>
      </PaymentCard>
    </>
  );
};
