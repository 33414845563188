import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ReleaseListItemContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 64px;
  border-radius: 6px;
  align-items: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const checkboxStyle = css`
  margin-right: 8px;
`;

export const loadingArtworkStyle = css`
  width: 48px;
  height: 48px;
  margin-right: 20px;
`;

export const textRowWrapper = css`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;

  > *:first-child {
    flex: 2.5;
  }

  > *:not(:first-child) {
    flex: 1;
    display: none;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (min-width: 640px) {
    > *:nth-child(2) {
      display: block;
    }
  }

  @media (min-width: 1280px) {
    > *:nth-child(3) {
      display: block;
    }
  }
`;

export const titleWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 2px;
  }
`;
