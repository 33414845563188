import { createContext } from 'react';
import { useFieldUpdateRequestDeliveryBundleChanges } from '../hooks';

type AdminFieldUpdateRequestContextType =
  | Record<string, never>
  | ReturnType<typeof useFieldUpdateRequestDeliveryBundleChanges>;

export const FieldUpdateRequestContext =
  createContext<AdminFieldUpdateRequestContextType>({});

FieldUpdateRequestContext.displayName = 'FieldUpdateRequestContext';
