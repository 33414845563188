//
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useBundleShops } from 'imddata';
import { ArtistSideEditor, ReleaseEditor } from 'components';
import {
  ExtendedFieldsColumn,
  LabelRegistrationShopContext,
  validation,
} from 'imdshared';

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
  position: relative;
  transition: background-color 0.1s;
`;

const emptyShops: never[] = [];

export default function ReleaseDetails({
  releaseId,
  bundleId,
  form,
}: {
  form: string;
  releaseId: number | string;
  bundleId: number | string;
}) {
  const shops = useBundleShops({ passive: true, id: bundleId });
  const shopIds = useMemo(
    () => shops?.map((s) => s.shopId) || emptyShops,
    [shops]
  );
  const displayPromotionalText = !!shops?.find((s) => s.shopId === 'beatport');
  return (
    <>
      <div>
        <Wrapper>
          <LabelRegistrationShopContext.Provider value={shopIds}>
            <ReleaseEditor
              showCoverRequirementText={true}
              fieldsComponent={
                <ExtendedFieldsColumn
                  enableArtistLimit={true}
                  displayPromotionalText={displayPromotionalText}
                />
              }
              requiredFields={
                validation.musicDistribution.releaseForm.requiredFields
              }
              key={releaseId}
              form={form}
              releaseId={releaseId}
            />
          </LabelRegistrationShopContext.Provider>
        </Wrapper>
      </div>
      <ArtistSideEditor isAdaptive={false} />
    </>
  );
}
