import React from 'react';
import type { EntityModels } from 'imddata';
import NotificationCard from '../NotificationCard';

type NotificationRendererProps = {
  entry: EntityModels.UserNotification;
  isExpanded?: boolean;
  onClick?: (
    e: React.MouseEvent,
    d: { id: string | number; hasDetailView: boolean }
  ) => void;
};

const renderNotification = ({
  entry,
  isExpanded,
  onClick,
}: NotificationRendererProps) => {
  const { notification, id, wasRead } = entry;

  return (
    <NotificationCard
      id={id}
      data={notification.data}
      body={notification.body}
      title={notification.title}
      date={notification.createdAt}
      isImportant={notification.isImportant}
      isPinned={notification.isPinned}
      wasRead={wasRead}
      type={notification.type}
      isExpanded={isExpanded}
      onClick={onClick}
    />
  );
};

export default renderNotification;
