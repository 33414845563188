//
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const NavItemWrap = styled.div<{ selected: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${({ selected }) =>
    selected ? ' var(--state-active-on-surface)' : 'transparent'};
  position: relative;
  border-radius: 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 8px 12px;
  &:hover {
    background: var(--state-hover-on-surface);
  }
`;
export const NavLabel = styled.div`
  boverflow: hidden;
  display: flex;
  flex: 1;
  color: var(--on-surface, #191a19);
  align-items: center;
  justify-content: space-between;
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  text-overflow: ellipsis;

  /* Component/Nav/Nav Label */
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.36px;
`;

export const NavItem = ({
  selected,
  onClick,
  icon,
  text,
  disabled,
  className,
  testId,
}: {
  disabled?: boolean;
  selected: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  text: React.ReactNode;
  className?: string;
  testId?: string;
}) => {
  return (
    <NavItemWrap
      selected={!!selected}
      data-test-id={testId}
      onClick={onClick}
      className={className + (disabled ? ' disabled' : '')}
    >
      {icon ? <Icon>{icon}</Icon> : null}

      <NavLabel>{text}</NavLabel>
    </NavItemWrap>
  );
};

const NavItemLink = ({
  to,
  icon,
  text,
  className,
  exact,
  strict,
  matchPath,
  testId,
}: {
  matchPath?: string;
  testId?: string;
  className?: string;
  text: string;
  icon: React.ReactNode;
  location?: Location;
  exact?: boolean;
  strict?: boolean;
  to: string;
}) => {
  const history = useHistory();

  return (
    <Route path={matchPath || to} exact={exact} strict={strict}>
      {({ match }: RouteComponentProps) => (
        <NavItem
          selected={!!match}
          testId={testId}
          icon={icon}
          text={text}
          onClick={() => {
            history.push(to);
          }}
          className={className}
        />
      )}
    </Route>
  );
};

export default NavItemLink;
