//
import React from 'react';

type Context = {
  onUpdateCard: any;
  onUpdateCardFilter: any;
  onCreateCard: any;
  onDeleteCard: any;
  data: any;
  position: number;
  id: string | undefined;
  status?: string;
};

const CardContext = React.createContext<Context>({
  onUpdateCard: () => {
    throw new Error('No card context provided');
  },
  onUpdateCardFilter: () => {
    throw new Error('No card context provided');
  },
  onCreateCard: () => {
    throw new Error('No card context provided');
  },
  onDeleteCard: () => {
    throw new Error('No card context provided');
  },
  data: {
    displayedColumns: {},
  },
  position: -1,
  id: undefined,
  status: undefined,
});

CardContext.displayName = 'CardContext';

export default CardContext;
