//
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AlertNotificationCard, LinkButton, Block, Icons } from 'imdshared';

export default function BlockedCustomer() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('page-title-blocked-customer')}</title>
      </Helmet>
      <Block padding={[0, 20, 20, 20]}>
        <Block>
          <AlertNotificationCard
            title={t('blocked-customer-title')}
            text={t('blocked-customer-text')}
          />
          <Block margin={[0, 0, 0, 0]}>
            <Block padding={[40, 0, 0, 0]}>
              <LinkButton
                to="/signout"
                text={t('sign-out')}
                icon={Icons.actions.signout}
              />
            </Block>
          </Block>
        </Block>
      </Block>
    </>
  );
}
