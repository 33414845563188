//
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { AppContext, LoadingIndicator, Centered } from 'imdshared';
import { ReduxState } from 'imddata';

function ProtectedRoute({ render, component, ...rest }: RouteProps) {
  const app = useContext(AppContext);
  const loggedUser = useSelector((state: ReduxState) => {
    return state.auth;
  });
  const isAdmin = loggedUser.data?.isAdmin;

  const isBlockedCustomer = useSelector((state: ReduxState) => {
    const { entities, auth } = state;
    const customerId = auth.data ? auth.data.customerId : undefined;
    const customer =
      customerId !== undefined ? entities.customers.entities[customerId] : null;
    return customer?.isBlocked;
  });

  const isBlocked = isBlockedCustomer && !isAdmin;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loggedUser.isRequested) {
          return (
            <Centered>
              <LoadingIndicator size="large" />
            </Centered>
          );
        }

        if (app === 'admin' && !isAdmin && loggedUser.data?.id) {
          return (
            <Redirect
              to={{
                pathname: '/signout',
              }}
            />
          );
        }

        if (
          loggedUser.data &&
          isBlocked &&
          props.location.pathname !== '/signout' &&
          props.location.pathname !== '/blocked'
        ) {
          return (
            <Redirect
              to={{
                pathname: '/blocked',
              }}
            />
          );
        }

        if (loggedUser.data && render) {
          return render(props);
        }
        if (loggedUser.data && component) {
          return React.createElement(component, props);
        }

        return (
          <Redirect
            to={{
              pathname:
                props.location.pathname === '/' && app === 'front'
                  ? '/signup'
                  : '/signin',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

export default ProtectedRoute;
