import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import entitiesActionTypes from '../../../actionTypes/entities';
import * as actions from '../../../actionTypes/request';
import { EntityIdentifier } from '../../../types';

type ActionCreator<Func extends (...args: any[]) => any> = (
  prop: Parameters<Func>[1],
  meta?: Parameters<Func>[2]
) => void;

type ActionCreators = {
  load: ActionCreator<typeof actions.loadEntity>;
  updateLocalEntry: (props: any, meta: any) => void; // TODO add definition
  update: ActionCreator<typeof actions.updateEntity>;
  create: ActionCreator<typeof actions.createEntity>;
  remove: ActionCreator<typeof actions.deleteEntity>;
  refresh: ActionCreator<typeof actions.refreshEntity>;
};

const isStandartEntity = (
  key: string
): key is keyof typeof entitiesActionTypes => {
  if (key in entitiesActionTypes) return true;
  return false;
};

export function useEntityActions(entity: EntityIdentifier) {
  const dispatch = useDispatch();
  const actionCreators = useMemo<ActionCreators>(
    () => ({
      updateLocalEntry:
        isStandartEntity(entity) &&
        entitiesActionTypes[entity].actions.updateLocalEntry
          ? (props, meta) =>
              dispatch(
                entitiesActionTypes[entity].actions.updateLocalEntry(
                  props,
                  meta
                )
              )
          : () => {
              console.error(`${entity}doesnt support updating locally`);
            },
      load: (props, meta) => dispatch(actions.loadEntity(entity, props, meta)),
      update: (props, meta) =>
        dispatch(actions.updateEntity(entity, props, meta)),
      create: (props, meta) =>
        dispatch(actions.createEntity(entity, props, meta)),
      remove: (props, meta) =>
        dispatch(actions.deleteEntity(entity, props, meta)),
      refresh: (props) => dispatch(actions.refreshEntity(entity, props)),
    }),
    [entity]
  );
  return actionCreators;
}
