import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 200%;
  height: 100%;
`;

export const DetailsWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export const Details = styled.div`
  flex: 1;
`;
