import { useCreateEntity, useCustomerFeature } from 'imddata';
import { BuyOrSubOffer, ConfirmationWindow, InputField } from 'imdshared';
import { TextFormatted } from 'imdui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CustomLinkWindowForm = ({
  isOpen,
  close,
  pageUrl,
  id,
}: {
  id: string;
  pageUrl: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const feature = useCustomerFeature('artist-hub-pro');
  const { t } = useTranslation();

  const prefix = pageUrl.split('/').slice(0, -1).join('/');
  const slug = pageUrl.split('/').slice(-1)[0];
  const [url, setUrl] = useState(slug);
  const {
    createEntry: createAction,
    request: { created, creating, errors },
  } = useCreateEntity({
    entity: 'artistPageActions',
  });
  const [error, setError] = useState('');
  useEffect(() => {
    if (created) {
      close();
    }
  }, [created]);
  useEffect(() => {
    setError('');
  }, [url]);

  useEffect(() => {
    if (errors && 'alias' in errors) setError(errors?.alias as 'string');
  }, [errors]);
  return (
    <ConfirmationWindow
      isOpen={isOpen}
      onRequestClose={() => {
        close();
        setUrl(slug);
      }}
      loading={creating}
      confirmLabel={t('save')}
      disabled={!feature}
      buttonType={true}
      onConfirm={() => {
        createAction({ id, data: { action: 'alias', alias: url } });
        return;
      }}
      title={t('named-url')}
    >
      <div style={{ padding: '0 32px' }}>
        {!feature && (
          <BuyOrSubOffer
            subscribeContext={{ analytics: { detail: 'artist-page' } }}
            title={t('subscribe-to-unlock-custom-url')}
            description={
              <TextFormatted text={t('subscribe-to-unlock-custom-url-desc')} />
            }
          />
        )}
        <InputField
          disabled={!feature}
          label={t('current-url')}
          errorText={error}
          helperText={prefix + '/' + url}
          input={{
            onChange: (e) => setUrl(e.target.value),
            value: url,
          }}
        />
      </div>
    </ConfirmationWindow>
  );
};
