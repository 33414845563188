//
import { put, takeLatest } from 'redux-saga/effects';
import { entities } from 'imddata/actionTypes';
import { loadEntity } from 'imddata/actionTypes/request';

// load user unless it is cached
function* refetchPayouts() {
  yield put(loadEntity('payouts'));
  yield put(loadEntity('payoutRequest'));
}

function* refetchBalance() {
  yield put(loadEntity('payoutRequest'));
}

export default function* runAuth() {
  yield takeLatest(entities.payoutRequest.types.create.SUCCESS, refetchPayouts);
  yield takeLatest(entities.payouts.types.update.SUCCESS, refetchBalance);
}
