import { useWinbackYearly } from './useWinbackYearly';
import { useEffect, useState } from 'react';
import { WinbackYearlyWindowContent } from './WinbackYearlyContents';

export const WinbackYearlyWindow = () => {
  const { active, onMarkWasRead, wasRead, toggleRenewal } = useWinbackYearly();

  const [open, setOpen] = useState(() => active && !wasRead);

  useEffect(() => setOpen(active && !wasRead), [active, wasRead]);

  return (
    <WinbackYearlyWindowContent
      close={() => {
        setOpen(false);
      }}
      isOpen={open}
      onDecline={() => {
        onMarkWasRead();
      }}
      onSubmit={() => {
        toggleRenewal();
      }}
    />
  );
};
