import React from 'react';
import { IconsCollection } from '../../icon';
import LoadingIndicator from '../LoadingIndicator';
import {
  ArtworkWrapper,
  ArtworkImg,
  ArtworkPlaceholder,
  StyledIcon,
  LoadingIndicatorWrapper,
} from './styles';

type Src =
  | string
  | {
      local?: string;
      xSmall?: string;
      export?: string;
      small?: string;
      medium?: string;
      large?: string;
    };

type Shadow = 'small' | 'medium' | 'large' | null;

type Props = {
  src?: Src;
  shadow?: Shadow;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const getArtworkSrc = (src?: Src): string => {
  if (!src) return '';
  if (typeof src === 'string') return src;
  return src.small || src.local || '';
};

const ArtworkImage: React.FC<Props> = ({
  src: srcProp,
  loading = false,
  className,
  style,
}) => {
  const src = getArtworkSrc(srcProp);

  return (
    <ArtworkWrapper className={className} style={style}>
      {src && <ArtworkImg src={src} />}

      {loading && (
        <LoadingIndicatorWrapper>
          <LoadingIndicator />
        </LoadingIndicatorWrapper>
      )}

      {!src && (
        <ArtworkPlaceholder>
          <StyledIcon d={IconsCollection.music} />
        </ArtworkPlaceholder>
      )}
    </ArtworkWrapper>
  );
};

export default ArtworkImage;
