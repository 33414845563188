import styled from '@emotion/styled';
import { ComponentIcons } from '../icon';
import IconButton from './IconButton';

const IconButtonStyled = styled(IconButton)`
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.05);
`;

export const CloseButton = (props: React.ComponentProps<typeof IconButton>) => (
  <IconButtonStyled {...props} icon={ComponentIcons.Close} />
);

export const BackButton = (props: React.ComponentProps<typeof IconButton>) => (
  <IconButtonStyled {...props} icon={ComponentIcons.ChevronLeft} />
);
