import React, { useRef, useEffect, useState } from 'react';
import { interpolate } from 'imdshared';
import { Container, ActiveSliderZone, Stripe, Knob } from './styles';

interface SliderProps {
  value: number;
  edges?: number[];
  className?: string;
  style?: React.CSSProperties;
}

export default function Slider({
  value,
  edges,
  className,
  style,
}: SliderProps): JSX.Element {
  const stripeRef = useRef<HTMLDivElement>(null);
  const knobRef = useRef<HTMLDivElement>(null);

  const [knobPosition, setKnobPosition] = useState(0);
  const [animAllowed, setAnimAllowed] = useState(false);

  const requestedFrame = useRef<number | null>(null);
  const timeout = useRef<number | null>(null);

  useEffect(() => {
    const calc = (): void => {
      const stripeWidth = stripeRef.current?.clientWidth;
      const knobWidth = knobRef.current?.clientWidth;

      if (stripeWidth) {
        // calculate the exact point on a progress stripe
        const midpointStripe = interpolate(
          {
            x: edges,
            y: [0, stripeWidth],
          },
          true
        )(value);

        // calculate the knob offset (knob has to be aligned to left on 0%, right on 100%)
        const knobMidpointOffset = interpolate(
          {
            x: edges,
            y: [0, knobWidth],
          },
          true
        )(value);

        // result is a pixel-perfect offset
        const result = Math.round(midpointStripe - knobMidpointOffset);

        setKnobPosition(result);

        if (!animAllowed) {
          setTimeout(() => setAnimAllowed(true), 250);
        }
      }
    };

    const throttleCalc = (): void => {
      if (requestedFrame.current) {
        cancelAnimationFrame(requestedFrame.current);
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        requestedFrame.current = requestAnimationFrame(calc);
      }, 250);
    };

    window.addEventListener('resize', throttleCalc);

    calc();

    return (): void => {
      requestedFrame.current = null;
      window.removeEventListener('resize', throttleCalc);
    };
  }, [value]);

  return (
    <Container className={className} style={style}>
      <ActiveSliderZone>
        <Stripe ref={stripeRef} />

        <Knob
          ref={knobRef}
          animate={{ x: knobPosition }}
          transition={{ duration: animAllowed ? 0.1 : 0 }}
        />
      </ActiveSliderZone>
    </Container>
  );
}
