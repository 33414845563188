import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useSettings } from 'imddata';
import { Body, Caption } from 'imdshared';
import { VoucherIcon } from './VoucherIcon';

const Wrapper = styled.div`
  display: flex;
  max-width: 600px;
  align-items: center;
`;

const VoucherIconWrapper = styled.div`
  margin: 0 24px 0 16px;
  width: 96px;
`;

const MessageBox = styled.div`
  margin: 16px 0px;

  ${Body} {
    color: ${({ theme }) => theme.foreground.primary};
    display: block;
  }
`;

const HideButton = styled.button`
  margin-top: 8px;
  margin-bottom: 8px;
  display: inline-block;
  text-decoration: none;
`;

export default function AvailableVoucherNotice() {
  const { updateSettings, settings } = useSettings();
  const [hidden, setHidden] = useState(() => settings.hasViewedVoucherNotice);
  const handleDoNotShowAgain = useCallback(() => {
    setHidden(true);
    updateSettings({ hasViewedVoucherNotice: true });
  }, []);
  const { t } = useTranslation();
  if (hidden) return null;

  return (
    <Wrapper>
      <VoucherIconWrapper>
        <VoucherIcon />
      </VoucherIconWrapper>

      <MessageBox>
        <Body>{t('you-have-vouchers-to-apply')}</Body>

        <HideButton onClick={handleDoNotShowAgain}>
          <Caption>{t('do-not-show-again')}</Caption>
        </HideButton>
      </MessageBox>
    </Wrapper>
  );
}
