import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePaymentSteps = <
  T extends { items?: { productId: string | null }[] },
>(
  invoice?: T
) => {
  const { t } = useTranslation();

  return useMemo((): string[] => {
    if (
      invoice?.items?.find(
        (item) =>
          [
            'normaldelivery',
            'expressdelivery',
            'artistpro',
            'mastering',
            'ultraexpressdelivery',
            'youtubeci',
            'distribution-modification',
            'jp-master-special',
          ].indexOf(item.productId || '') !== -1
      )
    ) {
      return [
        t('order-progress-placed'),
        t('order-progress-processing'),
        t('order-progress-delivered'),
      ];
    }

    return [t('order-progress-placed'), t('completed')];
  }, [invoice?.items]);
};
