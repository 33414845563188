import React from 'react';
import { useTranslation } from 'react-i18next';
import RedirectToDeliveryButton from '../RedirectToDeliveryButton';

export default function RedirectToMusicDistributionDeliveryButton(
  props: Omit<
    React.ComponentProps<typeof RedirectToDeliveryButton>,
    'product' | 'text'
  > & { text?: string }
) {
  const { t } = useTranslation();
  return (
    <RedirectToDeliveryButton text={t('distribute')} product="md" {...props} />
  );
}
