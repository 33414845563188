import type { CustomerFeautre } from 'imddata';
import type { SubscriptionId } from 'imddata/types/entities';
import { createContext, useCallback, useContext, useState } from 'react';

export type SubscriptionUpsellContext = {
  state?:
  | { mode: 'paid'; orderId: string | number; subscriptionId: string }
  | { mode: 'paid_upgrade'; orderId: string | number; subscriptionId: string }
  | { mode: 'renew_to_different'; subscriptionId: string }
  | { mode: 'instant'; subscriptionId: string }
  | { mode: 'instant_upgrade'; subscriptionId: string }
  | { mode: 'cancel' }
  | { mode: 'downgrade'; subscriptionId: string };

  trial?: boolean;
  subscriptionIds?: SubscriptionId[];
  recommendedSubscriptionId?: SubscriptionId;
  section?:
  | 'music-distribution'
  | 'support'
  | 'promo-tools'
  | 'music-analytics'
  | 'extra';
  feature?: CustomerFeautre;
  analytics?: {
    detail:
    | 'profile'
    | 'promo-banner'
    | 'delivery-changes'
    | 'music-analytics'
    | 'artist-page'
    | 'name'
    | 'amp'
    | 'commission'
    | 'genre'
    | 'pitching'
    | 'regions'
    | 'artists'
    | 'label'
    | 'rev-splits'
    | 'volume'
    | 'email-support'
    | 'release-page';
  };
};

type ContextValue = {
  open: (c?: SubscriptionUpsellContext) => void;
  close: () => void;
  isOpened: boolean;
  context: SubscriptionUpsellContext;
};

export const SubscriptionUpsellContext = createContext<ContextValue>({
  open: () => undefined,
  close: () => undefined,
  isOpened: false,
  context: {},
});

export const useSubscriptionUpsell = () => {
  return useContext(SubscriptionUpsellContext);
};

export const useSubscriptionUpsellManager = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [context, setContext] = useState<SubscriptionUpsellContext>({});

  const open = useCallback((c?: SubscriptionUpsellContext) => {
    if (c) {
      setContext(c);
    } else {
      setContext({});
    }
    setIsOpened(true);
    if (c?.analytics && window.analytics) {
      window.analytics.track('FT open subscription upsell', c.analytics);
    }
  }, []);

  const close = useCallback(() => {
    setIsOpened(false);
  }, []);
  return {
    context,
    isOpened,
    open,
    close,
  };
};
