import { Clickable, FieldCard, OverlineText, TextFormatted } from 'imdui';
import { ArtistIdContext, VariantPicker } from './components';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { primitives, lightTheme, darkTheme } from '@imus/services-ui/src/theme';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Field, FormSection, formValueSelector } from 'redux-form';
import ThemeIcon from './components/images/theme.svg';
import { Fragment, useMemo } from 'react';
import { useCustomerFeature, type ReduxState } from 'imddata';
import { BuyOrSubOffer } from 'imdshared';
import { ThemeSelector } from '@imus/services-ui';

const COLOR_VARIANTS = [
  'mono',
  0,
  120,
  144,
  168,
  192,
  216,
  24,
  240,
  264,
  288,
  312,
  336,
  48,
  72,
  96,
];

const ColorsGrid = styled(FieldCard)`
  ${primitives}
  display: grid;
  grid-template-columns: repeat(8, 46px);
  align-items: center;
  align-self: stretch;
  justify-content: center;
  row-gap: 16px;
  column-gap: 4px;
  flex-wrap: wrap;
`;

const ColorItemBase = ({
  fill,
  onClick,
  selected,
}: {
  fill: string;
  onClick: () => void;
  selected: boolean;
}) => (
  <Clickable
    onClick={onClick}
    style={{
      cursor: 'pointer',
      borderRadius: '50%',
      position: 'relative',
    }}
  >
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {selected && (
        <circle cx="23" cy="23" r="19" stroke="#004D63" strokeWidth="2" />
      )}
      <g clipPath="url(#clip0_887_1237)">
        <rect
          x="23"
          y="0.372559"
          width="16"
          height="32"
          transform="rotate(45 23 0.372559)"
          css={lightTheme}
          fill={fill}
        />
        <rect
          x="34.3137"
          y="11.6863"
          width="16"
          height="32"
          transform="rotate(45 34.3137 11.6863)"
          css={darkTheme}
          fill={fill}
        />
      </g>
      <circle
        cx="23"
        cy="23"
        r="16"
        fill="black"
        fillOpacity="0.15"
        style={{ mixBlendMode: 'overlay' }}
      />
      <defs>
        <clipPath id="clip0_887_1237">
          <rect
            x="23"
            y="0.372559"
            width="32"
            height="32"
            rx="16"
            transform="rotate(45 23 0.372559)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </Clickable>
);

const ColorPicker = ({
  label,
  input: { value, onChange },
  colorType = 'accent',
}: {
  input: any;
  label: string;
  colorType?: string;
}) => {
  return (
    <div>
      <OverlineText label={label} />
      <ColorsGrid>
        {COLOR_VARIANTS.map((v) => {
          const colorValue = `var(--${colorType}-${v})`;
          return (
            <ColorItemBase
              onClick={() => onChange(colorValue)}
              selected={value === colorValue}
              key={v}
              fill={colorValue}
            />
          );
        })}
      </ColorsGrid>
    </div>
  );
};

const themeIconContainerStyle = css`
  overflow: hidden;
  height: 80px;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 8px;
  border: 1px solid var(--outline-var, #c9c6c4);

  /* Shadow/1 */
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 0px 4px 0px rgba(0, 0, 0, 0.05);

  & > * {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: center;
    flex: 0.5;
    flex-grow: 1;
  }
`;

export function ArtistPageAppearanceForm({
  id,
  form,
}: {
  id: number;
  form: string;
}) {
  const { t } = useTranslation();
  const selector = useMemo(() => formValueSelector(form), [form]);
  const { accent, background } = useSelector((state: ReduxState) =>
    selector(state, 'settings.colors')
  );
  const feature = useCustomerFeature('artist-hub-pro');
  const themeValues = useMemo(
    () => [
      {
        value: 'auto',
        label: t('auto'),
        icon: (
          <div css={themeIconContainerStyle}>
            <ThemeSelector accent={accent} background={background} mode="dark">
              <ThemeIcon />
            </ThemeSelector>
            <ThemeSelector accent={accent} background={background} mode="light">
              <ThemeIcon />
            </ThemeSelector>
          </div>
        ),
      },
      {
        value: 'dark',
        label: t('dark'),
        icon: (
          <div css={themeIconContainerStyle}>
            <ThemeSelector accent={accent} background={background} mode="dark">
              <ThemeIcon />
            </ThemeSelector>
          </div>
        ),
      },
      {
        value: 'light',
        label: t('light'),
        icon: (
          <div css={themeIconContainerStyle}>
            <ThemeSelector accent={accent} background={background} mode="light">
              <ThemeIcon />
            </ThemeSelector>
          </div>
        ),
      },
    ],
    [accent, background]
  );
  return (
    <ArtistIdContext.Provider value={id}>
      {!feature && (
        <BuyOrSubOffer
          style={{ marginBottom: 0 }}
          subscribeContext={{ analytics: { detail: 'artist-page' } }}
          title={t('these-settings-will-not-apply-to-published-version')}
          description={
            <TextFormatted
              text={t('subscribe-to-unlock-customization-options')}
            />
          }
        />
      )}
      <FormSection name="settings" component={Fragment}>
        <Field
          name="colors.theme"
          label={t('pick-theme')}
          values={themeValues}
          component={VariantPicker}
        />
        <Field
          name="colors.accent"
          colorType="accent"
          label={t('pick-accent-color')}
          component={ColorPicker}
        />
        <Field
          name="colors.background"
          colorType="bg"
          label={t('pick-background-color')}
          component={ColorPicker}
        />
      </FormSection>
    </ArtistIdContext.Provider>
  );
}
