import React, { useCallback } from 'react';
import { Icons } from 'imdshared';
import { Wrapper, StyledSlider, StyledIconButton } from './styles';

interface StepSliderProps {
  testId?: string;
  value: number;
  onChange: (value: number) => void;
  edges?: number[];
  className?: string;
  style?: React.CSSProperties;
}

export default function StepSlider({
  testId,
  value = 0,
  onChange,
  edges = [-1, 1],
  className,
  style,
}: StepSliderProps): JSX.Element {
  const DECREASE_LOCKED = value <= edges[0];
  const INCREASE_LOCKED = value >= edges[1];

  const handleChangeLeft = useCallback(() => {
    if (!DECREASE_LOCKED) {
      onChange(value - 1);
    }
  }, [value]);

  const handleChangeRight = useCallback(() => {
    if (!INCREASE_LOCKED) {
      onChange(value + 1);
    }
  }, [value]);

  return (
    <Wrapper data-test-id={testId} className={className} style={style}>
      <StyledIconButton
        testId="StepSlider-minus"
        icon={Icons.actions.remove}
        onClick={handleChangeLeft}
        disabled={DECREASE_LOCKED}
      />

      <StyledSlider value={value} edges={edges} />

      <StyledIconButton
        testId="StepSlider-plus"
        icon={Icons.actions.add}
        onClick={handleChangeRight}
        disabled={INCREASE_LOCKED}
      />
    </Wrapper>
  );
}
