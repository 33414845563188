import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import moment from 'moment';
import { Caption, Body, Content } from 'imdshared';
import type { EntityModels } from 'imddata';
import { BlockTemplate } from 'components';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);

  ${Caption} {
    flex: 1;
  }
`;

const DataWrapper = styled.div`
  padding: 8px 0;
`;

const ShowMoreButton = styled.button`
  padding: 10px 12px;
  border-radius: 6px;
  margin: 8px 0 0 12px;
  font-size: 14px;
  font-weight: 500;

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const RowItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;

  > span {
    flex: 1;
  }
`;

type Props = {
  data: Array<EntityModels.Transaction>;
  translationPrefix: string;
  loadMore: () => void;
  loading: boolean;
  hasMorePages: boolean;
};

const RowItem = ({
  createdAt,
  id,
  amountFormatted,
}: EntityModels.Transaction) => (
  <RowItemWrapper data-test-id={`TransactionRow-${id}`}>
    <Body>{moment(createdAt).format('LL')}</Body>
    <Content>{amountFormatted}</Content>
  </RowItemWrapper>
);

const AffiliateTransactionsTable = ({
  data,
  translationPrefix,
  loadMore,
  loading,
  hasMorePages,
}: Props) => {
  const { t } = useTranslation();

  const simpleRevenueLabel = t(`${translationPrefix}-transactions-revenue`);
  const formattedRevenueLabel = `${simpleRevenueLabel}`;

  const handleShowMoreClick = () => {
    loadMore();
  };

  return (
    <>
      <HeaderWrapper>
        <Caption>{t(`${translationPrefix}-transactions-latest`)}</Caption>
        <Caption>{formattedRevenueLabel}</Caption>
      </HeaderWrapper>

      <DataWrapper>
        {data?.map((item) => <RowItem key={item.id} {...item} />)}

        <ShowMoreButton
          disabled={loading || !hasMorePages}
          onClick={handleShowMoreClick}
        >
          {t('show-more')}
        </ShowMoreButton>
      </DataWrapper>
    </>
  );
};

export const Transactions: React.FC<Props> = ({
  translationPrefix,
  loadMore,
  data,
  hasMorePages,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <BlockTemplate
      padded={false}
      testId={`Transactions-${loading ? 'loading' : 'loaded'}`}
      title={t(`${translationPrefix}-transactions-title`)}
    >
      <AffiliateTransactionsTable
        translationPrefix={translationPrefix}
        data={data}
        loading={loading}
        hasMorePages={hasMorePages}
        loadMore={loadMore}
      />
    </BlockTemplate>
  );
};
