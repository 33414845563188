import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  PayoutAddressesList,
  PayoutAddressForm,
  getFormLabel,
} from './screens';
import { ReturnNavigation } from 'components';

export default function PayoutAddresses({ match }: RouteComponentProps) {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('page-title-billing')}</title>
      </Helmet>

      <Route path={`${match.url}/:id`}>
        {({ match: routeMatch }: RouteComponentProps<{ id: string }>) =>
          routeMatch ? (
            <ReturnNavigation
              text={getFormLabel(routeMatch.params.id, t)}
              to={match.url}
            />
          ) : null
        }
      </Route>

      <Switch>
        <Route exact={true} path={match.url} component={PayoutAddressesList} />

        <Route path={`${match.url}/:id`} component={PayoutAddressForm} />
      </Switch>
    </>
  );
}
