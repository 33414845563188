import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { LibraryHeaderTools } from 'components';
import type { Query } from 'imddata';
import { useSalesStatements, useQuery } from 'imddata';
import StatementRangeSelector from '../StatementRangeSelector';
import EmptySalesPage from '../EmptySalesPage';
import { UnavailableSales } from '../UnavailableSales';

type Context = {
  query?: Query;
  queryHash: string | undefined;
  request: { loading: boolean };
};

const ReportingPageContext = createContext<Context>({
  query: undefined,
  queryHash: undefined,
  request: { loading: true },
});

export const useReportingQuery = () => {
  return useContext(ReportingPageContext);
};

const ReportingPage = ({
  children,
  query: additionalQuery = {},
  renderQueryBuilder,
}: {
  children: React.ReactChild;
  query?: Query;
  renderQueryBuilder: React.ComponentProps<
    typeof LibraryHeaderTools
  >['renderQueryBuilder'];
}) => {
  const {
    request: { loaded },
    request,
    entries,
  } = useSalesStatements();

  const [range, setRange] = useState<[string, string] | never[]>([]);
  const [filter, setFilter] = useState({
    query: {},
    value: [],
  });
  const onChangeFiltering = useCallback(({ searchQuery, value }) => {
    setFilter({
      query: searchQuery,
      value,
    });
  }, []);

  const query = useMemo(() => {
    if (!range?.length) {
      return filter.query;
    }
    const [from, to] = range;
    return {
      'filter.statement': from + (to !== from ? `,${to}` : ''),
      ...filter.query,
      ...additionalQuery,
    };
  }, [range, filter]);

  const onChangeStatement = useCallback(
    ([statementFrom, statementTo]: [string, string]) => {
      setRange([statementTo, statementFrom]);
    },
    []
  );

  const { query: contextQuery, queryHash } = useQuery({ query });

  const contextValue = useMemo(() => {
    return { query: contextQuery, queryHash, request };
  }, [queryHash, request]);

  const hasStatements = entries.length;

  const LeftStatementRange = useMemo(
    () => <StatementRangeSelector onChange={onChangeStatement} />,
    [onChangeStatement]
  );

  if (!hasStatements && loaded) {
    return <EmptySalesPage />;
  }

  return (
    <>
      <UnavailableSales />
      <LibraryHeaderTools
        value={filter.value}
        onChangeFiltering={onChangeFiltering}
        renderQueryBuilder={renderQueryBuilder}
        components={{ Left: LeftStatementRange }}
      />

      {contextValue.queryHash ? (
        <ReportingPageContext.Provider value={contextValue}>
          {children}
        </ReportingPageContext.Provider>
      ) : null}
    </>
  );
};

export default ReportingPage;
