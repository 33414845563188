//
//

type ReleaseCoverRequirement = {
  availableCovers: Record<string, string>;
};

const getCover = (
  availableCovers: Record<string, string>,
  size = 'basic',
  fallback = 'basic'
) => {
  if (!availableCovers) return undefined;
  return (
    availableCovers[size] ||
    availableCovers[fallback] ||
    availableCovers.xSmall ||
    availableCovers.small ||
    availableCovers.medium
  );
};

export const getReleaseCover = <R extends ReleaseCoverRequirement>(
  release: R,
  size = 'basic',
  fallback = 'basic'
) => {
  return getCover(release.availableCovers, size, fallback);
};
