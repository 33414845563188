import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import {
  INVALIDATE_AUTH,
  constants as userConstants,
} from 'imddata/actionTypes/auth';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import type { ReduxState } from 'imddata';
import { reducers } from 'imddata';
import type { State as ProductsState } from './products';
import products from './products';
import impersonate from './impersonate';

export type ReduxUiState = { products: ProductsState } & ReduxState['ui'];

const persistConfig = {
  key: 'ui',
  storage,
  whitelist: ['products', 'fieldUpdateRequests', 'impersonate', 'signup'],
};

const rootReducer = combineReducers({
  impersonate,
  products,
  ...reducers.ui,
});

const finalReducer = (state: ReduxUiState, action: any) => {
  if (
    action.type === userConstants.RESET_LOGGED_USER ||
    action.type === INVALIDATE_AUTH
  ) {
    // pass undefined as a previous state so all reducers will be reinitialized
    return rootReducer(
      {
        ...state,
        // @ts-ignore
        selection: undefined,
        player: undefined,
      },
      action
    );
  }

  // @ts-ignore
  return rootReducer(state, action);
};

// @ts-ignore
export default persistReducer(persistConfig, finalReducer);
