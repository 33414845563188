import styled from '@emotion/styled';
import { Button, Window } from 'imdui';
import { useTranslation } from 'react-i18next';

const WindowContent = styled.div`
  padding: 0 32px 32px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
  & > * {
    flex: 0.5;
  }
`;

export const ContinueWindow = ({
  title,
  isOpen,
  disabled,
  children,
  onCancel,
  onContinue,
}: {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onContinue: React.MouseEventHandler;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Window
      style={{ maxWidth: '448px' }}
      isOpen={isOpen}
      close={onCancel}
      title={title}
    >
      <WindowContent>
        {children}
        <Buttons>
          <Button text={t('cancel')} onClick={onCancel} />
          <Button
            text={t('continue')}
            disabled={disabled}
            onClick={onContinue}
          />
        </Buttons>
      </WindowContent>
    </Window>
  );
};
