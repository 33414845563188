import React from 'react';
import { useSelector } from 'react-redux';
import type { ReduxState } from 'imddata';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ProtectedRoute, FormWrapper } from './components';
import {
  Terms,
  TokenAuth,
  Signin,
  SignupSocial,
  ResetPassword,
  UpdatePassword,
  Signup,
  VerifyEmail,
  Signout,
  BlockedCustomer,
  ProfileDeletion,
} from './screens';

const LOGGED_IN_AUTH_ROUTES = [
  'verify',
  'terms',
  'profile-deletion',
  'signout',
  'blocked',
];
const LOGGED_REDIRECTS = [
  '/signin',
  '/signup',
  '/signout',
  '/reset',
  '/verify',
  '/maintenance',
  '/token',
];

export const useLoggedAuthRoutesMatch = () => {
  return useRouteMatch(
    `(${LOGGED_IN_AUTH_ROUTES.map((route) => `/${route}`).join('|')})`
  );
};

const AUTH_APP_URL = process.env.AUTH_APP_URL;
const APP_URL = process.env.APP_URL;

export default function Auth() {
  const { state } = useLocation<{ from?: { pathname: string } }>();
  const loggedUser = useSelector((s: ReduxState) => s.auth);
  if (AUTH_APP_URL) {
    const from =
      state &&
      state.from &&
      state.from.pathname !== '/' &&
      LOGGED_REDIRECTS.indexOf(state.from.pathname) === -1
        ? state.from
        : '';

    const redirect: string = `${AUTH_APP_URL}/signin?return_to=${APP_URL}${from}`;

    window.location.href = redirect;

    return null;
  }
  if (!loggedUser.data) {
    return (
      <Switch>
        <Route path="/terms" component={Terms} />
        <Route path="/signout" render={() => <Redirect to="/signin" />} />
        <Route path="/token" component={TokenAuth} />
        <Route exact={true} path="/" render={() => <Redirect to="/signin" />} />

        <Route path="/signup/:driver" component={SignupSocial} />

        <Route
          path="(/signin|/signup|/reset|/verify)"
          render={() => (
            <FormWrapper loggedIn={false}>
              <Switch>
                <Route path="/signin" component={Signin} />

                <Route path="/signup" component={Signup} />

                <Route path="/verify/:code" component={VerifyEmail} />

                <Route path="/reset/:code" component={UpdatePassword} />

                <Route path="/reset" component={ResetPassword} />
              </Switch>
            </FormWrapper>
          )}
        />

        <ProtectedRoute path="/profile-deletion/:code" render={() => null} />
        <Route
          path="/"
          render={() => (
            <Redirect
              to={
                state && state.from && state.from.pathname !== '/'
                  ? state.from
                  : '/signin'
              }
            />
          )}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path="/profile-deletion/:code"
        render={() => (
          <FormWrapper loggedIn={true}>
            <ProfileDeletion />
          </FormWrapper>
        )}
      />
      <Route exact={true} path="/terms" component={Terms} />
      <Route exact={true} path="/signout" component={Signout} />
      <ProtectedRoute
        path="/blocked"
        render={() => (
          <FormWrapper loggedIn={true}>
            <BlockedCustomer />
          </FormWrapper>
        )}
      />

      <Route
        path="/verify/:code"
        render={(props) => (
          <FormWrapper loggedIn={true}>
            <VerifyEmail
              {...props}
              continueLink={
                state && state.from && state.from.pathname !== '/'
                  ? state.from.pathname
                  : '/dashboard'
              }
            />
          </FormWrapper>
        )}
      />

      <Route
        path={`(${LOGGED_REDIRECTS.join('|')})`}
        render={() => {
          if (
            state &&
            state.from &&
            state.from.pathname !== '/' &&
            LOGGED_REDIRECTS.indexOf(state.from.pathname) === -1
          ) {
            return <Redirect to={state.from} />;
          }
          return <Redirect to="/dashboard" />;
        }}
      />

      <Route
        path="/"
        render={() => {
          if (state && state.from && state.from.pathname !== '/') {
            return <Redirect to={state.from} />;
          }
          return <Redirect to="/not-found" />;
        }}
      />
    </Switch>
  );
}
