import isURL from 'validator/lib/isURL';

export const validateRequired = (value?: string) => (!value ? 'required' : '');
export { canPlayUrl } from '@imus/artist-page-template/src/BioGrid/VideoCard';

export const validateURL = (value?: string) => {
  if (!value) return false;
  return isURL(value, {
    protocols: ['https', 'http'],
    require_protocol: true,
    require_valid_protocol: true,
  })
    ? ''
    : 'invalid-url';
};
