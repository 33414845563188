import styled from '@emotion/styled';
import { useSubscriptionSelectionManager } from './SubscriptionSelectionContext';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Button, Content } from 'imdui';

const PuHeader = styled.div`
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
    max-width: 800px;
    padding: 16px 64px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ReturnToPuHeader = () => {
  const {
    selectedSubscription,
    currentSubscription,
    grouppedSubscriptions: subscriptions,
    ordering,
    renewalEnabled,
    createHandleSubscription: handleSubscription,
  } = useSubscriptionSelectionManager();
  const { t } = useTranslation();

  const pu = subscriptions
    ?.find(
      (g) =>
        g.id === 'professional-unlimited' ||
        g.id === 'professional-unlimited-analytics'
    )
    ?.subscriptions.find(
      (s) =>
        s.subscriptionId === 'professional-unlimited' ||
        s.subscriptionId === 'professional-unlimited-analytics'
    );

  const puHandler = useMemo(() => {
    if (pu) {
      return handleSubscription(pu);
    }
    return undefined;
  }, [handleSubscription, pu, currentSubscription, renewalEnabled]);
  if (pu) {
    return (
      <PuHeader>
        <div>
          <Content>{t('get-back-to-pu')}</Content>
          <Button
            size="small"
            showLoading={
              ordering &&
              selectedSubscription?.subscriptionId === pu.subscriptionId
            }
            text={t('subscribe')}
            onClick={puHandler}
          ></Button>
        </div>
      </PuHeader>
    );
  }
  return null;
};
