import React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import TrackAudioFile from '../TrackAudioFile';

type Props = WrappedFieldProps & {
  audioDuration: any;
  trackId: any;
  handleTrackField: any;
  required?: any;
  renderAction?: React.FC<{ trackId: number }>;
  disabled?: any;
  onClickHelperButton: any;
};

const AudioField: React.FC<Props> = ({
  audioDuration,
  trackId,
  handleTrackField,
  required,
  disabled,
  input: { onChange },
  meta: { warning, touched, error },
  onClickHelperButton,
  ...props
}) => {
  return (
    <TrackAudioFile
      disabled={disabled}
      required={required}
      audioDuration={audioDuration}
      onChange={onChange}
      onClickHelperButton={onClickHelperButton}
      warning={warning}
      error={touched ? error : ''}
      trackId={trackId}
      {...props}
    />
  );
};

export default AudioField;
