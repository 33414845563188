import React, { ComponentProps, useMemo } from 'react';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import config from 'imdconfig';

type Props = {
  url?: string;
  children: React.ReactNode;
  user?: ComponentProps<typeof OptimizelyProvider>['user'];
};

export default function FeatureProvider({ user, url, children }: Props) {
  const optimizely = useMemo(() => {
    return createInstance({
      sdkKey: config.featureFlagKey,
      datafileOptions: url
        ? {
            urlTemplate: url,
          }
        : undefined,

      // Supress warnings by using defaults
      eventBatchSize: 10,
      eventFlushInterval: 1000,
    });
  }, []);
  return (
    <OptimizelyProvider optimizely={optimizely} timeout={500} user={user}>
      {children}
    </OptimizelyProvider>
  );
}
