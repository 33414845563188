import styled from '@emotion/styled';

const TabStripe = styled.div<{ underline?: boolean }>`
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0 32px;
  border-bottom: ${({ underline }) =>
    underline ? '1px solid rgba(0, 0, 0, 0.1)' : 'none'};
`;

export default TabStripe;
