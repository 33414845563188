import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/br';
import 'moment/locale/en-gb';

import {
  useEntries,
  useEntryProvider,
  useSelectImperonatedUser,
} from 'imddata';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import type { EntityModels, ReduxState } from 'imddata';
import otherMoment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { actions as impersonateActions } from 'redux/actions/ui/impersonate';
import qs from 'qs';

const selectUser = (state: ReduxState): EntityModels.User | undefined =>
  state.auth && state.auth.data && state.auth.data.userId
    ? state.entities.users.entities[state.auth.data.userId]
    : undefined;

export const useCustomerIoMessaging = () => {
  const trackingId = useSelector(
    (state: ReduxState) => selectUser(state)?.trackingId
  );
  useEffect(() => {
    if (window && window._cio && trackingId) {
      window._cio.identify({
        id: trackingId,
      });
    }
  }, [trackingId]); // Customer.io in app messaging
};

export const useImpersonate = () => {
  const impersonate = useSelector((state: ReduxState) => state.ui?.impersonate);
  const impersonatedUser = useSelectImperonatedUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (query.impersonateCustomerId && query.impersonateUserId) {
      dispatch(
        impersonateActions.setImpersonation({
          userId: query.impersonateUserId,
          customerId: query.impersonateCustomerId,
        })
      );
    }
  }, []);
  const onClear = useCallback(() => {
    dispatch(impersonateActions.setImpersonation(null));
  }, []);

  return useMemo(() => {
    if (!impersonate) {
      return { userId: null, customerId: null, onClear };
    }

    return {
      userId: impersonate.userId,
      customerId: impersonate.customerId,
      email: impersonatedUser?.email,
      onClear,
    };
  }, [impersonate?.userId, impersonate?.customerId, impersonatedUser?.email]);
};

export const useSegmentPageViewsTracking = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.analytics) {
      window.analytics.page({
        path: location.pathname,
        title: window.document.title,
        search: location.search,
      });
    }
  }, [location]);
};

export const useLocaleHandling = () => {
  const { i18n } = useTranslation();
  const devmode = useSelector((state: ReduxState) => state.dev.devmode);
  const dateFormatId = useSelector(
    (state: ReduxState) => selectUser(state)?.dateFormatId
  );
  const timezone = useSelector(
    (state: ReduxState) => selectUser(state)?.timezone
  );
  const selectedLocale = useSelector((state: ReduxState) => {
    if (state.auth?.admin?.locale) {
      return state.auth?.admin?.locale;
    }
    return state.auth && state.auth.data && state.auth.data.locale;
  });
  // If using aa_BB browser will freakout on localised functions
  const locale = selectedLocale?.replace(/_/, '-') || 'en';
  useEntries({
    entity: 'dateFormats',
  });
  const dateFormat = useEntryProvider<EntityModels.DateFormat>({
    entity: 'dateFormats',
    id: dateFormatId,
  });

  useEffect(() => {
    if (locale) {
      if (dateFormat) {
        moment.updateLocale(locale, {
          // TODO: Figure out how to properly update full locale
          // @ts-ignore
          longDateFormat: {
            L: dateFormat.short,
            LL: dateFormat.long,
          },
        });
        otherMoment.updateLocale(locale, {
          // TODO: Figure out how to properly update full locale
          // @ts-ignore
          longDateFormat: {
            L: dateFormat.short,
            LL: dateFormat.long,
          },
        });
        moment.locale(locale);
        otherMoment.locale(locale);

        if (i18n.language !== 'cimode' && !devmode) {
          i18n.changeLanguage(locale);
        }
      }
    }
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }, [locale, dateFormat, timezone]);
};
