import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icons } from '@imus/services-ui';

export const shopStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;

  ::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    background-color: var(--fg-4);

    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    pointer-events: none;
  }

  svg {
    fill: var(--fg-1);
    transition: transform 0.15s ease-in-out;
  }

  :hover {
    > svg {
      transform: translateX(8px);
    }

    ::before {
      opacity: 1;
    }
  }
`;

export const shopWrapperStyle = css`
  z-index: 1;
  background: var(--bg-1);
  border-radius: 12px;
  padding: 24px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const shopTextStyle = css`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ServiceIconStyled = styled(Icons.ShopIcon)`
  width: 104px;
`;

export const ShopIcon: React.FC<{ id: string }> = ({ id }) => (
  <ServiceIconStyled icon={id} />
);
