import React from 'react';
import { Stepper, StepperStep } from 'imdshared';
import type { RouteComponentProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ProductStepState as StepState } from 'redux/reducers/ui/products';

type Step = StepState & { to?: string; label?: string };

export default function ConnectedStepper({
  steps,
  match,
  disabled,
}: {
  disabled?: boolean;
  steps: Step[];
  match: RouteComponentProps['match'];
}) {
  const { t } = useTranslation();

  const stepsMapper = steps.map(({ id: stepId, to, label, ...step }, index) => (
    <Route key={stepId} path={`${match.url}/${stepId}`}>
      {({ match: matchStep }: RouteComponentProps) => {
        const nextStep = steps[index + 1];
        const invalid =
          (!nextStep || nextStep.visited || !matchStep) &&
          !step.valid &&
          step.visited &&
          !step.updating &&
          !step.verifying;
        const verifying = step.verifying || step.updating;
        return (
          <StepperStep
            testId={`Stepper-${stepId}-${
              verifying ? 'verifying' : !invalid ? 'valid' : 'invalid'
            }`}
            disabled={disabled}
            to={`${match.url}/${to || stepId}`}
            divider={index !== 0}
            number={index + 1}
            text={label ? t(label) : t(stepId)}
            active={!!matchStep}
            invalid={invalid}
            verifying={verifying}
            visited={!step.visited}
          />
        );
      }}
    </Route>
  ));

  return (
    <>
      <Stepper>{stepsMapper}</Stepper>
    </>
  );
}
