//
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Body, Button, Icons } from 'imdshared';
import styled from '@emotion/styled';

const ActionBarStyled = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #f2f2f2;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  & > ${Body} {
    padding: 20px 20px;
  }
  & > div {
    display: flex;
    margin: 10px 10px 10px auto;
  }
`;

const AddButton = styled(Button)`
  margin-right: 10px;
  width: 300px;
`;

const ActionBar = ({ countSelectedTracks, onClickAdd, onClickCancel }) => {
  const disabled = !countSelectedTracks;
  const { t } = useTranslation();
  return (
    <ActionBarStyled>
      <Body>{t('selected-tracks', { count: countSelectedTracks })}</Body>

      <div>
        <AddButton
          primary={true}
          icon={Icons.actions.add}
          testId={`TracksBrowserDialog-include-tracks-${
            disabled ? 'disabled' : 'enabled'
          }`}
          text={t('include-tracks-from-library')}
          disabled={disabled}
          onClick={onClickAdd}
        />

        <Button onClick={onClickCancel} text={t('cancel')} />
      </div>
    </ActionBarStyled>
  );
};

export default memo(ActionBar);
