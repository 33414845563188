export const AddIcon = ({ fill = 'var(--fg-2)' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.13"
      d="M2 9.2C2 6.67976 2 5.41965 2.49047 4.45704C2.9219 3.61031 3.61031 2.9219 4.45704 2.49047C5.41965 2 6.67976 2 9.2 2H14.8C17.3202 2 18.5804 2 19.543 2.49047C20.3897 2.9219 21.0781 3.61031 21.5095 4.45704C22 5.41965 22 6.67976 22 9.2V14.8C22 17.3202 22 18.5804 21.5095 19.543C21.0781 20.3897 20.3897 21.0781 19.543 21.5095C18.5804 22 17.3202 22 14.8 22H9.2C6.67976 22 5.41965 22 4.45704 21.5095C3.61031 21.0781 2.9219 20.3897 2.49047 19.543C2 18.5804 2 17.3202 2 14.8V9.2Z"
      fill={fill}
      fillOpacity="0.95"
    />
    <path
      d="M13 7.00032C13 6.44803 12.5524 6.00031 12.0001 6.00031C11.4479 6.00031 11.0002 6.44803 11.0002 7.00032C11.0002 8.31031 11.0002 9.62027 11.0001 10.9302C11.0001 10.9536 11.0001 10.977 11.0001 11.0003C10.7161 11.0003 10.4322 11.0003 10.1482 11.0003C9.09881 11.0003 8.04942 11.0003 7.00002 11.0003C6.44772 11.0003 6 11.448 6 12.0002C6 12.5525 6.44772 13.0001 7.00002 13.0001C8.31001 13.0001 9.61997 13.0002 10.9299 13.0002C10.9533 13.0002 10.9767 13.0002 11 13.0002C11 14.3336 11 15.667 11.0001 17.0004C11.0001 17.5527 11.4479 18.0004 12.0001 18.0004C12.5524 18.0003 13.0001 17.5526 13.0001 17.0003C13 15.6669 13 14.3336 13 13.0003C14.3333 13.0003 15.6667 13.0003 17.0001 13.0003C17.5524 13.0003 18.0001 12.5525 18 12.0002C18 11.448 17.5523 11.0003 17 11.0003C15.6666 11.0003 14.3333 11.0004 13 11.0004C13 10.7164 13 10.4325 13 10.1485C13 9.09912 13 8.04973 13 7.00032Z"
      fill={fill}
      fillOpacity="0.95"
    />
  </svg>
);
