import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { H3, CardSection } from 'imdui';
import { Currency } from 'imdshared';

interface Props {
  type: 'music-distribution' | 'youtube-content-id';
  title: string;
  description?: string;
  price: number;
  children: React.ReactNode;
}

const ProductCollapsible = ({
  type,
  title,
  description,
  price,
  children,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    // @ts-ignore
    <CardSection
      collapsible={true}
      collapsed={true}
      title={
        <>
          <H3>{t(type)}</H3>
          <H3>{title}</H3>
        </>
      }
      chipText={<Currency value={price} />}
      description={description}
    >
      {children}
    </CardSection>
  );
};

export default memo<Props>(ProductCollapsible);
