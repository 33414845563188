import React, { useState, useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useCustomerFeature, useSalesStatements } from 'imddata';
import { ApplicationSettingsContext } from 'components';

import { BodySmall, Caption } from 'imdui';
import { decamelize } from 'humps';
import { ChartsGridCard } from 'screens/Reporting/screens/EnhancedTrends/components/ChartsGridCard';
import { ChartHeader } from 'screens/Reporting/screens/EnhancedTrends/components/ChartHeader';
import LineChart from 'screens/Reporting/screens/EnhancedTrends/components/LineChart';
import BarChart from 'screens/Reporting/screens/EnhancedTrends/components/BarChart';
import { Table as ChartsTable } from 'screens/Reporting/screens/EnhancedTrends/components/EntriesTable';
import {
  ChartsContext,
  createFormatters,
} from 'screens/Reporting/screens/EnhancedTrends/components/ChartsContextProvider';
import type { TooltipProps } from '@visx/xychart/lib/components/Tooltip';

import {
  TooltipValue,
  TooltipLegendGrid,
  TooltipLegendRow,
} from 'screens/Reporting/screens/EnhancedTrends/components/Tooltip';
import { useTranslation } from 'react-i18next';

const getFormattedDateTime = (dateTime: Date) =>
  dateTime.toLocaleDateString(moment.locale(), {
    month: 'short',
    year: 'numeric',
  });
const formatDate = (
  d: string,
  index: number,
  values: Array<{ value: string; index: number }>
) => {
  const date = moment(d);
  const month = date.month();
  const year = date.year();
  const prevDate = index !== 0 ? values[index - 1].value : null;
  if (
    month === 1 ||
    index === 0 ||
    (prevDate && moment(prevDate).year() !== year)
  ) {
    return getFormattedDateTime(date.toDate());
  }
  return date.format('MMM');
};

type Datum = {
  date: Moment | string;
  value: number;
  label: string;
  count?: number;
};

const RevenueChart = styled(ChartsGridCard)`
  grid-column: 1 / -1;
  height: 440px;
`;

const RevenueSplitsChart = styled(ChartsGridCard)`
  grid-column: 1 / -1;
  @media (min-width: 1400px) {
    grid-column: span 4;
  }
  @media (min-width: 1700px) {
    grid-column: span 3;
  }
  height: 320px;
`;

const TotalCountChart = styled(ChartsGridCard)`
  grid-column: 1 / -1;
  @media (min-width: 1400px) {
    grid-column: span 2;
  }
  @media (min-width: 1700px) {
    grid-column: span 3;
  }
  ${ChartsTable} {
    grid-template-columns: auto auto;
  }
`;

type TotalValue = { count: number; revenue: number };

type Totals = {
  trackDownloads: TotalValue;
  trackStreams: TotalValue;
  releaseDownloads: TotalValue;
  total: TotalValue;
  monetizations: TotalValue;
};

const getMaxHorizontalTicks = (width: number) =>
  width < 400 ? 6 : width < 700 ? 10 : width < 1000 ? 20 : 30;

const renderTooltip: TooltipProps<Datum>['renderTooltip'] = ({
  tooltipData,
  colorScale,
}) => {
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const revenueFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR',
  });

  const { label, value, count } = tooltipData?.nearestDatum?.datum || {};
  const date =
    (tooltipData?.nearestDatum?.datum &&
      getFormattedDateTime(
        moment(tooltipData?.nearestDatum?.datum.date).toDate()
      )) ||
    'No date';
  return (
    <>
      <TooltipValue>{date}</TooltipValue>

      {value && (
        <TooltipLegendGrid>
          <TooltipLegendRow
            label={label}
            value={
              count
                ? `${revenueFormatter.format(value)} / ${count}`
                : revenueFormatter.format(value)
            }
            color={colorScale?.('total')}
          />
        </TooltipLegendGrid>
      )}
    </>
  );
};

export function RevenueCharts() {
  const { t } = useTranslation();
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const {
    request: { loaded, loading },
    entries,
  } = useSalesStatements();

  const empty = !entries?.length;

  const streamsFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 0,
  });
  const currency = entries?.[0]?.summary.totalRevenueCurrency || 'EUR';
  const revenueFormatterWithCurrency = new Intl.NumberFormat(
    numberFormatLocale,
    {
      maximumFractionDigits: 2,
      style: 'currency',
      currency,
    }
  );
  const [dataKey, setDataKey] = useState<keyof Totals>('total');
  const revenueChartData = useMemo(
    () => ({
      [dataKey]: entries?.reduce<Array<any>>((acc, { year, months }) => {
        const dasherisedField = decamelize(dataKey, {
          separator: '-',
        });
        return [
          ...months.map((m) => {
            // Ternary breaks syntaxhightlight for some reason
            let count = null;
            if (m.summary[dataKey]) {
              count = `${streamsFormatter.format(m.summary[dataKey])} ${t(
                dasherisedField
              )}`;
            }
            return {
              value: Number(m.summary[`${dataKey}RevenuePrecise`]),
              count: count,
              date: moment.utc().set({
                hour: 0,
                day: 10,
                minute: 0,
                year,
                month: Number(m.month) - 1,
              }),
              id: `${m.year}-${m.month}`,
            };
          }),
          ...acc,
        ];
      }, []),
    }),
    [entries, dataKey]
  );

  const availableDataKeys = useMemo<
    Array<{ value: keyof Totals; label: string }>
  >(
    () => [
      { value: 'total', label: t('total') },
      {
        value: 'trackStreams',
        label: t('track-streams'),
      },
      {
        value: 'releaseDownloads',
        label: t('release-downloads'),
      },
      {
        value: 'trackDownloads',
        label: t('track-downloads'),
      },
      {
        value: 'monetizations',
        label: t('monetizations'),
      },
    ],
    [t]
  );

  const totalsAllTime = useMemo<Totals>(() => {
    const initial: Totals = {
      total: { revenue: 0, count: 0 },
      trackStreams: { revenue: 0, count: 0 },
      releaseDownloads: { revenue: 0, count: 0 },
      trackDownloads: { revenue: 0, count: 0 },
      monetizations: { revenue: 0, count: 0 },
    };

    return entries?.length
      ? entries.reduce<Totals>((acc, e) => {
          return availableDataKeys.reduce(
            (innerAcc, { value }) => ({
              ...innerAcc,
              [value]: {
                count: innerAcc[value].count + Number(e.summary[value]),
                revenue:
                  innerAcc[value].revenue +
                  Number(e.summary[`${value}RevenuePrecise`]),
              },
            }),
            acc
          );
        }, initial)
      : initial;
  }, [entries]);

  const barChartData = useMemo(
    () =>
      (Object.keys(totalsAllTime) as Array<keyof Totals>)
        .filter((k) => k !== 'total')
        .map((key) => ({
          id: key,
          label:
            availableDataKeys.find(({ value }) => value === key)?.label || key,
          value: totalsAllTime[key].revenue,
        }))
        .sort((a, b) => b.value - a.value),
    [totalsAllTime]
  );

  const chartContextValue = useMemo(
    () => ({
      streamsFormatter: revenueFormatterWithCurrency,
      percentageFormatter:
        createFormatters(numberFormatLocale).percentageFormatter,
    }),
    [numberFormatLocale, currency]
  );

  const isPremium = useCustomerFeature('music-analytics');
  const displayRevenueChart = isPremium;
  const displayRevenueSplits = isPremium;

  return (
    <>
      {displayRevenueChart && (
        <RevenueChart loading={loading}>
          <ChartHeader
            title={t('revenue-chart')}
            selectedShop={!empty ? dataKey : undefined}
            // TODO: fix API for generics inference
            // @ts-ignore
            onChangeShop={!empty ? setDataKey : undefined}
            shops={!empty ? availableDataKeys : undefined}
          />
          <LineChart<Datum>
            disabled={empty || !loaded}
            data={revenueChartData}
            formatDate={formatDate}
            getMaxHorizontalTicks={getMaxHorizontalTicks}
            renderTooltip={renderTooltip}
            tickFormatValue={(v) => revenueFormatterWithCurrency.format(v)}
          />
        </RevenueChart>
      )}

      {displayRevenueSplits && (
        <>
          <RevenueSplitsChart loading={loading}>
            <ChartHeader title={t('revenue-breakdown')} />
            <ChartsContext.Provider value={chartContextValue}>
              <BarChart disabled={empty || loading} data={barChartData} />
            </ChartsContext.Provider>
          </RevenueSplitsChart>
          <TotalCountChart loading={loading}>
            <ChartHeader title={t('total-streams-downloads-youtube')} />

            <ChartsTable>
              <thead>
                <tr>
                  <td>
                    <Caption>{t('sales-type')}</Caption>
                  </td>
                  <td>
                    <Caption>{t('sales-count')}</Caption>
                  </td>
                </tr>
              </thead>
              <tbody>
                {availableDataKeys
                  .filter((d) => d.value !== 'total')
                  .map(({ value, label }) => (
                    <tr key={value}>
                      <td>
                        <BodySmall>{label}</BodySmall>
                      </td>
                      <td>
                        <BodySmall>
                          {loaded
                            ? streamsFormatter.format(
                                totalsAllTime[value].count
                              )
                            : ''}
                        </BodySmall>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </ChartsTable>
          </TotalCountChart>
        </>
      )}
    </>
  );
}
