import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArtworkImage, Body, Content, Checkbox } from 'imdui';
import type { EntityModels } from 'imddata';
import BundleStatusButton from '../../molecules/BundleStatusButton';
import {
  ReleaseListItemContainer,
  checkboxStyle,
  loadingArtworkStyle,
  textRowWrapper,
  titleWrapper,
} from './styles';

type Props<T> = {
  item: T;
  isCheckable?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onCheck?: any;
  onClick: any;
  className?: string;
  style?: React.CSSProperties;
};

type ReleaseRequirement = {
  currentDeliveryBundleId: number | null;
  states: EntityModels.Release['states'];
  title: EntityModels.Release['title'];
  displayArtist: EntityModels.Release['displayArtist'];
  releaseTypeId: EntityModels.Release['releaseTypeId'];
  volumes: any[];
  isFeedbackRequired: EntityModels.Release['isFeedbackRequired'];
  currentDeliveryBundleStatus: EntityModels.Release['currentDeliveryBundleStatus'];
  deliveryStatus: EntityModels.Release['deliveryStatus'];
  availableCovers?: Record<string, string>;
};

type Component = <T extends ReleaseRequirement>(
  p: Props<T>
) => React.ReactElement;

const ReleaseListItem: Component = ({
  item,
  isCheckable = false,
  checked,
  onCheck,
  disabled,
  onClick,
  style,
}) => {
  const { t } = useTranslation();

  const hasCurrentDeliveryBundle = !!item.currentDeliveryBundleId;

  const isFieldUpdateRequested = !!item?.states?.updateRequest?.find(
    (s) => s.status === 'requested'
  );

  return (
    <ReleaseListItemContainer
      key="release"
      className={disabled ? 'disabled' : undefined}
      style={style}
      onClick={onClick}
    >
      {isCheckable && (
        <Checkbox
          name="check-release"
          onCheck={onCheck}
          checked={checked}
          css={checkboxStyle}
        />
      )}

      <div css={loadingArtworkStyle}>
        <ArtworkImage src={item?.availableCovers?.xSmall} />
      </div>

      <div css={textRowWrapper}>
        <div css={titleWrapper}>
          <Content>{item.title}</Content>

          {hasCurrentDeliveryBundle && (
            <BundleStatusButton
              css={BundleStatusButton.smallStyle}
              status={item.currentDeliveryBundleStatus}
              deliveryStatus={item.deliveryStatus}
              isFeedbackRequired={isFieldUpdateRequested}
            />
          )}
        </div>

        <Body>{item.displayArtist}</Body>

        <Body>{item.volumes?.length > 0 ? t(item.releaseTypeId) : null}</Body>
      </div>
    </ReleaseListItemContainer>
  );
};

export default ReleaseListItem;
