import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from '@emotion/styled';
import type { WindowScrollerProps, ListRowProps } from 'react-virtualized';
import {
  List,
  WindowScroller,
  AutoSizer,
  InfiniteLoader,
} from 'react-virtualized';
import {
  SVGIcon,
  BodySmall,
  LoadingIndicator,
  Icon,
  IconsCollection,
} from 'imdui';
import { Loader } from './components';

// Used to make scrolling and prevent hidden items in nested scrolling containers
// E.g. Admin UI cards
export const ScrollerElementContext =
  createContext<React.RefObject<HTMLElement> | null>(null);

export type InfiniteListRowRenderer<T> = React.FC<
  ListRowProps & {
    item: T;
    onItemClick?: (e: React.MouseEvent, item?: T) => void;
  }
>;

export type Props<T> = {
  data?: T[];
  rowRenderer: InfiniteListRowRenderer<T>;
  getRowHeight?: ({ index, row }: { index: number; row: any }) => number;
  loadNextPage: () => void;
  hasNextPage: boolean;
  noRowsRenderer?: React.FC<{ emptyPlaceholder?: React.ReactNode }>;
  emptyPlaceholder?: React.ReactNode;
  emptyPlaceholderIcon?: string;
  onItemClick?: <V>(e: React.MouseEvent, item?: V) => void;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  //
  disableHeight?: boolean;
  height?: number;
  onChildScroll?: any;
  isScrolling?: boolean;
  scrollTop?: any;
  autoHeight?: boolean;
  [key: string]: unknown;
};

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px 0;

  svg {
    fill: ${({ theme }): string => theme.darks[4]};
  }

  span {
    margin-top: 16px;
    color: ${({ theme }): string => theme.darks[4]};
  }
`;

export const ListItemPlaceholder = ({
  loading,
  emptyPlaceholder,
  emptyPlaceholderIcon,
}: {
  emptyPlaceholderIcon?: string;
  emptyPlaceholder: React.ReactNode;
  loading?: boolean;
}) => (
  <PlaceholderWrapper>
    {loading ? (
      <LoadingIndicator size="large" />
    ) : (
      <>
        {emptyPlaceholderIcon && <SVGIcon d={emptyPlaceholderIcon} size={32} />}
        {!emptyPlaceholderIcon && <Icon d={IconsCollection.empty} size={32} />}
        {emptyPlaceholder && <BodySmall>{emptyPlaceholder}</BodySmall>}
      </>
    )}
  </PlaceholderWrapper>
);

const useRecomputeRowHeights = (data: any, listNode: any) => {
  const [cachedDataLength, setCachedDataLength] = useState(data?.length || 0);

  useEffect(() => {
    if (data && data.length !== cachedDataLength) {
      setCachedDataLength(data.length);
      listNode.current.recomputeRowHeights();
    }
  }, [data]);
};

const getRowCount = (hasNextPage: boolean, data?: any[]) => {
  if (hasNextPage && !data) {
    return 1;
  }
  if (hasNextPage && data) {
    return data.length + 1;
  }
  return data ? data.length : 0;
};

type SimpleInfiniteListType = <T>(props: Props<T>) => React.ReactElement;

export const SimpleInfiniteList: SimpleInfiniteListType = ({
  data,
  rowRenderer: customRowRenderer,
  getRowHeight: customGetRowHeight,
  loadNextPage,
  hasNextPage,
  noRowsRenderer: CustomNoRowsRenderer,
  emptyPlaceholder,
  emptyPlaceholderIcon,
  loading,
  onItemClick,
  className,
  style,
  disableHeight,
  height,
  onChildScroll,
  isScrolling,
  scrollTop,
  autoHeight,
  ...additionalProps
}) => {
  const listNode = useRef();

  useRecomputeRowHeights(data, listNode);

  const getRowHeight = useCallback(
    ({ index }: { index: number }) => {
      if (!data) {
        return 0;
      }

      const row = data[index];

      if (customGetRowHeight) {
        return customGetRowHeight({ index, row });
      }
      if (
        row &&
        typeof row === 'object' &&
        'type' in row &&
        // Figure out how to merge group type into main generic
        // @ts-ignore
        row.type === 'group-title'
      ) {
        return 130;
      }
      return 56;
    },
    [data, customGetRowHeight]
  );

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => {
      if (!data) {
        return false;
      }
      return !!data[index];
    },
    [data]
  );

  const loadMoreRows = useCallback(() => {
    if (loadNextPage) {
      loadNextPage();
    }
  }, [loadNextPage]);

  const noRowsRenderer: React.FC = useCallback(() => {
    if (CustomNoRowsRenderer) {
      return <CustomNoRowsRenderer emptyPlaceholder={emptyPlaceholder} />;
    }

    return (
      <ListItemPlaceholder
        loading={loading}
        emptyPlaceholder={emptyPlaceholder}
        emptyPlaceholderIcon={emptyPlaceholderIcon}
      />
    );
  }, [CustomNoRowsRenderer, loading, emptyPlaceholderIcon, emptyPlaceholder]);

  const rowRenderer = useCallback(
    ({ index, key, style: rowStyle, ...props }) => {
      if (!data) {
        return null;
      }

      if (!isRowLoaded({ index })) {
        return <Loader key={key} style={rowStyle} />;
      }

      const item = data[index];

      return React.createElement(customRowRenderer, {
        item,
        index,
        key,
        onItemClick,
        style: rowStyle,
        ...props,
      });
    },
    [data, onItemClick, customRowRenderer]
  );

  const rowCount = getRowCount(hasNextPage, data);

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      // @ts-ignore we use useRecomputeRowHeights to force trigger row recompute, no need for promises
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={5}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer disableHeight={disableHeight}>
          {({ width, height: sizerHeight }) => (
            <List
              autoHeight={autoHeight}
              className={className}
              style={style}
              ref={(ref: any) => {
                listNode.current = ref;
                registerChild(ref);
              }}
              onRowsRendered={onRowsRendered}
              // @ts-ignore library should use React.FC
              noRowsRenderer={noRowsRenderer}
              height={disableHeight ? height || 0 : sizerHeight}
              width={width}
              isScrolling={isScrolling}
              rowRenderer={rowRenderer}
              rowHeight={getRowHeight}
              rowCount={rowCount}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              data={data}
              //   As List is PureComponent, it does shallow checks for changes of list props,
              // and based on the check result triggers re-render of its children (rows).
              //   Using this prop, you can implement some interactive list features, such as:
              //   - Sorting;
              //   - Selecting;
              //   - Disabling...
              //   Otherwise the list item will be updated e.g. when it will change scroll position,
              // or simply too late.
              {...additionalProps}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

export type InfiniteListProps<T> = Props<T> & {
  scrollElement?: WindowScrollerProps['scrollElement'];
};

export default function ScrollerWrapperInfiniteList<T>({
  scrollElement,
  ...props
}: InfiniteListProps<T>) {
  return (
    <ScrollerElementContext.Consumer>
      {(element) => (
        <WindowScroller
          scrollElement={(element && element.current) || scrollElement}
        >
          {({
            registerChild,
            height,
            isScrolling,
            onChildScroll,
            scrollTop,
          }) => (
            <div ref={registerChild}>
              <SimpleInfiniteList
                {...props}
                height={height}
                isScrolling={isScrolling}
                onChildScroll={onChildScroll}
                scrollTop={scrollTop}
                autoHeight={true}
                disableHeight={true}
              />
            </div>
          )}
        </WindowScroller>
      )}
    </ScrollerElementContext.Consumer>
  );
}
