import { createRequestActions } from '../base';

export const types = {
  fetch: {
    SUCCESS: '@calendar/FETCH_DELIVERY_DATES_SUCCESS',
    REQUEST: '@calendar/FETCH_DELIVERY_DATES_REQUEST',
    FAILURE: '@calendar/FETCH_DELIVERY_DATES_FAILURE',
  },
};

export const actions = {
  fetch: createRequestActions(types.fetch),
};
