//
import { append, without } from 'ramda';
import { entities } from '../../../actionTypes';
import { types } from '../../../actionTypes/tracks';
import uploadFileRequestReducerFactory from '../../../reducerFactories/uploadFileRequestReducerFactory';

const fileReducer = uploadFileRequestReducerFactory(
  entities.tracks.types.audio
);

const uploadQueueReducer = (state, action) => {
  switch (action.type) {
    case types.ADD_TO_UPLOAD_QUEUE: {
      return {
        ...state,
        uploadQueue: append(action.payload.id, state.uploadQueue || []),
      };
    }
    case entities.tracks.types.audio.confirm.SUCCESS:
    case entities.tracks.types.audio.confirm.FAILURE:
    case entities.tracks.types.audio.upload.FAILURE: {
      return {
        ...state,
        uploadQueue: without(action.payload.id, state.uploadQueue || []),
      };
    }
    default:
      return state;
  }
};

export default function tracksRequestReducer(state, action) {
  return [uploadQueueReducer, fileReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
