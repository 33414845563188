const canBePaid = (invoice) => {
  if (!invoice) return false;
  const { status, isPayable } = invoice;
  return (
    isPayable ||
    status === 'unpaid' ||
    status === 'pending' ||
    status === 'legacy_unpaid' ||
    status === 'legacy_pending'
  );
};

export const invoice = {
  canBePaid,
};
