//
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { PageTemplate, MasteringProgressBar, DashboardCard } from 'components';
import {
  useCustomerProduct,
  useCustomerProducts,
  useInProgressInstantMasterings,
} from 'imddata';
import { useProductPriceFormatted } from 'imdshared';
import styled from '@emotion/styled';
import { useHistory } from 'react-router';
import { ContentSmall } from 'imdui';
import { accentThemes, AmpIconBase, AutosizeGrid } from '@imus/base-ui';
import { BodyXS } from '@imus/services-ui/src/Text';

const Description = DashboardCard.Text;

const masteringWrapperStyle = css`
  margin-bottom: 48px;
`;

const PriceBlockValueRow = styled.div`
  height: 16px;
`;
const PriceBlockLabel = styled(BodyXS)`
  color: var(--fg-2, rgba(0, 0, 0, 0.65));
`;
const PriceBlockPrice = styled(ContentSmall)`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));
`;
const PriceBlockAmp = styled(ContentSmall)`
  color: var(--accent);
  margin-left: 2px;
`;
const PriceBlockSeparator = styled.span`
  font-size: 13px;
  font-family: Inter;
  margin: 0 2px;
  font-weight: 600;
  color: var(--fg-4, rgba(0, 0, 0, 0.1));
`;
const PriceBlockWrap = styled.div`
  ${accentThemes.tertiary}
  display: grid;
  gap: 8px;
  font-family: Inter;
  svg path {
    fill: var(--accent);
  }
  & > div {
    display: flex;
    align-items: center;
  }
`;

const PriceBlock = ({
  label,
  price,
  ampPrice,
}: {
  label: string;
  price: string;
  ampPrice?: string | number;
}) => {
  return (
    <PriceBlockWrap>
      <div>
        <PriceBlockLabel>{label}</PriceBlockLabel>
      </div>
      <PriceBlockValueRow>
        <PriceBlockPrice>{price}</PriceBlockPrice>{' '}
        {ampPrice ? (
          <>
            <PriceBlockSeparator>|</PriceBlockSeparator>
            <AmpIconBase width={12} height={12} />
            <PriceBlockAmp>{ampPrice}</PriceBlockAmp>
          </>
        ) : null}
      </PriceBlockValueRow>
    </PriceBlockWrap>
  );
};

const PriceBlocks = styled.div`
  padding-top: 24px;
  margin-top: auto;
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  row-gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    flex: 100px;
  }
`;

const OngoingMasterings = () => {
  const { entries } = useInProgressInstantMasterings();

  if (!entries) {
    return null;
  }

  return (
    <div css={masteringWrapperStyle}>
      {entries.map((entry, idx) => (
        <MasteringProgressBar key={idx} entry={entry} />
      ))}
    </div>
  );
};

const ArtistHubCard = styled(DashboardCard)`
  &:hover {
    box-shadow:
      0px 2px 6px -2px rgba(0, 0, 0, 0.05),
      inset 0px 0px 0px 1px var(--artist-hub);
    ${DashboardCard.Chevron} * {
      fill: var(--artist-hub);
    }
  }
`;

const MusicDistributionCard = styled(DashboardCard)`
  &:hover {
    box-shadow:
      0px 2px 6px -2px rgba(0, 0, 0, 0.05),
      inset 0px 0px 0px 1px var(--music-distribution);
    ${DashboardCard.Chevron} * {
      fill: var(--music-distribution);
    }
  }
`;

const InstantMasteringCard = styled(DashboardCard)`
  &:hover {
    box-shadow:
      0px 2px 6px -2px rgba(0, 0, 0, 0.05),
      inset 0px 0px 0px 1px var(--instant-mastering);
    ${DashboardCard.Chevron} * {
      fill: var(--instant-mastering);
    }
  }
`;

export const ProductsDashboard = () => {
  const { t } = useTranslation();
  const { products } = useCustomerProducts();
  const jpMasterProduct = products?.ungroupped?.find(
    ({ id }) => id === 'jp-master-special'
  );

  const singlePrice = useProductPriceFormatted('single');
  const contentIdPrice = useProductPriceFormatted('youtubeci');
  const epPrice = useProductPriceFormatted('ep');
  const albumPrice = useProductPriceFormatted('album');
  const masteringPrice = useProductPriceFormatted('mastering');
  const jpMasterPrice = useProductPriceFormatted('jp-master-special');

  const single = useCustomerProduct('single');
  const contentId = useCustomerProduct('youtubeci');
  const ep = useCustomerProduct('ep');
  const album = useCustomerProduct('album');
  const mastering = useCustomerProduct('mastering');

  console.log(contentId, singlePrice);

  const history = useHistory();

  return (
    <PageTemplate title={t('products')}>
      <Helmet>
        <title>{t('products')}</title>
      </Helmet>
      <>
        <OngoingMasterings />

        <AutosizeGrid minWidth={360} maxColumnCount={4} gap={24}>
          <MusicDistributionCard
            label={t('music-distribution')}
            onClick={() => {
              history.push('/order/md/new');
            }}
          >
            <Description>{t('music-distribution-description')}</Description>
            <PriceBlocks>
              {singlePrice && (
                <PriceBlock
                  price={singlePrice}
                  ampPrice={Number(single.product?.price.ampPrice)}
                  label={t('single')}
                />
              )}
              {epPrice && (
                <PriceBlock
                  price={epPrice}
                  ampPrice={Number(ep.product?.price.ampPrice)}
                  label={t('ep')}
                />
              )}
              {albumPrice && (
                <PriceBlock
                  price={albumPrice}
                  ampPrice={Number(album.product?.price.ampPrice)}
                  label={t('album')}
                />
              )}
            </PriceBlocks>
          </MusicDistributionCard>

          <MusicDistributionCard
            label={t('content-id')}
            onClick={() => {
              history.push('/order/md/new');
            }}
          >
            <Description>{t('content-id-description')}</Description>

            <PriceBlocks>
              {contentIdPrice && (
                <PriceBlock
                  ampPrice={Number(contentId.product?.price.ampPrice)}
                  price={contentIdPrice}
                  label={t('per-track')}
                />
              )}
            </PriceBlocks>
          </MusicDistributionCard>

          <InstantMasteringCard
            label={t('instant-mastering')}
            onClick={() => {
              history.push('/order/mastering/new');
            }}
          >
            <Description>{t('instant-mastering-description')}</Description>

            <PriceBlocks>
              {masteringPrice && (
                <PriceBlock
                  price={masteringPrice}
                  ampPrice={Number(mastering.product?.price.ampPrice)}
                  label={t('per-track')}
                />
              )}
            </PriceBlocks>
          </InstantMasteringCard>

          <ArtistHubCard
            label={t('artist-hub')}
            onClick={() => {
              history.push('/products/artist-hub');
            }}
          >
            <Description>{t('artist-hub-description')}</Description>
            <PriceBlocks>
              <PriceBlock price={t('free')} label={t('artist-page')} />
              <PriceBlock price={t('free')} label={t('release-page')} />
            </PriceBlocks>
          </ArtistHubCard>

          {jpMasterProduct && (
            <DashboardCard
              label={jpMasterProduct.name}
              onClick={() => {
                history.push('/order/' + jpMasterProduct.id);
              }}
            >
              <Description>{jpMasterProduct.description}</Description>

              <PriceBlocks>
                {jpMasterPrice && (
                  <PriceBlock price={jpMasterPrice} label={t('per-track')} />
                )}
              </PriceBlocks>
            </DashboardCard>
          )}
        </AutosizeGrid>
      </>
    </PageTemplate>
  );
};
