//
import React, { useMemo } from 'react';
import { find, filter } from 'ramda';
import { useTranslation } from 'react-i18next';
import constants from '../constants';
import { createJoinTagField } from '../../helpers';
import JoinTags from '../JoinTags';
import EditItemForm from '../ContributorJoin/EditItemForm';
import { mapContributorEntry } from '../ContributorJoin';

export const { roles } = constants.performers;
const options = (t) =>
  roles.reduce(
    (acc, role) => ({
      ...acc,
      [role.value]: { static: true, value: role.value, label: t(role.label) },
    }),
    {}
  );

const contributorRoles = (t) => ({
  label: 'Role',
  key: 'role',
  isDisabled: ({ value, option, values }) => {
    const noAvailable =
      filter((entry) => entry.id === value.id, values).length === roles.length;

    return (
      noAvailable ||
      find(
        (entry) =>
          option.value === entry.role &&
          entry.role !== value.role &&
          entry.id === value.id,
        values
      )
    );
  },
  order: roles.map((role) => role.value),
  options: options(t),
});

const getData = (t) => ({
  tags: ['roles'],
  roles: contributorRoles(t),
});

const defaultRole = 'composer';

const PerformerForm = (props) => {
  const { t } = useTranslation();
  return (
    <EditItemForm
      testPrefix="performer"
      actionLabel={t('add-performer')}
      {...props}
    />
  );
};

const PerformerJoin = ({ ...props }) => {
  const { t } = useTranslation();
  const data = useMemo(() => {
    return getData(t);
  }, []);

  return (
    <JoinTags
      mapEntry={mapContributorEntry}
      renderForm={PerformerForm}
      allowDuplicates={true}
      testId="performers"
      data={data}
      defaultRole={defaultRole}
      placeholder={t('type-name', { defaultValue: 'Type name' })}
      idKey="contributorId"
      addLabel={t('add-performer')}
      createLabel={t('create-performer')}
      label={t('add-performer')}
      entity="contributors"
      {...props}
    />
  );
};

export const PerformerJoinField = createJoinTagField(
  PerformerJoin,
  'contributors'
);
export default PerformerJoin;
