import type { DOMAttributes } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { Subheading, Caption, Card } from 'imdshared';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

const Header = styled.div`
  padding: 24px 32px 24px 32px;
  // background-color: #fafafa;
  display: flex;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  flex: 1;
  align-items: baseline;
  h1,
  h2,
  h3 {
    margin: 0;
  }
  & > ${Caption} {
    margin-left: 12px;
  }
`;
type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  action?: React.ReactNode;
  testId?: string;
  style?: React.CSSProperties;
  className?: string;
  padded?: boolean;
  onClick?: DOMAttributes<HTMLDivElement>['onClick'];
};

const paddingCss = css`
  padding: 0px 32px 32px;
`;

const PaddingDiv = styled.div<{ padded: boolean }>`
  position: relative;
  word-break: break-word;
  ${({ padded }) => (padded ? paddingCss : '')};
`;

const Action = styled.div`
  align-items: center;
  min-height: 40px; // As a button
  position: absolute;
  right: 24px;
`;

export const BlockTemplate: React.FC<Props> & {
  paddingCss: SerializedStyles;
} = ({
  style,
  className,
  testId,
  title,
  subtitle,
  children,
  padded = true,
  action,
  onClick,
}: Props) => (
  <Card
    onClick={onClick}
    style={style}
    className={className}
    data-test-id={testId}
  >
    {title && (
      <Header>
        <Heading>
          {typeof title === 'string' ? <Subheading>{title}</Subheading> : title}
          {subtitle ? <Caption>{subtitle}</Caption> : null}
        </Heading>
        {action ? <Action>{action}</Action> : null}
      </Header>
    )}

    <PaddingDiv padded={padded}>{children}</PaddingDiv>
  </Card>
);

BlockTemplate.paddingCss = paddingCss;
