export const roles = [
  { label: 'contributor-role-composer', value: 'composer' },
  { label: 'contributor-role-lyricist', value: 'lyricist' },
  {
    disabled: true,
    deprecated: true,
    label: 'contributor-role-remixer',
    value: 'remixer',
  },
  { label: 'contributor-role-producer', value: 'producer' },
  { label: 'contributor-role-arranger', value: 'arranger' },
];
