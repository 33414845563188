import styled from '@emotion/styled';
import React from 'react';
import { BodySmall, H4 } from '../../text';
import type { Clickable } from '../button';
import { Button } from '../button';
import { accentThemes } from '@imus/base-ui';

type Props = {
  className?: string;
  testId?: string;
  style?: React.CSSProperties;
  features?: string[];
  action?: string;
  onActionClick: () => void;
  title: React.ReactNode;
};

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  ${H4} {
    margin-bottom: 8px;
  }
  ${BodySmall} {
    color: var(--on-surface-var, #353534);
  }
  & > div > div {
    align-items: center;
    display: flex;
  }
`;

const SubscriptionFeatureGateBase = ({
  className,
  title,
  features,
  testId,
  style,
  action,
  onActionClick,
}: Props) => {
  return (
    <div className={className} style={style}>
      <Header>
        <div>
          <H4>{title}</H4>

          {features && features.length
            ? features
                .map<React.ReactNode>((f) => <BodySmall key={f}>{f}</BodySmall>)
                .reduce((acc, f) => [acc, ' • ', f])
            : null}
        </div>
        {action && onActionClick && (
          <Button
            style={{ flexShrink: '0' }}
            primary={true}
            testId={testId}
            onClick={onActionClick}
            text={action}
          />
        )}
      </Header>
    </div>
  );
};

export const SubscriptionFeatureGate = styled(SubscriptionFeatureGateBase)`
  ${accentThemes.tertiary}
`;

const SubscriptionFeatureGateButtonBase = ({
  label,
  action,
  sublabel,
  onClick,
  className,
  style,
}: {
  label: string;
  className?: string;
  style?: React.CSSProperties;
  sublabel?: string;
  action: string;
  onClick: React.ComponentProps<typeof Clickable>['onClick'];
}) => {
  return (
    <SubscriptionFeatureGate
      className={className}
      style={style}
      title={label}
      features={sublabel ? [sublabel] : []}
      action={action}
      // @ts-ignore
      onActionClick={onClick}
    />
  );
};

export const SubscriptionFeatureGateButton = styled(
  SubscriptionFeatureGateButtonBase
)``;
