import type { EntityModels } from '../../../../types';
import type { NestDefinition } from '../../base';

export type ArtistNested = EntityModels.Nest<
  EntityModels.Artist,
  {
    profileImageFile?: EntityModels.File;
    namesNormalized: EntityModels.ArtistName[];
    defaultNameNormalized: EntityModels.ArtistName;
  }
>;

export const defaultArtistNest: NestDefinition = [
  {
    key: 'profileImageFile',
    getKey: (artist?: EntityModels.Artist) => artist?.profileImageFileId,
    entity: 'files',
    collect: true,
  },
  {
    key: 'defaultNameNormalized',
    entity: 'artistNames',
  },
  {
    key: 'namesNormalized',
    entity: 'artistNames',
    nest: [
      {
        key: 'revisions',
        entity: 'artistNames',
      },
    ],
  },
];
