import React from 'react';
import type { Theme, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Headline, Icon } from 'imdui';

const cardStyle = css`
  max-width: 768px;
  width: 100%;
  flex-shrink: 0;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * Card wrapper for payment-related routes
 */
const PaymentCard: React.FC<{ style?: React.CSSProperties }> = ({
  style,
  children,
}) => (
  <div style={style} css={cardStyle}>
    {children}
  </div>
);

export const PaymentHeader = styled.div`
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  width: 100%;
  padding: 40px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.lights[1]};

  ${Headline} {
    margin-bottom: 0;
  }
`;

export const columnStyle = css`
  width: 100%;
  max-width: 480px;
  padding: 24px 16px 40px;
`;

export const detailsStyle = css`
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const DetailsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;

  > *:last-child {
    text-align: right;
  }
`;

export const hrStyle = (theme: Theme): SerializedStyles => css`
  border: none;
  height: 1px;
  margin-top: -1px;
  box-shadow: inset 0 -1px 0 0 ${theme.lights[1]};
  width: 100%;
`;

export const footerStyle = css`
  display: flex;
  margin-top: 40px;
  justify-content: center;

  > button:first-child {
    margin-right: 8px;
  }
`;

export const StyledIcon = styled(Icon)<{
  failed?: boolean;
}>`
  fill: ${({ theme, failed }) =>
    failed ? theme.state.error : theme.accent.green};
`;

export default PaymentCard;
