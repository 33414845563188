import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  Centered,
  TrackForms,
  AdaptiveSelectSideWindow,
  SideWindow,
} from 'imdshared';
import { Button, FieldGroup, OptionTabs, TextFormatted } from 'imdui';
import type { EntityModels } from 'imddata';
import { useCreateEntity, useCurrentUser, useEntriesProvider } from 'imddata';
import SideEditorHeader from '../../atoms/SideEditorHeader';
import { SideEditorContainer } from '../../molecules/SideEditor';
import {
  RedirectToMasteringButton,
  RedirectToMusicDistributionDeliveryButton,
} from 'components/atoms';
import { useFeature } from 'imdfeature';
import { ConfirmWithValueWindow } from 'components/molecules';
import styled from '@emotion/styled';
import { useRouteMatch } from 'react-router';
import { useTrackSelection } from 'components/hooks';
import { SingleTrackSplitForm } from '../TrackSplitForm';
type CommonProps = {
  renderActions?: (p: { selectedTracks: number[] }) => React.ReactNode;
  displayedFields?: React.ComponentProps<
    typeof TrackForms.Single
  >['displayedFields'];
  additionalValidation?: (...args: unknown[]) => Record<string, any>;
  requiredFields?: string[];
};

const MonetizationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: 100%;

  & > button {
    width: 100%;
    height: 60px;
  }

  & > button:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const Monetizations: React.FC<{
  trackIds: number[];
  entries: (
    | EntityModels.Track
    | { id: number; created: number; temporary: boolean }
  )[];
}> = ({ trackIds, entries }) => {
  const isAllInCid = entries.reduce(
    (acc, e) => acc && 'isInContentId' in e && e.isInContentId,
    true
  );

  const { t } = useTranslation();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { createEntry, request } = useCreateEntity({ entity: 'trackActions' });
  const { cancelSelection } = useTrackSelection();
  const { entry: user } = useCurrentUser();
  useEffect(() => {
    if (request.created) {
      cancelSelection();
    }
  }, [request.created]);
  if (!user) return null;

  return (
    <MonetizationsWrapper>
      <RedirectToMusicDistributionDeliveryButton
        languageId="en"
        tracks={trackIds}
      />

      {
        isAllInCid && (
          <Button
            text={t('takedown-from-cid')}
            onClick={() => {
              setIsConfirmationOpen(true);
            }}
          />
        )
        // <RedirectToContentIdDeliveryButton
        //   testId="Library-Tracks-redirect-track-to-cid"
        //   languageId="en"
        //   tracks={trackIds}
        // />
      }

      <RedirectToMasteringButton
        testId="Library-Tracks-redirect-track-to-mastering"
        tracks={trackIds}
      />

      <ConfirmWithValueWindow
        confirmPlaceholder={t('confirm-with-email')}
        confirmValue={user.email}
        title={t('takedown-from-cid')}
        loading={!!request.creating}
        isOpen={isConfirmationOpen}
        close={() => {
          setIsConfirmationOpen(false);
        }}
        onConfirm={() => {
          createEntry({
            data: {
              action: 'takedown/request',
              handler: 'yt_cid',
              trackIds,
            },
          });
        }}
      >
        <TextFormatted
          style={{ marginBottom: '24px' }}
          text={t('content-id-takedown-description')}
        />
      </ConfirmWithValueWindow>
    </MonetizationsWrapper>
  );
};

type EditorProps = CommonProps & {
  label: string;
  tracks: (number | string)[];
  close: () => void;
};

const TrackEditor: React.FC<EditorProps> = ({
  label,
  close,
  renderActions,
  requiredFields,
  additionalValidation,
  tracks: tracksWithTempIds,
  displayedFields,
}) => {
  const { t } = useTranslation();

  const hideMonetization = useRouteMatch('/order');

  const { entries } = useEntriesProvider<
    // TODO: handle temporary data
    EntityModels.Track | { id: number; created: number; temporary: boolean }
  >({
    entity: 'tracks',
    ids: tracksWithTempIds,
  });

  const createdTracksIds = useMemo<number[]>(
    () =>
      entries.reduce<number[]>((acc, track) => {
        if ('temporary' in track && track.temporary) {
          return track.created ? [...acc, track.created] : acc;
        }
        return [...acc, track.id];
      }, []),
    [entries]
  );

  const processing = createdTracksIds.length !== tracksWithTempIds.length;

  const [selectedTab, setSelectedTab] = useState<'actions' | 'edit' | 'splits'>(
    () => {
      return !hideMonetization && !processing ? 'actions' : 'edit';
    }
  );

  const [revenueSplitsEnabled] = useFeature({ featureKey: 'revenue-splits' });

  const tabOptions = useMemo(
    () =>
      [
        ...(!hideMonetization
          ? [
              {
                value: 'actions',
                label: t('monetize'),
                testId: 'SideEditorHeader-actions',
              },
            ]
          : []),
        { value: 'edit', label: t('edit'), testId: 'sidewindow-edit-tab' },
        ...(revenueSplitsEnabled
          ? [
              {
                value: 'splits',
                label: t('revenue-splits'),
                testId: 'sidewindow-splits-tab',
              },
            ]
          : []),
      ] as const,
    [revenueSplitsEnabled, hideMonetization]
  );

  if (processing) {
    return (
      <>
        <SideEditorHeader label={label} onClickClose={close} />

        <SideEditorContainer>
          <Centered>
            <LoadingIndicator size="large" />
          </Centered>
        </SideEditorContainer>
      </>
    );
  }

  return (
    <>
      <SideEditorHeader label={label} onClickClose={close}>
        {tabOptions.length > 1 && (
          <>
            <OptionTabs
              style={{ flex: '1', width: '100%' }}
              value={selectedTab}
              options={tabOptions}
              // @ts-ignore
              onChange={setSelectedTab}
            />
          </>
        )}
      </SideEditorHeader>
      <SideEditorContainer>
        {Monetizations && selectedTab === 'actions' && (
          <Monetizations trackIds={createdTracksIds} entries={entries} />
        )}

        {selectedTab === 'splits' && (
          <SingleTrackSplitForm ids={createdTracksIds} />
        )}

        {selectedTab === 'edit' && (
          <>
            {createdTracksIds.length === 1 && (
              <TrackForms.Single
                form={'single-track-form-' + createdTracksIds[0]}
                additionalValidation={additionalValidation}
                requiredFields={requiredFields}
                trackId={createdTracksIds[0]}
                displayedFields={displayedFields}
              />
            )}

            {createdTracksIds.length > 1 && (
              <TrackForms.Multi
                tracks={createdTracksIds}
                additionalValidation={additionalValidation}
                displayedFields={displayedFields}
                requiredFields={requiredFields}
              />
            )}

            {renderActions && (
              <FieldGroup>
                {renderActions({ selectedTracks: createdTracksIds })}
              </FieldGroup>
            )}
          </>
        )}
      </SideEditorContainer>
    </>
  );
};

const sideEditorStyle = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TrackSideWindow: React.FC<
  {
    selected: EditorProps['tracks'];
    isAdaptive?: boolean;
    onClose?: () => void;
  } & CommonProps
> = ({ isAdaptive, onClose, selected: tracks, ...props }) => {
  const { t } = useTranslation();

  const label = t('selected-tracks', { count: tracks?.length });

  return (
    <AdaptiveSelectSideWindow
      isAdaptive={isAdaptive}
      label={label}
      isSelectionActive={tracks.length > 0}
      onClearSelection={onClose}
    >
      {({ isOpen, close }) => (
        <SideWindow
          testId="TrackSideEditor"
          isOpen={isOpen}
          css={sideEditorStyle}
        >
          <TrackEditor close={close} label={label} tracks={tracks} {...props} />
        </SideWindow>
      )}
    </AdaptiveSelectSideWindow>
  );
};

export default TrackSideWindow;
