import React from 'react';
import { useTranslation } from 'react-i18next';
import { constants } from 'imdshared';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'imddata';
import { ContributorSideEditor, QueryBuilderInput } from 'components';
import { EntityPage } from '../../shared';

const options = constants.contributors.roles.map(({ value, label }) => ({
  label,
  id: value,
  value: `role${value}`,
  prefix: 'role',
  queryKey: 'filter.row',
}));

const ContributorsBrowserFilter: React.FC = ({ value, onChange }: any) => {
  const { query, updateQuery } = useQuery();

  return (
    <QueryBuilderInput
      value={value}
      loading={false}
      options={options}
      onChange={onChange}
      query={query}
      onInputChange={updateQuery}
    />
  );
};

const Contributors: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet key="helmet">
        <title>{t('page-title-contributors')}</title>
      </Helmet>

      <EntityPage
        entity="contributors"
        renderQueryBuilder={ContributorsBrowserFilter}
      />

      <ContributorSideEditor />
    </>
  );
};

export default Contributors;
