//
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { ArtistHubProduct, ProductsDashboard } from './screens';

export default function Products({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={`${match.url}`} exact={true} component={ProductsDashboard} />
      <Route path={`${match.url}/artist-hub`} component={ArtistHubProduct} />
    </Switch>
  );
}
