//
import { values, compose, filter } from 'ramda';
import { delay, select, put } from 'redux-saga/effects';
import { loadEntity } from '../../actionTypes/request';

export default function* pollConfirmedTracks() {
  while (true) {
    yield delay(1000 * 60);
    const confirmedTracks = yield select((state) =>
      compose(
        filter(({ uploadStatus }) => uploadStatus === 'confirmed'),
        values
      )(state.entities.tracks?.entities || {})
    );
    for (let i = 0; i < confirmedTracks.length; i += 1) {
      yield put(loadEntity('tracks', { id: confirmedTracks[i].id }));
    }
  }
}
