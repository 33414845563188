//
import React, { memo, useMemo } from 'react';
import { ErrorBoundary } from 'components';
import {
  useCustomerPrices,
  useFetchEntity,
  useCountries,
  useLanguages,
  useDeliveryRegions,
} from 'imddata';
import { ProtectedRoute } from 'imdauth';
import type { RouteComponentProps, RouteProps } from 'react-router';
import useRouteState from './useRouteState';
import AppCrash from '../../AppCrash';
import ImpersonateUser from '../../ImpersonateUser';

const query = {
  'filter.is_main_genre': 1,
};

const labelQuery = {
  perPage: 150,
};

export const AssetFetcher = () => {
  useCountries();
  useDeliveryRegions();
  useLanguages();
  useFetchEntity({ entity: 'customerPaymentMethods' });
  useFetchEntity({ entity: 'genres', query });
  useFetchEntity({ entity: 'labels', query: labelQuery });
  useFetchEntity({ entity: 'subscriptions' });
  useFetchEntity({ entity: 'artists' });
  useFetchEntity({ entity: 'publishers' });
  useFetchEntity({ entity: 'contributors' });
  useFetchEntity({ entity: 'deliveryDates' });
  useFetchEntity({ entity: 'shops' });
  useFetchEntity({
    entity: 'customerSubscriptionState',
  });
  useCustomerPrices();

  return null;
};

const ImpersonateScreen = () => {
  return <ImpersonateUser />;
};

const createRenderRoute = (
  component: React.ComponentType<RouteComponentProps>
) =>
  memo(function ProtectedRouteInner(props: RouteComponentProps) {
    const { isAdmin, isImpersonating } = useRouteState();
    return (
      <>
        <AssetFetcher />
        <ErrorBoundary key={props.location.pathname}>
          {(error: any) => {
            if (error) {
              return <AppCrash error={error} />;
            }

            if (isAdmin && !isImpersonating)
              return React.createElement(ImpersonateScreen, props);

            return React.createElement(component, props);
          }}
        </ErrorBoundary>
      </>
    );
  });

const ConnectedLayoutRoute = ({
  component,
  ...rest
}: {
  component: React.ComponentType<RouteComponentProps>;
} & RouteProps) => {
  const renderRoute = useMemo(() => createRenderRoute(component), [component]);
  return <ProtectedRoute {...rest} component={renderRoute} />;
};
export default ConnectedLayoutRoute;
