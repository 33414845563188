import type {
  DecoratedComponentClass,
  DecoratedFormProps,
  FormSubmitHandler,
  WrappedFieldArrayProps,
} from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FieldCard, HelperText, Window } from 'imdui';
import { JoinTagItem } from 'imdshared/src/Next/JoinTags/JoinTagsList';
import { JoinTagAddButton } from 'imdshared/src/Next/JoinTags';
import { WindowWrapper } from './WindowWrapper';
import { JoinTagItemWrapper } from 'imdshared/src/Next/JoinTags/JoinTagsList/JoinTagItem';
import { css } from '@emotion/react';
import { BrandIndicator } from '@imus/services-ui';

export type ArtistCollectionFieldProps<T> = {
  sort?: (a: T, b: T) => number;
  disabled?: boolean;
  isDisabled?: (v: T) => boolean;
  dragType?: string;
  formComponent: DecoratedComponentClass<
    T,
    DecoratedFormProps<
      T,
      { submitText: string; onSubmit: FormSubmitHandler<T> },
      string
    >
  >;
  addText: string;
  formatValueToItem: (v: T) => {
    label: React.ReactNode;
    sublabel?: React.ReactNode;
  };
};

const buttonWrapperStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px;
  justify-content: center;
`;

export const ArtistCollectionField = <T extends object>({
  dragType,
  fields,
  isDisabled,
  disabled,
  formComponent: FormComponent,
  formatValueToItem,
  addText,
  sort = () => 0,
}: WrappedFieldArrayProps<T> & ArtistCollectionFieldProps<T>) => {
  const [formState, setFormState] = useState<null | 'add' | number>(null);
  const editing = typeof formState === 'number';
  const { t } = useTranslation();
  return (
    <>
      <div>
        <FieldCard style={{ padding: 0, gap: 0, overflow: 'hidden' }}>
          {fields
            .getAll()
            ?.sort(sort)
            .map((f, index) => {
              const disabled = isDisabled ? isDisabled(f) : false;
              const value = formatValueToItem(f);
              return (
                <JoinTagItem
                  style={{
                    minHeight: '56px',
                  }}
                  key={index}
                  index={index}
                  value={
                    disabled
                      ? {
                        label: (
                          <>
                            <BrandIndicator /> {value.label}
                          </>
                        ),
                      }
                      : value
                  }
                  onClickItem={
                    disabled
                      ? undefined
                      : () => {
                        setFormState(index);
                      }
                  }
                  type={dragType}
                  onClickDelete={() => fields.remove(index)}
                  onDropItem={
                    dragType
                      ? ({ from, to }) => fields.swap(from, to)
                      : undefined
                  }
                />
              );
            })}
          <JoinTagItemWrapper isOver={false}>
            <div css={buttonWrapperStyle}>
              <JoinTagAddButton
                icon={undefined}
                size="small"
                disabled={disabled}
                text={addText}
                onClick={() => {
                  setFormState('add');
                }}
              />
            </div>
          </JoinTagItemWrapper>
        </FieldCard>
        {disabled && <HelperText text={t('limit-reached')} />}
      </div>
      <Window
        lockClickOutside={true}
        title={addText}
        isOpen={formState !== null}
        close={() => setFormState(null)}
      >
        <WindowWrapper>
          <FormComponent
            submitText={editing ? t('save') : t('add')}
            initialValues={editing ? fields.get(formState) : {}}
            onSubmit={(values: T) => {
              if (editing) {
                fields.splice(formState, 1, values);
                setFormState(null);
              } else {
                fields.push(values);
                setFormState(null);
              }
            }}
          />
        </WindowWrapper>
      </Window>
    </>
  );
};
