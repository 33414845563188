import React from 'react';
import { ProcessFieldMeta } from 'imddata';
import { NewInput } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';

type Props = {
  input?: Partial<WrappedFieldProps['input']>;
  meta?: Partial<WrappedFieldProps['meta']>;
} & React.ComponentProps<typeof NewInput> &
  Omit<React.ComponentProps<typeof ProcessFieldMeta>, 'children'>;

export function NewInputField({
  input = {},
  meta = {},
  onChange,
  valueOverride,
  hasValue,
  errorText,
  multiline,
  rows,
  ...other
}: Props) {
  return (
    <ProcessFieldMeta
      valueOverride={valueOverride}
      hasValue={(input && !!input.value) || hasValue}
      meta={{
        ...meta,
        error: meta.error || errorText,
      }}
    >
      {({ error, warning }) => (
        <NewInput
          {...input}
          errorText={error}
          multiline={multiline}
          rows={rows}
          warningText={warning}
          onChange={(...args) => {
            if (input.onChange) {
              // @ts-ignore
              input.onChange(...args);
            }
            if (onChange) {
              // @ts-ignore
              onChange(...args);
            }
          }}
          {...other}
        />
      )}
    </ProcessFieldMeta>
  );
}

export default NewInputField;
