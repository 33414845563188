import { createContext } from 'react';

type AppSettings = {
  numberFormatLocale: string;
  timezone?: string;
  dateFormat?: number;
  locale?: string;
};

export const ApplicationSettingsContext = createContext<AppSettings>({
  numberFormatLocale: 'en',
  timezone: '',
});

ApplicationSettingsContext.displayName = 'ApplicationSettingsContext';
