const releaseRequiredFields = [
  'copyrightText',
  'pendingCover',
  'artists',
  'title',
  'labelId',
];

export const releaseForm = {
  requiredFields: releaseRequiredFields,
  warnArtists: (values) => {
    if (!values.name) {
      return {
        name: 'translate',
      };
    }
    return {};
  },
};
