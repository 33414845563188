import { useContext, createContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTrack } from 'imddata';
import { actions as playerActions } from 'imddata/actionTypes/ui/player';

const emptyState = {};

const usePlayerActions = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      play: (...args) => dispatch(playerActions.play(...args)),
      seekTo: (...args) => dispatch(playerActions.seekTo(...args)),
      sync: (...args) => dispatch(playerActions.sync(...args)),
      pause: (...args) => dispatch(playerActions.pause(...args)),
      stop: (...args) => dispatch(playerActions.stop(...args)),
      setBuffering: (...args) => dispatch(playerActions.setBuffering(...args)),
      clear: (...args) => dispatch(playerActions.clearPlayerData(...args)),
    }),
    []
  );
  return actions;
};

export const SelectAudioFileContext = createContext(
  (track) =>
    track?.availableAudio?.mp3Preview || track?.audioPreview?.file.downloadUrl
);

export const usePlayer = (id) => {
  const { trackId, ...playerState } = useSelector(
    (state) => state.ui.player || emptyState
  );
  const { entry: track } = useTrack({
    passive: true,
    id: id || trackId,
  });
  const actions = usePlayerActions();
  const selectFile = useContext(SelectAudioFileContext);
  const fileUrl = useMemo(() => selectFile(track), [selectFile, track]);

  return {
    ...playerState,
    ...actions,
    trackId,
    fileUrl,
    fileName: track?.audioPreview?.file?.originalFileName,
    playingTrackId: trackId,
    audioFile: track?.uploadStatus === 'failed' ? null : playerState.audioFile,
    title: track?.defaultNameNormalized?.title,
    artist: track?.defaultNameNormalized?.displayArtist,
  };
};

export default function PlayerProvider({ children, ...props }) {
  const data = usePlayer();

  return children({
    ...data,
    ...props,
  });
}
