import React, { forwardRef, useEffect } from 'react';

type LoadingComplete = { src: string };

type Props = {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
  onLoadingComplete?: (e) => LoadingComplete;
};

const NextJsImageCompat = forwardRef<HTMLImageElement, Props>(
  function ImageCompat(
    { src, className, onLoadingComplete, alt, style }: Props,
    ref
  ) {
    useEffect(() => {
      if (onLoadingComplete) {
        onLoadingComplete({ src });
      }
    }, []);
    return (
      <img ref={ref} src={src} className={className} style={style} alt={alt} />
    );
  }
);

export default NextJsImageCompat;
