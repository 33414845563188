import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerPaymentMethod, EnabledField } from 'components';
import type { EntityModels } from 'imddata';
import { NewPaymentOption, PaymentOption } from '../PaymentOption';
import type { GatewayProps } from '../types';

const PaypalGateway: React.FC<GatewayProps> = ({
  customerPaymentMethods,
  onPaymentChange,
  paymentData,
  formData,
  selectionActive,
}) => {
  const { t } = useTranslation();

  const activeEntry =
    !selectionActive &&
    paymentData.id &&
    paymentData.id !== 'new-paypal' &&
    customerPaymentMethods.find(
      (v): v is EntityModels.CustomerPaymentMethodPaypal =>
        v.id === paymentData.id && v.paymentMethodId === 'paypal'
    );

  const [storePaymentMethod, setStoreMethod] = useState(
    () => !!formData?.enforceStorePaymentMethod
  );

  useEffect(() => {
    onPaymentChange({
      id: 'new-paypal',
      paymentMethod: 'paypal',
      isReady: true,
      data: {
        paymentMethodId: 'paypal',
        data: {
          storePaymentMethod,
        },
      },
    });
  }, [storePaymentMethod]);

  return (
    <>
      {selectionActive &&
        customerPaymentMethods
          ?.filter(
            (v): v is EntityModels.CustomerPaymentMethodPaypal =>
              v.paymentMethodId === 'paypal'
          )
          .map((data: EntityModels.CustomerPaymentMethodPaypal) => (
            <PaymentOption
              key={data.id}
              checked={paymentData.id === data.id}
              onCheck={() =>
                onPaymentChange({
                  paymentMethod: 'paypal',
                  id: data.id,
                  isReady: true,
                  data: { customerPaymentMethodId: data.id },
                })
              }
            >
              <CustomerPaymentMethod
                scheme="paypal"
                description={data.description}
                data={data.data}
              />
            </PaymentOption>
          ))}
      {!selectionActive ? (
        <CustomerPaymentMethod
          scheme="paypal"
          description={activeEntry ? activeEntry.description : t('new-paypal')}
          data={{ shopperEmail: '' }}
        />
      ) : null}

      {selectionActive && (
        <NewPaymentOption selected={paymentData.id === 'new-paypal'}>
          <PaymentOption
            onCheck={() =>
              onPaymentChange({
                id: 'new-paypal',
                isReady: true,
                paymentMethod: 'paypal',
                data: {
                  paymentMethodId: 'paypal',
                  data: {
                    storePaymentMethod,
                  },
                },
              })
            }
          >
            <CustomerPaymentMethod
              scheme="paypal"
              description={t('new-paypal')}
              data={{ shopperEmail: '' }}
            />
          </PaymentOption>
          {!formData?.enforceStorePaymentMethod && (
            <div
              style={{
                padding: '8px 16px 16px',
                display:
                  selectionActive && paymentData.id === 'new-paypal'
                    ? 'block'
                    : 'none',
              }}
            >
              <EnabledField
                // @ts-ignore
                meta={{}}
                name="save-method"
                label={t('save-payment-method')}
                // @ts-ignore
                input={{
                  value: storePaymentMethod,
                  onChange: (value) => {
                    setStoreMethod(value);
                  },
                }}
              />
            </div>
          )}
        </NewPaymentOption>
      )}
    </>
  );
};

export default PaypalGateway;
