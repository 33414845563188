//
import { find } from 'ramda';
import { entities } from '../../../../actionTypes';

const initialState = {
  entities: {},
  searchHistory: {},
  keys: [],
};

const reducer = (state, action) => {
  if (action.type === entities.releaseDeliveryStatus.types.fetch.SUCCESS) {
    const {
      payload: { id },
      rawResponse: { data },
    } = action;

    const anyShopDelivered = !!find(
      ({ deliveredAt }) => !!deliveredAt,
      data.shops
    );
    const allShopsDelivered = !find(
      ({ deliveredAt }) => deliveredAt === null,
      data.shops
    );
    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: {
          ...data,
          anyShopDelivered,
          allShopsDelivered,
        },
      },
    };
  }

  return state;
};

export default function releaseDeliveryStatus(state = initialState, action) {
  return [reducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
