import { take } from 'ramda';
import moment from 'moment';

const createProcessingRange = (firstOption, lastOption, deliveryDates) => {
  const availableDates = take(
    lastOption.deliveryDays + 1,
    Object.values(deliveryDates).filter((date) => date.isAvailable)
  );

  return availableDates.reduce((acc, { date }) => {
    return {
      ...acc,
      [moment(date).format('YYYY-MM-DD')]: true,
    };
  }, {});
};

const createSinglePriceDate = (option) => (acc, dumb, idx) => {
  const rangeDay = moment(option.startDay).add(idx, 'day').format('YYYY-MM-DD');

  return {
    ...acc,
    [rangeDay]: {
      ...option,
      price: option.price,
      prices: undefined,
    },
  };
};

const mapColorsToOptions = (option) => ({
  ...option,
  deliveryType: option.id,
});

export default function buildDateRanges({ deliveryDates, deliveryOptions }) {
  const options = deliveryOptions
    .slice()
    .sort((optionA, optionB) => {
      return new Date(optionA.startDay) - new Date(optionB.startDay);
    })
    .map(mapColorsToOptions);

  const lastOptionIdx = options.length - 1;
  const defaultOption = options[lastOptionIdx];

  const datesWithDeliveryOptionsAndPrice = options.reduce(
    (acc, option, idx) => {
      const { startDay } = option;
      const nextOption = idx !== lastOptionIdx ? options[idx + 1] : null;
      if (!nextOption) {
        return acc;
      }
      const nextOptionStartDate = moment(nextOption.startDay);

      const rangeLength = nextOptionStartDate.diff(moment(startDay), 'days');

      const range = Array(rangeLength)
        .fill()
        .reduce(createSinglePriceDate(option), {});
      return {
        ...acc,
        ...range,
        nextOptionStartDate,
      };
    },
    {
      firstAvailbleDate: moment(options[0].startDay),
      key: Date.now(),
      dates: {},
    }
  );
  return {
    ...datesWithDeliveryOptionsAndPrice,
    legend: options,
    processingRange: createProcessingRange(
      options[0],
      options[lastOptionIdx],
      deliveryDates
    ),
    defaultOption,
  };
}
