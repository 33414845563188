import React, { useState, useCallback, useMemo } from 'react';
import type { EntityModels } from 'imddata';
import { useEntry, useDeleteEntity, useUserNotifications } from 'imddata';
import { sortWith, descend, isNil } from 'ramda';
import { CellMeasurerCache } from 'react-virtualized';
import {
  renderNotification,
  NotificationDetailsHeader,
  NotificationsList,
} from './components';
import { Container, DetailsWrapper, Details } from './styles';
import transition from '../../transition';

type Props = {
  onClickClose: (e: React.MouseEvent) => void;
};

const cache = new CellMeasurerCache({
  defaultHeight: 150,
  fixedWidth: true,
});

function useUserNotification({
  id,
  ...props
}: Omit<Parameters<typeof useEntry>[0], 'entity'>) {
  return useEntry<EntityModels.UserNotification>({
    entity: 'userNotifications',
    id,
    ...props,
  });
}

function useNotificationsControlls() {
  const { entries, refresh, loadMore, request } = useUserNotifications();

  const { deleteEntry: markAsRead } = useDeleteEntity({
    entity: 'userNotifications',
  });

  const orderedNotifications = useMemo(() => {
    if (!entries) {
      return undefined;
    }

    return sortWith<EntityModels.UserNotification>(
      [
        descend<EntityModels.UserNotification>(
          (v) => v?.notification?.isImportant || false
        ),
        descend<EntityModels.UserNotification>((value) =>
          new Date(value?.notification?.createdAt).getTime()
        ),
      ],
      entries
    );
  }, [entries]);

  return {
    entries: orderedNotifications,
    request,
    loadMore,
    refresh,
    markAsRead,
  };
}

const NotificationsWindow: React.FC<Props> = ({ onClickClose }) => {
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    undefined | number
  >();

  const handleSelectNotification = useCallback(
    (_event: React.MouseEvent, id: number) => {
      setSelectedNotificationId(id);
    },
    []
  );

  const onClickBackToList = useCallback(() => {
    setSelectedNotificationId(undefined);
  }, []);

  const isDetailsOpened = !isNil(selectedNotificationId);
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
    markAsRead,
  } = useNotificationsControlls();
  const { entry: notification } = useUserNotification({
    passive: !selectedNotificationId,
    id: selectedNotificationId,
  });

  const isListEmpty = !loading && entries?.length === 0;

  const handleClickItem = (
    event: React.MouseEvent,
    { id, hasDetailView }: { id: number; hasDetailView: boolean }
  ): void => {
    if (hasDetailView) {
      handleSelectNotification(event, id);
    }
    markAsRead({ id });
  };

  const handleClickClear = isListEmpty ? undefined : (): void => markAsRead();

  return (
    <Container
      animate={{ x: isDetailsOpened ? '-50%' : 0 }}
      transition={transition}
    >
      <NotificationsList
        isListEmpty={isListEmpty}
        cache={cache}
        load={loadMore}
        loading={loading}
        hasMorePages={hasMorePages}
        data={entries}
        onClickClose={onClickClose}
        onItemClick={handleClickItem}
        onClickClear={handleClickClear}
      />

      <DetailsWrapper>
        <NotificationDetailsHeader onClickBack={onClickBackToList} />

        <Details>
          {isDetailsOpened &&
            notification &&
            renderNotification({ entry: notification, isExpanded: true })}
        </Details>
      </DetailsWrapper>
    </Container>
  );
};

export default NotificationsWindow;
