import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  flex: 1;
  flex-shrink: 0;
  min-width: 128px;
`;

export const ActiveSliderZone = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const Stripe = styled.div`
  display: flex;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: white;
  box-shadow: inset 0 0 0 1px #e1e1e1;
`;

export const Knob = styled(motion.div)`
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #999999;
`;
