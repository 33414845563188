import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'imddata/actionTypes/ui/selection';
import { SideEditor } from 'components/molecules';
import type { ReduxState } from 'redux/reducers';

const getFormId = (id: string | number, entity: string) =>
  `${entity}SideEditForm:${id}`;

export type Props = {
  warn: any;
  isAdaptive?: boolean;
  label: string;
  entity: 'publishers' | 'artists' | 'contributors';
  components: {
    SingleForm: React.ComponentType<any>;
    MultiForm?: React.ComponentType<any>;
  };
};
const empty: string[] = [];

export const EntitySideEditor = ({
  components: { SingleForm, MultiForm },
  label,
  entity,
  isAdaptive,
  warn,
}: Props) => {
  const selection = useSelector(
    (state: ReduxState) => state.ui.selection[entity] || empty
  );

  const dispatch = useDispatch();

  const [form, Edit] = useMemo(() => {
    if (selection.length === 1 && SingleForm) {
      const singleForm = getFormId(selection[0], entity);
      return [
        singleForm,
        <SingleForm
          key="form"
          form={singleForm}
          warn={warn}
          id={selection[0]}
        />,
      ];
    }
    if (MultiForm) {
      const multiForm = getFormId('multi', entity);
      return [
        multiForm,
        <MultiForm key="multi" form={multiForm} ids={selection} warn={true} />,
      ];
    }

    return ['', null];
  }, [selection.length === 1]);

  const components = useMemo(() => ({ Edit }), [Edit]);

  const onClearSelection = useCallback(() => {
    dispatch(
      actions.updateSelection({
        entity,
        selected: [],
      })
    );
  }, []);
  return (
    <SideEditor
      form={form}
      label={label}
      isAdaptive={isAdaptive}
      isSelectionActive={selection.length > 0}
      onClearSelection={onClearSelection}
      components={components}
    />
  );
};
