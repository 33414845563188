//
import { put } from 'redux-saga/effects';
import { entities } from '../../actionTypes';
import { actions as uiActions } from '../../actionTypes/ui/selection';

export default function* createTempTracks({ payload: { tempTracks }, meta }) {
  for (let i = 0; i < tempTracks.length; i += 1) {
    const {
      data: { genreId, ...data },
      nestingPath,
      audio,
      tempId,
    } = tempTracks[i];
    yield put(
      entities.tracks.actions.create.request({
        payload: {
          tempId,
          data,
          nestingPath,
        },
      })
    );
    yield put({
      type: '@tracks_saga/CREATE_TRACK',
      payload: {
        nestingPath,
        audio,
        data: {
          ...data,
          ...(genreId ? { genreId } : {}),
        },
        tempId,
      },
      meta,
    });
  }
  yield put(
    uiActions.updateSelection({
      entity: 'tracks',
      selected: tempTracks.map(({ tempId }) => tempId),
    })
  );
}
