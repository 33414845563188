import { createContext } from 'react';

type Context = {
  isDrawerOpen: boolean;
  isCollapsible: boolean;
  open: () => void;
  close: () => void;
  hide: (shouldHide: boolean) => void;
};

export const NavDrawerContext = createContext<Context>({
  isDrawerOpen: false,
  isCollapsible: false,
  open: () => null,
  close: () => null,
  hide: () => null,
});

export default NavDrawerContext;
