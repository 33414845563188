import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { Portal } from 'react-portal';
import { useHistory, useLocation } from 'react-router-dom';
import { AnimatedDim } from 'imdui';
import { AnimatePresence } from 'framer-motion';
import { NavItem } from './NavItemLink';
import { NavHelpIcon } from '@imus/base-ui';
import { Global, css } from '@emotion/react';
import { useInitBeacon } from 'components/hooks';

const useBeaconLocationSync = () => {
  const history = useHistory();
  useEffect(() => {
    history.listen((location) => {
      if (window.Beacon) {
        window.Beacon('event', {
          type: 'page-viewed',
          url: document.location.href,
          title: document.title,
        });
        window.Beacon('suggest');
        if (location && location.pathname === '/') {
          window.Beacon('navigate', '/');
        }
      }
    });
  });
};

const beaconStyles = css`
  .BeaconContainer {
    @media (min-width: 600px) {
      left: 60px !important;
      /* box-shadow: rgb(0 0 0 / 6%) 0px 0px 0px 1px, */
      /*   0px 3px 5px rgba(0, 0, 0, 0.06) !important; */
      bottom: 60px !important;
    }
  }

  .c-BeaconCloseButton {
    & > * {
      pointer-events: none;
    }
  }
`;

export const HelpCenterButton = () => {
  useBeaconLocationSync();
  const { t } = useTranslation();
  const location = useLocation();
  const { opened, ready } = useInitBeacon();
  useEffect(() => {
    const { help } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (help === 'open' && ready && !opened && window.Beacon) {
      window.Beacon('toggle');
    }
  }, [ready]);

  return (
    <>
      <Portal
        node={document.getElementById('app') || document.getElementById('root')}
      >
        <AnimatePresence>
          {opened ? (
            <AnimatedDim
              style={{ zIndex: 104 }}
              onMouseDown={() => {
                if (window.Beacon) {
                  window.Beacon('close');
                }
              }}
            />
          ) : null}
        </AnimatePresence>
      </Portal>
      <Global styles={beaconStyles} />
      <NavItem
        icon={<NavHelpIcon />}
        selected={opened}
        text={t('help-center')}
        disabled={!ready}
        onClick={() => {
          if (window.Beacon) {
            window.Beacon('reset');
            window.Beacon('toggle');
          }
        }}
      />
    </>
  );
};
