import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import MaskedInput from 'react-text-mask';
import type { WrappedFieldProps } from 'redux-form';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { entities } from 'imddata/actionTypes';
import type { EntityModels } from 'imddata';

import {
  useEntityActions,
  useEntityFileRequestProvider,
  useTrack,
} from 'imddata';
import styled from '@emotion/styled';

import {
  TracksDownloadButton,
  MiniPlayer,
  TracksUploader,
  LabelTextField,
  HelperText,
  ChangeTrigger,
  Button,
  Caption,
  LoadingIndicator,
} from '../../../..';
import DeleteAudioButton from '../DeleteAudioButton';

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TrackAudioAction = ({
  disabled,
  fileUpload,
  uploadStatus,
  onRemoveAudio,
  trackId,
}: {
  disabled?: boolean;
  fileUpload: ReturnType<typeof useEntityFileRequestProvider>;
  uploadStatus: EntityModels.Track['uploadStatus'];
  onRemoveAudio: () => void;
  trackId: number;
}) => {
  const { t } = useTranslation();
  const uploading = fileUpload && fileUpload.uploading;
  const deleted = fileUpload && fileUpload.deleted;
  const isUploaded =
    uploadStatus &&
    (uploadStatus === 'confirmed' || uploadStatus === 'finished');
  const dispatch = useDispatch();

  if (fileUpload && fileUpload.uploading) {
    return (
      <Button
        disabled={disabled}
        onClick={() => {
          dispatch(
            entities.tracks.actions.audio.upload.abort({
              payload: {
                id: trackId,
              },
            })
          );
        }}
        size="small"
        position="center"
        style={{ width: '100%' }}
        text={t('abort-upload')}
      />
    );
  }

  if ((!uploading && !isUploaded) || deleted) {
    return (
      <TracksUploader
        key="uploader"
        multiple={false}
        trackId={trackId}
        message={t('upload-for-track')}
        style={{ width: '100%' }}
      >
        <Button
          position="center"
          primary={true}
          size="small"
          disabled={disabled}
          style={{ width: '100%' }}
          text={t('upload-track')}
          onClick={() => {
            // fix requiring on click for styles?
          }}
        />
      </TracksUploader>
    );
  }

  if (disabled || uploadStatus === 'confirmed') return null;

  return (
    <DeleteAudioButton
      position="center"
      testId="AudioFile-Delete"
      size="small"
      text={t('delete-audio')}
      onClick={onRemoveAudio}
    />
  );
};

type Props = {
  onChange: (e: any, v: any) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  warning?: string;
  trackId: number;
  className?: string;
  audioDuration: number;
  style?: React.CSSProperties;
  components?: React.ComponentProps<typeof LabelTextField>['components'];
  onClickHelperButton: React.ComponentProps<
    typeof LabelTextField
  >['onClickHelp'];
};

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  & > * {
    flex: 1;
  }
`;

const OffestInputStyled = styled(MaskedInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 4px;
  gap: 12px;

  width: 56px;
  height: 32px;

  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: rgba(0, 0, 0, 0.8);
`;

const OffsetRow = styled.div`
  margin: 0 0 0 auto;
  text-align: right;
  gap: 8px;
  display: flex;
  align-items: center;
`;

const OffsetField = ({
  input,
  mask,
}: WrappedFieldProps & {
  mask: React.ComponentProps<typeof MaskedInput>['mask'];
}) => {
  const { t } = useTranslation();
  return (
    <OffsetRow>
      <Caption>{t('preview-start')}</Caption>
      <OffestInputStyled {...input} mask={mask} />
    </OffsetRow>
  );
};

const TrackAudioFile: React.FC<Props> = ({
  required,
  disabled,
  error,
  warning,
  audioDuration,
  trackId,
  onChange,
  onClickHelperButton,
  className,
  style,
  components,
}) => {
  const { t } = useTranslation();

  const { entry } = useTrack({ id: trackId });
  const fileUpload = useEntityFileRequestProvider({
    entity: 'tracks',
    id: trackId,
  });
  // TODO: IMPORTANT Fix api
  // @ts-ignore
  const { remove: removeAudio } = useEntityActions(['tracks', 'audio']);

  const handleRemoveAudio = useCallback(() => {
    removeAudio({ id: trackId });
  }, [trackId]);

  const audioFile = entry?.duration !== null;

  const uploadStatus = entry ? entry.uploadStatus : 'none';

  const failedRequest = fileUpload && fileUpload.failed;
  const failedStatus = uploadStatus === 'failed';

  const finalError = failedStatus
    ? t(entry?.uploadStatusMessage || '')
    : failedRequest
      ? t('failed-to-validate-and-upload-audio')
      : t(error || '');

  return (
    <div className={className} style={style}>
      <ChangeTrigger value={uploadStatus} onChange={onChange} />

      <div>
        <LabelTextField
          components={components}
          label={t('audio-file')}
          meta={{ error, touched: true }}
          required={required}
          onClickHelp={onClickHelperButton}
        />

        <MiniPlayer
          disabled={!audioFile || failedStatus}
          duration={audioDuration}
          trackToBePlayed={trackId}
          leftChild={
            <>
              {fileUpload && !!fileUpload.uploading && (
                <StatusRow data-test-id="progress-upload">
                  <LoadingIndicator />
                  <Caption>
                    {t('uploading')}{' '}
                    {fileUpload.progress ? `${fileUpload.progress}%` : ''}
                  </Caption>
                </StatusRow>
              )}

              {uploadStatus === 'confirmed' && (
                <StatusRow data-test-id="audio-confirmed">
                  <LoadingIndicator />
                  <Caption>{t('verifying')}</Caption>
                </StatusRow>
              )}
            </>
          }
          rightChild={
            !(disabled || failedStatus || !audioFile || audioDuration < 60) ? (
              <Field
                name="prelisteningOffset"
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                component={OffsetField}
              />
            ) : (
              <></>
            )
          }
        />
      </div>

      <div>
        <Buttons>
          <TrackAudioAction
            onRemoveAudio={handleRemoveAudio}
            fileUpload={fileUpload}
            trackId={trackId}
            disabled={disabled}
            uploadStatus={uploadStatus}
          />
          {entry?.fileId && uploadStatus === 'finished' && (
            <TracksDownloadButton
              title={entry.title}
              version={entry.version}
              isMastered={entry.isMastered}
              fileId={entry.fileId}
            />
          )}
        </Buttons>
      </div>

      <HelperText
        errorText={error === 'required' ? true : finalError}
        warningText={warning}
        text={
          uploadStatus !== 'finished'
            ? t('track-helptext-short-audio-upload')
            : ''
        }
      />
    </div>
  );
};

export default TrackAudioFile;
