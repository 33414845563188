export default {
  track:
    'M13,4 L13,14 C13,16.2091,11.2091,18,9,18 C6.79086,18,5,16.2091,5,14 C5,11.7909,6.79086,10,9,10 C9.72857,10,10.4117,10.1948,11,10.5351 L11,2 L13,2 L16,2 L16,4 L13,4 Z M9,16 C10.1046,16,11,15.1046,11,14 C11,12.8954,10.1046,12,9,12 C7.89543,12,7,12.8954,7,14 C7,15.1046,7.89543,16,9,16 Z',
  album:
    'M10,18 C5.58172,18,2,14.4183,2,10 C2,5.58172,5.58172,2,10,2 C14.4183,2,18,5.58172,18,10 C18,14.4183,14.4183,18,10,18 Z M10,14 C12.2091,14,14,12.2091,14,10 C14,7.79086,12.2091,6,10,6 C7.79086,6,6,7.79086,6,10 C6,12.2091,7.79086,14,10,14 Z M10,11.5 C9.17157,11.5,8.5,10.8284,8.5,10 C8.5,9.17157,9.17157,8.5,10,8.5 C10.8284,8.5,11.5,9.17157,11.5,10 C11.5,10.8284,10.8284,11.5,10,11.5 Z',
  artist:
    'M8,13 L11,13 L11,16 L10,16 L10,19 L9,19 L9,16 L8,16 L8,13 Z M8.56388,1 L10.4361,1 C11.3276,1,11.6509,1.09283,11.9768,1.26713 C12.3028,1.44144,12.5586,1.69723,12.7329,2.02315 C12.9072,2.34908,13,2.67237,13,3.56389 L13,8 L6,8 L6,3.56389 C6,2.67237,6.09283,2.34908,6.26713,2.02315 C6.44144,1.69723,6.69723,1.44144,7.02315,1.26713 C7.34908,1.09283,7.67237,1,8.56388,1 Z M6,9 L13,9 L13,9.4908 C13,10.3167,12.9072,10.6509,12.7329,10.9768 C12.5586,11.3028,12.3028,11.5586,11.9768,11.7329 C11.6509,11.9072,11.3167,12,10.4908,12 L8.5092,12 C7.6833,12,7.34908,11.9072,7.02315,11.7329 C6.69723,11.5586,6.44144,11.3028,6.26713,10.9768 C6.09283,10.6509,6,10.3167,6,9.4908 L6,9 Z',
  contributor: '',
  publisher: '',
  p: 'M6.6,11.5 L6.6,4 L9.6,4 C13.6295,4,14.5975,5.12169,14.6,7.21429 C14.6,10.0938,12.8894,11.3259,8.6,11.4826 L8.6,16 L6.6,16 L6.6,11.5 Z M8.6,9.75 L9.1,9.75 C11.6128,9.75,12.6,9.11421,12.6,7.46429 C12.5988,6.34824,12.1148,5.75,10.1,5.75 L8.6,5.75 L8.6,9.75 Z M10,20 C4.47715,20,0,15.5228,0,10 C0,4.47715,4.47715,0,10,0 C15.5228,0,20,4.47715,20,10 C20,15.5228,15.5228,20,10,20 Z M10,19 C14.9706,19,19,14.9706,19,10 C19,5.02944,14.9706,1,10,1 C5.02944,1,1,5.02944,1,10 C1,14.9706,5.02944,19,10,19 Z',
  c: 'M14.2577,8.25 L12.0841,8.25 C11.6561,7.47791,10.9226,7,10,7 C8.5,7,7.5,8.26316,7.5,10 C7.5,11.7368,8.5,13,10,13 C10.9226,13,11.6561,12.5221,12.0841,11.75 L14.2577,11.75 C13.6815,13.7051,12.1175,15,10,15 C7.3,15,5.5,12.8947,5.5,10 C5.5,7.10526,7.3,5,10,5 C12.1175,5,13.6815,6.29491,14.2577,8.25 Z M10,20 C4.47715,20,0,15.5228,0,10 C0,4.47715,4.47715,0,10,0 C15.5228,0,20,4.47715,20,10 C20,15.5228,15.5228,20,10,20 Z M10,19 C14.9706,19,19,14.9706,19,10 C19,5.02944,14.9706,1,10,1 C5.02944,1,1,5.02944,1,10 C1,14.9706,5.02944,19,10,19 Z',
};
