import { camelize } from 'humps';
import { uniq, keys, groupBy, map } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as furActions } from '../../../actionTypes/ui/fieldUpdateRequests';
import type { FieldUpdateRequestCheck } from '../../../reducers/ui/fieldUpdateRequests';
import type { EntityModels, ReduxState } from '../../../types';
import type { NestDefinition } from '../base';
import {
  useEntriesProvider,
  useFetchEntity,
  useDeleteEntity,
  useUpdateEntity,
  useCreateEntity,
} from '../base';
import { useEntry, useEntries } from '../hooks';
import { useQuery } from '../useQueryHash';
import { useBundle } from './deliveryBundles';

const empty: [] = [];

export const FIELD_UPDATE_REQUEST_RESOLVED_BY_ADMIN =
  'UI_STRING:[FIELD_UPDATE_REQUEST_RESOLVED_BY_ADMIN]';

export const defaultFieldUpdateRequestQuery = { with: 'fields' };
export const defaultFieldUpdateRequestNest: NestDefinition = [
  {
    key: 'fields',
    entity: 'fieldUpdateRequestItems',
  },
];

type FieldUpdateRequestNested = EntityModels.Nest<
  EntityModels.FieldUpdateRequest,
  { fields: EntityModels.FieldUpdateRequestChange[] }
>;

type IssueList = { message: string }[];

type MappedField = EntityModels.FieldUpdateRequestChange & {
  issueList: IssueList;
  status: EntityModels.FieldUpdateRequest['status'];
};

export type FieldUpdateRequestMappedField = MappedField;

const mapFieldUpdateRequestField =
  (status: EntityModels.FieldUpdateRequest['status']) =>
  (change: EntityModels.FieldUpdateRequestChange): MappedField => {
    const [, resource] = change.resourceType.split(':');
    let issueList = null;
    try {
      if (change?.message) {
        const { requests } = JSON.parse(change.message);
        issueList = requests;
      }
    } catch {
      if (change?.message) {
        issueList = [{ message: change.message }];
      }
    }
    return {
      ...change,
      issueList,
      resourceType: camelize(resource),
      name: camelize(change.name),
      status,
    };
  };

export const mapFurFields = (
  entry: FieldUpdateRequestNested
): EntityModels.Nest<
  EntityModels.FieldUpdateRequest,
  { fields: MappedField[] }
> => {
  return {
    ...entry,
    fields: entry?.fields?.map(mapFieldUpdateRequestField(entry.status)),
  };
};

export type UseFieldUpdateRequestsEntry = Omit<
  FieldUpdateRequestNested,
  'fields'
> & {
  fields?: MappedField[];
};

export function useFieldUpdateRequest({
  nest = defaultFieldUpdateRequestNest,
  query = defaultFieldUpdateRequestQuery,
  id,
  passive,
}: {
  nest?: Parameters<typeof useEntry>[0]['nest'];
  query?: Parameters<typeof useEntry>[0]['query'];
  id: Parameters<typeof useEntry>[0]['id'];
  passive?: Parameters<typeof useEntry>[0]['passive'];
}) {
  const data = useEntry<FieldUpdateRequestNested>({
    entity: 'fieldUpdateRequests',
    id,
    query,
    nest,
    passive,
  });

  const entry: UseFieldUpdateRequestsEntry | null = useMemo(() => {
    if (data.entry) {
      return mapFurFields(data.entry);
    }

    return null;
  }, [data.entry]);

  return {
    ...data,
    entry,
  };
}

export function useFieldUpdateRequests(
  props: Omit<Parameters<typeof useEntries>[0], 'entity' | 'nest'>
) {
  const { entries, ...rest } = useEntries<FieldUpdateRequestNested>({
    entity: 'fieldUpdateRequests',
    nest: defaultFieldUpdateRequestNest,
    ...props,
  });

  const mappedWithStatus: UseFieldUpdateRequestsEntry[] = useMemo(
    () =>
      entries?.map((entry) => ({
        ...entry,
        fields: entry.fields?.map(mapFieldUpdateRequestField(entry.status)),
      })),
    [entries]
  );
  return {
    entries: mappedWithStatus,
    ...rest,
  };
}

export function useCountActiveFieldUpdateRequests() {
  const query = useMemo(
    () => ({ 'filter.status': 'requested', with: 'fields' }),
    []
  );
  const { queryHash } = useQuery({ wait: 0, query });

  useFetchEntity({ entity: 'fieldUpdateRequests', query, queryHash });

  const count = useSelector((state: ReduxState) => {
    const furEntities = state.entities.fieldUpdateRequests.entities || {};
    return Object.values(furEntities).reduce(
      (acc, entry) => (entry?.status === 'requested' ? acc + 1 : acc),
      0
    );
  });

  return count;
}

const sumIssues = (a: number, f: MappedField) => a + f.issueList.length;

type EntityChanges = Record<string, MappedField[]>;

const countChanges = (entityChanges?: EntityChanges) => {
  if (!entityChanges) return 0;
  return (
    Object.values(entityChanges).reduce(
      (acc, fields) => acc + fields.reduce(sumIssues, 0),
      0
    ) || 0
  );
};

const sumChanged =
  (furChecks: Record<string, FieldUpdateRequestCheck>) =>
  (acc: number, change: MappedField) =>
    acc +
    Object.values(furChecks[change.id]?.issues || {}).filter(Boolean).length;

const countChecker =
  (furChecks: Record<string, FieldUpdateRequestCheck>) =>
  (entityChanges?: EntityChanges) => {
    if (!entityChanges) return 0;
    return (
      Object.values(entityChanges).reduce(
        (acc, fields) => acc + fields.reduce(sumChanged(furChecks), 0),
        0
      ) || 0
    );
  };

const getIds = (entityChanges: EntityChanges | undefined) => {
  return entityChanges ? keys(entityChanges).map(Number) : empty;
};
type EntitiesNames =
  | 'releaseNames'
  | 'artistNames'
  | 'contributorNames'
  | 'trackNames';

type Entities =
  | 'tracks'
  | 'releases'
  | 'publishers'
  | 'performers'
  | 'nonPerformingContributors'
  | 'artists';

type SupportedEntities = Entities | EntitiesNames;

type GrouppedChanges = Partial<Record<SupportedEntities, EntityChanges>>;

const useEntityIds = (changes: GrouppedChanges = {}) => {
  const releaseNamesIds = getIds(changes?.releaseNames);

  const { entries: releaseNames } =
    useEntriesProvider<EntityModels.ReleaseName>({
      entity: 'releaseNames',
      ids: releaseNamesIds,
      passive: !releaseNamesIds,
    });

  const artistNamesIds = getIds(changes?.artistNames);

  const { entries: artistNames } = useEntriesProvider<EntityModels.ArtistName>({
    entity: 'artistNames',
    ids: artistNamesIds,
    passive: !artistNamesIds,
  });

  const contributorNamesIds = getIds(changes?.contributorNames);

  const { entries: contributorNames } =
    useEntriesProvider<EntityModels.ContributorName>({
      entity: 'contributorNames',
      ids: contributorNamesIds,
      passive: !contributorNamesIds,
    });

  const trackNamesIds = getIds(changes?.trackNames);

  const { entries: trackNames } = useEntriesProvider<EntityModels.TrackName>({
    entity: 'trackNames',
    ids: trackNamesIds,
    passive: !trackNamesIds,
  });

  return useMemo(() => {
    if (!changes)
      return {
        releases: [],
        publishers: [],
        tracks: [],
        contributors: [],
        artists: [],
      };
    return {
      releases: uniq([
        ...getIds(changes.releases),
        ...releaseNames.map(({ releaseId }) => releaseId),
      ]),
      publishers: getIds(changes.publishers),
      tracks: uniq([
        ...getIds(changes.tracks),
        ...trackNames.map(({ trackId }) => trackId),
      ]),
      contributors: uniq([
        ...getIds(changes.nonPerformingContributors),
        ...getIds(changes.performers),
        ...contributorNames.map(({ contributorId }) => contributorId),
      ]),
      artists: uniq([
        ...getIds(changes.artists),
        ...artistNames.map(({ artistId }) => artistId),
      ]),
    };
  }, [changes, releaseNames, artistNames, contributorNames, trackNames]);
};

const useHandlers = (id?: string) => {
  const updateCreateQuery = useMemo(() => ({ requestId: id }), [id]);

  const { updateEntry: updateDraft, request: updateRequestState } =
    useUpdateEntity({
      id,
      entity: 'fieldUpdateRequests',
    });
  const { deleteEntry: deleteDraft } = useDeleteEntity({
    id,
    entity: 'fieldUpdateRequests',
  });
  const { createEntry: createFieldFeedback } = useCreateEntity({
    query: updateCreateQuery,
    entity: 'fieldUpdateRequestItems',
  });

  const { updateEntry: updateFieldFeedback } = useUpdateEntity({
    query: updateCreateQuery,
    entity: 'fieldUpdateRequestItems',
  });

  const { deleteEntry: deleteFieldFeedback } = useDeleteEntity({
    query: updateCreateQuery,
    entity: 'fieldUpdateRequestItems',
  });

  const changeFeedback = useCallback(
    ({ id: changeId, form: formId, data }) => {
      if (!changeId) {
        createFieldFeedback({ data, formId });
        return;
      }

      if (!data || !data.message) {
        deleteFieldFeedback({ id: changeId /* , formId */ });
        return;
      }
      updateFieldFeedback({
        id: changeId,
        data,
        formId,
      });
    },
    [updateCreateQuery]
  );

  const updateRequest = useCallback(
    ({ form: formId, data }) => {
      updateDraft({ data, formId });
    },
    [id]
  );

  const deleteRequest = useCallback(
    ({ /* form: formId, */ data }) => {
      deleteDraft({ data /* formId */ });
    },
    [id]
  );

  return {
    changeFeedback,
    updateRequest,
    deleteRequest,
    updateRequestState,
  };
};

type ValidatorRequirement = {
  id: string | number;
  defaultNames: { id: number | string }[];
};

const createEntryValidator = ({
  entities: [entity, nameEntity],
  enableIssueCheck,
  checkedFieldIssues,
  changes,
}: {
  entities: [Entities, EntitiesNames];
  enableIssueCheck: boolean;
  checkedFieldIssues: Record<string, FieldUpdateRequestCheck>;
  changes: GrouppedChanges;
}) => {
  return <VR extends ValidatorRequirement>({ id, defaultNames }: VR) => {
    if (!enableIssueCheck || !checkedFieldIssues || !changes) return false;

    const issueCount =
      (changes[entity]?.[id]?.reduce(sumIssues, 0) || 0) +
      defaultNames.reduce(
        (acc: number, name) =>
          acc + (changes[nameEntity]?.[name.id]?.reduce(sumIssues, 0) || 0),
        0
      );

    const checkedCount =
      (changes[entity]?.[id]?.reduce(sumChanged(checkedFieldIssues), 0) || 0) +
      defaultNames.reduce(
        (acc: number, name) =>
          acc +
          (changes[nameEntity]?.[name.id]?.reduce(
            sumChanged(checkedFieldIssues),
            0
          ) || 0),
        0
      );
    return issueCount === checkedCount;
  };
};

const emptycheck = {};

type CheckCouner = {
  total: number;
  releases: number;
  contributors: number;
  artists: number;
  tracks: number;
  publishers: number;
};

export const useFieldUpdateRequestDeliveryBundleChanges = ({
  id,
  enableIssueCheck = false,
}: {
  id: string;
  enableIssueCheck?: boolean;
}) => {
  const {
    entry,
    request: { loading, hasMorePages, ...request },
  } = useFieldUpdateRequest({ id });

  const {
    entry: bundle,
    request: { loading: loadingBundle, loaded: loadedBundle },
  } = useBundle({ passive: !entry, id: entry?.contextId });

  const { entry: user } = useEntry<EntityModels.User>({
    entity: 'users',
    id: bundle?.userId,
    passive: !bundle,
  });

  const userLocale = user?.locale;

  const changes: GrouppedChanges = useMemo(() => {
    if (!entry || !entry.fields) return {};
    const grouppedByType = groupBy((change: MappedField) => {
      const type = camelize(change.resourceType);
      return type;
    }, entry.fields);

    return map(
      groupBy((change: MappedField) => change.resourceId),
      // @ts-ignore
      grouppedByType
    );
  }, [entry]);

  const entityIds = useEntityIds(changes);

  const counter = useMemo<Record<string, number>>(() => {
    if (!changes) return {};
    const result = {
      releases:
        countChanges(changes.releases) + countChanges(changes.releaseNames),
      tracks: countChanges(changes.tracks) + countChanges(changes.trackNames),
      publishers: countChanges(changes.publishers),
      contributors:
        countChanges(changes.nonPerformingContributors) +
        countChanges(changes.performers) +
        countChanges(changes.contributorNames),
      artists:
        countChanges(changes.artists) + countChanges(changes.artistNames),
    };

    return {
      total: Object.values(result).reduce((acc, value) => acc + value, 0),
      ...result,
    };
  }, [changes]);

  const checkedFieldIssues = useSelector((state: ReduxState) => {
    return entry ? state.ui.fieldUpdateRequests[entry.id] : emptycheck;
  });

  const checkedCounter = useMemo<CheckCouner>(() => {
    if (!changes)
      return {
        releases: 0,
        tracks: 0,
        publishers: 0,
        contributors: 0,
        artists: 0,
        total: 0,
      };
    const countChecked = countChecker(checkedFieldIssues || {});
    const result = {
      releases:
        countChecked(changes.releases) + countChecked(changes.releaseNames),
      tracks: countChecked(changes.tracks) + countChecked(changes.trackNames),
      publishers: countChecked(changes.publishers),
      contributors:
        countChecked(changes.nonPerformingContributors) +
        countChecked(changes.performers) +
        countChecked(changes.contributorNames),
      artists:
        countChecked(changes.artists) + countChecked(changes.artistNames),
    };

    return {
      total: Object.values(result).reduce((acc, value) => acc + value, 0),
      ...result,
    };
  }, [changes, checkedFieldIssues]);

  // TODO handle names
  const isTrackValid = useMemo(
    () =>
      createEntryValidator({
        entities: ['tracks', 'trackNames'],
        changes,
        checkedFieldIssues,
        enableIssueCheck,
      }),
    [changes, checkedFieldIssues]
  );
  const isArtistValid = useMemo(
    () =>
      createEntryValidator({
        entities: ['artists', 'artistNames'],
        changes,
        checkedFieldIssues,
        enableIssueCheck,
      }),
    [changes, checkedFieldIssues]
  );

  const handlers = useHandlers(entry?.id);

  const wasResolvedByAdmins =
    entry?.adminMessage === FIELD_UPDATE_REQUEST_RESOLVED_BY_ADMIN;

  const dispatch = useDispatch();

  const checkFieldIssue = useMemo(
    () =>
      enableIssueCheck && entry
        ? (changeId: string, issueIndex: number, checked: boolean) => {
            dispatch(
              furActions.checkIssue({
                changeId,
                issueIndex,
                checked,
                id: entry.id,
              })
            );
          }
        : null,
    [entry?.id, enableIssueCheck]
  );

  return {
    userLocale,
    checkedCounter,
    wasResolvedByAdmins,
    entry,
    bundle,
    changes,
    entityIds,
    isTrackValid,
    isArtistValid,
    counter,
    checkFieldIssue,
    request: {
      ...request,
      loaded: request.loaded && loadedBundle,
      loading: loading || loadingBundle,
    },
    ...handlers,
  };
};

const emptyChanges: GrouppedChanges = {};

// TODO: [REMOVE_BUNDLE_STATUSES] remove after next API deployments
const isBundleReady = (bundleStatus: EntityModels.DeliveryBundle['status']) =>
  ['paid', 'ready'].indexOf(bundleStatus) >= 0;

export const useAdminFieldUpdateRequest = ({
  bundleId,
}: {
  bundleId: number;
}) => {
  const { entry: bundle } = useEntry<EntityModels.DeliveryBundle>({
    entity: 'deliveryBundles',
    id: bundleId,
  });

  const activeEntryQuery = useMemo(
    () => ({
      with: 'fields',
      sortBy: 'created_at,desc',
      'filter.context_type': 'delivery_bundle',
      'filter.context_id': bundleId,
    }),
    []
  );
  const { queryHash: activeEntriesHash } = useQuery({
    wait: 0,
    query: activeEntryQuery,
  });

  const {
    reload: refreshDrafts,
    entries: entries,
    request: { loaded: activeEntriesLoaded },
  } = useFieldUpdateRequests({
    query: activeEntryQuery,
    queryHash: activeEntriesHash,
    passive: !bundleId,
  });

  const {
    createEntry,
    request: { errors },
    createdId: createdDraftId,
  } = useCreateEntity({
    requestStoreKey: `fur-draft-${bundleId}`,
    entity: 'fieldUpdateRequests',
  });

  const { entry: createdDraft } = useFieldUpdateRequest({
    id: createdDraftId,
    passive: !createdDraftId,
  });

  const activeEntry =
    createdDraft ||
    entries.find((dr) => dr.status === 'requested') ||
    entries.find((dr) => dr.status === 'draft') ||
    entries.find((dr) => dr.status === 'submitted') ||
    entries.find((dr) => dr.status === 'closed');

  const closed = entries.find((dr) => dr.status === 'closed');

  const submitted = entries.find((dr) => dr.status === 'submitted');

  useEffect(() => {
    if (activeEntry && activeEntry.status === 'closed') {
      refreshDrafts();
    }
  }, [activeEntry?.status]);

  const createDraft = useMemo(() => {
    if (
      !errors &&
      bundle &&
      isBundleReady(bundle.status) &&
      (!activeEntry || activeEntry.status === 'closed') &&
      activeEntriesLoaded
    ) {
      return () =>
        createEntry({
          data: {
            title: 'title',
            contextType: 'delivery_bundle',
            contextId: bundleId,
            customerId: bundle.customerId,
            userId: bundle.userId,
          },
        });
    }
    return null;
  }, [activeEntry, errors, activeEntriesLoaded, bundle?.status]);

  const refresh = useCallback(() => {
    if (activeEntriesLoaded && bundle && isBundleReady(bundle.status)) {
      refreshDrafts();
    }
  }, [activeEntriesLoaded]);

  useEffect(() => {
    if (
      activeEntriesLoaded &&
      !activeEntry &&
      bundle?.states?.updateRequest?.length
    ) {
      refresh();
    }
  }, [bundle?.states?.updateRequest]);

  const changes: GrouppedChanges = useMemo(() => {
    if (activeEntry || submitted || closed) {
      const fields: MappedField[] = activeEntry
        ? (activeEntry.fields || []).map((change) => ({
            ...change,
            status: activeEntry.status,
          }))
        : [];

      const grouppedByType = groupBy((change) => {
        const type = camelize(change.resourceType);
        return type;
      }, fields);

      return map(
        groupBy((change: MappedField) => change.resourceId),
        // @ts-ignore
        grouppedByType
      ) as GrouppedChanges;
    }
    return emptyChanges;
  }, [activeEntry]);

  const entityIds = useEntityIds(changes);
  const handlers = useHandlers(activeEntry?.id);

  const {
    updateEntry: updateActive,
    request: { updating },
  } = useUpdateEntity({
    entity: 'fieldUpdateRequests',
  });

  const resolveAndCloseRequest = useCallback(
    (props) => {
      handlers.updateRequest({
        ...props,
        data: {
          status: 'closed',
          adminMessage: FIELD_UPDATE_REQUEST_RESOLVED_BY_ADMIN,
        },
      });
    },
    [handlers.updateRequest]
  );

  const closeRequest = useCallback(
    (props) => {
      updateActive({
        ...props,
        id: submitted?.id || activeEntry?.id,
        data: { status: 'closed' },
      });
    },
    [submitted, activeEntry]
  );

  const { entry: user } = useEntry<EntityModels.User>({
    entity: 'users',
    id: activeEntry?.userId,
    passive: !activeEntry,
  });
  const userLocale = user?.locale;

  return {
    resolveAndCloseRequest,
    userLocale,
    entityIds,
    entry: activeEntry,
    createDraft,
    updating,
    readyForReview: !!createDraft,
    submittedEntry: submitted || closed,
    changes,
    closeRequest,
    refresh,
    ...handlers,
  };
};
