import { getBaseSubscriptionId } from 'components';
import type { SubscriptionId } from 'imddata/types/entities';

export * from './ConfirmInstantUpgradeWindow';
export * from './useFeatureList';

export const getSubCardVariant = (
  sid?: SubscriptionId | 'free'
):
  | 'free'
  | 'vip'
  | 'tier-1'
  | 'tier-2'
  | 'music-analytics-legacy'
  | 'professional-unlimited' => {
  if (!sid) return 'free';
  if (sid === 'free') return 'free';
  if (getBaseSubscriptionId(sid) === 'trial') return 'tier-1';
  if (getBaseSubscriptionId(sid) === 'tier-1') return 'tier-1';
  if (getBaseSubscriptionId(sid) === 'tier-2') return 'tier-2';
  if (sid === 'music-analytics') return 'music-analytics-legacy';
  if (sid === 'vip') return 'vip';
  if (
    sid === 'professional-unlimited' ||
    sid === 'professional-unlimited-analytics'
  )
    return 'professional-unlimited';

  return 'free';
};
