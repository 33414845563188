import React, { useContext, useMemo, useCallback } from 'react';
import moment from 'moment';
import { isSameDay } from 'react-dates';
import 'react-dates/initialize';
import {
  useRelease,
  useCustomerPrices,
  useCalendar,
  AppContext,
} from 'imddata';
import DatePicker from './components/DatePicker';
import buildDateRanges from './helpers/buildDateRanges';
import { DatePickerInput } from '../../fields/DatePickerInputField';

function useReleaseFlags({ id }) {
  const { entry: { isClassical, isHipHop, isElectronic } = {} } = useRelease({
    id,
    passive: true,
  });
  return {
    isClassical,
    isHipHop,
    isElectronic,
  };
}

function ReleaseDateSelector({
  onClickHelp,
  onChange,
  onFocus,
  onBlur,
  noOptions,
  disabled,
  planId,
  label,
  value,
  className,
  deliveryOptions,
  deliveryDates,
  errorText,
  placeholder,
}) {
  const dateRanges = useMemo(() => {
    if (noOptions) {
      return null;
    }
    if (deliveryDates && deliveryOptions && deliveryOptions.length) {
      return buildDateRanges({
        deliveryOptions,
        planId,
        deliveryDates,
      });
    }
    return null;
  }, [deliveryOptions, deliveryDates]);

  const handleDateSelected = useCallback(
    (date) => {
      onChange(date);
    },
    [onChange]
  );

  const isDayBlocked = useCallback(
    (day) => {
      const curDay = day.format('YYYY-MM-DD');
      const optionDay = dateRanges && dateRanges[curDay];
      const { firstAvailbleDate } = dateRanges || {};

      return (
        isSameDay(day, moment()) ||
        (optionDay && !optionDay.isAvailable) ||
        (firstAvailbleDate && firstAvailbleDate.isAfter(day))
      );
    },
    [dateRanges]
  );

  const date = value ? moment(value) : null;

  const renderDatePicker = useCallback(
    ({ onChange: onDateChange }) => (
      <DatePicker
        onDateChange={onDateChange}
        dateRanges={dateRanges}
        planId={planId}
        noOptions={noOptions}
        isDayBlocked={isDayBlocked}
        date={date}
      />
    ),
    [dateRanges, planId, noOptions, isDayBlocked, date]
  );

  return (
    <div
      data-test-id="ReleaseDateSelector"
      data-test-value={date ? date.format('x') : 'no-value'}
      className={className}
    >
      {(disabled || dateRanges || noOptions) && (
        <DatePickerInput
          onClickHelperButton={onClickHelp}
          placeholder={placeholder}
          value={date}
          renderDropdown={renderDatePicker}
          floatingLabelText={label}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={handleDateSelected}
          errorText={errorText}
        />
      )}
    </div>
  );
}

const CustomerReleaseDateSelector = (props) => {
  const { isClassical } = useReleaseFlags({ id: props.releaseId });
  const {
    request: { loaded: loadedCalendar },
    data: calendarData = {
      productsAvailability: { byGroup: { delivery: {} } },
    },
  } = useCalendar();

  const {
    data: prices,
    request: { loaded },
  } = useCustomerPrices();

  const deliveryDates = loadedCalendar && calendarData.dates;

  const deliveryAvailability =
    loadedCalendar && calendarData.productsAvailability.byGroup.delivery;

  const deliveryOptionKeys =
    loaded && prices && prices.entities.products.deliveryTypes.items;

  const productItems = loaded && prices && prices.entities.productItems;

  const deliveryOptions = useMemo(() => {
    if (deliveryOptionKeys && deliveryAvailability && productItems) {
      return deliveryOptionKeys.map((optionKey) => {
        const deliveryOption = productItems[optionKey];
        const from =
          deliveryAvailability[optionKey] &&
          deliveryAvailability[optionKey].from;
        const startDay = props.offset
          ? moment(from).add(props.offset, 'days').format('YYYY-MM-DD')
          : from;
        return {
          ...deliveryOption,
          startDay,
          isAvailable:
            deliveryOption.price &&
            !(isClassical && deliveryOption.id === 'ultraexpressdelivery'),
        };
      });
    }
    return null;
  }, [
    deliveryOptionKeys,
    props.offset,
    deliveryAvailability,
    productItems,
    isClassical,
  ]);

  return (
    <ReleaseDateSelector
      {...props}
      disabled={!loaded || !loadedCalendar || props.disabled}
      deliveryDates={deliveryDates}
      deliveryOptions={deliveryOptions}
    />
  );
};

export default (props) => {
  const app = useContext(AppContext);
  if (app === 'front') {
    return <CustomerReleaseDateSelector {...props} />;
  }
  return <ReleaseDateSelector {...props} noOptions={true} />;
};
