import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { themeLight } from 'imdui';

export const ExceptionRenderer = () => (
  <Translation>
    {(t) => (
      <div
        style={{
          padding: '20px',
          display: 'flex',
          flex: '1',
          background: '#f2f2f2',
          color: themeLight.state.error,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {t('app-visual-component-failed')}
        </span>
        <br />
      </div>
    )}
  </Translation>
);

type State = {
  eventId?: string | null;
  error?: any;
};

type Props = {
  extra?: any;
  onCatch?: (e: any) => void;
  errorComponent?: React.ComponentType<any>;
  children: React.ReactNode;
  componentName?: string;
};

class ErrorBoundary extends Component<Props, State> {
  state = { error: null, eventId: null };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const { componentName, onCatch, extra } = this.props;
    if (window.Sentry) {
      window.Sentry.withScope((scope: any) => {
        scope.setExtras({ errorInfo });
        scope.setExtras({ componentName });
        scope.setExtras({ extra });
        const eventId = window.Sentry?.captureException(error);
        this.setState({ eventId });
      });
    }

    if (window && window.analytics) {
      window.analytics.track('[FRONTEND_UI_ERROR]', { error, errorInfo });
    }
    if (onCatch) {
      onCatch(error);
    }
  }

  handleUnsetError = () => {
    this.setState({ error: false, eventId: null });
  };

  render() {
    const { children, errorComponent } = this.props;
    const { error, eventId } = this.state;

    const Handler = errorComponent || ExceptionRenderer;

    if (typeof children === 'function') {
      return children(
        error ? { error, eventId, onUnsetError: this.handleUnsetError } : null
      );
    }

    if (error) {
      return <Handler error={error} />;
    }
    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
