//
import { eventChannel, END } from 'redux-saga';
import {
  throttle,
  cancel,
  race,
  call,
  cancelled,
  put,
  take,
} from 'redux-saga/effects';
import action from './action';

const PROGRESS_THROTTLE = 'ui/PROGRESS_THROTTLE';

function uploadWatcher(url, formData) {
  return eventChannel((emit) => {
    const xhr = new XMLHttpRequest();
    xhr.upload?.addEventListener(
      'progress',
      (evt) => {
        let percentComplete = null;
        if (evt.lengthComputable) {
          percentComplete = Math.round((evt.loaded * 100) / evt.total);
        }
        emit({
          type: 'progress',
          progress: percentComplete,
        });
      },
      false
    );
    xhr.addEventListener(
      'load',
      (event) => {
        // $FlowFixMe

        if (event.target?.status > 205) {
          emit({
            type: 'error',
            // $FlowFixMe
            statusCode: event.target.status,
            event,
          });
        } else {
          emit({
            type: 'loaded',
            // $FlowFixMe
            response: event.target?.responseText,
          });
        }
        emit(END);
      },
      false
    );
    xhr.addEventListener(
      'abort',
      (event) => {
        emit({
          type: 'abort',
          event,
        });
      },
      false
    );
    xhr.onerror = (event) => {
      emit({
        type: 'error',
        event,
      });
      emit(END);
    };

    xhr.open('POST', url, true);

    xhr.send(formData);

    return () => {
      xhr.abort();
    };
  });
}

export default function createS3FileUploader({
  uploadApiSaga,
  confirmApiSaga,
  actions,
  types,
}) {
  function* getS3Data(payload, meta) {
    try {
      const { id, file, originalFileName } = payload;
      const s3Data = yield call(
        uploadApiSaga,
        {
          id,
          data: {
            originalFileName: file.name || originalFileName,
          },
        },
        meta
      );
      if (s3Data.error) {
        yield put(
          actions.confirm.failure({
            error: s3Data.error,
            statusCode: 0,
            payload,
          })
        );
        return null;
      }
      return s3Data;
    } catch (error) {
      yield put(
        actions.confirm.failure({
          error,
          statusCode: 0,
          payload,
        })
      );
    }
    return null;
  }
  function* handleChannelUpload(
    url,
    data,
    fileData,
    id,
    meta,
    confirmPayload = {}
  ) {
    const uploadChannel = yield call(uploadWatcher, url, data);
    console.log('confirm payload', confirmPayload);
    try {
      while (true) {
        const event = yield take(uploadChannel);
        switch (event.type) {
          case 'progress':
            yield put(
              action(PROGRESS_THROTTLE, { progress: event.progress, id })
            );
            break;
          case 'loaded': {
            yield call(
              confirmApiSaga,
              { id, file: fileData, data: confirmPayload },
              meta
            );
            return;
          }
          case 'error': {
            yield put(
              actions.upload.failure({
                error: event,
                statusCode: 0,
                payload: {
                  id,
                },
              })
            );
            return;
          }
          default:
            return;
        }
      }
    } finally {
      if (yield cancelled()) {
        uploadChannel.close();
      }
    }
  }
  function* progressThrottle({ payload: { progress, id } }) {
    yield put(actions.updateProgress({ progress, id }));
  }
  return function* uploadFileS3({ payload, meta }) {
    const { id, file, confirmPayload = {} } = payload;
    const s3Data = yield call(getS3Data, payload, meta);
    if (!s3Data) return;
    const data = new FormData();
    Object.entries(s3Data.form_inputs).forEach(([key, value]) =>
      data.append(key, value)
    );
    data.append('file', file);
    const url = s3Data.form_attributes.action;
    const fileData = {
      fileId: s3Data.file_id,
      fileName: file.name,
    };
    const task = yield throttle(250, PROGRESS_THROTTLE, progressThrottle);
    yield race([
      call(handleChannelUpload, url, data, fileData, id, {}, confirmPayload),
      take((incomingAction) => {
        if (
          incomingAction.type === types.upload.ABORT &&
          incomingAction.payload.id === id[0]
        ) {
          return true;
        }
        return false;
      }),
    ]);
    yield cancel(task);
  };
}
