//
import * as R from 'ramda';
import {
  generateIdLens,
  reducerFactoryCreator,
  requestReducerFactory,
} from '../base';

const updateWith = (query = {}, state) => {
  const prevWith = state && state.with;
  if (!query?.with || typeof query?.with !== 'string') return prevWith || {};

  const withs = query.with
    .split(',')
    .reduce((acc, key) => ({ ...acc, [key]: true }), {});

  const newWiths = R.mergeRight(withs, prevWith || {});

  return newWiths;
};

export const fetchStateCreator = {
  success: (
    {
      statusCode,
      payload: { query } = { query: {} },
      response: { result } = {},
      responseDetails: {
        offset,
        total,
        perPage: responsePerPage,
        currentPage: responsePage,
      } = {},
      meta = {},
    } = {},
    state = {}
  ) => {
    const loadedQuery = R.omit(['page', 'perPage', 'filter.ids'], query);
    const perPage = query?.perPage || responsePerPage || 50;
    const currentPage = responsePage || query?.page;

    return {
      offset,
      with: updateWith(query, state),
      loadedQuery,
      loaded: true,
      loading: false,
      statusCode,
      errorMessage: null,
      errors: null,
      errorData: null,
      fetched: Date.now(),
      failed: false,
      entryCount: total,
      loadedPages:
        meta.preventKeysReset && state.loadedPages >= +currentPage
          ? state.loadedPages
          : currentPage
            ? +currentPage
            : 1,
      hasMorePages: offset
        ? true
        : result && result.length
          ? result.length >= perPage
          : state.hasMorePages,
    };
  },
  request: (action, state) => ({
    with: (state && state.with) || {},
    loading: true,
    failed: false,
    failedToFetch: false,
  }),
  failure: (action, state) => ({
    failed: true,
    loading: false,
    loaded: action.statusCode === 422 && state ? state.loaded : false,
    statusCode: action && action.statusCode,
    errorMessage: action && action.error && action.error.message,
    errorData: action && action.error && action.error.data,
    errors: action && action.error && action.error.errors,
    failedToFetch: action && action.error && action.error.failedToFetch,
    hasMorePages: false,
  }),
};

export const handleActionReducerFactory =
  requestReducerFactory(fetchStateCreator);

const fetchRequestReducerFactory = (fetchAction) => {
  const innerReducer = handleActionReducerFactory(fetchAction);
  const reducerFactory = reducerFactoryCreator(innerReducer);
  const queryHashStateReducer = reducerFactory((innerAction) => {
    const generalLens = R.lensPath([
      'searchHistory',
      innerAction.payload.queryHash,
    ]);
    if (innerAction.payload.id) {
      return R.compose(generateIdLens(innerAction.payload.id), generalLens);
    }
    return generalLens;
  });
  const genericReducer = reducerFactory();
  const singleIdReducer = reducerFactory((innerAction) =>
    generateIdLens(innerAction.payload.id)
  );
  return (state, action) => {
    // If not right action return state
    if (!Object.values(fetchAction).includes(action.type)) return state;

    const { id, queryHash, entityKey } = action.payload;
    const { response = { entities: {}, result: [] } } = action;

    const newState =
      response.entities && response.entities[entityKey]
        ? R.keys(response.entities[entityKey]).reduce(
            (accState, resultId) =>
              reducerFactory(() => generateIdLens(resultId))(accState, action),
            state
          )
        : state;

    if (queryHash) {
      return queryHashStateReducer(newState, action);
    }
    if (id) {
      return singleIdReducer(newState, action);
    }

    return genericReducer(newState, action);
  };
};

export default fetchRequestReducerFactory;
