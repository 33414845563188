import { Button } from 'imdui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomerSalesCommission } from 'imddata';
import styled from '@emotion/styled';
import { BodyM, BodyS, H4 } from '@imus/services-ui/src/Text';
import { accentThemes, BaseGrid, Separtor } from '@imus/base-ui';

const CommissionText = styled.div`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));

  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 56px */
  letter-spacing: -2.24px;
`;

const CommissionWrapper = styled.div`
  text-align: center;
  display: grid;
  gap: 12px;
`;

const SubscribeRow = styled.div`
  ${accentThemes.tertiary}
  display: flex;
  gap: 24px;
  align-items: center;
`;

const SubscribeRowText = styled.div`
  display: grid;
  gap: 4px;
  ${BodyS} {
    color: var(--fg-2);
  }
`;

export const InfoValueDisplay = ({
  value,
  subValue,
}: {
  value: string;
  subValue?: React.ReactNode;
}) => {
  return (
    <CommissionWrapper>
      <CommissionText>{value}</CommissionText>
      {subValue && <BodyM>{subValue}</BodyM>}
    </CommissionWrapper>
  );
};

export const CustomerCommisionRate = ({
  onClickSubscribe,
  mode = 'subscribe',
}: {
  onClickSubscribe: React.MouseEventHandler;
  mode?: 'subscribe' | 'upgrade';
}) => {
  const { t } = useTranslation();

  const salesCommission = useCustomerSalesCommission();
  return (
    <BaseGrid>
      <InfoValueDisplay
        value={`${salesCommission}%`}
        subValue={t('sales-commission')}
      />
      {Number(salesCommission || '0') > 0 && (
        <>
          <Separtor />
          <SubscribeRow>
            <SubscribeRowText>
              <H4>{t('commission-rate-upsell', { context: mode })}</H4>
              <BodyS>
                {t('commission-rate-upsell-description', { context: mode })}
              </BodyS>
            </SubscribeRowText>
            <Button
              style={{ flexShrink: '0' }}
              testId="CustomerCommisionRate-upgrade"
              primary={true}
              text={t('upgrade')}
              onClick={(e) => onClickSubscribe(e)}
            />
          </SubscribeRow>
        </>
      )}
    </BaseGrid>
  );
};
