import { formValueSelector, FieldArray, Field } from 'redux-form';
import type { FormValues } from './types';
import { useCallback, useMemo } from 'react';
import { OverlineText, TextFormatted } from 'imdui';
import { BuyOrSubOffer, InputField } from 'imdshared';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCustomerFeature } from 'imddata';
import type { ReduxState } from 'imddata';
import type { ArtistCollectionFieldProps } from './components';
import {
  ArtistIdContext,
  AvatarField,
  useCustomerArtistPageLimits,
  ArtistCollectionField,
} from './components';
import { createMaxLengthValidation } from 'helpers/validation';
import { UsedGalleryIds } from './components/GalleryIdField';
import {
  useMediaTypes,
  MediaForm,
  MediaLinkForm,
  useMediaLinkTypes,
} from './components/MediaForms';
import { TrackCardLimitReached } from './components/TrackCardLimitReachedContext';

type Media = FormValues['media'][number];
type MediaLink = FormValues['mediaLinks'][number];

const MediaLinkCollectionField: typeof ArtistCollectionField<MediaLink> = (
  props
) => {
  const selector = useMemo(
    () => formValueSelector(props.meta.form),
    [props.meta.form]
  );

  const disabled = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return media?.length === 20;
  });
  return (
    <>
      <ArtistCollectionField disabled={disabled} {...props} />
    </>
  );
};

const MediaCollectionField: typeof ArtistCollectionField<Media> = (props) => {
  const selector = useMemo(
    () => formValueSelector(props.meta.form),
    [props.meta.form]
  );

  const galleryIdsUsed = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return media
      ?.map((m) => (m.type === 'image-gallery' ? m.data.artistGalleryId : ''))
      .filter((v) => v !== '');
  });
  const trackAlreadyUsed = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return !!media?.find((m) => m.type === 'track');
  });

  const disabled = useSelector((state: ReduxState) => {
    const media: FormValues['media'] | undefined = selector(state, 'media');
    return media?.length === 20;
  });
  return (
    <>
      <UsedGalleryIds.Provider value={galleryIdsUsed}>
        <TrackCardLimitReached.Provider value={trackAlreadyUsed}>
          <ArtistCollectionField disabled={disabled} {...props} />
        </TrackCardLimitReached.Provider>
      </UsedGalleryIds.Provider>
    </>
  );
};

export function ArtistPageAboutForm({ id }: { id: number }) {
  const { t } = useTranslation();
  const artistPageLimits = useCustomerArtistPageLimits();
  const isRegistered = useSelector(
    (state: ReduxState) => !!state.entities.artists.entities[id]?.registeredAt
  );

  const validateDescription = useMemo(
    () => createMaxLengthValidation(1120, t, false),
    []
  );
  const mediaTypes = useMediaTypes();
  const mediaLinkTypes = useMediaLinkTypes();
  const feature = useCustomerFeature('artist-hub-pro');
  const formatLinkValueToItem = useCallback(
    (v: MediaLink) => ({
      label: mediaLinkTypes.find((mt) => mt.value === v.type)?.label || v.type,
    }),
    [t]
  );
  const formatValueToItem = useCallback(
    (v: Media) => ({
      label: mediaTypes.find((mt) => mt.value === v.type)?.label || v.type,
    }),
    [t]
  );
  return (
    <ArtistIdContext.Provider value={id}>
      {!feature && (
        <BuyOrSubOffer
          style={{ marginBottom: 0 }}
          subscribeContext={{ analytics: { detail: 'artist-page' } }}
          title={t('some-settings-will-not-apply-to-published-version')}
          description={
            <TextFormatted
              text={t('subscribe-to-unlock-customization-options')}
            />
          }
        />
      )}
      <Field
        name="profileImage"
        component={AvatarField}
        handler="artistProfileImage"
        handlerId={id}
      />
      <Field
        name="name"
        component={InputField}
        disabled={isRegistered}
        label={t('artist-name')}
        style={{ margin: 0 }}
      />
      <Field
        name="description"
        placeholder={t('artist-about-text')}
        component={InputField}
        multiline={true}
        validate={validateDescription}
        style={{ margin: 0 }}
      />
      <div>
        <OverlineText size="large" label={t('cards')} />
        <FieldArray<ArtistCollectionFieldProps<Media>, Media>
          name="media"
          component={MediaCollectionField}
          dragType="MEDIA-CARD"
          isDisabled={(v) =>
            (artistPageLimits.about.allowedMediaTypes?.indexOf(v.type) || 1) < 0
          }
          addText={t('add-media-card')}
          formatValueToItem={formatValueToItem}
          formComponent={MediaForm}
        />
      </div>
      <div>
        <OverlineText size="large" label={t('links')} />
        <FieldArray<ArtistCollectionFieldProps<MediaLink>, MediaLink>
          name="mediaLinks"
          component={MediaLinkCollectionField}
          dragType="MEDIALINK-CARD"
          isDisabled={(v) =>
            (artistPageLimits.about.allowedMediaTypes?.indexOf(v.type) || 1) < 0
          }
          addText={t('add-link')}
          formatValueToItem={formatLinkValueToItem}
          formComponent={MediaLinkForm}
        />
      </div>
    </ArtistIdContext.Provider>
  );
}
