import React, {
  Fragment,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  Button,
  IconButton,
  Content,
  Caption,
  SVGIcon,
  Icons,
  FieldWrapper,
  SeparatorLine,
  OverlineText,
} from 'imdui';
import type { WrappedFieldProps } from 'redux-form';
import { equals, remove } from 'ramda';
import prettyBytes from 'pretty-bytes';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import type { FileRejection, DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import type { UploadableHandlers, EntityModels } from 'imddata';
import { useFilePreviews, useUploadFile } from 'imddata';
import { ProgressLine } from '../DataRow';

const DropzoneContainer = styled.div`
  margin-top: 8px;
  border-radius: 4px;
  border: dotted 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  position: relative;
  min-height: 144px;
  overflow: hidden;
  &:hover {
    background-color: #e5e5e5;
  }
  &:active {
    background-color: #cccccc;
  }
`;

const UploadIcon = styled(SVGIcon)`
  margin-bottom: 16px;
`;

const DropzoneContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    color: ${({ theme }) => theme.accent.green};
  }
`;

const FormatsCaption = styled(Caption)`
  margin-top: 8px;
  color: ${({ theme }) => theme.lights[4]};
`;

const ErrorCaption = styled(Caption)`
  margin-top: 8px;
  color: ${({ theme }) => theme.state.error};
`;

const FileRowTop = styled.div`
  position: relative;
  margin-top: 8px;
  min-height: 32px;
  align-items: center;
  display: flex;
`;
const FileName = styled(Content)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const FileRowActions = styled.div`
  margin-left: auto;
`;

type UploadedFile = {
  id: number;
  name: string;
  size: number;
  url: string | null;
  error?: boolean | string;
};

type DroppedFile = File | UploadedFile;

const isUploadedFile = (file: DroppedFile): file is UploadedFile => {
  return 'id' in file;
};

type StaticFileProps = {
  file: DroppedFile;
  error?: boolean | string | null;
  uploaded?: boolean;
  uploadingProgress?: number;
  uploading?: boolean;
  onRemove: () => void;
  testId?: string;
};

const FileProgressLine = styled(ProgressLine)`
  height: 100%;
`;

export const StaticFileRow = ({
  file,
  error,
  testId,
  uploaded,
  uploading,
  onRemove,
  uploadingProgress = 0,
}: StaticFileProps) => {
  const { name, size } = file;
  // TODO: handle name overflow
  return (
    <div data-test-id={testId}>
      <FileRowTop>
        {uploading && <div data-test-id="File-uploading" />}
        {uploaded && <div data-test-id="File-uploaded" />}
        {uploading ? (
          <FileProgressLine progress={uploadingProgress || 5} />
        ) : null}
        <FileName>{name}</FileName>
        <FileRowActions>
          {onRemove && (
            <IconButton
              testId="File-remove"
              onClick={onRemove}
              icon={Icons.actions.remove}
            />
          )}
        </FileRowActions>
      </FileRowTop>
      {size && (
        <div>
          <FormatsCaption>{prettyBytes(size)}</FormatsCaption>
        </div>
      )}
      {error && (
        <div>
          <ErrorCaption>{error}</ErrorCaption>
        </div>
      )}
    </div>
  );
};

type FileRowProps = {
  file: DroppedFile;
  error?: boolean | string | null;
  testId?: string;
  handlerData: Record<string, unknown>;
  handler: UploadableHandlers;
  onRemove: () => void;
  onAdd: (options: {
    downloadUrl: string;
    extension?: string;
    fileName: string;
    size: number;
  }) => void;
};

export type UploadPreview = {
  downloadUrl: string;
  extension?: string;
  fileName: string;
  size: number;
};
export const useSingelFileUploadManager = ({
  handler,
  handlerData,
}: {
  handlerData: Record<string, unknown>;
  handler: UploadableHandlers;
}) => {
  const [file, setFile] = useState<File>();
  const uploadStarted = useRef(false);
  const [readyPreview, setReadyPreview] = useState<UploadPreview | null>(null);
  const { getPreview, destroyPreview, createPreview } = useFilePreviews();

  const { abortAndDeleteFile, uploadFile, id, request } = useUploadFile(
    handler,
    handlerData
  );

  const startUpload = useCallback(
    (f: DroppedFile | undefined) => {
      // Do not upload if it is already provided by API
      if (f instanceof File && !uploadStarted.current) {
        uploadFile(f);
        setFile(f);
        uploadStarted.current = true;
      }
    },
    [uploadFile]
  );

  const preview = useMemo(
    () => (id ? getPreview(`${id}`) : undefined),
    [getPreview]
  );

  useEffect(() => {
    if (id && file && file instanceof File) {
      createPreview(file, `${id}`);
    }
  }, [id]);

  const abort = useCallback(() => {
    if (id) {
      abortAndDeleteFile(id);
    }
    if (file && isUploadedFile(file)) {
      abortAndDeleteFile(file.id);
    }
    if (preview && id) {
      destroyPreview(`${id}`);
    }
    uploadStarted.current = false;
  }, [id]);

  useEffect(() => {
    if (preview && file) {
      setReadyPreview({
        fileName: file.name,
        extension: file.name.split('.').pop(),
        downloadUrl: preview,
        size: file.size,
      });
    }
  }, [preview]);

  const failedUpload: boolean = request.failed && !request.uploading;

  useEffect(() => {
    if (request.uploaded || failedUpload) {
      uploadStarted.current = false;
    }
  }, [request.uploaded, failedUpload]);

  return {
    upload: startUpload,
    request: {
      ...request,
      failed: failedUpload,
    },
    preview: readyPreview,
    abort,
  };
};

const FileRow = ({
  file,
  error,
  testId,
  handlerData,
  handler,
  onAdd,
  onRemove,
}: FileRowProps) => {
  const { t } = useTranslation();

  const { upload, request, abort, preview } = useSingelFileUploadManager({
    handler,
    handlerData,
  });
  useEffect(() => {
    upload(file);
  }, []);

  useEffect(() => {
    if (preview) {
      onAdd(preview);
    }
  }, [preview]);

  return (
    <StaticFileRow
      file={file}
      error={request.failed ? (t('upload-failed') as string) : error}
      testId={testId}
      onRemove={() => {
        abort();
        onRemove();
      }}
      uploading={request.uploading}
      uploadingProgress={request.progress || 0}
      uploaded={request.uploaded}
    />
  );
};

const mapApiFileToDropFile = (
  value: DroppedFile | EntityModels.File
): DroppedFile => {
  if ('downloadUrl' in value) {
    return {
      error: value.status === 'incorrect',
      name: value.fileName,
      size: value.size,
      id: value.id,
      url: value.downloadUrl,
    };
  }
  return value;
};

const formatInputToState = (
  value: DroppedFile[] | DroppedFile | EntityModels.File | EntityModels.File[]
): DroppedFile[] => {
  if (Array.isArray(value)) {
    return value.map(mapApiFileToDropFile);
  }
  if (value) {
    return [mapApiFileToDropFile(value)];
  }
  return [];
};
type Accept = Array<{ fileType: string; extension: string[]; label?: string }>;

type FileDropFieldProps = WrappedFieldProps & {
  testId?: string;
  label?: string;
  disabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  accept?: Accept;
  requirementText?: string;
  handler: UploadableHandlers;
  handlerData?: any;
  onClickHelp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  required?: boolean;
  components?: React.ComponentProps<typeof OverlineText>['components'];
};

export const DropArea = ({
  multiple,
  onDrop,
  accept,
  maxSize,
  requirementText,
  testId,
}: Omit<DropzoneOptions, 'accept'> & {
  requirementText?: string;
  accept?: Accept;
  testId?: string;
}) => {
  const acceptDropzone = useMemo(
    () =>
      accept
        ? accept.reduce(
            (acc, { fileType, extension }) => ({
              ...acc,
              [fileType]: extension,
            }),
            {}
          )
        : undefined,
    [accept]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    onDrop,
    accept: acceptDropzone,
    maxSize,
  });
  const { t } = useTranslation();

  return (
    <DropzoneContainer {...getRootProps()}>
      <input data-test-id={`${testId}-input`} {...getInputProps()} />
      <DropzoneContent>
        <UploadIcon d={Icons.actions.upload} size={30} />

        {!isDragActive ? (
          <>
            <Caption>
              <Trans
                i18nKey="drag-and-drop-here-or-browse"
                default="Drag and drop here or <0>browse</0>"
                components={[
                  <button key={0} type="button">
                    browse
                  </button>,
                ]}
              ></Trans>
            </Caption>
            {accept && (
              <FormatsCaption>
                {t('supported-formats')}:{' '}
                {accept
                  .map((acceptType) => acceptType.label || acceptType.fileType)
                  .join(', ')}
              </FormatsCaption>
            )}
            {requirementText && (
              <FormatsCaption>{requirementText}</FormatsCaption>
            )}
          </>
        ) : (
          <Caption>{t('drop')}</Caption>
        )}
      </DropzoneContent>
    </DropzoneContainer>
  );
};

const FileDropField = ({
  testId,
  meta,
  label,
  disabled,
  required,
  onClickHelp,
  input,
  multiple,
  accept,
  requirementText,
  components,
  maxSize,
  handler,
  handlerData,
}: FileDropFieldProps) => {
  // TODO: handle exisiting files with different FileRow implentation?
  const valueRef = useRef();

  const [droppedFiles, setDroppedFiles] = useState(() => {
    valueRef.current = input.value;
    return formatInputToState(input.value);
  });
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>();

  useEffect(() => {
    if (!equals(input.value, valueRef.current)) {
      valueRef.current = input.value;
      setDroppedFiles(formatInputToState(input.value));
    }
  }, [input.value]);
  const [displayZone, setDisplayZone] = useState(false);

  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles, droppedRejectedFiles) => {
      setDisplayZone(false);
      setRejectedFiles(droppedRejectedFiles);
      if (acceptedFiles.length) {
        if (multiple) {
          setDroppedFiles([...droppedFiles, ...acceptedFiles]);
          return;
        }
        setDroppedFiles([acceptedFiles[0]]);
      }
    },
    [multiple, droppedFiles]
  );

  const error = meta?.error;

  return (
    <FieldWrapper
      onFocus={(e) => {
        if (input.onFocus) {
          input.onFocus(e);
        }
      }}
      onBlur={() => {
        if (input.onBlur) {
          input.onBlur(null);
        }
      }}
      data-test-id={testId}
    >
      <OverlineText
        label={label}
        onClickHelp={onClickHelp}
        error={error}
        required={!disabled && required}
        components={{
          OverlineActions: components?.OverlineActions,
        }}
      />
      {rejectedFiles?.map(({ file, errors }, index) => (
        <StaticFileRow
          key={index}
          onRemove={() => {
            setRejectedFiles(remove(index, 1, rejectedFiles));
          }}
          error={
            errors[0].code === 'file-too-large'
              ? (t('file-too-large') as string)
              : (t('invalid-file') as string)
          }
          file={file}
        />
      ))}
      {droppedFiles.map((entry, index) => (
        <Fragment
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <FileRow
            testId={`FileRow-${index}`}
            handlerData={handlerData}
            handler={handler}
            file={entry}
            error={Array.isArray(error) ? error[index] : error}
            onAdd={(value) => {
              if (input.onChange) {
                if (multiple) {
                  input.onChange([...(input.value || []), value]);
                  return;
                }
                input.onChange(value);
              }
            }}
            onRemove={() => {
              if (input.onChange) {
                if (multiple) {
                  input.onBlur(null);
                  input.onChange(remove(index, 1, input.value || []));
                } else {
                  input.onBlur(null);
                  input.onChange(null);
                }
              }
              setDroppedFiles(remove(index, 1, droppedFiles));
            }}
          />
          {multiple || (index > 0 && <SeparatorLine />)}
        </Fragment>
      ))}
      {multiple && !!droppedFiles?.length && !displayZone && (
        <Button
          text={t('add-file')}
          onClick={() => {
            setDisplayZone(true);
          }}
        />
      )}
      {((!droppedFiles.length && !rejectedFiles?.length) || displayZone) && (
        <DropArea
          accept={accept}
          requirementText={requirementText}
          multiple={multiple}
          maxSize={maxSize}
          onDrop={onDrop}
        />
      )}
    </FieldWrapper>
  );
};

export default FileDropField;
