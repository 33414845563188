import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { ApplicationSettingsContext, Content } from 'imdshared';
import { useTranslation } from 'react-i18next';
import ProductPriceContext from '../ProductPriceContext';
import { AmpIconBase } from '@imus/base-ui';

const TypeCaption = styled(Content)`
  white-space: nowrap;
`;

const PlanWrapper = styled.span`
  display: inline-flex;
  justify-content: end;
`;
const PriceText = styled(Content)`
  display: inline-flex;
  align-items: center;
`;

const AmpText = styled.span`
  color: #ff27b0;
`;

export const PriceCloud: React.FC<any> = ({ type }) => {
  const { calculating, ampDiscount, price, priceCurrency } =
    useContext(ProductPriceContext);
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const showPrice =
    price && priceCurrency && (Number(price) > 0 || !ampDiscount);

  const { t } = useTranslation();

  return (
    <PlanWrapper>
      {calculating ? (
        <TypeCaption>{t('calculating')}</TypeCaption>
      ) : (
        <>
          {type && (
            <TypeCaption>{type}&nbsp;&nbsp;&bull;&nbsp;&nbsp;</TypeCaption>
          )}

          <PriceText>
            {ampDiscount && (
              <>
                <AmpIconBase width={16} height={16} />
                <AmpText>{ampDiscount}</AmpText>
              </>
            )}
            {showPrice && ampDiscount ? <>&nbsp;+&nbsp;</> : ''}
            {showPrice
              ? new Intl.NumberFormat(numberFormatLocale, {
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: priceCurrency,
                }).format(Number(price))
              : ''}
          </PriceText>
        </>
      )}
      <div id="PriceCloud-HelpIcon" />
    </PlanWrapper>
  );
};
