import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actions } from '../../actionTypes/dev';

export const useDebugMode = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (query.devfeatures && typeof query.devfeatures === 'string') {
      dispatch(
        actions.updateFeatures(
          query.devfeatures.split(',').reduce(
            (acc, v) => ({
              ...acc,
              [v.split(':')[0]]: v.split(':')[1] === 'true',
            }),
            {}
          )
        )
      );
    }
    if (query.devdebug && typeof query.devdebug !== 'object') {
      switch (query.devdebug) {
        case 'false': {
          dispatch(actions.toggleDevmode(false));
          break;
        }

        default:
          dispatch(actions.toggleDevmode(query.devdebug));
          break;
      }
    }
  }, []);
};
