//
import { EventTypes, createTracker } from 'redux-segment';
import { entities } from 'imddata/actionTypes';
import { integrations, mapEcommerceProperties } from 'helpers/tracking';

const mapEcommerce =
  (idKey, event) =>
  (getState, action, extra = {}) => {
    const {
      rawResponse: { item },
    } = action;

    return {
      eventType: EventTypes.track,
      eventPayload: {
        event,
        properties: {
          ...mapEcommerceProperties(item, idKey),
          ...extra,
        },
        options: {
          integrations,
        },
      },
    };
  };

const mapPlacingOrder = mapEcommerce('orderId', 'FT Order Placed');

const mapRemoveOrder = (getState, action) => {
  return {
    eventType: EventTypes.track,
    eventPayload: {
      event: 'FT Order removed',
      properties: {
        orderId: action?.payload?.id,
      },
    },
  };
};

const trackerConfig = {
  mapper: {
    [entities.orders.types.delete.SUCCESS]: mapRemoveOrder,
    [entities.createOrder.types.create.SUCCESS]: mapPlacingOrder,
    [entities.invoices.types.create.SUCCESS]: mapEcommerce(
      'invoiceId',
      'FT Order checkout'
    ),
  },
};

const tracker = createTracker(trackerConfig);

export default tracker;
