import styled from '@emotion/styled';
import { Badge } from '@imus/base-ui';
import { FieldCard, OverlineText } from 'imdui';

type Values = { value: string; label: string; icon?: React.ReactNode }[];

const Variant = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  &:hover ${Badge}:after {
    background-color: var(--state-hover-on-surface, rgba(28, 28, 27, 0.08));
  }
`;

export const VariantPicker = ({
  values,
  label,
  input,
}: {
  label: string;
  values: Values;
  input: any;
}) => {
  return (
    <div>
      <OverlineText label={label} />
      <FieldCard style={{ gap: '16px' }}>
        {values.map(({ value, label, icon }) => {
          return (
            <Variant key={value} onClick={() => input.onChange(value)}>
              {icon}
              <Badge
                onClick={() => input.onChange(value)}
                primary={input.value === value}
              >
                {label}
              </Badge>
            </Variant>
          );
        })}
      </FieldCard>
    </div>
  );
};
