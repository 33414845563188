/* eslint-disable react/no-array-index-key */
//
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationSettingsContext, Card } from 'imdshared';
import type { EntityModels } from 'imddata';
import TotalRow from './TotalRow';

const TotalsCard = ({
  overview,
  loading,
  payable,
  paid,
}: {
  overview: {
    currency: EntityModels.Overview['currency'];
    paymentFee: EntityModels.Overview['paymentFee'];
    payments: EntityModels.Overview['payments'];
    tax: EntityModels.Overview['tax'];
    taxRate: EntityModels.Overview['taxRate'];
    toPay: EntityModels.Overview['toPay'];
    totalPayments: EntityModels.Overview['totalPayments'];
    total: EntityModels.Overview['total'];
  };
  payable?: boolean;
  paid?: boolean;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const currencyId = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currencyId,
  });

  return (
    <Card>
      <>
        {overview.paymentFee && Number(overview.paymentFee) > 0 && (
          <>
            <TotalRow
              loading={loading}
              label={t('total-before-fee')}
              value={priceFormatter.format(Number(overview.total))}
            />

            <TotalRow
              loading={loading}
              label={t('payment-fee')}
              value={priceFormatter.format(Number(overview.paymentFee))}
            />
          </>
        )}

        {overview.totalPayments && Number(overview.totalPayments) > 0 && (
          <>
            <TotalRow
              loading={loading}
              label={t('invoice-total')}
              value={priceFormatter.format(
                Number(overview.total) + Number(overview.paymentFee)
              )}
            />

            {!!Number(overview.taxRate) && (
              <TotalRow
                loading={loading}
                label={t('including-tax', {
                  count: Number(overview.taxRate),
                })}
                value={priceFormatter.format(Number(overview.tax))}
              />
            )}

            {overview.payments ? (
              overview.payments.map((payment, idx) => (
                <TotalRow
                  key={idx}
                  loading={loading}
                  label={t(payment.type, { defaultValue: payment.typeText })}
                  value={priceFormatter.format(Number(payment.value))}
                />
              ))
            ) : (
              <TotalRow
                loading={loading}
                label={t('payment-from-cash-credit')}
                value={priceFormatter.format(
                  Number(overview.totalPayments) * -1
                )}
              />
            )}
          </>
        )}

        {!paid && (
          <>
            {payable && (
              <TotalRow
                loading={loading}
                label={paid ? t('paid') : t('total-for-all')}
                isAccent={true}
                value={priceFormatter.format(Number(overview.toPay))}
              />
            )}

            {!!Number(overview.taxRate) && !overview.payments.length && (
              <TotalRow
                loading={loading}
                label={t('including-tax', { count: Number(overview.taxRate) })}
                value={priceFormatter.format(Number(overview.tax))}
              />
            )}
          </>
        )}
      </>
    </Card>
  );
};

export default TotalsCard;
