import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { BodySmall, Content } from '../../../text';
import * as ComponentIcons from '../../icon/ComponentIcons';
import { FieldCardAction } from '../FieldCard';

// No idea why it triggers
// eslint-disable-next-line no-shadow
enum AlertBoxType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

type Props = {
  testId?: string;
  style?: Record<string, unknown>;
  className?: string;
  title?: string;
  text?: string;
  type?: AlertBoxType;
  onClickClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type AlertBoxFC = React.FC<Props> & {
  Type: typeof AlertBoxType;
};

const TextColumn = styled.div`
  display: grid;
  gap: 8px;
  flex: 1;
  margin-left: 8px;
  align-self: center;

  * {
    display: block;
    align-self: center;
  }
`;

const rowStyle = css`
  display: flex;
  align-items: flex-start;
`;

const AlertBox: AlertBoxFC = ({
  style,
  className,
  title,
  text,
  type = AlertBoxType.warning,
  children,
  testId,
}) => {
  const theme = useTheme();
  return (
    <FieldCardAction
      data-test-id={testId}
      style={style}
      className={className}
      status={type}
    >
      <div css={rowStyle}>
        {type === AlertBoxType.success ? (
          <ComponentIcons.Check fill={theme.accent.green} />
        ) : type === AlertBoxType.warning ? (
          <ComponentIcons.Error />
        ) : (
          <ComponentIcons.Error />
        )}

        <TextColumn>
          {title && <Content>{title}</Content>}
          {text && <BodySmall>{text}</BodySmall>}
        </TextColumn>
      </div>

      {children}
    </FieldCardAction>
  );
};

AlertBox.Type = AlertBoxType;

export default AlertBox;
