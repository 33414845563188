import type { FocusEventHandler } from 'react';
import React, { useMemo, PureComponent } from 'react';
import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isNil } from 'ramda';
import type {
  ControlProps,
  SingleValueProps,
  InputProps as SelectInputProps,
  SelectComponentsConfig,
  Props as SelectProps,
} from 'react-select';
import Select from 'react-select';
import {
  OverlineText,
  OverlayController,
  Content,
  Icon,
  IconsCollection,
  NewInput,
  FieldWrapper,
  LoadingIndicator,
  Dropdown,
  MenuItem,
} from 'imdui';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DropdownIcon } from '@imus/base-ui';

export interface InputProps extends Omit<SelectInputProps, 'innerRef'> {
  testId?: string;
  components?: {
    Actions?: React.FC<any>; // TODO: add props
    OverlineActions?: React.FC<any>; // TODO: add props
  };
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string | React.ReactNode;
  label?: string;
  warningText?: string;
  errorText?: string | boolean;
  onClickHelp?: () => void;
  inputRef: React.Ref<any>;
  hasValue: boolean;
}

type InputDef = React.FC<InputProps>;

export type DropdownMenuBaseProps<
  OT extends OptionType,
  isMulti extends boolean,
> = Omit<SelectProps<OT, isMulti>, 'components' | 'onChange'> & {
  onChange: (option: OT | string, option2?: OT) => void; // TODO fix this shitty API
  onBlur?: (...args: any[]) => void; // Overwrite due to redux-form bug
  /**
   * Helper text props
   */
  helperText?: string | React.ReactNode;
  style?: React.CSSProperties;
  warningText?: string;
  label?: string;
  labelSize?: 'small' | 'large';
  errorText?: string | boolean;
  onClickHelp?: () => void;
  hasValue?: boolean;
  required?: boolean;
  placeholder?: string;
  /**
   * render props
   */
  components?: Omit<SelectComponentsConfig<OT, isMulti, any>, 'Input'> & {
    InputActions?: React.FC<any>; // TODO: add props
    DropdownActions?: React.FC<any>; // TODO: add props
    OverlineActions?: React.FC<any>; // TODO: add props
    Input?: InputDef; // TODO: add props
  };
  /**
   * Dropdown props, extras
   */
  menuIsOpen?: boolean; // force open menu
  testId?: string;
  disabled?: boolean;
  addString?: string; // add dialog window title
};

type ConnectedProps<
  OT extends OptionType,
  isMulti extends boolean,
> = ResultData<OT> & DropdownMenuBaseProps<OT, isMulti> & { t: TFunction };

type State = {
  menuIsOpen: boolean;
};

const StyledSingleValueWrapper = styled.div<{
  hasLabel: boolean;
}>`
  z-index: 1;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 24px 0 16px;
  pointer-events: none;
  overflow: hidden;
`;

const textStyle = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const placeholderStyle = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

  svg {
    fill: ${theme.darks[2]};
  }
`;

const NoOptionsMessage: React.FC<any> = ({ isLoading }) => {
  return (
    <div css={placeholderStyle}>
      {isLoading ? <LoadingIndicator /> : <Icon d={IconsCollection.empty} />}
    </div>
  );
};

const ValueContainer = ({ children }: { children: React.ReactNode }) => (
  <div>{children}</div>
);
const dummyClick = () => null;

const Option: React.FC<any> = (props) => (
  <MenuItem onClick={dummyClick} {...props} />
);

const DefaultInput: InputDef = ({ inputRef, ...props }) => (
  <NewInput inputRef={inputRef} {...props} />
);

const NoopComponent = () => null;
const IndicatorsContainer = NoopComponent;
const IndicatorSeparator = NoopComponent;
const DropdownIndicator = NoopComponent;
const Placeholder = NoopComponent;

class DropdownMenu<
  OT extends OptionType,
  IsMulti extends boolean,
> extends PureComponent<ConnectedProps<OT, IsMulti>, State> {
  /**
   * react-select root element ref
   */
  selectNode: React.RefObject<any> = React.createRef();

  /**
   * Input element ref
   */
  inputAnchorNode: React.RefObject<HTMLDivElement> = React.createRef();

  state = {
    menuIsOpen: false,
  };

  componentDidUpdate(prevProps: ConnectedProps<OT, IsMulti>, prevState: State) {
    if (!prevProps.menuIsOpen && this.props.menuIsOpen) {
      // must not trigger loop
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ menuIsOpen: true });
    }
    if (this.state.menuIsOpen && !prevState.menuIsOpen) {
      this.selectNode.current?.focus();
    }
    if (!this.state.menuIsOpen && prevState.menuIsOpen) {
      this.selectNode.current?.blur();
    }
  }

  handleOpen: FocusEventHandler = () => {
    this.setState({ menuIsOpen: true });
  };

  handleClose = () => {
    this.setState({ menuIsOpen: false });
  };

  handleFocus: FocusEventHandler = (e) => {
    const { onFocus } = this.props;
    if (onFocus) {
      // @ts-ignore
      onFocus(); // pass undefined event
    }
    this.handleOpen(e);
  };

  handleBlur: FocusEventHandler = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(); // pass undefined event
    }
    this.setState({ menuIsOpen: false });
  };

  // TODO: FIX API
  handleChange = (option: any) => {
    const { isMulti, onChange } = this.props;
    if (isMulti) {
      onChange(option);
      return;
    }
    if (!option) {
      return;
    }
    this.setState({ menuIsOpen: false });

    // TODO refactor to only pass option
    onChange(option.value, option);
  };

  renderSingleValue = ({
    innerProps,
    data,
    ...props
  }: SingleValueProps<OT, IsMulti>) => {
    const { components, label, disabled, isLoading } = this.props;

    if (components?.SingleValue) {
      return (
        <components.SingleValue
          {...props}
          innerProps={innerProps}
          className={disabled ? 'disabled' : ''}
          isDisabled={!!disabled}
          data={data}
        />
      );
    }

    return (
      <StyledSingleValueWrapper
        data-test-id="DropdownMenu-value"
        className={disabled ? 'disabled' : ''}
        hasLabel={!!label}
      >
        {!isLoading && (
          <Content data-test-id="dropdown-value" css={textStyle}>
            {data.label}
          </Content>
        )}

        {isLoading && <LoadingIndicator />}
      </StyledSingleValueWrapper>
    );
  };

  renderControl = ({
    children,
    innerProps: { ...innerProps },
    ...props
  }: ControlProps<OT, IsMulti>) => {
    const { components } = this.props;

    if (components && components.Control) {
      return (
        <div ref={this.inputAnchorNode}>
          <components.Control {...props} innerProps={innerProps}>
            {children}
          </components.Control>
        </div>
      );
    }
    return (
      <div
        ref={this.inputAnchorNode}
        {...innerProps}
        data-test-id="menu-option"
      >
        {children}
      </div>
    );
  };

  renderDropdown = ({ children }: { children: React.ReactNode }) => {
    const { options, testId, components, isLoading } = this.props;

    const onlyHiddenOption =
      options?.length === 1 && options[0].type === 'hidden';

    return (
      <Dropdown
        anchorEl={this.inputAnchorNode}
        onClickOutside={this.handleClose}
      >
        {({ width }) =>
          components && components.DropdownActions ? (
            <div style={{ width }}>
              <components.DropdownActions
                isLoading={isLoading}
                options={options}
                closeMenu={this.handleClose}
                onlyHiddenOption={onlyHiddenOption}
              />
              {!onlyHiddenOption ? (
                children
              ) : (
                <div css={placeholderStyle} style={{ margin: '4px 0' }}>
                  {isLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <Icon d={IconsCollection.empty} />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div data-test-id={`${testId}-menu`} style={{ width }}>
              {children}
            </div>
          )
        }
      </Dropdown>
    );
  };

  renderInputActions: React.FC<any> = () => {
    const { components } = this.props;

    return (
      <>
        <DropdownIcon />

        {components?.InputActions ? <components.InputActions /> : null}
      </>
    );
  };

  renderInput = ({
    innerRef,
    ...inputProps
  }: SelectInputProps<OT, IsMulti>) => {
    const {
      entries,
      value,
      components,
      testId,
      required,
      hasValue: hasValueProp,
      disabled,
      placeholder,
      onClickHelp,
      errorText,
      helperText,
      warningText,
      labelSize,
    } = this.props;

    // @ts-ignore
    const hasEntryWithValue = entries ? entries[value] : false;

    const render = components?.Input || DefaultInput;
    const hasValue =
      hasValueProp ||
      hasEntryWithValue ||
      // @ts-ignore
      (value !== null && value !== undefined && value !== '');

    return render({
      ...inputProps,
      testId,
      required,
      labelSize,
      hasValue,
      // @ts-ignore
      inputRef: innerRef,
      disabled,
      // label,
      errorText,
      warningText,
      helperText,
      placeholder: hasValue ? undefined : placeholder,
      components: {
        Actions: this.renderInputActions,
      },
      onClickHelp: labelSize === 'large' ? undefined : onClickHelp,
    });
  };

  render() {
    const {
      value,
      options,
      entries,
      //
      disabled,
      // t,
      autoFocus,
      placeholder,
      //
      styles,
      //
      onInputChange,
      //
      isMulti,
      testId,
      isLoading,
      backspaceRemovesValue,
      hideSelectedOptions,
      filterOption,
      label,
      onClickHelp,
      required,
      //
      components: { OverlineActions, ...components } = {},
      //
      className,
      labelSize,
      style,
    } = this.props;

    return (
      <FieldWrapper
        key="content"
        data-test-id={testId + (entries ? '-loaded' : '-empty')}
        className={className}
        style={style}
      >
        <div>
          {label && (
            <OverlineText
              size={labelSize}
              label={label}
              onClickHelp={labelSize === 'large' ? onClickHelp : undefined}
              required={!disabled && required}
              components={{ OverlineActions }}
            />
          )}
          <Select<OT, IsMulti>
            autoFocus={autoFocus}
            ref={this.selectNode}
            backspaceRemovesValue={backspaceRemovesValue}
            menuIsOpen={this.state.menuIsOpen}
            // isDisabled={disabled} for some reason it blocks clicking on helper texts
            openMenuOnClick={!disabled}
            openMenuOnFocus={!disabled}
            tabIndex={disabled ? -1 : 0}
            styles={styles}
            maxMenuHeight={300}
            isMulti={isMulti}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            placeholder={placeholder}
            // @ts-ignore
            value={!isMulti ? (entries && entries[value]) || null : value}
            onInputChange={onInputChange}
            hideSelectedOptions={hideSelectedOptions}
            filterOption={filterOption}
            components={{
              Placeholder,
              ...components,
              SingleValue: this.renderSingleValue,
              Control: this.renderControl,
              NoOptionsMessage,
              ValueContainer,
              IndicatorsContainer,
              LoadingIndicator: NoopComponent,
              DropdownIndicator,
              IndicatorSeparator,
              Option,
              Menu: this.renderDropdown,
              Input: this.renderInput,
            }}
            isLoading={isLoading}
            isSearchable={true}
            name="color"
            options={options}
          />
        </div>
      </FieldWrapper>
    );
  }
}

type BaseEntry =
  | {
      value: string | number;
      label: string | React.ReactNode;
      children: Array<Entry>;
      nestingLevel?: number;
    }
  | {
      value: string | number;
      label: string | React.ReactNode;
    }
  | {
      value: string | number;
      text: string;
    };

type FallbackEntry = {
  id: number;
  label: string;
};

type Entry = BaseEntry | FallbackEntry;

export type OptionType = {
  type?: 'hidden';
  nestingLevel?: number;
  value: string | null | number;
  label: string;
  children?: React.ReactNode;
};

type ParsedData<OT> = {
  entries: Record<string, OT>;
  keys: (string | number)[];
  nestingLevel?: number;
};

type ResultData<OT> = {
  options: Array<OT>;
  entries: Record<string, OT>;
};

type EntryAccessor = (entry: Entry, data?: Array<Entry>) => string | number;
type LabelEntryAccessor = (
  entry: Entry,
  data?: Array<Entry>
) => string | React.ReactNode;

const isBaseEntry = (e: Entry): e is BaseEntry => 'value' in e;

const defaultGetValue: EntryAccessor = (entry: Entry) => {
  if (isBaseEntry(entry) && !isNil(entry.value)) {
    return entry.value;
  }
  return (entry as FallbackEntry).id;
};

const defaultGetLabel: LabelEntryAccessor = (entry: Entry) => {
  if ('label' in entry) return entry.label;
  if ('text' in entry) return entry.text;

  return `${defaultGetValue(entry)}`;
};

type Data = Array<Entry>;

export const useDropdownData = <OT extends OptionType = OptionType>({
  getValue = defaultGetValue,
  getLabel = defaultGetLabel,
  data,
}: {
  getValue?: EntryAccessor;
  getLabel?: LabelEntryAccessor;
  data: Data;
}): ResultData<OT> => {
  return useMemo(() => {
    if (!Array.isArray(data)) {
      return data as ResultData<OT>; // Assume developer provides right data structure
    }

    const reduceChildren = (
      acc: ParsedData<OT>,
      child: Entry
    ): ParsedData<OT> => {
      const value = getValue(child, data);
      const label = getLabel(child, data);
      // TODO: figure out how to assemble structure OT from defined Array<Entry> type
      // @ts-ignore
      return child
        ? {
            keys: [...acc.keys, value],
            entries: {
              ...acc.entries,
              [value]: {
                ...child,
                nestingLevel: acc.nestingLevel,
                value,
                label,
              },
            },
            nestingLevel: acc.nestingLevel,
          }
        : acc;
    };

    const newData = data.reduce<ParsedData<OT>>(
      // @ts-ignore
      (acc, entry, idx) => {
        if (!entry) return acc;
        if (typeof entry === 'string' || entry instanceof String) {
          return {
            nestingLevel: 0,
            keys: [...acc.keys, entry.toString()],
            entries: {
              ...acc.entries,
              [entry.toString()]: {
                nestingLevel: 0,
                id: idx + 100000,
                value: entry.toString(),
                label: entry.toString(),
              },
            },
          };
        }
        return reduceChildren(
          {
            ...acc,
            nestingLevel: 0,
          },
          entry
        );
      },
      {
        nestingLevel: 0,
        keys: [],
        entries: {},
      }
    );
    // @ts-ignore
    const options = newData.keys.map((key) => newData.entries[key]);
    return {
      keys: [],
      data: newData,
      options,
      // @ts-ignore
      entries: newData.entries,
    };
  }, [data]);
};

export type Props<
  OT extends OptionType = OptionType,
  IsMulti extends boolean = false,
> = {
  data: Data;
  getValue?: EntryAccessor;
  getLabel?: LabelEntryAccessor;
} & DropdownMenuBaseProps<OT, IsMulti>;

const MenuWithContext = <
  OT extends OptionType = OptionType,
  IsMulti extends boolean = false,
>({
  data,
  ...props
}: Props<OT, IsMulti>) => {
  const { getValue, getLabel } = props;

  const { t } = useTranslation();
  const dropdownData = useDropdownData<OT>({
    getValue,
    getLabel,
    data,
  });
  return (
    <OverlayController>
      <DropdownMenu {...props} {...dropdownData} t={t} />
    </OverlayController>
  );
};

export default MenuWithContext;
