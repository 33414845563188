import React, { useCallback, useMemo } from 'react';
import { keys } from 'ramda';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { useInstantMasteringTrackIds } from 'imddata';
import { TracksManager, DescriptionBlock } from '../../../../shared';

interface Props {
  id: number | string;
}

const validate = (values: EntityModels.Track) => {
  const errors: Record<string, string> = {};

  if (!values.uploadStatus || values.uploadStatus === 'none') {
    errors.uploadStatus = 'required';
  }
  if (values.uploadStatus && values.uploadStatus === 'failed') {
    errors.uploadStatus = 'audio-processing-failed';
  }

  return errors;
};

export const isTrackValid = (track: EntityModels.Track): boolean =>
  !keys(validate(track)).length;

const requiredFields = ['uploadStatus'];

export default function MasteringTracksManager({ id }: Props): JSX.Element {
  const trackIds = useInstantMasteringTrackIds(id);
  const nestingPath = useMemo(
    () => ({
      path: ['tracks'],
      wrapperEntity: 'instantMasteringTracks',
      entity: 'instantMasterings',
      entityId: id,
      entityIdKey: 'instantMasteringId',
    }),
    [id]
  );

  const isNotAllowed = useCallback(
    (track) => {
      if (track && trackIds && trackIds.indexOf(track.id) >= 0) {
        return true;
      }
      return track && track.isInMastering;
    },
    [trackIds]
  );

  const { t } = useTranslation();

  return (
    <>
      <DescriptionBlock
        title={t('select-tracks-for-mastering-title')}
        text={t('select-tracks-for-mastering-body')}
      />

      <TracksManager
        targetName={undefined}
        removeTrackButtonLabel={t('remove-from-mastering')}
        isTrackValid={isTrackValid}
        validate={validate}
        trackIds={trackIds}
        isNotAllowed={isNotAllowed}
        nestingPath={nestingPath}
        requiredFields={requiredFields}
      />
    </>
  );
}
