import { useCallback } from 'react';
import { useStartDownload } from './hooks';

type Props = {
  children: (d: {
    onDownload: () => void;
    requestStatus: ReturnType<typeof useStartDownload>['request'];
  }) => React.ReactElement;
  data: any;
  entityId?: number | string;
  downloadType: 'sales' | 'salesReleases';
  preventDownloadDialog?: boolean;
};

export default function DownloadProvider({
  data,
  entityId: id,
  downloadType: entity,
  children,
  preventDownloadDialog = false,
}: Props) {
  const { startDownload, request } = useStartDownload({
    entity,
    id,
    preventDownloadDialog,
  });
  const onDownload = useCallback(() => {
    startDownload(data);
  }, [startDownload, data]);

  return children
    ? children({
        requestStatus: request,
        onDownload,
      })
    : null;
}
