//
import React from 'react';
import { ErrorBoundary } from 'components';
import { useCurrentCustomerBalance } from 'imddata';
import { BalanceDisplay, Card, OverlineText } from 'imdshared';
import { useTranslation } from 'react-i18next';

export default function BalanceCard() {
  const { isUserCustomer, cashCreditValue, promoCreditValue } =
    useCurrentCustomerBalance();

  const { t } = useTranslation();
  return (
    <Card style={{ padding: '24px' }}>
      <OverlineText size="large" label={t('balance-overview')} />
      <ErrorBoundary>
        <BalanceDisplay
          isUserCustomer={isUserCustomer}
          cashCredit={cashCreditValue}
          promoCredit={promoCreditValue}
          hasConversion={false}
        />
      </ErrorBoundary>
    </Card>
  );
}
