import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import config, { i18nLanguages } from 'imdconfig';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const initI18n = () =>
  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${config.assetsUrl}/locales/app/{{lng}}/{{ns}}.json`,
        crossDomain: true,
      },
      returnNull: false,
      fallbackLng: 'en',
      nsSeparator: false,
      returnedObjectHandler: (key, value) => {
        // Fix for using key as object prefix that results in
        // {0: 'Ovevirew', 'somenestedkey': 'sasdasd'}
        if (!key.includes('.')) return value['0'];
        // @ts-ignore
        return value?.[key];
      },
      supportedLngs: i18nLanguages,
      ns: ['translation', 'offline'],
      defaultNS: 'translation',
      fallbackNS: 'offline',
      nonExplicitSupportedLngs: true,
      load: 'languageOnly',
      compatibilityJSON: 'v3',
      react: {
        useSuspense: true,
        bindI18n: 'languageChanged loaded',
        nsMode: 'default',
      },
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
    });

export default initI18n;
