import React from 'react';
import { connect } from 'react-redux';
import { actions } from 'imddata/actionTypes/dev';
import type { ReduxState } from 'imddata';

const DevComponent = ({
  children,
  devmode,
}: {
  children?: React.ReactNode;
  devmode: boolean | string;
}) => {
  if (typeof children === 'function') {
    return children(devmode);
  }
  return !devmode ? null : React.Children.only(children);
};

export default connect(
  (state: ReduxState) => ({ devmode: state.dev.devmode }),
  actions
)(DevComponent);
