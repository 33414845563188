import styled from '@emotion/styled';
import { ListCheckIcon } from '@imus/base-ui';
import React, { useCallback } from 'react';
import { Icon, IconsCollection } from '../../icon';
import ListItem from '../ListItem';

const Return = styled.div`
  display: flex;
  padding: 8px 0;
`;

const Wrapper = styled.div<{
  nestingLevel: number;
}>`
  display: flex;
  flex: 1;
  height: inherit;
`;

type Data = {
  value?: string | number;
  label?: string | React.ReactNode;
  children?: string;
  nestingLevel?: number;
  icon?: React.ReactNode;
  disabled?: boolean;
  prefix?: string;
};

type Props = {
  data: Data;
  isFocused: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  innerProps: any;
};

const MenuItem: React.FC<Props> = ({
  data: { label, icon, children, value, prefix, nestingLevel, disabled } = {},
  isFocused = false,
  isSelected = false,
  isSelectable = true,
  onClick,
  innerProps,
}: Props) => {
  const renderReturn = useCallback(
    () => (
      <Return>{isFocused && <Icon d={IconsCollection.returnKey} />}</Return>
    ),
    [isFocused]
  );

  const mouseHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <Wrapper
      {...innerProps}
      onMouseMove={mouseHandler}
      onMouseOver={mouseHandler}
      nestingLevel={nestingLevel}
    >
      <ListItem
        testId={`option-${value}`}
        label={children || label}
        prefix={prefix}
        icon={
          isSelected ? (
            <ListCheckIcon />
          ) : isSelectable ? (
            icon || '' // to align to the empty column
          ) : null
        }
        selected={isSelected}
        focused={isFocused}
        disabled={disabled}
        onClick={onClick}
        renderRightChildren={renderReturn}
      />
    </Wrapper>
  );
};

export default MenuItem;
