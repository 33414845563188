//
import React from 'react';
import styled from '@emotion/styled';
import { Content, Body } from 'imdshared';
import { LoadingArtwork } from 'components';

const TextWrapper = styled.div<{ noMargin?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: ${(props) => (props.noMargin ? 0 : 24)}px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 8px 16px 8px;
`;

const Header = ({
  releaseId,
  title,
  rightText,
}: {
  releaseId?: number;
  title: string;
  rightText?: string;
}) => (
  <Wrapper>
    {releaseId !== undefined && (
      <LoadingArtwork releaseId={releaseId} hideStatusText={true} />
    )}

    <TextWrapper noMargin={!releaseId}>
      <Content loading={title === '' || !title}>{title}</Content>

      {rightText && <Body>{rightText}</Body>}
    </TextWrapper>
  </Wrapper>
);

export default Header;
