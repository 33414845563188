import { createContext } from 'react';

export const LayoutContext = createContext<{
  isLayoutCollapsed: boolean;
  appRefreshZoneHeight: number;
  safeConstraints: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  breakpointsFit: {
    xxSmall: boolean;
    xSmall: boolean;
    small: boolean;
    medium: boolean;
    large: boolean;
    xLarge: boolean;
    xxLarge: boolean;
  };
}>({
  isLayoutCollapsed: false,
  appRefreshZoneHeight: 0,
  safeConstraints: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  breakpointsFit: {
    xxSmall: false,
    xSmall: false,
    small: false,
    medium: true,
    large: true,
    xLarge: true,
    xxLarge: true,
  },
});

export default LayoutContext;
