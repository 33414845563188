//
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ImpersonateContext } from 'imddata';
import { createEmptyTrack as createEmptyTrackAction } from 'imddata/actionTypes/tracks';
import { Icons, AutosizeGrid, ComponentIcons, Button } from 'imdui';
import styled from '@emotion/styled';
import TracksUploader from '../../../TracksUploader';

const Options = styled(AutosizeGrid)`
  padding: 24px 32px 24px;
  & > * {
    width: 100%;
  }
`;

const Toolbar = ({
  releaseId,
  revenueSplits,
  disabled,
  volumes,
  toolbarActions,
  createEmptyTrack,
  selectedVolume,
  genreId,
  nestingPath,
  disabledCreate,
  artists,
  onClickTrackLibrary,
  onClickDeleteVolume,
}) => {
  const { t } = useTranslation();
  const displayVolumeDelete = volumes?.length >= 2 && onClickDeleteVolume;

  const maxColumnCount = displayVolumeDelete ? 4 : 3;
  return (
    <Options maxColumnCount={maxColumnCount} gap={8} minWidth={220}>
      <TracksUploader
        genreId={genreId}
        artists={artists}
        nestingPath={nestingPath}
        disableClick={disabled || disabledCreate}
        hideOverlay={true}
      >
        <Button
          disabled={disabled || disabledCreate}
          position="center"
          iconLeft={Icons.actions.upload}
          style={{ width: '100%' }}
          onClick={() => {
            if (window.analytics) {
              window.analytics.track('FT Release Tracks Import', {
                state: 'started',
                release_id: releaseId,
              });
            }
            /* fake click */
          }}
          text={t('import')}
        />
      </TracksUploader>

      <ImpersonateContext.Consumer>
        {(impersonateQuery) => (
          <Button
            testId="create-empty-track"
            iconLeft={Icons.actions.add}
            text={t('create-empty')}
            position="center"
            disabled={disabled || disabledCreate}
            onClick={() => {
              if (window.analytics) {
                window.analytics.track('FT Release Tracks Create Empty', {
                  state: 'started',
                  release_id: releaseId,
                });
              }
              createEmptyTrack(
                {
                  nestingPath,
                  data: {
                    revenueSplits,
                    artists,
                    ...(genreId ? { genreId } : {}),
                  },
                },
                {
                  impersonate: impersonateQuery,
                }
              );
            }}
          />
        )}
      </ImpersonateContext.Consumer>

      <Button
        iconLeft={Icons.actions.add}
        testId="TracksList-Toolbar-include-tracks"
        position="center"
        disabled={disabled || disabledCreate}
        text={t('include-from-library')}
        onClick={() => {
          if (window.analytics) {
            window.analytics.track('FT Release Tracks Include From Library', {
              state: 'started',
              release_id: releaseId,
            });
          }
          onClickTrackLibrary();
        }}
      />

      {onClickDeleteVolume && displayVolumeDelete && (
        <Button
          iconLeft={ComponentIcons.Delete}
          text={t('delete-volume')}
          disabled={disabled}
          position="center"
          onClick={onClickDeleteVolume}
        />
      )}
      {toolbarActions && toolbarActions({ selectedVolume, volumes })}
    </Options>
  );
};

export default connect(null, { createEmptyTrack: createEmptyTrackAction })(
  Toolbar
);
