//
import action from '../base/action';
import createRequestActions from '../base/createRequestActions';
import { actionTypes as userActionTypes } from './users';

export const INVALIDATE_AUTH = '@auth/INVALIDATE';

export const REFRESH_AUTH = '@auth/REFRESH_AUTH';
export const REFRESH_AUTH_RESULT = '@auth/REFRESH_AUTH_RESULT';

export const actionTypes = {
  login: {
    SUCCESS: '@auth/LOGIN_SUCCESS',
    REQUEST: '@auth/LOGIN_REQUEST',
    FAILURE: '@auth/LOGIN_FAILURE',
  },
  signOut: {
    SUCCESS: '@auth/SIGNOUT_SUCCESS',
    REQUEST: '@auth/SIGNOUT_REQUEST',
    FAILURE: '@auth/SIGNOUT_FAILURE',
  },
  optimizelyUpdate: '@auth/OPTIMIZELY_UPDATE',
};

export const INVALIDATE = INVALIDATE_AUTH;

export const entityActions = {
  login: createRequestActions(actionTypes.login),
  signOut: createRequestActions(actionTypes.signOut),
};

export const invalidate = () => {
  return action(INVALIDATE_AUTH, {});
};

export const refreshAuth = (refreshToken: string) => {
  return action(REFRESH_AUTH, { refreshToken });
};

export const constants = {
  LOGGED_USER_SUCCESS: userActionTypes.loggedUser.fetch.SUCCESS,
  LOGGED_USER_REQUEST: userActionTypes.loggedUser.fetch.REQUEST,
  LOGGED_USER_FAILURE: userActionTypes.loggedUser.fetch.FAILURE,
  RESET_LOGGED_USER: actionTypes.signOut.SUCCESS,
};

// Actions
export const actions = {
  reset: () => ({ type: constants.RESET_LOGGED_USER }),
};
