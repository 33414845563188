import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { OverlineText, RadioButton } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';

const TogglesRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  > *:first-child {
    margin-right: 16px;
  }
`;
const overlineTextStyle = css`
  margin-bottom: 8px;
`;

type Props = {
  testId?: string;
  disabled?: boolean;
  label?: string;
  onClickHelp?: () => void;
  input: {
    onChange: (e: boolean) => void;
    onFocus?: React.EventHandler<React.FocusEvent<boolean>>;
    onBlur?: React.EventHandler<React.FocusEvent<boolean>>;
    value: boolean | undefined;
  };
  meta?: Partial<WrappedFieldProps['meta']>;
  components?: React.ComponentProps<typeof OverlineText>['components'];
  required?: React.ComponentProps<typeof OverlineText>['required'];
  style?: React.CSSProperties;
  className?: string;
};

export default function YesNoField({
  disabled,
  label,
  onClickHelp,
  className,
  style,
  testId,
  components,
  required,
  input: { value, onFocus, onBlur, onChange },
  meta: { error, touched } = {
    error: false,
    touched: false,
  },
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      {label && (
        <OverlineText
          required={required}
          error={error}
          label={label}
          components={{ OverlineActions: components?.OverlineActions }}
          onClickHelp={onClickHelp}
          css={overlineTextStyle}
        />
      )}
      <TogglesRow data-test-id={testId} className={className} style={style}>
        <RadioButton
          testId="Radio-No"
          text={t('no')}
          disabled={disabled}
          selected={value === false}
          onClick={(): void => {
            if (onChange && !disabled) {
              onChange(false);
              // Because RadioButton prevents onBlur with handleMouseDown callback.
              // And we need to set touched: true for YesNoField to prevent it being cleared on form reinit.
              // May be we should rethink that.
              if (!touched && onBlur && onFocus) {
                // TODO: fix def or logic
                // @ts-ignore
                onFocus();
                // @ts-ignore
                onBlur();
              }
            }
          }}
        />

        <RadioButton
          testId="Radio-Yes"
          text={t('yes')}
          disabled={disabled}
          selected={value === true}
          onClick={(): void => {
            if (onChange && !disabled) {
              onChange(true);
              if (!touched && onBlur) {
                // @ts-ignore
                onFocus();
                // @ts-ignore
                onBlur();
              }
            }
          }}
        />
      </TogglesRow>
    </>
  );
}
