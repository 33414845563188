import React from 'react';
import styled from '@emotion/styled';
import { Chip } from 'imdui';
import type { Change } from '../../types';
import RevertButton from '../RevertButton';

type Props = {
  change: Change;
  label: string;
};

const StyledChip = styled(Chip)<{
  removed: boolean;
}>`
  margin: 0 8px 8px 0;
  text-decoration: ${({ removed }): string =>
    removed ? 'line-through' : 'none'};
`;

const ChipChange: React.FC<Props> = ({ change, label }) => {
  const ITEM_TAKEN_DOWN = change.change.startsWith('take-down');

  return (
    <RevertButton
      changeId={change.id}
      // TODO: fix this to hook useRevert
      // eslint-disable-next-line
      customRenderer={({ onClickRevert, deleting, deleted }): JSX.Element => (
        /*
      // @ts-ignore */
        <StyledChip
          text={label}
          removed={ITEM_TAKEN_DOWN}
          disabled={deleted}
          showLoading={deleting}
          onClickDelete={change.canRevert ? onClickRevert : undefined}
        />
      )}
    />
  );
};

export default ChipChange;
