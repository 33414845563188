import { useTranslation } from 'react-i18next';
import { TextBlock } from './TextBlock';
import { Field, reduxForm } from 'redux-form';
import { Card, CheckboxField, InputField } from 'imdshared';
import styled from '@emotion/styled';
import { H4 } from 'imdui';

export const FEEDBACK_FORM = 'sub-cancel-feedback';
const FEEDBACK_WIDTH = 400;

const DELETE_PROFILE_REASONS = ['1', '3', '4', '5'];

const FormWrapper = styled(Card)`
  align-self: center;
  display: flex;
  width: 100%;
  padding: 0 24px;
  max-width: ${FEEDBACK_WIDTH}px;
  flex-direction: column;
`;
const FieldWrapper = styled.div`
  padding: 24px 0;
  ${H4} {
    margin: 0 0 0 16px;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  &:last-of-type {
    border-bottom: none;
  }
`;

export type FormData = {
  reasons: string[];
  otherText: string;
  other: boolean;
};
const CancellationFeedbackForm = reduxForm<FormData>({
  form: FEEDBACK_FORM,
})(() => {
  const { t } = useTranslation();

  return (
    <>
      <FormWrapper>
        {DELETE_PROFILE_REASONS.map((reasonKey) => (
          <FieldWrapper key={reasonKey}>
            <Field
              name={`reasons.${reasonKey}`}
              text={
                <H4>{t(`cancel-subscription-reason-${reasonKey}-label`)}</H4>
              }
              component={CheckboxField}
            />
          </FieldWrapper>
        ))}
      </FormWrapper>

      <div
        style={{ maxWidth: FEEDBACK_WIDTH, width: '100%', textAlign: 'center' }}
      >
        <H4 style={{ display: 'block', marginBottom: '8px' }}>
          {t('tell-us-some-details')}
        </H4>
        <Field
          style={{ width: '100%' }}
          name={`otherText`}
          multiline={true}
          component={InputField}
        />
      </div>
    </>
  );
});
export const CancelFlowFeedback = ({ firstName }: { firstName?: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextBlock
        title={t('sad-to-see-you-go', {
          firstName,
          context: firstName ? 'with-first-name' : '',
        })}
        subtitle={t('why-do-you-want-to-cancel')}
      />
      <CancellationFeedbackForm />
    </>
  );
};
