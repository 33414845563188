import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { EntityModels, Query } from 'imddata';
import { useEntries, useQuery } from 'imddata';
import { actions } from 'imddata/actionTypes/ui/selection';
import type { ReduxState } from 'redux/reducers';
import type { DataRowListRendererProps } from '../../molecules/DataRowListRenderer';
import DataRowListRenderer from '../../molecules/DataRowListRenderer';

const noSelected: never[] = [];

export type EntityListSupportedEntities =
  | 'publishers'
  | 'contributors'
  | 'artists';

export type EntityListSelectModel<E extends EntityListSupportedEntities> =
  E extends 'publishers'
    ? EntityModels.Publisher
    : E extends 'contributors'
      ? EntityModels.Contributor
      : E extends 'artists'
        ? EntityModels.Artist
        : never;

type EntityListProps<E extends EntityListSupportedEntities> = {
  entity: E;
  query?: Query;
  virtualized?: boolean;
  ids?: (number | string)[];
  isValid?: DataRowListRendererProps<EntityListSelectModel<E>>['isValid'];
  rowRenderer?: DataRowListRendererProps<
    EntityListSelectModel<E>
  >['rowRenderer'];
};

const EntityList = <E extends EntityListSupportedEntities>({
  entity,
  ids,
  query: searchQuery,
  ...props
}: EntityListProps<E>) => {
  const selected = useSelector(
    ({ ui: { selection } }: ReduxState) => selection[entity] || noSelected
  );
  const dispatch = useDispatch();

  const updateSelection = useCallback((value) => {
    dispatch(actions.updateSelection(value));
  }, []);

  const onChangeSelection = useCallback(
    (_e, id) => {
      updateSelection({ entity, selected: [id] });
    },
    [entity]
  );

  const { queryHash, query } = useQuery({ query: searchQuery });
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useEntries<EntityListSelectModel<E>>({ entity, queryHash, query, ids });

  return (
    <DataRowListRenderer<EntityListSelectModel<E>>
      selected={selected}
      onClickEntry={onChangeSelection}
      onCheckEntry={onChangeSelection}
      loading={loading}
      hasNextPage={hasMorePages}
      data={entries}
      loadNextPage={loadMore}
      {...props}
    />
  );
};

export default EntityList;
