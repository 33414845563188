import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Caption, BodySmall } from 'imdui';
import { useTranslation } from 'react-i18next';
import { ChartsContext } from './ChartsContextProvider';

type Props = {
  disabled?: boolean;
  data: Array<{
    trackId: number;
    skipRate: number;
    streams: number;
    title: string;
    displayArtist: string;
  }>;
};

// TODO: unify between charts

export const Table = styled.table<{
  hideContents?: boolean;
  pulsate?: boolean;
}>`
  z-index: 1;
  display: grid;
  border-collapse: collapse;
  height: 100%;
  overflow: auto;
  min-width: 100%;
  grid-template-columns:
    minmax(16px, 80px)
    auto
    auto
    minmax(100px, 120px)
    minmax(100px, 120px);

  grid-auto-rows: minmax(40px, max-content);
  tbody: {
    overflow: auto;
  }
  thead td {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  td:first-child,
  td:last-child {
    padding-left: 24px;
    padding-right: 24px;
    min-height: 40px;
  }

  th,
  td {
    & > * {
      visibility: ${({ hideContents }) =>
        hideContents ? 'hidden' : 'visible'};
    }

    font-weight: normal;
    padding: 8px 8px 8px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    text-align: left;
  }

  tbody tr:nth-child(odd) td {
    background: #fafafa;
  }
`;

const LOADING_ARRAY = [0, 0, 0, 0, 0];

export function EntriesTable({ data, disabled }: Props) {
  const { percentageFormatter, streamsFormatter } = useContext(ChartsContext);
  const { t } = useTranslation();
  const displayDummyRows = disabled;
  return (
    <Table hideContents={displayDummyRows}>
      <thead>
        <tr>
          <td>
            <Caption>#</Caption>
          </td>
          <td>
            <Caption>{t('title')}</Caption>
          </td>
          <td>
            <Caption>{t('artist')}</Caption>
          </td>
          <td>
            <Caption>{t('streams')}</Caption>
          </td>
          <td>
            <Caption>{t('skiprate')}</Caption>
          </td>
        </tr>
      </thead>
      <tbody>
        {!displayDummyRows
          ? data.map((entry, index) => (
              <tr key={entry.trackId}>
                <td>
                  <BodySmall>{index + 1}</BodySmall>
                </td>
                <td>
                  <BodySmall>{entry.title}</BodySmall>
                </td>
                <td>
                  <BodySmall>{entry.displayArtist}</BodySmall>
                </td>
                <td>
                  <BodySmall>
                    {streamsFormatter.format(entry.streams)}
                  </BodySmall>
                </td>
                <td>
                  <BodySmall>
                    {percentageFormatter.format(entry.skipRate)}
                  </BodySmall>
                </td>
              </tr>
            ))
          : LOADING_ARRAY.map((_v, idx) => (
              <tr key={idx}>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            ))}
      </tbody>
    </Table>
  );
}
