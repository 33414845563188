//
import {
  createS3FileUploader,
  factoryEntityApi,
  requestSagaCreator,
} from '../base';
import callApi from '../callApi';
import * as schemas from '../schemas';
import { entities } from '../actionTypes';

const endpoint = 'releases';

const upload = requestSagaCreator(
  entities.releases.actions.cover.upload,
  ({ id, data }, impersonate) => {
    return callApi({
      impersonate,
      camelize: false,
      endpoint: `releases/${id}/cover/s3`,
      options: { method: 'POST', data },
    });
  }
);

const confirm = requestSagaCreator(
  entities.releases.actions.cover.confirm,
  ({ id }, impersonate) => {
    return callApi({
      impersonate,
      endpoint: `releases/${id}/cover`,
      options: { method: 'POST' },
    });
  }
);

export const covers = {
  confirm,
  upload,
  uploadAndConfirm: createS3FileUploader({
    uploadApiSaga: upload,
    confirmApiSaga: confirm,
    actions: entities.releases.actions.cover,
    types: entities.releases.types.cover,
  }),
  delete: requestSagaCreator(
    entities.releaseCovers.actions.delete,
    ({ id, query }, impersonate) => {
      return callApi({
        impersonate,
        endpoint: query.releaseId
          ? `releases/${query.releaseId}/cover`
          : `release_covers/${id}`,
        options: { method: 'DELETE' },
      });
    }
  ),
};

export default {
  ...factoryEntityApi({
    endpoint,
    entityKey: 'releases',
    schema: schemas.release,
    actions: entities.releases.actions,
    bySchemas: {
      artists: schemas.artist,
      tracks: schemas.track,
    },
    normalizers: {
      fetch: schemas.normalizeWithSales,
    },
  }),
  cover: covers,
};
