import React, { forwardRef } from 'react';
import { LoadingIndicator, pulsateLoadingAnimation, Card } from 'imdui';
import styled from '@emotion/styled';
import { LegendIcon } from './LegendItem';

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  loading?: boolean;
  showLoadingIndicator?: boolean;
};
const GridCard = forwardRef<HTMLDivElement, Props>(
  ({ className, style, loading, showLoadingIndicator, children }, ref) => (
    <Card ref={ref} className={className} style={style}>
      {loading && showLoadingIndicator && (
        <LoaderWrapper>
          <LoadingIndicator size="large" />
        </LoaderWrapper>
      )}
      {children}
    </Card>
  )
);

GridCard.displayName = 'GridCard';

export const ChartsGridCard = styled(GridCard)<Props>`
  position: relative;
  display: grid;
  grid-template-rows: 64px calc(100% - 64px);
  grid-template-columns: 100%;

  .visx-xychart-line,
  .visx-axis-tick .visx-circle,
  .visx-bar,
  ${LegendIcon}, .visx-pie-arcs-group,
  tbody tr:nth-child(odd) td {
    will-change: opacity;
    ${pulsateLoadingAnimation}
    animation-duration: ${({ loading }) => (loading ? '1.5s' : '0s')};
  }
`;
