import styled from '@emotion/styled';
import { Caption } from 'imdui';

export const UpsellMessage = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  background-color: var(--bg-3);
  padding: 32px;
  border-radius: 8px;
  align-items: center;

  svg {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
  }
`;

export const ControlsText = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  a {
    word-break: break-word;
  }
  ${Caption} {
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
  }
  a {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ControlsWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: var(--bg-3);
  border-radius: 16px;
  overflow: hidden;

  flex: 1;
  min-width: 0;
`;

export const ControlsGrid = styled.div`
  display: grid;
  padding: 16px;
  gap: 24px;
  align-items: center;
  grid-auto-flow: row;
  flex: 1;
  min-width: 0;

  @media screen and (min-width: 1060px) {
    grid-template-columns: minmax(0, 1fr) minmax(196px, auto);
  }
`;
