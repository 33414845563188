import type { EntityModels } from 'imddata';
import { useProductPrice } from 'imddata';
import { useContext } from 'react';
import { ApplicationSettingsContext } from './ApplcationSettingsProvider';
import { useTranslation } from 'react-i18next';

export function useProductPriceFormatter(
  {
    maximumFractionDigits,
    minimumFractionDigits,
  }: { maximumFractionDigits?: number; minimumFractionDigits?: number } = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }
) {
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const { price } = useProductPrice('single');

  return new Intl.NumberFormat(numberFormatLocale, {
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
    currency: price?.priceCurrency,
  });
}

export function useProductPriceFormatted(
  productId: EntityModels.ProductId,
  {
    maximumFractionDigits,
    minimumFractionDigits,
  }: { maximumFractionDigits?: number; minimumFractionDigits?: number } = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  },
  subscriptionId?: EntityModels.SubscriptionId | 'none'
) {
  const { price } = useProductPrice(productId, subscriptionId);
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  if (!price) return '';
  if (Number(price.price) === 0) return t('free');

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    minimumFractionDigits,
    maximumFractionDigits,
    style: 'currency',
    currency: price.priceCurrency,
  });
  return priceFormatter.format(Number(price.price));
}
