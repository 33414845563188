import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SubscriptionSubmitHandler } from 'components';
import { useSubscriptionManagement } from '../logic';
import { ConfirmationWindow } from 'imdshared';
import { useHistory } from 'react-router';
import { ConfirmInstantUpgradeWindow } from './ConfirmInstantUpgradeWindow';
import moment from 'moment';
import type { CustomerSubscriptionUpgrade } from 'imddata';
import { SubscriptionSelectionContext } from './SubscriptionSelectionContext';

export const SubscriptionSelectionManager = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit?: SubscriptionSubmitHandler;
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { pendingInvoiceId, ordering, selectSubscription, ...rest } =
    useSubscriptionManagement({ onSubmit });

  const [selectedForInstantUpgrade, setSelectedForInstantUpgrade] =
    useState<CustomerSubscriptionUpgrade>();
  const [displayPendingInvoice, setDisplayPendingInvoice] = useState(false);
  useEffect(() => {
    if (pendingInvoiceId) {
      setDisplayPendingInvoice(true);
    }
  }, [pendingInvoiceId]);

  const handleSubscription = useCallback(
    (s?: CustomerSubscriptionUpgrade | { mode: 'cancel' }) => {
      if (!s) return;
      switch (s.mode) {
        case 'renew_to_different':
        case 'paid_discounted':
        case 'paid_pro_rated':
        case 'paid':
          selectSubscription(s);
          break;
        case 'instant':
        case 'instant_upgrade':
        case 'legacy_upgrade':
          setSelectedForInstantUpgrade(s);
          break;
        case 'cancel':
          if (s && onSubmit) onSubmit(s);
          break;
        case 'downgrade':
          if (s && onSubmit)
            onSubmit({ mode: 'downgrade', subscriptionId: s.subscriptionId });
          break;
      }
    },
    [setSelectedForInstantUpgrade, selectSubscription]
  );

  const createHandleSubscription = useCallback(
    (s?: CustomerSubscriptionUpgrade | { mode: 'cancel' }) => () => {
      handleSubscription(s);
    },
    [setSelectedForInstantUpgrade, selectSubscription]
  );

  return (
    <>
      <SubscriptionSelectionContext.Provider
        value={{
          ...rest,
          ordering,
          handleSubscription,
          createHandleSubscription,
        }}
      >
        {children}
      </SubscriptionSelectionContext.Provider>
      <ConfirmationWindow
        title={t('outstanding-invoice')}
        isOpen={displayPendingInvoice}
        onRequestClose={() => {
          setDisplayPendingInvoice(false);
        }}
        onConfirm={() => {
          history.push(`/account/invoices/${pendingInvoiceId}`);
        }}
        cancelLabel={t('close')}
        confirmLabel={t('view-order')}
        message={t('you-have-outstanding-invoice-for-subscription')}
      />
      <ConfirmInstantUpgradeWindow
        loading={!!ordering}
        isOpen={!!selectedForInstantUpgrade}
        title={t('confirm-plan-upgrade')}
        close={() => {
          setSelectedForInstantUpgrade(undefined);
        }}
        message={t('upgraded-renewal-date', {
          endAt: moment(selectedForInstantUpgrade?.endAt).format('LL'),
        })}
        onConfirm={() => {
          selectSubscription(selectedForInstantUpgrade);
          setSelectedForInstantUpgrade(undefined);
        }}
      />
    </>
  );
};
