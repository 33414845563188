import type { OverviewGroup } from 'imddata/types/entities';
import { useTranslation } from 'react-i18next';

export const OverviewGroupLabel = ({
  overviewGroup,
}: {
  overviewGroup: OverviewGroup;
}) => {
  const { t } = useTranslation();
  const getLabel = (g: OverviewGroup): string => {
    if (g.items.length === 1) {
      return t(g.items[0].id, { defaultValue: g.items[0].name });
    }

    if (g.data?.releaseId?.length) {
      return t('music-distribution');
    }

    if (g.data?.trackId?.length) {
      return t('content-id');
    }

    return g.label || t('no-order-label');
  };

  return <>{getLabel(overviewGroup)}</>;
};
