//
import React, { useEffect } from 'react';
import { callAuthApi } from 'imddata';
import { useDispatch } from 'react-redux';
import { LoadingIndicator, Centered } from 'imdshared';
import { actions } from 'imddata/actionTypes/auth';
import { setAuth } from 'imddata/utils/auth';

export default function Signout() {
  const dispatch = useDispatch();
  useEffect(() => {
    callAuthApi.signOut().then(() => {
      setAuth(undefined);

      dispatch(actions.reset());
    });
  }, []);

  return (
    <Centered style={{ width: '100%', height: '100vh' }}>
      <LoadingIndicator size="large" />
    </Centered>
  );
}
