//
import React from 'react';
import styled from '@emotion/styled';
import { Content, IconButton, IconsCollection, Body } from 'imdui';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  width: 100%;
  position: relative;
  border-radius: 0px;
  background-color: white;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  background: #fff;

  @media (min-width: 840px) {
    flex-wrap: nowrap;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
  margin-bottom: 16px;

  @media (min-width: 840px) {
    margin-bottom: 0px;
  }
`;

const StyledAsset = styled.div`
  & > * {
    width: 48px;
    height: 48px;
  }
  margin-right: 24px;
`;

const TextWrapper = styled.div`
  ${Content} {
    margin-bottom: 4px;
  }

  * {
    display: block;
  }
`;
const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const AppBanner = ({
  asset,
  title,
  text,
  children,
  className,
  onRequestClose,
}: {
  onRequestClose?: () => void;
  className?: string;
  children?: React.ReactNode;
  asset?: React.ReactNode;
  title?: string;
  text?: string;
}) => (
  <Wrapper className={className}>
    <ContentWrapper>
      <StyledAsset>{asset}</StyledAsset>

      <TextWrapper>
        <Content>{title}</Content>

        <Body>{text}</Body>
      </TextWrapper>
    </ContentWrapper>

    {children}

    {onRequestClose && (
      <IconButtonStyled
        newIcon={IconsCollection.cross}
        onClick={onRequestClose}
      />
    )}
  </Wrapper>
);
