import { action } from 'imddata';

// export const GUIDE = 'guide';
// export const TRACKS_DETAILS = 'tracks-details';
// export const SUMMARY = 'summary';
// export const RELEASE_DETAILS = 'release-details';
// export const DELIVERY = 'delivery';
// export const CONTENT_ID_STEPS = [TRACKS_DETAILS, DELIVERY, SUMMARY];
// export const PUBLISHING_STEPS = [GUIDE, TRACKS_DETAILS, SUMMARY];
// const MUSIC_DISTRIBUTION_STEPS = [
//   RELEASE_DETAILS,
//   TRACKS_DETAILS,
//   DELIVERY,
//   SUMMARY,
// ];
// export const CONTENT_ID = 'content-id';
// export const PUBLISHING = 'publishing';

export enum types {
  INIT_PRODUCT_PAGE = '@ui/products/INIT_PRODUCT_PAGE',
  DELETE_PRODUCT_PAGE = '@ui/products/DELETE_PRODUCT_PAGE',
  CHANGE_STEP_STATE = '@ui/products/CHANGE_STEP_STATE',
  CHANGE_PRODUCT_PAGE = '@ui/products/CHANGE_PRODUCT_PAGE',
}

export const actions = {
  initProductPage: (payload: {
    id: string;
    product: string;
    data?: Record<string, unknown>;
    steps: { id: string }[];
  }) => action(types.INIT_PRODUCT_PAGE, payload),

  changeProductPage: ({
    id,
    product,
    data,
  }: {
    id: string;
    product: string;
    data: Record<string, unknown>;
  }) =>
    action(types.CHANGE_PRODUCT_PAGE, {
      id,
      product,
      data,
    }),

  changeProductStep: (
    {
      id,
      product,
      stepId,
      payload,
    }: {
      id: string;
      product: string;
      stepId: string;
      payload: Record<string, unknown>;
    },
    meta?: Record<string, unknown>
  ) =>
    action(
      types.CHANGE_STEP_STATE,
      {
        product,
        id,
        stepId,
        payload,
      },
      meta
    ),

  deleteProductPage: ({ id, product }: { id: string; product: string }) =>
    action(types.DELETE_PRODUCT_PAGE, { id, product }),
};
