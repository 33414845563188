//
import { map, over, set, lensPath } from 'ramda';
import { entities } from '../../../../actionTypes';

const initialState = {
  entities: {},
  searchHistory: {},
  keys: [],
};

const { delete: deleteActions } = entities.userNotifications.types;

const deleteNotificationReducer = (state, action) => {
  if (action.type === deleteActions.REQUEST) {
    const {
      payload: { id },
    } = action;
    if (!id) {
      return state;
    }
    return set(lensPath(['entities', id, 'wasRead']), true, state);
  }
  if (action.type === deleteActions.SUCCESS) {
    const {
      payload: { id },
    } = action;
    if (!id) {
      return over(
        lensPath(['entities']),
        map(set(lensPath(['wasRead']), true)),
        state
      );
    }
    return state;
  }
  if (action.type === deleteActions.FAILURE) {
    const {
      payload: { id },
    } = action;
    if (!id) return state;
    return set(lensPath(['entities', id, 'wasRead']), false, state);
  }
  return state;
};

export default function userNotifications(state = initialState, action) {
  return [deleteNotificationReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
