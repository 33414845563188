//
import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'imddata';
import { QueryBuilderInput } from '../../atoms';
import type { Props, InputQuery } from '../TracksQueryBuilder';
import {
  useArtistsOptions,
  useDeliveryOptions,
  useGenresOptions,
} from '../TracksQueryBuilder';

export default function ReleaseQueryBuilder({ value, onChange }: Props) {
  const [searchInputQuery, setSearchInputQuery] = useState<InputQuery>({});
  const { query, queryHash, updateQuery } = useQuery();
  useEffect(() => {
    setSearchInputQuery(searchInputQuery);
  }, [queryHash]);
  const { options: deliveryOptions } = useDeliveryOptions();
  const { options: artistsOptions, loading: artistsLoading } =
    useArtistsOptions(searchInputQuery);

  const { options: genresOptions, loading: genresLoading } =
    useGenresOptions(searchInputQuery);

  const loading = genresLoading || artistsLoading;

  const options = useMemo(
    () => [...deliveryOptions, ...genresOptions, ...artistsOptions],
    [genresOptions, artistsOptions]
  );

  return (
    <QueryBuilderInput
      value={value}
      loading={loading}
      options={options}
      onChange={onChange}
      query={query}
      onInputChange={updateQuery}
    />
  );
}
