//
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEntriesProvider } from 'imddata/providers/hooks/base';
import { decamelize } from 'humps';
import { difference, groupWith } from 'ramda';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { AmpSquareIcon } from '@imus/base-ui';
import constants from '../constants';
import { createJoinTagField } from '../../helpers';
import JoinTags from '../JoinTags';
import EditItemForm from './EditItemForm';
import { useCustomerArtistLimits } from 'imddata';
import { useOpenTier2UpsellContext, useSubscriptionUpsell } from '../../logic';

// TODO: withTranslation and/or fetch dynamically

const isUpsellRole = (role) => ['main', 'versus', 'meets'].indexOf(role) >= 0;

export const makeData = (roles, t, limitReached, allowedArtists) => {
  const artistRoles = {
    label: 'Role',
    key: 'role',
    order: roles.map((role) => role.value),
    options: roles.reduce(
      (acc, role) => ({
        ...acc,
        [role.value]: {
          value: role.value,
          label: t(role.label),
          icon: ({ value }) =>
            limitReached &&
            isUpsellRole(role.value) &&
            allowedArtists?.indexOf(value.artistId) < 0 ? (
              <AmpSquareIcon />
            ) : undefined,
          joinString: role.joinString,
          static: role.static,
          displayArtist: role.displayArtist,
        },
      }),
      {}
    ),
  };

  return {
    tags: ['roles'],
    roles: artistRoles,
  };
};

const combineItems = (data, values, includeRole = false) => {
  const roles = data.roles && data.roles.options;
  return values.reduce((acc, { label, role }, index) => {
    if (roles[role] && roles[role].displayArtist) {
      const roleLabel =
        roles && role && roles[role]
          ? roles[role].joinString
          : 'undefined role';
      if (index === 0) {
        return includeRole ? `${roleLabel} ${label}` : label;
      }
      return index === values.length - 1
        ? `${acc} & ${label}`
        : `${acc}, ${label}`;
    }
    return acc;
  }, '');
};

const defaultFormatter = (data, values) => {
  const grouped = groupWith((a, b) => a.role === b.role, values);

  return grouped.reduce((acc, group, index) => {
    if (index === 0) {
      return combineItems(data, group);
    }
    return `${acc} ${combineItems(data, group, true)}`;
  }, '');
};
const empty = [];

export const useDisplayArtist = (artistWrappers) => {
  const { t } = useTranslation();
  const data = useMemo(() => makeData(constants.artists.trackRoles, t), []);
  const artistIds = useMemo(
    () => artistWrappers?.map((aw) => aw.artistId) || empty,
    [artistWrappers]
  );
  const { entries: artists } = useEntriesProvider({
    entity: 'artists',
    ids: artistIds,
  });

  const displayArtist = useMemo(() => {
    if (!artists || !artistWrappers) return '';

    const values = artistWrappers
      .filter((entry) => !!entry)
      .map((aw) => {
        return {
          role: aw.role,
          label: artists.find((a) => a.id === aw.artistId)?.defaultName?.name,
        };
      });

    const hasNonFetched = values.find(({ label }) => label === null);

    if (hasNonFetched) return '';

    return defaultFormatter(data, values);
  }, [artists, artistWrappers]);

  return displayArtist;
};

export const useFormDisplayArtist = (form) => {
  const selector = useMemo(() => formValueSelector(form), [form]);
  const artistWrappers = useSelector((state) => selector(state, 'artists'));

  return useDisplayArtist(artistWrappers);
};
// eslint-disable-next-line
export const isValidArtist = (v) =>
  v.targets?.addToDelivery?.isMet !== false &&
  v.defaultName?.targets?.addToDelivery?.isMet !== false;

const isDisabled = (dropdown, dropdownIdx, rowIdx) => {
  if (rowIdx === 0) {
    return true;
  }
  return false;
};

const shouldTriggerUpsell = (
  newValues,
  previousValues,
  allowedArtistIds,
  allowance = 0
) => {
  const mainIds = newValues
    .filter((v) => isUpsellRole(v.role))
    .map((v) => v.artistId);

  const previousMainIds = previousValues
    .filter((v) => isUpsellRole(v.role))
    .map((v) => v.artistId);

  // Do not trigger if removing mains
  if (
    difference(mainIds, allowedArtistIds).length <
    difference(previousMainIds, allowedArtistIds).length
  )
    return false;

  return difference(mainIds, allowedArtistIds).length > allowance;
};
const emptyArtists = [];

const ArtistJoin = ({
  data,
  enableArtistLimit = false,
  onUpdateDisplay,
  disabled,
  className,
  style,
  value,
  ...props
}) => {
  const tier2Context = useOpenTier2UpsellContext();
  const artistLimit = useCustomerArtistLimits();
  const globalLimitReached = artistLimit?.available === 0;
  const globalAllowance = enableArtistLimit
    ? Number.isInteger(artistLimit?.available)
      ? artistLimit.available
      : Number.POSITIVE_INFINITY
    : Number.POSITIVE_INFINITY;

  const { t } = useTranslation();
  const usedMainArtists = artistLimit?.data?.artistId || emptyArtists;

  const reachedLimit =
    globalLimitReached ||
    (value &&
      value.filter(
        ({ artistId, role }) =>
          usedMainArtists.indexOf(artistId) < 0 && isUpsellRole(role)
      ).length >= globalAllowance);

  const finalData = useMemo(() => {
    if (data) return data;
    return makeData(constants.artists.roles, t, reachedLimit, usedMainArtists);
  }, [data, reachedLimit]);
  const { open: openUpsell } = useSubscriptionUpsell();

  const mapEntry = useCallback((result, artist) => {
    const shops = artist.shopArtistIds
      ? Object.keys(artist.shopArtistIds)
          .filter((key) => !!artist.shopArtistIds[key]?.id)
          .map((key) => t(decamelize(`${key}`).replace('_', '-')))
      : [];
    const valid = isValidArtist(artist);
    const sublabel = shops.join(', ');

    return {
      ...result,
      invalid: !valid,
      sublabel,
    };
  }, []);

  return (
    <JoinTags
      key="tags"
      isDisabled={isDisabled}
      mapEntry={mapEntry}
      renderForm={EditItemForm}
      data={finalData}
      defaultRole={value.length ? 'featuring' : 'main'}
      placeholder={t('type-name', { defaultValue: 'Type name' })}
      idKey="artistId"
      addLabel={t('add-artist')}
      createLabel={t('create-artist')}
      label={t('add-search-artist')}
      entity="artists"
      disabled={disabled}
      value={value}
      className={className}
      style={style}
      {...props}
      onChange={(v) => {
        if (shouldTriggerUpsell(v, value, usedMainArtists, globalAllowance)) {
          openUpsell({
            ...tier2Context,
            analytics: { detail: 'artists' },
          });
          return;
        }
        props.onChange(v);
      }}
    />
  );
};

export const ArtistJoinField = createJoinTagField(ArtistJoin, 'artists');

export default ArtistJoin;
