import React from 'react';
import { OptionGrid, OptionGridItem } from 'imdui';

interface Genre {
  value: string;
  label: string;
}

export default function GenreSelectField({
  data,
  input = {},
  onChange,
}: {
  input: any;
  data: Genre[];
  onChange: (v: Genre['value']) => void;
}): JSX.Element {
  return (
    <OptionGrid>
      {data.map((g: Genre) => (
        <OptionGridItem
          onClick={(): void => {
            if (input.onChange) {
              input.onChange(g.value);
            }
            if (onChange) {
              onChange(g.value);
            }
          }}
          key={g.value}
          selected={input.value === g.value}
        >
          {g.label}
        </OptionGridItem>
      ))}
    </OptionGrid>
  );
}
