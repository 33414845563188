import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { themeLight as theme } from 'imdui';
import type { Moment } from 'moment';
import type { ModifiersShape } from 'react-dates';

type Props = {
  testId?: string;
  testValue?: string;
  day: Moment;
  modifiers?: ModifiersShape;
  deliveryOption?: { id: string };
  isProcessing?: boolean;
  onHovered?: (day: Moment) => void;
};

const Day = styled.span<{
  modifiers?: Props['modifiers'];
  deliveryOption: Props['deliveryOption'];
}>`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: ${({ modifiers }) => {
    if (modifiers?.has('today')) {
      return '50%';
    }

    return '8px';
  }};
  font-size: 14px;
  font-weight: ${({ modifiers }) => (modifiers?.has('selected') ? 700 : 400)};
  color: ${({ modifiers }) => {
    if (modifiers?.has('selected-start') || modifiers?.has('selected-end')) {
      return '#fff';
    }
    return 'inherit';
  }};
  background-color: ${({ modifiers, deliveryOption }) => {
    if (modifiers?.has('selected-start') || modifiers?.has('selected-end')) {
      return theme.accent.green;
    }
    if (
      modifiers &&
      (modifiers.has('hovered-span') ||
        modifiers.has('after-hovered-start') ||
        modifiers.has('before-hovered-end') ||
        modifiers?.has('selected-span'))
    ) {
      return theme.lights[2];
    }
    if (modifiers?.has('blocked')) {
      return 'none';
    }
    if (deliveryOption?.id === 'ultraexpressdelivery') {
      return '#c4e99e';
    }
    return '#fafafa';
  }};
  box-shadow: inset 0 0 0 1px
    ${({ modifiers, deliveryOption }) => {
      if (
        modifiers?.has('blocked') ||
        deliveryOption?.id === 'ultraexpressdelivery'
      ) {
        return 'none';
      }
      if (deliveryOption?.id === 'expressdelivery') {
        return theme.accent.green;
      }
      return '#eee';
    }};
  opacity: ${({ modifiers }) =>
    modifiers?.has('blocked-out-of-range') ? 0.3 : 1};

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: ${({ modifiers }) =>
      modifiers?.has('selected') ? 'rgba(0, 0, 0, 0.1)' : 'none'};
  }

  :hover {
    ::after {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  :active {
    ::after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

const IsProcessingIndicator = styled.div<{ isProcessing: boolean }>`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${theme.state.warning};
  opacity: ${({ isProcessing }) => (isProcessing ? 1 : 0)};
  box-shadow: 0 0 0 1px white;
`;

const DayNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const DayRenderer = ({
  testId,
  testValue,
  day,
  modifiers,
  deliveryOption,
  onHovered,
  isProcessing,
}: Props) => {
  useEffect(() => {
    if (onHovered && modifiers && modifiers.has('hovered')) {
      onHovered(day);
    }
  }, [modifiers && modifiers.has('hovered')]);
  return (
    <Day
      data-test-id={testId || `Day-${day.format('YYYY-MM-DD')}`}
      data-test-value={testValue}
      modifiers={modifiers}
      deliveryOption={deliveryOption}
    >
      <IsProcessingIndicator isProcessing={!!isProcessing} />

      <DayNumber>{day.format('D')}</DayNumber>
    </Day>
  );
};

export default DayRenderer;
