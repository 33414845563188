import { css } from '@emotion/react';

export const fileStyle = css`
  flex: 1;
  display: flex;
  gap: 16px;
`;

export const previewStyle = css`
  align-self: center;
  --on-surface: var(--accent);
`;

export const textColumnStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
