import { find } from 'ramda';
import { useMemo } from 'react';
import * as modelSelectors from '../../../selectors/modelSelectors';
import { EntityModels } from '../../../types';
import { NestDefinition } from '../base';
import { useEntry, useEntries } from '../hooks';

export const defaultInvoiceQuery = { with: 'items' };

export const defaultInvoiceNest: NestDefinition = [
  {
    key: 'items',
    entity: 'invoiceItems',
  },
];

type InvoiceNested = EntityModels.Nest<
  EntityModels.Invoice,
  {
    items?: EntityModels.InvoiceItem[];
  }
>;

type UseInvoiceOptions = {
  id?: number | string;
  passive?: boolean;
};

export function useInvoice({ id, passive }: UseInvoiceOptions = {}) {
  return useEntry<InvoiceNested>({
    passive,
    entity: 'invoices',
    id,
    query: defaultInvoiceQuery,
    nest: defaultInvoiceNest,
  });
}
const invoicesQuery = {
  sort_by:
    'status,asc,payment_failed,payment_timeout,legacy_unpaid,legacy_pending,unpaid,pending,paid;id,desc',
};
export function useInvoices() {
  return useEntries<EntityModels.Invoice>({
    entity: 'invoices',
    query: invoicesQuery,
  });
}

export function useHasUnpaidInvoices() {
  const { entries, request } = useInvoices();
  const hasInvoices = entries && !!entries.length;
  const hasUnpaid = useMemo(
    () =>
      hasInvoices &&
      find((invoice) => {
        return modelSelectors.invoice.canBePaid(invoice);
      }, entries),
    [entries]
  );
  return { hasInvoices, hasUnpaid, request };
}
