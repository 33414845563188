//
import * as R from 'ramda';
import { entities } from '../../../actionTypes';
import uploadFileRequestReducerFactory from '../../../reducerFactories/uploadFileRequestReducerFactory';

export const createLens = (ids) => {
  const [releaseId, volumeId] = ids;
  if (R.isNil(volumeId)) {
    return R.lensPath(['items', releaseId, 'volumes']);
  }
  return R.lensPath(['items', releaseId, 'volumes', 'items', volumeId]);
};

const coverReducer = uploadFileRequestReducerFactory(
  entities.releases.types.cover
);

export default function releaseRequest(state, action) {
  return [coverReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
