export interface AuthToken {
  tokenType: string;
  expiresIn: number;
  accessToken: string;
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  token_type: string;
}

export interface EmptyToken {
  emptyToken: true;
}

const emptyToken: EmptyToken = { emptyToken: true };

export function getAuth(): AuthToken | EmptyToken {
  try {
    if (localStorage) {
      const token = localStorage.getItem('auth');
      return token ? JSON.parse(token) : emptyToken;
    }
    return emptyToken;
  } catch (err) {
    return emptyToken;
  }
}

export function setAuth(auth: AuthToken | undefined): void {
  if (!auth || !auth.expiresIn) {
    localStorage.setItem('auth', '');
    return;
  }
  // convert expiresIn from seconds to miliseconds and subtract 30 seconds (as a buffer)
  const expireTimestamp = new Date().getTime() + +auth.expiresIn * 1000 - 30000;
  localStorage.setItem('auth', JSON.stringify({ ...auth, expireTimestamp }));
}

export default {
  getAuth,
};
