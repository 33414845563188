//
import React, { memo, useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { NavStatusDot, Notifications, OrdersCountProvider } from 'components';
import { NavDrawerContext, LayoutContext, IconButton, Icons } from 'imdshared';
import { AppLogo, ShoppingCartIcon } from '@imus/base-ui';

const NavHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 32px 0 32px;
`;

const ButtonsWrapper = styled.div`
  gap: 16px;
  display: flex;
  height: 24px;
`;

const StyledOrdersLink = styled(Link)`
  position: relative;
`;

const renderCount = (ordersCount: number) =>
  ordersCount > 0 ? <NavStatusDot /> : null;

const CloseDrawerButton = () => {
  const { close } = useContext(NavDrawerContext);
  return <IconButton icon={Icons.arrows.back} onClick={close} />;
};

const MobileHeader = () => <CloseDrawerButton />;

const DesktopHeader = () => (
  <Link style={{ display: 'inline-flex' }} to="/dashboard">
    <AppLogo />
  </Link>
);

const NavHeader = () => {
  const { close } = useContext(NavDrawerContext);
  const { isLayoutCollapsed } = useContext(LayoutContext);
  const handleRequestClose = useMemo(
    () => (isLayoutCollapsed ? close : () => null),
    [isLayoutCollapsed]
  );

  return (
    <NavHeaderContainer>
      {isLayoutCollapsed ? <MobileHeader /> : <DesktopHeader />}

      <ButtonsWrapper>
        <span onClick={handleRequestClose}>
          <StyledOrdersLink to="/order/overview">
            <IconButton inline={true} icon={ShoppingCartIcon} />

            <OrdersCountProvider>{renderCount}</OrdersCountProvider>
          </StyledOrdersLink>
        </span>

        <Notifications onClick={handleRequestClose} />
      </ButtonsWrapper>
    </NavHeaderContainer>
  );
};

export default memo(NavHeader);
