import type { AppLocale } from 'imddata';

// To obtain values put breakpoint on line 231
// on custom-fields-body.js file loaded by beacon (run prettify by clicking `{}` button)
// Or line 63 to get list of all rendered
const EN_ANSWERS = [
  {
    id: 33735,
    value: 182988,
  },
  {
    id: 33715,
    value: 174655,
  },
  {
    id: 33716,
    value: 'no',
  },
  {
    id: 33739,
    value: 174806,
  },
  {
    id: 33737,
    value: 174802,
  },
];

const DE_ANSWERS = [
  {
    id: 33974,
    value: 182989,
  },
  {
    id: 33975,
    value: 175933,
  },
  {
    id: 33976,
    value: 'no',
  },
  {
    id: 33977,
    value: 175935,
  },
  {
    id: 33978,
    value: 175937,
  },
];

const ES_ANSWERS = [
  {
    id: 33979,
    value: 182993,
  },

  {
    id: 33980,
    value: 175948,
  },
  {
    id: 33981,
    value: 'no',
  },
  {
    id: 33982,
    value: 175950,
  },
  {
    id: 33983,
    value: 175952,
  },
];
const PT_ANSWERS = [
  {
    id: 29666,
    value: 182994,
  },

  {
    id: 33984,
    value: 175961,
  },
  {
    id: 33985,
    value: 'no',
  },
  {
    id: 33986,
    value: 175963,
  },
  {
    id: 33987,
    value: 175965,
  },
];
const FR_ANSWERS = [
  {
    id: 33969,
    value: 182990,
  },

  {
    id: 33970,
    value: 175910,
  },
  {
    id: 33971,
    value: 'no',
  },
  {
    id: 33972,
    value: 175912,
  },
  {
    id: 33973,
    value: 175915,
  },
];
const IT_ANSWERS = [
  {
    id: 33734,
    value: 182991,
  },

  {
    id: 29843,
    value: 174800,
  },
  {
    id: 33736,
    value: 'no',
  },
  {
    id: 33740,
    value: 174808,
  },
  {
    id: 33738,
    value: 174804,
  },
];

export const getBeaconPrefill = (
  locale: AppLocale
): Array<{ id: number; value: any }> => {
  switch (locale) {
    case 'en':
      return EN_ANSWERS;
    case 'es':
      return ES_ANSWERS;
    case 'it':
      return IT_ANSWERS;
    case 'pt':
      return PT_ANSWERS;
    case 'de':
      return DE_ANSWERS;
    case 'fr':
      return FR_ANSWERS;
    default:
      return [];
  }
};
