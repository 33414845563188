import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { Trans, useTranslation } from 'react-i18next';
import { callAuthApi } from 'imddata';
import { setAuth } from 'imddata/utils/auth';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { Body } from 'imdui';
import {
  useCurrentLocale,
  Button,
  NewInputField,
  CheckboxField,
} from 'imdshared';
import isEmail from 'validator/lib/isEmail';
import { SocialSignin, useSignupCodes, validatePassword } from '../../shared';

const formStyle = css`
  padding: 24px;
`;

const checkboxStyle = css`
  margin-bottom: 24px;
`;

const buttonStyle = css`
  width: 100%;
  justify-content: center;
`;

type FormData = {
  email: string;
  password: string;
  passwordConfirmation: string;
  lastName: string;
  firstName: string;
  receiveNewsletters: boolean;
  acceptTermsAndConditions: boolean;
};

function Signup({ submitting, handleSubmit }: InjectedFormProps<FormData>) {
  const dispatch = useDispatch();
  const locale = useCurrentLocale();

  const { affiliate, referral } = useSignupCodes();

  const submitForm = useCallback(
    ({ email, receiveNewsletters, ...values }: FormData) => {
      return callAuthApi
        .signup({
          ...values,
          locale,
          affiliatePartnerToken: affiliate,
          referralToken: referral,
          receiveNewsletters,
          email: email.trim(),
        })
        .then((result) => {
          if ('error' in result && 'validationError' in result.error) {
            // @ts-ignore
            throw new SubmissionError(result.error.validationError);
          }
          if ('error' in result) {
            throw new SubmissionError({ _error: result.error.message });
          }
          setAuth(result.response as any);
          dispatch(loadLoggedUser({ signup: 'app' }));
        });
    },
    [locale, referral, affiliate]
  );
  const { t } = useTranslation();

  return (
    <form name="signup" onSubmit={handleSubmit(submitForm)} css={formStyle}>
      <Helmet>
        <title>{t('page-title-signup')}</title>
      </Helmet>

      <Field
        name="firstName"
        testId="Signup-firstName"
        label={t('first-name')}
        placeholder={t('enter-your-first-name')}
        component={NewInputField}
      />

      <Field
        name="lastName"
        testId="Signup-lastName"
        label={t('last-name')}
        placeholder={t('enter-your-last-name')}
        component={NewInputField}
      />

      <Field
        name="email"
        testId="Signup-email"
        label={t('email')}
        placeholder={t('enter-your-email')}
        component={NewInputField}
      />

      <Field
        name="password"
        testId="Signup-password"
        label={t('password')}
        helperText={t('password-requirement-hint')}
        placeholder={t('enter-your-password')}
        type="password"
        component={NewInputField}
      />

      <Field
        name="passwordConfirmation"
        testId="Signup-passwordConfirmation"
        label={t('confirm-password')}
        placeholder={t('enter-your-password-again')}
        type="password"
        component={NewInputField}
      />

      <Field
        name="receiveNewsletters"
        type="checkbox"
        css={checkboxStyle}
        label={t('receive-newsletters')}
        text={t('receive-newsletters')}
        component={CheckboxField}
      />

      <Field
        name="acceptTermsAndConditions"
        type="checkbox"
        css={checkboxStyle}
        label={t('terms')}
        testId="acceptTermsAndConditions"
        text={
          <Body>
            <Trans
              i18nKey="terms-and-conditions-signup-confirmation"
              defaults="I accept <0>terms</0> and <1>conditions</1>"
              components={[
                <a
                  key={'1'}
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                  tabIndex={0}
                  target="_blank"
                  href={t('terms-link-for-order')}
                >
                  0
                </a>,
                <a
                  key={'2'}
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  rel="noopener noreferrer"
                  tabIndex={0}
                  target="_blank"
                  href={t('privacy-policy-link')}
                >
                  {'a'}
                </a>,
              ]}
            />
          </Body>
        }
        component={CheckboxField}
      />

      <Button
        disabled={submitting}
        primary={true}
        testId="Signup-submit"
        type="submit"
        text={t('register')}
        showLoading={submitting}
        css={buttonStyle}
      />

      <SocialSignin />
    </form>
  );
}

const validate = (values: FormData) => {
  const errors: Partial<Record<keyof FormData, string>> = {};
  if (!values.firstName) {
    errors.firstName = 'required';
  }
  if (!values.acceptTermsAndConditions) {
    errors.acceptTermsAndConditions = 'accept-terms-error';
  }
  if (!values.lastName) {
    errors.lastName = 'required';
  }
  if (!values.email) {
    errors.email = 'required';
  } else if (!isEmail(values.email)) {
    errors.email = 'invalid-email-address';
  }

  const passwordErrors = validatePassword(values);

  const err = { ...errors, ...passwordErrors };
  return err;
};

export default reduxForm({
  form: 'loginForm',
  getFormState: (state) => state.form,
  validate,
})(Signup);
