import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'imdui';

type Props = {
  label: string;
  reset?: () => void;
  submitted: boolean;
  pristine: boolean;
  submitting: boolean;
};

const ProfileFormButtons: React.FC<Props> = ({
  label,
  reset,
  submitted,
  pristine,
  submitting,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {reset && (
        <Button
          text={t('cancel')}
          disabled={submitting || pristine}
          onClick={() => reset()}
        />
      )}

      <Button
        type="submit"
        testId={`ProfileForm-${
          submitted ? 'submitted' : submitting ? 'submitting' : 'pristine'
        }`}
        primary={!pristine && true}
        text={label}
        disabled={submitting}
      />
    </>
  );
};

export default ProfileFormButtons;
