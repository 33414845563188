import styled from '@emotion/styled';

const FieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0%;

  :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export default FieldWrapper;
