/* eslint-disable import/no-import-module-exports */
// rule false alarms on module.hot
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import tracker from './tracker';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = () => persistReducer(persistConfig, rootReducer());

const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    console.error(error);
    console.error(sagaStack);

    if (window && window.Raven) {
      window.Raven.captureException(error, { extra: sagaStack });
    } else if (window.Sentry) {
      window.Sentry.withScope((scope) => {
        scope.setExtras(sagaStack);
        scope.setExtras({ origin: 'saga' });
        window.Sentry.captureException(error);
      });
    }
  },
});

export default function createStore() {
  const middlewares = [
    applyMiddleware(sagaMiddleware),
    applyMiddleware(tracker),
  ];

  if (__DEVELOPMENT__) {
    middlewares.push(
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    );
  }
  const finalCreateStore = compose(...middlewares)(reduxCreateStore);
  const store = finalCreateStore(persistedReducer());
  const persistor = persistStore(store);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line
      const nextRootReducer = require('./reducers').default;

      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }

  return { store, sagaMiddleware, persistor };
}
