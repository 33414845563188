import React from 'react';
import { useTranslation } from 'next-i18next';
import type { ReleaseLink, TemplateData } from '../types';
import { Icons, Text } from '@imus/services-ui';
import { shopStyle, shopWrapperStyle, shopTextStyle, ShopIcon } from './styles';

type Props = {
  releaseLink: ReleaseLink;
  data: TemplateData;
};

const DOWNLOAD_SHOPS = ['qobuzdownload', 'itunes', 'amazonmusicdownload'];

const isDownloadShop = (id: string) => {
  return DOWNLOAD_SHOPS.indexOf(id) >= 0;
};

const getShopType = (id: string): 'download' | 'stream' => {
  if (isDownloadShop(id)) {
    return 'download';
  }

  return 'stream';
};

const ShopItem: React.FC<Props> = ({
  releaseLink: { name, url, presave, preorder, isCustom },
  data: {
    releasePageId,
    release: { barcode, title, displayArtist, cover, releaseAt },
  },
}) => {
  const { t } = useTranslation();

  const id = isCustom ? 'custom' : name;
  const shopName = isCustom ? name : t(id);

  const shopType = (() => {
    if (isCustom) {
      return null;
    }
    if (presave) {
      return 'presave';
    }
    if (preorder) {
      return 'preorder';
    }
    return getShopType(name);
  })();

  // i18next-extract-disable-next-line
  return (
    <a
      css={shopStyle}
      data-test-id={`ShopItem-${id}`}
      data-component="shop-link"
      data-shop={id}
      id={id}
      target="_blank"
      rel="noreferrer"
      onMouseDown={() => {
        if (window && window.localStorage) {
          window.localStorage.setItem(
            'release_json',
            JSON.stringify({
              backUrl: window.location.href,
              barcode,
              releasePageId,
              title,
              displayArtist,
              coverUrl: cover?.url,
              releaseAt: releaseAt,
            })
          );
        }
      }}
      href={url || ''}
    >
      <div css={shopWrapperStyle}>
        <ShopIcon id={isCustom ? 'custom' : id} />
      </div>

      <div css={shopTextStyle}>
        <Text.BodyS>{t('play-on', { context: shopType })}</Text.BodyS>
        <Text.ContentM>{shopName}</Text.ContentM>
      </div>

      <Icons.Icon icon="chevron" />
    </a>
  );
};

export default ShopItem;
