import React from 'react';
import { Button, Block, Centered, CenterWrapper, Subheading } from 'imdui';
import { css } from '@emotion/react';

type Props = {
  heading: string;
  buttonText: string;
  error: { eventId: string };
  children: React.ReactNode;
};

const wrapperStyle = css`
  margin: '0 auto';
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FeedbackErrorComponent = ({
  error: { eventId } = { eventId: '' },
  heading,
  buttonText = 'Send Feedback',
  children,
}: Props) => {
  return (
    <div css={wrapperStyle}>
      <CenterWrapper maxContentWidth={1400}>
        <Centered>
          <div style={{ maxWidth: '500px' }}>
            <Block padding={[50, 50, 50, 50]}>
              <Subheading>{heading}</Subheading>

              {children ? (
                <Block padding={[20, 0, 20, 0]}>{children}</Block>
              ) : (
                <Block padding={[20, 0, 0, 0]} />
              )}

              <div>
                <Button
                  text={buttonText}
                  onClick={() => {
                    if (window.Sentry) {
                      window.Sentry.showReportDialog({
                        eventId,
                      });
                    }
                  }}
                />
              </div>
            </Block>
          </div>
        </Centered>
      </CenterWrapper>
    </div>
  );
};
