//
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { map } from 'ramda';
import type { EntityModels } from 'imddata';
import { useTranslation } from 'react-i18next';
import { Content, Card, ApplicationSettingsContext } from 'imdshared';
import ItemRow from './ItemRow';
import TotalRow from './TotalRow';

const CardWithMargin = styled(Card)`
  margin-bottom: 8px;
`;

const DeductionsHeader = styled.div`
  display: flex;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1);
`;

const ItemsWrapper = styled.div`
  padding: 8px 0;
`;

const Deductions = ({
  overview,
  loading,
}: {
  overview: {
    currency: EntityModels.Overview['currency'];
    totalDiscounts: EntityModels.Overview['totalDiscounts'];
    discounts: EntityModels.Overview['discounts'];
  };
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const currencyId = overview && overview.currency.id;

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currencyId,
  });

  return (
    <CardWithMargin>
      <DeductionsHeader>
        <Content>{t('deductions')}</Content>
      </DeductionsHeader>

      <ItemsWrapper>
        {map(
          (discountItem) => (
            <ItemRow
              loading={loading}
              label={t(discountItem.type, { defaultValue: discountItem.name })}
              description={t(discountItem.type + '-description', {
                defaultValue: discountItem.description,
              })}
              value={
                discountItem.appliedDiscount
                  ? priceFormatter.format(
                      Number(discountItem.appliedDiscount) * -1
                    )
                  : ''
              }
            />
          ),
          overview.discounts
        )}
      </ItemsWrapper>

      <TotalRow
        loading={loading}
        label={t('total-discounts')}
        value={
          overview.totalDiscounts
            ? priceFormatter.format(Number(overview.totalDiscounts) * -1)
            : ''
        }
      />
    </CardWithMargin>
  );
};

export default Deductions;
