import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from 'imdshared';
import AppError from '../AppError';

const ErrorBoundaryRoute = ({
  component: Component,
  path,
  ...props
}: RouteProps): React.ReactElement => (
  <Route
    {...props}
    render={(routeProps) => (
      <ErrorBoundary key={routeProps.location.pathname}>
        {(error: { eventId: string }) => {
          if (error) return <AppError error={error} />;
          if (Component) {
            return <Component {...routeProps} />;
          }
          return null;
        }}
      </ErrorBoundary>
    )}
  />
);

export default ErrorBoundaryRoute;
