import { entityRequestReducerFactory } from '../../reducerFactories';
import combineReducers from '../../base/combineReducers';
import { entities } from '../../actionTypes';

import * as specificRequestReducers from './customReducers';

const entityKeys = Object.keys(entities);

const baseReducers = combineReducers(
  entityKeys.reduce(
    (acc, entityKey) => ({
      ...acc,
      [entityKey]: entityRequestReducerFactory({
        entityKey,
        fetchActions: [entities[entityKey].types.fetch],
        createActions: [entities[entityKey].types.create],
        updateActions: [entities[entityKey].types.update],
        deleteActions: [entities[entityKey].types.delete],
      }),
    }),
    []
  )
);

const specificReducer = combineReducers(specificRequestReducers);

export default function requestReducer(state, action) {
  return specificReducer(baseReducers(state, action), action);
}
