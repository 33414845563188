import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Icon } from '../../icon';

export const ArtworkWrapper = styled.div`
  z-index: 0;
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const ArtworkImg = styled('img')<{
  src: string;
}>`
  z-index: 1;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

export const ArtworkShadow = styled('div', {
  shouldForwardProp: (propName) => isPropValid(propName) && propName !== 'src',
})<{
  src: string;
  blurAmount: number;
}>`
  z-index: -1;
  position: absolute;
  top: 12.5%;
  left: 8%;
  width: 84%;
  height: 84%;
  background-image: url(${({ src }): string => src});
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  filter: blur(${({ blurAmount }) => blurAmount}px);
`;

export const ArtworkPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.component.border};
`;

export const StyledIcon = styled(Icon)`
  min-width: 24px;
  min-height: 24px;
  width: 60%;
  height: 60%;
  fill: ${({ theme }): string => theme.foreground.primary};
  opacity: 0.6;
`;

export const LoadingIndicatorWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
`;
