import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { H3 } from 'imdui';

interface EntitledGroupProps {
  groupKey: string;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  > ${H3} {
    display: block;
    margin: 24px 24px 16px;
  }
`;

export default function EntitledGroup({
  groupKey,
  children,
}: EntitledGroupProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <H3>{t(groupKey)}</H3>
      {children}
    </Wrapper>
  );
}
