import React, { useState, useCallback, memo } from 'react';
import { Content, Caption } from '../../../text';
import { Icons } from '../../icon';
import {
  CardContainer,
  TopBar,
  InfoRow,
  TextRow,
  StyledChip,
  ExpandIcon,
} from './styles';

interface Props {
  testId?: string;
  title: React.ReactNode;
  chipText?: React.ReactNode;
  chipStyles?: Record<string, unknown>;
  description?: string;
  children: React.ReactNode;
  collapsed?: boolean;
  collapsible?: boolean;
  chipState?: 'primary' | 'error';
}

const CardSection = ({
  testId,
  title,
  description,
  chipText,
  chipStyles,
  children,
  collapsed: collapsedProp = false,
  collapsible = true,
  chipState = 'primary',
}: Props): JSX.Element => {
  const [collapsed, setCollapsed] = useState(collapsedProp && collapsible);

  const DESC_VISIBLE = description && collapsed;

  const handleClick = useCallback(() => {
    if (!collapsible) return;
    setCollapsed(!collapsed);
  }, [collapsed, collapsible]);

  return (
    <CardContainer data-test-id={testId}>
      <TopBar onClick={handleClick} collapsed={collapsed}>
        <InfoRow>
          <TextRow>{title}</TextRow>

          <div>
            {chipText && (
              <StyledChip style={chipStyles} state={chipState}>
                <Content>{chipText}</Content>
              </StyledChip>
            )}

            {collapsible && (
              <ExpandIcon d={Icons.arrows.dropdown} collapsed={collapsed} />
            )}
          </div>
        </InfoRow>

        {DESC_VISIBLE && <Caption>{description}</Caption>}
      </TopBar>

      {!collapsed && <div>{children}</div>}
    </CardContainer>
  );
};

export default memo<Props>(CardSection);
