/* eslint-disable react/jsx-no-undef */
//
import React, { Component } from 'react';
import { difference, compose, uniq, concat } from 'ramda';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { actions } from 'imddata/actionTypes/ui/selection';
import {
  NoTracks,
  Toolbar,
} from 'imdshared/src/Next/ReleaseTracksList/components';
import { Wrapper } from 'imdshared/src/Next/ReleaseTracksList';
import { DraggableTracksList, TracksUploader } from 'imdshared';
import { useTracks } from 'imddata';
import TracksBrowserDialog from '../TracksBrowserDialog';
import ContributorSideEditor from '../ContributorSideEditor';
import ArtistSideEditor from '../ArtistSideEditor';
import PublisherSideEditor from '../PublisherSideEditor';
import TrackSideEditor from '../TrackSideEditor';

class SelectedTracksList extends Component {
  state = {
    showTracksBrowser: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.trackIds !== prevProps.trackIds &&
      difference(this.props.selectedTracks, this.props.trackIds).length
    ) {
      this.onCloseWindow();
    }
  }

  handleSelectionChanged = (selectedTracks) => {
    const { updateSelection } = this.props;
    updateSelection({
      entity: 'tracks',
      selected: selectedTracks,
    });
  };

  addTracksFromTheLib = (tracksToBeAdded) => {
    const { onChange, trackIds } = this.props;
    if (onChange) {
      onChange(uniq(concat(tracksToBeAdded, trackIds)));
    }
    this.hideTracksBrowser();
  };

  showTracksBrowser = () => {
    this.setState({ showTracksBrowser: true });
  };

  hideTracksBrowser = () => {
    this.setState({ showTracksBrowser: false });
  };

  onCloseWindow = () => {
    const { updateSelection } = this.props;

    updateSelection({
      entity: 'tracks',
      selected: [],
    });

    updateSelection({
      entity: 'contributors',
      selected: [],
    });

    updateSelection({
      entity: 'publishers',
      selected: [],
    });
  };

  render() {
    const {
      selectedTracks,
      displayedFields,
      trackIds,
      renderActions,
      requiredFields,
      nestingPath,
      additionalValidation,
      languageId,
      targetName,
      isValid,
      isDisabled,
      hideReleaseName,
      hideToolbar,
      virtualized,
      hideEditors,
      hideTrackNumber = false,
      canUpload = true,
    } = this.props;

    const { showTracksBrowser } = this.state;

    return (
      <Wrapper>
        <div>
          <DraggableTracksList
            virtualized={virtualized}
            isValid={isValid}
            items={trackIds}
            targetName={targetName}
            hideReleaseName={hideReleaseName}
            selectedTracks={selectedTracks}
            noRowsRenderer={NoTracks}
            columns={{ number: !hideTrackNumber, album: false }}
            onChangeSelection={this.handleSelectionChanged}
          />
          {!hideToolbar && (
            <Toolbar
              nestingPath={nestingPath}
              languageId={languageId}
              onClickTrackLibrary={this.showTracksBrowser}
              selectedTracks={selectedTracks}
            />
          )}

          <TracksBrowserDialog
            isOpen={showTracksBrowser}
            isDisabled={isDisabled}
            disabledTracks={trackIds}
            onClose={this.hideTracksBrowser}
            onTracksSelected={this.addTracksFromTheLib}
          />

          <TrackSideEditor
            renderActions={renderActions}
            languageId={languageId}
            selected={selectedTracks}
            requiredFields={requiredFields}
            additionalValidation={additionalValidation}
            displayedFields={displayedFields}
            onClose={this.onCloseWindow}
          />

          {!hideEditors && (
            <>
              <ContributorSideEditor isAdaptive={false} />

              <ArtistSideEditor isAdaptive={false} />

              <PublisherSideEditor isAdaptive={false} />
            </>
          )}
          {canUpload && <TracksUploader nestingPath={nestingPath} />}
        </div>
      </Wrapper>
    );
  }
}

const noSelected = [];

export default compose(
  connect(
    ({ ui: { selection } }) => ({
      selectedTracks: selection.tracks || noSelected,
    }),
    { updateSelection: actions.updateSelection }
  ),
  withTranslation('translation')
)((props) => {
  useTracks({
    ids: props.trackIds,
  });
  return <SelectedTracksList {...props} />;
});
