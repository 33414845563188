//
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { InfiniteList, PageTemplate } from 'components';
import { Helmet } from 'react-helmet-async';
import type { UseFieldUpdateRequestsEntry } from 'imddata';
import { useQuery, useFieldUpdateRequests } from 'imddata';
import { Card } from 'imdui';
import {
  IconButton,
  NavListItem,
  NavListHeader,
  OptionTabs,
  Icons,
  Body,
} from 'imdshared';
import type { RouteComponentProps } from 'react-router';

// TODO: remove duplication in src/screens/Downloads/index
const ListIcon = styled(IconButton)`
  background-color: #fafafa;
  width: 40px;
  height: 40px;
  box-shadow: inset 0 0 0 1px #ececec;
`;

const FieldUpdateRequestListItem = ({
  style,
  match,
  item,
}: {
  style: React.CSSProperties;
  match: RouteComponentProps['match'];
  item: UseFieldUpdateRequestsEntry;
}) => {
  return (
    <NavListItem
      testId={`FieldUpdateRequestListItem-${item.id}`}
      title={item.title}
      style={style}
      subtitle={
        item.message && item.message.length < 34
          ? item.message
          : item.message
            ? `${item.message.substring(0, 34)}...`
            : ''
      }
      status={item.status}
      createdAt={moment(item.requestedAt).format('L')}
      to={`${match.url}/${item.id}`}
    >
      <ListIcon icon={Icons.arrows.forward} />
    </NavListItem>
  );
};

const OptionsStyled = styled(OptionTabs)``;

type FilterOption = 'requested' | 'submitted';

const FieldUpdateRequestsStatusFilter = ({
  onChange,
  value,
}: {
  onChange: (v: FilterOption) => void;
  value: FilterOption;
}) => {
  const { t } = useTranslation();
  const statusFilterOptions = useMemo<
    Array<{ value: FilterOption; label: string }>
  >(
    () => [
      { value: 'requested', label: t('active') },
      { value: 'submitted', label: t('submitted') },
    ],
    []
  );
  return (
    <OptionsStyled
      style={{ marginBottom: '32px' }}
      options={statusFilterOptions}
      value={value}
      // styled components are stripping generics
      // @ts-ignore
      onChange={onChange}
    />
  );
};

const Header = styled(NavListHeader)`
  background-color: #f9f9f9;
  padding: 16px 32px;
`;

const HeaderItem = styled(Body)`
  font-size: 14px;
  opacity: 0.8;
  display: flex;
  flex: 1;
`;

const FieldUpdateRequestsPage = ({ match }: RouteComponentProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<FilterOption>('requested');

  // TODO: Change to filter.status after API ready
  const query = useMemo(
    () => ({ with: 'fields', 'filter.status': status }),
    [status]
  );
  const { queryHash } = useQuery({ wait: 0, query });
  const {
    entries,
    loadMore,
    refresh,
    request: { loading, hasMorePages },
  } = useFieldUpdateRequests({ query, queryHash });

  useEffect(() => {
    // TODO: what happens to list where this item should be?
    const statuses = status && status.split(',');
    if (
      entries &&
      statuses &&
      statuses.length &&
      entries.find((entry) => statuses.indexOf(entry.status) < 0) &&
      refresh
    ) {
      refresh();
    }
  }, []);

  return (
    <PageTemplate
      title={t('field-update-requests')}
      descriptionKey="field-update-requests-description"
    >
      <Helmet>
        <title>{t('field-update-requests')}</title>
      </Helmet>
      <FieldUpdateRequestsStatusFilter onChange={setStatus} value={status} />
      <Card>
        <Header>
          <HeaderItem>{t('description')}</HeaderItem>
          <HeaderItem>{t('status')}</HeaderItem>
          <HeaderItem>{t('requested-at')}</HeaderItem>
          <HeaderItem />
        </Header>
        <InfiniteList
          data={entries}
          hasNextPage={hasMorePages}
          loadNextPage={loadMore}
          loading={loading}
          // TODO: FIX memo
          // eslint-disable-next-line
          rowRenderer={(props) => (
            <FieldUpdateRequestListItem {...props} match={match} />
          )}
          emptyPlaceholder={t('no-field-update-requests')}
          getRowHeight={() => 80}
        />
      </Card>
    </PageTemplate>
  );
};

export default FieldUpdateRequestsPage;
