import React, { useState, useCallback } from 'react';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { Settings } from 'imddata';
import { useSettings } from 'imddata';
import { LinkButton } from 'imdshared';
import { Icon, IconsCollection, OptionTabs } from 'imdui';
import {
  ReleaseGrid,
  ReleasesList,
  ReleaseQueryBuilder,
  LibraryHeaderTools,
} from 'components';
import { LibraryWrap } from '../../shared/LibraryWrap';

type SearchQuery = {
  perPage?: number;
  with?: string;
};

const contentWrapper = css`
  padding: 16px 32px;
`;

const viewModeOptions: Array<{
  value: 'grid' | 'list';
  children: React.ReactNode;
}> = [
  { value: 'grid', children: <Icon d={IconsCollection.viewModeGrid} /> },
  { value: 'list', children: <Icon d={IconsCollection.viewModeList} /> },
];

const Releases: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { settings, updateSettings } = useSettings();

  const [viewMode, setViewMode] = useState<
    Exclude<Settings['releasesViewMode'], undefined>
  >(settings.releasesViewMode || 'grid');
  const [filterValue, setFilterValue] = useState([]);
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({ perPage: 30 });

  const handleFilteringChange = useCallback(({ value, searchQuery: query }) => {
    setFilterValue(value);
    setSearchQuery({ ...query });
  }, []);

  const handleViewModeChange = useCallback(
    (value: Exclude<Settings['releasesViewMode'], undefined>) => {
      setViewMode(value as 'grid' | 'list');
      updateSettings({ releasesViewMode: value });
    },
    []
  );

  const handleReleaseClick = useCallback((_, id: number | string) => {
    history.push(`/library/releases/${id}`);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('page-title-releases')}</title>
      </Helmet>

      <LibraryWrap>
        <LibraryHeaderTools
          value={filterValue}
          renderQueryBuilder={ReleaseQueryBuilder}
          onChangeFiltering={handleFilteringChange}
        >
          <OptionTabs
            style={{ minHeight: '40px' }}
            value={viewMode}
            options={viewModeOptions}
            onChange={handleViewModeChange}
          />

          <LinkButton to="/order/release/new" text={t('add-new-release')} />
        </LibraryHeaderTools>

        <div css={contentWrapper}>
          {viewMode === 'grid' && <ReleaseGrid searchQuery={searchQuery} />}

          {viewMode === 'list' && (
            <ReleasesList
              searchQuery={searchQuery}
              onClickRelease={handleReleaseClick}
            />
          )}
        </div>
      </LibraryWrap>
    </>
  );
};

export default Releases;
