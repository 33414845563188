//
import callApi from '../callApi';
import bindCrudApi from './bindCrudApi';

/**
 *
 *
 * @returns {undefined}
 */
const defaultQueryFormat = (value) => value;
const defaultFormatEndpoint = ({ id, endpoint, domain }) => {
  const result = id ? `${endpoint}/${id}` : endpoint;
  return domain ? `${domain}/${result}` : result;
};
export default function factoryEntityApi({
  payloadFormatter,
  base,
  selectData,
  singularSchema,
  endpoint,
  formatEndpoint = defaultFormatEndpoint,
  formatQuery = defaultQueryFormat,
  entityKey,
  schema,
  actions,
  bySchemas,
  updateHandlers,
  normalizers = {},
}) {
  const apiCalls = {
    fetch({ id, query: propQuery } = { query: {} }, impersonate) {
      const { IMD_FETCH_DOMAIN, ...query } = propQuery || {};
      return callApi({
        base,
        bySchemas,
        singularSchema,
        selectData,
        impersonate,
        query: formatQuery(query),
        endpoint: formatEndpoint({
          id,
          endpoint,
          domain: IMD_FETCH_DOMAIN,
          query,
        }),
        schema,
        normalizer: normalizers.fetch
          ? normalizers.fetch({ id, schema, query })
          : null,
        options: { method: 'GET' },
      });
    },
    update({ id, data, query: propQuery }, impersonate) {
      const { IMD_FETCH_DOMAIN, ...query } = propQuery || {};
      return callApi({
        payloadFormatter,
        base,
        selectData,
        singularSchema,
        query: formatQuery(query),
        impersonate,
        schema,
        normalizer: normalizers.update
          ? normalizers.update({ id, schema, query, data })
          : null,
        endpoint: formatEndpoint({
          id,
          endpoint,
          query,
        }),
        options: { method: 'PUT', data },
      });
    },
    create({ data, id, query: propQuery }, impersonate) {
      const { IMD_FETCH_DOMAIN, ...query } = propQuery || {};
      return callApi({
        payloadFormatter,
        base,
        singularSchema,
        query: formatQuery(query),
        impersonate,
        selectData,
        schema,
        normalizer: normalizers.create,
        endpoint: formatEndpoint({
          id,
          endpoint,
          query,
        }),
        options: { method: 'POST', data },
      });
    },
    delete({ id, query: propQuery }, impersonate) {
      const { IMD_FETCH_DOMAIN, ...query } = propQuery || {};
      return callApi({
        base,
        singularSchema,
        schema,
        selectData,
        query: formatQuery(query),
        impersonate,
        normalizer: normalizers.delete,
        endpoint: formatEndpoint({
          id,
          endpoint,
          query,
        }),
        options: { method: 'DELETE' },
      });
    },
  };

  return bindCrudApi(actions, apiCalls, entityKey, updateHandlers);
}
