import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDates } from './hooks';
import type { WrappedFieldProps } from 'redux-form';
import { change } from 'redux-form';
import { useCallback } from 'react';
import moment from 'moment';
import { ReleaseDateSelector } from 'imdshared';

export const ShippingField = ({
  input,
  meta,
  ...props
}: WrappedFieldProps & { disabled?: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { form } = meta;
  const {
    digitalReleaseError,
    onChangeDates,
    pricePlanId: planId,
    hasPreSales,
    preSalesAt,
    releaseAt,
    shippingOffset,
  } = useDates({ form, disabled: props.disabled });
  const controlledDateKey = hasPreSales ? 'preSalesAt' : 'releaseAt';
  const controlledDate = hasPreSales ? preSalesAt : releaseAt;
  const controlledDateValue = controlledDate ? moment(controlledDate) : null;

  const handleChange = useCallback(
    (date) => {
      const shippingDate = moment(date).subtract(shippingOffset || 0, 'days');

      dispatch(change(form, controlledDateKey, date));

      onChangeDates({
        deliverAt: shippingDate,
        [controlledDateKey]: date,
      });
      input.onChange(shippingDate);
    },
    [onChangeDates, input.onChange, shippingOffset, controlledDateKey]
  );

  return (
    <ReleaseDateSelector
      planId={planId}
      {...input}
      {...props}
      placeholder={t('not-selected')}
      errorText={
        (meta.touched &&
          controlledDateKey === 'releaseAt' &&
          digitalReleaseError &&
          t(digitalReleaseError)) ||
        (meta.touched && meta.error)
      }
      offset={shippingOffset}
      value={controlledDateValue}
      onChange={handleChange}
    />
  );
};
