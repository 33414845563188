import React from 'react';
import type { WrappedFieldProps } from 'redux-form';
import type { ToggleSwitchProps } from 'imdui';
import { ToggleSwitch } from 'imdui';

type Props = WrappedFieldProps &
  Omit<ToggleSwitchProps, 'onCheck' | 'checked'> & {
    onChange?: ToggleSwitchProps['onCheck'];
  };

export default function ToggleSwitchField({
  input,
  onChange,
  meta,
  ...other
}: Props) {
  return (
    <ToggleSwitch
      {...input}
      {...other}
      warningText={meta?.warning}
      errorText={meta?.error}
      checked={input.value}
      onCheck={(evt) => {
        input.onChange(!input.value);
        if (onChange) {
          onChange(evt, !input.value);
        }
      }}
    />
  );
}
