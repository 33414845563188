import {
  useEntries,
  // useQuery,
  useUpdateEntity,
  useEntryProvider,
  useUpdateRequestProvider,
} from 'imddata';
import type { EntityModels, ReduxState } from 'imddata';
import { useEffect, useState, useMemo } from 'react';
import { useCustomerArtistPageLimits } from './components';
import { useSelector } from 'react-redux';
import { makeSelectRequestState } from 'imddata/providers/hooks/selectors';

// const publishedQuery = {
//   'filter.is_published': true,
// };

export const usePublishArtistPage = (
  artistId: number,
  artistPageId?: string
) => {
  const limits = useCustomerArtistPageLimits();
  // const { query, queryHash } = useQuery({
  //   query: publishedQuery,
  // });
  const {
    entries: artistPages,
    request: { loaded },
  } = useEntries<EntityModels.ArtistPage>({
    entity: 'artistPages',
    // query,
    // queryHash,
  });
  const publihsedPages = useMemo(() => {
    return artistPages.filter((p) => p.isPublished);
  }, [artistPages]);

  const exceededLimit = publihsedPages.length + 1 - limits.publishedLimit;

  const artistPage = useEntryProvider<EntityModels.ArtistPage>({
    entity: 'artistPages',
    id: artistPageId,
  });
  const { updateEntry: updateArtistPage } = useUpdateEntity({
    entity: 'artistPages',
  });

  const [published, setPublished] = useState(() => artistPage?.isPublished);
  useEffect(() => {
    if (artistPage) {
      setPublished(artistPage?.isPublished);
    }
  }, [artistPage?.isPublished]);

  const { updating: artistPagePublishing } = useUpdateRequestProvider({
    entity: 'artistPages',
    id: artistPageId,
  });

  const updatingSelector = useMemo(
    () => makeSelectRequestState(['updating']),
    []
  );

  const [unpublishArtistPagesIds, setUnpublishArtistPagesIds] = useState<
    string[]
  >([]);

  const unpublishing = useSelector((state: ReduxState) => {
    for (const id of unpublishArtistPagesIds) {
      const request = updatingSelector(state, { entity: 'artistPages', id });
      return request.updating;
    }
  });

  useEffect(() => {
    if (unpublishArtistPagesIds.length && !unpublishing) {
      updateArtistPage({ data: { isPublished: !published }, id: artistPageId });
      setPublished(true);
      setUnpublishArtistPagesIds([]);
    }
  }, [unpublishArtistPagesIds, unpublishing, artistPageId]);

  const publish = useMemo(
    () =>
      loaded
        ? (unpublishArtistPagesIds: string[] = []) => {
            if (!published && exceededLimit > unpublishArtistPagesIds.length) {
              return;
            }

            if (!published && unpublishArtistPagesIds.length) {
              for (const apId of unpublishArtistPagesIds) {
                updateArtistPage({
                  id: apId,
                  data: { isPublished: false },
                });
              }
              setUnpublishArtistPagesIds(unpublishArtistPagesIds);
              return;
            }

            updateArtistPage({
              id: artistPageId,
              data: { isPublished: !published },
            });

            setPublished(!published);
          }
        : undefined,
    [exceededLimit, published, artistPageId, loaded]
  );

  const artistPagesToResolve = useMemo(() => {
    return publihsedPages.filter((p) => p.artistId !== artistId);
  }, [publihsedPages]);

  return {
    artistPagesToResolve,
    exceededLimit: exceededLimit,
    limit: limits.publishedLimit,
    published,
    loading: unpublishing || artistPagePublishing,
    publish,
  };
};
