import { useCreateEntity } from 'imddata';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingIndicator, Window } from 'imdui';
import { Dashboard } from 'screens';

export const TakedownConfirmationDashboardModal = ({ ...props }) => {
  const { entity, uuid } = useParams<{ entity: string; uuid: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  const { createEntry: createReleaseTakedown, request: releaseRequest } =
    useCreateEntity({
      entity: 'releaseActions',
    });

  const { createEntry: createTrackTakedown, request: trackRequest } =
    useCreateEntity({
      entity: 'trackActions',
    });

  useEffect(() => {
    if (entity === 'tracks') {
      createTrackTakedown({
        data: {
          action: `takedown/confirm/${uuid}`,
        },
      });
    }

    if (entity === 'releases') {
      createReleaseTakedown({
        data: {
          action: `takedown/confirm/${uuid}`,
        },
      });
    }
  }, [entity]);
  const failed = trackRequest.failed || releaseRequest.failed;
  const success = trackRequest.created || releaseRequest.created;

  return (
    <>
      <Window
        title={t('takedown-confirmation')}
        isOpen={true}
        close={() => {
          history.replace('/');
        }}
      >
        <div style={{ padding: '24px' }}>
          {failed ? (
            t('failed-to-takedown')
          ) : success ? (
            t('takedown-confirmed')
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </Window>

      <Dashboard {...props} />
    </>
  );
};
