//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Caption, themeLight as theme } from 'imdui';
import LegendRow from './LegendRow';

const NoteWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px 8px;
`;

const ProcessingDayLegend = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${theme.state.warning};
`;

const LegendWrapper = styled.div`
  padding: 24px 16px;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.16);
`;

const DatePickerLegend = ({ planId, dateRanges }) => {
  const { t } = useTranslation();

  return (
    <div>
      <NoteWrapper>
        <ProcessingDayLegend />

        <Caption>{t('processing-day')}</Caption>
      </NoteWrapper>

      <LegendWrapper>
        {dateRanges.legend.map((option) => (
          <LegendRow key={option.id} planId={planId} option={option} />
        ))}
      </LegendWrapper>
    </div>
  );
};

export default DatePickerLegend;
