import { action } from '../../base';

export enum types {
  CHECK_ISSUE = '@ui/fur/CHECK_ISSUE',
}

export const actions = {
  checkIssue: (payload: {
    id: string;
    changeId: string;
    issueIndex: number;
    checked: boolean;
  }) => action(types.CHECK_ISSUE, payload),
};
