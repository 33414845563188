import { normalize, schema as normalizrSchema } from 'normalizr';
import { camelizeKeys } from 'humps';

const filtersSchema = [
  new normalizrSchema.Entity(
    'filters',
    {},
    {
      idAttribute: 'name',
    }
  ),
];

export const defaultGetSchema = (json, schema) =>
  json.items ? [schema] : schema;

export const defaultNormalizeResponse =
  ({
    selectData,
    schema,
    camelize = camelizeKeys,
    getSchema = defaultGetSchema,
  }) =>
  (json) => {
    const selectedData = selectData
      ? selectData(json)
      : json.item || json.items || json.data || json;
    const camelizedJson = camelize ? camelize(selectedData) : selectedData;

    const rightSchema = selectData ? schema : getSchema(json, schema);

    const response =
      schema && camelizedJson
        ? { ...normalize(camelizedJson, rightSchema) }
        : camelizedJson || {};

    const responseDetails = json.details ? camelize(json.details) : {};
    return {
      rawResponse: camelize(json),
      originalResponse: json,
      response,
      responseDetails: {
        ...responseDetails,
        filtersData: responseDetails.filtersData
          ? normalize(responseDetails.filtersData, filtersSchema)
          : null,
      },
    };
  };

export const normalizeGrouped =
  ({
    groupSchema,
    schema,
    camelize = camelizeKeys,
    getSchema = defaultGetSchema,
  }) =>
  (json) => {
    const groups = camelize(json.details.groups);
    const groupItems = groupSchema
      ? normalize(camelize(json.details.group_items), [groupSchema])
      : { entities: camelize(json.details.group_items) };
    const resultJson = defaultNormalizeResponse({
      schema,
      camelize,
      getSchema,
    })(json);
    const response = {
      groups,
      entities: resultJson?.response?.entities
        ? { ...resultJson.response.entities, ...groupItems.entities }
        : groupItems.entities,
      ...(resultJson?.response || {}),
    };
    return {
      ...(resultJson || {}),
      response,
    };
  };

const salesSchema = new normalizrSchema.Object({
  byId: [new normalizrSchema.Entity('itemSales')],
});

export const normalizeWithSales = (payload) => (json) => {
  const normalizeFunc =
    payload.query && payload.query.by
      ? normalizeGrouped
      : defaultNormalizeResponse;
  const data = normalizeFunc(payload)(json);
  return {
    ...data,
    response: {
      sales: json.sales && normalize(camelizeKeys(json.sales), salesSchema),
      ...data.response,
    },
  };
};
