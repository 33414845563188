import styled from '@emotion/styled';
import ArtistHubThumbnail from 'components/icons/product-thumbnails/artist-hub.svg';
import MusicAnalyticsThumbnail from 'components/icons/product-thumbnails/music-analytics.svg';
import ReleasesIncludedThumbnail from './icons/releases-included.svg';
import DeliveryDiscountThumbnail from './icons/delivery-discount.svg';
import SalesCommissionThumbnail from './icons/sales-commission.svg';
import RevenueSplitThumbnail from './icons/revenue-splits.svg';
import UnlimitedArtistsThumbnail from './icons/unlimited-artists.svg';
import ReleasePagesWithPresaveThhumbnail from './icons/release-pages-with-presave.svg';

import { useHistory } from 'react-router';
import { TextBlock } from './TextBlock';
import type { EntityModels } from 'imddata';
import { useCustomerSalesCommission, useEntryProvider } from 'imddata';
import { DashboardCard } from 'components';
import type { CancelEvent, Page } from '../types';
import type { SubscriptionId } from 'imddata/types/entities';
import { useTranslation } from 'react-i18next';
import { ContactSupportCard } from './ContactSupportCard';
import { useMemo } from 'react';

const SvgAligner = styled.div`
  display: flex;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const ProductsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

type CardType =
  | 'music-analytics'
  | 'release-pages-with-presave'
  | 'releases-included'
  | 'delivery-discount'
  | 'unlimited-artists'
  | 'revenue-split'
  | 'commission'
  | 'contact-support'
  | 'legacy-commission'
  | 'artist-hub';

const LEGACY_FREE_DOWNGRADE: CardType[] = [
  'legacy-commission',
  'music-analytics',
  'artist-hub',
  'contact-support',
];

const NEW_FREE_DOWNGRADE: CardType[] = [
  'releases-included',
  'commission',
  'music-analytics',
  'release-pages-with-presave',
  'contact-support',
];

const getActiveCards = (
  currentSubscriptionId: SubscriptionId,
  downgradeToId: SubscriptionId | null
): CardType[] => {
  switch (currentSubscriptionId) {
    case 'tier-1':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'trial-tier-2':
      switch (downgradeToId) {
        case null:
          return NEW_FREE_DOWNGRADE;
        case 'tier-1':
          return ['releases-included', 'revenue-split'];
        default:
          return [];
      }

    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
    case 'tier-3':
      switch (downgradeToId) {
        case null:
          return NEW_FREE_DOWNGRADE;
        case 'tier-2-2':
        case 'tier-2-5':
        case 'tier-2-15':
        case 'tier-2-25':
          return ['unlimited-artists', 'delivery-discount'];
        case 'tier-1':
          return ['releases-included', 'revenue-split'];
        default:
          return [];
      }

    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
      switch (downgradeToId) {
        case null:
          return LEGACY_FREE_DOWNGRADE;
        default:
          return [];
      }

    case 'vip':
      switch (downgradeToId) {
        case null:
          return LEGACY_FREE_DOWNGRADE;
        default:
          return LEGACY_FREE_DOWNGRADE;
      }
  }
};

const getRedirect = (cardType: CardType) => {
  switch (cardType) {
    case 'music-analytics':
      return '/reporting';
    case 'release-pages-with-presave':
      return '/products/artist-hub';
    case 'unlimited-artists':
    case 'delivery-discount':
    case 'releases-included':
      return '/order/md/new';
    case 'commission':
    case 'revenue-split':
      return '/revenue';
    case 'artist-hub':
      return '/products/artist-hub';
    case 'contact-support':
    case 'legacy-commission':
      return '/dashboard';
  }
};
const Icon = ({ cardType }: { cardType: CardType }) => {
  switch (cardType) {
    case 'release-pages-with-presave':
      return <ReleasePagesWithPresaveThhumbnail />;
    case 'delivery-discount':
      return <DeliveryDiscountThumbnail />;
    case 'releases-included':
      return <ReleasesIncludedThumbnail />;
    case 'unlimited-artists':
      return <UnlimitedArtistsThumbnail />;
    case 'revenue-split':
      return <RevenueSplitThumbnail />;
    case 'commission':
      return <SalesCommissionThumbnail />;

    case 'contact-support':
    case 'music-analytics':
    case 'artist-hub':
    case 'legacy-commission':
      return null;
  }
};

const ProductCard = ({
  onClose,
  page,
  cardType,
  requestedSubscription,
  currentSalesCommission,
  currentSubscriptionId,
  nextSalesCommission,
}: {
  currentSubscriptionId: SubscriptionId;
  requestedSubscription: SubscriptionId | 'free';
  currentSalesCommission?: number | string;
  nextSalesCommission?: number | string;
  cardType: CardType;
  onClose: (meta: CancelEvent) => void;
  page: Page;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  switch (cardType) {
    case 'music-analytics':
      return (
        <DashboardCard
          onClick={() => {
            onClose({
              requestedSubscription,
              page,
              product: 'music-analytics',
              action: 'redirect',
            });
            history.push(getRedirect(cardType));
          }}
          label={t('discover-potential')}
        >
          <DashboardCard.Text>{t('with-music-analytics')}</DashboardCard.Text>
          <SvgAligner>
            <MusicAnalyticsThumbnail />
          </SvgAligner>
        </DashboardCard>
      );
    case 'contact-support':
      return (
        <ContactSupportCard
          style={{ gridColumn: '1 / -1' }}
          onClose={onClose}
          page={page}
          requestedSubscription={requestedSubscription}
        />
      );
    case 'legacy-commission':
      return (
        <DashboardCard
          style={{ gridColumn: '1 / -1' }}
          label={t('sales-commission-downgrade')}
        >
          <DashboardCard.Text>
            {t('sales-commission-downgrade-text', {
              currentSalesCommission,
              nextSalesCommission:
                nextSalesCommission !== undefined ? nextSalesCommission : 10,
              defaultValue:
                '{{currentSalesCommission}} >> {{nextSalesCommission}} {currentSalesCommission} >> {nextSalesCommission}',
            })}
          </DashboardCard.Text>
        </DashboardCard>
      );
    case 'artist-hub':
      return (
        <DashboardCard
          onClick={() => {
            onClose({
              requestedSubscription,
              page,
              product: 'artist-hub',
              action: 'redirect',
            });
            history.push(getRedirect(cardType));
          }}
          label={t('promote-your-brand')}
        >
          <DashboardCard.Text>
            {t('with-artist-hub')}
            <ArtistHubThumbnail />
          </DashboardCard.Text>
        </DashboardCard>
      );
    case 'release-pages-with-presave':
    case 'releases-included':
    case 'delivery-discount':
    case 'unlimited-artists':
    case 'revenue-split':
    case 'commission':
      return (
        <DashboardCard
          onClick={() => {
            onClose({
              requestedSubscription,
              page,
              product: cardType,
              action: 'redirect',
            });
            history.push(getRedirect(cardType));
          }}
          label={t(`downgrade-${cardType}-title`, {
            context: `${currentSubscriptionId}--${requestedSubscription}`,
          })}
        >
          <DashboardCard.Text>
            {t(`downgrade-${cardType}-text`, {
              context: `${currentSubscriptionId}--${requestedSubscription}`,
            })}
          </DashboardCard.Text>
          <SvgAligner>
            <Icon cardType={cardType} />
          </SvgAligner>
        </DashboardCard>
      );
  }
};

export const CancelFlowProducts = ({
  onClose,
  firstName,
  page,
  currentSubscriptionId,
  downgradeToId = null,
}: {
  page: Page;
  firstName?: string;
  downgradeToId: SubscriptionId | null;
  currentSubscriptionId: SubscriptionId;
  onClose: (meta?: CancelEvent) => void;
}) => {
  const nextSubscription = useEntryProvider<EntityModels.Subscription>({
    entity: 'subscriptions',
    id: downgradeToId || '[stub]',
  });
  const salesCommission = useCustomerSalesCommission();

  const { t } = useTranslation();
  const requestedSubscription = downgradeToId ? downgradeToId : 'free';

  const activeCards = useMemo(
    () => getActiveCards(currentSubscriptionId, downgradeToId),
    [downgradeToId, currentSubscriptionId]
  );
  return (
    <>
      <TextBlock
        title={t('before-you-cancel', {
          firstName,
          context: firstName ? 'with-first-name' : '',
        })}
        subtitle={t('before-you-cancel-text')}
      />
      <ProductsGrid>
        {activeCards.map((cardType) => (
          <ProductCard
            key={cardType}
            onClose={onClose}
            cardType={cardType}
            requestedSubscription={requestedSubscription}
            currentSalesCommission={salesCommission}
            nextSalesCommission={nextSubscription?.salesCommission}
            page={page}
            currentSubscriptionId={currentSubscriptionId}
          />
        ))}
      </ProductsGrid>
    </>
  );
};
