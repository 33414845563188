import React, { forwardRef } from 'react';
import { VariableSizeList } from 'react-window';

/**
 * Vertical padding of the list
 * (keep in mind that we also need to set such offset in custom Option component)
 */
const PADDING_SIZE = 4;

const sum = (a, b) => a + b;

class OptionWrapper extends React.PureComponent {
  render() {
    const { style, index, data } = this.props;
    return (
      <div
        style={{ ...style, top: `${parseFloat(style.top) + PADDING_SIZE}px` }}
      >
        {data[index]}
      </div>
    );
  }
}

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + PADDING_SIZE * 2}px`,
    }}
    {...rest}
  />
));

export default class MenuList extends React.PureComponent {
  static defaultProps = {
    listDefaultHeight: 40,
    listApproximatedLengthBreak: 40,
  };

  constructor(props) {
    super(props);
    this.itemSize = this.itemSize.bind(this);
    this.list = React.createRef();

    this.state = {
      rawChildren: null,
      maxHeight: null,
      currentIndex: 0,
    };
  }

  static getDerivedStateFromProps(
    {
      children: rawChildren,
      maxHeight,
      listApproximatedLengthBreak,
      listDefaultHeight,
    },
    state
  ) {
    if (rawChildren !== state.rawChildren || maxHeight !== state.maxHeight) {
      const children = Array.isArray(rawChildren) ? rawChildren : [rawChildren];

      // Waiting for https://github.com/bvaughn/react-window/issues/6
      const heights = children.map(
        ({ props: { label } }) =>
          listDefaultHeight *
          Math.ceil((`${label}` || '_').length / listApproximatedLengthBreak)
      );
      const totalHeight = heights.reduce(sum) || 0;
      const height = Math.min(maxHeight, totalHeight);
      const itemCount = children.length;

      const currentIndex = Math.max(
        children.findIndex(({ props: { isFocused } }) => isFocused),
        0
      );

      const estimatedItemSize = Math.floor(totalHeight / itemCount);

      return {
        height,
        itemCount,
        heights,
        estimatedItemSize,
        maxHeight,
        rawChildren,
        currentIndex,
      };
    }
    return null;
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { currentIndex } = this.state;
    this.list.current.scrollToItem(currentIndex);
  }

  itemSize(index) {
    return this.state.heights[index];
  }

  render() {
    const { children, innerRef } = this.props;
    const { height, itemCount, estimatedItemSize } = this.state;

    return (
      <VariableSizeList
        ref={this.list}
        outerRef={innerRef}
        innerElementType={innerElementType}
        height={height + PADDING_SIZE * 2}
        itemCount={itemCount}
        itemSize={this.itemSize}
        estimatedItemSize={estimatedItemSize}
        itemData={Array.isArray(children) ? children : [children]}
      >
        {OptionWrapper}
      </VariableSizeList>
    );
  }
}
