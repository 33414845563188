//
import React, { useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  RadioButton,
  Card,
  Icons,
  Content,
  IconButton,
  ApplicationSettingsContext,
} from 'imdshared';
import type { EntityModels } from 'imddata';
import { useDeleteEntity } from 'imddata';
import type { OverviewGroup } from 'imddata/types/entities';
import TotalRow from '../TotalRow';
import { OverviewGroupLabel } from 'components';
import { clickableStyle } from 'imdui/src/components/button/Clickable';

const OrderCard = styled(Card)`
  overflow: initial;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const OrderClickable = styled.div<{ selected?: boolean }>`
  border-radius: 8px;
  width: 100%;
  text-align: left;
  border-radius: 20px;
  ${clickableStyle}
  box-shadow: ${({ selected }) =>
    selected ? ` 0 0 0 2px var(--accent)` : 'none'};
`;

const DisabledNotice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  & > div {
    background-color: #f7f7f7;
    padding: 24px;
    border-radius: 8px;
  }
`;

const OrderHeader = styled.div`
  height: 64px;
  padding: 8px 8px 8px 24px;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemsWrapper = styled.div`
  padding: 8px 0;
`;

const Row = styled.div`
  flex: 1;
`;

const RadioButtonStyled = styled(RadioButton)`
  align-self: center;
  padding: 8px 0 8px 24px;
`;

type Props = {
  order: OverviewGroup;
  children?: React.ReactChild;
  currency: EntityModels.Currency;
  deletable?: boolean;
  canBeApplied?: boolean;
  selected?: boolean;
  id: number;
  getOverview?: () => void;
  onClick?: (id: number) => void;
};

const Order = ({
  onClick,
  currency,
  getOverview,
  children,
  order,
  deletable,
  canBeApplied,
  selected,
  id,
}: Props) => {
  const { t } = useTranslation();

  const { deleteEntry } = useDeleteEntity({ entity: 'orders', id });

  const handleClick = useMemo(
    !onClick ? () => undefined : () => () => onClick(id),
    [id, onClick]
  );
  const disabled = !canBeApplied;

  const wrapperProps = useMemo(
    () =>
      onClick
        ? {
          selected,
          disabled,
          onClick: handleClick,
        }
        : {},
    [onClick]
  );

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currency.id,
  });

  return (
    <OrderCard>
      {onClick && !canBeApplied && (
        <DisabledNotice>
          <div>{t('voucher-can-not-be-applied-to-that-order')}</div>
        </DisabledNotice>
      )}
      <OrderClickable {...wrapperProps}>
        {onClick && (
          <RadioButtonStyled selected={!!selected} disabled={disabled} />
        )}

        <Row>
          <OrderHeader>
            <Content>
              <OverviewGroupLabel overviewGroup={order} />
            </Content>

            {id && deletable && getOverview ? (
              <IconButton
                icon={Icons.actions.close}
                onClick={() => {
                  deleteEntry({}, { resolve: () => getOverview() });
                }}
              />
            ) : null}
          </OrderHeader>

          <ItemsWrapper>{children}</ItemsWrapper>

          <TotalRow
            label={t('order-total')}
            value={priceFormatter.format(Number(order.value))}
          />
        </Row>
      </OrderClickable>
    </OrderCard>
  );
};

export default Order;
