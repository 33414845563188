import { pick, map } from 'ramda';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import shallowEqual from 'shallowequal';
import type {
  EntityRequestsReduxState,
  RequestState,
  ReduxState,
  EntityIdentifier,
  EntityReduxState,
  CreateRequestState,
} from '../../../types';

export const createShallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => shallowEqual(a, b)
);

const emptyRequest: RequestState = {
  entryCount: 0,
  hasMorePages: false,
  loading: false,
  loaded: false,
  failed: false,
};

type RequestSelectorOptions = {
  entity: EntityIdentifier;
  queryHash?: string;
  id?: string | number;
};

const getRequestStatus = (
  entityRequests: EntityRequestsReduxState,
  { entity, queryHash, id }: RequestSelectorOptions
): RequestState => {
  if (id && queryHash) {
    const itemHistory = entityRequests[entity]?.items[id]?.searchHistory;
    return itemHistory ? itemHistory[queryHash] || emptyRequest : emptyRequest;
  }

  if (id) {
    return entityRequests[entity]?.items[id] || emptyRequest;
  }

  if (queryHash) {
    return entityRequests[entity]?.searchHistory[queryHash]
      ? entityRequests[entity].searchHistory[queryHash]
      : emptyRequest;
  }
  return entityRequests[entity] || emptyRequest;
};

const fallbackEntityState: EntityReduxState<null> = {
  searchHistory: {},
  indexedData: {},
  data: {},
  keys: [],
  newItemsKeys: [],
  entities: {},
};

export const selectEntityData = (
  state: ReduxState,
  entity: EntityIdentifier
) => {
  const entityData = state.entities[entity];

  return entityData || fallbackEntityState;
};

export const makeSelectRequestState = <
  T extends readonly [keyof RequestState, ...(keyof RequestState)[]],
>(
  keys: T
) =>
  createShallowEqualSelector(
    [
      (
        state: ReduxState,
        { entity, id, queryHash }: RequestSelectorOptions
      ) => {
        const requestData = getRequestStatus(state.requests.entities, {
          entity,
          id,
          queryHash,
        });
        return pick(keys, requestData);
      },
    ],
    (data) => data
  );

export const makeMissingRequestSelector = () =>
  createShallowEqualSelector(
    [
      (state: ReduxState, missingQueries: Array<RequestSelectorOptions>) => {
        return map(({ entity, queryHash }) => {
          return getRequestStatus(state.requests.entities, {
            entity,
            queryHash,
          });
        }, missingQueries);
      },
    ],
    (data) => data
  );

const createRequestExtractedKeys: [
  keyof CreateRequestState,
  ...(keyof CreateRequestState)[],
] = [
  'loaded',
  'loading',
  'failed',
  'errorMessage',
  'errors',
  'statusCode',
  'error',
  'errorData',
  'createdEntityId',
];

export const makeSelectCreateRequestState = () =>
  createShallowEqualSelector(
    (
      state: ReduxState,
      {
        entity,
        requestStoreKey,
      }: { entity: EntityIdentifier; requestStoreKey?: string }
    ) => {
      const requestData = state.requests.entities[entity];

      if (!requestData) {
        console.warn(entity, 'entity is not yet exisiting');
      }

      const componentRequestStatus =
        requestStoreKey &&
        requestData?.awaitingComponents &&
        requestData?.awaitingComponents[requestStoreKey];

      return componentRequestStatus
        ? pick(createRequestExtractedKeys, componentRequestStatus)
        : false;
    },
    (selection) =>
      selection
        ? {
            created: selection.loaded,
            creating: selection.loading,
            failed: selection.failed,
            statusCode: selection.statusCode,
            errorMessage: selection.errorMessage,
            errors: selection.errors,
            errorData: selection.errorData,
            error: selection.error,
            id: selection.createdEntityId,
          }
        : {
            created: false,
          }
  );
