import prettyBytes from 'pretty-bytes';
import React from 'react';
import * as Text from '../Text';
import { DownloadIcon, FileIcon, ImageIcon } from '@imus/base-ui';
import { fileStyle, previewStyle, textColumnStyle } from './styles';
import { IconButton } from 'imdui';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  url: string;
  extension: string;
  size?: number;
};

const getExtensionIcon = (ext?: string) => {
  switch (ext) {
    case 'pdf':
    case 'csv':
    case 'txt':
      return FileIcon;
    case 'jpeg':
    case 'jpg':
    case 'png':
      return ImageIcon;
    default:
      return null;
  }
};

const getPressFilePreviewStub = (
  extension: string | undefined
): React.VFC<React.SVGProps<SVGSVGElement>> | null => {
  return getExtensionIcon(extension);
};

export const PressFileItem: React.FC<Props> = ({
  name,
  extension,
  size,
  url,
  style,
  className,
}) => {
  const PreviewImg = getPressFilePreviewStub(extension);

  return (
    <div
      data-test-id="PressKitDownloadLink"
      data-component="presskit-link"
      css={fileStyle}
      style={style}
    >
      {PreviewImg && <PreviewImg css={previewStyle} />}
      <div css={textColumnStyle}>
        <Text.ContentM>{name}</Text.ContentM>
        {!!size && <Text.BodyS>{prettyBytes(size)}</Text.BodyS>}
      </div>
      <a href={url} download css={previewStyle}>
        <IconButton
          onClick={() => {
            return;
          }}
          className={className}
          icon={DownloadIcon}
        />
      </a>
    </div>
  );
};
