//
import * as R from 'ramda';
import { entities } from '../../../../actionTypes';

const initialState = {
  volumes: {},
  searchHistory: {},
  releaseArtists: {},
  entities: {},
  keys: [],
};

function addItem(state, action) {
  switch (action.type) {
    case entities.fieldUpdateRequestItems.types.delete.SUCCESS: {
      const {
        payload: { id, query: { requestId } = {} },
      } = action;

      return R.over(
        R.lensPath(['entities', requestId, 'fields']),
        (value) => {
          if (!value) return [];
          return R.without([id], value);
        },
        state
      );
    }
    case entities.fieldUpdateRequestItems.types.create.SUCCESS: {
      const {
        payload: { query: { requestId } = {} },
        response: { result },
      } = action;

      return R.over(
        R.lensPath(['entities', requestId, 'fields']),
        (value) => {
          if (!value) return [result];
          return [...value, result];
        },
        state
      );
    }
    default:
      return state;
  }
}

export default function fieldUpdateRequests(state = initialState, action) {
  return [addItem].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
