import React from 'react';
import { css } from '@emotion/react';
import {
  reduxForm,
  SubmissionError,
  Field,
  InjectedFormProps,
} from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import isEmail from 'validator/lib/isEmail';
import { callAuthApi } from 'imddata';
import {
  Block,
  Button,
  NewInputField,
  palette,
  Subheading,
  AlertBox,
} from 'imdshared';

const buttonStyle = css`
  justify-content: center;
`;

type FormData = {
  email: string;
};

const submitForm = ({ email }: FormData) => {
  return callAuthApi.resetPasswordRequest({ email }).then((response) => {
    if ('error' in response) {
      throw new SubmissionError({ _error: response.error.message });
    }
  });
};

function ResetPassword({
  error,
  submitting,
  submitSucceeded,
  handleSubmit,
  pristine,
}: InjectedFormProps<FormData>) {
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Helmet>
        <title>{t('page-title-password-reset')}</title>
      </Helmet>

      <Block padding={[30, 20, 20, 20]}>
        <Block margin={[0, 0, 25, 0]}>
          <Subheading style={{ color: palette.dark.xLight }}>
            {t('reset-password')}
          </Subheading>
        </Block>

        <Block margin={[0, 0, 20, 0]}>
          <Field
            testId="ResetPassword-email"
            key="email"
            name="email"
            errorText={error}
            props={{
              autoFocus: true,
              placeholder: t('please-enter-your-email'),
              floatingLabelText: t('email'),
            }}
            component={NewInputField}
          />
        </Block>

        {submitSucceeded && (
          <AlertBox
            testId="ResetPassword-email-sent"
            text={t('reset-email-has-been-sent')}
          />
        )}

        {!submitSucceeded && (
          <Button
            testId="ResetPassword-submit"
            type="submit"
            disabled={pristine}
            primary={true}
            text={t('reset-password')}
            showLoading={submitting}
            css={buttonStyle}
          />
        )}
      </Block>
    </form>
  );
}

const validate = (values: FormData) => {
  const errors: Partial<FormData> = {};
  if (!values.email) {
    errors.email = 'required';
  } else if (!isEmail(values.email)) {
    errors.email = 'invalid-email-address';
  }
  return errors;
};

export default reduxForm<FormData>({
  form: 'resetPasswordForm',
  validate,
})(ResetPassword);
