//
import * as R from 'ramda';
import { actionTypes } from '../../../../actionTypes/prices';

const initialState = {
  searchHistory: {},
  entities: {},
  keys: [],
  prices: {},
};

function extractFromOverview(state = initialState, action) {
  switch (action.type) {
    case actionTypes.overviewByRegion.SUCCESS: {
      return R.mergeDeepRight(state, {
        prices: {
          ...state.prices,
          ...action.response.entities.combined.fixKey.releaseTypes,
        },
      });
    }
    default:
      return state;
  }
}

export default function releaseTypes(state = initialState, action) {
  return [extractFromOverview].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
