import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { isNil } from 'ramda';
import { useDeliveryRegions } from 'imddata';
import styled from '@emotion/styled';
import type * as Types from './types';
import Region from './Region';

export type DeliveryRegionsFieldValue = Types.Value;

const RegionsGrid = styled.div`
  display: grid;
  gap: 16px;
`;

export const isChecked = (value: string | boolean): boolean =>
  isNil(value) || value === 'takedown' || value === 'not_delivered'
    ? false
    : value === true || value === 'deliver' || value === 'delivered'; // TODO: remove true when API will use statuses

export const getDeliveryStatus = (
  currentStatus: string | boolean | undefined,
  checked: boolean
) => {
  if (currentStatus === 'delivered') {
    if (checked) {
      return 'delivered';
    }
    return 'takedown';
  }
  if (currentStatus === 'deliver') {
    if (!checked) {
      return false;
    }
    return 'deliver';
  }
  if (currentStatus === 'takedown') {
    if (!checked) {
      return 'takedown';
    }
    return 'delivered';
  }
  return 'deliver';
};

const filterNonDeliver = (v: Types.Value[0]) => v.status !== false;

const emptyRegions: Types.Regions = [];

export const useCountryRegionsValue = (
  value: Types.Value | undefined,
  onChange: (v: Types.Value) => void
) => {
  const {
    entries,
    request: { loaded, loading },
  } = useDeliveryRegions();

  const regions = useMemo<Types.Regions>(
    () =>
      loaded
        ? entries.map((deliveryRegion) => {
            const countries = deliveryRegion.countries.map(
              ({ countryId, country }) => {
                const bunldeCountryStatus =
                  value &&
                  Array.isArray(value) &&
                  value?.find((bc) => bc.countryId === countryId)?.status;
                return {
                  id: country.id,
                  name: country.name,
                  status: bunldeCountryStatus || false,
                  isChecked: isChecked(bunldeCountryStatus || false),
                };
              }
            );
            return {
              id: deliveryRegion.id,
              name: deliveryRegion.name,
              isChecked: countries.reduce<boolean>(
                (acc, c) => acc && c.isChecked,
                true
              ),
              hasCheckedItems: countries.reduce<boolean>(
                (acc, c) => acc || c.isChecked,
                false
              ),
              countries,
            };
          })
        : emptyRegions,
    [entries, value, loaded]
  );

  const onCheckAll = useCallback(
    (_, checked) => {
      const newCountries = regions.reduce<Types.Value>((acc, region) => {
        return [
          ...acc,
          ...region.countries.map(
            (c) => ({
              status: getDeliveryStatus(c.status, checked),
              countryId: c.id,
            }),
            {}
          ),
        ].filter(filterNonDeliver);
      }, []);
      onChange(newCountries);
    },
    [onChange, regions]
  );

  return { regions, loading, loaded, onCheckAll };
};

const DeliveryRegions = ({
  disabled,
  className,
  input: { value, onChange },
}: {
  disabled?: boolean;
  className?: string;
  meta: { form: string; error: any };
  input: {
    value?: Types.Value;
    onChange: (v: Types.Value) => void;
  };
}) => {
  const { regions, loading } = useCountryRegionsValue(value, onChange);

  const onRegionCheck = useCallback(
    (region: Types.Region, checked: boolean) => {
      const newCountries = region.countries.map((country) => ({
        countryId: country.id,
        status: getDeliveryStatus(country.status, checked),
      }));
      const otherRegions = regions.filter((r) => r.id !== region.id);
      const otherCountries = otherRegions.reduce<Types.Value>(
        (acc, r) => [
          ...acc,
          ...r.countries
            .filter(
              (c) => newCountries.findIndex((nc) => nc.countryId === c.id) < 0
            ) // Make sure not to include duplicates from other regions
            .map((c) => ({ countryId: c.id, status: c.status })),
        ],
        []
      );
      onChange([...newCountries, ...otherCountries].filter(filterNonDeliver));
    },
    [onChange, regions]
  );
  const handleCountryCheck = useCallback(
    (country: Types.Country, checked: boolean) => {
      const status = getDeliveryStatus(country.status, checked);
      const withoutCountry =
        value?.filter((c) => c.countryId !== country.id) || [];

      onChange(
        [...withoutCountry, { countryId: country.id, status }].filter(
          filterNonDeliver
        )
      );
    },
    [onChange, regions]
  );

  const [countryUpdate, setCountryUpdate] = useState<{
    country: Types.Country;
    checked: boolean;
  }>();
  useEffect(() => {
    if (countryUpdate) {
      handleCountryCheck(countryUpdate.country, countryUpdate.checked);
    }
  }, [countryUpdate]);

  const onCountryCheck = useCallback(
    (country: Types.Country, checked: boolean) => {
      setCountryUpdate({ country, checked });
    },
    []
  );

  return (
    <RegionsGrid className={className}>
      {regions &&
        regions.map((region) => (
          <Region
            disabled={disabled}
            loading={loading}
            key={region.id}
            region={region}
            onCountryCheck={onCountryCheck}
            onRegionCheck={onRegionCheck}
          />
        ))}
    </RegionsGrid>
  );
};

export default DeliveryRegions;
