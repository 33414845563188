import React, { useCallback } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, useHistory } from 'react-router-dom';
import { OptionTab } from '../OptionTabs';
import { TabItem } from '.';
import type { TabItemProps } from '.';

type Props = {
  text: string;
  onClick?: TabItemProps['onClick'];
  exact?: boolean;
  to: string;
  testId?: string;
  state?: Record<string, unknown>;
};

export const ToggleOptionLink: React.FC<Props> = (props) => {
  const { onClick, text, testId, exact, to, state } = props;

  const history = useHistory();

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      history.push(to, state);
    },
    [to, onClick]
  );

  return (
    <Route path={to} exact={exact}>
      {({ match }: RouteComponentProps) => (
        <OptionTab
          testId={testId}
          label={text}
          value={to}
          onChange={handleClick}
          selected={!!match}
        />
      )}
    </Route>
  );
};

type TabProps = Omit<TabItemProps, 'onClick'> & {
  onClick?: TabItemProps['onClick'];
  exact?: boolean;
  to: string;
  state?: Record<string, unknown>;
};

export const TabItemLink: React.FC<TabProps> = (props) => {
  const { onClick, exact, to, state } = props;

  const history = useHistory();

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e);
      }
      history.push(to, state);
    },
    [to, onClick]
  );

  return (
    <Route path={to} exact={exact}>
      {({ match }: RouteComponentProps) => (
        <TabItem {...props} onClick={handleClick} selected={!!match} />
      )}
    </Route>
  );
};
