export const RemoveIcon = ({ fill = 'var(--fg-2)' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.13"
      d="M2 9.2C2 7.67976 2 5.41965 2.49047 4.45704C2.9219 3.61031 3.61031 2.9219 4.45704 2.49047C5.41965 2 6.67976 2 9.2 2H14.8C17.3202 2 18.5804 2 19.543 2.49047C20.3897 2.9219 21.0781 3.61031 21.5095 4.45704C22 5.41965 22 6.67976 22 9.2V14.8C22 17.3202 22 18.5804 21.5095 19.543C21.0781 20.3897 20.3897 21.0781 19.543 21.5095C18.5804 22 17.3202 22 14.8 22H9.2C6.67976 22 5.41965 22 4.45704 21.5095C3.61031 21.0781 2.9219 20.3897 2.49047 19.543C2 18.5804 2 17.3202 2 14.8V9.2Z"
      fill={fill}
      fillOpacity="0.95"
    />
    <path
      d="M6 12.0002C6 11.448 6.44772 11.0003 7.00002 11.0003C10.3334 11.0003 13.6666 11.0005 17 11.0003C17.5523 11.0003 18 11.448 18 12.0002C18.0001 12.5525 17.5524 13.0003 17.0001 13.0003C13.6667 13.0005 10.3334 13.0001 7.00002 13.0001C6.44772 13.0001 6 12.5525 6 12.0002Z"
      fill={fill}
      fillOpacity="0.95"
    />
  </svg>
);
