//
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Content, BodySmall } from 'imdui';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  padding: 24px;
  gap: 4px;
`;

const NoTracks = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>{t('add-track-to-use-volume')}</Content>
      <BodySmall>{t('list-is-empty')}</BodySmall>
    </Container>
  );
};

export default NoTracks;
