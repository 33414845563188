// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';
import type { EntityModels } from 'imddata';
import { useEntries, useQuery, useCurrentCustomer } from 'imddata';
import { AffiliateReferralTemplate } from '../../shared';

const Affiliate = () => {
  const { entry: customer } = useCurrentCustomer();

  const { query, queryHash } = useQuery({
    query: {
      perPage: 5,
      'filter.operation': 'affiliate_revenue',
    },
  });
  const {
    loadMore,
    entries,
    request: { hasMorePages, loading },
  } = useEntries<EntityModels.Transaction>({
    entity: 'transactions',
    query,
    queryHash,
  });
  if (!customer) return null;

  if (customer && !customer.isAffiliatePartner) {
    return <Redirect to="/dashboard" />;
  }

  const {
    affiliateSignUpsCount,
    affiliatePartnerToken,
    affiliateRevenueFormatted,
    affiliateShare,
    affiliateCustomerDiscount,
  } = customer;

  const loyalty = parseInt(affiliateCustomerDiscount, 10);
  const bonus = parseInt(affiliateShare, 10);
  const imdShare = 100 - loyalty - bonus;

  const link = `imusician.app/signup?affiliate_partner_token=${affiliatePartnerToken}`;

  const translationPrefix = 'affiliate';
  return (
    <AffiliateReferralTemplate
      transactions={entries}
      loadNextTransactions={loadMore}
      loadingTransactions={loading}
      hasMoreTransactions={!!hasMorePages}
      testId="Affiliate"
      split={{
        imdShare,
        bonus,
        loyalty,
      }}
      totalRevenue={affiliateRevenueFormatted}
      tokenUsedCount={affiliateSignUpsCount}
      translationPrefix={translationPrefix}
      link={link}
    />
  );
};

export default Affiliate;
