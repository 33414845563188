// @flow
import React, { useContext } from 'react';
import type { WrappedFieldProps } from 'redux-form';
import { FormSection, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { HelpWindowContext, DropdownMenu } from 'imdshared';
import { FieldGroup } from 'imdui';

const tempalateLanguagesData: Array<{
  label: string;
  value: string;
}> = [
  { label: 'Deutsch', value: 'de' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Italiano', value: 'it' },
  { label: 'Português', value: 'pt' },
];

export const TemplateLanguageField = ({
  onClickHelp,
  label,
  input,
}: WrappedFieldProps & { label: string; onClickHelp?: () => void }) => {
  return (
    <DropdownMenu
      onClickHelp={onClickHelp}
      label={label}
      {...input}
      data={tempalateLanguagesData}
    />
  );
};

export type GeneralFormData = {
  locale: string;
};

const GeneralForm: React.FC<{ releasePageId: string }> = () => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);

  return (
    <FormSection name="general">
      <FieldGroup>
        <Field
          name="locale"
          label={t('page-language')}
          onClickHelp={() => {
            showHelpWindow(t('page-language'), t('page-helptext-language'));
          }}
          component={TemplateLanguageField}
        />
      </FieldGroup>
    </FormSection>
  );
};

export default GeneralForm;
