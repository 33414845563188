//

import { action, createRequestActions } from '../base';

export const types = {
  pricesCustomer: {
    SUCCESS: '@prices/FETCH_PRICES_CUSTOMER_SUCCESS',
    REQUEST: '@prices/FETCH_PRICES_CUSTOMER_REQUEST',
    FAILURE: '@prices/FETCH_PRICES_CUSTOMER_FAILURE',
  },
  overviewByRegion: {
    SUCCESS: '@prices/OVERVIEW_BY_REGION_SUCCESS',
    REQUEST: '@prices/OVERVIEW_BY_REGION_REQUEST',
    FAILURE: '@prices/OVERVIEW_BY_REGION_FAILURE',
  },
};

export const actionTypes = types;

export const actions = {
  pricesCustomer: createRequestActions(types.pricesCustomer, 'null', 'fetch'),
  overviewByRegion: createRequestActions(
    types.overviewByRegion,
    null,
    'create'
  ),
};

export const LOAD_PRICES_OVERVIEW = '@prices/LOAD_PRICES_OVERVIEW';
export const REFRESH_PRICES_OVERVIEW = '@prices/REFRESH_PRICES_OVERVIEW';

export const loadPricesOverview = (priceRegionId: string) =>
  action(LOAD_PRICES_OVERVIEW, { id: priceRegionId });

export const refreshPricesOverview = (priceRegionId: string) =>
  action(REFRESH_PRICES_OVERVIEW, { id: priceRegionId });
