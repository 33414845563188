//
import * as R from 'ramda';

const generateIdLens = (id) => {
  if (id === undefined || id === null) return R.lensPath([]);
  if (!Array.isArray(id)) {
    return R.lensPath(['items', id.toString()]);
  }
  return R.lensPath(
    id.reduce(
      (accLens, singleId) => [...accLens, 'items', singleId.toString()],
      []
    )
  );
};

export default generateIdLens;
