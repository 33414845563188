import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { AppOutdatedContext } from '../../logic';

// TODO: reinstate caching
const RefreshWindowController = ({ children }) => {
  const [isAppOutdated, setIsAppOutdated] = useState(false);

  useEffect(() => {
    // OfflinePluginRuntime.install({
    //   onUpdating: () => {
    //     console.log('SW Event:', 'onUpdating');
    //   },
    //   onUpdateReady: () => {
    //     console.log('SW Event:', 'onUpdateReady');
    //     // Tells to new SW to take control immediately
    //     OfflinePluginRuntime.applyUpdate();
    //   },
    //   onUpdated: () => {
    //     setIsAppOutdated(true);
    //   },
    //   onUpdateFailed: () => {
    //     console.log('SW Event:', 'onUpdateFailed');
    //   },
    // });
  }, []);

  const forceUpdate = useCallback(() => {
    // OfflinePluginRuntime.update();
  }, []);

  const context = useMemo(
    () => ({
      forceUpdate,
      isAppOutdated,
      setIsAppOutdated,
    }),
    [forceUpdate, isAppOutdated, setIsAppOutdated]
  );

  return (
    <AppOutdatedContext.Provider value={context}>
      {children}
    </AppOutdatedContext.Provider>
  );
};

export default RefreshWindowController;
