import { action } from '../../base';

export enum types {
  UPDATE_SELECTION = '@ui/selection/UPDATE_SELECTION',
  CLEAR_SELECTION = '@ui/selection/CLEAR_SELECTION',
}

export const actions = {
  updateSelection: ({
    entity,
    selected,
  }: {
    entity: string;
    selected: Array<number | string>;
  }) =>
    action(types.UPDATE_SELECTION, {
      entity,
      selected,
    }),
  clearSelection: () => action(types.CLEAR_SELECTION, {}),
};
