//
import React, { useMemo } from 'react';
import type { JoinTagData } from '../types';
import JoinTagItem from './JoinTagItem';
import styled from '@emotion/styled';

export { default as JoinTagItem } from './JoinTagItem';

export const JoinTagListWrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--fg-4, rgba(0, 0, 0, 0.1));
  background: var(--bg-3, #f2f2f2);
`;

type ItemProps = React.ComponentProps<typeof JoinTagItem>;
type Props = {
  onClickItem?: ItemProps['onClickItem'];
  onMoveItem?: ItemProps['onMoveItem'];
  onDropItem?: ItemProps['onDropItem'];
  onDelete?: ItemProps['onClickDelete'];
  onChange?: ItemProps['onChange'];
  values: ItemProps['values'];
  draggable?: boolean;
  disabled?: boolean;
  type: string;
  data: JoinTagData;
  renderItem: React.FC<ItemProps & { type?: string }>;
};

export const JoinTags = (props: Props) => {
  const {
    data,
    type,
    values,
    onChange,
    renderItem,
    onDelete,
    onClickItem,
    onMoveItem,
    onDropItem,
    disabled = false,
    draggable = false,
  } = props;

  const dropdownData = useMemo(
    () =>
      data.tags.map((tagKey) => {
        return {
          label: tagKey,
          key: data[tagKey].key,
          getValue: data[tagKey].getValue,
          isDisabled: data[tagKey].isDisabled,
          values: {
            keys: data[tagKey].order,
            entries: data[tagKey].options,
          },
        };
      }),
    [data]
  );

  return values?.length ? (
    <JoinTagListWrapper>
      {values?.map((value, index) =>
        renderItem({
          disabled,
          type,
          index,
          onMoveItem: value.static || !draggable ? undefined : onMoveItem,
          onDropItem: value.static || !draggable ? undefined : onDropItem,
          onClickDelete: onDelete,
          onClickItem,
          dropdownData,
          onChange,
          value,
          values,
        })
      )}
    </JoinTagListWrapper>
  ) : null;
};

JoinTags.defaultProps = {
  renderItem: JoinTagItem,
};

export default JoinTags;
