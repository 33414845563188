import { useEffect } from 'react';
import { useOverview } from 'imddata';

const UPDATE_OVERVIEW_KEY = 'UPDATE_OVERVIEW';

export default ({ deleted }: { deleted: boolean }): void => {
  const { refresh } = useOverview({
    entity: 'deliveryBundleUpdateOverview',
    componentKey: UPDATE_OVERVIEW_KEY,
  });

  useEffect(() => {
    if (deleted) {
      refresh();
    }
  }, [deleted]);
};
