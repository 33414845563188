import { useCurrentCustomerSubscription } from 'imddata';
import type { SubscriptionUpsellContext } from './useSubscriptionUpsell';
import { useMemo } from 'react';
import type { SubscriptionIdTier2 } from 'imddata/types/entities';

const T2_SUBS: SubscriptionIdTier2[] = [
  'tier-2-2',
  'tier-2-5',
  'tier-2-15',
  'tier-2-25',
];
function useCustomerNextTier2Id(): SubscriptionIdTier2 | null {
  const sub = useCurrentCustomerSubscription();
  if (
    !sub ||
    (sub.subscriptionId !== 'tier-2-2' &&
      sub.subscriptionId !== 'tier-2-5' &&
      sub.subscriptionId !== 'tier-2-25' &&
      sub.subscriptionId !== 'tier-2-15')
  )
    return 'tier-2-2';
  const currentIdx = T2_SUBS.indexOf(sub.subscriptionId);
  if (currentIdx < T2_SUBS.length - 1) {
    return T2_SUBS[currentIdx + 1];
  }
  return null;
}

export function useOpenTier2UpsellContext() {
  const tier2Id = useCustomerNextTier2Id();

  return useMemo<SubscriptionUpsellContext>(
    () =>
      tier2Id
        ? {
            recommendedSubscriptionId: tier2Id,
            subscriptionIds: [tier2Id, 'tier-3'],
          }
        : {},
    [tier2Id]
  );
}
