//
import React, { useEffect, useCallback } from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, SubmissionError } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Block, LinkButton, Body } from 'imdshared';
import { callAuthApi } from 'imddata';
import { SocialSignin } from '../../shared';

type Props = {
  continueLink?: string;
};
type FormData = Record<string, never>;

function VerifyEmail({
  error,
  continueLink,
  submitFailed,
  submitting,
  submitSucceeded,
  handleSubmit,
}: InjectedFormProps<FormData, Props> & Props) {
  const { code } = useParams<{ code: string }>();

  const submitForm = useCallback(() => {
    return callAuthApi.verifyEmail({ code }).then((response) => {
      if ('error' in response) {
        throw new SubmissionError({ _error: response.error.message });
      }
    });
  }, [code]);

  useEffect(() => {
    handleSubmit(submitForm)();
  }, []);
  const { t } = useTranslation();

  return (
    <form style={{ padding: '20px' }} onSubmit={handleSubmit(submitForm)}>
      <Block borderBottom={true}>
        <Block margin={[0, 0, 20, 0]}>
          <div>
            {submitFailed && (
              <div style={{ textAlign: 'center' }}>
                <Body>{t(error)}</Body>
              </div>
            )}

            {submitSucceeded && (
              <div
                data-test-id="VerifyEmail-success"
                style={{ textAlign: 'center' }}
              >
                <Body>{t('email-has-been-verified')}</Body>
              </div>
            )}

            <LinkButton
              to={continueLink || '/signin'}
              primary={true}
              showLoading={submitting}
              text={continueLink ? t('return-to-app') : t('login')}
              style={{ width: '100%' }}
            />
          </div>
        </Block>
      </Block>

      {!continueLink && (
        <Block padding={[10, 0, 0, 0]}>
          <SocialSignin />
        </Block>
      )}
    </form>
  );
}

export default reduxForm<FormData, Props>({
  form: 'verifyEmail',
})(VerifyEmail);
