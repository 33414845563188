import styled from '@emotion/styled';
import React from 'react';

export const BadgeContainer = styled.div<{
  count: number;
}>`
  pointer-events: none;
  padding: 2px 4px;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ theme }): string => theme.accent.green};
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: white;
  transition: transform 0.1s ease-in-out;
  transform: scale(${({ count }): number => (count > 0 ? 1 : 0)});
`;

type Props = {
  count: number;
  className?: string;
  style?: React.CSSProperties;
};

const Badge: React.FC<Props> = ({ count, className, style }) => {
  return (
    <BadgeContainer className={className} style={style} count={count}>
      {count}
    </BadgeContainer>
  );
};

export default Badge;
