import type { TFunction } from 'i18next';

export const createMaxLengthValidation =
  (maxLength: number, t: TFunction, required = false) =>
  (value: string) =>
    !value?.length && required
      ? 'required'
      : value && value.length > maxLength
        ? t('exceeded-maximum-length', { maxLength })
        : '';
