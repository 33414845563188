//
import * as R from 'ramda';

const fileReducer =
  (types, key = 'file') =>
  (state, action) => {
    switch (action.type) {
      case types.confirm.SUCCESS:
        return R.compose(
          R.set(
            R.lensPath(['entities', action.payload.id, key]),
            action.payload.file
          ),
          R.set(
            R.lensPath(['entities', action.payload.id, 'previewStatus']),
            'pending'
          ),
          R.set(
            R.lensPath(['entities', action.payload.id, 'uploadStatus']),
            'confirmed'
          )
        )(state);
      case types.delete.REQUEST:
      case types.upload.FAILURE:
      case types.confirm.FAILURE:
      case types.upload.ABORT:
        return R.set(
          R.lensPath(['entities', action.payload.id, 'localFilePreview']),
          undefined,
          state
        );
      case types.delete.SUCCESS:
        return R.compose(
          R.set(
            R.lensPath(['entities', action.payload.id, 'previewStatus']),
            'none'
          ),
          R.set(
            R.lensPath(['entities', action.payload.id, 'uploadStatus']),
            'none'
          ),
          R.set(R.lensPath(['entities', action.payload.id, key]), undefined)
        )(state);
      default:
        return state;
    }
  };

export default fileReducer;
