import React from 'react';
import styled from '@emotion/styled';
import { BodySmall } from 'imdui';
import type { Change } from '../../types';
import ChipChange from './ChipChange';

interface Props {
  title: string;
  changes: Change[];
}

const Wrapper = styled.div`
  width: 100%;

  > ${BodySmall} {
    display: block;
    margin-bottom: 16px;
    font-weight: 500;
  }

  :not(:first-of-type) {
    margin-top: 24px;
  }
`;

const Region: React.FC<Props> = ({ title, changes }) => {
  return (
    <Wrapper>
      <BodySmall>{title}</BodySmall>

      {changes.map((c) => (
        <ChipChange key={c.id} change={c} label={c.details.countryName} />
      ))}
    </Wrapper>
  );
};

export default Region;
