import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSalesStatementsRanges } from 'imddata';
import { useTranslation } from 'react-i18next';
import { DropdownMenu } from 'imdshared';
import { OptionTab, OptionTabsWrapper, OverlineText } from 'imdui';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 32px;

  ${OverlineText} {
    margin-bottom: 4px;
  }
`;

const StatementRangeSelector = ({
  onChange,
}: {
  onChange: (v: [string, string]) => void;
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'month' | 'year'>('month');

  const {
    filterValue,
    handleChange,
    statementRange: [from, to],
    months,
    years,
  } = useSalesStatementsRanges();

  useEffect(() => {
    if (filterValue) {
      onChange([from, to]);
    }
  }, [from, to]);
  useEffect(() => {
    if (mode === 'month') {
      handleChange(null, [months[0].value, months[0].value]);
    } else if (mode === 'year') {
      handleChange(null, [years[0].value, years[0].value]);
    }
  }, [mode]);

  return (
    <Wrapper>
      <OptionTabsWrapper>
        <OptionTab
          onChange={() => setMode('month')}
          selected={mode === 'month'}
          label={t('month')}
          value={'month'}
        />
        <OptionTab
          onChange={() => setMode('year')}
          selected={mode === 'year'}
          label={t('year')}
          value={'year'}
        />
      </OptionTabsWrapper>

      <div>
        <DropdownMenu
          value={from}
          data={mode === 'month' ? months : years}
          onChange={(v) => handleChange(null, [v, v])}
          // onChange={handleFromChange}
          style={{ marginBottom: 0 }}
        />
      </div>
    </Wrapper>
  );
};

export default StatementRangeSelector;
