import { mergeDeepRight } from 'ramda';
import { types, actions } from '../../actionTypes/ui/fieldUpdateRequests';

const initialState = {};

export type FieldUpdateRequestCheck = {
  issues: Record<number, boolean>;
};

export type UiFieldUpdateRequestState = Record<
  string,
  Record<string, FieldUpdateRequestCheck>
>;

type Action = {
  type: typeof types.CHECK_ISSUE;
  payload: Parameters<typeof actions.checkIssue>[0];
};

const fieldUpdateRequests = (
  state: UiFieldUpdateRequestState = initialState,
  action: Action
) => {
  switch (action.type) {
    case types.CHECK_ISSUE: {
      const { id, changeId, issueIndex, checked } = action.payload;

      return mergeDeepRight(state, {
        [id]: {
          [changeId]: {
            issues: {
              [issueIndex]: checked,
            },
          },
        },
      });
    }

    default:
      return state;
  }
};

export default fieldUpdateRequests;
