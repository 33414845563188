import type { EntityModels } from 'imddata';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions as impersonateActions } from 'redux/actions/ui/impersonate';
import CustomerListItem from './CustomerListItem';

interface VirtualizedItemProps {
  key: string;
  item: CustomerNested;
  style: React.CSSProperties;
}

type CustomerNested = EntityModels.Nest<
  EntityModels.Customer,
  {
    users: EntityModels.User[];
  }
>;

export default function VirtualizedCustomerItem({
  key,
  item: { id: customerId, subscriptionId, users },
  style,
}: VirtualizedItemProps): JSX.Element {
  const dispatch = useDispatch();

  const handleListItemClick = (): void => {
    if (users) {
      dispatch(
        impersonateActions.setImpersonation({
          userId: users[0].id,
          customerId,
        })
      );
    }
  };

  const testId = users ? `Impersonate-userId-${users[0].id}` : undefined;
  const title = users
    ? `${users[0].firstName} ${users[0].lastName}`
    : `Customer ${customerId}`;
  const subtitle = users ? users[0].email : 'No users';
  const { t } = useTranslation();

  return (
    <CustomerListItem
      key={key}
      testId={testId}
      subscription={subscriptionId ? t(`${subscriptionId}`) : undefined}
      title={title}
      subtitle={subtitle}
      onClick={handleListItemClick}
      disabled={!users}
      style={style}
    />
  );
}
