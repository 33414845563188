//
import React from 'react';
import { Checkbox } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';

type Props = WrappedFieldProps & {
  onChange?: (e: any, v: boolean) => void;
} & Omit<React.ComponentProps<typeof Checkbox>, 'onCheck' | 'checked'>;

export default function CheckboxField({
  input,
  meta,
  onChange,
  ...other
}: Props) {
  return (
    <Checkbox
      {...input}
      {...other}
      // TODO: Fix?
      // errorText={meta.touched && meta.error}
      checked={input.value}
      onCheck={(e) => {
        if (input?.onChange) {
          input.onChange(!input.value);
        }
        if (onChange) {
          onChange(e, !input.value);
        }
      }}
    />
  );
}
