import styled from '@emotion/styled';
import React, { useState, useContext, useCallback } from 'react';
import { Window } from '../../components/window';
import HelpWindowContext from '../../context/HelpWindowContext';
import OverlayContext from '../../context/OverlayContext';
import TextFormatted from '../../text/TextFormatted';
import OverlayController from '../OverlayController';

interface Props {
  children: React.ReactNode;
}

const WindowContent = styled.div`
  padding: 0px 32px 26px;
  flex: 1;
  overflow: auto;
`;

const ControllerInside = ({ children }: Props): JSX.Element => {
  const { open } = useContext(OverlayContext);

  const [helpWindowMeta, setHelpWindowMeta] = useState({ title: '', text: '' });

  const openHelpWindow = useCallback(
    (title: string, text: string): void => {
      setHelpWindowMeta({ title, text });
      open();
    },
    [open]
  );

  return (
    <>
      <Window title={helpWindowMeta.title}>
        <WindowContent>
          {helpWindowMeta.text && <TextFormatted text={helpWindowMeta.text} />}
        </WindowContent>
      </Window>

      <HelpWindowContext.Provider value={openHelpWindow}>
        {children}
      </HelpWindowContext.Provider>
    </>
  );
};

// Wrapper is here to avoid re-rendering on helper metadata state change
//
export default function HelpWindowController({ children }: Props): JSX.Element {
  return (
    <OverlayController>
      <ControllerInside>{children}</ControllerInside>
    </OverlayController>
  );
}
