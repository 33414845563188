import React, { memo } from 'react';
import styled from '@emotion/styled';
import { IconButton } from 'imdshared';
import { NotificationBellIcon } from '@imus/base-ui';
import { NavStatusDot } from 'components/atoms';

const NotificationsButtonContainer = styled.div`
  --accent: var(--error);
  position: relative;
`;

type Props = {
  unreadCount: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const NotificationsButton: React.FC<Props> = ({ unreadCount, onClick }) => (
  <NotificationsButtonContainer>
    <IconButton inline={true} icon={NotificationBellIcon} onClick={onClick}>
      {unreadCount !== 0 && <NavStatusDot />}
    </IconButton>
  </NotificationsButtonContainer>
);

export default memo(NotificationsButton);
