import { ThemeProvider } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import DarkThemeContext from '../../context/DarkThemeContext';
import { themeLight } from '../../theme/config';
import WithGlobalStyles from '../../theme/WithGlobalStyles';

interface Props {
  children: React.ReactNode;
  injectGlobal?: boolean;
}

export default function ThemeController({
  children,
  injectGlobal = true,
}: Props): JSX.Element {
  const [isDarkThemeOn, setDarkThemeOn] = useState(false);
  const value = useMemo(
    () => ({ isDarkThemeOn, setDarkThemeOn }),
    [isDarkThemeOn]
  );

  return (
    <DarkThemeContext.Provider value={value}>
      {injectGlobal && <WithGlobalStyles />}
      <ThemeProvider theme={themeLight}>{children}</ThemeProvider>
    </DarkThemeContext.Provider>
  );
}
