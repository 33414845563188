import { useEntry } from '../hooks';
import { defaultContributorNest, ContributorNested } from './nests';

export function useContributor({
  nest = defaultContributorNest,
  ...props
}: Omit<Parameters<typeof useEntry>[0], 'entity'>) {
  return useEntry<ContributorNested>({
    entity: 'contributors',
    nest,
    ...props,
  });
}
