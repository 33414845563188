//
import { entities } from '../../../../actionTypes';
import uploadFileReducerFactory from '../../../../reducerFactories/uploadFileReducerFactory';

const initialState = {
  entities: {},
  searchHistory: {},
  keys: [],
};

const audioReducer = uploadFileReducerFactory(
  entities.tracks.types.audio,
  'audio'
);

export default function tracks(state = initialState, action) {
  return [audioReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
