//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body, Content } from 'imdshared';

const TotalRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;

  *:first-child {
    flex: 2;
  }

  *:last-child {
    flex: 1;
    text-align: right;
  }
`;

const TotalRow = ({
  label,
  loading = false,
  isAccent = false,
  value,
  className,
}: {
  loading?: boolean;
  isAccent?: boolean;
  className?: string;
  label: string;
  value: string;
}) => {
  const { t } = useTranslation();

  return (
    <TotalRowContainer className={className}>
      <Content style={{ color: isAccent ? 'var(--accent)' : undefined }}>
        {label}
      </Content>

      {!loading ? (
        <Content style={{ color: isAccent ? 'var(--accent)' : undefined }}>
          {value}
        </Content>
      ) : (
        <span>
          <Body>{t('calculating')}</Body>
        </span>
      )}
    </TotalRowContainer>
  );
};

export default TotalRow;
