import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import { useEntry } from 'imddata';
import { Button } from 'imdui';

function downloadURI(uri: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.click();
}

const TracksDownloadButton = ({
  fileId,
  title,
  version,
  style,
  isMastered,
}: {
  fileId: number;
  title?: string;
  version?: string;
  style?: React.CSSProperties;
  isMastered?: boolean;
}) => {
  const { t } = useTranslation();

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
  }, [fileId]);

  const {
    entry: file,
    request: { loading, loaded },
  } = useEntry<EntityModels.File>({
    entity: 'files',
    passive: !clicked,
    id: fileId,
  });

  const requested = file && loaded && clicked;

  useEffect(() => {
    if (requested) {
      const fileName = title
        ? `${title}${version ? ` (${version})` : ''}${
            isMastered ? ` (${t('mastered')})` : ''
          }.${file.extension}`
        : file.fileName;
      downloadURI(file.downloadUrl, fileName);
      setClicked(false);
    }
  }, [requested]);

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setClicked(true);
      }}
      showLoading={loading && clicked}
      position="center"
      size="small"
      style={style}
      text={t('download-track-audio')}
    />
  );
};

export default TracksDownloadButton;
