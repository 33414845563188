import React, { useState } from 'react';
import { Button, SatisfactionSurvey, StarsRating } from 'imdui';
import type { FormSubmitHandler } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { NewInputField } from 'imdshared';
import { useTranslation } from 'react-i18next';

const getTextKeys = ({
  feedbackRequested,
  success,
}: {
  success: boolean;
  feedbackRequested: boolean;
}): { title: string; description: string } => {
  if (success) {
    return {
      title: 'thank-you',
      description: '',
    };
  }
  if (feedbackRequested) {
    return {
      title: 'what-to-improve',
      description: 'tell-us-what-to-improve',
    };
  }

  return {
    title: 'tell-us-about-your-experience',
    description: 'how-happy-are-you-with-imusician',
  };
};

type Values = { feedback: string };
type Props = { onSubmit: FormSubmitHandler<Values> };

const FeedbackForm = reduxForm<Values, Props>({ form: 'feedbackForm' })(({
  handleSubmit,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Field
          name="feedback"
          style={{ margin: 0 }}
          component={NewInputField}
          multiline={true}
        />
      </div>
      <Button
        type="submit"
        size="small"
        position="center"
        onClick={handleSubmit(onSubmit)}
        text={t('submit-feedback')}
      />
    </>
  );
});

export const ProductRating = ({
  product,
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
  product: string;
}) => {
  const [submitted, setSubmitted] = useState(0);
  const [feedback, setFeedback] = useState('');
  const feedbackRequested = !feedback && submitted > 0;
  const success = submitted > 0 && !!feedback;
  const { title, description } = getTextKeys({ feedbackRequested, success });
  const { t } = useTranslation();

  return (
    <>
      <SatisfactionSurvey
        style={style}
        className={className}
        title={t(title, {
          context:
            product === 'md'
              ? 'music-distribution'
              : product === 'ycid'
                ? 'youtubeci'
                : product,
        })}
        description={t(description)}
      >
        {success ? null : feedbackRequested ? (
          <FeedbackForm
            onSubmit={({ feedback }: Values) => {
              setFeedback(feedback);
              if (window.analytics && feedback.length > 0) {
                window.analytics.track('FT CSat Survey Feedback', {
                  starsRating: submitted,
                  product,
                  feedbackText: feedback,
                });
              }
            }}
          />
        ) : (
          <StarsRating
            onSubmit={(starsRating) => {
              setSubmitted(starsRating);
              if (window.analytics) {
                window.analytics.track('FT CSat Survey Rating', {
                  starsRating,
                  product,
                });
              }
            }}
          />
        )}
      </SatisfactionSurvey>
    </>
  );
};
