import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessFieldMeta } from 'imddata/providers';
import { HelpWindowContext } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';
import type { Props } from './LabelSelector';
import LabelSelector from './LabelSelector';

export { LabelRegistrationShopContext } from './LabelSelector';

const LabelSelectorField = ({
  input,
  meta,
  onChange,
  ...other
}: {
  input: WrappedFieldProps['input'];
  meta?: Partial<WrappedFieldProps['meta']>;
} & Props) => {
  const { t } = useTranslation();

  const showHelpWindow = useContext(HelpWindowContext);

  const onClickHelperButton = useCallback(() => {
    showHelpWindow(t('label'), t('release-helptext-label'));
  }, [t, showHelpWindow]);

  const handleChange = useCallback(
    (value) => {
      input.onChange(value);
      if (onChange) {
        onChange(value);
      }
    },
    [input.onChange, onChange]
  );

  return (
    <ProcessFieldMeta hasValue={!!input.value} meta={meta}>
      {({ error, warning }) => (
        <LabelSelector
          {...input}
          {...other}
          testId="labelId"
          label={t('label')}
          onClickHelp={onClickHelperButton}
          errorText={error}
          warningText={warning}
          onChange={handleChange}
        />
      )}
    </ProcessFieldMeta>
  );
};

export default LabelSelectorField;
