// Constants

export enum constants {
  PLAY = '@ui/player/PLAY',
  SEEK_TO = '@ui/player/SEEK_TO',
  PAUSE = '@ui/player/PAUSE',
  STOP = '@ui/player/STOP',
  SET_BUFFERING = '@ui/player/SET_BUFFERING',
  SYNC = '@ui/player/SYNC',
  CLEAR = '@ui/player/CLEAR',
}

// Actions
export const actions = {
  play: (
    trackId: number,
    position: number,
    audioFile: unknown,
    meta: Record<string, string>
  ) => {
    return {
      type: constants.PLAY,
      data: {
        trackId,
        position,
        playing: true,
        ...(audioFile ? { audioFile } : {}),
      },
      meta,
    };
  },

  pause: () => ({ type: constants.PAUSE }),

  stop: () => ({ type: constants.STOP }),

  seekTo: (seekTo: number) => ({ type: constants.SEEK_TO, seekTo }),

  setBuffering: (buffering: boolean) => ({
    type: constants.SET_BUFFERING,
    buffering,
  }),

  sync: (data: { currentPos: number; totalTime: number }) => ({
    type: constants.SYNC,
    progress: data.currentPos,
    totalTime: data.totalTime,
  }),

  clearData: () => ({ type: constants.CLEAR }),
};
