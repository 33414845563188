import React from 'react';
import { useTranslation } from 'react-i18next';
import type { InjectedFormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { useCurrentCustomer, useDeleteEntity } from 'imddata';
import styled from '@emotion/styled';
import {
  Subheading,
  Card,
  LinkButton,
  CheckboxField,
  Button,
  TextFormatted,
} from 'imdshared';

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="186"
    height="162"
    viewBox="0 0 186 162"
  >
    <defs>
      <path id="a" d="M0 .068h185.857V161H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g opacity=".674" transform="translate(0 1)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FFF"
          d="M8.82 49.524c4.155-6.481 11.078-14.218 30.35-24.364C62.55 12.85 82.197 8.527 102.206 4.276c27.902-5.927 37.341-4.496 44.358-2.32 13.342 4.135 21.502 12.068 23.347 13.922 2.46 2.472 10.3 10.83 14.008 24.364 2.943 10.745 1.836 19.478 1.167 24.364-1.47 10.73-5.134 18.257-8.171 24.364-7.336 14.75-16.3 24.096-23.347 31.326-2.572 2.639-12.1 12.229-26.848 22.043-10.6 7.054-19.337 12.869-32.685 16.243-11.252 2.844-19.855 2.457-22.18 2.32-10.713-.63-18.533-3.631-21.011-4.64-2.216-.902-11.642-4.886-21.012-13.923-5.818-5.61-9.007-10.653-14.008-18.563-2.47-3.906-5.955-9.47-9.339-17.403C3.977 100.493 1.753 95.28.648 87.81c-1.314-8.898-.267-15.77 0-17.403C2.405 59.694 7.015 52.34 8.82 49.524"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#F9B174"
        d="M64 35.95L25.38 107.274c-4.09 7.554 1.394 16.726 10 16.726h77.237c8.607 0 14.09-9.172 10-16.726L84 35.95c-4.295-7.933-15.705-7.933-20 0"
      />
      <path
        fill="#303030"
        d="M74.5 31.524c-3.52 0-6.622 1.865-8.295 4.987L27.646 108.46a9.485 9.485 0 0 0 .198 9.381c1.717 2.902 4.744 4.635 8.097 4.635h77.118c3.353 0 6.38-1.733 8.097-4.635a9.484 9.484 0 0 0 .198-9.381l-38.56-71.95c-1.673-3.121-4.774-4.986-8.294-4.986M113.06 126H35.94c-4.596 0-8.745-2.375-11.1-6.354-2.354-3.977-2.455-8.786-.271-12.861l38.56-71.95C65.421 30.556 69.672 28 74.5 28c4.827 0 9.078 2.555 11.372 6.836l38.56 71.949c2.183 4.076 2.081 8.884-.273 12.862-2.354 3.978-6.503 6.353-11.1 6.353"
      />
      <path
        fill="#303030"
        d="M130.929 126H53v-3.525h77.929c3.386 0 6.443-1.732 8.177-4.634a9.396 9.396 0 0 0 .199-9.378L100.339 36.51c-1.654-3.069-4.728-4.932-8.225-4.985h-.15V28h.177c4.823.074 9.041 2.63 11.31 6.838l38.962 71.948c2.208 4.073 2.107 8.88-.272 12.86-2.377 3.978-6.57 6.354-11.212 6.354"
      />
      <path fill="#303030" d="M73 32h20v-4H73zM122 110h20v-5h-20z" />
      <path
        fill="#F58220"
        d="M81 59.25c0 .187-.011.362-.022.537l-1.09 32.13C79.889 94.167 78.145 96 76 96s-3.889-1.832-3.889-4.084l-1.089-32.13c-.01-.174-.022-.35-.022-.536 0-2.905 2.233-5.25 5-5.25s5 2.345 5 5.25"
      />
      <path
        fill="#303030"
        d="M76 55.316c-2.456 0-4.454 1.818-4.454 4.052 0 .152.01.294.02.435l1.252 31.945c0 1.638 1.428 2.936 3.182 2.936 1.755 0 3.182-1.298 3.182-2.895l1.248-31.93c.014-.185.025-.333.025-.49 0-2.235-1.999-4.053-4.455-4.053M76 97c-3.158 0-5.727-2.337-5.727-5.21l-1.247-31.848a7.842 7.842 0 0 1-.026-.574C69 55.857 72.14 53 76 53s7 2.857 7 6.368c0 .214-.013.412-.028.612l-1.245 31.85c0 2.833-2.569 5.17-5.727 5.17"
      />
      <path
        fill="#F58220"
        d="M77.715 109.913a3 3 0 1 1-1.43-5.826 3 3 0 0 1 1.43 5.826"
      />
      <path
        fill="#303030"
        d="M77.004 104.5a2.503 2.503 0 0 0-2.431 3.097 2.502 2.502 0 0 0 3.022 1.831 2.485 2.485 0 0 0 1.542-1.133c.346-.57.449-1.242.29-1.89a2.485 2.485 0 0 0-1.133-1.542 2.484 2.484 0 0 0-1.29-.363M77 112a5.009 5.009 0 0 1-4.855-3.807 5.005 5.005 0 0 1 3.662-6.047 4.966 4.966 0 0 1 3.782.58 4.964 4.964 0 0 1 2.265 3.083 4.964 4.964 0 0 1-.58 3.781 4.964 4.964 0 0 1-3.083 2.265A4.999 4.999 0 0 1 77 112"
      />
      <path
        fill="#F58220"
        d="M19.5 65.206a3.295 3.295 0 1 0 .786 6.493 3.298 3.298 0 0 0 2.414-3.985 3.3 3.3 0 0 0-3.2-2.508M19.492 74c-.992 0-1.969-.27-2.839-.798a5.464 5.464 0 0 1-2.491-3.391 5.46 5.46 0 0 1 .637-4.159 5.46 5.46 0 0 1 3.392-2.49 5.455 5.455 0 0 1 4.16.636 5.462 5.462 0 0 1 2.49 3.391 5.505 5.505 0 0 1-4.028 6.65c-.438.108-.88.161-1.321.161M71.818 19.434l-1.762 1.469c-.197.165-.477.115-.621-.111l-7.35-11.516a.558.558 0 0 1 .097-.71l1.762-1.469c.197-.165.477-.115.621.111l7.35 11.516c.144.226.1.545-.097.71M100.681 19.687a1.593 1.593 0 0 1-.357-2.268l6.653-8.76c.545-.718 1.6-.874 2.342-.346.742.529.903 1.55.357 2.268l-6.653 8.76c-.546.718-1.6.874-2.342.346M85.42 20h-2.84c-.32 0-.58-.217-.58-.481V.482c0-.265.26-.482.58-.482h2.84c.32 0 .58.217.58.482v19.037c0 .264-.26.481-.58.481M65.603 141.327c-.069.105-.137.243-.206.346.069-.103.137-.242.171-.346h.035zM65 140.143c0 .194-.045.366-.124.526h-.01a.56.56 0 0 1-.057.114C61.558 147 57.665 147 56 147c-5.332 0-8.325-5.269-8.876-6.32l.01-.011a1.044 1.044 0 0 1-.134-.526c0-.628.506-1.143 1.125-1.143.427 0 .81.252.99.617l.011-.011c.27.526 2.757 5.108 6.874 5.108 1.361 0 4.207 0 6.84-5.017.011-.023.023-.057.045-.08a1.11 1.11 0 0 1 .99-.617c.619 0 1.125.515 1.125 1.143M159.5 73.206a3.297 3.297 0 0 0-2.817 1.588 3.274 3.274 0 0 0-.382 2.492c.21.854.74 1.576 1.493 2.032.752.455 1.635.59 2.492.381a3.298 3.298 0 0 0 2.414-3.985 3.3 3.3 0 0 0-3.2-2.508M159.492 82c-.992 0-1.969-.27-2.839-.798a5.464 5.464 0 0 1-2.491-3.391 5.46 5.46 0 0 1 .637-4.159 5.461 5.461 0 0 1 3.392-2.49 5.457 5.457 0 0 1 4.16.636 5.46 5.46 0 0 1 2.49 3.391 5.504 5.504 0 0 1-4.028 6.65c-.438.108-.881.161-1.321.161M136.99 48c-1.096 0-1.991-.622-1.99-1.38l.015-9.243c.001-.758.899-1.378 1.995-1.377 1.096.001 1.991.622 1.99 1.38l-.015 9.243c-.001.758-.899 1.378-1.995 1.377"
      />
      <path
        fill="#F58220"
        d="M131 42.99c0-1.096.622-1.991 1.38-1.99l9.243.015c.758.002 1.378.899 1.377 1.995 0 1.096-.622 1.991-1.38 1.99l-9.243-.015c-.758-.001-1.378-.899-1.377-1.995"
      />
    </g>
  </svg>
);

const EmailSentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="210"
    height="176"
    viewBox="0 0 210 176"
  >
    <defs>
      <path id="a" d="M.014.39H181.95v175.584H.014z" />
      <path id="c" d="M0 .392h14.19V3.66H0z" />
      <path
        id="e"
        d="M18.377.777l-9.929 9.728-5.308-5.05a1.804 1.804 0 0 0-1.742-.425A1.784 1.784 0 0 0 .155 6.315a1.77 1.77 0 0 0 .5 1.71l6.573 6.244c.335.316.779.494 1.242.494.472 0 .923-.183 1.258-.512L20.896 3.305a1.767 1.767 0 0 0-.017-2.481 1.796 1.796 0 0 0-1.276-.53c-.44 0-.881.16-1.226.483z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(27.632)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FAFAFA"
          d="M65.228.677c17.388-1.72 32.9 4.483 49.736 12.422 9.282 4.377 15.8 9.045 20.724 12.422 15.15 10.873 32.636 24.416 41.447 49.688 2.454 7.039 7.72 22.808 2.764 41.406-3.775 14.16-11.715 23.352-16.58 28.984-11.258 13.034-23.532 19.505-29.013 22.084-14.024 6.597-27.041 8.146-35.92 8.28-18.604.284-32.17-5.447-38.685-8.28-6.037-2.626-20.783-9.749-34.54-24.844-4.864-5.339-14.858-16.54-20.723-34.505C-.788 92.324-.131 78.923.293 73.828c.51-6.133 2.124-17.552 8.29-30.364 2.62-5.444 7.58-15.5 17.96-24.844C30.72 14.86 44.584 2.719 65.228.677"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#F58220"
        d="M25.314 90.031a1.938 1.938 0 0 1 1.213-2.45l24.636-8.33a1.944 1.944 0 0 1 2.454 1.211 1.938 1.938 0 0 1-1.213 2.451l-24.636 8.33a1.941 1.941 0 0 1-2.454-1.212M75.3 140.315a1.938 1.938 0 0 1 .566-2.675l21.798-14.173a1.943 1.943 0 0 1 2.678.566c.58.891.326 2.095-.567 2.674l-21.798 14.174a1.942 1.942 0 0 1-2.678-.566M23.314 122.233a1.936 1.936 0 0 1 .876-2.589l30.728-15.199a1.94 1.94 0 0 1 2.591.875 1.939 1.939 0 0 1-.874 2.591l-30.73 15.197a1.941 1.941 0 0 1-2.591-.875"
      />
      <path fill="#F9B174" d="M97.277 90.161l34.685 31.924 71.167-80.647z" />
      <path fill="#FFF" d="M96.114 92.08l-14.47 20.826 23.482-12.948z" />
      <path
        fill="#FFF"
        d="M79.348 111.655l14.98-22.58 97.537-44.261L82.992 79.347z"
      />
      <path fill="#F9B174" d="M46.549 57.193l33.794 21.382 116.69-37.48z" />
      <path
        fill="#303030"
        d="M49.21 58.157L193.988 41.8l-14.78 4.696L81.19 77.64 49.21 58.157zM82.46 79.865l104.43-33.18-91.859 42.053c-.19.088-.345.203-.482.329-.037.033-.068.069-.1.104-.036.04-.081.072-.11.114l-15.243 21.864 3.364-31.284zm-.623 32.83l14.356-20.59 7.87 7.193-22.226 13.398zm16.663-22.7l64.972-29.743 37.41-17.127-69.004 77.384-23.997-21.94-.004-.002-.001-.002-9.376-8.57zm-54.576-31.66l35.02 21.332-4.058 37.72c-.004.036.018.065.018.1a.834.834 0 0 0 .06.317c.022.056.056.105.09.156.055.079.115.152.193.222.055.047.11.087.174.128.041.027.069.063.117.087.04.021.092.02.134.038a2 2 0 0 0 .45.124c.093.016.179.034.274.038.045.003.082.02.127.02.202 0 .396-.024.58-.066.218-.052.41-.136.588-.235.02-.012.048-.015.069-.028l28.368-17.1 24.38 22.287c.342.312.897.474 1.484.435.139-.01.274-.03.405-.06.424-.1.8-.304 1.043-.576l74.477-83.52c.014-.019.017-.035.03-.053a.887.887 0 0 0 .065-.087c.03-.043.045-.087.067-.131.003-.009.01-.017.012-.024.014-.029.04-.055.051-.085.01-.027.009-.053.015-.08a.606.606 0 0 0 .023-.12.996.996 0 0 0 .002-.129c-.001-.037.009-.074.002-.112-.004-.025-.018-.048-.025-.073-.004-.02.004-.043-.003-.062-.01-.03-.039-.054-.054-.081-.012-.027-.004-.053-.018-.078-.005-.01-.02-.018-.026-.027-.02-.032-.048-.061-.07-.091-.014-.018-.013-.04-.03-.057-.01-.008-.024-.014-.034-.023-.036-.04-.084-.07-.128-.107-.03-.025-.047-.056-.082-.08-.02-.012-.043-.018-.06-.029-.023-.015-.05-.023-.074-.036l-.008-.004c-.033-.018-.058-.047-.094-.063-.042-.018-.09-.024-.133-.039-.055-.022-.105-.047-.163-.062-.047-.014-.098-.018-.148-.028-.05-.01-.094-.029-.143-.036-.035-.005-.072 0-.108-.004a3.504 3.504 0 0 0-.21-.01c-.065 0-.125.003-.19.008-.037.002-.071-.007-.109-.002L44.997 56.17c-.69.076-1.29.428-1.519.895-.232.468-.056.966.447 1.27z"
      />
      <path
        fill="#F58220"
        d="M7.21 90.582c-.33 0-.663.04-.992.12a4.106 4.106 0 0 0-2.548 1.87 4.088 4.088 0 0 0-.478 3.121 4.098 4.098 0 0 0 1.87 2.547 4.132 4.132 0 0 0 6.15-4.515 4.09 4.09 0 0 0-1.87-2.544 4.098 4.098 0 0 0-2.132-.599M7.19 101.6a6.849 6.849 0 0 1-3.558-1 6.84 6.84 0 0 1-3.124-4.247 6.841 6.841 0 0 1 .798-5.21 6.849 6.849 0 0 1 4.253-3.12 6.85 6.85 0 0 1 5.215.798 6.841 6.841 0 0 1 3.123 4.248c.906 3.687-1.36 7.424-5.05 8.33a6.93 6.93 0 0 1-1.656.201M134.199 149.06a5.575 5.575 0 0 0-5.423 6.898 5.531 5.531 0 0 0 2.525 3.432c1.273.77 2.77 1 4.217.646a5.535 5.535 0 0 0 3.437-2.523c.771-1.271 1-2.767.646-4.212a5.537 5.537 0 0 0-2.525-3.434 5.555 5.555 0 0 0-2.877-.808m-.01 13.899c-3.748 0-7.164-2.548-8.096-6.342-1.096-4.461 1.644-8.98 6.106-10.074a8.28 8.28 0 0 1 6.309.965 8.267 8.267 0 0 1 3.776 5.137 8.27 8.27 0 0 1-.966 6.3 8.278 8.278 0 0 1-5.142 3.772 8.339 8.339 0 0 1-1.987.242M7.087 134.713a1.632 1.632 0 0 1-1.628-1.63l.012-10.92a1.635 1.635 0 0 1 1.632-1.627 1.634 1.634 0 0 1 1.628 1.632l-.013 10.919c0 .895-.735 1.627-1.631 1.626"
      />
      <g transform="translate(0 125.599)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#F58220"
          d="M0 2.018A1.633 1.633 0 0 1 1.632.392l10.93.012a1.635 1.635 0 0 1 1.628 1.63 1.634 1.634 0 0 1-1.633 1.626L1.629 3.65a1.636 1.636 0 0 1-1.63-1.632"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#F58220"
        d="M63.003 153.955c-.63 0-1.144-.516-1.144-1.145l.01-7.667c0-.628.516-1.143 1.145-1.142.63 0 1.144.516 1.144 1.144l-.009 7.667a1.149 1.149 0 0 1-1.146 1.143"
      />
      <path
        fill="#F58220"
        d="M58.026 148.972c0-.63.517-1.143 1.147-1.143l7.675.01c.628 0 1.144.516 1.142 1.144 0 .63-.517 1.143-1.145 1.143l-7.675-.008a1.149 1.149 0 0 1-1.144-1.146"
      />
      <path
        fill="#C1DDA3"
        d="M179.254 96.88c1.357 11.417-6.807 21.773-18.236 23.13-11.43 1.355-21.795-6.802-23.153-18.219-1.357-11.418 6.808-21.774 18.237-23.13 11.43-1.356 21.796 6.8 23.152 18.22"
      />
      <path
        fill="#303030"
        d="M158.599 79.92c-.774 0-1.552.046-2.331.138a19.313 19.313 0 0 0-12.973 7.265 19.273 19.273 0 0 0-4.031 14.3c1.264 10.631 10.949 18.253 21.587 16.99 10.64-1.263 18.268-10.938 17.004-21.567a19.28 19.28 0 0 0-7.271-12.96 19.296 19.296 0 0 0-11.985-4.166m-.055 41.642c-11.106 0-20.735-8.321-22.077-19.606-1.446-12.17 7.287-23.246 19.47-24.691 5.903-.7 11.722.937 16.39 4.61 4.667 3.674 7.625 8.943 8.325 14.838 1.447 12.17-7.287 23.248-19.468 24.693-.887.105-1.769.156-2.64.156"
      />
      <g transform="translate(148 93)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          fill="#303030"
          d="M-2.138 16.984h25.776V-1.928H-2.138z"
          mask="url(#f)"
        />
      </g>
    </g>
  </svg>
);

const WarningIconStyled = styled.div`
  svg {
    width: 180px;
    margin: 16px 0 32px;
  }
`;

const MessageWrapper = styled.div`
  justify-content: center;
  width: 100%;
  margin: 32px;
  align-items: center;
  width: 100%;
  margin: 32px;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
`;
const ActionsWrapper = styled.div`
  margin-top: 32px;
  justify-content: center;
  display: flex;
`;

const CardStyled = styled(Card)`
  padding: 32px;
`;

type FormData = {
  accepted: boolean;
};

function ProfileDeletionWarning({
  handleSubmit,
  pristine,
  submitSucceeded,
  submitting,
  form,
}: InjectedFormProps<FormData>) {
  const { t } = useTranslation();
  const { entry: customer } = useCurrentCustomer();
  const { deleteEntry } = useDeleteEntity({
    entity: 'customers',
    id: customer?.id,
  });

  if (!customer) return null;

  if (submitSucceeded)
    return (
      <MessageWrapper>
        <WarningIconStyled>
          <EmailSentIcon />
        </WarningIconStyled>
        <TextFormatted text={t('profile-deletion-email-sent')} />
      </MessageWrapper>
    );
  return (
    <>
      <MessageWrapper>
        <WarningIconStyled>
          <WarningIcon />
        </WarningIconStyled>
        <Subheading>{t('profile-deletion-hard-warning-heading')}</Subheading>
        <TextWrapper>
          <TextFormatted text={t('profile-deletion-hard-warning')} />
        </TextWrapper>
      </MessageWrapper>
      <CardStyled>
        <Field
          name="accepted"
          component={CheckboxField}
          text={t('profile-deletion-accept-terms')}
        />
        <ActionsWrapper>
          <Button
            onClick={handleSubmit(() =>
              deleteEntry({ id: customer.id, formId: form })
            )}
            style={{ marginRight: '8px' }}
            text={t('delete-my-account')}
            disabled={pristine}
            showLoading={submitting}
          />
          <LinkButton
            primary={true}
            to="/dashboard"
            text={t('keep-my-account')}
          />
        </ActionsWrapper>
      </CardStyled>
    </>
  );
}

export default reduxForm({
  form: 'deleteprofilewarning',
  initialValues: { accepted: false },
  submitAsSideEffect: true,
})(ProfileDeletionWarning);
