//
import React, { useCallback } from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { callAuthApi } from 'imddata';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Block,
  Subheading,
  Caption,
  Button,
  LinkButton,
  NewInputField,
  SVGIcon,
  Icons,
  palette,
} from 'imdshared';
import { validatePassword } from '../../shared';

type Props = Record<string, never>;

type FormData = {
  password: string;
  passwordConfirmation: string;
};

function UpdatePassword({
  error,
  submitting,
  submitSucceeded,
  handleSubmit,
  pristine,
}: InjectedFormProps<FormData, Props>) {
  const { code } = useParams<{ code: string }>();

  const submitForm = useCallback(
    (values: FormData) => {
      return callAuthApi.resetPassword({ ...values, code }).then((response) => {
        if ('error' in response && 'validationError' in response.error) {
          const { validationError } = response.error;
          // @ts-ignore
          throw new SubmissionError(validationError);
        }
      });
    },
    [code]
  );

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Helmet>
        <title>{t('page-title-password-reset')}</title>
      </Helmet>

      <Block padding={[30, 20, 20, 20]}>
        <Block margin={[0, 0, 25, 0]}>
          <Subheading style={{ color: palette.dark.xLight }}>
            {t('update-password')}
          </Subheading>
        </Block>

        <Field
          testId="UpdatePassword-password"
          name="password"
          errorText={error}
          floatingLabelText={t('password')}
          props={{
            placeholder: t('please-enter-your-password'),
            type: 'password',
          }}
          component={NewInputField}
        />

        <Field
          name="passwordConfirmation"
          testId="UpdatePassword-passwordConfirmation"
          errorText={error}
          floatingLabelText={t('confirm-password')}
          props={{
            placeholder: t('please-confirm-your-password'),
            type: 'password',
          }}
          component={NewInputField}
        />

        {submitSucceeded && (
          <Block direction="row" padding={[10, 10, 10, 10]} borderRadius={3}>
            <SVGIcon d={Icons.states.warning} style={{ marginRight: 10 }} />

            <Caption data-test-id="UpdatePassword-reset-message">
              {t('password-has-been-reset')}
            </Caption>

            <Block.Stroke color={palette.light.xDark} thickness={1} />
          </Block>
        )}

        {!submitSucceeded ? (
          <Button
            testId="UpdatePassword-submit"
            type="submit"
            style={{ width: '100%' }}
            primary={true}
            disabled={pristine}
            showLoading={submitting}
            text={t('update-password')}
          />
        ) : (
          <LinkButton
            to="/signin"
            style={{ width: '100%' }}
            text={t('signin')}
          />
        )}
      </Block>
    </form>
  );
}

const validate = (values: FormData) => validatePassword(values);

export default reduxForm({
  form: 'updatePassword',
  validate,
})(UpdatePassword);
