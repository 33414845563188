//
import { fork, actionChannel, take, put, call } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { types } from '../../actionTypes/tracks';
import { entities } from '../../actionTypes';
import createEmptyTrackAsync from './createEmptyTrack';

function* createAndUploadTrack({
  payload: { audio, nestingPath, data, tempId },
  duration,
  valid,
  meta,
}) {
  try {
    const result = yield call(createEmptyTrackAsync, {
      payload: {
        tempId,
        nestingPath,
        data,
      },
      meta,
    });
    if (result) {
      if (duration) {
        yield put(
          entities.tracks.actions.updateLocalEntry({
            id: result,
            data: {
              localFilePreview: audio,
              localAudioDuration: duration,
            },
          })
        );
      }
      if (valid) {
        yield put(
          entities.tracks.actions.audio.upload.request({
            payload: {
              id: [result],
            },
          })
        );
        yield put({
          type: types.ADD_TO_UPLOAD_QUEUE,
          payload: {
            audio,
            id: result,
          },
          meta,
        });
      }
    }
  } catch (error) {
    yield put(
      entities.tracks.actions.create.failure({
        payload: {
          tempId,
          data,
          nestingPath,
        },
      })
    );
  }
}

export default function* handleCreateAndUploadTrack() {
  const buffer = buffers.expanding();
  const channel = yield actionChannel('@tracks_saga/CREATE_TRACK', buffer);

  while (true) {
    const {
      payload: { audio, nestingPath, data, tempId },
      payload,
      meta,
    } = yield take(channel);
    try {
      if (!audio) {
        yield call(createEmptyTrackAsync, {
          payload: {
            tempId,
            nestingPath,
            data,
          },
          meta,
        });
      } else {
        yield fork(createAndUploadTrack, {
          valid: true,
          payload,
          meta,
        });
      }
    } catch (error) {
      yield put(
        entities.tracks.actions.create.failure({
          payload: {
            tempId,
            data,
            nestingPath,
          },
        })
      );
    }
  }
}
