import * as R from 'ramda';
import { genericApiActions, stubTypes } from './constants';

export interface AsyncActionTypes {
  SUCCESS: string;
  FAILURE: string;
  REQUEST: string;
  ABORT?: string;
}

export interface FactoryEntityType {
  reorder: string;
  updateLocalEntry: string;
  fetch: AsyncActionTypes;
  update: AsyncActionTypes;
  create: AsyncActionTypes;
  delete: AsyncActionTypes;
}

export const createAsyncType = (
  prefix: string,
  actionType: string
): AsyncActionTypes => ({
  SUCCESS: `@${prefix}/${actionType}_SUCCESS`,
  FAILURE: `@${prefix}/${actionType}_FAILURE`,
  REQUEST: `@${prefix}/${actionType}_REQUEST`,
});

/**
 * Async types generation for entitie's CRUD actions
 *
 * @returns {FactoryEntityType}
 */
export default function asyncCrudActionTypeFactory(
  prefix: string
): FactoryEntityType {
  return {
    reorder: `@${prefix}/REORDER`,
    updateLocalEntry: `@${prefix}/UPDATE_LOCAL_ENTRY`,
    ...genericApiActions.reduce(
      (accTypes, type) => {
        const actionType = type.toUpperCase();
        const actions = createAsyncType(prefix, actionType);
        return R.set(R.lensProp(type), actions, accTypes);
      },
      {
        fetch: stubTypes,
        update: stubTypes,
        delete: stubTypes,
        create: stubTypes,
      }
    ),
  };
}

export function factoryEntityTypes(prefix: string): FactoryEntityType {
  return asyncCrudActionTypeFactory(prefix);
}
