//
import uploadFileReducerFactory from '../../../../reducerFactories/uploadFileReducerFactory';
import { entities } from '../../../../actionTypes';

const initialState = {
  searchHistory: {},
  entities: {},
  keys: [],
};

const fileReducer = uploadFileReducerFactory(
  entities.salesImports.types.file,
  'file'
);

export default function salesImports(state = initialState, action) {
  return [fileReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
