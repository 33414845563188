import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { BadCaseTemplate } from 'components';
import { useCurrentUser, useCurrentCustomer } from 'imddata';
import {
  DevComponent,
  Body,
  Icons,
  Window,
  Button,
  OverlayController,
  OverlayContext,
  AppOutdatedContext,
} from 'imdshared';

const ErrorWrapper = styled.div`
  max-width: 640px;
  padding: 16px;
  ${Body} {
    line-height: 24px;
  }
`;

const StackButton = styled(Button)`
  position: fixed;
  width: 128px;
  bottom: 24px;
  right: 24px;
  justify-content: center;
  background-color: transparent;
`;

type Props = {
  error: {
    eventId: string;
    error: {
      message: string;
      stack: string;
    };
  };
};

const AppCrash: React.FC<Props> = ({ error: { error, eventId } }) => {
  const { t } = useTranslation();

  const { entry } = useCurrentUser();
  const { entry: customer } = useCurrentCustomer();

  const user =
    entry && customer
      ? {
          email: entry.email,
          name: customer.label,
          userId: entry.id,
          customerId: customer.id,
        }
      : {};

  const { forceUpdate } = useContext(AppOutdatedContext);

  // Trigger ServiceWorker outdated check on AppCrash
  useEffect(() => {
    forceUpdate();
  }, []);

  return (
    <BadCaseTemplate
      title={t('app-crash-title')}
      description={t('app-crash-description')}
      imageSrc="/images/badcase/crash.svg"
    >
      <DevComponent>
        <OverlayController>
          <OverlayContext.Consumer>
            {({ open }): JSX.Element => (
              <StackButton text="Error stack" onClick={open} />
            )}
          </OverlayContext.Consumer>

          <Window title={error.message}>
            <ErrorWrapper>
              <Body>{error.stack}</Body>
            </ErrorWrapper>
          </Window>
        </OverlayController>
      </DevComponent>

      <Button
        text={t('report-error')}
        icon={Icons.actions.report}
        onClick={(): void => {
          if (window.Sentry) {
            window.Sentry.showReportDialog({
              user,
              eventId,
            });
          }
        }}
      />
    </BadCaseTemplate>
  );
};

export default AppCrash;
