import type { TextProps } from '@visx/text/lib/Text';

export const createAxisLabelProps = (index: number): Partial<TextProps> => {
  return {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    fill: (index + 1) % 2 ? '#333' : '#777',
  };
};

export const lineColors = [
  '#F9A825',
  '#BA68C8',
  '#2E7D32',
  '#42A5F5',
  '#A1887F',
  '#E84141',
  '#A7B7BF',
  '#FF80AB',
];
