import React from 'react';
import { Text } from '@imus/services-ui';
import { rowStyle } from './styles';

type Props = {
  children: React.ReactNode;
};

const SectionHeader: React.FC<Props> = ({ children }) => {
  return (
    <div css={rowStyle}>
      <Text.H2>{children}</Text.H2>
    </div>
  );
};

export default SectionHeader;
