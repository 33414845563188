import { action } from '../../base';

export enum types {
  TOGGLE_DEVMODE = '@dev/TOGGLE_DEVMODE',
  UPDATE_FEATURES = '@dev/UPDATE_FEATURES',
  SET_VARS = '@dev/SET_VARS',
}

export const actions = {
  updateFeatures: (features: Record<string, boolean>) =>
    action(types.UPDATE_FEATURES, { features }),
  setVars: (vars: Record<string, boolean | string>) =>
    action(types.SET_VARS, { vars }),
  toggleDevmode: (value: string | boolean) =>
    action(types.TOGGLE_DEVMODE, { devmode: value }),
};
