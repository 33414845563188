import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'imddata';
import { PublisherSideEditor, QueryBuilderInput } from 'components';
import { EntityPage } from '../../shared';

const options: any[] = [];

const PublishersBrowserFilter: React.FC = ({ value, onChange }: any) => {
  const { query, updateQuery } = useQuery();

  return (
    <QueryBuilderInput
      value={value}
      loading={false}
      options={options}
      onChange={onChange}
      query={query}
      onInputChange={updateQuery}
    />
  );
};

const Publishers: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page-title-publishers')}</title>
      </Helmet>

      <EntityPage
        entity="publishers"
        renderQueryBuilder={PublishersBrowserFilter}
      />

      <PublisherSideEditor />
    </>
  );
};

export default Publishers;
