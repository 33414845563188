import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import cx from 'classnames';
import React, { SyntheticEvent, useCallback } from 'react';
import { Content } from '../../../text';

export type TabItemProps = {
  id?: string;
  text?: string;
  children?: React.ReactNode;
  testId?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent<any>) => void;
  className?: string;
  style?: React.CSSProperties;
  invalid?: boolean;
};

const Underline = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transition: all 0.1s ease-in-out;
`;

const TabItemContainer = styled.div<{
  invalid?: boolean;
  selected: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;

  :not(:last-child) {
    margin-right: 24px;
  }

  > ${Underline} {
    background-color: ${({ theme, invalid, selected }) =>
      invalid ? theme.state.error : selected ? theme.accent.green : 'none'};
    transform: scale(${({ selected }) => (selected ? 1 : 0.85)});
  }

  :focus {
    > ${Underline} {
      background-color: ${({ theme, selected }) => !selected && theme.darks[1]};
      transform: scale(1);
    }
  }
`;

export const TabItem: React.FC<TabItemProps> = ({
  invalid,
  text,
  disabled = false,
  children,
  selected = false,
  onClick,
  testId,
  className,
  style,
}) => {
  const theme = useTheme();

  const fgColor = selected
    ? theme.foreground.primary
    : theme.foreground.secondary;

  const preventDefault = useCallback(
    (e: React.MouseEvent<HTMLDivElement>): void => {
      e.preventDefault();
    },
    []
  );

  return (
    <TabItemContainer
      invalid={invalid}
      role="button"
      tabIndex={0}
      selected={selected}
      data-test-id={testId}
      onClick={onClick}
      onKeyPress={onClick}
      onMouseDown={preventDefault}
      className={cx(className, disabled && 'disabled')}
      style={style}
    >
      <Underline />

      {text && <Content style={{ color: fgColor }}>{text}</Content>}

      {children}
    </TabItemContainer>
  );
};
