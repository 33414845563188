import React, { useCallback } from 'react';
import { SketchPicker } from 'react-color';
import styled from '@emotion/styled';
import { Button, Card, ListItem, ComponentIcons } from 'imdui';
import { useTranslation } from 'react-i18next';
import DropdownInteractive from '../../../DropdownInteractive';

const fontFamilies = [
  'Nunito Sans',
  'Oswald',
  'Helvetica',
  'Proza Libre',
  'Trirong',
  'Abhaya Libre',
  'Montserrat',
];

type Props = {
  textColor: string;
  textFontFamily: string;
  onFontFamilyChange: (a: string) => void;
  onColorChange: (v: string) => void;
};

const Check = styled.div`
  display: flex;
  padding: 8px 0;
`;

const pickerStyles = {
  picker: {
    width: '240px',
    padding: '16px 10px 0px',
    boxSizing: 'initial',
    background: 'rgb(255, 255, 255)',
    borderRadius: '0px',
    boxShadow: 'rgb(238, 238, 238) 0px 0px 0px 1px',
  },
};

const EditorMenu: React.FC<Props> = ({
  textFontFamily,
  textColor,
  onFontFamilyChange,
  onColorChange,
}) => {
  const renderFontFaceButton = useCallback(
    ({ /* value, label, displayValue, */ dropdownOpened, onClick, label }) => {
      return (
        <Button
          text={label}
          onClick={onClick}
          iconProps={{ style: { marginLeft: 'auto' } }}
          icon={dropdownOpened ? ComponentIcons.Close : ComponentIcons.FontFace}
        />
      );
    },
    []
  );
  const renderColorButton = useCallback(
    ({ /* value, label, displayValue, */ dropdownOpened, onClick, label }) => {
      return (
        <Button
          text={label}
          onClick={onClick}
          iconProps={{ style: { marginLeft: 'auto' } }}
          icon={
            dropdownOpened ? ComponentIcons.Close : ComponentIcons.ColorBlock
          }
        />
      );
    },
    []
  );

  const renderCheck = useCallback(
    ({ selected }) =>
      selected ? (
        <Check>
          <ComponentIcons.Check fill="#333" />
        </Check>
      ) : null,
    []
  );

  const { t } = useTranslation();

  return (
    <>
      {/* 
    @ts-ignore */}
      <DropdownInteractive
        render={renderFontFaceButton}
        label={t('change-font-face')}
        renderDropdown={({ style }) => (
          <Card style={{ padding: '0', ...style }}>
            {fontFamilies.map((f) => (
              <ListItem
                key={f}
                label={f}
                renderRightChildren={renderCheck}
                selected={f === textFontFamily}
                onClick={() => {
                  onFontFamilyChange(f);
                }}
              />
            ))}
          </Card>
        )}
      />
      {/* 
    @ts-ignore */}
      <DropdownInteractive
        render={renderColorButton}
        label={t('change-text-color')}
        renderDropdown={() => (
          <Card style={{ padding: '0' }}>
            <SketchPicker
              // @ts-ignore
              styles={pickerStyles}
              width="240px"
              disableAlpha={true}
              color={textColor}
              onChange={({ hex }) => {
                if (onColorChange) {
                  onColorChange(hex);
                }
              }}
            />
          </Card>
        )}
      />
    </>
  );
};

// <EditorWrapper>
//   <div style={{ paddingTop: '8px', flex: '200px' }}>
//   </div>

export default EditorMenu;
