import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Button, Content, Caption, Card } from 'imdshared';

const Wrapper = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 0 0 16px;
  margin-bottom: 24px;
`;

const Actions = styled.div`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin: 0 16px 16px 0;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ProgressWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  display: flex;
  min-width: 228px;
  margin: 0 16px 16px 0;
`;

const Bar = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.04);
  margin-right: 16px;
`;

const ProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 4px;
  border-radius: 4px;
  background: #5fa910;
`;

const textWrapper = css`
  flex: 1;
  margin: 0 16px 16px 0;
  min-width: 66%;

  > * {
    display: block;
  }
`;

const captionStyle = css`
  margin-top: 8px;
`;

const percentageStyle = css`
  width: 32px;
  text-align: right;
`;

const Progress = ({ progress }: { progress: number }) => (
  <ProgressWrapper>
    <Bar>
      <ProgressBar progress={progress} />
    </Bar>
    <Content css={percentageStyle}>{progress}%</Content>
  </ProgressWrapper>
);

export type Props = {
  style?: any;
  className?: string;
  title: string;
  description?: string;
  progress: number;
  hideProgress?: boolean;
  hideActions?: boolean;
  cancelText?: string;
  continueText?: string;
  onCancel?: () => void;
  onContinue?: () => void;
};

export default function ProductProgressBar({
  style,
  className,
  title,
  description,
  progress,
  hideProgress,
  hideActions,
  cancelText,
  continueText,
  onCancel,
  onContinue,
}: Props) {
  const { t } = useTranslation();

  return (
    <Wrapper style={style} className={className}>
      <div css={textWrapper}>
        <Content>{title}</Content>
        {description && <Caption css={captionStyle}>{description}</Caption>}
      </div>

      {progress !== 100 ? (
        !hideProgress ? (
          <Progress progress={progress} />
        ) : null
      ) : (
        <Actions>
          {onCancel && !hideActions && (
            <Button text={cancelText || t('cancel')} onClick={onCancel} />
          )}
          {onContinue && !hideActions && (
            <Button
              primary={true}
              text={continueText || t('continue')}
              onClick={onContinue}
            />
          )}
        </Actions>
      )}
    </Wrapper>
  );
}
