import type { WrappedFieldProps } from 'redux-form';
import { ListButton } from './ListButton';
import { Content, ToggleSwitch } from 'imdui';

export const EnabledRowField = ({
  input,
  title,
}: WrappedFieldProps & { title: string }) => (
  <ListButton onClick={() => input.onChange(!input.value)}>
    <Content>{title}</Content>
    <ToggleSwitch
      checked={input.value}
      style={{ marginLeft: 'auto', flexGrow: 0 }}
    />
  </ListButton>
);
