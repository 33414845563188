import {
  Button,
  FieldCardAction,
  H4,
  HelpWindowContext,
  OverlineText,
} from 'imdui';
import { BodyM, BodyS, ContentM } from '@imus/services-ui/src/Text';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Headline,
  SideWindow,
  IconButton,
  Icons,
  TracksListRenderer,
} from 'imdshared';
import styled from '@emotion/styled';
import type { ReleaseNested } from 'imddata';
import { useTrack } from 'imddata';
import { flatten } from 'ramda';
import { SideEditorSaveCancelButtons } from 'components/organisms/DeliveryForm/SideEditorFields';

const SideWindowStyled = styled(SideWindow)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fafafa;
  z-index: 1000;
`;

const Header = styled.div`
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  align-items: center;
  border-bottom: 1px solid var(--fg-4, rgba(0, 0, 0, 0.1));
`;

const TrackList = styled.div`
  border-radius: 8px;

  border: 1px solid var(--fg-4);
  background: var(--bg-3, #f4f4f4);
  padding: 8px 0;
  margin: 0 32px 0;
  display: flex;
  flex: 1;
  height: 100%;
  max-height: calc(100% - 200px);
  overflow-y: auto;
  flex-direction: column;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 32px;
`;

type Props = {
  onSelect: (track: number | string) => void;
  value?: number | string;
  isOpen: boolean;
  onClose: () => void;
  volumes: ReleaseNested['volumes'];
};

function TracksBrowserDialog(props: Props) {
  const { t } = useTranslation();
  const flatVolumes = flatten(props.volumes).map((rt) => rt.track);
  const [selected, setSelected] = useState<(string | number)[]>(() =>
    props.value ? [props.value] : []
  );
  return (
    <SideWindowStyled isOpen={props.isOpen} isLarge={true}>
      <Header>
        <Headline>{t('playlist-pitching')}</Headline>

        <IconButton icon={Icons.actions.close} onClick={props.onClose} />
      </Header>
      <Info>
        <div>
          <H4>{t('select-focus-track')}</H4>
        </div>
        <BodyS>{t('select-focus-track-text')}</BodyS>
      </Info>

      <TrackList>
        <TracksListRenderer
          rowRenderer={() => null}
          hasNextPage={false}
          loadNextPage={() => null}
          isValid={() => true}
          hideReleaseName={true}
          virtualized={false}
          isDisabled={(t) => t.deliveryStatus !== 'not_delivered'}
          selectedTracks={selected}
          onChangeSelection={(s) => {
            if (s.length) {
              setSelected([s[s.length - 1]]);
            } else {
              setSelected([]);
            }
          }}
          data={flatVolumes}
        />
      </TrackList>
      <div style={{ margin: 'auto 32px 32px 32px' }}>
        <SideEditorSaveCancelButtons
          onCancel={props.onClose}
          onSave={() => {
            props.onSelect(selected[0]);
          }}
        />
      </div>
    </SideWindowStyled>
  );
}

export const PitchingTrackSelector = ({
  value: initialValue,
  disabled,
  volumes,
  onSelect,
}: {
  value?: number | string;
  onSelect: (track: number | string) => void;
  disabled?: boolean;
  volumes?: ReleaseNested['volumes'];
}) => {
  const [value, setValue] = useState(initialValue);
  const [opened, setOpened] = useState(false);
  const { entry: track } = useTrack({ id: value!, passive: !value });
  const showHelpWindow = useContext(HelpWindowContext);
  const { t } = useTranslation();

  return (
    <div>
      <OverlineText label={t('playlist-pitching-focus-track')} />

      <FieldCardAction
        onClickHelp={() => {
          showHelpWindow(
            t('playlist-pitching-focus-track'),
            t('playlist-pitching-helpertext')
          );
        }}
        action={
          <Button
            style={{ marginLeft: 'auto' }}
            disabled={disabled}
            text={t('select')}
            testId="CountryFieldCard-change"
            onClick={() => {
              setOpened(!opened);
            }}
          />
        }
      >
        {track && value ? (
          <ContentM>
            {track.defaultName.title} &ndash; {track.defaultName.displayArtist}
          </ContentM>
        ) : (
          <BodyM secondary={true}>{t('not-selected')}</BodyM>
        )}
      </FieldCardAction>
      {volumes && (
        <TracksBrowserDialog
          value={value}
          onSelect={(v) => {
            setValue(v);
            onSelect(v);
            setOpened(false);
          }}
          volumes={volumes}
          isOpen={opened}
          onClose={() => {
            setOpened(false);
          }}
        />
      )}
    </div>
  );
};
