const main = {
  xLight: '#c1dda3',
  light: '#a2cc75',
  normal: '#84bc48',
  dark: '#628c35',
  xDark: '#415d23',
};

const accent = '#f58220';

const light = {
  xLight: '#ffffff',
  light: '#f2f2f2',
  normal: '#e5e5e5',
  dark: '#d8d8d8',
  xDark: '#cccccc',
};

const dark = {
  xLight: '#666666',
  light: '#595959',
  normal: '#4c4c4c',
  dark: '#3f3f3f',
  xDark: '#333333',
};

const warning = '#f4a800';
const error = 'red';

const focus = '#333333';
const disabled = 'rgba(0, 0, 0, 0.26)';

export const isAccent = (value) => (value ? accent : main.dark);

export default {
  utils: {
    isAccent,
  },
  main,
  accent,
  light,
  dark,
  focus,
  disabled,
  warning,
  error,
};
