//
import { createContext } from 'react';

type Context = {
  forceUpdate: () => void;
  isAppOutdated: boolean;
  setIsAppOutdated: (v: boolean) => void;
};

const AppOutdatedContext = createContext<Context>({
  forceUpdate: () => null,
  isAppOutdated: false,
  setIsAppOutdated: () => null,
});

export default AppOutdatedContext;
