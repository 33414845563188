import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TextFormatted } from 'imdui';
import { Content, Body } from 'imdshared';
import { BlockTemplate } from 'components';

const Wrapper = styled.div`
  padding-bottom: 8px;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;

  :nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  > * {
    flex: 1;
    flex-shrink: none;
  }

  > span:not(:last-child) {
    margin-right: 16px;
  }
`;

type ValueRowProps = {
  label: string;
  value: string;
};

const ValueRow = ({ label, value }: ValueRowProps) => (
  <RowWrapper>
    <Body>{label}</Body>

    <Content>{value}</Content>
  </RowWrapper>
);

export const ReferralRewards = ({
  referralReward = '',
}: // referralSignupReward = '',
{
  referralSignupReward?: string;
  referralReward?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <BlockTemplate title={t(`referral-rewards-title`)} padded={false}>
        <TextFormatted
          css={BlockTemplate.paddingCss}
          text={t(`referral-rewards-description`)}
        />
        <Wrapper>
          <ValueRow label={t(`referrer-reward`)} value={referralReward} />
          {/* 
          <ValueRow
            label={t(`referred-signup-reward`)}
            value={referralSignupReward}
          /> */}
        </Wrapper>
      </BlockTemplate>
    </div>
  );
};

export const Splits: React.FC<{
  bonus: number;
  imdShare: number;
  loyalty: number;
  translationPrefix: string;
}> = ({ loyalty, bonus, translationPrefix, imdShare }) => {
  const { t } = useTranslation();

  return (
    <div>
      <BlockTemplate
        padded={false}
        title={t(`${translationPrefix}-splits-title`)}
      >
        <TextFormatted
          css={BlockTemplate.paddingCss}
          text={t(`${translationPrefix}-splits-description`, {
            loyalty: `${loyalty}%`,
            bonus: `${bonus}%`,
            imusician: `${imdShare}%`,
          })}
        />
        <Wrapper>
          <ValueRow
            label={t(`${translationPrefix}-split-loyalty`)}
            value={`${loyalty}%`}
          />
          <ValueRow
            label={t(`${translationPrefix}-split-bonus`)}
            value={`${bonus}%`}
          />
          <ValueRow
            label={t(`${translationPrefix}-split-imusician`)}
            value={`${imdShare}%`}
          />
        </Wrapper>
      </BlockTemplate>
    </div>
  );
};
