import styled from '@emotion/styled';
import { Card } from 'imdui';

export const StyledCard = styled(Card)`
  margin: 24px 0;
`;

export const ColumnsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 24px 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > section {
      flex: 1;
    }

    > section:first-child {
      margin-right: 24px;
    }
  }
`;
