//
import { set, view, lensPath } from 'ramda';
import { entities } from '../../../../actionTypes';

const setGeneratedPassword = (state, action) => {
  switch (action.type) {
    case entities.admins.types.create.SUCCESS: {
      const generatedPassword = view(
        lensPath(['rawResponse', 'generatedPassword']),
        action
      );
      return set(
        lensPath(['entities', action.response.result, 'generatedPassword']),
        generatedPassword,
        state
      );
    }
    default:
      return state;
  }
};

export default function adminsReducer(state, action) {
  return [setGeneratedPassword].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
