import React, { Component } from 'react';
import { useQuery, useTracks } from 'imddata';
import { TracksListRenderer } from 'imdshared';

function GroupedTracksList({ query: passedQuery, ...props }) {
  const { query, queryHash } = useQuery({ query: passedQuery });
  const {
    loadMore,
    groups,
    entries,
    request: { loaded, loading, hasMorePages },
  } = useTracks({ query, queryHash });

  return (
    <TracksListRenderer
      showIsrc={true}
      loading={loading}
      loaded={loaded}
      data={groups.length ? groups : entries}
      hasNextPage={hasMorePages}
      loadNextPage={loadMore}
      key="list"
      {...props}
    />
  );
}

export default class GlobalTracksList extends Component {
  state = {};

  static getDerivedStateFromProps(
    { searchQuery, filters, groupBy },
    prevState
  ) {
    if (searchQuery !== prevState.savedQuery || groupBy !== prevState.groupBy) {
      return {
        groupBy,
        savedQuery: searchQuery,
        searchQuery: {
          by: filters && filters.length ? undefined : groupBy,
          ...searchQuery,
        },
      };
    }
    return null;
  }

  render() {
    const { onChangeSelection, disabledTracks, isDisabled, selectedTracks } =
      this.props;

    return (
      <GroupedTracksList
        onChangeSelection={onChangeSelection}
        selectedTracks={selectedTracks}
        disabledTracks={disabledTracks}
        isDisabled={isDisabled}
        scrollElement={this.props.scrollElement}
        query={this.state.searchQuery}
      />
    );
  }
}
