import styled from '@emotion/styled';
import { Content } from '../../../text';
import OverlineText from '../../field/OverlineText';

export const StyledOverlineText = styled(OverlineText)``;

export const InputRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  pointer-events: none;

  & button {
    pointer-events: all;
  }
`;

export const LanguageIdIndicator = styled(Content)`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: 6px;
  top: 6px;
  width: 28px;
  height: 28px;
  border-radius: 3px;
  pointer-events: none;
  background-color: #eee;
`;
