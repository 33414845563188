//
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ReleasesNested } from 'imddata';
import { useEntitySales } from 'imddata';
import { getReleaseBarcode, Caption, Content } from 'imdshared';
import { ReportingListItem } from '../../shared';

const labelRenderer = ({ cellData }: { cellData: string }) => (
  <Caption>{cellData}</Caption>
);
const revenueRenderer = ({ cellData }: { cellData: string }) => (
  <Content>{cellData}</Content>
);

const ReleaseSalesRow = ({
  release,
  queryHash,
}: {
  release: ReleasesNested;
  queryHash?: string;
}) => {
  const { t } = useTranslation();

  const fields = useMemo(
    () => [
      {
        dataKey: 'label',
        label: '',
        cellRenderer: labelRenderer,
      },
      {
        dataKey: 'trackStreams',
        textAlign: 'right',
        label: t('streams'),
      },
      {
        dataKey: 'trackDownloads',
        textAlign: 'right',
        label: t('downloads-tracks'),
      },
      {
        dataKey: 'releaseDownloads',
        textAlign: 'right',
        label: t('downloads-releases'),
      },
      {
        dataKey: 'monetizations',
        textAlign: 'right',
        label: t('youtube-content-id'),
      },
      {
        dataKey: 'totalRevenue',
        textAlign: 'right',
        label: t('total'),
        cellRenderer: revenueRenderer,
      },
    ],
    [t]
  );

  const formatter = new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
  });

  const itemProps = useMemo(() => {
    if (!release)
      return {
        releaseId: undefined,
        title: '',
        rightText: '',
      };
    return {
      releaseId: release.id,
      title: release.title,
      rightText: getReleaseBarcode({
        customBarcode: release.customBarcode,
        barcodes: release.barcodes,
      }),
    };
  }, [release]);

  const sales = useEntitySales({
    entity: 'releases',
    id: release.id,
    queryHash,
  });
  const items = useMemo(() => {
    if (!sales?.summary) return [];
    const {
      trackStreams,
      trackDownloads,
      releaseDownloads,
      views,
      trackStreamsRevenue,
      trackDownloadsRevenue,
      releaseDownloadsRevenue,
      ringtoneDownloads,
      ringtoneDownloadsRevenue,
      monetizations,
      monetizationsRevenue,
      viewsRevenue,
      totalRevenue,
      totalRevenueCurrency,
    } = sales.summary;

    return [
      {
        id: 'quantity',
        label: t('quantity'),
        trackStreams: trackStreams + views,
        trackDownloads: trackDownloads + ringtoneDownloads,
        releaseDownloads,
        monetizations,
      },
      {
        id: 'revenue',
        label: `${t('revenue')} (${totalRevenueCurrency})`,
        trackStreams: formatter.format(
          Number(trackStreamsRevenue) + Number(viewsRevenue)
        ),
        trackDownloads: formatter.format(
          Number(trackDownloadsRevenue) + Number(ringtoneDownloadsRevenue)
        ),
        releaseDownloads: releaseDownloadsRevenue,
        monetizations: monetizationsRevenue,
        totalRevenue,
      },
    ];
  }, [sales]);
  const tableProps = useMemo(() => ({ fields, items }), [fields, items]);

  return <ReportingListItem itemProps={itemProps} tableProps={tableProps} />;
};

export default ReleaseSalesRow;
