import { EntityModels } from '../../../../types';
import { NestDefinition } from '../../base';

export type ContributorNested = EntityModels.Nest<
  EntityModels.Contributor,
  {
    namesNormalized: EntityModels.ContributorName[];
    defaultNameNormalized: EntityModels.ContributorName;
  }
>;

export const defaultContributorNest: NestDefinition = [
  {
    key: 'defaultNameNormalized',
    entity: 'contributorNames',
  },
  {
    key: 'namesNormalized',
    entity: 'contributorNames',
    nest: [
      {
        key: 'revisions',
        entity: 'contributorNames',
      },
    ],
  },
];
