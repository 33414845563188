import styled from '@emotion/styled';
import { IconButton } from 'imdshared';
import Slider from './Slider';

export const Wrapper = styled.div`
  display: flex;
  width: 280px;
  align-items: center;
`;

export const StyledSlider = styled(Slider)`
  margin: 0 8px;
`;

export const StyledIconButton = styled(IconButton)`
  width: 32px;
  height: 32px;
  background-color: #e1e1e1;
`;
