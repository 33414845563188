// @ts-nocheck
import React, { useMemo } from 'react';
import { useDeliveryRegions } from 'imddata';
import { groupBy } from 'ramda';
import type { ChangesProps } from '../../types';
import ChipsGroup from './ChipsGroup';
import RegionGroup from './Region';

const Countries: React.FC<ChangesProps> = ({ changes }) => {
  const {
    entries,
    request: { loading },
  } = useDeliveryRegions();

  const changesPerRegion = useMemo(
    () =>
      groupBy((change) => {
        const deliveryRegion = entries.find(
          (region) =>
            region.countries.findIndex(
              (c: any) => c.countryId === change.details.countryId
            ) >= 0
        );
        return deliveryRegion.name;
      }, changes),
    [changes]
  );

  const regionsAZ = useMemo(
    () => Object.keys(changesPerRegion).sort(),
    [changes]
  );

  if (loading) {
    return null;
  }

  return (
    <ChipsGroup groupKey="countries">
      {regionsAZ.map((r) => (
        <RegionGroup key={r} title={r} changes={changesPerRegion[r]} />
      ))}
    </ChipsGroup>
  );
};

export default Countries;
