import { getSubscriptionCadence } from 'components';
import { useCurrentCustomer, useCurrentCustomerSubscription } from 'imddata';
import { useFeature } from 'imdfeature';
import { useSubscriptionUpsell } from 'imdshared';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

export const useSubsPromo = (): [boolean, MouseEventHandler] => {
  const { entry: customer } = useCurrentCustomer();
  const subscription = useCurrentCustomerSubscription();
  const { open } = useSubscriptionUpsell();
  const [enabledPromo] = useFeature({ featureKey: 'promo-subs' });

  const cadence = subscription
    ? getSubscriptionCadence(subscription.subscriptionId)
    : null;

  const futureCadence = subscription?.renewToId
    ? getSubscriptionCadence(subscription.renewToId)
    : null;

  const shouldHide =
    !customer ||
    !enabledPromo ||
    !subscription ||
    cadence === 'yearly' ||
    futureCadence === 'yearly';

  const handleClick = useCallback<MouseEventHandler>(() => {
    open({ analytics: { detail: 'promo-banner' } });
  }, []);

  return [!shouldHide, handleClick];
};
