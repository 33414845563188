//
import * as R from 'ramda';
import { entities } from '../../../../actionTypes';

const initialState = {
  entities: {},
  searchHistory: {},
  keys: [],
};

function genreReducer(state, action) {
  switch (action.type) {
    case entities.genres.types.create.SUCCESS: {
      const { payload } = action;
      if (!R.isNil(payload.data.parentId)) {
        return R.over(
          R.lensPath(['entities', payload.data.parentId, 'subGenres']),
          (subGenres) => R.append(action.response.result, subGenres),
          state
        );
      }
      return state;
    }
    case entities.genres.types.delete.SUCCESS: {
      const { payload } = action;
      const parentId = R.view(
        R.lensPath(['entities', payload.id, 'parendId']),
        state
      );
      if (!R.isNil(parentId)) {
        return R.over(
          R.lensPath(['entities', parentId, 'subGenres']),
          (subGenres) => R.reject((key) => key === payload.id, subGenres),
          state
        );
      }
      return state;
    }
    default:
      return state;
  }
}

export default function genres(state = initialState, action) {
  return [genreReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
