import { useRef, useMemo, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useCurrentLocale } from 'imdshared';

import type {
  ArtistNested,
  EntityModels,
  NestDefinition,
  ReduxState,
  ReleaseNested,
} from 'imddata';
import {
  useEntries,
  useQuery,
  useEntry,
  useUpdateEntity,
  useRelease,
  useCustomerFeature,
  useArtists,
} from 'imddata';
import type { FormData } from './components';
import type { TemplateData } from '@imus/release-page-template';

export const useSaveMetadata = ({
  id,
  updateShops,
  legacyPremium,
  form,
  formSelector,
}: {
  legacyPremium?: boolean;
  id: string;
  updateShops: any;
  form: string;
  formSelector: any;
}) => {
  const canBeUpdated = useRef(false);
  const { updateEntry: updatePage } = useUpdateEntity({
    entity: 'releasePages',
    id,
  });
  const release: FormData['release'] = useSelector((state: ReduxState) =>
    formSelector(state, 'release')
  );
  const general: FormData['general'] = useSelector((state: ReduxState) =>
    formSelector(state, 'general')
  );

  const services = release?.services;

  const presaveFeatureEnabled = useCustomerFeature('artist-hub-pre-save');

  const servicesRef = useRef<typeof release.services>();
  const presaveRef = useRef<typeof release.presave>();

  useEffect(() => {
    if (
      (services && servicesRef.current && servicesRef.current !== services) ||
      (release &&
        presaveRef.current !== undefined &&
        presaveRef.current !== release.presave)
    ) {
      updateShops(
        {
          formId: form,
          data: services.map(
            ({ preSaveAvailable, isVisible, isCustom, customUrl, name }) => ({
              isCustom,
              isVisible,
              customUrl,
              name,
              ...(preSaveAvailable && (presaveFeatureEnabled || legacyPremium)
                ? { preSaveActive: !!release?.presave }
                : {}),
            })
          ),
        },

        { debounce: true }
      );
    }
    servicesRef.current = services;
    presaveRef.current = release?.presave;
  }, [services, !!release?.presave]);

  useEffect(() => {
    if (canBeUpdated.current && general) {
      updatePage(
        {
          formId: form,
          data: {
            locale: general?.locale,
          },
        },

        { debounce: true }
      );
    }

    if (general) {
      canBeUpdated.current = true;
    }
  }, [general]);
};

type ReleasePageShop = {
  customUrl: string;
  name: string;
  isCustom: boolean;
  isVisible: boolean;
  url: string;
  preSaveActive: boolean;
  preSaveAvailable: boolean;
};

const useReleasePageShops = ({
  id,
  passive,
}: {
  id: string;
  passive?: boolean;
}) => {
  const query = useMemo(() => ({ releasePageId: id }), [id]);
  const { queryHash } = useQuery({ query, wait: 0 });

  const { updateEntry: updateShops } = useUpdateEntity({
    entity: 'releasePagesShops',
    query,
    queryHash,
  });

  const data = useEntries<ReleasePageShop>({
    queryHash,
    entity: 'releasePagesShops',
    passive,
    query,
  });

  return { ...data, updateShops };
};

const nestFiles: NestDefinition = [
  {
    key: 'background',
    getKey: (entry: ReleasePage) => {
      return entry.backgroundId;
    },
    entity: 'files',
    collect: true,
  },
  {
    collect: true,
    key: 'logo',
    getKey: (entry: ReleasePage) => {
      return entry.logoId;
    },
    entity: 'files',
  },
];

type ReleasePagePressFile = {
  id: number | string;
  file?: number;
  fileId?: number;
  previewFileId?: number;
  title: string;
  description: string;
};

type ReleasePagePressFileNested = EntityModels.Nest<
  ReleasePagePressFile,
  {
    file?: EntityModels.File;
    preview?: EntityModels.File;
  }
>;

export type AdjustedFormData = FormData & {
  artistPages?: TemplateData['artistPages'];
  release: {
    displayArtist: string;
    title: string;
    cover: { downloadUrl: string };
  };
};
//
export type ReleasePage = {
  id: string;
  isBranded: boolean;
  isPremium: boolean;
  isOutdated: boolean;
  isActive: boolean;
  locale: string;
  title: string;
  logoId: number | null;
  backgroundId: number | null;
  releaseId: number;
  shopsStatus: 'fetched' | 'none' | 'scanning' | 'requested' | 'not_available';
  status: 'draft';
  logo?: EntityModels.File;
  background?: EntityModels.File;
  url: string | null;
  targets: {
    isReadyToActivate: {
      conditions: {
        hasCorrectGtin: {
          isMet: boolean;
        };
      };
      isMet: boolean;
    };
    isReadyToGenerate: {
      isMet: boolean;
    };
    isReadyToOrder: {
      isMet: boolean;
    };
  };
};
export const useReleasePage = ({ id }: { id: string }) =>
  useEntry<ReleasePage>({
    entity: 'releasePages',
    id,
    nest: nestFiles,
  });

export const useReleasePageFormData = ({
  releasePage,
  releasePageShops,
  release,
  loaded,
  artists,
}: {
  artists?: ArtistNested[];
  releasePage?: ReleasePage;
  releasePageShops?: ReleasePageShop[];
  release?: ReleaseNested | null;
  pressFiles?: ReleasePagePressFileNested[];
  loaded: boolean;
}) => {
  const locale = useCurrentLocale();
  const services = useMemo(() => {
    if (releasePageShops?.length && loaded) {
      return releasePageShops.map(
        ({ name, isCustom, preSaveAvailable, customUrl, url, isVisible }) => ({
          url,
          isCustom,
          customUrl,
          name,
          isVisible,
          preSaveAvailable,
        })
      );
    }
    return [];
  }, [loaded, releasePageShops]);

  const presave = !!releasePageShops?.find((rps) => rps.preSaveActive);

  const {
    defaultName: { title, displayArtist },
    availableCovers,
  } = release || {
    defaultName: { title: '', displayArtist: '' },
    availableCovers: { small: '', export: '' },
  };

  const coverUrl = release?.availableCovers?.small;

  const allReleaseDataLoaded = !!coverUrl && loaded;

  return useMemo<AdjustedFormData | null>(
    () =>
      allReleaseDataLoaded && releasePage
        ? {
            branded: releasePage.isBranded,
            appVersion: __VERSION__,
            general: {
              logo: releasePage.logo,
              background: releasePage.background,
              locale: releasePage.locale || locale,
              title: releasePage.title,
            },
            artistPages: artists
              ?.filter((a) => a.states?.artistHub?.artistPage?.isPublished)
              .map((artist) => ({
                avatar: {
                  filename: '',
                  extension: '',
                  access: '',
                  url: artist.profileImageFile?.downloadUrl,
                },
                pageKey: '',
                title: artist.defaultName?.name || '',
              })),
            release: {
              releaseAt:
                release.currentDeliveryBundle?.releaseAt ||
                release.originalReleaseDate ||
                undefined,
              presave,
              cover: {
                downloadUrl: availableCovers?.small || availableCovers?.export,
              },
              enabled: true,
              title,
              displayArtist,
              services,
            },
          }
        : null,
    [
      !!release,
      artists?.length,
      releasePage,
      services,
      availableCovers?.small,
      allReleaseDataLoaded,
    ]
  );
};

export const useReleasePageData = (id: string) => {
  const { entry: releasePage, request: releasePageRequest } = useReleasePage({
    id,
  });

  const {
    updateShops,
    entries: releasePageShops,
    request: releasePageShopsRequest,
  } = useReleasePageShops({
    id,
  });

  const { entry: release, request: releaseRequest } = useRelease({
    id: releasePage?.releaseId,
    passive: !releasePage,
  });

  const { entries: artists } = useArtists({
    ids: release?.artists?.map(({ artistId }) => artistId),
    queryHash: 'release-id-' + id,
    passive: !release,
  });

  // console.log(
  //   'Artists',
  //   release?.artists?.map(({ id }) => id),
  //   artists
  // );

  const loaded =
    releaseRequest.loaded &&
    releasePageRequest.loaded &&
    releasePageShopsRequest.loaded;
  return {
    loaded,
    release,
    artists,
    updateShops,
    releasePageShops,
    releasePage,
  };
};
