import React from 'react';

export default function ErrorLine() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 5,
        left: 5,
        width: 2,
        height: 'calc(100% - 10px)',
        borderRadius: 1,
        backgroundColor: 'red',
      }}
    />
  );
}
