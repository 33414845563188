import { useCallback, useEffect, useState } from 'react';
import type { EntityModels } from 'imddata';
import { useEntryProvider, useCreateOrder, useCustomerFeature } from 'imddata';
import { useHistory } from 'react-router-dom';
import { useFastPaymentFlowContext } from 'imdshared';
import { useProductPage } from '../ProductWizard';

export const useReleaseDeliveryOrder = ({
  bundleId,
}: {
  bundleId: number | string;
  releaseId: number | string;
}) => {
  const [processStarted, setStartOrder] = useState(false);
  const hasSupport = useCustomerFeature('customer-support');

  const {
    state: {
      data: { advancedSupportPurchase },
    },
  } = useProductPage();

  const shouldBuySupport = advancedSupportPurchase && !hasSupport;

  const { open: openPayment } = useFastPaymentFlowContext();
  const history = useHistory();

  const startOrder = useCallback(() => {
    setStartOrder(true);
  }, []);

  const {
    createOrder: createSupportOrder,
    request: { id: supportOrderId },
  } = useCreateOrder({
    entity: 'products',
    id: 'customer-support',
    requestStoreKey: `${bundleId}-support`,
  });

  const {
    createOrder,
    request: { errorMessage, id: orderId },
  } = useCreateOrder({
    entity: 'deliveryBundles',
    id: bundleId,
    requestStoreKey: `${bundleId}`,
  });

  useEffect(() => {
    if (processStarted) {
      openPayment({
        loading: true,
      });

      if (shouldBuySupport) {
        createSupportOrder();
      }

      createOrder({
        componentKey: bundleId,
      });
    }
  }, [processStarted]);

  const order = useEntryProvider<EntityModels.Order>({
    entity: 'orders',
    id: orderId,
  });

  useEffect(() => {
    if (errorMessage) {
      history.push('/dashboard');

      openPayment({
        loading: false,
        failed: true,
        successPath: `/order/md/${bundleId}/thank-you`,
      });
      return;
    }
    if (order && (!shouldBuySupport || supportOrderId)) {
      openPayment({
        loading: false,
        orderStatus: order.status,
        successPath: `/order/md/${bundleId}/thank-you`,
      });
    }
  }, [errorMessage, supportOrderId, shouldBuySupport, order]);

  return {
    startOrder,
    ordering: processStarted,
  };
};
