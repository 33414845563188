import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { NestDefinition, EntityModels } from 'imddata';
import { useEntries, useQuery } from 'imddata';
import { PageTemplate, QueryBuilderInput, InfiniteList } from 'components';
import { CenterWrapper } from 'imdshared';
import { Card } from 'imdui';
import VirtualizedItem from './components/VirtualizedItem';

const Screen = styled.div`
  min-width: 320px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const ListWrapper = styled(Card)`
  margin: 48px 0;
`;

const nest: NestDefinition = [{ entity: 'users', key: 'users' }];
const options: any[] = [];

type CustomerNested = EntityModels.Nest<
  EntityModels.Customer,
  {
    users: EntityModels.User[];
  }
>;

export default function ImpersonateUser(): JSX.Element {
  const { t } = useTranslation();
  const { query, queryHash, updateQuery } = useQuery();
  const [filterValue, setFilterValue] = useState([]);
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useEntries<CustomerNested>({
    nest,
    entity: 'customers',
    query: { ...query, with: 'users' },
    queryHash,
  });

  const handleChange = useCallback(({ value, searchQuery }): void => {
    setFilterValue(value);
    updateQuery(searchQuery || { query: null });
  }, []);

  return (
    <CenterWrapper>
      <Screen>
        <PageTemplate title={t('Select customer for impersonation')}>
          <QueryBuilderInput
            testId="Impersonate-search-input"
            value={filterValue}
            loading={loading}
            options={options}
            onChange={handleChange}
            query={query}
          />

          <ListWrapper>
            <InfiniteList<CustomerNested>
              data={entries}
              hasNextPage={hasMorePages}
              loadNextPage={loadMore}
              loading={loading}
              rowRenderer={VirtualizedItem}
              emptyPlaceholder={t('no-users')}
              getRowHeight={(): number => 64}
            />
          </ListWrapper>
        </PageTemplate>
      </Screen>
    </CenterWrapper>
  );
}
