//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body, Content, themeLight as theme } from 'imdui';
import { useProductPriceFormatted } from '../../../../logic';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  ${Body} {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 16px;
  }

  ${Content} {
    text-align: right;
  }
`;

const LegendRowColor = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 6px;
  background-color: ${({ id }) => {
    if (id === 'ultraexpressdelivery') {
      return theme.accent.green;
    }
    return '#fafafa';
  }};
  box-shadow: inset 0 0 0 1px
    ${({ id }) => {
      if (id === 'ultraexpressdelivery') {
        return 'none';
      }
      if (id === 'expressdelivery') {
        return theme.accent.green;
      }
      return '#eee';
    }};
`;

const LegendRow = ({ option }) => {
  const { t } = useTranslation();
  const priceFormatted = useProductPriceFormatted(option.id);

  if (!priceFormatted) {
    return null;
  }

  if (!option.isAvailable) return null;

  return (
    <Wrapper>
      <LegendRowColor id={option.id} />

      <Body>
        {t(option.id, {
          context: `calendar-${option.isAvailable ? 'enabled' : 'disabled'}`,
        })}
      </Body>

      <Content>{priceFormatted}</Content>
    </Wrapper>
  );
};

export default LegendRow;
