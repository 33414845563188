//
import { fork, select, takeEvery } from 'redux-saga/effects';
import { types } from '../../actionTypes/tracks';
import createTempId from '../../utils/createTempId';

import createTempTracks from './createTempTracks';
import pollConfirmedTracks from './pollConfirmedTracks';
// import createEmptyTrackAsync from './createEmptyTrack';
import uploadAudioForSingleTrack from './uploadAudioForSingleTrack';
import handleUpdateReleaseTracks from './handleUpdateReleaseTracks';
import handleCreateAndUploadSingleTrack from './handleCreateAndUploadSingleTrack';
import handleUploadQueueChannel from './handleUploadQueueChannel';

const selectCustomerAssetLanguage = (state) => {
  const { userId } = state.auth.profile || {};
  return state.entities.users.entities[userId]?.defaultLanguageId || 'en';
};

function* uploadTracksAsync(action) {
  const {
    payload: { trackId },
    meta: { analytics, ...meta } = {},
  } = action;
  if (trackId) {
    yield fork(uploadAudioForSingleTrack, action);
  } else {
    const {
      payload: {
        nestingPath,
        revenueSplits,
        artists,
        audios,
        genreId,
        languageId,
      },
    } = action;
    const tempTracks = [];
    const defaultLanguageId = yield select(selectCustomerAssetLanguage);

    for (let i = 0; i < audios.length; i += 1) {
      const audio = audios[i];
      const name = {
        languageId: languageId || defaultLanguageId,
        title: audio && audio.name ? audio.name.replace(/\.\w+$/, '') : '',
      };
      const data = {
        revenueSplits,
        artists,
        genreId,
        defaultLanguageId: name.languageId,
        names: {
          [name.languageId]: name,
        },
        defaultName: name,
      };
      const tempId = createTempId(i.toString());
      tempTracks.push({
        nestingPath,
        tempId,
        audio,
        data,
      });
    }
    yield fork(createTempTracks, {
      payload: { tempTracks },
      meta,
    });
  }
}

function* createTrackAsync(action) {
  const {
    payload: { nestingPath, data = {}, tempId: passedTempId },
    meta,
  } = action;

  const tempId =
    passedTempId || createTempId(nestingPath ? JSON.toString(nestingPath) : '');

  const customerLangauge = yield select(selectCustomerAssetLanguage);

  const defaultLanguageId = data.defaultLanguageId || customerLangauge;

  const names = data.names || [
    {
      languageId: defaultLanguageId,
    },
  ];

  yield fork(createTempTracks, {
    payload: {
      tempTracks: [
        {
          data: {
            ...data,
            names,
            defaultName: names[0],
            defaultLanguageId,
          },
          nestingPath,
          tempId,
        },
      ],
    },
    meta,
  });
}

export default function* requestRoot() {
  yield takeEvery([types.CREATE_EMPTY_TRACK], createTrackAsync);
  yield takeEvery([types.UPLOAD_TRACKS], uploadTracksAsync);

  // Handling internal socket messages
  yield fork(handleCreateAndUploadSingleTrack);
  yield fork(handleUpdateReleaseTracks);
  yield fork(handleUploadQueueChannel);
  // Polling on state
  yield fork(pollConfirmedTracks);
}
