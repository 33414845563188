import { map } from 'ramda';
import {
  useMemo,
  useCallback,
  useEffect,
  useContext,
  createContext,
} from 'react';
import type { Moment } from 'moment';
import moment from 'moment';
import { change, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import type { ReduxState } from 'imddata';
import { updateEntity as updateEntityAction } from 'imddata/actionTypes/request';
import type { Values } from './types';

export const BundleReleaseContext = createContext<{
  releaseId: string | number;
  bundleId: string | number;
}>({
  releaseId: 0,
  bundleId: 0,
});

const PRESALES_OFFSET = 2;
const SPOTIFY_OFFSET = 14;
const PLAYLIST_OFFSET = 20;

export const useDates = ({
  form,
  disabled,
}: {
  form: string;
  disabled: boolean | undefined;
}) => {
  const dispatch = useDispatch();
  const { bundleId, releaseId } = useContext(BundleReleaseContext);
  const selector = useMemo(() => formValueSelector(form), []);
  const {
    originalReleaseDate,
    pricePlanId,
    hasPreSales,
    pitching,
    preSalesAt,
    deliverAt,
    enableEditorialPlaylistPitching,
    releaseAt,
  }: Pick<
    Values,
    | 'enableEditorialPlaylistPitching'
    | 'originalReleaseDate'
    | 'pricePlanId'
    | 'deliverAt'
    | 'hasPreSales'
    | 'pitching'
    | 'preSalesAt'
    | 'releaseAt'
  > = useSelector((state: ReduxState) =>
    selector(
      state,
      'originalReleaseDate',
      'enableEditorialPlaylistPitching',
      'pricePlanId',
      'deliverAt',
      'hasPreSales',
      'pitching',
      'preSalesAt',
      'releaseAt'
    )
  );

  const spotifyPitching = pitching?.shops?.find((s) => s === 'spotify');

  const shippingOffset = hasPreSales
    ? PRESALES_OFFSET
    : enableEditorialPlaylistPitching
      ? PLAYLIST_OFFSET
      : spotifyPitching
        ? SPOTIFY_OFFSET
        : 0;

  const digitalReleaseOffset = enableEditorialPlaylistPitching
    ? PLAYLIST_OFFSET
    : spotifyPitching
      ? SPOTIFY_OFFSET
      : hasPreSales
        ? 1
        : 0;

  const onChangeDates = useCallback(
    (values: { [key: string]: Moment | null }) => {
      const dates = map(
        (value) => (value ? value.format('YYYY-MM-DD') : null),
        values
      ) as any;
      dispatch(
        updateEntityAction('deliveryBundles', {
          id: bundleId,
          data: dates,
        })
      );
    },
    [bundleId, releaseId]
  );

  const onClearDeliveryDates = useCallback(() => {
    if (disabled) return;
    dispatch(change(form, 'deliverAt', null));
    dispatch(change(form, 'preSalesAt', null));
    dispatch(change(form, 'releaseAt', null));
    onChangeDates({
      releaseAt: null,
      preSalesAt: null,
      deliverAt: null,
    });
  }, [disabled]);

  const digitalReleaseError = useMemo(() => {
    if (
      originalReleaseDate &&
      releaseAt &&
      moment(releaseAt).isBefore(originalReleaseDate)
    ) {
      return 'original-release-date-cannot-be-after-digital-release';
    }
    return null;
  }, [originalReleaseDate, releaseAt]);

  useEffect(() => {
    if (disabled) return;
    if (
      deliverAt &&
      releaseAt &&
      moment(releaseAt).isBefore(
        moment(deliverAt).add(digitalReleaseOffset, 'days')
      )
    ) {
      dispatch(change(form, 'releaseAt', null));
      onChangeDates({
        releaseAt: null,
      });
    }
  }, [deliverAt, disabled, releaseAt]);

  return {
    digitalReleaseError,
    onClearDeliveryDates,
    onChangeDates,
    digitalReleaseOffset,
    originalReleaseDate,
    pricePlanId,
    hasPreSales,
    spotifyPitching,
    preSalesAt,
    releaseAt,
    shippingOffset,
  };
};
