import { useEffect, useCallback, useState } from 'react';
import type { TFunction } from 'i18next';
import { themeLight, useCurrentLocale } from 'imdshared';
import type { ReduxState } from 'imddata';
import {
  useCustomerFeatureRollout,
  useCurrentCustomer,
  useCurrentUser,
} from 'imddata';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BEACON_KEYS = {
  noSupport: {
    en: 'e51e090e-0462-47ba-be6b-6e1f207fc086',
    fr: 'c676f42d-232a-47d5-bc81-b062ad2099dd',
    it: 'ccf07b7d-ad5a-4c49-bebf-f9363b913305',
    de: 'a21ae176-4e49-4e79-ba0e-999bba7ede86',
    es: 'aac1e391-2cb4-4c32-a5d5-8ef57a55f230',
    pt: '61f66067-3021-4446-bf01-1e3fc1af6ba8',
  },
  standart: {
    en: 'd7a244ac-0431-46ee-9555-024985191a62',
    fr: '98a6e9ed-e867-4532-9095-1ebf34a53d0d',
    it: 'e06a5d4c-f258-4593-80a1-3ea4d54d9cc6',
    de: '89f03d94-7e60-49a4-a52d-4bd5fc36c932',
    es: '8247d8c3-c306-4b91-9273-791a88711b1c',
    pt: '0bd208f0-7597-4a53-b5e5-49cc7b5c0543',
  },
  fast: {
    en: 'ad5de6a9-abe4-4fe5-a818-fa837281c154',
    fr: '9b41c42c-a897-4426-93c0-15118af763fa',
    it: 'e65ecbe3-040d-4e02-ad6a-e4667acbfed2',
    de: '6f5f5979-81e3-4227-9646-d7a644548a40',
    es: 'a4064543-98ff-4bd2-b9f9-b371a6544d02',
    pt: 'b2d2d87f-a760-432f-9444-08833774049e',
  },
};
const getBeaconLabels = (t: TFunction) => {
  return {
    // Answers
    messageConfirmationText: t('help-center-message-confirmation-text'),
    responseTime: t('help-center-response-time'),
    noTimeToWaitAround: t('help-center-no-time-to-wait-around'),
  };
};
export const useInitBeacon = () => {
  const [opened, setOpened] = useState(false);
  const { entry: user } = useCurrentUser();
  const locale = useCurrentLocale();
  const customer = useCurrentCustomer();
  const { t } = useTranslation();

  const [ready, setReady] = useState(false);

  const devBeaconKey = useSelector((state: ReduxState) =>
    state.dev.devmode ? state.dev.vars.beaconId : undefined
  );

  const supportEnabled = useCustomerFeatureRollout({
    feature: 'customer-support',
    rolloutKey: 'tiered-subs',
    fallback: true,
  });

  const fastSupportEnabled = useCustomerFeatureRollout({
    feature: 'customer-support-faster',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });

  const supportLevel: keyof typeof BEACON_KEYS = fastSupportEnabled
    ? 'fast'
    : supportEnabled
      ? 'standart'
      : 'noSupport';

  const init = useCallback(
    (level?: keyof typeof BEACON_KEYS) => {
      const beaconKey = user
        ? devBeaconKey || BEACON_KEYS[level || supportLevel][locale]
        : null;

      if (window.Beacon && locale && beaconKey && user?.email) {
        const beaconState = window.Beacon('info');
        if (beaconState && beaconState.beaconId !== beaconKey) {
          setReady(false);
          window.Beacon('destroy');
        }
        window.Beacon('init', beaconKey);
        window.Beacon('identify', {
          name: customer?.entry?.label,
          email: user.email,
          'FT-env': process.env.TARGET_ENV,
          'FT-version': __VERSION__,
        });
        window.Beacon('config', {
          showPrefilledCustomFields: false,
          color: themeLight.accent.green,
          // mode: 'selfService',
          // messagingEnabled: true,
          // docsEnabled: true,
          messaging: {
            // chatEnabled: true,
            contactForm: {
              // showName: false,
              showGetInTouch: true,
            },
          },
          display: {
            showPoweredBy: false,
            style: 'manual',
            position: 'left',
          },
          labels: getBeaconLabels(t),
        });

        window.Beacon('on', 'ready', () => {
          setReady(true);
        });

        window.Beacon('on', 'open', () => {
          setOpened(true);
        });
        window.Beacon('on', 'close', () => {
          setOpened(false);
        });
      }
    },
    [locale, user?.email, supportLevel]
  );
  useEffect(() => {
    init();
  }, [supportLevel, user?.email]);
  return {
    ready,
    opened,
    init,
  };
};
