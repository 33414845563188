import React from 'react';
import styled from '@emotion/styled';
import { Content } from 'imdui';
import PlainField from './PlainField';
import type { ChangesProps, Change } from '../../types';
import EntitledGroup from '../EntitledGroup';
import RevertButton from '../RevertButton';

interface Props extends ChangesProps {
  groupKey: string;
  getTitle: (c: Change) => string;
}

const Wrapper = styled.div`
  padding: 0 24px 24px 24px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > ${Content} {
    display: block;
    margin-right: 8px;
  }
`;

const FieldsChange: React.FC<Props> = ({ groupKey, getTitle, changes }) => {
  return (
    <EntitledGroup groupKey={groupKey}>
      {changes.map((c) => (
        <Wrapper key={c.id}>
          <TitleRow>
            <Content>{getTitle(c)}</Content>

            {c.attributes.length > 0 && <RevertButton changeId={c.id} />}
          </TitleRow>

          <PlainField change={c} />
        </Wrapper>
      ))}
    </EntitledGroup>
  );
};

export default FieldsChange;
