//
import React from 'react';
import styled from '@emotion/styled';
import { Centered, Card, Caption, Table } from 'imdshared';
import { useTranslation } from 'react-i18next';
import Header from './Header';

const Wrapper = styled.div`
  width: 100%;
  height: 256px;
  padding-bottom: 56px;
`;

const SalesWrapper = styled(Card)`
  > ${Caption} {
    color: ${(props) => props.theme.foreground.secondary};
    margin-top: 56px;
  }
`;

const ReportingListItem = ({
  itemProps,
  tableProps,
}: {
  itemProps: React.ComponentProps<typeof Header>;
  tableProps: {
    fields: unknown[];
    items: unknown[];
  };
}) => {
  const { t } = useTranslation();
  if (!tableProps?.items?.length) {
    return (
      <Wrapper>
        <Header {...itemProps} />
        <SalesWrapper>
          <Centered style={{ padding: '32px 0' }}>
            {t('sales-are-being-generated')}
          </Centered>
        </SalesWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Header {...itemProps} />

      <SalesWrapper>
        {/*
       //@ts-ignore */}
        <Table {...tableProps} />
      </SalesWrapper>
    </Wrapper>
  );
};

export default ReportingListItem;
