import React, {
  createContext,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { Moment } from 'moment';
import moment from 'moment';
import { decamelize } from 'humps';
import type { ReduxState, FieldUpdateRequestMappedField } from 'imddata';
import {
  EntityFormContext,
  AppContext,
  FieldUpdateRequestContext,
} from 'imddata';
import { IssueListContext, IconsCollection, OverlineText, Window } from 'imdui';
import { equals, difference } from 'ramda';
import type { BaseFieldProps } from 'redux-form';
import { Field } from 'redux-form';
import { FeedbackRequestForm } from './FeedbackRequestForm';

const parseMomentToDate = (value: any): string | null => {
  if (value) {
    return moment(value).format();
  }
  return null;
};
const formatToMoment = (value: any): Moment | null =>
  value ? moment(value) : null;

type FieldUpdateRequestEntry = Record<string, any>;

const ActionsContext = createContext<{
  adminError?: FieldUpdateRequestEntry | null;
  feedbackError?: FieldUpdateRequestEntry | null;
  userLocale?: string;
  status?: string;
}>({});

const preventIssues = { issues: null };

const FeedbackRequestAction = ({
  label,
  fieldName,
}: {
  label: string;
  fieldName: string;
}) => {
  const [isOpen, setOpen] = useState(false);
  const [loadedTranslations, setLoadedTranslations] = useState(false);
  const { adminError, userLocale, status } = useContext(ActionsContext);

  const initialValues = useMemo(
    () => ({ requests: adminError?.issueList }),
    [adminError?.issueList]
  );

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const { i18n } = useTranslation();
  useMemo(() => {
    if (userLocale) {
      i18n.loadLanguages([userLocale]).then(() => {
        setLoadedTranslations(true);
      });
    }
  }, [!!userLocale]);
  const disabled = status !== 'draft';
  return (
    <>
      <OverlineText.IconButton
        icon={IconsCollection.requestFeedback}
        iconProps={{ size: 16, viewBox: '0 0 24 24' }}
        tabIndex={-1}
        className={disabled || !loadedTranslations ? 'disabled' : ''}
        warning={!!adminError?.issueList?.length}
        disabled={disabled}
        onClick={handleClick}
      />
      <Window
        style={{ minWidth: '600px' }}
        isOpen={isOpen}
        close={handleClose}
        title={`Feedback for "${label}"`}
      >
        <IssueListContext.Provider value={preventIssues}>
          <FeedbackRequestForm
            userLocale={userLocale}
            onClose={() => setOpen(false)}
            fieldName={fieldName}
            initialValues={initialValues}
          />
        </IssueListContext.Provider>
      </Window>
    </>
  );
};

const CompareToOriginalValueAction = ({
  component: Component,
  label,
}: {
  label: string;
  component: React.ComponentType<any>;
}) => {
  const [isOpen, setOpen] = useState(false);
  const { feedbackError, userLocale } = useContext(ActionsContext);
  const issueList = useMemo(
    () =>
      feedbackError
        ? {
            issues: { list: feedbackError.issueList },
          }
        : preventIssues,
    [feedbackError]
  );
  if (!feedbackError) return null;
  return (
    <>
      <OverlineText.IconButton
        icon={IconsCollection.previewChange}
        iconProps={{ size: 16 }}
        tabIndex={-1}
        onClick={() => {
          setOpen(true);
        }}
        disabled={!feedbackError}
      />
      <Window
        style={{ minWidth: '600px' }}
        isOpen={isOpen}
        close={() => {
          setOpen(false);
        }}
        title={`Original Customer Value for "${label}" (${userLocale})`}
      >
        <div style={{ padding: '16px' }}>
          <IssueListContext.Provider value={issueList}>
            <Component
              meta={{ touched: true }}
              disabled={true}
              input={{
                value: feedbackError?.data?.value,
              }}
            />
          </IssueListContext.Provider>
        </div>
      </Window>
    </>
  );
};

const getFirstId = (id: Array<number> | number) => {
  if (Array.isArray(id)) return id[0];
  return id;
};

const emptycheck: Record<number, never> = {};

export const useFieldUpdatable = ({ fieldName }: { fieldName: string }) => {
  const app = useContext(AppContext);
  const { id, entity } = useContext(EntityFormContext);
  const { changes, userLocale, checkFieldIssue, entry } = useContext(
    FieldUpdateRequestContext
  );

  const firstId = getFirstId(id);

  const feedbackError = useMemo(() => {
    if (!entity || !changes || !changes[entity] || !changes[entity]?.[firstId])
      return null;

    return changes[entity]?.[firstId].find(
      (data) => data.name === fieldName && data.status !== 'draft'
    );
  }, [changes]);

  const adminError = useMemo(() => {
    if (!entity || !changes || !changes[entity] || !changes[entity]?.[firstId])
      return null;

    return changes[entity]?.[firstId].find(
      (data) => data.name === fieldName && data.status === 'draft'
    );
  }, [changes]);

  const checkedFieldIssues = useSelector((state: ReduxState) => {
    if (
      feedbackError?.id &&
      entry?.id &&
      state.ui.fieldUpdateRequests[entry.id]?.[feedbackError?.id]?.issues
    ) {
      return state.ui.fieldUpdateRequests[entry.id][feedbackError?.id]?.issues;
    }
    return emptycheck;
  });

  const editable =
    feedbackError && app === 'front'
      ? feedbackError.status === 'requested'
      : null;

  const handleIssueCheck = useMemo(
    () =>
      checkFieldIssue && feedbackError?.id
        ? (issueIndex: number, checked: boolean) => {
            checkFieldIssue(feedbackError?.id, issueIndex, checked);
          }
        : undefined,
    [feedbackError?.id, checkFieldIssue]
  );

  const issueList = useMemo(() => {
    if (adminError?.issueList) {
      return adminError?.issueList;
    }
    if (feedbackError?.issueList) {
      return feedbackError?.issueList.map((v, idx: number) => {
        return {
          checked: checkedFieldIssues[idx],
          message: v.message,
        };
      });
    }
    return null;
  }, [checkedFieldIssues, adminError?.issueList, feedbackError?.issueList]);

  return {
    issueList,
    handleIssueCheck,
    userLocale,
    entry,
    adminError,
    feedbackError,
    editable,
  };
};

const isValueNotChanged = ({
  fieldName,
  feedbackError,
  entityValue,
  value,
}: {
  fieldName: string;
  feedbackError: FieldUpdateRequestMappedField;
  entityValue: number[];
  value: unknown;
}) => {
  if (!feedbackError) return true;

  switch (fieldName) {
    case 'artists': {
      const serialized = feedbackError.data?.value?.map(
        ({ artistId, role }: any) => ({ artistId, role })
      );

      return equals(value, serialized);
    }
    case 'contributors': {
      const serialized = feedbackError.data?.value?.map(
        ({ contributorId, role }: any) => ({ contributorId, role })
      );

      return equals(value, serialized);
    }
    case 'publishers': {
      const serialized = feedbackError.data?.value?.map(
        ({ publisherId }: any) => ({
          publisherId,
        })
      );

      return equals(value, serialized);
    }
    case 'covers': {
      const serialized = feedbackError.data?.value?.map(({ id }: any) => id);

      console.log(entityValue, serialized);

      return difference(entityValue, serialized).length === 0;
    }
    case 'audios': {
      const serialized = feedbackError.data?.value?.map(({ id }: any) => id);
      return difference(entityValue, serialized).length === 0;
    }

    default: {
      if (toString.call(value) === '[object Boolean]') {
        return value === !!feedbackError.data?.value;
      }
      return value === feedbackError.data?.value;
    }
  }
};

const isValueChanged = (...args: Parameters<typeof isValueNotChanged>) =>
  !isValueNotChanged(...args);

const emptyRelation: never[] = [];

const useEntityFieldValue = (fieldName: string): number[] => {
  const { id } = useContext(EntityFormContext);
  const entityValue = useSelector((state: ReduxState) => {
    // Currently do not support multiedit compare
    if (Array.isArray(id)) return emptyRelation;

    switch (fieldName) {
      case 'covers': {
        return state.entities.releases.entities[id]?.covers || emptyRelation;
      }
      case 'audios': {
        return state.entities.tracks.entities[id]?.audios || emptyRelation;
      }
      default: {
        return emptyRelation;
      }
    }
  });
  return entityValue;
};

type Props<T = Record<string, unknown>> = BaseFieldProps<T> &
  Omit<T, 'input' | 'meta'> & {
    type?: 'momentdate' | 'input' | 'checkbox';
    disabled?: boolean;
    label?: string;
    testId?: string;
    floatingLabelText?: string;
    entityFieldName?: string;
    nameKey?: string;
    components?: Record<string, React.FC>;
  };

const FieldUpdatable = <P,>(props: Props<P>) => {
  const app = useContext(AppContext);
  const { t } = useTranslation();
  const fieldName = props.entityFieldName || props.nameKey || props.name;
  const label =
    props.label ||
    props.floatingLabelText ||
    // @ts-ignore
    props?.props?.label ||
    // @ts-ignore
    props?.props?.floatingLabelText ||
    decamelize(fieldName, { separator: '-' });

  const entityValue = useEntityFieldValue(fieldName);

  const {
    editable,
    adminError,
    userLocale,
    handleIssueCheck,
    issueList,
    entry,
    feedbackError,
  } = useFieldUpdatable({
    fieldName,
  });

  const validate = useCallback(
    (value) => {
      if (feedbackError?.status !== 'requested' || adminError)
        return typeof props.validate === 'function'
          ? props.validate(value)
          : null;

      return isValueNotChanged({ value, fieldName, entityValue, feedbackError })
        ? '[FUR]' // not valid but message is omitted
        : typeof props.validate === 'function'
          ? props.validate(value)
          : null;
    },
    [
      props.validate,
      feedbackError?.message,
      adminError?.message,
      fieldName,
      entityValue,
    ]
  );
  const warn = useMemo(() => {
    // TODO define
    return (value: any) => {
      if (app === 'admin') {
        if (feedbackError?.status === 'submitted') {
          if (
            isValueChanged({ value, fieldName, entityValue, feedbackError })
          ) {
            return 'Customer changed that field';
          }
          return '(!!!) Customer omitted that field';
        }
      }
      return typeof props.warn === 'function' ? props.warn(value) : null;
    };
  }, [
    props.warn,
    feedbackError?.message,
    adminError?.message,
    fieldName,
    entityValue,
  ]);

  const disabled = editable !== null ? !editable : props.disabled;

  const OverlineActions = useCallback(() => {
    return app === 'admin' ? (
      <>
        <CompareToOriginalValueAction
          label={label}
          // @ts-ignore
          component={props.component}
          fieldName={fieldName}
        />
        <FeedbackRequestAction
          key="feedback-request"
          label={label}
          fieldName={fieldName}
        />
      </>
    ) : null;
  }, []);

  const components = useMemo(() => {
    return {
      ...(props.components || {}),
      OverlineActions,
    };
  }, [props.components]);

  const handleBlur = useMemo(
    () =>
      props.type === 'momentdate'
        ? (event: Event) => {
            event.preventDefault();
          }
        : props.onBlur,
    [props.type]
  );

  const actionsContextValue = useMemo(
    () => ({
      userLocale,
      status: entry?.status,
      feedbackError,
      adminError,
    }),
    [userLocale, entry?.status, feedbackError, adminError]
  );

  const issueContext = useMemo(
    () =>
      issueList
        ? {
            onCheckIssue: handleIssueCheck,
            issues: {
              title: `${t('issues')}${
                app === 'admin' ? ` [${entry?.status}]` : ''
              }:`,
              list: issueList.map((is) => ({
                ...is,
                message: is.message.replace(/{{[^}]*}}/g, '').trim(),
              })),
            },
          }
        : preventIssues,
    [handleIssueCheck, issueList]
  );

  return (
    <ActionsContext.Provider value={actionsContextValue}>
      <IssueListContext.Provider value={issueContext}>
        <Field
          {...props}
          disabled={disabled}
          onBlur={handleBlur}
          warn={warn}
          validate={validate}
          format={props.type === 'momentdate' ? formatToMoment : props.format}
          parse={props.type === 'momentdate' ? parseMomentToDate : props.parse}
          components={components}
        />
      </IssueListContext.Provider>
    </ActionsContext.Provider>
  );
};

export default FieldUpdatable;
