//
import * as R from 'ramda';
import { REFRESH_ENTITY } from '../actionTypes/request';
import { generateIdLens, reducerFactoryCreator } from '../base';

const refreshEntityRequestFactory = (entityKey) => {
  const innerReducer = () => {
    return {
      errors: null,
      errorMessage: null,
      failed: false,
      loaded: false,
      loading: false,
      loadedPages: 0,
      hasMorePages: undefined,
    };
  };

  /// TODO: remove duplication from fetchRequestReducerFactory
  const reducerFactory = reducerFactoryCreator(innerReducer);
  const queryHashStateReducer = reducerFactory((innerAction) => {
    const generalLens = R.lensPath([
      'searchHistory',
      innerAction.payload.queryHash,
    ]);
    if (innerAction.payload.id) {
      return R.compose(generateIdLens(innerAction.payload.id), generalLens);
    }
    return generalLens;
  });
  const genericReducer = reducerFactory();
  const singleIdReducer = reducerFactory((innerAction) =>
    generateIdLens(innerAction.payload.id)
  );
  return (state, action) => {
    // If not right action return state

    if (
      action.type !== REFRESH_ENTITY ||
      !R.equals(action.payload.entityKey)(entityKey)
    )
      return state;

    const { id, queryHash } = action.payload;

    if (queryHash) {
      return queryHashStateReducer(state, action);
    }
    if (id) {
      return singleIdReducer(state, action);
    }

    return genericReducer(state, action);
  };
};

export default refreshEntityRequestFactory;
