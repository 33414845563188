import { combineReducers, ReducersMapObject } from 'redux';

type CombineReducers = typeof combineReducers;

const combineEntityReducers: CombineReducers = (
  reducers: ReducersMapObject<Record<string, unknown>>
) => {
  const reducerKeys = Object.keys(reducers);

  return function combination(
    state: Record<string, unknown> = {},
    action: any
  ) {
    let hasChanged = false;
    const nextState = {};
    for (let i = 0; i < reducerKeys.length; i += 1) {
      const key = reducerKeys[i];
      const reducer = reducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(previousStateForKey, action);
      // TODO figure out why failes
      // @ts-ignore
      nextState[key] = nextStateForKey;
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
    }
    return hasChanged ? { ...state, ...nextState } : state;
  };
};

export default combineEntityReducers;
