import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, uniq } from 'ramda';
import { makeMissingRequestSelector } from './selectors';
import * as actions from '../../actionTypes/request';

export function useMissing({ missing, passive, meta = {} }) {
  const dispatch = useDispatch();
  const selectFetchRequest = useMemo(() => makeMissingRequestSelector(), []);
  const [wasLoading, setWasLoading] = useState();
  const [wasLoaded, setWasLoaded] = useState();

  const missingQueries = useMemo(
    () =>
      missing
        ? map((data) => {
            const query = {
              ...(data.query || {}),
              'filter.ids': uniq(data.map(({ id }) => id)).join(','),
            };

            return {
              entity: data[0].entity,
              query,
              queryHash: JSON.stringify(query),
            };
          }, missing)
        : {},
    [missing]
  );

  const queryStates = useSelector((state) =>
    selectFetchRequest(state, missingQueries)
  );

  const loaded = useMemo(
    () =>
      Object.keys(missingQueries).reduce((acc, entity) => {
        return acc && queryStates[entity]?.loaded;
      }, true),
    [queryStates]
  );

  const failed = useMemo(
    () =>
      Object.keys(missingQueries).reduce((acc, entity) => {
        return acc || queryStates[entity]?.failed;
      }, false),
    [queryStates]
  );

  const loading = useMemo(
    () =>
      Object.keys(missingQueries).reduce((acc, entity) => {
        return acc || queryStates[entity]?.loading;
      }, false),
    [queryStates]
  );

  useEffect(() => {
    if (missing && !passive) {
      Object.keys(missingQueries).forEach((entity) => {
        const { query, queryHash } = missingQueries[entity];
        const qReq = queryStates[entity] || {
          loaded: false,
          loading: false,
          failed: false,
        };
        if (!qReq.loading && !qReq.loaded && !qReq.failed) {
          dispatch(
            actions.loadEntity(
              entity,
              {
                query,
                queryHash,
              },
              { collectMissing: true, _missing: missing, ...meta }
            )
          );
        }
      });
    }
  }, [missing, passive]);

  useEffect(() => {
    if (!wasLoading && loading) {
      setWasLoading(true);
    }
  }, [loading]);

  useEffect(() => {
    if (wasLoading && loaded) {
      setWasLoaded(true);
    }
  }, [wasLoading, loaded]);

  return {
    failed,
    pause: missing && wasLoading && !wasLoaded && !failed,
    loading: loading && !wasLoaded,
    loaded: loaded || wasLoaded,
  };
}
