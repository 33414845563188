import styled from '@emotion/styled';
import type { Theme, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Content } from 'imdui';
import BundleStatusButton from '../../../BundleStatusButton';

export const StyledCell = styled.div`
  position: relative;
`;

export const smallBundleStatusButtonStyle = css`
  ${BundleStatusButton.smallStyle};
  position: absolute;
  right: 8px;
  bottom: 68px;
  margin-left: 8px;
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
`;

export const linkStyle = (theme: Theme): SerializedStyles => css`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  font-weight: 400;

  > div {
    transition: box-shadow 0.1s ease-in-out;
  }

  :hover {
    text-decoration: none;
    ${HoverOverlay} {
      box-shadow: inset 0 0 0 2px ${theme.foreground.primary};
      transition: box-shadow 0.1s ease-in-out;
    }
  }

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 4px;
  }

  > ${Content} {
    margin-top: 12px;
    margin-bottom: 4px;
  }
`;
