//
import * as R from 'ramda';
// Create reducer that can update state on specific path
// that is created by using ramda lenses
// or just merge the state.
// Used mostly for setting generic state for each entity item
// e.g request state for each separatly loaded track (see fetchRequestReducerFactory)
const reducerFactoryCreator =
  (reducer) => (createLens) => (state, innerAction) => {
    if (createLens) {
      const lens = createLens(innerAction); // Create path based on incomming action
      const updatedItem = R.view(lens, state) || {};
      const newItemState = R.mergeRight(
        updatedItem, // Either merge with existing state or create a new one
        reducer(state, innerAction, updatedItem)
      );
      return R.set(lens, newItemState, state);
    }
    return R.mergeRight(state, reducer(state, innerAction));
  };
export default reducerFactoryCreator;
