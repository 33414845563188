import React, { useCallback } from 'react';
import cx from 'classnames';
import { Checkbox } from 'imdui';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const repeatCandybar = keyframes`
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const DataRowContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 0px;
  padding: 0 32px;
  user-select: none;
  overflow: hidden;
  transition: background-color 0.1s;
  will-change: background-color;
  &:hover {
    background-color: #e5e5e5;
  }
  & > * {
    z-index: 1;
  }
`;

const ControlsSection = styled.div`
  display: flex;
  margin-right: 20px;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const ProgressLineStyled = styled.div<{ progress: number }>`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  overflow: hidden;
  width: calc(${({ progress }) => progress}%);

  & > div {
    width: calc(100% + 50px);
    height: 100%;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDUwIDUwIj48cGF0aCBkPSJNMjUgMEw1MCAwIDI1IDUwIDAgNTBaIiBmaWxsPSIjZDhkOGQ4Ii8+PC9zdmc+);
    animation: ${repeatCandybar} 1s linear infinite;
    will-change: transform;
  }
`;

const InvalidIndicator = styled.div`
  position: absolute;
  top: 5px;
  left: 4px;
  height: 40px;
  width: 2px;
  border-radius: 1px;
  background-color: red;
`;

export const ProgressLine = ({
  progress,
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
  progress: number;
}) => {
  return (
    <ProgressLineStyled style={style} className={className} progress={progress}>
      <div />
    </ProgressLineStyled>
  );
};

export type Props = {
  testId?: string;
  controls?: React.ReactNode;
  data?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  valid?: boolean;
  verifying?: boolean;
  hideCheckbox?: boolean;
  number?: number;
  progressUploading?: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onCheck: React.MouseEventHandler<HTMLInputElement>;
  className?: string;
  style?: React.CSSProperties;
};

export default function DataRow({
  testId,
  controls,
  data,
  checked = false,
  disabled = false,
  valid = true,
  verifying = false,
  progressUploading = 0,
  onCheck,
  onClick,
  className,
  style,
  hideCheckbox,
}: Props): React.ReactElement {
  const handleCheck = useCallback(
    (e) => {
      e.stopPropagation();
      onCheck(e);
    },
    [onCheck]
  );

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClick(e);
    },
    [onClick]
  );

  const progress = verifying ? 100 : Math.floor(progressUploading);

  return (
    <DataRowContainer
      data-test-id={testId}
      className={cx(className, disabled && 'disabled')}
      style={style}
      onClick={handleClick}
      onKeyPress={handleClick}
    >
      {!!progress && <ProgressLine progress={progress} />}

      {!valid && <InvalidIndicator />}

      <ControlsSection>
        {!hideCheckbox && (
          <Checkbox
            name="datarow-check"
            checked={checked}
            onCheck={handleCheck}
          />
        )}

        {controls}
      </ControlsSection>

      {data}
    </DataRowContainer>
  );
}
