import {
  Window,
  HelpWindowContext,
  Body,
  IconButton,
  ComponentIcons,
  Card,
  Button,
} from 'imdui';
import { useContext, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WindowWrapper } from './WindowWrapper';
import styled from '@emotion/styled';
import { ArtistDataRow, DataRowListRenderer } from 'components/molecules';
import type { ArtistNested, EntityModels } from 'imddata';
import { useArtists } from 'imddata';

const Message = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 24px;
`;

const ActionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;
`;

export const ExceededPublishedLimitDialog = ({
  limit,
  publish,
  loading,
  exceededLimit,
  artistPagesToResolve,
  isOpen,
  onClose,
}: {
  limit: number;
  exceededLimit: number;
  artistPagesToResolve: EntityModels.ArtistPage[];
  loading?: boolean;
  publish?: (ids: string[]) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const showHelp = useContext(HelpWindowContext);
  const canSelectArtists = limit > 1;

  const artistIds = useMemo(
    () => artistPagesToResolve.map((ap) => ap.artistId),
    [artistPagesToResolve]
  );

  // TODO: prob move higher in the editor
  const {
    entries,
    loadMore,
    request: { loaded: loadedArtists },
  } = useArtists({ passive: !artistIds.length, ids: artistIds });
  const [selected, setSelected] = useState<(string | number)[]>([]);

  const onChangeSelection = useCallback(
    (_e, id: string | number, checked: boolean) => {
      if (checked) {
        setSelected([...selected, id]);
      } else {
        setSelected(selected.filter((s) => s != id));
      }
    },
    [selected]
  );
  const isDisabledRow = useCallback(
    ({ id }) => {
      return (
        !canSelectArtists ||
        (selected.length >= exceededLimit && selected.indexOf(id) < 0)
      );
    },
    [canSelectArtists, selected.length, limit, exceededLimit]
  );
  const allSelected = useMemo(() => entries.map((e) => e.id), [entries]);
  return (
    <Window isOpen={isOpen} close={onClose} title={t('publish-page')}>
      <WindowWrapper>
        <Message>
          <div style={{ marginTop: '8px' }}>
            <Body>
              {t('you-have-reached-artist-pages-limit', {
                count: limit,
                defaultValue: 'you have reached limit of {{limit}}',
                exceededLimit,
                limit,
              })}
            </Body>
            <br />
            <br />
            <Body>
              {canSelectArtists
                ? t('select-artists-to-unpublish', {
                    count: exceededLimit,
                    exceededLimit,
                    defaultValue:
                      'select {{exceededLimit}} artists to unpublish',
                  })
                : t('those-artist-pages-will-be-unpublished', {
                    count: exceededLimit,
                  })}
            </Body>
          </div>
          <IconButton
            onClick={() => {
              showHelp(
                t('artist-page-limits'),
                t('artist-page-limits-helptext')
              );
            }}
          >
            <ComponentIcons.HelpIcon />
          </IconButton>
        </Message>
        <Card>
          <DataRowListRenderer<ArtistNested>
            isDisabled={isDisabledRow}
            selected={canSelectArtists ? selected : allSelected}
            virtualized={false}
            onCheckEntry={onChangeSelection}
            loading={!loadedArtists}
            hasNextPage={false}
            data={entries}
            rowRenderer={ArtistDataRow}
            loadNextPage={loadMore}
          />
        </Card>
        <ActionWrapper>
          <Button text={t('cancel')} position="center" onClick={onClose} />
          <Button
            primary={true}
            position="center"
            disabled={
              !loadedArtists &&
              canSelectArtists &&
              exceededLimit > selected.length
            }
            text={t('publish')}
            showLoading={loading}
            onClick={() => {
              if (publish) {
                const ids = canSelectArtists ? selected : allSelected;
                publish(
                  artistPagesToResolve
                    .filter((ap) => ids.indexOf(ap.artistId) >= 0)
                    .map((ap) => ap.id)
                );
              }
            }}
          />
        </ActionWrapper>
      </WindowWrapper>
    </Window>
  );
};
