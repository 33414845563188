import { pickBy, omit, isNil } from 'ramda';
import type { Query } from '../types';

const filterQuery = (q: Query): Record<string, string | number | boolean> =>
  pickBy(
    (val) => !isNil(val) && val !== '',
    omit(['perPage', 'page', 'with', 'by'], q)
  );

export default filterQuery;
