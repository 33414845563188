import { useEntries, useEntry } from '../hooks';
import type { ArtistNested } from './nests';
import { defaultArtistNest } from './nests';

export function useArtist({
  nest = defaultArtistNest,
  ...props
}: Omit<Parameters<typeof useEntry>[0], 'entity'>) {
  return useEntry<ArtistNested>({
    entity: 'artists',
    nest,
    ...props,
  });
}

export function useArtists({
  nest = defaultArtistNest,
  ...props
}: Omit<Parameters<typeof useEntries>[0], 'entity'>) {
  return useEntries<ArtistNested>({
    entity: 'artists',
    nest,
    ...props,
  });
}
