import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityFormContext, usePublisher, useUpdateEntity } from 'imddata';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Form } from 'redux-form';
import { Centered, FieldGroup, LoadingIndicator } from 'imdui';
import FieldUpdatable from '../../../fields/FieldUpdatable';
import NewInputField from '../../../fields/InputField';

type Props = {
  id: number;
};

type FormData = {
  name: string;
};
const SingleEditForm = ({
  id,
  form,
}: Props & InjectedFormProps<FormData, Props>) => {
  const { t } = useTranslation();

  const { updateEntry } = useUpdateEntity({ entity: 'publishers' });

  const handleName = (event: any) => {
    updateEntry(
      {
        id,
        formId: form,
        data: { name: event.target.value },
      },
      {
        debounce: true,
      }
    );
  };

  const value = useMemo<React.ContextType<typeof EntityFormContext>>(
    () => ({ entity: 'publishers', id }),
    [id]
  );

  return (
    <EntityFormContext.Provider value={value}>
      <Form data-test-id="publisher-single-edit-form">
        <FieldGroup>
          <FieldUpdatable
            name="name"
            component={NewInputField}
            onChange={handleName}
            label={t('publisher-name')}
          />
        </FieldGroup>
      </Form>
    </EntityFormContext.Provider>
  );
};

export const validatePublisherForm = (values: FormData): any => {
  const errors: { name?: string } = {};
  if (!values.name) {
    errors.name = 'required';
  }
  if (values.name && values.name === values.name.toUpperCase()) {
    errors.name = 'publisher-all-caps-not-allowed';
  }
  return errors;
};

const PublisherEditReduxForm = reduxForm<FormData, Props>({
  getFormState: (state) => state.form,
  validate: validatePublisherForm,
})(SingleEditForm);

const PublisherEditReduxFormWithData: React.FC<Props & { form: string }> = ({
  form,
  id,
  ...props
}) => {
  const {
    entry,
    request: { loaded },
  } = usePublisher({ id });
  const formId = form;
  const initialValues = useMemo(
    () => ({
      name: entry?.name || '',
    }),
    [entry?.name]
  );
  if (!loaded) {
    return (
      <Centered>
        <LoadingIndicator />
      </Centered>
    );
  }
  return (
    <PublisherEditReduxForm
      id={id}
      key={formId}
      form={formId}
      initialValues={initialValues}
      {...props}
    />
  );
};

export default PublisherEditReduxFormWithData;
