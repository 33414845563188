import { useUserNotifications } from 'imddata';

type Props = {
  children: (unreadCount: number) => JSX.Element;
};

const NotificationsCountProvider: React.FC<Props> = ({ children }) => {
  const { unreadCount } = useUserNotifications();
  return children(unreadCount);
};

export default NotificationsCountProvider;
