//
// Select most recent revision of an name and save older ones under revisions key
import { omit } from 'ramda';

const processNames = (value) => {
  let names = {};
  if (value.names) {
    names = {
      names: value.names.reduce(
        (acc, name) => {
          if (acc[name.languageId]) {
            // check if saved revision is newer
            if (acc[name.languageId].revision > name.revision) {
              return {
                ...acc,
                [name.languageId]: {
                  ...acc[name.languageId],
                  revisions: acc[name.languageId].revisions
                    ? [...acc[name.languageId].revisions, name]
                    : [name],
                },
              };
            }
            return {
              ...acc,
              [name.languageId]: {
                ...name,
                revisions: acc[name.languageId].revisions
                  ? [
                      ...acc[name.languageId].revisions,
                      acc[name.languageId],
                    ].sort((a, b) => a.revision - b.revision)
                  : [acc[name.languageId]],
              },
            };
          }
          return {
            ...acc,
            [name.languageId]: name,
          };
        },
        {
          default: value.defaultName,
        }
      ),
    };
  }
  const processedNamesArray = Object.values(omit(['default'], names.names));
  return {
    ...value,
    defaultNameNormalized: value.defaultName,
    ...(processedNamesArray.length
      ? {
          formNames: processedNamesArray,
          namesNormalized: processedNamesArray,
        }
      : {}),
    ...names,
  };
};

export default processNames;
