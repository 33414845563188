import cx from 'classnames';
import React, { forwardRef, useCallback, useState } from 'react';
import { Body } from '../../../text';
import { IconsCollection } from '../../icon';
import { Wrapper, CheckboxWrapper, StyledInput, StyledIcon } from './styles';

type Props = React.ComponentProps<'input'> & {
  onCheck: (
    e:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | React.ChangeEvent<HTMLInputElement>,
    state: boolean
  ) => void;
  subchecked?: boolean;
  text?: string | React.ReactNode;
  testId?: string;
  name: string;
};

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      testId = 'checkbox',
      checked = false,
      subchecked = false,
      onCheck = undefined,
      text,
      tabIndex = 0,
      disabled = false,
      className,
      style,
      onBlur,
      onFocus,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (onCheck && !disabled) {
          onCheck(e, !checked);
        }
      },
      [checked, onCheck, disabled]
    );

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onCheck && !disabled) {
          onCheck(e, !checked);
        }
      },
      [checked, onCheck, disabled]
    );

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onCheck && !disabled) {
          onCheck(e, !checked);
        }
      },
      [checked, onCheck, disabled]
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        if (onFocus) {
          onFocus(e);
        }
      },
      [focused, onFocus, disabled]
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        if (onBlur) {
          onBlur(e);
        }
      },
      [focused, onBlur, disabled]
    );

    return (
      <Wrapper
        data-test-id={testId}
        className={cx(className, disabled && 'disabled')}
        style={style}
        onClick={handleClick}
      >
        <CheckboxWrapper focused={focused}>
          <StyledInput
            ref={ref}
            data-test-id="Checkbox-box"
            tabIndex={disabled ? -1 : tabIndex}
            type="checkbox"
            role="checkbox"
            aria-checked={checked}
            aria-labelledby={props.name}
            checked={checked}
            subchecked={subchecked}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            {...props}
          />

          {(checked || subchecked) && (
            <StyledIcon
              d={
                checked ? IconsCollection.checkSmall : IconsCollection.subcheck
              }
              checked={checked}
              subchecked={subchecked}
            />
          )}
        </CheckboxWrapper>

        {typeof text === 'string' ? <Body>{text}</Body> : text}
      </Wrapper>
    );
  }
);

Checkbox.displayName = 'CheckboxWithRef';

export default Checkbox;
