/* eslint-disable react/display-name */
import React, { memo, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import {
  useOverview,
  useUpdateRequestProvider,
  useFetchRequestProvider,
  useProductPrice,
} from 'imddata';
import type { RedirectProps, RouteComponentProps } from 'react-router-dom';
import { Redirect, useRouteMatch } from 'react-router-dom';
import {
  useProductPageContext,
  ProductLoader,
  useProductPage,
  useProductPageActions,
} from 'components';
import type { ProductStepState as StepState } from 'redux/reducers/ui/products';
import ProductPriceContext from '../ProductPriceContext';
import ProductScreen from '../ProductScreen';
import Stepper from '../Stepper';
import NavBar from '../NavBar';

export * from 'components/logic/productPageHooks/index';

const ProductStep: React.FC<{
  match: RouteComponentProps['match'];
  nextStep?: StepState;
  step: StepState;
  previousStep?: StepState;
}> = memo(
  ({ match, step, nextStep, previousStep }) => {
    const stepId = step.id;
    const { StepRenderer, NavBarRenderer = NavBar } = useProductPage();

    const { changeStep } = useProductPageActions();

    useEffect(() => {
      if (step && !step.visited) {
        if (window) {
          window.scrollTo({
            top: 0,
          });
        }
        changeStep({
          stepId,
          payload: { visited: true },
        });
      }
    }, []);

    const previousUrl = previousStep
      ? `${match.url}/${previousStep.id}`
      : undefined;
    const nextUrl = nextStep ? `${match.url}/${nextStep.id}` : undefined;

    return (
      <>
        <StepRenderer
          match={match}
          stepId={stepId}
          previousUrl={previousUrl}
          nextUrl={nextUrl}
        />

        <NavBarRenderer
          stepId={stepId}
          key="nav"
          previous={previousUrl}
          previousText={previousStep?.label}
          next={nextUrl}
          nextText={nextStep?.label}
        />
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.match === nextProps.match &&
    prevProps.step.visited === nextProps.step.visited &&
    prevProps.nextStep?.id === nextProps?.nextStep?.id &&
    prevProps.previousStep?.id === nextProps?.previousStep?.id
);

const defaultManager = () => {
  return null;
};

const defaultRedirector = ({
  defaultFallbackRoute,
}: {
  defaultFallbackRoute: RedirectProps['to'];
}) => {
  return <Redirect to={defaultFallbackRoute} />;
};

export const getOverviewItemPrice = (oi: EntityModels.OverviewGroupItem) =>
  Number(oi.discountedValue) +
  Number(oi.discounts.find((oid) => oid.type === 'PROMO_CREDIT')?.value || 0);

export const ProductPriceProvider: React.FC = ({ children }) => {
  const { entity, overviewEntity, id } = useProductPageContext();
  const {
    state: {
      data: { advancedSupportPurchase },
    },
  } = useProductPage();
  const { updated } = useUpdateRequestProvider({
    entity,
    id,
  });
  const supportPrice = useProductPrice('customer-support');

  if (!overviewEntity) {
    // TODO: remove overviewEntity from product def context
    throw new Error('Attempt to use PriceProvider without overviewEntity');
  }

  const {
    overview,
    refresh,
    request: { loading, failed },
  } = useOverview({
    entity: overviewEntity,
    componentKey: `${overviewEntity}-${id}`,
    id,
  });

  const ampDiscount = overview?.discounts.find(
    (d) => d.fullType === 'subscription_credit'
  );

  useEffect(() => {
    if (updated && !loading) {
      refresh();
    }
  }, [updated]);

  const overviewItems = useMemo(
    () =>
      overview
        ? Object.keys(overview.groups).reduce<
            EntityModels.OverviewGroup['items']
          >((acc, groupKey) => {
            const group = overview.groups[groupKey];
            return [...acc, ...group.items];
          }, [])
        : [],
    [overview]
  );

  const total =
    overviewItems.reduce((acc, oi) => acc + getOverviewItemPrice(oi), 0) +
    (advancedSupportPurchase ? Number(supportPrice.price?.price) : 0);

  const contextValue = useMemo(() => {
    return overview
      ? {
          recalculate: refresh,
          price: `${total}`,
          ampDiscount: ampDiscount?.discountableAmps,
          priceFormat: overview.currency?.format,
          priceCurrency: overview.currency?.id,
          calculating: !!loading,
          error: failed,
          overview,
        }
      : { recalculate: refresh, calculating: true };
  }, [overview, total, refresh, loading, failed]);

  return (
    <ProductPriceContext.Provider value={contextValue}>
      {children}
    </ProductPriceContext.Provider>
  );
};

type WizardProps = Omit<
  React.ComponentProps<typeof ProductScreen>,
  'title' | 'children'
> &
  RouteComponentProps & { loading: boolean };

export const ProductWizard: React.FC<WizardProps> = ({
  match,
  type,
  loading,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    title,
    entity,
    steps,
    id,
    state,
    RedirectManager = defaultRedirector,
    StepperStateManager = defaultManager,
  } = useProductPage();

  const { loaded, failed } = useFetchRequestProvider({
    entity,
    id,
  });

  const stepper = useMemo(
    () =>
      props.stepper || (
        <Stepper
          match={match}
          steps={steps?.map((step) => ({
            ...step,
            ...(state.steps.find((stateStep) => stateStep.id === step.id) ||
              {}),
            label: step.label || step.id,
            to: step.id,
          }))}
        />
      ),
    [steps, match, props.stepper, state?.steps]
  );

  const stepMatch = useRouteMatch<{ stepId: string }>({
    exact: true,
    path: `${match.path}/:stepId`,
  });

  const fallback = `${match.url}/${steps[0].id}`;

  const { stepId: stepRoute } = stepMatch?.params || { stepId: undefined };
  const stepIndex = steps.findIndex((step) => stepRoute === step.id);
  const step = steps[stepIndex];
  const stepId = step?.id;
  const nextStep = steps[stepIndex + 1];
  const previousStep = stepIndex >= 1 ? steps[stepIndex - 1] : undefined;

  if (failed) {
    return <Redirect to="/dashboard" />;
  }

  if (!step || !loaded || loading) {
    return (
      <>
        <ProductScreen
          hidePrice={state?.data?.hidePrice}
          title={t(title)}
          type={type}
          stepper={stepper}
        >
          <ProductLoader />
        </ProductScreen>

        {loaded && !stepId && (
          <RedirectManager
            match={match}
            defaultFallbackRoute={fallback}
            id={id}
          />
        )}
      </>
    );
  }

  return (
    <ProductScreen
      hidePrice={state?.data?.hidePrice}
      type={type}
      title={t(title)}
      stepper={stepper}
      {...props}
    >
      <StepperStateManager currentStepId={step.id} />

      <ProductStep
        nextStep={nextStep}
        previousStep={previousStep}
        step={step}
        match={match}
      />
    </ProductScreen>
  );
};
