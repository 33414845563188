import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useIsAdmin } from 'imddata';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Window,
  H3,
  TextFormatted,
  LinkButton,
  Button,
  Icon,
  IconsCollection,
} from 'imdui';

type Props = {
  count: number;
};

const contentStyle = css`
  padding: 24px 32px;
  text-align: center;
`;

const titleStyle = css`
  margin: 24px 0 8px;
`;

const buttonRowStyle = css`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  > button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const FieldUpdateRequestWindowPopup: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation();

  const isAdmin = useIsAdmin();

  const furMatch = useRouteMatch('/update-requests');

  const [open, setOpen] = useState(() => !furMatch && !isAdmin && count > 0);

  const handleResolveButton = () => {
    setOpen(false);
  };

  return (
    <Window
      lockClickOutside={true}
      isOpen={open}
      title={t('action-required')}
      hideTitleBar={true}
    >
      <div css={contentStyle}>
        <Icon d={IconsCollection.responseFeedback} size={64} />

        <H3 css={titleStyle}>{t('action-required')}</H3>

        <TextFormatted text={t('you-have-unresolved-issues')} />

        <div css={buttonRowStyle}>
          <LinkButton
            onClick={() => {
              setOpen(false);
            }}
            primary={true}
            to="/update-requests"
            text={t('resolve-issue', { count })}
          />

          <Button text={t('resolve-later')} onClick={handleResolveButton} />
        </div>
      </div>
    </Window>
  );
};
