import React, { useMemo, useCallback } from 'react';
import type { PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import styled from '@emotion/styled';
import { Group } from '@visx/group';
import { useCurrentLocale } from 'imdshared';
import { Caption, ContentSmall } from 'imdui';
import { LegendItem } from './LegendItem';

// accessor functions

// color scales

const MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

const Wrapper = styled.div`
  padding: 8px 24px;
  height: 100%;
  display: grid;
  column-gap: 24px;
  grid-template-columns: 112px calc(100% - 112px);
  grid-template-rows: auto auto;
  align-items: center;
  align-self: center;
`;

const LegendGrid = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: max-content max-content;
  align-items: center;
  grid-auto-rows: minmax(40px, max-content);
`;

const ChartCaption = styled(Caption)`
  grid-column-start: span 2;
  align-self: end;
  margin-bottom: 16px;
`;

type Datum = {
  value: number;
  id: string;
  label: string;
};

type Data = Array<Datum>;

type PieProps = {
  width: number;
  height: number;
  data: Data;
  colorScaleConfig: {
    domain: string[];
    range: string[];
  };
  caption?: string;
  disabled?: boolean;
};

const pieValue = (d: Datum) => d.value;
const pieSort = (a: Datum, b: Datum) => a.value - b.value;

const disabledData = [
  { label: '', id: '1', value: 1 },
  { label: '', id: '2', value: 2 },
  { label: '', id: '3', value: 3 },
];
const disabledColorScale = {
  domain: ['3', '2', '1'],
  range: ['#ccc', '#e8e8e8', '#f2f2f2'],
};

export default function DonutChart({
  disabled,
  width,
  height,
  colorScaleConfig,
  data: propsData,
  caption,
}: PieProps) {
  if (width < 10) return null;

  const data = disabled || !propsData ? disabledData : propsData;
  const innerWidth = width - MARGIN.left - MARGIN.right;
  const innerHeight = height - MARGIN.top - MARGIN.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 16;

  const total = data.reduce((acc, v) => acc + v.value, 0);

  const notAvailable = total === 0 || disabled;

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>(
        notAvailable ? disabledColorScale : colorScaleConfig
      ),
    [notAvailable]
  );
  const fill = useCallback(
    (d: PieArcDatum<Datum>) => {
      return colorScale(d.data.id);
    },
    [colorScale]
  );

  const locale = useCurrentLocale();
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }),
    [locale]
  );
  return (
    <Wrapper>
      <div>
        <svg width={width} height={height}>
          <Group top={centerY + MARGIN.top} left={centerX + MARGIN.left}>
            <Pie<Datum>
              fill={fill}
              startAngle={-6}
              data={data}
              pieSort={pieSort}
              pieValue={pieValue}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={0}
            />
          </Group>
        </svg>
      </div>
      <LegendGrid>
        {data.map(({ id, value, label }) => (
          <>
            <LegendItem fill={colorScale(id)} label={label} />
            <ContentSmall>
              {notAvailable ? '' : formatter.format(value / total)}
            </ContentSmall>
          </>
        ))}
      </LegendGrid>

      <ChartCaption>{notAvailable ? '' : caption}</ChartCaption>
    </Wrapper>
  );
}
