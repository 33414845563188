import React, { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, clearSubmitErrors, Form, Field } from 'redux-form';
import { Caption, Button, Body, NewInputField } from 'imdshared';
import type { EntityModels } from 'imddata';
import { useEntryProvider, useCreateEntity } from 'imddata';
import { useDispatch } from 'react-redux';

const form = 'registerVoucher';

const StyledInput = styled(NewInputField)`
  margin-right: 8px;
  width: 192px;
`;

const Wrapper = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  ${Body} {
    width: 160px;
    color: ${(props) => props.theme.foreground.secondary};
    margin: 8px 16px 8px 0;
  }
`;

// TODO: Remove duplication with VoucherDropdown
const DropdownCaption = styled(Caption)`
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 16px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
`;

type FormData = {
  code: string;
};

type Props = {
  onRegisterVoucher: (v: EntityModels.CustomerVoucher) => void;
};

const RegisterVoucherForm = ({
  submitting,
  handleSubmit,
  error,
  onRegisterVoucher,
}: InjectedFormProps<FormData, Props> & Props) => {
  const { createEntry, createdId } = useCreateEntity({
    entity: 'customerVouchers',
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const customerVoucher = useEntryProvider<EntityModels.CustomerVoucher>({
    entity: 'customerVouchers',
    id: createdId,
  });

  useEffect(() => {
    if (createdId && customerVoucher) {
      onRegisterVoucher(customerVoucher);
    }
  }, [createdId]);

  const submit = useCallback(
    ({ code }) => {
      createEntry({
        data: { code },
        formId: form,
      });
    },
    [createEntry]
  );

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Wrapper>
        <DropdownCaption>{t('new-voucher')}</DropdownCaption>
        <FlexRow>
          <div>
            <Field
              autoFocus={true}
              name="code"
              acceptValidation={false}
              // placeholder={t('placeholder-voucher-code')}
              // helperText={t('must-be-4-characters-at-least')}
              errorText={error}
              component={StyledInput}
              onChange={() => {
                if (error) {
                  dispatch(clearSubmitErrors(form));
                }
              }}
            />
          </div>

          <div>
            <Button
              css={css({ width: 128, justifyContent: 'center' })}
              data-test-id="add-new-voucher"
              type="submit"
              showLoading={submitting}
              disabled={submitting}
              text={t('add')}
            />
          </div>
        </FlexRow>
      </Wrapper>
    </Form>
  );
};

export default reduxForm<FormData, Props>({
  form,
})(RegisterVoucherForm);
