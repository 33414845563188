import React from 'react';
import type { EntityModels } from 'imddata';
import { useQuery, useCurrentCustomer, useEntries } from 'imddata';
import config from 'imdconfig';
import { useTranslation } from 'react-i18next';
import { AffiliateReferralTemplate } from '../../shared';

const Referral = () => {
  const { entry: customer } = useCurrentCustomer();
  const { query, queryHash } = useQuery({
    query: {
      perPage: 5,
      'filter.operation': 'referrer_reward',
    },
  });
  const {
    loadMore,
    entries,
    request: { hasMorePages, loading },
  } = useEntries<EntityModels.Transaction>({
    entity: 'transactions',
    query,
    queryHash,
  });
  const { t } = useTranslation();

  if (!customer) return null;

  const {
    referralSignups,
    referralSignupReward,
    referralRevenue,
    referralToken,
    referralReward,
  } = customer;

  const linkReady =
    typeof referralToken === 'string' && referralToken.length > 0;

  const link = linkReady
    ? `${config.imdfront.appUrl}/signup?referral_token=${referralToken}`
    : t('creating-referral-link');

  const translationPrefix = 'referral';

  return (
    <AffiliateReferralTemplate
      testId="Referral"
      transactions={entries}
      referralReward={referralReward}
      referralSignupReward={referralSignupReward}
      loadingTransactions={loading}
      hasMoreTransactions={!!hasMorePages}
      loadNextTransactions={loadMore}
      totalRevenue={referralRevenue}
      tokenUsedCount={referralSignups}
      translationPrefix={translationPrefix}
      linkReady={linkReady}
      link={link}
    />
  );
};

export default Referral;
