// @ts-nocheck
/* eslint max-classes-per-file: 0 */
import React, { Component } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  Button,
  CenterWrapper,
  NewInputField,
  ToggleSwitchField,
  Checkbox,
  Content,
  InputField,
} from 'imdshared';
import type { ReduxState } from 'imddata';
import { useSettings } from 'imddata';
import { ErrorBoundary, BlockTemplate } from 'components';
import { actions } from 'imddata/actionTypes/dev';
import AppCrash from '../AppCrash';

const Grid = styled.div`
  padding: 48px 0;
  display: grid;
  grid-template-columns: minmax(400px, 1fr) minmax(400px, 1fr);
  gap: 24px;
`;
//
// class ThrowOnMount extends Component {
//   componentDidMount() {
//     throw new Error('Sentry Test');
//   }
//
//   render() {
//     return null;
//   }
// }

// class TestReactErrorHandler extends Component {
//   state = {
//     renderError: false,
//   };
//
//   render() {
//     return (
//       <div style={{ padding: '16px 0 0 0' }}>
//         <Button
//           text="Test React Error Handler"
//           onClick={() => {
//             this.setState({
//               // Setting inconsistent state to trigger ErrorBoundary
//               renderError: true,
//             });
//           }}
//         />
//         {this.state.renderError && <ThrowOnMount />}
//       </div>
//     );
//   }
// }

const FeatureList = ({ fields }) => {
  return (
    <>
      {fields.length === 0 ? (
        <div style={{ marginBottom: '8px' }}>No Overrides Enabled</div>
      ) : (
        ''
      )}
      <div>
        {fields.map((entry, index) => (
          <div
            style={{
              marginBottom: '8px',
              alignItems: 'center',
              background: '#f5f5f5',
              borderRadius: '8px',
              gap: '8px',
              padding: '8px',
              display: 'flex',
            }}
            key={`press${index}`}
          >
            <Field
              name={`${entry}.name`}
              label="Feature key"
              component={NewInputField}
            />
            <Field
              name={`${entry}.value`}
              text="Override"
              component={ToggleSwitchField}
            />
            <div>
              <Button
                size="small"
                text="Delete"
                onClick={() => fields.remove(index)}
              />
            </div>
          </div>
        ))}
      </div>
      <Button
        size="small"
        text="Add Override"
        onClick={() => {
          fields.push({
            name: '',
            value: true,
          });
        }}
      />
    </>
  );
};
const FeatureOverrideForm = reduxForm({ form: 'dev-feature-override-form' })(({
  handleSubmit,
  onSubmit,
}) => {
  return (
    <div>
      <div
        style={{
          paddingBottom: '16px',
        }}
      >
        <Content>Enable Devmode for overrides to work</Content>
      </div>
      <div style={{ margin: '0 0 16px 0' }}>
        <FieldArray name="features" component={FeatureList} />
      </div>
      <Button
        position="center"
        style={{
          width: '100%',
        }}
        text="Save Feature Set"
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
});

const VarsForm = reduxForm({ form: 'varsform' })(({
  handleSubmit,
  onSubmit,
}) => {
  return (
    <div>
      <div
        style={{
          paddingBottom: '16px',
        }}
      >
        <Content>Enable Devmode for overrides to work</Content>
      </div>
      <div style={{ margin: '0 0 16px 0' }}>
        <Field name="beaconId" label="Beacon ID" component={InputField} />
      </div>
      <Button
        position="center"
        style={{
          width: '100%',
        }}
        text="Save"
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
});

class UpdateSettings extends Component {
  state = { value: '' };

  static getDerivedStateFromProps(newProps, prevState) {
    if (prevState.settings !== newProps.settings) {
      try {
        return {
          settings: newProps.settings,
          value: JSON.stringify(JSON.parse(newProps.settings), null, 2),
        };
      } catch (err) {
        console.log('invalid settings');
      }
    }
    return null;
  }

  render() {
    return (
      <BlockTemplate
        title="Customer Saved Settings"
        action={
          <Button
            small={true}
            onClick={() => {
              try {
                const newValue = JSON.parse(this.state.value);
                this.props.updateSettings(newValue);
              } catch (err) {
                console.log(err);
              }
            }}
            text="update settings"
          />
        }
      >
        <div
          style={{
            paddingBottom: '16px',
          }}
        >
          <Content>Do not touch if do not know what you are doing</Content>
        </div>
        <div
          style={{
            background: '#fff',
            border: '1px solid #888',
          }}
        >
          <textarea
            style={{
              width: '100%',
              height: '100%',
              fontSize: 16,
              minHeight: '150px',
            }}
            value={this.state.value}
            onChange={(event) => {
              this.setState({
                value: event.target.value,
              });
            }}
          />
        </div>
      </BlockTemplate>
    );
  }
}

const Devdebug = ({ dev, toggleDevmode }: any) => {
  const { updateSettings, settings } = useSettings();
  const features = useSelector((state: ReduxState) => state.dev.features);
  const vars = useSelector((state: ReduxState) => state.dev.vars);
  const dispatch = useDispatch();
  const initialValues = {
    features: Object.keys(features).map((fkey) => ({
      name: fkey,
      value: features[fkey],
    })),
  };
  return (
    <ErrorBoundary>
      {(error) => {
        if (error) {
          return <AppCrash error={error} />;
        }
        return (
          <CenterWrapper>
            <Grid>
              <BlockTemplate title="App Settings">
                <code>App commit: {__COMMITHASH__}</code>
                <Checkbox
                  text="Devmode"
                  checked={dev.devmode}
                  onCheck={() => toggleDevmode(!dev.devmode)}
                />
              </BlockTemplate>
              <BlockTemplate title="Optimizely Feature Override">
                <FeatureOverrideForm
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    const newFeatures = values.features
                      ?.filter(({ name }) => !!name)
                      .reduce(
                        (acc, { name, value }) => ({ ...acc, [name]: value }),
                        {}
                      );
                    dispatch(actions.updateFeatures(newFeatures || {}));
                  }}
                />
              </BlockTemplate>

              <BlockTemplate title="App Variable overrides">
                <VarsForm
                  initialValues={vars}
                  onSubmit={(values) => {
                    dispatch(actions.setVars(values || {}));
                  }}
                />
              </BlockTemplate>

              <UpdateSettings
                settings={settings && JSON.stringify(settings)}
                updateSettings={updateSettings}
              />
            </Grid>
          </CenterWrapper>
        );
      }}
    </ErrorBoundary>
  );
};

export default connect((state) => ({ dev: state.dev }), actions)(Devdebug);
