import { useSubscriptionManagement } from 'components';
import type { EntityModels } from 'imddata';
import { useDeleteEntity, useEntriesProvider } from 'imddata';
import { useCallback } from 'react';

export const useWinbackYearly = () => {
  // Assume notifications are loaded elsewhere
  const { deleteEntry } = useDeleteEntity({ entity: 'userNotifications' });
  const { entries: notifications } =
    useEntriesProvider<EntityModels.UserNotification>({
      entity: 'userNotifications',
    });

  const { renewalEnabled, currentSubscription, toggleRenewal } =
    useSubscriptionManagement();

  const winbackYearlyNotification: EntityModels.UserNotification | undefined =
    notifications?.find(
      (n) => n.notification.type === 'yearly-subscription-renewal-disabled'
    );
  const onMarkWasRead = useCallback(() => {
    deleteEntry({ id: winbackYearlyNotification?.id });
  }, [winbackYearlyNotification]);

  return {
    wasRead: !!winbackYearlyNotification?.wasRead,
    onMarkWasRead,
    active:
      !!winbackYearlyNotification && !renewalEnabled && !!currentSubscription,
    toggleRenewal,
    subscriptionId: currentSubscription?.subscriptionId,
  };
};
