import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, BalanceDisplay, PayoutForm } from 'imdshared';
import { ActionCard, BlockTemplate } from 'components';
import { gridGapsCss, LinkButton } from 'imdui';
import {
  useHasUnpaidInvoices,
  useCurrentCustomerBalance,
  usePayoutRequest,
} from 'imddata';
import { RecentPayouts } from './components';

const TableBlock = styled(BlockTemplate)`
  grid-column: 1 / -1;
`;

const BalanceBlock = styled.div``;
const RequestBlock = styled(BlockTemplate)``;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${gridGapsCss}

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    ${RequestBlock} {
      grid-column: span 2;
    }
    ${BalanceBlock} {
      grid-column: span 2;
    }
  }
  @media (min-width: 1600px) {
    ${BalanceBlock} {
      grid-column: span 1;
    }
  }
`;

const BalanceDisplayStyled = styled(BalanceDisplay)``;

const BalanceDisplayConnected = () => {
  const { hasConversion, isUserCustomer, cashCreditValue, promoCreditValue } =
    useCurrentCustomerBalance();

  return (
    <BalanceDisplayStyled
      hasConversion={hasConversion}
      isUserCustomer={isUserCustomer}
      cashCredit={cashCreditValue}
      promoCredit={promoCreditValue}
    />
  );
};

const useExchangeRate = () => {
  const { entry: data } = usePayoutRequest();

  if (!data) {
    return null;
  }
  const {
    localizedCashCreditExchangeRate,
    localizedCashCreditCurrency,
    cashCreditCurrency,
  } = data;
  if (localizedCashCreditCurrency === cashCreditCurrency) {
    return null;
  }
  const exchangeString = `1 ${cashCreditCurrency} = ${localizedCashCreditExchangeRate} ${localizedCashCreditCurrency}`;

  return exchangeString;
};

export default function BalancePage() {
  const { t } = useTranslation();

  const { hasUnpaid } = useHasUnpaidInvoices();
  const exchangeRate = useExchangeRate();
  return (
    <>
      {hasUnpaid && (
        <ActionCard
          title={t('alert-complete-invoices-before-payout')}
          style={{ marginBottom: '24px' }}
        >
          <LinkButton
            size="small"
            text={t('view-invoices')}
            to="/account/invoices"
          />
        </ActionCard>
      )}
      <GridWrapper>
        <ErrorBoundary componentName="PayoutRequestForm">
          <RequestBlock title={t('withdraw-money')} subtitle={exchangeRate}>
            {/*
          // @ts-ignore */}
            <PayoutForm disabled={hasUnpaid} />
          </RequestBlock>
        </ErrorBoundary>
        <BalanceBlock>
          <BlockTemplate title={t('balance-overview')}>
            <BalanceDisplayConnected />
          </BlockTemplate>
        </BalanceBlock>

        <TableBlock padded={false} title={t('recent-payouts')}>
          <ErrorBoundary componentName="Payouts Table">
            <RecentPayouts />
          </ErrorBoundary>
        </TableBlock>
      </GridWrapper>
    </>
  );
}
