import styled from '@emotion/styled';
import config from 'imdconfig';
import { useInvoice } from 'imddata';
import { useFastPaymentFlowContext } from 'imdshared';
import { PageLayer, Centered, LoadingIndicator, Button } from 'imdui';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { PaymentFlow } from 'screens/Order/screens/PaymentGate/components/PaymentFlow';
import { PaymentStatusMessage } from '../SubscribeLayer/PaymentStatusMessage';
import { ProductRating } from 'components';
import { css } from '@emotion/react';

type State = {
  page: 'thank-you' | 'payment';
  invoiceId?: number | string;
};

enum ActionTypes {
  RESTART = 'RESTART',
  START_PAYMENT = 'START_PAYMENT',
  FINISH_FLOW = 'FINISH_FLOW',
}

type Action =
  | {
      type: typeof ActionTypes.START_PAYMENT;
      orderId: number | string;
    }
  | {
      type: typeof ActionTypes.RESTART;
    }
  | {
      type: typeof ActionTypes.FINISH_FLOW;
      invoiceId?: number;
    };

const initialState: State = {
  page: 'payment',
};

type Reducer = (s: State, a: Action) => State;

const pageReducer: Reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.RESTART: {
      return initialState;
    }
    case ActionTypes.FINISH_FLOW: {
      return {
        ...state,
        page: 'thank-you',
        invoiceId: action.invoiceId,
      };
    }
    case ActionTypes.START_PAYMENT:
      return {
        ...state,
        page: 'payment',
      };
    default:
      return state;
  }
};

const ratingStyle = css`
  max-width: 420px;
  margin: 0 auto 64px auto;
`;

const FinishFlowContainer = styled.div`
  text-align: center;
`;

export const FastPaymentLayer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { isOpened, context, open, close } = useFastPaymentFlowContext();
  const [{ page, invoiceId }, dispatch] = useReducer(pageReducer, initialState);

  const { entry: invoice, request: invoiceRequest } = useInvoice({
    id: invoiceId,
    passive: !invoiceId,
  });

  const orderMatch = useRouteMatch<{ product: string }>('/order/:product');

  const product = orderMatch?.params.product;

  const paymentSuccess =
    invoice?.status === 'paid' ||
    context.orderStatus === 'finalized' ||
    context.orderStatus === 'paid';

  const paymentFailed =
    (invoiceId && invoiceRequest.failed) ||
    context.failed ||
    invoice?.status === 'payment_failed';

  const handleClose = useCallback(() => {
    close();
    dispatch({ type: ActionTypes.RESTART });
    if (!paymentSuccess) {
      if (
        invoice &&
        (invoice.status == 'payment_failed' || invoice.status === 'unpaid')
      ) {
        history.push('/account/invoices/' + invoice.id);
        return;
      }
      // Stay in place if we are running isolated order
      if (!context.orderId) {
        history.push('/order/overview');
      }
    }
  }, [invoice, paymentSuccess]);

  const params = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  useEffect(() => {
    if (params.fastCheckout) {
      open(
        params.successPath && typeof params.successPath === 'string'
          ? {
              successPath: params.successPath,
              loading: false,
              orderStatus: undefined,
            }
          : { loading: false, orderStatus: undefined }
      );
    }
  }, [params.fastCheckout]);

  useEffect(() => {
    if (params.invoiceId) {
      dispatch({
        type: ActionTypes.FINISH_FLOW,
        invoiceId: Number(params.invoiceId),
      });
    }
  }, [invoiceId, context.successPath]);
  useEffect(() => {
    if (paymentSuccess && context.successPath) {
      history.push(context.successPath);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (isOpened && !invoiceId && page === 'payment' && window?.analytics) {
      window.analytics.track('FT Modal checkout initiated');
    }
  }, [isOpened, invoiceId, page === 'payment']);

  useEffect(() => {
    if (invoice?.status === 'paid' && window?.analytics) {
      window.analytics.track('FT Modal checkout success', { invoiceId });
    }
  }, [isOpened, invoice?.status === 'paid']);

  useEffect(() => {
    if (context.failed) {
      dispatch({
        type: ActionTypes.FINISH_FLOW,
      });
    }
  }, [context.failed]);

  const content = useMemo(() => {
    if (page === 'payment') {
      return (
        <PaymentFlow
          id={context.orderId}
          loading={!!context.loading}
          entity="orders"
          makeReturnUrl={(id) =>
            `${config.imdfront.appUrl}${location.pathname}?fastCheckout=true&invoiceId=${id}&successPath=${context.successPath}`
          }
          onError={(_t, invoiceId) => {
            dispatch({ type: ActionTypes.FINISH_FLOW, invoiceId });
          }}
          onComplete={(invoiceId) => {
            dispatch({ type: ActionTypes.FINISH_FLOW, invoiceId });
          }}
          action={
            context.orderId ? null : (
              <Button
                onClick={handleClose}
                size="small"
                text={t('have-voucher-apply-here')}
                style={{ width: '100%', marginTop: '16px' }}
              />
            )
          }
          terms={
            <Trans
              i18nKey="product-terms-and-conditions"
              components={[
                <a
                  key={1}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t('terms-link-for-order') || '#'}
                >
                  terms
                </a>,
              ]}
              defaults={
                'Paying this order you are accepting <0>terms and conditions</0> for products'
              }
            />
          }
        ></PaymentFlow>
      );
    }
    if (paymentSuccess) {
      return (
        <FinishFlowContainer data-test-id="ThankYouPage">
          <PaymentStatusMessage
            success={true}
            message={t('payment-was-successful')}
          />
          {product === 'mastering' && (
            <ProductRating css={ratingStyle} product={product} />
          )}
          <Button onClick={handleClose} text={t('continue')} primary={true} />
        </FinishFlowContainer>
      );
    }
    if (paymentFailed) {
      return (
        <FinishFlowContainer>
          <PaymentStatusMessage success={false} message={t('payment-failed')} />
          <Button
            onClick={handleClose}
            text={invoiceId ? t('view-invoices') : t('close')}
            primary={true}
          />
        </FinishFlowContainer>
      );
    }
    return (
      <Centered>
        <LoadingIndicator
          style={{ justifySelf: 'center', alignSelf: 'center' }}
          size="large"
        />
      </Centered>
    );
  }, [page, invoiceId, invoice?.status, invoiceRequest.failed, context]);

  return (
    <PageLayer
      isOpen={isOpened}
      closeOnLocation={false}
      lockClickOutside={true}
      close={context.loading ? undefined : handleClose}
    >
      {content}
    </PageLayer>
  );
};
