import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  createContext,
} from 'react';
import type { EntityModels } from 'imddata';
import {
  ImpersonateContext,
  useLabel,
  useQuery,
  useCustomerFeature,
  useAllAvailableEntries,
  useFetchEntity,
} from 'imddata';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  OverlineText,
  Window,
  Button,
  IconButton,
  IssueListContext,
  TextFormatted,
  InputActionSeparator,
} from 'imdui';
import type { Props as DropdownProps } from '../../DropdownMenu';
import DropdownMenu from '../../DropdownMenu';
import NewLabelDialog from './NewLabelDialog';
import { AddIcon } from '@imus/base-ui';

const AddButtonWrap = styled.div`
  display: flex;
`;

const isElectronicaLabel = <T extends { label: string }>(label: T) =>
  label && label.label === 'iM Electronica';

const mapKeys = (
  labels: EntityModels.Label[] | undefined,
  entry: EntityModels.Label | undefined,
  isElectronic: boolean,
  hasCustomLabelFeature: boolean
) => {
  if (!labels) return [];
  return [
    ...labels,
    ...(entry && !labels.find((l) => l.id === entry.id) ? [entry] : []),
  ]
    .map((label) => ({
      value: label.id,
      label: label.name,
      disabled: !hasCustomLabelFeature && label.type === 'custom',
      isFree: label.isFree,
    }))
    .filter((l) => (isElectronic ? true : !isElectronicaLabel(l)));
};

const preventIssues = { issues: null };

export type Props = {
  premiumLabelLimit: number | 'none';
  isElectronic: boolean;
  value?: number;
  onBlur?: (e?: any) => void;
  onFocus?: (e?: any) => void;
  testId?: string;
  disabled?: boolean;
  onChange: DropdownProps['onChange'];
  components: DropdownProps['components'];
  onClickHelp: DropdownProps['onClickHelp'];
  errorText: DropdownProps['errorText'];
  helperText: DropdownProps['helperText'];
  warningText: DropdownProps['warningText'];
  label: DropdownProps['label'];
};

export const LabelRegistrationShopContext = createContext<string[]>([]);

function LabelSelector({
  premiumLabelLimit,
  value,
  isElectronic,
  label,
  testId,
  onChange,
  onBlur,
  onFocus,
  disabled,
  components,
  onClickHelp,
  errorText,
  helperText,
  warningText,
}: Props) {
  const { t } = useTranslation();

  const { entry } = useLabel({ id: value });

  const impersonateQuery = useContext(ImpersonateContext);

  const hasCustomLabelFeature = useCustomerFeature('custom-labels');

  const finalQuery = useMemo(
    () => ({ ...impersonateQuery, perPage: 150 }),
    [impersonateQuery]
  );

  const allLabels = useAllAvailableEntries<EntityModels.Label>({
    entity: 'labels',
  });

  const { queryHash, query, updateQuery } = useQuery({
    query: finalQuery,
  });
  const {
    reload,
    request: { loading },
  } = useFetchEntity({
    entity: 'labels',
    query,
    queryHash,
  });

  const data = useMemo(
    () => mapKeys(allLabels, entry, isElectronic, hasCustomLabelFeature),
    [entry, allLabels, isElectronic]
  );

  const freeLabel = data && data.find((labelEntry) => labelEntry.isFree);

  const handleFormSaved = useCallback(
    (response) => {
      onChange(response.result);
    },
    [onChange]
  );

  const [openWindow, setOpenWindow] = useState(false);

  const handleInputChange = useCallback(
    (searchString) => {
      updateQuery({ query: searchString });
      return searchString;
    },
    [updateQuery]
  );

  const shops = useContext(LabelRegistrationShopContext);

  const completed = [
    ...(entry?.registeredAtTraxsourceAt ? [t('traxsource')] : []),
    ...(entry?.registeredAtBeatportAt ? [t('beatport')] : []),
  ];

  const pending = [
    ...(!entry?.registeredAtTraxsourceAt && shops.indexOf('traxsource') >= 0
      ? [t('traxsource')]
      : []),
    ...(!entry?.registeredAtBeatportAt && shops.indexOf('beatport') >= 0
      ? [t('beatport')]
      : []),
  ];

  const status =
    completed.length === 2
      ? 'completed'
      : completed.length === 1
        ? 'partial'
        : 'pending';

  const translation =
    entry?.name !== 'iM Electronica'
      ? t('label-registration', {
          context: entry?.isFree ? 'free-label' : status,
          completed,
          pending,
        })
      : '';

  const reachedPremiumLimit =
    typeof premiumLabelLimit === 'number' &&
    premiumLabelLimit <=
      Number(data.reduce((acc, v) => (v.isFree ? acc + 1 : acc), 0));

  const renderInputActions = useCallback(
    () => (
      <AddButtonWrap>
        <InputActionSeparator />
        <IconButton
          inline={true}
          testId="LabelSelector-NewLabelButton"
          onClick={() => {
            setOpenWindow(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </AddButtonWrap>
    ),
    [reachedPremiumLimit, !!freeLabel]
  );

  const dropdownComponents = useMemo(
    () => ({
      ...(components || {}),
      InputActions: renderInputActions,
    }),
    [components]
  );

  const labelWindow = (
    <Window
      close={() => {
        setOpenWindow(false);
      }}
      title={t('add-label')}
      isOpen={openWindow}
    >
      <IssueListContext.Provider value={preventIssues}>
        <NewLabelDialog
          onCancel={() => {
            setOpenWindow(false);
          }}
          isPremiumAllowed={premiumLabelLimit !== 0}
          isFreeAllowed={!freeLabel}
          onFormSaved={(...args) => {
            handleFormSaved(...args);
            setOpenWindow(false);
          }}
        />
      </IssueListContext.Provider>
    </Window>
  );
  let uiComponent = null;

  if (data.length === 0 && !value) {
    uiComponent = (
      <>
        <OverlineText
          label={label}
          size="large"
          onClickHelp={onClickHelp}
          components={{ OverlineActions: components?.OverlineActions }}
        />
        <Button
          showLoading={loading}
          icon={AddIcon}
          position="center"
          disabled={loading}
          style={{
            width: '100%',
            borderColor: errorText ? 'var(--state-negative)' : undefined,
          }}
          testId="LabelSelector-NewLabelButton"
          text={t('add-label', {
            context: reachedPremiumLimit ? 'only-free' : '',
          })}
          onClick={() => {
            setOpenWindow(true);
          }}
        />
      </>
    );
  }

  // if (premiumLabelLimit === 0 && data.length === 1 && data[0].isFree) {
  //   return (
  //     <>
  //       <OverlineText
  //         label={label}
  //         size="large"
  //         onClickHelp={onClickHelp}
  //         error={errorText === 'required' ? t('required') : undefined}
  //         components={{ OverlineActions: components?.OverlineActions }}
  //       />
  //       <Body>{entry?.name}</Body>
  //     </>
  //   );
  // }
  //
  const finalErrorText =
    errorText ||
    (entry?.type === 'custom' && !hasCustomLabelFeature ? true : false) ||
    ((shops.indexOf('beatport') >= 0 || shops.indexOf('traxsource') >= 0) &&
      entry?.isFree);
  return (
    <>
      {uiComponent || (
        <DropdownMenu
          testId={testId}
          // isLoading={loading}
          label={label}
          onClickHelp={onClickHelp}
          key="menu"
          onFocus={() => {
            if (onFocus) onFocus();
            reload();
          }}
          errorText={!loading ? finalErrorText : undefined}
          labelSize="large"
          // @ts-ignore
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          onInputChange={handleInputChange}
          warningText={warningText}
          data={data}
          helperText={
            (completed.length || pending.length) && value ? (
              <TextFormatted text={translation} />
            ) : (
              helperText
            )
          }
          components={dropdownComponents}
        />
      )}
      {labelWindow}
    </>
  );
}

export default LabelSelector;
