import React, { useContext } from 'react';
import { Portal } from 'react-portal';
import type { DropzoneState, DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { Icons, SVGIcon } from 'imdui';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { palette } from '../../helpers';

import { DropContext } from '../DropObserver/DropContext';

const contentAppear = keyframes(`
  0% {
    opacity: 0.01;
    transform: scaleY(0.9);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`);
const DropzoneStyled = styled.div<{
  isDragReject: boolean;
  isDragActive: boolean;
}>`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  animation: ${contentAppear} 0.35s cubic-bezier(0.19, 1, 0.22, 1) forwards;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.15s ease-in;
  background-color: var(--bg-2);
  border: 2px dashed var(--outline-var);
  border-radius: 3px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 100;
  ${({ isDragActive }) =>
    isDragActive
      ? `
                border: 2px dashed #84bc48;
                will-change: opacity, transform;
                & > svg {
                  fill: #84bc48;
                }
  `
      : ''}

  ${({ isDragReject }) =>
    isDragReject
      ? `
                border: 2px dashed red;
                & > svg path {
                  fill: rgba(255, 0, 0, 1);
                }
  `
      : ''}
`;

const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconStyled = styled.div`
  margin: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type Props = Omit<DropzoneOptions, 'children'> & {
  children: (props: {
    isDragReject: boolean;
    isDragActive: boolean;
    isDragAccept: boolean;
    acceptedFiles: DropzoneState['acceptedFiles'];
  }) => React.ReactChild;
  className?: string;
};

const DropzoneContainer: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const container = useContext(DropContext);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone(props);
  return (
    <Portal node={container}>
      <Container>
        <DropzoneStyled
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          <ContentWrapper>
            <IconStyled>
              <SVGIcon
                color={
                  // eslint-disable-next-line no-nested-ternary
                  isDragReject
                    ? palette.error
                    : isDragAccept
                      ? palette.dark.xDark
                      : palette.dark.dark
                }
                size={100}
                d={isDragReject ? Icons.actions.close : Icons.actions.upload}
              />
            </IconStyled>

            {children({
              isDragReject,
              isDragActive,
              isDragAccept,
              acceptedFiles,
            })}
          </ContentWrapper>
        </DropzoneStyled>
      </Container>
    </Portal>
  );
};

export default DropzoneContainer;
