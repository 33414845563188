//
import { select, put, call } from 'redux-saga/effects';
import createTempId from '../../utils/createTempId';
import * as api from '../../api';
import { UPDATE_RELEASE_TRACKS } from './types';

export default function* createEmptyTrack(action) {
  const {
    payload: {
      nestingPath,
      data: { names, ...data } = {},
      tempId: passedTempId,
    },
    meta,
  } = action;
  const tempId =
    passedTempId ||
    createTempId(nestingPath ? JSON.stringify(nestingPath) : '');

  const defaultLanguageId = yield select((state) => {
    const { userId } = state.auth.profile || {};
    return state.entities.users.entities[userId]?.defaultLanguageId;
  });

  try {
    const response = yield call(
      api.tracks.create,
      {
        nestingPath,
        tempId,
        data: {
          defaultName: names
            ? Object.values(names).find(
                ({ languageId }) => languageId === defaultLanguageId
              )
            : { languageId: defaultLanguageId },
          names:
            names && Object.values(names).length
              ? Object.values(names)
              : [{ languageId: defaultLanguageId }],
          ...data,
        },
      },
      meta
    );
    if (nestingPath && response) {
      const { result } = response;
      yield put({
        type: UPDATE_RELEASE_TRACKS,
        id: nestingPath?.entityId,
        payload: {
          tempId,
          response,
          nestingPath,
          trackId: result,
        },
        meta,
      });
      return response && response.result;
    }
    return response && response.result;
  } catch (error) {
    console.error(error);
    if (meta && meta.reject) {
      meta.reject(error);
    }
  }
  return null;
}
