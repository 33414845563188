import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type {
  ReduxState,
  EntityIdentifier,
  RequestState,
} from '../../../types';
import { makeSelectRequestState } from '../selectors';

type FetchRequestProviderOptions = {
  entity: EntityIdentifier;
  id?: string | number;
  queryHash?: string;
};

const fetchRequestExtractedKeys: [
  keyof RequestState,
  ...(keyof RequestState)[],
] = [
  'withs',
  'loading',
  'entryCount',
  'loaded',
  'failed',
  'errorMessage',
  'loadedQuery',
  'errorData',
  'errors',
  'hasMorePages',
  'loadedPages',
  'offset',
];

export function useFetchRequestProvider({
  entity,
  id,
  queryHash,
}: FetchRequestProviderOptions) {
  const selectFetchRequest = useMemo(
    () => makeSelectRequestState(fetchRequestExtractedKeys),
    []
  );

  return useSelector((state: ReduxState) =>
    selectFetchRequest(state, {
      entity,
      id,
      queryHash,
    })
  );
}
