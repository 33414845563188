import React from 'react';
import { css } from '@emotion/react';
import type { MultiValueProps } from 'react-select';
import type { OptionType } from '../../types';
import { Chip } from 'imdui';

const containerStyle = css`
  margin: 4px 8px 4px 0;
`;

const QueryChip: React.FC<MultiValueProps<OptionType>> = ({
  data: { value, label },
  removeProps: { onClick: onRemove },
}) => (
  <Chip
    css={containerStyle}
    text={label || value}
    onClickDelete={(e) => {
      // @ts-ignore
      onRemove(e);
      e.stopPropagation();
    }}
  />
);

export default QueryChip;
