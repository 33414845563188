import type { EntityModels } from '../../../../types';
import type { NestDefinition } from '../../base';
import type { ReleaseNested } from './releases';
import { defaultReleaseNest } from './releases';
import type { TrackNested } from './tracks';
import { defaultTrackNest } from './tracks';

export type DBShop = EntityModels.Nest<
  EntityModels.DeliveryBundleShop,
  { shop: EntityModels.Shop }
>;

type DBCountry = EntityModels.Nest<
  EntityModels.DeliveryBundleCountry,
  { country: EntityModels.Country }
>;
type DBRelease = EntityModels.Nest<
  EntityModels.DeliveryBundleRelease,
  { release: ReleaseNested }
>;

type DBTrack = EntityModels.Nest<
  EntityModels.DeliveryBundleTrack,
  { track: TrackNested }
>;

export type DBNested = EntityModels.Nest<
  EntityModels.DeliveryBundle,
  {
    countries: DBCountry[];
    shops: DBShop[];
    tracks: DBTrack[];
    releases: DBRelease[];
  }
>;

export const defaultBundleNest: NestDefinition = [
  {
    entity: 'deliveryBundleShops',
    key: 'shops',
    nest: [
      {
        key: 'shop',
        entity: 'shops',
      },
    ],
  },
  {
    entity: 'deliveryBundleCountries',
    key: 'countries',
    nest: [
      {
        key: 'country',
        entity: 'countries',
      },
    ],
  },
  {
    entity: 'deliveryBundleTracks',
    key: 'tracks',
    nest: [
      {
        collect: true,
        getKey: (entry: EntityModels.DeliveryBundleTrack) => entry?.trackId,
        key: 'track',
        entity: 'tracks',
        nest: defaultTrackNest,
      },
    ],
  },
  {
    entity: 'deliveryBundleReleases',
    key: 'releases',
    nest: [
      {
        collect: true,
        getKey: (entry: EntityModels.DeliveryBundleRelease) => entry?.releaseId,
        key: 'release',
        entity: 'releases',
        nest: defaultReleaseNest,
      },
    ],
  },
];
