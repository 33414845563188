//
import React, { useContext, useMemo, useCallback } from 'react';
import { pick, keys } from 'ramda';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import { Portal } from 'react-portal';
import {
  Button,
  Icons,
  Body,
  HelperText,
  ToggleSwitchField,
  HelpWindowContext,
} from 'imdshared';
import { StepSlider, ProductLoader, BlockTemplate } from 'components';
import type { WrappedFieldProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import type { EntityModels, NestDefinition } from 'imddata';
import { useEntry, useUpdateEntity } from 'imddata';
import { AlertBox, Subheading } from 'imdui';
import { DescriptionBlock } from '../../../../shared';

import { useProductPage } from '../../../../shared/ProductWizard';
import GenreSelectField from './GenreSelectField';

const MASTERING_STYLES = [
  {
    value: 'default',
    label: 'mastering-genre-default',
  },
  {
    value: 'hiphop',
  },
  {
    value: 'drum_and_bass',
  },
  {
    value: 'edm',
  },
  {
    value: 'electro_house',
  },
  {
    value: 'deep_house',
  },
  {
    value: 'tech_house',
  },
  {
    value: 'techno',
  },
  {
    value: 'trance',
  },
  {
    value: 'rock_pop',
  },
  {
    value: 'metal_punk',
  },
  {
    value: 'ambient',
  },
  {
    value: 'world',
  },
  {
    value: 'classical',
  },
  {
    value: 'jazz_blues',
  },
];

const GenreColumn = styled.div`
  min-width: 0;
`;

const ToggleColumn = styled.div`
  min-width: 0;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 24px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);

    ${GenreColumn} {
      grid-column: span 3;
    }

    ${ToggleColumn} {
      grid-column: span 1;
    }
  }
`;

const SliderFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const SliderLabel = styled(Body)`
  flex: 1;
  font-weight: 500;
  min-width: 100px;
  padding: 0 4px 0 0;
  color: rgb(102, 102, 102);
`;

const MasteringWrapper = styled.div`
  margin-top: 32px;
`;

const TitleRow = styled.div`
  margin: 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const TermsBlock = ({
  onSubmit,
  submitting,
}: {
  submitting: boolean;
  onSubmit: (...params: any) => void;
}) => {
  const {
    state: { steps },
  } = useProductPage();
  const [{ verifying, valid }] = steps;

  const { t } = useTranslation();
  const disabled = verifying || !valid;

  return (
    <div>
      <Portal node={document && document.getElementById('next-product-step')}>
        <Button
          {...{
            text: t('generate-previews'),
            iconColor: 'white',
            textColor: 'white',
            testId: `DistributeButton--${disabled ? 'disabled' : 'enabled'}`,
            icon: Icons.arrows.forward,
            primary: true,
            disabled,
            showLoading: submitting,
            onClick: onSubmit,
          }}
        />
      </Portal>
    </div>
  );
};

const SliderInput = ({
  testId,
  input,
  label,
}: WrappedFieldProps & { testId?: string; label?: string }) => {
  return (
    <SliderFieldWrapper data-test-id={testId}>
      <SliderLabel>{label}</SliderLabel>
      <StepSlider
        value={input.value}
        onChange={(value) => input.onChange(value)}
      />
    </SliderFieldWrapper>
  );
};

type FormProps = {
  status: EntityModels.InstantMastering['status'];
  id: string | number;
  tracks: InstantMastering['tracks'];
  nextUrl?: string;
};

type FormData = {
  genre: string;
  loudness: number;
  bass: number;
  warmth: number;
  mid: number;
  brightness: number;
  stereoWidening: boolean;
};

const DeliveryForm = reduxForm<FormData, FormProps>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  submitAsSideEffect: true,
})(({
  status,
  id,
  tracks,
  handleSubmit,
  submitSucceeded,
  nextUrl,
  submitting,
  form,
}) => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);

  const masteringOptions = useMemo(() => {
    return MASTERING_STYLES.map(({ value, label }) => ({
      value,
      label: t(label || value.replace(/_/g, '-')),
    }));
  }, []);

  const {
    state: { steps },
  } = useProductPage();
  const [{ verifying, valid }] = steps;

  const { updateEntry } = useUpdateEntity({
    entity: 'instantMasterings',
    id,
    query: { with: 'tracks' },
  });

  const onSubmit = useCallback(
    handleSubmit((values) => {
      updateEntry({
        data: {
          status: 'previewing',
          tracks: tracks?.map((instantMasteringTrack) => ({
            ...instantMasteringTrack,
            ...values,
          })),
        },
        formId: form,
      });
    }),
    [updateEntry, tracks, handleSubmit]
  );

  if (submitSucceeded && nextUrl) {
    return <Redirect to={nextUrl} />;
  }

  if (status !== 'open') {
    return <ProductLoader />;
  }

  return (
    <>
      {(!valid || verifying) && (
        <AlertBox
          style={{ marginTop: '20px' }}
          type={!valid ? AlertBox.Type.error : AlertBox.Type.warning}
          text={
            !valid
              ? t('tracks-missing-audio')
              : t('wait-for-tracks-upload-to-finish')
          }
        />
      )}

      <DescriptionBlock
        title={t('select-mastering-options-title')}
        text={t('select-mastering-options-body')}
      />

      <FormWrapper>
        <GenreColumn>
          <BlockTemplate
            title={
              <TitleRow>
                <Subheading>{t('mastering-adjust-genre')}</Subheading>
                <HelperText
                  onClickHelperButton={() => {
                    showHelpWindow(
                      t('mastering-adjust-genre'),
                      t('mastering-helptext-adjust-genre')
                    );
                  }}
                />
              </TitleRow>
            }
          >
            <Field
              name="genre"
              floatingLabelText={t('mastering-style')}
              // helperText={t('mastering-helptext-short-style')}
              disabled={false}
              required={false}
              data={masteringOptions}
              component={GenreSelectField}
            />
          </BlockTemplate>
        </GenreColumn>

        <ToggleColumn>
          <BlockTemplate
            title={
              <TitleRow>
                <Subheading>{t('mastering-adjust-sound')}</Subheading>
                <HelperText
                  onClickHelperButton={() => {
                    showHelpWindow(
                      t('mastering-adjust-sound'),
                      t('mastering-helptext-adjust-sound')
                    );
                  }}
                />
              </TitleRow>
            }
          >
            <Field
              label={t('brightness')}
              name="brightness"
              component={SliderInput}
            />
            <Field
              label={t('loudness')}
              name="loudness"
              component={SliderInput}
            />
            <Field label={t('bass')} name="bass" component={SliderInput} />
            <Field
              testId="Field-warmth"
              label={t('warmth')}
              name="warmth"
              component={SliderInput}
            />
            <Field
              testId="Field-mid"
              label={t('mid')}
              name="mid"
              component={SliderInput}
            />

            <MasteringWrapper>
              <Field
                name="stereoWidening"
                text={t('stereo-widening')}
                disabled={false}
                required={false}
                component={ToggleSwitchField}
              />
            </MasteringWrapper>
          </BlockTemplate>
        </ToggleColumn>
      </FormWrapper>

      <TermsBlock onSubmit={onSubmit} submitting={submitting} />
    </>
  );
});

const defaultValues: {
  genre: string;
  loudness: number;
  bass: number;
  warmth: number;
  mid: number;
  brightness: number;
  stereoWidening: boolean;
} = {
  loudness: 0,
  bass: 0,
  warmth: 0,
  mid: 0,
  brightness: 0,
  genre: 'default',
  stereoWidening: false,
};

const nest: NestDefinition = [
  {
    entity: 'instantMasteringTracks',
    key: 'tracks',
  },
];

type InstantMastering = EntityModels.Nest<
  EntityModels.InstantMastering,
  {
    tracks: EntityModels.InstantMasteringTrack[];
  }
>;

const Delivery = ({
  id,
  nextUrl,
}: {
  id: string | number;
  nextUrl?: string;
}) => {
  const { t } = useTranslation();
  const { entry } = useEntry<InstantMastering>({
    entity: 'instantMasterings',
    nest,
    id,
  });
  const firstTrack =
    (entry && entry.tracks && entry.tracks[0]) || defaultValues;

  const initialValues: FormData = useMemo(
    // @ts-ignore
    () => pick(keys(defaultValues), firstTrack),
    [entry]
  );

  if (!entry) return null;
  const { tracks } = entry;

  const form = `instant-mastering:${id}`;

  return (
    <>
      <Helmet>
        <title>{t('page-title-mastering-options')}</title>
      </Helmet>

      <DeliveryForm
        status={entry?.status}
        tracks={tracks}
        initialValues={initialValues}
        form={form}
        id={id}
        nextUrl={nextUrl}
      />
    </>
  );
};

export default Delivery;
