//

const interpolate =
  ({ x, y }, clamp = false) =>
  (value) => {
    let result = 0;
    const leftCorrection = y[0];
    const rightCorrection = y[1] - y[0];
    const progress = (value - x[0]) / (x[1] - x[0]);
    result = leftCorrection + progress * rightCorrection;

    if (clamp) {
      const boundYRange = [y[0], y[1]].sort((a, b) => a > b);
      if (result <= boundYRange[0]) {
        return boundYRange[0];
      }
      if (result >= boundYRange[1]) {
        return boundYRange[1];
      }
    }

    return result;
  };

export default interpolate;
