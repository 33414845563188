import { useTranslation } from 'next-i18next';
import { PressFileItem, Text, SurfaceCard } from '@imus/services-ui';
import type { PressContact, PressFile, PressQuote } from '../types';
import { AutosizeGrid } from '@imus/base-ui';
import { quoteStyle, quoteText } from './styles';
import styled from '@emotion/styled';
import { getFileUrl } from '../utils/getFileUrl';
import { useBaseUrl } from '../BaseUrlContext';
import isURL from 'validator/lib/isURL';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import { Separator } from '../Separator';

const PressCard = styled(SurfaceCard)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  border-radius: var(--space-S, 16px);
`;

const ContactInfo = styled.div`
  word-break: break-all;
  display: flex;
  flex: 1;
  gap: 4px;
  flex-direction: column;
`;

const formatDescription = (description: string) => {
  if (isMobilePhone(description)) {
    return { url: 'tel:' + description, label: description };
  }
  if (isEmail(description)) {
    return { url: 'mailto:' + description, label: description };
  }
  if (isURL(description, { require_host: true })) {
    try {
      const url = new URL(description);
      return { url: description, label: url.hostname };
    } catch {
      return { url: description, label: description };
    }
  }
  return { url: undefined, label: description };
};

export const PressItems = ({
  contacts,
  files,
  quotes,
}: {
  contacts?: PressContact[];
  files?: PressFile[];
  quotes?: PressQuote[];
}) => {
  const { t } = useTranslation();
  const baseUrl = useBaseUrl();
  return (
    <>
      {!!contacts?.length && (
        <>
          <Text.H2>{t('contact-details')}</Text.H2>

          <div>
            <PressCard>
              {contacts.map(({ value, name }, idx) => {
                const { label, url } = formatDescription(value);
                return (
                  <>
                    {' '}
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '8px',
                      }}
                      key={idx}
                    >
                      <ContactInfo>
                        <Text.BodyS css={{ opacity: 0.75 }}>{name}</Text.BodyS>
                        {url ? (
                          <Text.ContentM
                            css={{ color: 'var(--accent)', cursor: 'pointer' }}
                            as="a"
                            // @ts-ignore
                            href={url}
                          >
                            {label}
                          </Text.ContentM>
                        ) : (
                          <Text.ContentM>{label}</Text.ContentM>
                        )}
                      </ContactInfo>
                    </div>
                    {contacts.length - 1 !== idx && <Separator />}
                  </>
                );
              })}
            </PressCard>
          </div>
        </>
      )}
      {!!files?.length && (
        <>
          <Text.H2>{t('press')}</Text.H2>

          <div>
            <PressCard>
              {files.map(({ file, description }, idx) =>
                file ? (
                  <>
                    <PressFileItem
                      key={idx}
                      name={description || t('press-file')}
                      extension={file.extension}
                      url={getFileUrl(file, baseUrl)}
                      size={file.size}
                    />
                    {files.length - 1 !== idx && <Separator />}
                  </>
                ) : null
              )}
            </PressCard>
          </div>
        </>
      )}
      {!!quotes?.length && (
        <>
          <Text.H2>{t('quotes')}</Text.H2>
          <AutosizeGrid
            gap={24}
            style={{ marginBottom: '16px' }}
            minWidth={320}
            maxColumnCount={2}
          >
            {quotes.map(({ origin, message }, idx) => (
              <SurfaceCard
                data-test-id="PressItem-QuoteCard"
                css={quoteStyle}
                key={idx}
              >
                <span css={quoteText}>"{message}"</span>
                <Text.ContentL> – {origin}</Text.ContentL>
              </SurfaceCard>
            ))}
          </AutosizeGrid>
        </>
      )}
    </>
  );
};
