import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { TextFormatted } from 'imdui';
import { CopyToClipboardField, BlockTemplate } from 'components';

const CopyToClipboardFieldStyled = styled(CopyToClipboardField)`
  margin: 16px 0 0 0;
`;

export const Link: React.FC<{
  translationPrefix: string;
  linkReady?: boolean;
  link: string;
}> = ({ linkReady, translationPrefix, link }) => {
  const { t } = useTranslation();

  return (
    <BlockTemplate title={t(`${translationPrefix}-link-title`)}>
      <TextFormatted text={t(`${translationPrefix}-link-description`)} />
      <CopyToClipboardFieldStyled value={link} disabled={!linkReady} />
    </BlockTemplate>
  );
};
