//
import * as R from 'ramda';
import { generateIdLens } from '../base';

export default function createRequestReducerFactory(createAction) {
  return (state, action) => {
    const componentKey = action.payload
      ? action.payload.componentKey
      : undefined;
    const result = action.response ? action.response.result : undefined;
    const id = Array.isArray(result) ? result[0] : result;
    switch (action.type) {
      case createAction.REQUEST:
        return R.mergeRight(state, {
          awaitingComponents: componentKey
            ? {
                ...state.awaitingComponents,
                [componentKey]: {
                  loading: true,
                  failed: false,
                  errorMessage: null,
                  errorData: null,
                  errors: null,
                  error: null,
                  statusCode: null,
                },
              }
            : state.awaitingComponents,
        });
      case createAction.SUCCESS: {
        const awaitingComponents = R.isNil(componentKey)
          ? state.awaitingComponents
          : R.set(
              R.lensPath([componentKey]),
              {
                loading: false,
                createdEntityId: id,
                loaded: true,
                failed: false,
                errorMessage: null,
                errors: null,
                error: null,
                statusCode: 200,
              },
              state.awaitingComponents
            );
        const item = id
          ? R.mergeRight(R.view(generateIdLens(id), state), {
              loaded: true,
              failed: false,
              errorMessage: null,
              errorData: null,
              errors: null,
              error: null,
              statusCode: 200,
            })
          : {};
        const items = id
          ? R.set(generateIdLens(id), item, state).items
          : state.items;
        return R.mergeRight(state, {
          awaitingComponents,
          items,
        });
      }
      case createAction.FAILURE:
        return R.mergeRight(state, {
          awaitingComponents: componentKey
            ? {
                ...state.awaitingComponents,
                [componentKey]: {
                  failed: true,
                  loading: false,
                  statusCode: action.statusCode,
                  errorMessage: action.error && action.error.message,
                  errorData: action && action.error && action.error.data,
                  errors: action.error && action.error.errors,
                  error: action.error,
                },
              }
            : state.awaitingComponents,
        });
      default:
        return state;
    }
  };
}
