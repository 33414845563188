import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import cx from 'classnames';
import type { EventHandler, SyntheticEvent } from 'react';
import React, { useMemo } from 'react';
import { Body } from '../../../text';
import type { HelperTextProps } from '../../field/HelperText';
import HelperText from '../../field/HelperText';

const focusPulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
`;
type Handler = EventHandler<SyntheticEvent<any>>;

type ToggleElementProps = {
  checked: boolean;
};

const ToggleTrack = styled.div<ToggleElementProps>`
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ checked }) =>
    checked ? 'var(--accent, #00A743)' : 'var(--on-surface-var-2, #5F5E5D)'};
`;

const ToggleHead = styled.div<ToggleElementProps>`
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateX(${({ checked }) => (checked ? 12 : 0)}px);

  & > div {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border-radius: 8px;
    background: var(--surface-container-high, #fff);
  }

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(0);
  }
`;

const ToggleSwitchContainer = styled.div<ToggleElementProps>`
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;

  &:active ${ToggleTrack} {
    background-color: rgba(0, 0, 0, 0.2);
  }
  & > * {
    user-select: none;
  }
  &:focus ${ToggleHead}:before {
    transform: scale(1);
    animation: ${focusPulse} 1.5s ease-in-out infinite;
    animation-delay: 0.2s;
  }
`;

const Toggle = styled.div`
  position: relative;
  display: flex;
`;

type FocusEventHandler = (event: React.FocusEvent<HTMLElement>) => void;

export type ToggleSwitchProps = HelperTextProps & {
  helperText?: string;
  testId?: string;
  helperStyle?: React.CSSProperties;
  helperClassName?: string;
  checked?: boolean;
  disabled?: boolean;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  onCheck?: (e: SyntheticEvent<any>, selected: boolean) => void;
  components?: { OverlineActions?: React.FC<Record<string, never>> };
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
};

export function ToggleSwitch({
  helperText,
  testId,
  warningText,
  errorText,
  helperClassName,
  helperStyle,
  onClickHelperButton,
  checked = false,
  disabled = false,
  text,
  className,
  style,
  onCheck,
  components,
  onFocus,
  onBlur,
}: ToggleSwitchProps): JSX.Element {
  const handleCheckAndPress = useMemo<Handler | undefined>(
    () =>
      !disabled
        ? (e) => {
            if (onCheck) {
              onCheck(e, checked);
            }
          }
        : undefined,
    [checked, onCheck, disabled]
  );

  return (
    <>
      <ToggleSwitchContainer
        checked={checked}
        data-test-id={testId}
        role="switch"
        data-action="aria-switch"
        aria-checked={checked}
        aria-labelledby={text}
        tabIndex={0}
        className={cx(disabled && 'disabled', className)}
        style={style}
        onClick={handleCheckAndPress}
        onKeyPress={handleCheckAndPress}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <Toggle>
          <ToggleTrack checked={checked} />

          <ToggleHead checked={checked}>
            <div />
          </ToggleHead>
        </Toggle>

        {text ? <Body style={{ marginLeft: 16 }}>{text}</Body> : null}
      </ToggleSwitchContainer>

      {helperText || errorText || warningText ? (
        <HelperText
          text={helperText}
          warningText={warningText}
          errorText={errorText}
          onClickHelperButton={onClickHelperButton}
          className={helperClassName}
          style={helperStyle}
        />
      ) : null}
      {components?.OverlineActions && <components.OverlineActions />}
    </>
  );
}
