import styled from '@emotion/styled';
import { Window, Button, H2, TextFormatted } from 'imdui';
import { StyledButton } from 'imdui/src/components/button/IconButton/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Buttons = styled.div``;

const ConfirmContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 32px;
  width: 100%;
  & > ${Buttons} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  & ${StyledButton} {
    width: 100%;
  }

  ${H2} {
    margin-bottom: 40px;
  }
`;

const TextFormattedStyled = styled(TextFormatted)`
  display: block;
  margin-bottom: 40px;
`;

export function ConfirmInstantUpgradeWindow({
  isOpen,
  message,
  title,
  onConfirm,
  loading,
  close,
}: {
  title: string;
  message?: string;
  close: () => void;
  isOpen: boolean;
  loading: boolean;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Window title={title} isOpen={isOpen} close={close}>
      <ConfirmContent>
        <TextFormattedStyled text={message} />
        <Buttons>
          <Button
            position="center"
            disabled={loading}
            text={t('cancel')}
            onClick={close}
          />
          <Button
            style={{ marginRight: '16px' }}
            testId="ConfirmInstantUpgradeWindow-confirm"
            primary={true}
            disabled={loading}
            showLoading={loading}
            position="center"
            text={t('confirm')}
            onClick={onConfirm}
          />
        </Buttons>
      </ConfirmContent>
    </Window>
  );
}
