//
import React from 'react';
import styled from '@emotion/styled';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import download from 'downloadjs';
import type { EntityModels } from 'imddata';
import { useEntries, useDeleteEntity } from 'imddata';
import { InfiniteList } from 'components';
import prettyBytes from 'pretty-bytes';
import { Card } from 'imdui';
import type { InfiniteListRowRenderer } from 'imdshared';
import { NavListItem, Icons, IconButton, Button } from 'imdshared';

const DeleteButton = styled(Button)`
  box-shadow: inset 0 0 0 1px #ececec;
  margin-right: 24px;
`;

const DownloadButton = styled(IconButton)`
  background-color: #fafafa;
  width: 40px;
  height: 40px;
  box-shadow: inset 0 0 0 1px #ececec;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const getIcon = (handler: EntityModels.Download['handler']) => {
  switch (handler) {
    case 'payout_invoice_pdf':
    case 'invoice_pdf': {
      return Icons.categories.invoice;
    }
    case 'sales_report_elastic':
    case 'sales_releases_report_elastic':
    case 'sales_report':
    case 'sales_releases_report': {
      return Icons.categories.salesReport;
    }

    default:
      return undefined;
  }
};

const formatStatementsToString = (
  statements: EntityModels.DownloadStatement['data']['statements']
) => {
  if (!statements) return '';
  return statements
    .map((d) =>
      /\d\d\d\d$/.test(d)
        ? moment(d, 'YYYY').format('YYYY')
        : moment(d, 'YYYY-M').format(`MMM 'YY`)
    )
    .reduce((acc, cur, i) => {
      if (i > 0 && acc !== cur) {
        return `${acc} - ${cur}`;
      }
      return cur;
    }, '');
};

const getTitle = (item: EntityModels.Download, t: TFunction) => {
  switch (item.handler) {
    case 'payout_invoice_pdf':
      return `${t('download-item-payout-invoice-pdf')} #${item.data.payoutId}`;
    case 'invoice_pdf':
      return `${t('download-item-invoice-pdf')} #${item.data.invoiceId}`;
    case 'sales_report_elastic':
    case 'sales_report': {
      const date = formatStatementsToString(item.data.statements);
      return `${t('download-item-sales-report-for')} ${date}`;
    }
    case 'sales_releases_report_elastic':
    case 'sales_releases_report': {
      const date = formatStatementsToString(item.data.statements);
      return `${t('download-item-sales-releases-report-for')} ${date}`;
    }

    default:
      return '';
  }
};

const renderDownloadRow: InfiniteListRowRenderer<EntityModels.Download> = ({
  style,
  key,
  item,
}) => {
  const { createdAt, id, file, handler, status } = item;
  const { t } = useTranslation();

  const {
    deleteEntry,
    request: { deleting },
  } = useDeleteEntity({ entity: 'downloads', id });

  const icon = getIcon(handler);

  const title = getTitle(item, t);

  return (
    <NavListItem
      key={key}
      icon={icon}
      title={title}
      subtitle={file?.size ? prettyBytes(file.size) : ''}
      createdAt={moment(createdAt).format('L')}
      style={style}
    >
      <ButtonWrapper>
        {status === 'ready' && (
          <DeleteButton
            onClick={() => {
              deleteEntry();
            }}
            disabled={deleting}
            text={t('delete')}
          />
        )}

        <DownloadButton
          icon={Icons.actions.download}
          disabled={status !== 'ready'}
          showLoading={['pending', 'registered'].indexOf(status) >= 0}
          onClick={() => {
            if (item.status === 'ready') {
              download(item.downloadUrl);
            }
          }}
        />
      </ButtonWrapper>
    </NavListItem>
  );
};

const Downloads = () => {
  const { t } = useTranslation();
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useEntries<EntityModels.Download>({ entity: 'downloads' });

  return (
    <Card>
      <InfiniteList
        data={entries}
        hasNextPage={hasMorePages}
        loadNextPage={loadMore}
        loading={loading}
        rowRenderer={renderDownloadRow}
        emptyPlaceholder={t('no-downloads')}
        emptyPlaceholderIcon={Icons.actions.download}
        getRowHeight={() => 80}
      />
    </Card>
  );
};

export default Downloads;
