//
import requestSagaCreator from './requestSagaCreator';

/**
 * Binds redux action to respective API calls
 *
 * @returns {undefined}
 */
export default function bindCrudApi(actions, apiCalls) {
  return {
    fetch: requestSagaCreator(actions.fetch, apiCalls.fetch),
    update: requestSagaCreator(actions.update, apiCalls.update, 3),
    create: requestSagaCreator(actions.create, apiCalls.create, 3),
    delete: requestSagaCreator(actions.delete, apiCalls.delete, 3),
  };
}
