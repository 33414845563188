import styled from '@emotion/styled';
import type { HTMLReactParserOptions } from 'html-react-parser';
import Parser, { Element, domToReact } from 'html-react-parser';
import React from 'react';

const Wrapper = styled.div`
  * {
    line-height: 24px;
  }

  h1,
  h2,
  h3 {
    font-weight: 500;
  }

  h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    margin: 40px 0 32px 0;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 32px 0 24px;
  }

  ul,
  ol {
    padding-inline-start: 20px;
  }

  p:not(:last-child),
  ul:not(:last-child),
  ol:not(:last-child) {
    font-size: 16px;
    margin-bottom: 12px;
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }
  table {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    border-spacing: 0;
    border-collapse: collapse;
    border: 0;

    thead {
      background-color: #fafafa;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
    }

    th {
      font-weight: 400;
    }

    th,
    td {
      min-width: 160px;
      padding: 4px;
      border: 0;
    }

    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
`;

type Props = {
  text?: string;
  className?: string;
  style?: any;
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.tagName === 'contactus') {
      return (
        <a
          onClick={(e) => {
            e.preventDefault();
            // @ts-ignore
            if (window.Beacon) {
              // @ts-ignore
              window.Beacon('reset');
              // @ts-ignore
              window.Beacon('toggle');
              // @ts-ignore
              window.Beacon('navigate', '/ask/message/');
            }
          }}
        >
          {domToReact(domNode.children, options)}
        </a>
      );
    }
  },
};

const TextFormatted: React.FC<Props> = ({ text, className, style }) => (
  <Wrapper className={className} style={style}>
    {Parser(text || '', options)}
  </Wrapper>
);

export default TextFormatted;
