import { EntityModels } from '../../../../types';
import { NestDefinition } from '../../base';
import { defaultTrackNest, TrackNested } from './tracks';

export type InstantMasteringTrackNested = EntityModels.Nest<
  EntityModels.InstantMasteringTrack,
  { track: TrackNested; currentPreview: EntityModels.InstantMasteringPreview }
>;

export type InstantMasteringNested = EntityModels.Nest<
  EntityModels.InstantMastering,
  {
    tracks: InstantMasteringTrackNested[];
  }
>;

export const defaultInstantMasteringNest: NestDefinition = [
  {
    entity: 'instantMasteringTracks',
    key: 'tracks',
    nest: [
      {
        key: 'currentPreview',
        entity: 'instantMasteringPreviews',
      },
      {
        getKey: (entry?: EntityModels.InstantMasteringTrack) => {
          return entry?.trackId;
        },
        key: 'track',
        entity: 'tracks',
        nest: defaultTrackNest,
      },
    ],
  },
];
