import React from 'react';
import { ProcessFieldMeta } from 'imddata';
import type { WrappedFieldProps } from 'redux-form';
import type { Props, OptionType } from '../DropdownMenu';
import DropdownMenu from '../DropdownMenu';

export type FieldProps<
  OT extends OptionType = OptionType,
  T extends boolean = false,
> = Omit<Props<OT, T>, 'onChange'> & {
  valueOverride?: string;
  input: Partial<WrappedFieldProps['input']>;
  meta: Partial<WrappedFieldProps['meta']>;
};

const SelectField = <OT extends OptionType = OptionType>({
  label,
  floatingLabelText,
  data,
  meta,
  onChange,
  valueOverride,
  input = {},
  ...other
}: FieldProps<OT, false> & {
  floatingLabelText?: string;
  onChange?: (v: any) => void;
}) => (
  <ProcessFieldMeta
    hasValue={!!input.value}
    valueOverride={valueOverride}
    meta={meta}
  >
    {({ error, warning }) => (
      <DropdownMenu
        label={label || floatingLabelText}
        data={data}
        errorText={error}
        warningText={warning}
        {...other}
        {...input}
        // @ts-ignore
        onChange={(value: string) => {
          if (input.onChange) {
            input.onChange(value);
          }
          if (onChange) {
            onChange(value);
          }
        }}
      />
    )}
  </ProcessFieldMeta>
);

export default SelectField;
