//

import fetchRequestReducerFactory from './fetchRequestReducerFactory';
import updateRequestReducerFactory from './updateRequestReducerFactory';
import deleteRequestReducerFactory from './deleteRequestReducerFactory';
import createRequestReducerFactory from './createRequestReducerFactory';
import refreshEntityRequestFactory from './refreshEntityRequestFactory';

export const requestInitalState = () => ({
  items: {},
  searchHistory: {},
  awaitingComponents: {},
  loaded: false,
  loading: false,
  updating: false,
});

const entityRequestReducerFactory = ({
  entityKey,
  fetchActions,
  createActions,
  deleteActions,
  updateActions,
}) => {
  const initialState = requestInitalState();

  const reducer = [];
  if (deleteActions && deleteActions.length) {
    reducer.push(
      ...deleteActions.reduce((accReducers, actions) => {
        if (Array.isArray(actions)) {
          const [actionsObject, getId] = actions;
          return [
            ...accReducers,
            deleteRequestReducerFactory(actionsObject, getId),
          ];
        }
        return [...accReducers, deleteRequestReducerFactory(actions)];
      }, [])
    );
  }
  if (updateActions && updateActions.length) {
    reducer.push(
      ...updateActions.reduce((accReducers, actions) => {
        if (Array.isArray(actions)) {
          const [actionsObject, getId] = actions;
          return [
            ...accReducers,
            updateRequestReducerFactory(actionsObject, getId),
          ];
        }
        return [...accReducers, updateRequestReducerFactory(actions)];
      }, [])
    );
  }
  if (fetchActions && fetchActions.length) {
    reducer.push(
      ...fetchActions.reduce(
        (accReducers, actions) => [
          ...accReducers,
          fetchRequestReducerFactory(actions),
        ],
        []
      )
    );
  }
  if (createActions && createActions.length) {
    reducer.push(
      ...createActions.reduce(
        (accReducers, actions) => [
          ...accReducers,
          createRequestReducerFactory(actions),
        ],
        []
      )
    );
  }
  reducer.push(refreshEntityRequestFactory(entityKey));

  return (state = initialState, action) => {
    return reducer.reduce(
      (accState, reducerFn) => reducerFn(accState, action),
      state
    );
  };
};
export default entityRequestReducerFactory;
