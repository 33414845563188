//
import { mergeDeepLeft } from 'ramda';
import { actionTypes } from '../../../../actionTypes/prices';

const initialState = {
  entities: {},
  searchHistory: {},
  keys: [],
};

export default function prices(state = initialState, action) {
  switch (action.type) {
    case actionTypes.overviewByRegion.SUCCESS: {
      return mergeDeepLeft(
        {
          entities: {
            ...state.entities,
            ...action.response.entities.prices,
          },
        },
        state
      );
    }
    default:
      return state;
  }
}
