import { action } from '../../base';

export enum types {
  SET_SIGNUP_CODES = '@ui/signup/SET_SIGNUP_CODES',
}

export const setSignupCodes = (codes: {
  referral: string | undefined;
  affiliate: string | undefined;
}) =>
  action(types.SET_SIGNUP_CODES, {
    codes,
  });
