import React from 'react';
import moment from 'moment';
import { Field } from 'redux-form';

const parseMomentToDate = (value) => {
  if (value) {
    return moment(value).format();
  }
  return null;
};

const formatToMoment = (value) => (value ? moment(value) : null);

const MomentDateField = (props) => (
  <Field
    {...props}
    onBlur={(event) => {
      event.preventDefault();
    }}
    format={formatToMoment}
    parse={parseMomentToDate}
  />
);

export default MomentDateField;
