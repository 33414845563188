import { useSelector } from 'react-redux';
import { lensPath, view } from 'ramda';

const getSalesPath = (queryHash, entityId, entity) => {
  const base = ['entities', 'sales', entity];

  if (!queryHash) {
    return [...base, 'data'];
  }
  return [
    ...base,
    'searchHistory',
    queryHash,
    'entities',
    'itemSales',
    entityId,
  ];
};

export const useEntitySales = ({ entity, id, queryHash }) => {
  const sales = useSelector((state) =>
    view(lensPath(getSalesPath(queryHash, id, entity)), state)
  );
  return sales;
};
