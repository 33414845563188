//
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

const DragHandlerWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  opacity: 0.34;
  cursor: move;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.87;
  }
`;

const DragHandler = forwardRef(({ className, style }, ref) => (
  <DragHandlerWrapper ref={ref} className={className} style={style}>
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 16H1M11 12L1 12M11 8L1 8"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </DragHandlerWrapper>
));

export default DragHandler;
