export default {
  back: 'M5.82604,11 L12.6066,17.7806 L11.1924,19.1948 L2,10.0024 L11.1924,0.81 L12.6066,2.22421 L5.83082,9 L19,9 L19,11 L5.82604,11 Z',
  forward:
    'M14.174,11 L7.3934,17.7806 L8.80761,19.1948 L18,10.0024 L8.80761,0.81 L7.3934,2.22421 L14.1692,9 L1,9 L1,11 Z',
  cloud: 'M0,0 L20,0 L20,20 Z',
  chevronLeft:
    'M14.1066,17.7806 L12.6924,19.1948 L3.5,10.0024 L12.6924,0.81 L14.1066,2.22421 L6.32604,10 Z',
  chevronRight:
    'M5.89,17.7806 L7.30421,19.1948 L16.4966,10.0024 L7.30421,0.81 L5.89,2.22421 L13.6706,10 Z',
  dropdown:
    'M6.2506,7.75 L5,9.00036 L10,14.0004 L15,9.00036 L13.7494,7.75 L10,11.4994 L6.2506,7.75 Z',
  moveHorizontal:
    'M3.82159,10.96 L16.1048,10.96 L11.36,15.6179 L12.7977,17.0321 L19.9977,9.96107 L12.7977,2.89 L11.36,4.30421 L16.1026,8.96 L3.96638,8.96 L8.63772,4.37421 L7.20006,2.96 L0,10.0311 L7.20006,17.1021 L8.63772,15.6879 L3.82159,10.96 Z',
};
