import { action } from 'imddata';

export const types = {
  SET_IMPERSONATION: '@ui/impersonate/SET_IMPERSONATION',
};

export const actions = {
  setImpersonation: (impersonate) =>
    action(types.SET_IMPERSONATION, impersonate),
};
