import type { TFunction } from 'i18next';
import { useMemo, createContext, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { WrappedFieldProps } from 'redux-form';

export const ShowErrorContext = createContext(false);

const formatError = (error: any, t: TFunction) => {
  if (error === true) return true;
  if (['required', '[FUR]'].indexOf(error) >= 0) return error; // Bypass for special strings

  return t(error);
};

type Children = (p: { error?: string; warning?: string }) => React.ReactElement;

type Props = {
  children: Children;
  hasValue?: boolean;
  valueOverride?: any;
  meta?: Partial<WrappedFieldProps['meta']>;
};

const ProcessFieldMeta = ({
  children,
  hasValue,
  valueOverride,
  meta = {},
}: Props) => {
  const { t } = useTranslation();
  const { touched } = meta;

  const showError = useContext(ShowErrorContext);

  const displayError =
    (touched || hasValue || showError) && !valueOverride && !!meta.error;

  const lastTrackedError = useRef(null);

  useEffect(() => {
    if (
      displayError &&
      meta.error &&
      meta.error !== 'required' &&
      meta.error !== lastTrackedError.current &&
      window.analytics &&
      window.analytics.user &&
      window.analytics.user() &&
      window.analytics.user().id()
    ) {
      window.analytics.track('FT Field Valididation Displayed', {
        ...meta,
      });
      lastTrackedError.current = meta.error;
    }
    if (
      !meta.error &&
      lastTrackedError.current &&
      window?.analytics &&
      window.analytics.user &&
      window.analytics.user() &&
      window.analytics.user().id()
    ) {
      window.analytics.track('FT Field Valididation Resolved', {
        error: lastTrackedError.current,
        ...meta,
      });
      lastTrackedError.current = null;
    }
  }, [displayError]);

  const error = formatError(meta.error, t);

  const warning = formatError(meta.warning, t);

  const props = useMemo(() => {
    return {
      warning,
      error: displayError && error,
    };
  }, [warning, displayError, error]);

  return children(props);
};

export default ProcessFieldMeta;
