import { useLayoutEffect } from 'react';

export const useSentry = () => {
  const environment = process.env.TARGET_ENV || 'development';
  const release = __COMMITHASH__;
  useLayoutEffect(() => {
    if (window && window.analytics) {
      window.analytics.ready(() => {
        if (window.Sentry) {
          const { Sentry } = window;
          Sentry.configureScope((scope) => {
            scope.addEventProcessor((event) => {
              /* eslint-disable no-param-reassign */
              event.release = release;
              event.environment = environment;
              return event;
            });
          });
        }
      });
    }
  }, []);
};
