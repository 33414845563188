//
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import type { InstantMasteringNested, EntityModels } from 'imddata';
import {
  useEntryProvider,
  useCreateOrder,
  useEntries,
  useInstantMastering,
  useOverview,
  useInstantMasteringTrackIds,
} from 'imddata';
import {
  Button,
  usePlayer,
  ToggleSwitch,
  SelectAudioFileContext,
  DraggableTracksList,
  useFastPaymentFlowContext,
} from 'imdshared';
import { Portal } from 'react-portal';
import { MasteringProgressBar, BlockTemplate } from 'components';
import { AlertBox } from 'imdui';
import { DescriptionBlock } from '../../../../shared';

const ToggleRow = styled.div`
  display: flex;
  margin-right: 10px;
  height: 100%;
  justify-items: center;
  align-items: center;
`;

const selectMasteredAudio = ({
  instantMastering,
  trackId,
  files,
}: {
  instantMastering: InstantMasteringNested;
  trackId: string | number;
  files: EntityModels.File[];
}) => {
  const { currentPreview } =
    instantMastering.tracks.find(
      (instantMasteringTrack) => instantMasteringTrack.trackId === trackId
    ) || {};
  if (currentPreview && files) {
    const file = files.find(
      (value) => value.id === currentPreview.previewFileId
    );
    return file;
  }
  return null;
};

const ToggleTrackAudio = ({
  instantMastering,
  files,
  ...props
}: {
  instantMastering: InstantMasteringNested;
  files: EntityModels.File[];
} & React.ComponentProps<typeof ToggleSwitch>) => {
  const { fileUrl, play, progress, trackId } = usePlayer();
  const masteredAudio =
    trackId &&
    instantMastering &&
    files &&
    selectMasteredAudio({
      instantMastering,
      files,
      trackId,
    });

  return (
    <ToggleRow>
      <ToggleSwitch
        {...props}
        testId="MasteredAudio-Toggle"
        disabled={!masteredAudio}
        checked={masteredAudio && props.checked}
        onCheck={() => {
          const preview = props.checked ? fileUrl : masteredAudio?.downloadUrl;
          play(trackId, progress, preview);

          // TODO why does it worked?
          // @ts-ignore
          props.onCheck();
        }}
      />
    </ToggleRow>
  );
};

export default function MasteringSummary({ id }: { id: string | number }) {
  const { t } = useTranslation();
  const { entry } = useInstantMastering({ id });
  const trackIds = useInstantMasteringTrackIds(id);
  const [playMastered, setPlayMastered] = useState(true);
  const fileIds = useMemo(() => {
    if (!entry) return [];
    return entry.tracks.reduce<number[]>(
      (acc, { currentPreview }) =>
        currentPreview && currentPreview.previewFileId
          ? [currentPreview.previewFileId, ...acc]
          : acc,
      []
    );
  }, [entry]);
  const { entries: files } = useEntries<EntityModels.File>({
    passive: !fileIds.length,
    ids: fileIds,
    entity: 'files',
  });
  const defaultSelectAudio = useContext(SelectAudioFileContext);
  const selectAudio = useCallback(
    (track) => {
      if (!track || !entry) return null;
      const originalPreview = defaultSelectAudio(track);

      const masteredPreview = selectMasteredAudio({
        instantMastering: entry,
        files,
        trackId: track.id,
      })?.downloadUrl;

      return playMastered
        ? masteredPreview || originalPreview
        : originalPreview;
    },
    [playMastered, entry]
  );

  const isVerifying = useCallback(
    (track) => {
      if (!entry) return true;
      const preview = entry.tracks.find(
        ({ trackId }) => trackId === track.id
      )?.currentPreview;
      return !preview || !preview.previewFileId;
    },
    [entry]
  );

  const processingCount = trackIds.length - files.length;

  const {
    createOrder,
    request: { creating, id: orderId },
  } = useCreateOrder({
    id,
    entity: 'instantMasterings',
  });
  const { overview, refresh } = useOverview({
    entity: 'instantMasteringOverview',
    id,
  });

  useEffect(() => {
    if (processingCount === 0) {
      refresh();
    }
  }, [processingCount]);

  const order = useEntryProvider<EntityModels.Order>({
    entity: 'orders',
    id: orderId,
  });

  const { open: openPayment } = useFastPaymentFlowContext();

  useEffect(() => {
    if (order) {
      openPayment({
        loading: false,
        orderStatus: order?.status,
        successPath: '/library/tracks',
      });
    }
  }, [!!order]);

  if (!entry) return null;

  return (
    <div style={{ marginBottom: '0px' }}>
      <Helmet>
        <title>{t('page-title-mastering-mastered-tracks')}</title>
      </Helmet>

      <MasteringProgressBar
        style={{ marginTop: '32px' }}
        entry={entry}
        hideOnFinish={true}
        hideActions={true}
      />

      <Portal node={document && document.getElementById('player-controls')}>
        <ToggleTrackAudio
          text={t('mastered')}
          checked={playMastered}
          instantMastering={entry}
          files={files}
          onCheck={() => setPlayMastered(!playMastered)}
        />
      </Portal>
      <SelectAudioFileContext.Provider value={selectAudio}>
        <DescriptionBlock
          title={t('mastered-tracks-title')}
          text={t('mastered-tracks-body')}
        />

        <BlockTemplate padded={false} title={t('mastered-tracks')}>
          <DraggableTracksList
            isVerifying={isVerifying}
            disableDrag={true}
            hideCheckbox={true}
            enableFetch={true}
            items={trackIds}
            selectedTracks={trackIds}
            columns={{ number: false, album: false }}
            hasNextPage={false}
          />
        </BlockTemplate>
      </SelectAudioFileContext.Provider>

      {!!processingCount && (
        <div style={{ marginBottom: '48px' }}>
          <AlertBox
            style={{ marginTop: '20px' }}
            type={AlertBox.Type.warning}
            text={t('wait-for-tracks-mastering-to-finish', {
              count: processingCount,
            })}
          />
        </div>
      )}

      <Portal node={document && document.getElementById('next-product-step')}>
        <Button
          text={t('order')}
          primary="paid"
          onClick={() => {
            openPayment({
              loading: true,
            });
            createOrder();
          }}
          disabled={creating || !overview?.canBePaid}
          showLoading={creating}
        />
      </Portal>
    </div>
  );
}
