// @flowInvoice.
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { BlockTemplate } from 'components';
import {
  Centered,
  LoadingIndicator,
  Body,
  Content,
  DownloadInvoiceButton,
} from 'imdshared';
import { Overview } from 'screens/Order/screens/AllOrdersOverview';
import { Grid } from 'imdui';
import type { EntityModels } from 'imddata';
import { useOverview, useUpdateRequestProvider, useEntry } from 'imddata';
import moment from 'moment';
import { PayInvoiceButton, InvoiceStatus } from '../../shared';

export default function Invoice() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const {
    refresh: refreshOverview,
    overview,
    request: { loading },
  } = useOverview({ entity: 'invoicesOverview', id });
  const { entry: invoice } = useEntry<EntityModels.Invoice>({
    id,
    entity: 'invoices',
    query: { with: 'items' },
  });

  const { updated } = useUpdateRequestProvider({ id, entity: 'invoices' });

  useEffect(() => {
    if (updated) {
      refreshOverview({ id });
    }
  }, [updated]);

  if (!invoice) {
    return (
      <Centered>
        <LoadingIndicator size="large" />
      </Centered>
    );
  }
  const paid = invoice.status === 'paid';

  const { createdAt, statusText } = invoice;

  return (
    <>
      <Helmet>
        <title>{t('page-title-invoice-details')}</title>
      </Helmet>

      <BlockTemplate
        testId={`Invoice-${paid ? 'paid' : 'unpaid'}`}
        title={`${t('invoice')} #${id}`}
        action={
          invoice ? (
            <>
              <PayInvoiceButton invoice={invoice} />
              <DownloadInvoiceButton
                id={id}
                type="button"
                text={t('download')}
              />
            </>
          ) : null
        }
      >
        <Grid columns={1}>
          <div>
            <Content>{t('created-at')}: </Content>{' '}
            <Body>{moment(createdAt).format('LLL')}</Body>
          </div>
          <div>
            <Content>{t('status')}: </Content>
            <InvoiceStatus status={invoice.status}>{statusText}</InvoiceStatus>
          </div>
        </Grid>
      </BlockTemplate>

      {overview && (
        <Overview
          overview={overview}
          loading={loading}
          paid={paid}
          payable={invoice.isPayable}
        />
      )}
    </>
  );
}
