//
import * as R from 'ramda';
import { entities } from '../../../../actionTypes';
import { types } from '../../../../actionTypes/releases';
import uploadFileReducerFactory from '../../../../reducerFactories/uploadFileReducerFactory';

export const COVER_SIZES = [
  'xSmall',
  'small',
  'medium',
  'large',
  'basic',
  'export',
];

const initialState = {
  volumes: {},
  searchHistory: {},
  releaseArtists: {},
  entities: {},
  keys: [],
};

function updateLocalCover(state, action) {
  switch (action.type) {
    case types.COVER_THUMBNAIL_CREATED: {
      const { id, coverTypeName, downloadUrl } = action.payload;
      return R.set(
        R.lensPath(['entities', id, 'availableCovers', coverTypeName]),
        downloadUrl,
        state
      );
    }
    case types.SET_COVER_IN_STORE: {
      const { id, cover } = action.payload;
      return R.set(R.lensPath(['entities', id, 'inMemoryCover']), cover, state);
    }
    case types.UPDATE_COVER_STATUS: {
      return state;
      // const { id, ...data } = action.payload;
      // return R.over(
      //   R.lensPath(['entities', id]),
      //   release => R.mergeRight(release, data),
      //   state
      // );
    }
    case entities.releaseCovers.types.delete.SUCCESS: {
      const { query: { releaseId } = {} } = action.payload;
      return R.compose(
        R.set(R.lensPath(['entities', releaseId, 'hasCoverUploaded']), false),
        R.set(R.lensPath(['entities', releaseId, 'uploadStatus']), 'none'),
        R.set(R.lensPath(['entities', releaseId, 'coverDominantColor']), null),
        R.set(R.lensPath(['entities', releaseId, 'availableCovers']), {})
      )(state);
    }
    default:
      return state;
  }
}

const coverReducer = uploadFileReducerFactory(
  entities.releases.types.cover,
  'cover'
);

export default function releases(state = initialState, action) {
  return [updateLocalCover, coverReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
