import React, { useState, useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { CommonFieldProps } from 'redux-form';
import { HelpWindowContext } from 'imdui';
import YesNoField from '../YesNoField';
import FieldUpdatable from '../FieldUpdatable';
import DatePickerInputField from '../DatePickerInputField';

type Props = {
  disabled: boolean;
  onChange: CommonFieldProps['onChange'];
};

// TODO: CAN NOT HANDLE PRESALES
const DateComponent = (props: any) => {
  const { t } = useTranslation();

  const [displayPicker, setDisplayPicker] = useState(!!props.input.value);

  const toggleInput = useMemo(() => {
    return {
      value: displayPicker,
      onChange: (value: boolean) => {
        setDisplayPicker(value);
        if (value === false) {
          props.input.onChange(undefined);
        }
      },
    };
  }, [displayPicker, props.input.onChange]);

  return (
    <>
      <YesNoField
        testId="OriginalReleaseDateSelectorField-YesNo"
        input={toggleInput}
        label={t('released-before-digital')}
        disabled={props.disabled}
      />
      {displayPicker && <DatePickerInputField {...props} />}
    </>
  );
};

type DateProps = {
  disabled?: boolean;
  isOutsideRange?: () => void;
  mode: string;
  autoOk: boolean;
  helperText: string;
  onClickHelperButton: any;
};

const OriginalReleaseDateSelectorField = ({ disabled, onChange }: Props) => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);
  const onClickHelperButton = useCallback(() => {
    showHelpWindow(
      t('original-release-date'),
      t('release-helptext-original-release-date')
    );
  }, [t]);
  return (
    <FieldUpdatable<DateProps>
      type="momentdate"
      name="originalReleaseDate"
      onChange={onChange}
      disabled={disabled}
      testId="releaseDate"
      isOutsideRange={() => false}
      floatingLabelText={t('original-release-date')}
      mode="landscape"
      autoOk={true}
      helperText={t('release-helptext-short-original-release-date')}
      onClickHelperButton={onClickHelperButton}
      component={DateComponent}
    />
  );
};

export default OriginalReleaseDateSelectorField;
