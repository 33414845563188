import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Body, AppTitle, Card, Content, Button } from 'imdui';
import { css } from '@emotion/react';
import { ChartHeader } from './ChartHeader';
import { useSubscriptionUpsell } from 'imdshared';
import { accentThemes } from '@imus/base-ui';

const ShowcaseGrid = styled.div`
  display: grid;
  padding: 32px 24px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 32px;
  img {
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
  ${Content} {
    display: block;
    margin-bottom: 8px;
  }
`;

const SubscribeIcon: React.FC<{
  className?: string;
  style?: React.CSSProperties;
}> = ({ className, style }) => (
  <svg
    className={className}
    style={style}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.89935 0.698242C6.19402 -0.232749 7.51145 -0.232746 7.80612 0.698245L8.89989 4.15396C8.99811 4.46427 9.24121 4.70737 9.55152 4.80558L13.0072 5.89935C13.9382 6.19402 13.9382 7.51145 13.0072 7.80612L9.55152 8.89989C9.24121 8.99811 8.99811 9.24121 8.89989 9.55152L7.80612 13.0072C7.51145 13.9382 6.19402 13.9382 5.89935 13.0072L4.80558 9.55152C4.70737 9.24121 4.46427 8.99811 4.15396 8.89989L0.698242 7.80612C-0.232749 7.51145 -0.232746 6.19402 0.698245 5.89935L4.15396 4.80558C4.46427 4.70737 4.70737 4.46427 4.80558 4.15396L5.89935 0.698242Z"
      fill="var(--accent)"
    />
    <path
      d="M17.7327 13.1982C18.0274 12.2673 19.3448 12.2673 19.6395 13.1982L20.3325 15.388C20.4307 15.6983 20.6738 15.9414 20.9842 16.0396L23.1739 16.7327C24.1049 17.0274 24.1049 18.3448 23.1739 18.6395L20.9842 19.3325C20.6738 19.4307 20.4307 19.6738 20.3325 19.9842L19.6395 22.1739C19.3448 23.1049 18.0274 23.1049 17.7327 22.1739L17.0396 19.9842C16.9414 19.6738 16.6983 19.4307 16.388 19.3325L14.1982 18.6395C13.2673 18.3448 13.2673 17.0274 14.1982 16.7327L16.388 16.0396C16.6983 15.9414 16.9414 15.6983 17.0396 15.388L17.7327 13.1982Z"
      fill="var(--accent)"
    />
  </svg>
);

const SubscribeRow = styled.div`
  margin: 0 24px;
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
  align-items: center;
  ${Content} {
    margin: 0 24px;
  }
`;

type ShowcaseProps = {
  className?: string;
  style?: React.CSSProperties;
  data: Array<{
    title: string;
    src: string;
    description: string;
  }>;
  title: string;
  children?: React.ReactNode;
};

const ShowcaseCardContent: React.FC<ShowcaseProps> = ({
  className,
  style,
  data,
  children,
  title,
}) => {
  return (
    <Card className={className} style={style}>
      <ChartHeader
        title={
          <>
            <SubscribeIcon
              css={css`
                margin-right: 16px;
              `}
            />
            {title}
          </>
        }
      />
      {data && (
        <ShowcaseGrid>
          {data.map((d, idx) => (
            <div key={idx}>
              <img src={d.src} alt="" />
              <Content>{d.title}</Content>
              <Body>{d.description}</Body>
            </div>
          ))}
        </ShowcaseGrid>
      )}
      {children}
    </Card>
  );
};

const ShowcaseCard = styled(ShowcaseCardContent)`
  ${accentThemes.tertiary}
  ${AppTitle} {
    display: inline-flex;
    align-items: center;
  }
`;

export const SubscribeCard: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  title: string;
  data: React.ComponentProps<typeof ShowcaseCard>['data'];
}> = ({ className, title, data, style }) => {
  const { t } = useTranslation();

  const { open: openUpsell } = useSubscriptionUpsell();

  return (
    <ShowcaseCard className={className} style={style} title={title} data={data}>
      <SubscribeRow>
        <Button
          onClick={() => {
            openUpsell({
              section: 'music-analytics',
              analytics: { detail: 'music-analytics' },
            });
          }}
          primary={true}
          text={t('upgrade')}
        />
      </SubscribeRow>
    </ShowcaseCard>
  );
};
