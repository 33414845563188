import React from 'react';
import styled from '@emotion/styled';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { simplifyBundleStatus } from 'imddata';
import { Chip, SVGIcon, Icons } from 'imdui';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  isFeedbackRequired: boolean;
  deliveryStatus: string;
  status: string;
  onClick?: () => void;
  text?: string;
};

type BundleStatusFC = React.FC<Props> & {
  smallStyle: SerializedStyles;
};

const StyledChip = styled(Chip)<{
  isFieldUpdateRequested: boolean;
}>`
  position: relative;
  background-color: ${({ theme, isFieldUpdateRequested }) =>
    isFieldUpdateRequested ? theme.state.warning : 'white'};
  box-shadow: inset 0 0 0 1px
    ${({ theme, isFieldUpdateRequested }) =>
      isFieldUpdateRequested ? theme.state.warning : 'none'};

  svg {
    margin-left: 16px;
    fill: ${({ theme, isFieldUpdateRequested }) =>
      isFieldUpdateRequested ? theme.state.warning : theme.foreground.primary};
  }
`;

const BundleStatusButton: BundleStatusFC = ({
  status,
  deliveryStatus,
  isFeedbackRequired,
  className,
  style,
  onClick,
  text,
}) => {
  const { t } = useTranslation();

  const simpleBundleStatus = simplifyBundleStatus(
    status,
    isFeedbackRequired,
    deliveryStatus
  );

  return (
    <StyledChip
      onClick={onClick}
      text={text || t(`delivery-bundle-${simpleBundleStatus}`)}
      isFieldUpdateRequested={isFeedbackRequired}
      className={className}
      style={style}
    >
      {isFeedbackRequired && <SVGIcon d={Icons.states.error} />}
    </StyledChip>
  );
};

BundleStatusButton.smallStyle = css`
  height: auto;
  min-height: 20px;
  border-radius: 10px;
  padding: 2px 0;

  span {
    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    font-weight: 400;
  }

  svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

export default BundleStatusButton;
