/* eslint-disable no-console */
import React, { memo, useMemo } from 'react';
import { groupBy } from 'ramda';
import styled from '@emotion/styled';
// import PricePlan from './components/PricePlan';
import Shops from './components/DeliveryChips/Shops';
import Countries from './components/DeliveryChips/Countries';
import FieldsChange from './components/FieldsChange';
import type { Change } from './types';

type ChangeGroup = {
  [key: string]: Change[];
};

type Props = {
  changes: Change[];
};

type SwitchGroupProps = {
  groupKey: string;
  changes: Change[];
};

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${({ theme }): string => theme.lights[1]};
`;

const orderOfChanges = [
  'release',
  'shops',
  'countries',
  'tracks',
  'artists',
  'contributors',
  'other',
];
const countryChanges = ['deliver-to-country', 'take-down-from-country'];
const shopChanges = ['deliver-to-shop', 'take-down-from-shop'];

const reduceChanges = (changes: Change[]): ChangeGroup => {
  if (!changes) {
    return {};
  }

  const grouped = groupBy((change) => {
    if (shopChanges.indexOf(change.change) >= 0) {
      return 'shops';
    }
    if (countryChanges.indexOf(change.change) >= 0) {
      return 'countries';
    }
    if (change.change === 'release-name-updated') {
      return 'release';
    }
    if (change.change === 'track-name-updated') {
      return 'tracks';
    }
    if (change.change === 'artist-name-updated') {
      return 'artists';
    }
    if (change.change === 'contributor-name-updated') {
      return 'contributors';
    }
    return 'other';
  }, changes);

  return { ...grouped };
};

const SwitchGroup: React.FC<SwitchGroupProps> = ({ groupKey, changes }) => {
  switch (groupKey) {
    // case 'bundle':
    //   return <PricePlan />;
    case 'shops':
      return <Shops changes={changes} />;
    case 'countries':
      return <Countries changes={changes} />;
    case 'release':
      return (
        <FieldsChange
          groupKey={groupKey}
          getTitle={(c): string =>
            `${c.details.nameTitle} – ${c.details.nameDisplayArtist}`
          }
          changes={changes}
        />
      );
    case 'tracks':
      return (
        <FieldsChange
          groupKey={groupKey}
          getTitle={(c): string => c.details.nameTitle}
          changes={changes}
        />
      );
    case 'artists':
      return (
        <FieldsChange
          groupKey={groupKey}
          getTitle={(c): string => c.details.nameName}
          changes={changes}
        />
      );
    case 'contributors':
      return (
        <FieldsChange
          groupKey={groupKey}
          getTitle={(c): string => c.details.nameName}
          changes={changes}
        />
      );
    default:
      console.table({ [groupKey]: changes });
      return null;
  }
};

const BundleUpdateFeed: React.FC<Props> = ({ changes }) => {
  const groupedChanges = useMemo(() => reduceChanges(changes), [changes]);
  const groupKeys = useMemo(
    () => orderOfChanges.filter((c) => !!groupedChanges[c] && c !== 'other'),
    [changes]
  );

  return (
    <>
      {groupKeys.map((groupKey, i) => {
        const group = groupedChanges[groupKey];

        if (!group) {
          return null;
        }

        return (
          <div key={groupKey}>
            <SwitchGroup groupKey={groupKey} changes={group} />
            {i + 1 !== groupKeys.length ? <Divider /> : null}
          </div>
        );
      })}
    </>
  );
};

export default memo(BundleUpdateFeed);
