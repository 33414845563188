//
import { factoryEntityApi, requestSagaCreator } from '../base';
import { entities } from '../actionTypes';
import * as schemas from '../schemas';
import { entityActions as userEntityActions } from '../actionTypes/users';
import callApi from '../callApi';

export const apiCalls = {
  fetch(payload, impersonate) {
    return callApi({
      impersonate,
      endpoint: 'profile',
      schema: { user: schemas.user, customer: schemas.customer },
      options: { method: 'GET' },
    });
  },
};

export default {
  ...factoryEntityApi({
    endpoint: 'users',
    entityKey: 'users',
    schema: schemas.user,
    actions: entities.users.actions,
    bySchemas: {
      customers: schemas.customer,
    },
  }),
  getCurrentProfile: requestSagaCreator(
    userEntityActions.loggedUser.fetch,
    apiCalls.fetch
  ),
};
