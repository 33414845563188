import { camelize } from 'humps';
import { unnest, filter, map, concat, uniq, values, compose } from 'ramda';
import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { entities } from '../../../actionTypes';
import type { EntityModels, ReduxState } from '../../../types';
import { useEntryProvider, useDeleteEntity } from '../base';
import { useEntry, useIsAdmin, useEntries } from '../hooks';
import { selectEntityData } from '../selectors';
import type {
  ReleaseWithBundlesNested,
  ReleaseNested,
  ReleasesNested,
  ReleaseWithCoversNested,
} from './nests/releases';
import {
  nestReleaseBundles,
  listNest,
  createReleaseNest,
  nestReleaseCovers,
} from './nests/releases';

export function useLabel({ ...props }) {
  return useEntry<EntityModels.Label>({ entity: 'labels', ...props });
}

const defaultReleaseQuery = {};

type ReleaseCoverRequirement = {
  availableCovers: Record<string, string>;
  covers: Array<{ type: string; file: { downloadUrl: string } }>;
};

type MappedRelease<T extends Record<string, unknown>> = Omit<
  T,
  'availableCovers'
> & {
  availableCovers: Record<string, string>;
};

const mapReleaseCovers = <T extends ReleaseCoverRequirement>(
  release: T
): MappedRelease<T> => {
  const availableCovers =
    release.covers?.reduce((acc, cover) => {
      if (!cover || !cover.type) {
        return acc;
      }
      return {
        ...acc,
        [camelize(cover.type)]: cover.file?.downloadUrl,
      };
    }, {}) || {};

  return {
    ...release,
    availableCovers: {
      // TODO: make single source of availableCovers here and remove it from Release model itself
      // Due to sockets update providing availableCovers directly bypassing covers array
      ...(release.availableCovers || {}),
      ...availableCovers,
    },
  };
};

export const extractTracksForContentId = (
  volumes: ReleaseNested['volumes']
) => {
  if (!volumes) return [];

  return unnest(volumes)
    .filter(({ track }) => track && !track.isInContentId)
    .map(({ trackId }) => trackId);
};

export const useReleaseLabelLimitations = ({
  releaseId,
}: {
  releaseId: number | string;
}) => {
  const labelId = useSelector(
    (state: ReduxState) => state.entities.releases.entities[releaseId]?.labelId
  );

  const { entry: label } = useLabel({
    passive: !labelId,
    id: labelId,
  });

  const options = useMemo(() => {
    if (label) {
      const { registeredAtBeatportAt, registeredAtTraxsourceAt, isFree } =
        label;

      const isPremium = !isFree;
      const beatport = !!(isPremium || registeredAtBeatportAt);
      const traxsource = !!(isPremium || registeredAtTraxsourceAt);

      return { beatport, traxsource };
    }
    return {
      traxsource: true,
      beatport: true,
    };
  }, [label]);
  return options;
};

export function useRelease({
  query = defaultReleaseQuery,
  collect = {},
  ...props
}) {
  const nest = useMemo(() => {
    return createReleaseNest({
      ...collect,
      artists: true,
      covers: true,
      tracks: true,
    });
  }, []);
  const isAdmin = useIsAdmin();
  const { entry, ...entryData } = useEntry<ReleaseNested>({
    entity: 'releases',
    query,
    nest,
    ...props,
  });

  const editableFields = useMemo(() => {
    if (!entry) return {};
    const isNotDelivered = entry.deliveryStatus === 'not_delivered';
    const locks = entry.states?.resourceLocking
      ? Object.values(entry.states.resourceLocking)
      : ([] as never[]);
    const barcodeLocked =
      locks.length &&
      locks.find(
        (lock) =>
          lock.attributes.find((a) => a === 'custom_barcode') &&
          lock.actionType === 'edit'
      );
    return {
      title: true,
      version: true,
      tracks: isAdmin || isNotDelivered,
      genreId: isAdmin || isNotDelivered,
      languageId: isAdmin || isNotDelivered,
      originalReleaseDate: isAdmin || isNotDelivered,
      artists: isAdmin || isNotDelivered,
      labelId: isAdmin || isNotDelivered,
      copyrightYear: isAdmin || isNotDelivered,
      copyrightText: isAdmin || isNotDelivered,
      coverCopyrightYear: isAdmin || isNotDelivered,
      coverCopyrightText: isAdmin || isNotDelivered,
      customBarcode: (isAdmin || isNotDelivered) && !barcodeLocked,
      catalogNumber: isAdmin || isNotDelivered,
      promotionalText: isAdmin || isNotDelivered,
      coverUpload: isAdmin || isNotDelivered,
    };
  }, [entry]);

  const release = useMemo(() => {
    return entry ? mapReleaseCovers(entry) : null;
  }, [entry]);

  return {
    entry: release,
    ...entryData,
    editableFields,
  };
}

export function useReleaseCovers(props: {
  id: number | undefined | string;
  passive?: boolean;
}) {
  const entry = useEntryProvider<ReleaseWithCoversNested>({
    ...props,
    entity: 'releases',
    nest: nestReleaseCovers,
  });

  const release = useMemo(() => {
    return entry ? mapReleaseCovers(entry) : null;
  }, [entry]);

  return {
    entry: release,
  };
}

const makeSelectDeliveryBundles = () =>
  createSelector(
    [
      (state: ReduxState) => selectEntityData(state, 'deliveryBundleReleases'),
      (_state: ReduxState, id: number | string) =>
        typeof id === 'string' ? parseInt(id, 10) : id,
    ],
    (deliveryBundleReleases, id): number[] => {
      // @ts-ignore
      const associatedBundleIds: number[] = compose(
        map(({ deliveryBundleId }) => deliveryBundleId),
        filter(({ releaseId }) => releaseId === id),
        values
        // @ts-ignore
      )(deliveryBundleReleases.entities);
      return associatedBundleIds || [];
    }
  );

export function useDeleteRelease({ id }: { id: number | string }) {
  const { entry: release } = useEntry<ReleaseWithBundlesNested>({
    entity: 'releases',
    id,
    nest: nestReleaseBundles,
  });
  const dispatch = useDispatch();

  const bundleActions = useMemo(
    () => ({
      removeSuccess: (payload: { id: number | string }) =>
        dispatch(
          entities.deliveryBundles.actions.delete.success({
            payload,
          })
        ),
    }),
    []
  );

  const { deleteEntry: deleteRelease, request } = useDeleteEntity({
    entity: 'releases',
    id,
  });

  const releaseBundles = useMemo<number[]>(
    () =>
      release?.deliveryBundles?.map(
        ({ deliveryBundleId }) => deliveryBundleId
      ) || [],
    [release]
  );

  const { deleteEntry: deleteBundle } = useDeleteEntity({
    entity: 'deliveryBundles',
  });

  const selectDeliveryBundles = useMemo(makeSelectDeliveryBundles, []);

  const deliveryBundles = useSelector((state: ReduxState) =>
    selectDeliveryBundles(state, id)
  );

  const bundles = useMemo(
    () => uniq(concat(deliveryBundles, releaseBundles)),
    [releaseBundles, deliveryBundles]
  );

  const deleteReleaseWithRelatedBundle = useCallback(() => {
    if (bundles) {
      bundles.forEach((bundleId) => {
        bundleActions.removeSuccess({ id: bundleId });
      });
    }
    deleteRelease();
  }, [deleteRelease, deleteBundle, bundles]);

  return {
    deletable: release?.deliveryStatus !== 'not_delivered',
    deleteEntry: deleteReleaseWithRelatedBundle,
    request,
  };
}

const defaultReleasesQuery = {};

export function useReleases({
  nest = listNest,
  query = defaultReleasesQuery,
  ...props
}) {
  const { entries, ...data } = useEntries<ReleasesNested>({
    entity: 'releases',
    query,
    nest,
    ...props,
  });

  const releases: MappedRelease<ReleasesNested>[] | never[] = useMemo(
    () => (entries ? entries.map(mapReleaseCovers) : []),
    [entries]
  );
  return {
    entries: releases,
    ...data,
  };
}
