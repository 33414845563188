import type { InjectedFormProps } from 'redux-form';
import { FieldArray, reduxForm, Form } from 'redux-form';
import type { EntityModels } from 'imddata';
import { useCustomerFeatureRollout, useUpdateEntity } from 'imddata';
import { Centered, LoadingIndicator, FieldGroup, AlertBox } from 'imdui';
import { useTrackFormValues } from 'imdshared/src/Next/TrackForms/MultiEditForm';
import { BuyOrSubOffer, RevenueSplitsField } from 'imdshared';
import { useTranslation } from 'react-i18next';

type Props = { collision: boolean };

type FormData = Partial<{
  revenueSplits: EntityModels.Track['revenueSplits'];
}>;

const EditForm = reduxForm<FormData, Props>({})(({
  collision,
}: Props & InjectedFormProps<FormData, Props>) => {
  const revenueSplitsAllowed = useCustomerFeatureRollout({
    fallback: false,
    feature: 'revenue-splits',
    rolloutKey: 'revenue-splits',
  });
  const { t } = useTranslation();

  return (
    <Form>
      <FieldGroup>
        {collision && revenueSplitsAllowed && (
          <AlertBox
            style={{ marginBottom: '16px' }}
            type={AlertBox.Type.warning}
            text={t('multiedit-revenue-splits-will-override-all', {
              defaultValue:
                'Revenue Splits are different between selected tracks, editing splits will override values on all of them',
            })}
          />
        )}
        {!revenueSplitsAllowed && (
          <BuyOrSubOffer
            subscribeContext={{ analytics: { detail: 'rev-splits' } }}
            title={t('unlock-revenue-splits')}
            onDecideSubscribe={() => {
              if (window.analytics) {
                window.analytics.track(
                  'FT open upsell on track revenue splits'
                );
              }
            }}
            description={t('unlock-revenue-splits-description')}
            style={{ marginBottom: '16px' }}
          />
        )}
        <FieldArray
          disabled={!revenueSplitsAllowed}
          name="revenueSplits"
          // @ts-ignore
          component={RevenueSplitsField}
        />
      </FieldGroup>
    </Form>
  );
});

export const SingleTrackSplitForm = ({ ids }: { ids: number[] }) => {
  const { initialValues, collidedFields } = useTrackFormValues({ ids });
  const { updateEntry } = useUpdateEntity({ entity: 'tracks' });

  if (initialValues === null) {
    return (
      <Centered>
        <LoadingIndicator />
      </Centered>
    );
  }

  return (
    <>
      <EditForm
        collision={!!collidedFields?.revenueSplits}
        onChange={(values, _dispatch, _props, prevValues) => {
          if (!prevValues.revenueSplits) return;
          updateEntry(
            {
              data: {
                id: ids,
                payload: {
                  revenueSplits: values.revenueSplits,
                },
              },
            },
            { debounce: true, multiedit: true }
          );
        }}
        initialValues={initialValues}
        form={'track-splits-' + ids.join(',')}
        key={'track-splits-' + ids.join(',')}
      />
    </>
  );
};
