import { createContext } from 'react';

type LayoutNodes = {
  contentNode?: React.RefObject<React.ElementRef<'div'>>;
  dropdownNode?: React.RefObject<React.ElementRef<'div'>>;
  sideWindowNode?: React.RefObject<React.ElementRef<'div'>>;
};

const DOMNodeContext = createContext<LayoutNodes>({
  contentNode: { current: null },
  dropdownNode: { current: null },
  sideWindowNode: { current: null },
});

DOMNodeContext.displayName = 'DOMNodeContext';

export default DOMNodeContext;
