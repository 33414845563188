// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'imdshared';
import { useCurrentCustomer } from 'imddata';
import styled from '@emotion/styled';
import { ActionGrid, Caption, Content, LinkButton, TextFormatted } from 'imdui';

const Row = styled(Card)`
  padding: 24px;
  border: 2px solid ${(props) => props.theme.state.warning};
`;

export const ActionCard = ({
  style,
  className,
  title,
  caption,
  children,
}: {
  title: string;
  caption?: string;
  children?: React.ReactNode;

  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <Row style={style} className={className}>
      <ActionGrid>
        <div>
          <Content>{title}</Content>
          {caption && (
            <Caption>
              <TextFormatted text={caption} />
            </Caption>
          )}
        </div>
        {children && <div>{children}</div>}
      </ActionGrid>
    </Row>
  );
};

const FillProfileDataForReportsAlertCard = ({
  style = {},
  className,
  text,
}: {
  text: string;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const { t } = useTranslation();
  const { entry } = useCurrentCustomer();

  if (entry && entry.hasAddressSet) return null;
  return (
    <ActionCard
      caption={text || t('alert-fill-profile')}
      title={t('alert-fill-profile-title')}
      style={style}
      className={className}
    >
      <LinkButton
        size="small"
        to="/account/profile"
        text={t('edit')}
      ></LinkButton>
    </ActionCard>
  );
};

export default FillProfileDataForReportsAlertCard;
