import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataRow } from 'imdshared';
import type { ArtistNested } from 'imddata';
import styled from '@emotion/styled';
import { ArtistAvatar } from '@imus/base-ui';
import { Body, Chip, Card, ComponentIcons, Clickable, Content } from 'imdui';
import type { DataRowProps } from '../DataRowListRenderer';
import { useHistory } from 'react-router';

const ArtistStyledRow = styled(DataRow)`
  height: 72px;
  min-height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const MiniAvatar = styled(Card)`
  border-radius: 50%;

  width: 32px;
  display: flex;
  margin: 4px;
  justify-content: center;
  align-items: center;
  height: 32px;
  div,
  img {
    width: 32px;
    height: 32px;
  }
  svg {
    width: 24px;
    height: 32px;
  }
`;

const ArtistChip = styled(Chip)`
  flex-direction: row-reverse;
  border-radius: 20px;
  height: 40px;
  align-items: center;
`;

const ArtistData = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  align-items: center;
  top: 1px;
`;

const ArtistExtra = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddArtistPageButton = styled(Clickable)`
  height: 32px;
  padding: 16px;
  z-index: 2;
  pointer-events: all;
  gap: 8px;
  border-radius: 16px;
  align-items: center;
  svg path {
    stroke: rgba(0, 0, 0, 0.65);
  }
  ${Content} {
    color: rgba(0, 0, 0, 0.65);
  }
  &:hover {
    svg path {
      stroke: rgba(0, 0, 0, 0.9);
    }
    ${Content} {
      color: rgba(0, 0, 0, 0.9);
    }
  }
`;

export const ArtistDataRow = ({
  item,
  checked,
  onCheck,
  onClick,
  ...props
}: DataRowProps<ArtistNested>) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <ArtistStyledRow
      {...props}
      onCheck={(e) => {
        onCheck(e, item, checked);
      }}
      onClick={(e) => {
        onClick(e, item, checked);
      }}
      checked={checked}
      data={
        <ArtistData>
          <Body>{item.defaultName?.name}</Body>

          <ArtistExtra>
            {item.states?.artistHub?.artistPage?.isPublished ? (
              <ArtistChip
                text={t('artist-page')}
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/library/artists/${item.id}/artist-page`);
                }}
              >
                <MiniAvatar>
                  <ArtistAvatar src={item.profileImageFile?.downloadUrl} />
                </MiniAvatar>
              </ArtistChip>
            ) : (
              <AddArtistPageButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(`/library/artists/${item.id}/artist-page`);
                }}
              >
                <ComponentIcons.Plus /> <Content>{t('artist-page')}</Content>
              </AddArtistPageButton>
            )}
          </ArtistExtra>
        </ArtistData>
      }
    ></ArtistStyledRow>
  );
};
