//
import { Component } from 'react';

export default class ChangeTrigger extends Component {
  componentDidMount() {
    const { value, onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  componentDidUpdate(prevProps) {
    const { value, onChange } = this.props;
    if (onChange && prevProps.value !== value) {
      onChange(value);
    }
  }

  render() {
    return null;
  }
}
