import styled from '@emotion/styled';
import { Card, Centered, Content, LoadingIndicator } from 'imdui';

const ReleaseGridCardWrapper = styled(Card)`
  display: grid;
  grid-template-rows: max-content 1fr;
`;

const ReleaseGridCardContent = styled.div`
  padding: 24px 24px 24px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ReleaseGridCardHeader = styled.div`
  padding: 24px 24px 0 24px;
  gap: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ReleaseGridCardAction = styled.div``;

const ReleaseGridCardTitle = styled(Content)`
  display: block;
  flex: 120px;
  flex-shrink: 0;
  flex-grow: 1;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.1px;
`;

export const ReleaseGridCard = ({
  title,
  children,
  action,
  loading,
}: {
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  loading?: boolean;
}) => {
  return (
    <ReleaseGridCardWrapper>
      <ReleaseGridCardHeader>
        <ReleaseGridCardTitle>{title}</ReleaseGridCardTitle>
        <ReleaseGridCardAction>{action}</ReleaseGridCardAction>
      </ReleaseGridCardHeader>
      <ReleaseGridCardContent>
        {!loading ? (
          children
        ) : (
          <Centered>
            <LoadingIndicator />
          </Centered>
        )}
      </ReleaseGridCardContent>
    </ReleaseGridCardWrapper>
  );
};
