import styled from '@emotion/styled';
import { CenterWrapper } from 'imdui';

const ToolbarWrapper = styled(CenterWrapper)<{ hasStepper?: boolean }>`
  z-index: 10;
  overflow: hidden;

  padding-bottom: ${({ hasStepper }) => (hasStepper ? '32px' : '16px')};

  > div {
    margin-top: 40px;
  }
`;

export default ToolbarWrapper;
