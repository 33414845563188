import { types } from 'redux/actions/ui/impersonate';
import { constants, INVALIDATE_AUTH } from 'imddata/actionTypes/auth';

const initialState = null;

const impersonateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_IMPERSONATION: {
      if (!action.payload) return null;
      const { userId, customerId } = action.payload;
      return {
        userId,
        customerId,
      };
    }
    case constants.RESET_LOGGED_USER:
    case INVALIDATE_AUTH:
      return initialState;
    default:
      return state;
  }
};

export default impersonateReducer;
