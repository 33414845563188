//
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { LibraryWrap } from '../../shared/LibraryWrap';
import {
  TrackSideEditor,
  PublisherSideEditor,
  ArtistSideEditor,
  ContributorSideEditor,
  TracksSearch,
} from 'components';
import { Icons, Button } from 'imdshared';
import { useDeleteEntity, useTrack } from 'imddata';
import { useTrackSelection } from 'components/hooks';

const displayedFields = {
  copyrightText: true,
  publishers: false,
  performers: true,
  audioLanguageId: false,
};

const DeleteTrackButton = ({
  id,
  onDeleted,
}: {
  id: number;
  onDeleted: () => void;
}) => {
  const { entry: track } = useTrack({ id, passive: true });
  const { t } = useTranslation();
  const {
    deleteEntry,
    request: { deleted, deleting },
  } = useDeleteEntity({ entity: 'tracks', id });
  useEffect(() => {
    if (deleted) {
      onDeleted();
    }
  }, [deleted]);

  return (
    <Button
      icon={Icons.actions.trash}
      text={t('delete-track', {
        count: 1,
      })}
      style={{ width: '100%' }}
      disabled={!track || track.inReleases?.length > 0 || deleting}
      onClick={() => {
        deleteEntry({ id });
      }}
    />
  );
};

const Actions = ({ selectedTracks }: { selectedTracks: number[] }) => {
  const { cancelSelection } = useTrackSelection();
  if (selectedTracks.length !== 1) {
    return null;
  }

  return (
    <div>
      <DeleteTrackButton id={selectedTracks[0]} onDeleted={cancelSelection} />
    </div>
  );
};

const renderActions = ({ selectedTracks }: { selectedTracks: number[] }) => (
  <Actions selectedTracks={selectedTracks} />
);

export default function Tracks() {
  const { selected, changeSelection, cancelSelection } = useTrackSelection();
  const { t } = useTranslation();

  return (
    <LibraryWrap>
      <Helmet>
        <title>{t('page-title-tracks')}</title>
      </Helmet>

      <TracksSearch
        selectedTracks={selected}
        onChangeSelection={changeSelection}
      />

      <TrackSideEditor
        displayedFields={displayedFields}
        renderActions={renderActions}
        selected={selected}
        onClose={cancelSelection}
      />

      <ContributorSideEditor isAdaptive={false} />

      <ArtistSideEditor isAdaptive={false} />

      <PublisherSideEditor isAdaptive={false} />
    </LibraryWrap>
  );
}
