//
import React, { useState, createContext, useContext } from 'react';
import Tooltip from 'rc-tooltip';
import { identity } from 'ramda';
import styled from '@emotion/styled';
import { decamelize } from 'humps';
import { useEntryProvider } from 'imddata';
import {
  Clickable,
  Block,
  Content,
  Body,
  Caption,
  SVGIcon,
  Icons,
} from 'imdui';
import { palette } from '../../helpers';
import { useTranslation } from 'react-i18next';

const renderBoolean = ({ value }) => (
  <Block
    padding={[4, 4, 4, 4]}
    backgroundColor={value ? palette.main.normal : palette.error}
    borderRadius="50%"
  >
    <SVGIcon
      size={16}
      color="#fff"
      d={value ? Icons.selection.check : Icons.actions.close}
    />
  </Block>
);

const warnCondition = ({ isMet, isRequired }) => {
  if (isRequired) {
    if (isMet) {
      return false;
    }
    return true;
  }
  if (isMet) {
    return false;
  }
  return true;
};

const Tip = styled(Caption)`
  border-bottom: 2px dashed red;
  cursor: help;
  display: inline-flex;
`;

const Condition = ({ message, status, isRequired, isMet }) => {
  const { t, i18n } = useTranslation('admin');
  const tipKey = message + '-targetHelpText';

  return (
    <Block
      borderBottom={true}
      direction="row"
      padding={[0, 10, 0, 10]}
      backgroundColor={warnCondition({ isMet, isRequired }) && '#ff000075'}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isMet && i18n.exists(tipKey, { ns: 'admin' }) ? (
          <Tooltip
            destroyTooltipOnHide={true}
            placement="top"
            trigger={['hover']}
            overlay={<>{t(tipKey)}</>}
          >
            <Tip>{message}</Tip>
          </Tooltip>
        ) : (
          <Caption>{message}</Caption>
        )}

        {isRequired && <Body>*</Body>}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '5px 0 5px auto',
        }}
      >
        <Caption style={{ marginRight: 10 }}>{status}</Caption>
        {renderBoolean({ value: isMet })}
      </div>
    </Block>
  );
};

export const Target = ({
  isMet,
  // isAdmissable,
  isOpen,
  conditions,
  text,
}) => {
  const conditionsKeys = conditions ? Object.keys(conditions) : [];
  const [open, setOpen] = useState(() => isOpen);
  const items = conditionsKeys
    .filter((k) => (!open ? !conditions[k].isMet : true))
    .map((conditionKey) => (
      <Condition key={conditionKey} {...conditions[conditionKey]} />
    ));
  return (
    <Block
      margin={[0, 0, 0, 0]}
      card={true}
      backgroundColor={palette.dark.xDark}
    >
      <Clickable onClick={() => setOpen(!open)}>
        <div
          style={{
            width: '100%',
            padding: '10px 20px 10px 20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Block>
            <Content style={{ color: palette.light.normal }}>
              {decamelize(text)}
            </Content>
          </Block>

          <div style={{ marginLeft: 'auto' }}>
            {renderBoolean({ value: isMet })}
          </div>
        </div>
      </Clickable>

      {items.length > 0 && (
        <Block padding={[10, 10, 10, 10]} backgroundColor={palette.dark.normal}>
          <Block
            card={true}
            padding={[10, 0, 10, 0]}
            backgroundColor={palette.light.light}
          >
            {items}
          </Block>
        </Block>
      )}
    </Block>
  );
};

export const DisplayTargetContext = createContext(true);

DisplayTargetContext.displayName = 'DisplayTargetContext';

export const EntityTargets = ({
  entity,
  isOpen = false,
  fallback,
  entityId,
}) => {
  const displayTarget = useContext(DisplayTargetContext);
  if (!displayTarget) return null;

  const entry = useEntryProvider({ id: entityId, entity });
  if (!entry) return null;
  if (!entry.targets)
    return fallback !== undefined ? fallback : 'no-targets-availble';

  const targets = Object.entries(entry.targets);
  return (
    <>
      {targets.map(([key, target]) =>
        typeof target === 'object' ? (
          <Target
            key={key}
            text={`${entity}:${entityId}:${key}`}
            isOpen={isOpen}
            {...target}
          />
        ) : null
      )}
    </>
  );
};

export default function Targets({ targets, text }) {
  const displayTarget = useContext(DisplayTargetContext);
  if (!displayTarget) return null;
  if (!targets) return 'no-targets';
  const targetsKeys = Object.keys(targets);
  return (
    <div>
      {targetsKeys.map((targetKey) => (
        <Target
          key={targetKey}
          text={text || targetKey}
          {...targets[targetKey]}
        />
      ))}
    </div>
  );
}

Targets.defaultProps = {
  t: identity,
};
