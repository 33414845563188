import React, { useMemo, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { MenuItem, DropdownMenu, IconsCollection, Icon } from 'imdshared';
import type { StylesConfig, ControlProps, OptionProps } from 'react-select';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import type { Query } from 'imddata';
import { QueryChip } from './components';
import type { OptionType } from './types';

/**
 * Custom styles for react-select
 */
const customStyles: StylesConfig<OptionType, true> = {
  control: () => ({
    position: 'relative',
    maxHeight: 88,
    minHeight: 40,
    paddingLeft: 48,
    borderRadius: 12,
    cursor: 'pointer',
    overflow: 'hidden',
    boxShadow: 'inset 0 0 0 1px var(--outline-var)',
    backgroundColor: 'var(--bg-3, #F4F4F4)',
    ':focus': {
      backgroundColor: 'transparent',
    },
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    '> div': {
      display: 'flex',
      flexWrap: 'wrap',
      minHeight: 40,
      maxHeight: 88,
      overflow: 'auto',
    },
  }),
  placeholder: () => ({
    marginTop: 8,
    color: 'rgba(0,0,0,0.6)',
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  input: () => ({
    display: 'flex',
    flex: 1,
    input: {
      flex: 1,
      height: 40,
      display: 'flex',
    },
    margin: 0,
    fontSize: '16px',
    lineHeight: '20px',
  }),
  menuList: (style: any) => ({
    ...style,
    padding: '8px 0',
  }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';
  //
  //   return { ...provided, opacity, transition };
  // }
};

const iconStyle = css`
  position: absolute;
  top: 8px;
  left: 12px;
`;

const Option = ({ getValue, cx, ...props }: OptionProps<OptionType, true>) => {
  return <MenuItem {...props} />;
};

const buildQuery = (values: Array<OptionType>) => {
  if (!values || values.length === null) return null;
  return values.reduce<Record<string, string>>((acc, option: OptionType) => {
    if (!option.id) {
      return acc;
    }
    if (acc[option.queryKey]) {
      return {
        ...acc,
        [option.queryKey]: `${acc[option.queryKey]},${option.id}`,
      };
    }
    return {
      ...acc,
      [option.queryKey]: `${option.id}`,
    };
  }, {});
};

const getDefaultOption = (query: string, prefix: string): OptionType => ({
  id: query,
  value: query,
  label: query,
  prefix,
  queryKey: 'query',
});

const getOptions = (
  options: Array<OptionType>,
  inputValue: string,
  prefix: string
) => {
  const defaultOption = getDefaultOption(inputValue, prefix);
  if (!inputValue || !inputValue.trim().length) {
    return [...options];
  }
  if (inputValue && inputValue.length < 3) {
    return [defaultOption];
  }
  return [defaultOption, ...options];
};

const Control = ({ children, ...props }: ControlProps<OptionType, true>) => (
  <components.Control {...props}>
    <Icon d={IconsCollection.search} css={iconStyle} />

    {children}
  </components.Control>
);

type Props = {
  onInputChange?: (data: { query: string }) => void;
  onChange: (data: {
    value: Array<OptionType>;
    searchQuery: Record<string, string> | null;
  }) => void;
  query?: Query;
  testId?: string;
  value: Array<OptionType>;
  loading?: boolean;
  options: Array<OptionType>;
};

const Input = ({
  hasValue,
  showArrow,
  showUnderline,
  required,
  hideHelperText,
  errorText,
  warningText,
  floatingLabelText,
  acceptValidation,
  testId,
  ...props
}: any) => <components.Input data-test-id={testId} {...props} />;

export default function QueryBuilderInput({
  onChange,
  onInputChange,
  query,
  testId,
  value,
  loading,
  options,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const data = useMemo(
    () => getOptions(options, inputValue, t('search-for')),
    [query?.query, options, inputValue]
  );

  const handleInputChange = useCallback(
    (newInpuValue) => {
      if (onInputChange) {
        onInputChange({ query: newInpuValue });
      }
      setInputValue(newInpuValue);

      return newInpuValue;
    },
    [onInputChange]
  );

  const handleChange = useCallback(
    (newValue) => {
      if (onChange) {
        onChange({ value: newValue || [], searchQuery: buildQuery(newValue) });
      }
    },
    [onChange]
  );

  return (
    <DropdownMenu<OptionType, true>
      testId={testId}
      value={value}
      name="filter-bar"
      styles={customStyles}
      components={{
        Option,
        Input,
        MultiValue: QueryChip,
        Control,
        // Placeholder: components.Placeholder,
      }}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isMulti={true}
      data={data}
      placeholder={loading ? t('loading') : t('type-to-filter')}
    />
  );
}
