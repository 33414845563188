import { DeleteIcon } from '@imus/base-ui';
import { useDeleteEntity, useArtist } from 'imddata';
import { Button, HelperText } from 'imdui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const DeleteArtistButon = ({
  id,
  onDeleted,
}: {
  id: number;
  onDeleted: () => void;
}) => {
  const { entry: artist } = useArtist({ id, passive: true });
  const { t } = useTranslation();
  const {
    deleteEntry,
    request: { deleted, deleteErrorMessage, deleting },
  } = useDeleteEntity({ entity: 'artists', id });

  useEffect(() => {
    if (deleted) {
      onDeleted();
    }
  }, [deleted]);

  return (
    <>
      <Button
        iconLeft={DeleteIcon}
        text={t('delete-artist', {
          count: 1,
        })}
        style={{ width: '100%' }}
        showLoading={deleting}
        disabled={deleting || artist?.deliveryStatus !== 'not_delivered'}
        onClick={() => {
          deleteEntry({ id });
        }}
      />
      {deleteErrorMessage && <HelperText errorText={deleteErrorMessage} />}
    </>
  );
};
