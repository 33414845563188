import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createEntity, updateEntity } from 'imddata/actionTypes/request';
import { Button, FieldGroup, FormToolbar } from 'imdui';
import { NewInputField } from '../../../fields';
import { validatePublisherForm } from '../../PublisherForms';

const Form = ({
  form,
  impersonate,
  onSaved,
  createPublisher,
  submitting,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const submitForm = useCallback(
    (values) => {
      return new Promise((resolve) => {
        createPublisher(
          {
            formId: form,
            data: values,
          },
          {
            impersonate,
            resolve: (data) => {
              onSaved({
                result: data.result,
                entities: {
                  items: data.entities.publishers,
                  names: data.entities.publishersNames,
                },
              });
              resolve();
            },
          }
        );
      });
    },
    [createPublisher, form, impersonate, onSaved]
  );

  return (
    <form data-test-id="new-publisher-form" onSubmit={handleSubmit(submitForm)}>
      <FieldGroup>
        <Field
          name="name"
          label={t('name')}
          renderAsDistinctBlock={true}
          autoFocus={true}
          component={NewInputField}
        />
      </FieldGroup>

      <FormToolbar
        rightChildren={
          <Button
            primary={true}
            data-test-id="add-new-publisher"
            type="submit"
            text={t('add-publisher')}
            showLoading={submitting}
          />
        }
      />
    </form>
  );
};

export default connect(null, {
  createPublisher: (...args) => createEntity('publishers', ...args),
  updatePublisher: (...args) => updateEntity('publishers', ...args),
})(
  reduxForm({
    form: 'publisherEditForm',
    getFormState: (state) => state.form,
    validate: validatePublisherForm,
  })(Form)
);
