//
import React, { useRef } from 'react';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, SubmissionError, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  useUpdateEntity,
  useCurrentUser,
  callAuthApi,
  useCreateEntity,
} from 'imddata';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
  TextFormatted,
  CheckboxField,
  Block,
  Subheading,
  Button,
  NewInputField,
  palette,
} from 'imdshared';

const DELETE_PROFILE_REASONS = ['1', '2', '3', '4', '5', '6'];

type FormData = {
  other?: boolean;
  otherText?: number;
  reasons: Record<string, boolean>;
};

function ProfileDeletion({
  error,
  submitting,
  submitSucceeded,
  submitFailed,
  handleSubmit,
  pristine,
}: InjectedFormProps<FormData>) {
  const { code } = useParams<{ code: string }>();
  const { t } = useTranslation();
  const { entry: user } = useCurrentUser();
  const trackingId = useRef(user?.trackingId);

  const { createEntry } = useCreateEntity({ entity: 'contactRequests' });

  const { updateEntry: updateUser } = useUpdateEntity({
    entity: 'users',
    id: user?.id,
  });

  const submitForm = ({ other, otherText, reasons }: FormData) => {
    const deleteReasons = { other, otherText, reasons };
    createEntry({
      data: { event: 'customer-deletion-reasons', payload: deleteReasons },
    });
    updateUser({
      data: {
        settings: {
          deleteReasons,
        },
      },
    });
    return callAuthApi.deleteProfile(code).then((response) => {
      if ('error' in response && 'data' in response.error) {
        const { data } = response.error;
        if (data && data.currentStatus) {
          throw new SubmissionError({
            _error: t('profile-deletion-already-requested-error--formatted'),
          });
        } else {
          throw new SubmissionError({
            _error: t('profile-deletion-invalid-token--formatted'),
          });
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Helmet>
        <title>{t('page-title-profile-deletion')}</title>
      </Helmet>

      <Block padding={[30, 20, 20, 20]}>
        <Block margin={[0, 0, 25, 0]}>
          <Subheading style={{ color: palette.dark.xLight }}>
            {t('delete-profile')}
          </Subheading>
        </Block>

        {submitFailed && (
          <Block margin={[0, 0, 15, 0]}>
            <TextFormatted text={error} />
          </Block>
        )}

        {submitSucceeded && (
          <Block margin={[0, 0, 15, 0]}>
            <TextFormatted
              text={t('profile-deleted', { trackingId: trackingId.current })}
            />
          </Block>
        )}

        {!submitSucceeded && !submitFailed && (
          <>
            <Block margin={[0, 0, 15, 0]}>
              <TextFormatted
                text={t('please-provide-profile-deletion-reason')}
              />
            </Block>

            {DELETE_PROFILE_REASONS.map((reasonKey) => (
              <Field
                key={reasonKey}
                name={`reasons.${reasonKey}`}
                text={t(`delete-profile-reason-${reasonKey}-label`)}
                component={CheckboxField}
              />
            ))}

            <Field
              name="other"
              text={t(`delete-profile-reason-other-label`)}
              component={CheckboxField}
            />

            <Block margin={[15, 0, 0, 0]}>
              <Field
                name="otherText"
                floatingLabelText={t(
                  `delete-profile-reason-other-textbox-label`
                )}
                multiline={true}
                component={NewInputField}
              />
            </Block>

            <Button
              type="submit"
              style={{ marginTop: 16, width: '100%' }}
              primary={true}
              disabled={pristine}
              showLoading={submitting}
              text={t('delete-profile')}
            />
          </>
        )}
      </Block>
    </form>
  );
}

export default reduxForm<FormData>({
  form: 'deleteProfile',
  initialValues: {
    reasons: DELETE_PROFILE_REASONS.reduce(
      (acc, key) => ({
        ...acc,
        [key]: false,
      }),
      {}
    ),
    other: false,
  },
})(ProfileDeletion);
