//
import * as R from 'ramda';

function getGroupData({ oldGroups, action }) {
  if (action.response.groups) {
    const newGroups = action.response.groups; // Comes from normalizing response

    return { grouped: true, groups: [...oldGroups, ...newGroups] };
  }
  return { groups: oldGroups };
}

export const mergeKeys = (
  action,
  { keys: oldKeys = [], groups: oldGroups = [], ...data } = {}
) => {
  // We don't want to update list of loaded entites if we load just one
  const groupData = getGroupData({ oldGroups, action });

  if (!Array.isArray(action.response.result))
    return { ...groupData, ...data, keys: oldKeys };

  const newKeys = action.response.result; // Comes from normalizing response

  // Reset keys if we fetched first page
  if (
    action.responseDetails.currentPage === 1 &&
    action.payload &&
    !action.meta.preventKeysReset
  ) {
    return {
      ...data,
      ...groupData,
      keys: newKeys,
    };
  }
  return {
    ...data,
    ...groupData,
    keys: R.uniq([...(oldKeys || []), ...newKeys]),
  };
};

export const deleteKey = (id, keys) => {
  if (!keys) return [];
  const finalId = !Number.isNaN(id) ? Number(id) : id;

  return R.without([finalId], keys);
};

const NEW_KEYS_TO_END = ['releasePagesPressFiles'];

export const generateKeysOnCreate = (
  action,
  { keys = [], newItemsKeys = [], ...data }
) => {
  const {
    payload: { tempId },
    response: { result },
  } = action;
  const newId =
    typeof result === 'string' || typeof result === 'number'
      ? result
      : typeof result === 'object'
        ? result?.item
        : undefined;
  if (tempId) {
    const tempIndex = R.findIndex(R.equals(tempId), keys);
    const tempIndexInNewKeys = R.findIndex(R.equals(tempId), newItemsKeys);
    if (!R.isNil(tempIndex)) {
      return {
        keys: R.set(R.lensIndex(tempIndex), newId, keys),
        newItemsKeys:
          tempIndexInNewKeys >= 0
            ? R.set(R.lensIndex(tempIndexInNewKeys), newId, newItemsKeys)
            : newItemsKeys,
        ...data,
      };
    }
  }
  if (newId === null || newId === undefined)
    return { keys, newItemsKeys, ...data };
  if (!keys) {
    return { keys: [newId], ...data };
  }
  if (NEW_KEYS_TO_END.indexOf(action.entityKey) >= 0) {
    return { keys: [...keys, newId], ...data };
  }
  return { keys: [newId, ...keys], ...data };
};

export const removeKeysBeforeMerge = ({ keys, mainEntity, stateEntity }) =>
  mainEntity
    ? {
        ...stateEntity,
        ...R.keys(mainEntity).reduce(
          (acc, entityId) =>
            stateEntity[entityId]
              ? {
                  ...acc,
                  [entityId]: keys.reduce((entityAcc, key) => {
                    if (mainEntity[entityId][key]) {
                      return {
                        ...entityAcc,
                        [key]: undefined,
                      };
                    }
                    return entityAcc;
                  }, stateEntity[entityId]),
                }
              : acc,
          {}
        ),
      }
    : stateEntity;
