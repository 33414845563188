import styled from '@emotion/styled';
import type { Theme, SerializedStyles } from '@emotion/react';
import { css, keyframes } from '@emotion/react';
import { Content } from 'imdui';

//
// the size of margin between steps depends on screen width
//
const smallGap = 16;
const largeGap = 96;

const repeatCandybar = keyframes`
  0% {
    background-position: -19px;
  }
  100% {
    background-position: 0px;
  }
`;

export const ItemWrapper = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 96px;

  > svg {
    fill: ${({ theme, selected }) =>
      selected ? theme.accent.green : theme.lights[3]};
  }

  > ${Content} {
    color: ${({ theme, selected }) =>
      selected ? theme.darks[2] : theme.darks[4]};
  }

  :not(:last-child) {
    margin-right: ${smallGap}px;
  }

  @media (min-width: 800px) {
    :not(:last-child) {
      margin-right: ${largeGap}px;
    }
  }
`;

export const wrapperStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 24px 0 60px;
`;

export const itemsWrapperStyle = css`
  display: flex;
`;

export const ProgressBar = styled.div<{
  fullyCompleted: boolean;
}>`
  position: absolute;
  z-index: -1;
  display: flex;
  top: 11px;
  left: 0;
  right: 0;
  height: 2px;
  border-radius: 1px;
  overflow: hidden;
  background-color: ${({ theme, fullyCompleted }) =>
    fullyCompleted ? theme.accent.green : theme.lights[2]};

  > * {
    height: 2px;
  }

  > *:first-child,
  > *:last-child {
    flex: 1;
  }
`;

export const progressStyle = (theme: Theme): SerializedStyles => css`
  background-color: ${theme.accent.green};
`;

export const GapWrapper = styled.div<{
  completed: boolean;
}>`
  display: flex;

  > *::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
  }

  > *:first-child {
    background: ${({ theme, completed }) =>
      completed ? theme.accent.green : null};
  }

  > *:nth-child(2)::after {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDE5IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxtYXNrIGlkPSJtYXNrMCIgbWFzay10eXBlPSJhbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE5IiBoZWlnaHQ9IjIiPgo8cmVjdCB3aWR0aD0iMTkiIGhlaWdodD0iMiIgZmlsbD0iI0M0QzRDNCIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazApIj4KPHBhdGggZD0iTTAgMEg3TDkgMkgyTDAgMFoiIGZpbGw9IiNDNEM0QzQiLz4KPC9nPgo8L3N2Zz4K);
    animation: ${repeatCandybar} 1s linear infinite;
    will-change: background-position;
    display: ${({ completed }) => (completed ? 'initial' : 'none')};
  }
`;

export const gapStyle = css`
  position: relative;
  height: 2px;

  width: ${96 + smallGap}px;

  @media (min-width: 800px) {
    width: ${96 + largeGap}px;
  }
`;
