import React from 'react';
import styled from '@emotion/styled';
import { Bar, IconButton, Icons } from 'imdui';

const NotificationDetailsBar = styled(Bar)`
  width: 100%;
  padding: 0 8px;
`;

type Props = {
  onClickBack: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const NotificationsHeader: React.FC<Props> = ({ onClickBack }) => (
  <NotificationDetailsBar>
    <IconButton
      style={{
        position: 'relative',
        zIndex: 2,
      }}
      icon={Icons.arrows.back}
      onClick={onClickBack}
    />
  </NotificationDetailsBar>
);

export default NotificationsHeader;
