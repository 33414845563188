//
import { put, takeLatest } from 'redux-saga/effects';
import entities from 'imddata/actionTypes/entities';
import { createEntity } from 'imddata/actionTypes/request';

function* refetchOrders() {
  yield put(
    createEntity('ordersOverview', {
      componentKey: 'OrdersCountProvider',
    })
  );
}

export default function* runOrders() {
  yield takeLatest(
    [
      entities.orders.types.delete.SUCCESS,
      entities.createOrder.types.create.SUCCESS,
      entities.invoices.types.create.SUCCESS,
      entities.invoices.types.update.SUCCESS,
    ],
    refetchOrders
  );
}
