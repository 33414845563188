import { types } from '../../actionTypes/ui/signup';

const initialState = {};

type SignupCodes = {
  affiliate: string | undefined;
  referral: string | undefined;
};

type Action = {
  type: types.SET_SIGNUP_CODES;
  payload: { codes: SignupCodes };
};

export type UiSignupState = {
  codes?: SignupCodes;
};

const signupReducer = (
  state: UiSignupState = initialState,
  action: Action
): UiSignupState => {
  switch (action.type) {
    case types.SET_SIGNUP_CODES: {
      const { codes } = action.payload;
      return {
        codes,
      };
    }
    default:
      return state;
  }
};

export default signupReducer;
