import React, { useCallback, useMemo, useState } from 'react';
import { OverlineText, Window, Tooltip, FieldCard, Chip, Body } from 'imdui';
import type { EntityModels } from 'imddata';
import { useCustomerFeature, useShops } from 'imddata';
import { useTranslation } from 'react-i18next';
import { isChecked, getDeliveryStatus } from '../DeliveryRegionsField';
import styled from '@emotion/styled';
import type { i18n } from 'i18next';

const FieldCardToolbar = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid var(--fg-4);
  flex: auto;
  flex: 100%;
  display: flex;
`;

export type ShopsFieldValue = {
  otherShopsDownloads: boolean;
  otherShopsStreaming: boolean;
  shops: Array<{ shopId: string; status: boolean | string }>;
};

type DisabledFunc = (
  value: { shopId: string; status: string | boolean },
  v?: ShopsFieldValue
) => string | boolean;

export const getShopTranslationKey = (s: EntityModels.Shop['id']) => {
  return s.replaceAll('_', '-');
};

export const getShopTipText = (
  i18n: i18n,
  shopId: EntityModels.Shop['id'],
  value?: ShopsFieldValue,
  isDisabled?: DisabledFunc
) => {
  const hasAppleMusicClassical = isDisabled
    ? !isDisabled(
        {
          shopId: 'apple-music-classical',
          status: '',
        },
        value
      )
    : false;
  const tipContext =
    shopId === 'apple_music' && hasAppleMusicClassical ? 'classical' : '';
  const tipKey = `${getShopTranslationKey(shopId)}-tip`;

  const hasTip = i18n.exists(tipKey);
  return hasTip ? i18n.t(tipKey, { context: tipContext }) : null;
};

export default function ShopsField({
  input: { value: baseValue, onChange },
  meta: { error, touched },
  isDisabled,
  disabled,
}: {
  gridItemMinWidth?: number;
  disabled?: boolean;
  meta: { form: string; error: any; touched: boolean };
  input: {
    value?: ShopsFieldValue;
    onChange: any;
    onFocus: any;
    onBlur: any;
  };

  isDisabled?: DisabledFunc;
}) {
  const { t, i18n } = useTranslation();
  const { entries: shopEntries } = useShops();
  const shops = useMemo(
    () => shopEntries.filter((s) => s.isActive),
    [shopEntries]
  );
  const [openedShopError, setOpenShopError] = useState('');

  const value = useMemo<ShopsFieldValue | undefined>(() => {
    if (!baseValue) return undefined;
    if (!shopEntries) return baseValue;
    return {
      otherShopsDownloads: baseValue.otherShopsDownloads,
      otherShopsStreaming: baseValue.otherShopsStreaming,
      shops: baseValue.shops.sort((a, b) => {
        const aIdx = shopEntries.findIndex((s) => a.shopId === s.id);
        const bIdx = shopEntries.findIndex((s) => b.shopId === s.id);
        return aIdx - bIdx;
      }),
    };
  }, [baseValue, !!shopEntries]);

  const distributeToPremiumShopsFeature = useCustomerFeature(
    'distribute-to-premium-shops'
  );

  const distributeClassical = useCustomerFeature('deliver-classical-releases');

  // const reachedLimit = shopsLimit && value && countChecked(value) >= shopsLimit;
  // Add check all button
  // Add Header
  // Add Caption about beatport
  // Shop caption in red when label/genre present

  const Shop = useCallback(
    ({ shop }: { shop: EntityModels.Shop }) => {
      const shopValue = value?.shops?.find((v) => v.shopId === shop.id);
      const selected = shopValue && isChecked(shopValue?.status);
      const shopSelectionError = isDisabled
        ? isDisabled(shopValue || { shopId: shop.id, status: false }, value)
        : false;

      const tip = getShopTipText(i18n, shop.id, value, isDisabled);

      return (
        <span className="shop-tip" data-tooltip-content={tip}>
          <Chip
            disabled={disabled}
            state={selected ? 'primary' : undefined}
            testId={`Shop-${shop.id}-${selected ? 'checked' : 'unchecked'}`}
            onClick={() => {
              const withoutSelectedShop =
                value?.shops.filter((sv) => sv.shopId !== shop.id) || [];
              const status = getDeliveryStatus(shopValue?.status, !selected);
              if (status === false) {
                onChange({ ...value, shops: withoutSelectedShop });
                return;
              }

              if (typeof shopSelectionError === 'string') {
                setOpenShopError(shopSelectionError);
                return;
              }

              onChange({
                ...value,
                shops: [...withoutSelectedShop, { status, shopId: shop.id }],
              });
            }}
            text={
              <>
                {t(getShopTranslationKey(shop.id), { defaultValue: shop.name })}
              </>
            }
          ></Chip>
        </span>
      );
    },
    [
      value,
      isDisabled,
      disabled,
      distributeClassical,
      distributeToPremiumShopsFeature,
    ]
  );

  const otherShopsDownloadsDisabled = useMemo(
    () =>
      isDisabled
        ? isDisabled(
            {
              shopId: 'otherShopsDownloads',
              status: value?.otherShopsDownloads || false,
            },
            value
          )
        : false,
    [isDisabled, value]
  );

  return (
    <div tabIndex={-1}>
      <OverlineText label={t('select-shops')} error={touched && !!error} />

      <FieldCard status={touched && error ? 'error' : undefined}>
        {shops.map((s) => {
          return <Shop key={s.id} shop={s} />;
        })}

        <Chip
          style={{
            gridColumn: '1 / -1',
          }}
          disabled={disabled}
          state={value?.otherShopsStreaming ? 'primary' : undefined}
          onClick={() => {
            if (disabled) return;
            onChange({
              ...value,
              otherShopsDownloads: !value?.otherShopsDownloads,
              otherShopsStreaming: !value?.otherShopsStreaming,
            });
          }}
          text={t('all-other')}
        />
        <FieldCardToolbar>
          <Chip
            disabled={disabled}
            text={t('select-all', { context: 'shops' })}
            testId="ShopsSelector-toggle-all"
            onClick={() => {
              if (disabled) return;
              onChange({
                otherShopsDownloads: !otherShopsDownloadsDisabled,
                otherShopsStreaming: !otherShopsDownloadsDisabled,
                shops: shops
                  .map((s) => {
                    const shopValue = value?.shops?.find(
                      (v) => v.shopId === s.id
                    );
                    const status = getDeliveryStatus(shopValue?.status, true);
                    const disabled = isDisabled
                      ? isDisabled(
                          { shopId: s.id, status: isChecked(status) },
                          value
                        )
                      : false;
                    return {
                      shopId: s.id,
                      status: disabled ? false : status,
                    };
                  })
                  .filter((s) => s.status !== false),
              });
            }}
          />
        </FieldCardToolbar>
      </FieldCard>

      <Window
        title={t('shop-error')}
        isOpen={!!openedShopError}
        close={() => {
          setOpenShopError('');
        }}
      >
        {openedShopError && (
          <div style={{ padding: '0 32px 32px' }}>
            <Body>{t(openedShopError)}</Body>
          </div>
        )}
      </Window>
      <Tooltip anchorSelect=".shop-tip" />
    </div>
  );
}
