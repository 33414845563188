interface ReduxMeta {
  resolve?: any;
}

interface ReduxAction<T, K extends string> {
  type: K;
  payload: T;
  meta: ReduxMeta;
}

export default function action<T, K extends string>(
  type: K,
  payload: T,
  meta: ReduxMeta = {}
): ReduxAction<T, K> {
  return { type, payload, meta };
}
