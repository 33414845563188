import { useTranslation } from 'react-i18next';
import { actions } from 'imddata/actionTypes/ui/selection';
import type { TrackForms } from 'imdshared';
import { ArtistForms, SideWindow } from 'imdshared';
import React, { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { FieldGroup, OptionTabs } from 'imdui';
import type { ReduxState } from 'imddata';
import SideEditorHeader from '../../atoms/SideEditorHeader';
import { SideEditorContainer } from '../../molecules/SideEditor';
import { useDispatch, useSelector } from 'react-redux';
import { ArtistPageEditor } from './ArtistPageEditor/ArtistPageEditor';
import { DeleteArtistButon } from './DeleteArtistButton';

type CommonProps = {
  renderActions?: (p: { selectedTracks: number[] }) => React.ReactNode;
  displayedFields?: React.ComponentProps<
    typeof TrackForms.Single
  >['displayedFields'];
  additionalValidation?: (...args: unknown[]) => Record<string, any>;
  initialTab?: EditorTab | undefined;
  requiredFields?: string[];
};

type EditorTab = 'artist-page' | 'edit';

const ArtistSideEditorContent = ({
  label,
  selection,
  initialTab = 'edit',
  warn,
  onClose,
}: {
  onClose?: () => void;
  label: string;
  selection: (number | string)[];
  warn?: any;
  initialTab?: EditorTab;
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<EditorTab>(initialTab);

  const dispatch = useDispatch();

  const onClearSelection = useCallback(() => {
    dispatch(
      actions.updateSelection({
        entity: 'artists',
        selected: [],
      })
    );
    if (onClose) {
      onClose();
    }
  }, []);

  const tabOptions = useMemo(
    () =>
      [
        { value: 'edit', label: t('edit') },
        { value: 'artist-page', label: t('artist-page') },
      ] as const,
    []
  );

  return (
    <>
      <SideEditorHeader label={label} onClickClose={onClearSelection}>
        <OptionTabs
          style={{ flex: '1', width: '100%' }}
          value={selectedTab}
          options={tabOptions}
          onChange={setSelectedTab}
        />
      </SideEditorHeader>
      {selection.length === 1 && (
        <SideEditorContainer>
          {selectedTab === 'artist-page' && (
            <ArtistPageEditor artistId={Number(selection[0])} />
          )}

          {selectedTab === 'edit' && (
            <ArtistForms.Single
              warn={warn}
              id={selection[0] as number}
              form={'single-artist-form-' + selection[0]}
            >
              <FieldGroup>
                <DeleteArtistButon
                  id={selection[0] as number}
                  onDeleted={onClearSelection}
                />
              </FieldGroup>
            </ArtistForms.Single>
          )}
        </SideEditorContainer>
      )}
    </>
  );
};

const sideEditorStyle = css`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow: hidden;
`;

const ArtistSideEditor: React.FC<
  {
    isAdaptive?: boolean;
    onClose?: () => void;
  } & CommonProps
> = ({ onClose, initialTab }) => {
  const { t } = useTranslation();

  const selection = useSelector(
    (state: ReduxState) => state.ui.selection.artists || []
  );

  const label = t('selected-artists', { count: selection?.length });

  return (
    <SideWindow
      testId="TrackSideEditor"
      isOpen={!!selection.length}
      css={sideEditorStyle}
    >
      <ArtistSideEditorContent
        initialTab={initialTab}
        onClose={onClose}
        label={label}
        selection={selection}
      />
    </SideWindow>
  );
};

export default ArtistSideEditor;
