//
import React, { useMemo } from 'react';
import { ComponentIcons } from 'imdui';
import { find, filter } from 'ramda';
import { useTranslation } from 'react-i18next';
import constants from '../constants';
import { createJoinTagField } from '../../helpers';
import JoinTags from '../JoinTags';
import EditItemForm from './EditItemForm';

export const { roles } = constants.contributors;

const options = (t) =>
  roles.reduce(
    (acc, role) => ({
      ...acc,
      [role.value]: {
        static: true,
        value: role.value,
        deprecated: role.deprecated,
        disabled: role.disabled,
        label: t(role.label),
      },
    }),
    {}
  );

const contributorRoles = (t) => ({
  label: 'Role',
  key: 'role',
  isDisabled: ({ value, option, values }) => {
    if (option.disabled) {
      return true;
    }
    if (
      value.id === 'traditional' &&
      (option.value === 'arranger' || option.value === 'remixer')
    ) {
      return true;
    }

    const noAvailable =
      filter((entry) => entry.id === value.id, values).length === roles.length;

    return (
      noAvailable ||
      find(
        (entry) =>
          option.value === entry.role &&
          entry.role !== value.role &&
          entry.id === value.id,
        values
      )
    );
  },
  order: roles.map((role) => role.value),
  options: options(t),
});

const createData = (t) => ({
  tags: ['roles'],
  roles: contributorRoles(t),
});

const defaultRole = 'composer';

const filterDeprecated = ({ data }) => !data.deprecated;

export const mapContributorEntry = (result, contributor) => {
  const valid = contributor.targets?.addToDelivery?.isMet !== false;

  return {
    ...result,
    label: !contributor.defaultName.firstName ? (
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        <ComponentIcons.Error fill={'red'} style={{ margin: '0 8px 0 auto' }} />
        {contributor.defaultName.artistName}
      </span>
    ) : (
      contributor.defaultName.name
    ),
    invalid: !valid,
  };
};

const ContributorJoin = ({ ...props }) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return createData(t);
  }, []);
  return (
    <JoinTags
      renderForm={EditItemForm}
      allowDuplicates={true}
      testId="contributors"
      data={data}
      mapEntry={mapContributorEntry}
      defaultRole={defaultRole}
      placeholder={t('type-name', { defaultValue: 'Type name' })}
      idKey="contributorId"
      addLabel={t('add-contributor')}
      createLabel={t('create-contributor')}
      label={t('add-contributor')}
      entity="contributors"
      filterOption={filterDeprecated}
      {...props}
    />
  );
};

export const ContributorJoinField = createJoinTagField(
  ContributorJoin,
  'contributors'
);
export default ContributorJoin;
