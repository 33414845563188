import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationsCountProvider } from 'components/logic';
import {
  NotificationsButton,
  NotificationsWindowController,
} from './components';

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Notifications: React.FC<Props> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [location]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    onClick(e);
    setIsOpen(true);
  };

  const handleClickClose = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <NotificationsCountProvider>
        {(unreadCount): JSX.Element => (
          <NotificationsButton
            unreadCount={unreadCount}
            onClick={handleClick}
          />
        )}
      </NotificationsCountProvider>

      <NotificationsWindowController
        isOpen={isOpen}
        onClickClose={handleClickClose}
      />
    </>
  );
};

export default Notifications;
