import React, { useState, useCallback, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { EntityModels, InstantMasteringTrackNested } from 'imddata';
import { useDeleteEntity } from 'imddata';
import type { Props as ProgressBarProps } from '../../atoms/ProductProgressBar';
import ProductProgressBar from '../../atoms/ProductProgressBar';
import moment from 'moment';

const getText = ({
  t,
  trackCount,
  status,
  previewsProgress,
  masteringProgress,
  latestEstimateText,
}: {
  t: TFunction;
  latestEstimateText: string;
  trackCount: number;
  status: EntityModels.InstantMastering['status'];
  previewsProgress: number;
  masteringProgress: number;
}) => {
  if (masteringProgress === 100) {
    return {
      title: t('mastering-done'),
      description: '',
    };
  }
  switch (status) {
    case 'done': {
      return {
        title: t('mastering-done'),
        description: '',
      };
    }
    case 'paid':
      return {
        title: t('mastering-tracks-in-progress', {
          count: trackCount,
          estimate: latestEstimateText,
        }),
        description: '',
      };
    case 'pending':
      return {
        title: t('instant-mastering'),
        description: t('finish-order'),
      };
    case 'previewing':
      return {
        title:
          previewsProgress !== 100
            ? t('mastering-previews-in-progress')
            : t('mastering-previews-complete'),
        description:
          previewsProgress !== 100
            ? t('mastering-preview-generation-in-progress')
            : t('mastering-preview-generation-complete', { count: trackCount }),
      };

    default:
      return {
        title: '',
        description: '',
      };
  }
};
type MastringEntry = {
  deletedAt: string;
  masteringProgress: number;
  previewsProgress: number;
  status: EntityModels.InstantMastering['status'];
  id: number;
  tracks: Array<InstantMasteringTrackNested>;
};

type Props<T> = Omit<ProgressBarProps, 'title' | 'description' | 'progress'> & {
  entry: T;
  hideOnFinish?: boolean;
  hideActions?: boolean;
};

export default function MasteringProductBar<T extends MastringEntry>({
  entry: { tracks, id, previewsProgress, masteringProgress, status, deletedAt },
  hideOnFinish,
  hideActions,
  ...props
}: Props<T>) {
  const { t } = useTranslation();
  const trackCount = tracks?.length;
  const latestEstimatedTrack: null | InstantMasteringTrackNested = tracks
    .filter((t) => !!t.estimatedCompletionAt)
    .sort(
      (a, b) =>
        new Date(b.estimatedCompletionAt!).valueOf() -
        new Date(a.estimatedCompletionAt!).valueOf()
    )[0];

  const latestEstimateText = latestEstimatedTrack
    ? moment().to(latestEstimatedTrack.estimatedCompletionAt)
    : '';
  console.log(
    latestEstimateText,
    tracks
      .filter((t) => !!t.estimatedCompletionAt)
      .sort(
        (a, b) =>
          new Date(b.estimatedCompletionAt!).valueOf() -
          new Date(a.estimatedCompletionAt!).valueOf()
      )
  );
  const { title, description } = getText({
    t,
    status,
    masteringProgress,
    previewsProgress,
    trackCount,
    latestEstimateText,
  });
  const { deleteEntry } = useDeleteEntity({ entity: 'instantMasterings' });
  const [redirect, setRedirect] = useState(false);

  const onCancel = useCallback(() => {
    deleteEntry({ id });
  }, [id]);

  const cancellable = status === 'previewing' || status === 'open';

  const onContinue = useMemo(
    () =>
      status === 'paid'
        ? undefined
        : () => {
            setRedirect(true);
          },
    [status]
  );

  if (
    hideOnFinish &&
    (status === 'done' || (status === 'previewing' && previewsProgress === 100))
  ) {
    return null;
  }

  if (deletedAt) return null;

  if (redirect) {
    if (status === 'done') {
      return <Redirect to="/library/tracks" />;
    }
    if (status === 'pending') {
      return <Redirect to={`/order/overview`} />;
    }
    return <Redirect to={`/order/mastering/${id}`} />;
  }

  return (
    <ProductProgressBar
      {...props}
      title={title}
      description={description}
      progress={previewsProgress}
      hideProgress={trackCount === 1}
      hideActions={hideActions}
      onCancel={cancellable ? onCancel : undefined}
      onContinue={onContinue}
    />
  );
}
