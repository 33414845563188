import React from 'react';
import type { ChangesProps } from '../../types';
import ChipsGroup from './ChipsGroup';
import ChipChange from './ChipChange';

const Shops: React.FC<ChangesProps> = ({ changes }) => {
  return (
    <ChipsGroup groupKey="shops">
      {changes.map((c) => (
        <ChipChange key={c.id} change={c} label={c.details.shopName} />
      ))}
    </ChipsGroup>
  );
};

export default Shops;
