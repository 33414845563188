import {
  compose,
  map,
  over,
  mergeLeft,
  mergeDeepRight,
  lensPath,
  adjust,
  propEq,
  addIndex,
  findIndex,
} from 'ramda';
import { types } from '../../actions/ui/products';

export type ProductStepState = {
  id: string;
  label?: string;
  visited?: boolean;
  valid?: boolean;
  active?: boolean;
  verifying?: boolean;
  updating?: boolean;
};

export type UiProductState = {
  createdAt: number;
  id: string;
  data: Record<string, unknown>;
  steps: Array<ProductStepState>;
};

type ReducerProductState = Record<string, UiProductState | undefined>;

export type State = Record<string, ReducerProductState>;

type Action =
  | {
      type: types.INIT_PRODUCT_PAGE;
      payload: {
        data?: Record<string, unknown>;
        id: string;
        product: string;
        steps: { id: string }[];
      };
    }
  | {
      type: types.DELETE_PRODUCT_PAGE;
      payload: {
        id: string;
        product: string;
      };
    }
  | {
      type: types.CHANGE_STEP_STATE;
      payload: {
        id: string;
        product: string;
        stepId: string;
        payload: Record<string, unknown>;
      };
    }
  | {
      type: types.CHANGE_PRODUCT_PAGE;
      payload: {
        id: string;
        product: string;
        data: Record<string, unknown>;
      };
    };

const mapIndexed = addIndex(map);

const products = (state: State = {}, action: Action): State => {
  switch (action.type) {
    case types.INIT_PRODUCT_PAGE: {
      const { id, product, data = {}, steps } = action.payload;
      // @ts-ignore
      return mergeDeepRight(state, {
        [product]: {
          [id]: {
            createdAt: Date.now(),
            id,
            data,
            steps: steps.map((step) => ({
              id: step.id,
              visited: false,
              valid: true,
              verifying: false,
            })),
          },
        },
      });
    }

    case types.CHANGE_PRODUCT_PAGE: {
      const { id, product, data } = action.payload;
      const value = over(
        lensPath([product, id, 'data']),
        (dataState) => {
          return mergeDeepRight(dataState, data);
        },
        state
      );
      return value;
    }

    case types.DELETE_PRODUCT_PAGE: {
      const { id, product } = action.payload;
      return {
        ...state,
        [product]: {
          [id]: undefined,
        },
      };
    }

    case types.CHANGE_STEP_STATE: {
      const { id, product, stepId, payload } = action.payload;
      const value = over(
        lensPath([product, id, 'steps']),
        // @ts-ignore
        (steps: ProductStepState[]) => {
          const stepIndex = findIndex(propEq(stepId, 'id'), steps);
          const { visited } = payload;

          // @ts-ignore
          const updateState = adjust(stepIndex, mergeLeft(payload));

          if (visited) {
            return compose(
              // @ts-ignore
              updateState,
              mapIndexed((step, idx) => {
                if (idx < stepIndex) {
                  // @ts-ignore
                  return { ...step, visited: true };
                }
                return step;
              })
            )(steps);
          }

          // @ts-ignore
          return updateState(steps);
        },
        state
      );
      return value;
    }

    default:
      return state;
  }
};

export default products;
