import React, {
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImpersonateContext,
  useCustomerFeature,
  useCreateEntity,
} from 'imddata';
import { Button } from 'imdui';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Form, Field } from 'redux-form';
import { NewInputField } from '../../../InputField';
import {
  BuyOrSubOffer,
  ConfirmationWindow,
  useProductPriceFormatted,
} from '../../../../Next';
import styled from '@emotion/styled';

const SaveCancelWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  margin-top: auto;
  padding-top: 24px;
  & > * {
    flex: 1;
  }
`;

type FormData = {
  name: string;
};

const NEW_PREFIX = 'iMusician | ';

type FormProps = {
  onCancel: () => void;
  disabled?: boolean;
  prefix: string;
  onSubmit: (v: FormData) => void;
  isFree: boolean;
};

const createValidate =
  (prefix: string) => (values: FormData, props: FormProps) => {
    const errors: any = {};
    if (!values.name || values.name === prefix) {
      errors.name = 'required';
    }
    if (
      !props.isFree &&
      values.name &&
      values.name.substring(0, 4).toLowerCase() === prefix.toLowerCase()
    ) {
      errors.name = 'can-not-create-premium-label-with-imd-prefix';
    }
    return errors;
  };

const NewLabel = ({
  prefix,
  initialize,
  disabled,
  submitting,
  error,
  isFree,
  handleSubmit,
  onCancel,
  onSubmit,
}: InjectedFormProps<FormData, FormProps> & FormProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    initialize({ name: isFree ? prefix : '' });
  }, [isFree]);

  const withPrefix = useCallback(
    (value: string) =>
      value.slice(0, prefix.length) === prefix
        ? value
        : value.slice(0, prefix.length - 1) ===
            prefix.slice(0, prefix.length - 1)
          ? prefix
          : prefix,
    [prefix]
  );

  return (
    <Form data-test-id="new-label-form">
      <Field
        name="name"
        autoFocus={true}
        props={{
          errorText: error,
          floatingLabelText: t(
            isFree ? 'label-free-label' : 'label-premium-label'
          ),
          autoFocus: true,
          renderAsDistinctBlock: true,
        }}
        disabled={disabled}
        normalize={isFree ? withPrefix : undefined}
        component={NewInputField}
      />

      <SaveCancelWrapper>
        <Button text={t('cancel')} onClick={onCancel} />
        <Button
          type="submit"
          showLoading={submitting}
          disabled={submitting || disabled}
          onClick={handleSubmit(onSubmit)}
          text={t('save')}
          primary={true}
        />
      </SaveCancelWrapper>
    </Form>
  );
};

const NewLabelForm = reduxForm<FormData, FormProps>({
  form: 'newLabelForm',
})(NewLabel);

export default function NewLabelDialog({
  onFormSaved,
  onCancel,
  isFreeAllowed,
}: {
  onCancel: () => void;
  isPremiumAllowed?: boolean;
  isFreeAllowed?: boolean;
  onFormSaved: (v: { result: number | string }) => void;
}) {
  const impersonateQuery = useContext(ImpersonateContext);
  const { t } = useTranslation();

  const {
    createEntry,
    request: { errorMessage },
    createdId,
  } = useCreateEntity({
    entity: 'labels',
  });

  const hasCustomLabelFeature = useCustomerFeature('custom-labels');
  const price = useProductPriceFormatted('premium-label');
  const [decidePay, setDecidePay] = useState(() => false);

  const prefix = NEW_PREFIX;
  const validate = useMemo(() => createValidate(prefix), [prefix]);
  const type = hasCustomLabelFeature
    ? 'custom'
    : decidePay
      ? 'premium'
      : 'free';

  useEffect(() => {
    if (createdId) {
      onFormSaved({ result: createdId });
    }
  }, [createdId]);

  // When changing an endpoint from Order to POST /label
  const submitForm = useCallback(
    (values) => {
      const name =
        type === 'free' ? values.name.slice(prefix.length) : values.name;
      createEntry(
        {
          formId: 'newLabelForm',
          data: {
            type: type,
            isFree: type === 'free',
            name,
          },
        },
        {
          impersonate: impersonateQuery,
        }
      );
      return;
    },
    [type]
  );

  const [showLimitReached, setShowLimitReached] = useState(false);
  useEffect(() => {
    if (errorMessage === 'Customer has too many custom labels.') {
      setShowLimitReached(true);
    }

    return () => {
      if (window.Beacon) {
        window.Beacon('reset');
        window.Beacon('navigate', '/');
      }
    };
  }, [errorMessage]);

  return (
    <div style={{ padding: '0 32px 32px' }}>
      <ConfirmationWindow
        isOpen={showLimitReached}
        title={t('limit-exceeded')}
        message={t('limit-reached-on-custom-labels')}
        onRequestClose={() => {
          setShowLimitReached(false);
        }}
        onCancel={() => {
          if (window && window.Beacon) {
            window.Beacon('navigate', '/ask/message/');
          }
        }}
        cancelLabel={t('contact-customer-service')}
      />

      {!hasCustomLabelFeature && (
        <BuyOrSubOffer
          subscribeContext={{ analytics: { detail: 'label' } }}
          title={t('customizations-unavailable-on-free')}
          price={price}
          description={t('custom-labels-features', {
            defaultValue:
              'Custom label name|Multiple labels|Unlocks Beatport & Traxsource',
          })
            .split('|')
            .join(' • ')}
          onDecideBuy={(v) => setDecidePay(v)}
          decideBuy={decidePay}
        />
      )}
      <NewLabelForm
        onCancel={onCancel}
        prefix={prefix}
        validate={validate}
        onSubmit={submitForm}
        submitAsSideEffect={true}
        disabled={!isFreeAllowed && !hasCustomLabelFeature && !decidePay}
        isFree={type === 'free'}
      />
    </div>
  );
}
