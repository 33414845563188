//
import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { palette, interpolate } from '../../helpers';

const Container = styled.div`
  cursor: ew-resize;
`;

const Stripe = styled.div`
  display: flex;
  width: 100%;
`;

const Drag = styled.div`
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  cursor: ew-resize;
`;

export default function DragBox({
  onChange,
  min = 0,
  className,
  style,
  maxOffset = 0,
  length,
  disabled,
  renderPositionBox,
  renderPosition,
  renderProgress,
  renderOffset,
  offset = 0,
  label,
  useFloor = Math.floor,
}) {
  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState(false);

  const isPressed = useRef(pressed);

  const dragBox = useRef();
  const stripe = useRef();

  const stripeWidth = useRef(0);
  const stripeStart = useRef(0);
  const requestedFrame = useRef(null);
  const dragWidth = useRef(0);
  const event = useRef(null);
  const arrowStartMovementTime = useRef(0);

  const updateDimensions = useCallback(() => {
    if (!stripe.current || !dragBox.current) {
      return;
    }

    stripeStart.current = stripe.current.getBoundingClientRect().left;
    stripeWidth.current = stripe.current.getBoundingClientRect().width;
    dragWidth.current = dragBox.current.getBoundingClientRect().width;

    arrowStartMovementTime.current =
      (length * (stripeWidth.current - dragWidth.current)) /
      stripeWidth.current;
  }, []);

  const calcOffset = useCallback(() => {
    updateDimensions();
    const e = event.current;

    // time offset converted to px (relative to stripe container)
    // e.g. 00:20 --> 20px
    const CURRENT_OFFSET_PX = (offset * stripeWidth.current) / length;

    // mouse position in px (relative to stripe container)
    // e.g. 24px
    const END_NEXT_OFFSET_PX = e.clientX - stripeStart.current;

    // difference between next and current offset values (relative to stripe container)
    // e.g. diff is (24px - 20px) = -4px
    const NEXT_OFFSET_DIFF = END_NEXT_OFFSET_PX - CURRENT_OFFSET_PX;

    // next values in pixels (relative to stripe container) and time (relative to length)
    const NEXT_OFFSET_PX = CURRENT_OFFSET_PX + NEXT_OFFSET_DIFF;

    const interpolateOutput = interpolate(
      {
        x: [0, stripeWidth.current],
        y: [min, length],
      },
      true
    );

    const NEXT_OFFSET_TIME = interpolateOutput(NEXT_OFFSET_PX);

    // clean number for update and return
    const newOffset = useFloor ? useFloor(NEXT_OFFSET_TIME) : NEXT_OFFSET_TIME;

    if (newOffset < min) {
      onChange(e, min);
      return;
    }

    if (newOffset > length - maxOffset) {
      onChange(e, length - maxOffset);
      return;
    }

    onChange(e, newOffset);
  }, [maxOffset, offset, onChange]);

  const onMouseEnter = useCallback(() => {
    if (disabled) {
      return;
    }
    setHovered(true);
  }, [disabled]);

  const onMouseLeave = useCallback(() => {
    if (disabled) {
      return;
    }
    setHovered(false);
  }, [disabled]);

  const onMouseDown = useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      if (requestedFrame.current) {
        cancelAnimationFrame(requestedFrame.current);
      }
      isPressed.current = true;
      setPressed(true);
      event.current = e.nativeEvent;
      requestedFrame.current = requestAnimationFrame(calcOffset);
    },
    [pressed, disabled]
  );

  const onMouseUp = useCallback(() => {
    if (disabled) return;
    isPressed.current = false;
    setPressed(false);
  }, [disabled]);

  const onMouseMove = useCallback(
    (e) => {
      if (disabled) {
        return;
      }

      if (isPressed.current) {
        if (requestedFrame.current) {
          cancelAnimationFrame(requestedFrame.current);
        }

        event.current = e;
        requestedFrame.current = requestAnimationFrame(calcOffset);
      }
    },
    [disabled]
  );

  useLayoutEffect(() => {
    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mouseup', onMouseUp);
    window.addEventListener('resize', updateDimensions);

    updateDimensions();

    return () => {
      cancelAnimationFrame(requestedFrame.current);
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('resize', updateDimensions);
    };
  }, [onMouseUp, onMouseMove]);

  useEffect(updateDimensions, [length]);

  const interpolateProgress = interpolate(
    { x: [min, length], y: [0, 1] },
    true
  );

  const interpolateBox = interpolate(
    {
      x: [min, arrowStartMovementTime.current],
      y: [0, stripeWidth.current - dragWidth.current],
    },
    true
  );
  const interpolateArrow = interpolate(
    {
      x: [arrowStartMovementTime.current + min, length],
      y: [-10 + min, dragWidth.current - 10],
    },
    true
  );
  const interpolateLength = interpolate(
    {
      x: [min, length],
      y: [0, stripeWidth.current],
    },
    true
  );
  const theme = useTheme();

  const accentColor = disabled
    ? palette.light.light
    : hovered || pressed
      ? theme.accent.green
      : palette.dark.xLight;
  return (
    <Container
      className={className}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        cursor: disabled ? 'default' : 'ew-resize',
        ...style,
      }}
    >
      {renderProgress({
        disabled,
        offset: interpolateLength(offset),
        length: maxOffset ? interpolateProgress(maxOffset) : undefined,
        xPos: interpolateProgress(offset),
      })}

      <Stripe ref={stripe}>
        <Drag
          ref={dragBox}
          style={{
            cursor: disabled ? 'default' : 'ew-resize',
            transform: `translateX(${interpolateBox(offset)}px)`,
          }}
        >
          {renderPosition
            ? renderPosition({
                disabled,
                color: accentColor,
                xPos: interpolateArrow(offset),
              })
            : null}

          {renderPositionBox
            ? renderPositionBox({
                disabled,
                label,
                offset,
                maxOffset,
                renderOffset,
                color: accentColor,
              })
            : null}
        </Drag>
      </Stripe>
    </Container>
  );
}
