import React, { EventHandler, SyntheticEvent, useMemo } from 'react';
import { Body } from '../../../text';
import { Wrapper, Circle, Dot } from './styles';

type Props = {
  testId?: string;
  selected: boolean;
  disabled?: boolean;
  text?: string;
  tabIndex?: number;
  onClick?: (e: SyntheticEvent<any>, selected: boolean) => void;
  tintColor?: string;
  className?: string;
};
type Handler = EventHandler<SyntheticEvent<any>>;

export default function RadioButton({
  testId,
  selected,
  disabled,
  text,
  tabIndex,
  onClick,
  tintColor,
  className,
}: Props): JSX.Element {
  const handleClickAndPress = useMemo<Handler | undefined>(
    () =>
      !disabled
        ? (e) => {
            if (onClick) {
              onClick(e, selected);
            }
          }
        : undefined,
    [selected, onClick, disabled]
  );

  return (
    <Wrapper
      data-test-id={testId}
      role="radio"
      aria-checked={selected}
      selected={selected}
      tabIndex={tabIndex || 0}
      className={className + (disabled ? ' disabled' : '')}
      tintColor={tintColor}
      onClick={handleClickAndPress}
      onKeyPress={handleClickAndPress}
    >
      <Circle>
        <Dot />
      </Circle>

      {text && <Body>{text}</Body>}
    </Wrapper>
  );
}

RadioButton.Circle = Circle;
