import styled from '@emotion/styled';
import { Clickable } from 'imdui';

export const ListButton = styled(Clickable)`
  flex: 1;
  padding: 0 16px;
  min-height: 56px;
  align-items: center;
  display: flex;
  width: 100%;
  &:after {
    border-radius: 0;
  }
  border-radius: 0;
`;
