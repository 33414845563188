import { DashboardCard } from 'components';
import { TextFormatted } from 'imdui';
import { useTranslation } from 'react-i18next';

export const UnavailableSales = () => {
  const { t, i18n } = useTranslation();
  if (!i18n.exists('sales-data-unavailable')) {
    return null;
  }

  return (
    <DashboardCard
      style={{ gridColumn: '1/-1' }}
      label={t('sales-data-unavailable-title')}
    >
      <TextFormatted text={t('sales-data-unavailable')} />
    </DashboardCard>
  );
};
