//
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { InfiniteList } from 'imdshared';
import type { TrackNested } from 'imddata';
import { useTracks } from 'imddata';
import { TracksQueryBuilder } from 'components';
import { ReportingPage, useReportingQuery } from '../../shared';
import TrackSalesRow from './TrackSalesRow';

const getRowHeight = () => 256;

const listStyle = {
  padding: '32px 0',
};

const TracksList = () => {
  const {
    queryHash,
    query,
    request: { loading: loadingStatement },
  } = useReportingQuery();
  const {
    loadMore,
    entries,
    request: { loading, hasMorePages },
  } = useTracks({ queryHash, query, passive: loadingStatement });

  const trackRenderer = useCallback(
    ({ item, key, style }) => (
      <div key={key} style={style}>
        <TrackSalesRow track={item} queryHash={queryHash} />
      </div>
    ),
    [queryHash]
  );

  return (
    <InfiniteList<TrackNested>
      style={listStyle}
      loading={loading || loadingStatement}
      hasNextPage={hasMorePages}
      loadNextPage={loadMore}
      getRowHeight={getRowHeight}
      rowRenderer={trackRenderer}
      data={entries}
    />
  );
};

const ReportingTracks = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('page-title-reporting-tracks')}</title>
      </Helmet>

      <ReportingPage renderQueryBuilder={TracksQueryBuilder}>
        <div>
          <TracksList />
        </div>
      </ReportingPage>
    </div>
  );
};

export default ReportingTracks;
