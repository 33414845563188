import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Trends } from './screens';

const Reporting: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact={true} path={`${match.path}`} component={Trends} />
      <Route
        path={`${match.path}/sales`}
        render={() => <Redirect to="/revenue/statements" />}
      />
    </Switch>
  );
};

export default Reporting;
