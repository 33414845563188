import React, { useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useParams, Route, Switch, Redirect } from 'react-router-dom';
import { ProductPageContext } from 'components';
import { useRelease } from 'imddata';
import NavBar from '../../shared/NavBar';
import type { ProductPageContextType } from '../../shared/ProductWizard';
import { ProductWizard, useProductId } from '../../shared/ProductWizard';
import { ProductPriceContext } from '../../shared';
import { SimpleReleaseForm } from './screens';

const RELEASE = 'edit';

const ReleasePageManager = () => {
  return null;
};

const RELEASE_STEPS = [{ id: RELEASE, to: RELEASE, label: 'release' }];

/*
 * TODO:
 *
 * ---- UI
 *
 * - Custom translation string for overview
 *
 * ---- Logic
 *
 * - Pending cover/audio state
 * - fix link to Release
 *
 */

const RELEASE_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'release',
  title: 'release',
  initialData: { nextProduct: 'md' },
  steps: RELEASE_STEPS,
  initialStepsState: RELEASE_STEPS,
  entity: 'releases',
  overviewEntity: undefined,
  StepperStateManager: ReleasePageManager,
  NavBarRenderer: function NavBarRenderer({ stepId, ...props }) {
    switch (stepId) {
      default:
        return <NavBar {...props} />;
    }
  },
  RedirectManager: function RedirectManager({ match }) {
    return <Redirect to={`${match.url}/${RELEASE}`} />;
  },
  StepRenderer: function StepsRenderer({ stepId, match, ...props }) {
    const id = useProductId();

    switch (stepId) {
      case RELEASE: {
        return <SimpleReleaseForm id={id} {...props} />;
      }
      default:
        return <span>{`${stepId} :not-found`}</span>;
    }
  },
};

const noPrice = {
  error: true,
  calculating: false,
  recalculate: () => {
    throw new Error('should not be called');
  },
};

export function ReleaseWizard(props: RouteComponentProps) {
  const { id } = useParams<{ id: string }>();

  useRelease({ id });

  const value = useMemo(() => {
    return {
      id,
      ...RELEASE_CONTEXT,
    };
  }, [id]);

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceContext.Provider value={noPrice}>
        <Switch>
          <Route path={props.match.path}>
            <ProductWizard loading={false} stepper={null} {...props} />
          </Route>
        </Switch>
      </ProductPriceContext.Provider>
    </ProductPageContext.Provider>
  );
}
