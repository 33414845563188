import React from 'react';
import { Tooltip as VisxTooltip } from '@visx/xychart';
import type { TooltipProps } from '@visx/xychart/lib/components/Tooltip';
import styled from '@emotion/styled';
import type { LegendType } from './LegendItem';
import { LegendIcon } from './LegendItem';

type TooltipType = <D extends Record<string, unknown>>(
  props: TooltipProps<D>
) => React.ReactElement;

// @ts-ignore
export const Tooltip: TooltipType = styled(VisxTooltip)`
  padding: 12px 12px 8px 12px;
  border-radius: 8px;
  background-color: #fafafaf2;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
  font-weight: 400;
`;

export const TooltipValue = styled.span`
  font-weight: 500;
`;

export const TooltipLegendGrid = styled.div`
  margin-top: 16px;
  max-width: 256px;
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: 24px auto auto;
  gap: 4px;
  & > * {
    display: flex;
  }
`;

export const TooltipLegendRow = ({
  label,
  value,
  color,
  type = 'line',
}: {
  label?: string;
  value?: number | string;
  color?: string;
  type?: LegendType;
}) => (
  <>
    <div>
      <LegendIcon fill={color} type={type} />
    </div>
    <div>{label}</div>
    <TooltipValue>{value}</TooltipValue>
  </>
);
