import debounce from 'lodash.debounce';
import { equals, keys, mergeRight } from 'ramda';
import { useRef, useCallback, useEffect, useState } from 'react';
import { Query } from '../../types';
import filterQuery from '../../utils/filterQuery';

export const computeQueryHash = (query?: Query): string | undefined => {
  if (query) {
    const filteredQuery = filterQuery(query);
    if (keys(filteredQuery).length > 0) {
      return JSON.stringify(filteredQuery);
    }

    return undefined;
  }
  return undefined;
};

const defaultQuery: Query = {};

type UseQueryOptions = {
  query?: Query;
  wait?: number;
};

const mergeStrategy = mergeRight;

export function useQuery({
  query: propsQuery = defaultQuery,
  wait = 500,
}: UseQueryOptions = {}): {
  query?: Query;
  queryHash: string | undefined;
  updateQuery: (q: Query) => void;
} {
  const queryRef = useRef(propsQuery);
  const [queryHash, setQueryHash] = useState(() =>
    computeQueryHash(queryRef.current)
  );

  const [query, setQuery] = useState(() => propsQuery);

  const updateQuery = useCallback(
    (newQuery) => {
      setQuery(mergeStrategy(query, newQuery));
    },
    [query]
  );

  const updateHashDebounced = useCallback(
    debounce((passedQuery) => {
      setQueryHash(computeQueryHash(passedQuery));
    }, wait),
    [wait]
  );

  useEffect(() => {
    if (!equals(queryRef.current, propsQuery)) {
      queryRef.current = propsQuery;
      setQuery(propsQuery);
    }
  }, [propsQuery]);

  useEffect(() => {
    updateHashDebounced(query);

    return () => {
      updateHashDebounced.cancel();
    };
  }, [query]);

  return {
    query,
    queryHash,
    updateQuery,
  };
}
