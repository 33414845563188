//
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { Centered, LoadingIndicator } from 'imdui';
import { TableHeader, TableContent } from './components';

export { TableRow, TableContent, TableHeader } from './components';

export const TableStyled = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0;

  th,
  td {
    padding: 16px 0;
    white-space: nowrap;
  }

  tr {
    box-shadow: inset 0 -1px 0 0 var(--fg-4);
  }
`;

const Table = ({
  fields = [],
  items = [],
  children,
  rowRenderer,
  onClickRow,
  loading = false,
  hasNextPage,
  loadNextPage,
  style,
  className,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!loading && hasNextPage && inView && loadNextPage && items) {
      loadNextPage();
    }
  }, [inView, loading, hasNextPage, loadNextPage]);

  return (
    <TableStyled style={style} className={className}>
      <TableHeader fields={fields} />
      {children || (items && items.length) ? (
        <TableContent
          rowRenderer={rowRenderer}
          items={items}
          fields={fields}
          onClickRow={onClickRow}
        >
          {children}
        </TableContent>
      ) : null}
      {loading || (hasNextPage && loadNextPage) ? (
        <tfoot>
          <tr>
            <td colSpan={42} ref={ref}>
              <Centered style={{ padding: '32px 0' }}>
                <LoadingIndicator size="large" />
              </Centered>
            </td>
          </tr>
        </tfoot>
      ) : null}
    </TableStyled>
  );
};

export default Table;
