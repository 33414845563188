import TracksBrowserDialog from 'components/organisms/TracksBrowserDialog';
import { useTrack } from 'imddata';
import { Body, Button, HelperText } from 'imdui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { WrappedFieldProps } from 'redux-form';
import { Item, ItemCollection } from './ItemCollection';
import styled from '@emotion/styled';

const TrackItem = styled(Item)`
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
`;

export const TrackSelectField = ({
  input,
  disabled,
}: WrappedFieldProps & { disabled: boolean }) => {
  const [open, setOpen] = useState(false);
  const [selectedTracks, setSelectedTracks] = useState<number[]>([]);
  const { t } = useTranslation();
  const { entry } = useTrack({ id: input.value, passive: !input.value });
  return (
    <>
      <ItemCollection>
        <TrackItem>
          <Body>{entry?.defaultName.title || t('select-track')}</Body>
          <Button
            disabled={disabled}
            size="small"
            position="center"
            onClick={() => {
              setOpen(true);
            }}
            text={t('select')}
          />
        </TrackItem>
      </ItemCollection>
      {disabled && (
        <HelperText
          style={{ marginBottom: 0 }}
          text={t('artist-page-track-card-limit')}
        />
      )}

      <TracksBrowserDialog
        isOpen={open}
        hideUploadTrack={false}
        onClose={() => {
          setOpen(false);
        }}
        isDisabled={({ isAudioCorrect, hasAudioUploaded }) =>
          !(isAudioCorrect && hasAudioUploaded)
        }
        onSelect={(tracks) =>
          tracks.length ? setSelectedTracks(tracks.slice(-1)) : []
        }
        selectedTracks={selectedTracks}
        onTracksSelected={() => {
          setOpen(false);
          input.onChange(selectedTracks[0]);
        }}
      />
    </>
  );
};
