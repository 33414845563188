import { cancel, all, take, fork } from 'redux-saga/effects';
import requestRoot from './request';
import tracksRoot from './tracks';
import socketsRoot from './sockets';
import releasesRoot from './releases';

import { actionTypes } from '../actionTypes/users';
import {
  INVALIDATE_AUTH,
  actionTypes as authActionTypes,
} from '../actionTypes/auth';

export default function* runDataSagas(sagas, listeners) {
  yield fork(requestRoot);
  while (true) {
    yield take(actionTypes.loggedUser.fetch.SUCCESS);
    const tasks = yield all([
      fork(releasesRoot),
      fork(tracksRoot),
      fork(socketsRoot, ...listeners),
      ...sagas.map((entry) => fork(entry)),
    ]);
    yield take([INVALIDATE_AUTH, authActionTypes.signOut.SUCCESS]);
    yield cancel(tasks);
  }
}
