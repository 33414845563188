import React from 'react';
import { Headline, CenterWrapper } from 'imdshared';
import styled from '@emotion/styled';
import Toolbar from '../Toolbar';
import Container from '../Container';
import { PriceCloud } from '../PriceCloud';

interface Props {
  title: string;
  type?: string;
  stepper?: React.ReactNode;
  children: React.ReactNode;
  priceHelperText?: string;
  priceShortHelperText?: string;
  testId?: string;
  hidePrice?: unknown;
}

const Header = styled.div<{ hasStepper: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ hasStepper }) => (hasStepper ? '32px' : '0')};
`;

export default function ProductScreen({
  stepper,
  title,
  children,
  type,
  hidePrice,
  priceHelperText,
  priceShortHelperText,
  testId,
}: Props): JSX.Element {
  return (
    <Container testId={testId}>
      <Toolbar>
        <Header hasStepper={!!stepper}>
          <Headline>{title}</Headline>
          {!hidePrice && (
            <PriceCloud
              type={type}
              priceHelperText={priceHelperText}
              priceShortHelperText={priceShortHelperText}
            />
          )}
        </Header>

        {stepper}
      </Toolbar>

      <CenterWrapper>{children}</CenterWrapper>
    </Container>
  );
}
