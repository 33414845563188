//
// keys used to define CRUD actions

export type RequestType = 'fetch' | 'update' | 'delete' | 'create';
export const genericApiActions: Array<RequestType> = [
  'fetch',
  'update',
  'delete',
  'create',
];

export const stubTypes = {
  SUCCESS: 'STUB',
  FAILURE: 'STUB',
  REQUEST: 'STUB',
};
