import React from 'react';
import { ProcessFieldMeta } from 'imddata';
import { OverlineText } from 'imdui';

type Props = React.ComponentProps<typeof OverlineText> & {
  meta?: any;
  valueOverride?: any;
};

const LabelTextField: React.FC<Props> = ({
  label,
  required,
  meta,
  valueOverride,
  onClickHelp,
  className,
  style,
  size,
  components,
}) => (
  <ProcessFieldMeta valueOverride={valueOverride} meta={meta}>
    {({ error }) => (
      <OverlineText
        label={label}
        required={required}
        size={size}
        className={className}
        style={style}
        error={!!error}
        onClickHelp={onClickHelp}
        components={components}
      />
    )}
  </ProcessFieldMeta>
);

export default LabelTextField;
