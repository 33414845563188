import React from 'react';
import { useTranslation } from 'react-i18next';
import { PublisherForms } from 'imdshared';
import type { Props as EditorProps } from '../EntitySideEditor';
import { EntitySideEditor } from '../EntitySideEditor';

type Props = {
  isAdaptive?: EditorProps['isAdaptive'];
  warn?: EditorProps['warn'];
};

const components = { SingleForm: PublisherForms.Single };

const PublisherSideEditor: React.FC<Props> = ({ isAdaptive, warn }) => {
  const { t } = useTranslation();
  const label = t('selected-publishers');

  return (
    <EntitySideEditor
      warn={warn}
      entity="publishers"
      label={label}
      isAdaptive={isAdaptive}
      components={components}
    />
  );
};

export default PublisherSideEditor;
