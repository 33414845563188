const mediumRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export default function validatePassword<
  T extends { password: string; passwordConfirmation: string },
>(values: T, devmode?: boolean) {
  const errors: Record<string, string> = {};
  if (!values.password) {
    errors.password = 'required';
  } else if (!devmode && !mediumRegex.test(values.password)) {
    errors.password = 'weak-password';
  }

  if (!!values.password && values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'please-enter-the-same-value';
  }
  return errors;
}
