//
import * as R from 'ramda';
import {
  delay,
  race,
  flush,
  call,
  take,
  fork,
  takeEvery,
  actionChannel,
} from 'redux-saga/effects';
import {
  LOAD_ENTITY,
  UPDATE_ENTITY,
  DELETE_ENTITY,
  CREATE_ENTITY,
} from '../actionTypes/request';
import * as api from '../api';
import mergeBuffer from '../base/mergeBuffer';

export const getApiHandler = (entityKey, key) => {
  if (entityKey === 'unsupported') {
    return undefined;
  }
  if (!Array.isArray(entityKey) && api[entityKey] !== undefined) {
    return api[entityKey][key];
  }
  if (entityKey === undefined) {
    console.error('UNDEFINED ENTITY KEY', entityKey);
    return undefined;
  }
  const apiCrudCalls = R.view(R.lensPath(entityKey), api);
  if (!apiCrudCalls) {
    console.error('unsupported api call at', entityKey);
    return undefined;
  }
  return apiCrudCalls[key];
};

function crudAsync(key, useCache = false) {
  let cache = {};
  return function* handleCrud(action, buffer) {
    try {
      const {
        payload: { entityKey },
      } = action;
      const apiHandler = getApiHandler(entityKey, key);
      if (!apiHandler) return;
      if (useCache) {
        const actionKey = JSON.stringify(action);
        if (cache[actionKey]) return;
        cache[actionKey] = true;
        yield call(apiHandler, action.payload, action.meta);
        cache[actionKey] = false;
      } else {
        yield call(apiHandler, action.payload, action.meta);
      }

      if (buffer && key === 'update' && buffer.isEmpty()) {
        // yield put(hideNotification('updateEntity'));
      }
    } catch (error) {
      cache = {};
      if (error && error.type !== 'APIERROR') throw error;
    }
  };
}

export const deleteAsync = crudAsync('delete', true);
export const fetchAsync = crudAsync('fetch', true);
export const createAsync = crudAsync('create', false);
export const updateAsync = crudAsync('update', true);

function* debounce(ms, pattern, worker, ...args) {
  const task = yield fork(function* debounceInner() {
    while (true) {
      let ac = yield take(pattern);

      while (true) {
        const { action, debounced } = yield race({
          action: take(pattern),
          debounced: delay(ms),
        });

        if (debounced) {
          yield fork(worker, ...args, ac);
          break;
        }

        ac = action;
      }
    }
  });
  return task;
}

export function* debounceUpdate(ms, pattern, worker, ...args) {
  const buffer = mergeBuffer();
  const throttleChannel = yield actionChannel(pattern, buffer);

  yield debounce(ms, pattern, function* handleChannel() {
    const actions = yield flush(throttleChannel);
    for (let i = 0; i < actions.length; i += 1) {
      yield fork(worker, ...args, actions[i]);
    }
  });
}

const shouldDebounce = (action) => {
  return action.meta && action.meta.debounce;
};

export default function* requestRoot() {
  yield takeEvery([LOAD_ENTITY], fetchAsync);
  yield takeEvery(DELETE_ENTITY, deleteAsync);
  yield takeEvery(CREATE_ENTITY, createAsync);
  yield takeEvery(
    (action) => !shouldDebounce(action) && action.type === UPDATE_ENTITY,
    updateAsync
  );
  yield debounceUpdate(
    500,
    (action) => shouldDebounce(action) && action.type === UPDATE_ENTITY,
    updateAsync
  );
}
