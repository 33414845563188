import styled from '@emotion/styled';

export default styled.div<{
  hideShadow?: boolean;
}>`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: ${({ hideShadow }) =>
    hideShadow
      ? 'none'
      : '0 1px 2px rgba(0, 0, 0, 0.1), inset 0 -0.5px rgba(0, 0, 0, 0.2)'};
`;
