import React from 'react';
import styled from '@emotion/styled';
import { Centered, Caption, LoadingIndicator } from 'imdui';

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 18px;
  background-color: white;
  padding: 0 8px;

  ${Caption} {
    margin-left: 8px;
  }
`;

type Props = {
  progress: number;
  indeterminate: boolean;
  loaderText: string;
  hideStatusText?: boolean;
};

const Status = ({
  progress,
  indeterminate,
  loaderText,
  hideStatusText = false,
}: Props) => (
  <Centered>
    <Content>
      <LoadingIndicator progress={progress} indeterminate={indeterminate} />

      {!hideStatusText && <Caption>{loaderText}</Caption>}
    </Content>
  </Centered>
);

export default Status;
