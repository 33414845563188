import React from 'react';
import { Global, css } from '@emotion/react';
import { baseTheme } from '@imus/base-ui';

const interPreix = '/fonts/Inter/Inter-';

export default function WithGlobalStyles() {
  return (
    <Global
      styles={css`
        :root {
          ${baseTheme}
        }
        svg {
          fill: none;
        }
        body {
          overflow-x: hidden;
        }
        html {
          width: 100%;
          background: var(--background, #e6e5e3);
        }

        #app {
          display: flex;
          width: 100%;
        }

        a {
          color: #222;
          font-weight: 500;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .link:focus,
        .link:active {
          color: #84bc48;
        }

        h1 {
          margin: 0;
        }

        button,
        html [type='button'],
        [type='reset'],
        [type='submit'] {
          -webkit-appearance: none;
        }

        .react-tooltip__tooltip_KtSkBq {
          visibility: hidden;
          width: max-content;
          position: absolute;
          top: 0;
          left: 0;
          padding: 8px 16px;
          border-radius: 3px;
          font-size: 90%;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.3s ease-out;
          will-change: opacity, visibility;
        }
        .react-tooltip__fixed_KtSkBq {
          position: fixed;
        }
        .react-tooltip__arrow_KtSkBq {
        }
        .react-tooltip__no-arrow_KtSkBq {
          display: none;
        }
        .react-tooltip__clickable_KtSkBq {
          pointer-events: auto;
        }

        .react-tooltip__show_KtSkBq {
          visibility: visible;
          opacity: 1;
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src:
            url('${interPreix}Regular.woff2?v=3.19') format('woff2'),
            url('${interPreix}Regular.woff?v=3.19') format('woff');
        }
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 400;
          font-display: swap;
          src:
            url('${interPreix}Italic.woff2?v=3.19') format('woff2'),
            url('${interPreix}Italic.woff?v=3.19') format('woff');
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src:
            url('${interPreix}Medium.woff2?v=3.19') format('woff2'),
            url('${interPreix}Medium.woff?v=3.19') format('woff');
        }
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 500;
          font-display: swap;
          src:
            url('${interPreix}MediumItalic.woff2?v=3.19') format('woff2'),
            url('${interPreix}MediumItalic.woff?v=3.19') format('woff');
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src:
            url('${interPreix}SemiBold.woff2?v=3.19') format('woff2'),
            url('${interPreix}SemiBold.woff?v=3.19') format('woff');
        }
        @font-face {
          font-family: 'Inter';
          font-style: italic;
          font-weight: 600;
          font-display: swap;
          src:
            url('${interPreix}SemiBoldItalic.woff2?v=3.19') format('woff2'),
            url('${interPreix}SemiBoldItalic.woff?v=3.19') format('woff');
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src:
            url('${interPreix}Bold.woff2?v=3.19') format('woff2'),
            url('${interPreix}Bold.woff?v=3.19') format('woff');
        }
      `}
    />
  );
}
