import { createContext } from 'react';

interface DarkThemeControl {
  isDarkThemeOn: boolean;
  setDarkThemeOn: (isOn: boolean) => void;
}

const defaultValue = {
  isDarkThemeOn: false,
  setDarkThemeOn: (isOn: boolean): void => {
    console.error(
      isOn,
      'DarkThemeProvider is not initialized with theme context'
    );
  },
};

const DarkThemeContext = createContext<DarkThemeControl>(defaultValue);

DarkThemeContext.displayName = 'Dark Theme Context';

export default DarkThemeContext;
