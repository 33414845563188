import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '../../../text';

const keyframesAppear = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    transform: scale(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2);
    transform: scale(1);
  }
`;

const keyframesPulse = keyframes`
  0% { transform: scale(1) }
  50% { transform: scale(0.75) }
  100% { transform: scale(1) }
`;

export const Circle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 1px;
  transition: box-shadow 0.1s ease-in-out;

  ::after {
    content: '';
    position: absolute;
    top: -9px;
    left: -9px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: transform 0.05s ease-in-out;
`;

export const Wrapper = styled.div<{
  tintColor?: string;
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  min-height: 40px;

  :hover {
    ${Circle} {
      box-shadow: inset 0 0 0 3px
        ${({ theme, tintColor }): string => {
          if (tintColor) {
            return tintColor;
          }
          return theme.foreground.primary;
        }};
    }
  }

  :focus {
    ${Circle}::after {
      animation:
        ${keyframesAppear} ease-in-out 0.2s forwards,
        ${keyframesPulse} 1.5s 0.2s ease-in-out infinite;
    }
  }

  ${Circle} {
    box-shadow: inset 0 0 0 2px
      ${({ theme, tintColor }): string => {
        if (tintColor) {
          return tintColor;
        }
        return theme.foreground.primary;
      }};
  }

  ${Dot} {
    background-color: ${({ theme, tintColor }): string => {
      if (tintColor) {
        return tintColor;
      }
      return theme.foreground.primary;
    }};
    transform: scale(${({ selected }): number => (selected ? 1 : 0)});
  }

  ${Body} {
    margin-left: 8px;
    font-weight: 400;
  }
`;
