//
import React, { useMemo, useCallback, useContext } from 'react';
import { head, view, lensPath, find } from 'ramda';
import { actions } from 'imddata/actionTypes/ui/selection';
import { getFormMeta, getFormSyncErrors, formValueSelector } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { ProcessFieldMeta } from 'imddata/providers';
import AppContext from '../logic/AppContext';
import CardContext from '../logic/CardContext';

export { hasEmoji } from './hasEmoji';

export const getTrackIsrc = (entry) =>
  entry.isrcs && entry.isrcs.length
    ? view(
      lensPath(['value']),
      find((isrc) => isrc.isPrimary, entry.isrcs) || head(entry.isrcs)
    )
    : entry.customIsrc;

export const createJoinTagField = (JoinTagComponent, entity) => {
  return ({
    input: { value, onBlur, onChange },
    meta,
    languageId,
    label,
    warningText,
    floatingLabelText,
    onClickHelperButton,
    onItemClick,
    valueOverride,
    ...props
  }) => {
    const app = useContext(AppContext);
    const { onCreateCard } = useContext(CardContext);
    const dispatch = useDispatch();
    const onClickItem = useCallback(
      (_event, v) => {
        if (app === 'front') {
          dispatch(
            actions.updateSelection({
              entity,
              selected: [v.id],
            })
          );
          return;
        }
        if (onCreateCard) {
          onCreateCard(entity, {
            selectedEntityId: v.id,
            status: 'edit',
          });
        }
      },
      [app]
    );
    return (
      <ProcessFieldMeta
        hasValue={value?.length > 0}
        valueOverride={valueOverride}
        meta={meta}
      >
        {({ error, warning }) => (
          <JoinTagComponent
            onClickHelperButton={onClickHelperButton}
            warningText={warning || warningText}
            errorText={error}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            languageId={languageId}
            onClickItem={onClickItem}
            {...props}
          />
        )}
      </ProcessFieldMeta>
    );
  };
};

export const createSelector =
  (...args) =>
    ({ form, children }) => {
      const selector = useMemo(() => formValueSelector(form), [form]);
      const result = useSelector((state) => selector(state, ...args));

      return children(result);
    };

export const FieldMetaSelector = ({ form, children, name }) => {
  const meta = useSelector((state) => getFormMeta(form)(state));
  const errors = useSelector((state) => getFormSyncErrors(form)(state));
  const fieldMeta = meta[name];
  const fieldError = errors[name];
  const result = useMemo(
    () => ({
      touched: fieldMeta?.touched,
      error: fieldError,
    }),
    [fieldMeta?.touched, fieldError]
  );

  return children(result);
};

export const timeRegex = /^(([01]|\d|2)?[0-3]?):([0-5]?\d)$/;

export function secsToMmSs(secs) {
  if (timeRegex.test(secs)) {
    return secs;
  }

  let time = '';
  if (+secs || secs === 0) {
    const minutes = parseInt(secs / 60, 10);
    const seconds = secs % 60;
    time = `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds
      }`;
  }
  return time;
}

export const formatOffset = (value) => (value ? secsToMmSs(value) : '00:00');

export { default as interpolate } from './interpolate';
export { default as palette } from './palette';
export { default as validateArtistRoles } from './validateArtistRoles';
