import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteEntity } from 'imddata';
import { Button } from 'imdui';

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

const RevertAllButton: React.FC<Props> = ({ style, className }) => {
  const { t } = useTranslation();

  const {
    deleteEntry,
    request: { deleting, deleted },
  } = useDeleteEntity({
    entity: 'deliveryBundleUpdates',
  });

  return (
    <Button
      showLoading={deleting}
      disabled={deleted}
      type="button"
      text={t('revert-all')}
      className={className}
      style={style}
      onClick={() => {
        deleteEntry();
      }}
    />
  );
};

export default RevertAllButton;
