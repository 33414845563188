import { css } from '@emotion/react';
export const tourItemStyle = css`
  --calendar-fg-main: rgba(0, 0, 0, 0.9);
  --calendar-fg-header: ##fff;
  --calendar-bg-main: #fff;
  --calendar-bg-header: #ff5959;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 24px;
  row-gap: 8px;
`;

export const calendarStyle = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
  font-family: Barlow, Inter;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  background: var(--calendar-bg-main);

  color: rgba(0, 0, 0, 0.9);
  font-feature-settings: 'ss01' on;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 60% */
`;

export const calendarHeaderStyle = css`
  display: flex;
  font-family: Barlow, Inter;
  justify-content: center;
  width: 100%;
  padding: 5px 0 3px;
  margin-bottom: 3px;

  color: #fff;
  font-feature-settings: 'ss01' on;
  font-family: Barlow;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 133.333% */
  letter-spacing: 0.54px;
  background: #fb5966;
`;

export const textColumnStyle = css`
  flex: 280px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const buttonStyle = css`
  flex-grow: 1;
  flex: 80px;
  background: none;
`;
