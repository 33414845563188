import { Global, css } from '@emotion/react';
import React from 'react';
import { themeLight as theme } from './config';
import { reactDatesStyles } from './react-dates-styles';
import { reactVirtualizedStyles } from './react-virtualized-styles';

export default function WithGlobalStyles(): JSX.Element {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Barlow:wght@400;500;600;700&family=Space+Grotesk:wght@700&display=swap');

        :root {
          --color-main: ${theme.foreground.primary};
          --color-accent: ${theme.accent.green};
          --color-accent-link: ${theme.accent.green};
        }

        h1 {
          font-size: 28px;
          line-height: 32px;
          font-weight: 500;
        }

        h2 {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;
        }

        h3 {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
        }

        h4 {
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
        }
        html {
          scrollbar-gutter: stable;
        }

        body {
          scrollbar-gutter: stable both-edges;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          overflow-y: auto;
          line-height: 24px;
          color: var(--color-main);
          -webkit-font-smoothing: antialiased;
        }

        span[role='button'] {
          position: relative;
          text-decoration: underline;
          cursor: pointer;
        }

        a {
          color: var(--color-accent-link);
          text-decoration: none;
          font-weight: 500;
        }
        a:focus,
        a:hover,
        a:active {
          text-decoration: underline;
        }

        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          outline: none;
        }

        .disabled {
          opacity: 0.4;
          pointer-events: none;
          user-select: none;
          cursor: default;
        }

        .hidden {
          display: none;
        }

        textarea,
        input,
        button {
          background: none;
          font-family: inherit;
          margin: 0;
          line-height: 1;
          border: 0;
          outline: none;
          appearance: none;
          text-decoration: none;
        }

        button {
          cursor: pointer;
        }
        ${reactDatesStyles}
        ${reactVirtualizedStyles}
      `}
    />
  );
}
