import React, { useCallback, useState } from 'react';
import type {
  DataRowListRendererProps,
  EntityListSupportedEntities,
  EntityListSelectModel,
  LibraryHeaderToolsProps,
} from 'components';
import { EntityList, LibraryHeaderTools } from 'components';
import { LibraryWrap } from '../LibraryWrap';

type EntityPageProps<E extends EntityListSupportedEntities> = {
  actions?: React.ReactNode;
  entity: E;
  rowRenderer?: DataRowListRendererProps<
    EntityListSelectModel<E>
  >['rowRenderer'];
  renderQueryBuilder: LibraryHeaderToolsProps['renderQueryBuilder'];
};

export default function EntityPage<E extends EntityListSupportedEntities>({
  entity,
  rowRenderer,
  actions,
  renderQueryBuilder,
}: EntityPageProps<E>) {
  const [{ filterValue, searchQuery }, setFilter] = useState(() => ({
    filterValue: [],
    searchQuery: undefined,
  }));

  const onChangeFiltering = useCallback(({ value, searchQuery: newQuery }) => {
    setFilter({ filterValue: value, searchQuery: newQuery });
  }, []);

  return (
    <LibraryWrap>
      <LibraryHeaderTools
        value={filterValue}
        renderQueryBuilder={renderQueryBuilder}
        onChangeFiltering={onChangeFiltering}
      >
        {actions}
      </LibraryHeaderTools>

      <EntityList
        query={searchQuery}
        entity={entity}
        rowRenderer={rowRenderer}
      />
    </LibraryWrap>
  );
}
