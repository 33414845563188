export default {
  add: 'M10,3 C10.5521988,3 10.9998444,3.44764559 10.9998444,3.99984442 C10.9998444,3.99989628 10.9998444,3.99994813 10.9998444,3.99999999 L10.9990664,9.00046681 L10.9990664,9.00046681 L16,9.00007779 C16.5522418,9.00003483 16.999957,9.44768043 17,9.99992222 C17,9.99994814 17,9.99997407 17,10 C17,10.5522418 16.5523196,10.9999222 16.0000778,10.9999222 C16.0000519,10.9999222 16.0000259,10.9999222 16,10.9999222 L10.9990664,10.9995332 L10.9990664,10.9995332 L10.9998444,16 C10.9999303,16.5521988 10.5523544,16.9999141 10.0001556,17 C10.0001037,17 10.0000519,17 10,17 C9.44771525,17 9,16.5522847 9,16 L9,10.9995332 L9,10.9995332 L4,10.9999222 C3.44775822,10.9999652 3.00004297,10.5523196 3,10.0000778 C3,10.0000519 3,10.0000259 3,10 C3,9.44775822 3.44768042,9.0000778 3.9999222,9.0000778 C3.99994813,9.0000778 3.99997406,9.0000778 4,9.0000778 L9,9.00046681 L9,9.00046681 L9,4 C9,3.44771525 9.44771525,3 10,3 Z',
  refresh:
    'M13.2096,6.22843 C13.9199,6.85643,14.4597,7.6931,14.7235,8.67746 C15.4382,11.3448,13.8553,14.0865,11.1879,14.8012 C8.52061,15.5159,5.77892,13.933,5.06422,11.2657 C4.34951,8.59832,5.93242,5.85664,8.59975,5.14193 L9.56381,4.88361 L8.32706,7.02573 L9.85917,7.91029 L12.6876,3.01132 L7.78862,0.182888 L6.90405,1.715 L9.04617,2.95176 L8.08211,3.21007 C4.34785,4.21067,2.13177,8.04903,3.13236,11.7833 C4.13296,15.5176,7.97132,17.7336,11.7056,16.733 C15.4398,15.7324,17.6559,11.8941,16.6553,8.15982 C16.2484,6.64106,15.372,5.37343,14.2242,4.47119 L13.2096,6.22843 Z',
  remove:
    'M17,10 C17,10.5522847 16.5522847,11 16,11 L4,11 C3.44771525,11 3,10.5522847 3,10 C3,9.44771525 3.44771525,9 4,9 L16,9 C16.5522847,9 17,9.44771525 17,10 Z',
  close:
    'M8.58579,10 L2.92893,15.6569 L4.34315,17.0711 L10,11.4142 L15.6569,17.0711 L17.0711,15.6569 L11.4142,10 L17.0711,4.34315 L15.6569,2.92893 L10,8.58579 L4.34315,2.92893 L2.92893,4.34315 Z',
  clearList:
    'M1,4 L11,4 L11,6 L1,6 L1,4 Z M1,8 L11,8 L11,10 L1,10 L1,8 Z M1,12 L11,12 L11,14 L1,14 L1,12 Z M15.6568542,13.2426407 L17.7781746,11.1213203 L19.1923882,12.5355339 L17.0710678,14.6568542 L19.1923882,16.7781746 L17.7781746,18.1923882 L15.6568542,16.0710678 L13.5355339,18.1923882 L12.1213203,16.7781746 L14.2426407,14.6568542 L12.1213203,12.5355339 L13.5355339,11.1213203 L15.6568542,13.2426407 Z',
  trash:
    'M7,3 C7.66667,1.66667,8.66667,1,10,1 C11.3333,1,12.3333,1.66667,13,3 L16,3 L16,5 L4,5 L4,3 L7,3 Z M5,6 L15,6 L15,16.4361 C15,17.3276,14.9072,17.6509,14.7329,17.9768 C14.5586,18.3028,14.3028,18.5586,13.9768,18.7329 C13.6509,18.9072,13.3276,19,12.4361,19 L7.56389,19 C6.67237,19,6.34908,18.9072,6.02315,18.7329 C5.69723,18.5586,5.44144,18.3028,5.26713,17.9768 C5.09283,17.6509,5,17.3276,5,16.4361 L5,6 Z M9.5,8 L9.5,17 L10.5,17 L10.5,8 L9.5,8 Z M12,8 L12,17 L13,17 L13,8 L12,8 Z M7,8 L7,17 L8,17 L8,8 L7,8 Z',
  distribute:
    'M16.1716,9.00315 L-0.0031456,9.00315 L-0.0031456,11.0031 L16.1653,11.0031 L12.9258,14.2426 L14.34,15.6569 L19.9969,10 L14.34,4.34315 L12.9258,5.75736 Z',
  distributeYouTube:
    'M12,6.5 L15,6.5 C15.3682,6.5,15.7131,6.59949,16.0094,6.77306 C16.3057,6.94662,16.5534,7.19427,16.7269,7.49056 C16.9005,7.78686,17,8.13181,17,8.5 L17.0035,11.5 C17.0028,11.8692,16.9031,12.2145,16.7294,12.5108 C16.5556,12.8071,16.3079,13.0544,16.0113,13.2276 C15.7147,13.4009,15.3692,13.5,15,13.5 L12,13.5 L12,6.5 Z M14,8.5 L14,11.5 L15,11.5 L15,8.5 L14,8.5 Z M8.75,6.5 L10.75,6.5 L10.75,13.5 L8.75,13.5 L8.75,6.5 Z M7.5,8.5 L5,8.5 L5,11.5 L7.5,11.5 L7.5,13.5 L4.28194,13.5 C3.83618,13.5,3.67454,13.4536,3.51158,13.3664 C3.34861,13.2793,3.22072,13.1514,3.13357,12.9884 C3.04641,12.8255,3,12.6638,3,12.2181 L3,7.78194 C3,7.33618,3.04641,7.17454,3.13357,7.01158 C3.22072,6.84861,3.34861,6.72072,3.51158,6.63357 C3.67454,6.54641,3.83618,6.5,4.28194,6.5 L7.5,6.5 L7.5,8.5 Z M1.89547,4.60331 C1.68534,4.6346,1.51791,4.79526,1.47799,5.0039 C1.15925,6.67024,1,8.33534,1,10 C1,11.6646,1.15925,13.3297,1.47799,14.9961 C1.5179,15.2047,1.68533,15.3654,1.89543,15.3967 C4.59742,15.7989,7.29882,16,10,16 C12.7012,16,15.4025,15.7989,18.1045,15.3967 C18.3147,15.3654,18.4821,15.2047,18.522,14.9961 C18.8407,13.3298,19,11.6647,19,10 C19,8.33535,18.8408,6.67027,18.522,5.00394 C18.4821,4.79527,18.3147,4.63461,18.1046,4.60332 C15.4026,4.20108,12.7012,4,10,4 C7.29883,4,4.59745,4.20107,1.89547,4.60331 Z M1.74823,3.61421 C4.49882,3.20474,7.24941,3,10,3 C12.7506,3,15.5012,3.20474,18.2518,3.61422 C18.8822,3.70809,19.3845,4.19007,19.5042,4.81606 C19.8347,6.54404,20,8.27202,20,10 C20,11.728,19.8347,13.456,19.5042,15.184 C19.3844,15.81,18.8822,16.2919,18.2518,16.3858 C15.5012,16.7953,12.7506,17,10,17 C7.2494,17,4.49879,16.7953,1.74819,16.3858 C1.11779,16.2919,0.615534,15.8099,0.495793,15.1839 C0.165264,13.456,-1.02632e-14,11.728,0,10 C1.06564e-14,8.27201,0.165267,6.54401,0.495801,4.81602 C0.615571,4.19003,1.11783,3.70806,1.74823,3.61421 Z',
  distributeMastering:
    'M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,2.5 C6.75329488,2.5 2.5,6.75329488 2.5,12 C2.5,17.2467051 6.75329488,21.5 12,21.5 C17.2467051,21.5 21.5,17.2467051 21.5,12 C21.5,6.75329488 17.2467051,2.5 12,2.5 Z M9,7 L12,10.5 L15,7 L17,7 L17,17 L15,17 L15,10.5 L12,13.5 L9,10.5 L9,17 L7,17 L7,7 L9,7 Z',
  distribution: {
    delivery: 'M10,13 L2,13 L2,7 L10,7 L10,3 L19,10 L10,17 Z',
  },
  download:
    'M3,17 L17,17 L17,19 L3,19 L3,17 Z M11,10.9976 L14.4988,7.49882 L15.9984,8.99843 L9.99685,15 L3.99843,9.00157 L5.49882,7.50118 L9,11.0024 L9,1 L11,1 L11,10.9976 Z',
  drag: 'M7,12 C5.89543,12,5,11.1046,5,10 C5,8.89543,5.89543,8,7,8 C8.10457,8,9,8.89543,9,10 C9,11.1046,8.10457,12,7,12 Z M13,12 C11.8954,12,11,11.1046,11,10 C11,8.89543,11.8954,8,13,8 C14.1046,8,15,8.89543,15,10 C15,11.1046,14.1046,12,13,12 Z M7,5 C5.89543,5,5,4.10457,5,3 C5,1.89543,5.89543,1,7,1 C8.10457,1,9,1.89543,9,3 C9,4.10457,8.10457,5,7,5 Z M13,5 C11.8954,5,11,4.10457,11,3 C11,1.89543,11.8954,1,13,1 C14.1046,1,15,1.89543,15,3 C15,4.10457,14.1046,5,13,5 Z M7,19 C5.89543,19,5,18.1046,5,17 C5,15.8954,5.89543,15,7,15 C8.10457,15,9,15.8954,9,17 C9,18.1046,8.10457,19,7,19 Z M13,19 C11.8954,19,11,18.1046,11,17 C11,15.8954,11.8954,15,13,15 C14.1046,15,15,15.8954,15,17 C15,18.1046,14.1046,19,13,19 Z',
  tools:
    'M4.94308,3.80068 C5.60282,3.26188,6.35016,2.82629,7.16047,2.51858 L8,0 L12,0 L12.8395,2.51858 C13.6498,2.82629,14.3972,3.26188,15.0569,3.80068 L17.6603,3.26795 L19.6603,6.73205 L17.898,8.71932 C17.9651,9.13631,18,9.56408,18,10 C18,10.4359,17.9651,10.8637,17.898,11.2807 L19.6603,13.2679 L17.6603,16.732 L15.0569,16.1993 C14.3972,16.7381,13.6498,17.1737,12.8395,17.4814 L12,20 L8,20 L7.16047,17.4814 C6.35016,17.1737,5.60282,16.7381,4.94308,16.1993 L2.33975,16.732 L0.339746,13.2679 L2.10196,11.2807 C2.03487,10.8637,2,10.4359,2,10 C2,9.56408,2.03487,9.13631,2.10196,8.71932 L0.339746,6.73205 L2.33975,3.26795 L4.94308,3.80068 Z M10,16 C13.3137,16,16,13.3137,16,10 C16,6.68629,13.3137,4,10,4 C6.68629,4,4,6.68629,4,10 C4,13.3137,6.68629,16,10,16 Z',
  edit: 'M16.0815,6.03769 L13.9602,3.91637 L15.3744,2.50216 L17.4957,4.62348 L16.0815,6.03769 Z M15.3744,6.7448 L6.18198,15.9372 L4.06066,15.9372 L4.06066,13.8159 L13.253,4.62348 L15.3744,6.7448 Z',
  filter:
    'M1,4 L19,4 L19,6 L1,6 L1,4 Z M4,9 L16,9 L16,11 L4,11 L4,9 Z M7,14 L13,14 L13,16 L7,16 L7,14 Z',
  help: 'M10,20 C4.47715,20,0,15.5228,0,10 C0,4.47715,4.47715,0,10,0 C15.5228,0,20,4.47715,20,10 C20,15.5228,15.5228,20,10,20 Z M10,18 C14.4183,18,18,14.4183,18,10 C18,5.58172,14.4183,2,10,2 C5.58172,2,2,5.58172,2,10 C2,14.4183,5.58172,18,10,18 Z M8.96844,12.1681 C8.96844,11.5277,9.04788,11.0174,9.20676,10.6371 C9.36564,10.2567,9.608,9.90863,10.1492,9.38408 C10.6904,8.85953,11.33,8.07761,11.33,7.45948 C11.33,6.84134,11.1654,6.06567,10.0084,6.06567 C8.85136,6.06567,8.62902,6.96599,8.62902,7.36559 L6.87413,7.36559 C6.88375,6.51342,7.43882,4.59965,10.0084,4.59965 C12.5779,4.59965,13.0849,6.3024,13.0849,7.39448 C13.0849,8.48656,12.409,9.27415,11.9871,9.71267 L11.0989,10.5865 C10.7811,10.9476,10.6174,11.4748,10.6078,12.1681 L8.96844,12.1681 Z M8.84567,14.4141 C8.84567,14.13,8.93474,13.9001,9.11288,13.7244 C9.29102,13.5486,9.53174,13.4608,9.83506,13.4608 C10.1432,13.4608,10.3863,13.551,10.5645,13.7316 C10.7426,13.9121,10.8317,14.1396,10.8317,14.4141 C10.8317,14.6789,10.745,14.9003,10.5717,15.0785 C10.3984,15.2566,10.1528,15.3457,9.83506,15.3457 C9.5173,15.3457,9.27296,15.2566,9.10205,15.0785 C8.93113,14.9003,8.84567,14.6789,8.84567,14.4141 Z',
  includeLibrary:
    'M7,5 L10,5 L10,7 L7,7 L7,10 L5,10 L5,7 L2,7 L2,5 L5,5 L5,2 L7,2 L7,5 Z M16,5 L16,15 C16,17.2091,14.2091,19,12,19 C9.79086,19,8,17.2091,8,15 C8,12.7909,9.79086,11,12,11 C12.7286,11,13.4117,11.1948,14,11.5351 L14,3 L16,3 L19,3 L19,5 L16,5 Z M12,17 C13.1046,17,14,16.1046,14,15 C14,13.8954,13.1046,13,12,13 C10.8954,13,10,13.8954,10,15 C10,16.1046,10.8954,17,12,17 Z',
  mail: 'M6,5 L10,9 L14,5 L6,5 Z M2,3 L18,3 L18,17 L2,17 L2,3 Z M4,6 L4,15 L16,15 L16,6 L10,12 L4,6 Z',
  maximize:
    'M4,4 L4,9 L2,9 L2,2 L9,2 L9,4 L4,4 Z M16,4 L11,4 L11,2 L18,2 L18,9 L16,9 L16,4 Z M16,16 L16,11 L18,11 L18,18 L11,18 L11,16 L16,16 Z M4,16 L9,16 L9,18 L2,18 L2,11 L4,11 L4,16 Z',
  minimize:
    'M6,6 L6,1 L8,1 L8,8 L1,8 L1,6 L6,6 Z M14,6 L19,6 L19,8 L12,8 L12,1 L14,1 L14,6 Z M14,14 L14,19 L12,19 L12,12 L19,12 L19,14 L14,14 Z M6,14 L1,14 L1,12 L8,12 L8,19 L6,19 L6,14 Z',
  more: 'M3,12 C1.89543,12,1,11.1046,1,10 C1,8.89543,1.89543,8,3,8 C4.10457,8,5,8.89543,5,10 C5,11.1046,4.10457,12,3,12 Z M10,12 C8.89543,12,8,11.1046,8,10 C8,8.89543,8.89543,8,10,8 C11.1046,8,12,8.89543,12,10 C12,11.1046,11.1046,12,10,12 Z M17,12 C15.8954,12,15,11.1046,15,10 C15,8.89543,15.8954,8,17,8 C18.1046,8,19,8.89543,19,10 C19,11.1046,18.1046,12,17,12 Z',
  multiselect:
    'M4.53553391,9.65685425 L8.07106781,6.12132034 L9.48528137,7.53553391 L4.53553391,12.4852814 L3.82842712,11.7781746 L1,8.94974747 L2.41421356,7.53553391 L4.53553391,9.65685425 Z M4.53553391,4.65685425 L8.07106781,1.12132034 L9.48528137,2.53553391 L4.53553391,7.48528137 L3.82842712,6.77817459 L1,3.94974747 L2.41421356,2.53553391 L4.53553391,4.65685425 Z M11,4 L19,4 L19,6 L11,6 L11,4 Z M11,9 L19,9 L19,11 L11,11 L11,9 Z M11,14 L19,14 L19,16 L11,16 L11,14 Z',
  newcard:
    'M10,15 L10,17 L4,17 L4,2 L16,2 L16,11 L14,11 L14,6 L6,6 L6,15 L10,15 Z M14,15 L14,13 L16,13 L16,15 L18,15 L18,17 L16,17 L16,19 L14,19 L14,17 L12,17 L12,15 L14,15 Z',
  pay: 'M2,2 L18,2 C19.1046,2,20,2.89543,20,4 L20,16 C20,17.1046,19.1046,18,18,18 L2,18 C0.895431,18,1.35271e-16,17.1046,0,16 L0,4 C-1.35271e-16,2.89543,0.895431,2,2,2 Z M2,4 L2,16 L18,16 L18,4 L2,4 Z M2,6 L18,6 L18,9 L2,9 L2,6 Z',
  remarkFull:
    'M10,16 L10,18 L3,18 L3,1 L17,1 L17,11 L15,11 L15,3 L5,3 L5,16 L10,16 Z M17.864,14.682 L12.6213,19.9246 L11.2071,19.9246 L11.2071,18.5104 L16.4497,13.2678 L17.864,14.682 Z M18.2175,11.5 L19.6317,12.9142 L18.5711,13.9749 L17.1569,12.5607 L18.2175,11.5 Z M6,4 L14,4 L14,6 L6,6 L6,4 Z M6,7 L11,7 L11,9 L6,9 L6,7 Z',
  remarkEmpty:
    'M10,16 L10,18 L3,18 L3,1 L17,1 L17,11 L15,11 L15,3 L5,3 L5,16 L10,16 Z M17.864,14.682 L12.6213,19.9246 L11.2071,19.9246 L11.2071,18.5104 L16.4497,13.2678 L17.864,14.682 Z M18.2175,11.5 L19.6317,12.9142 L18.5711,13.9749 L17.1569,12.5607 L18.2175,11.5 Z',
  report:
    'M9.99949494,0.100000001 L19.8989899,9.99949494 L9.99949494,19.8989899 L0.100000001,9.99949494 L9.99949494,0.100000001 Z M9.99949494,2.92842713 L2.92842713,9.99949494 L9.99949494,17.0705627 L17.0705627,9.99949494 L9.99949494,2.92842713 Z M9,5 L11,5 L11,11 L9,11 L9,5 Z M10,15 C9.30964406,15 8.75,14.4403559 8.75,13.75 C8.75,13.0596441 9.30964406,12.5 10,12.5 C10.6903559,12.5 11.25,13.0596441 11.25,13.75 C11.25,14.4403559 10.6903559,15 10,15 Z',
  resumeDelivery:
    'M8,7.50157 L8,6 L6,6 L6,7.50157 L6,12.5016 L6,14 L8,14 L8,12.5016 L8,7.50157 Z M10,9.00315 L16.1747,9.00315 L12.9289,5.75736 L14.3431,4.34315 L20,10 L14.3431,15.6569 L12.9289,14.2426 L16.1684,11.0031 L10,11.0031 L10,9.00315 Z M4,9.00315 L0,9.00315 L1.13687e-13,11.0031 L4,11.0031 L4,9.00315 Z',
  return:
    'M5.82528,10.0031 L16,10.0031 L16,12.0031 L5.83157,12.0031 L9.07107,15.2426 L7.65685,16.6569 L2,11 L7.65685,5.34315 L9.07107,6.75736 L5.82528,10.0031 Z M16,2 L18,2 L18,12 L16,12 L16,2 Z',
  search:
    'M19.6066,18.1924 L18.1924,19.6066 L12.1922,13.6064 C11.0236,14.4816,9.57234,15,8,15 C4.13401,15,1,11.866,1,8 C1,4.13401,4.13401,1,8,1 C11.866,1,15,4.13401,15,8 C15,9.57234,14.4816,11.0236,13.6064,12.1922 L19.6066,18.1924 Z M8,13 C10.7614,13,13,10.7614,13,8 C13,5.23858,10.7614,3,8,3 C5.23858,3,3,5.23858,3,8 C3,10.7614,5.23858,13,8,13 Z',
  signout:
    'M15.499405,9 L12.75,6.25059497 L14.0003569,5 L19.0003569,10 L14.0003569,15 L12.75,13.749405 L15.499405,11 L7,11 L7,9 L15.499405,9 Z M1,1 L12,1 L12,3 L3,3 L3,17 L12,17 L12,19 L1,19 L1,1 Z',
  sortup: 'M5.99964,12.0004 L10,8 L14.0004,12.0004 L5.99964,12.0004 Z',
  sortdown: 'M5.99964,9 L10,13.0004 L14.0004,9 L5.99964,9 Z',
  withTranslation:
    'M12.1284,20 L10,20 L13.6397,10 L16.3033,10 L19.943,20 L17.8146,20 L17.0867,18 L12.8563,18 L12.1284,20 L12.1284,20 Z M16.3587,16 L14.9715,12.1886 L13.5842,16 L16.3587,16 Z M4.5,1 L4.5,0 L6.5,0 L6.5,1 L11,1 L11,3 L8.89933,3 C8.7158,4.6004,8.10947,6.03733,7.08854,7.28964 C7.80955,7.65071,8.6804,7.88779,9.7101,7.99789 L10.7044,8.10421 L10.4918,10.0929 L9.49746,9.98656 C7.93169,9.81913,6.60125,9.39097,5.52222,8.69677 C4.44318,9.39097,3.11274,9.81913,1.54697,9.98656 L0.55264,10.0929 L0.34,8.10421 L1.33433,7.99789 C2.36403,7.88779,3.23488,7.65071,3.95589,7.28964 C3.15237,6.30402,2.60568,5.20403,2.3198,4 L4.39449,4 C4.63204,4.73876,5.00718,5.41866,5.52222,6.04567 C6.26173,5.14537,6.71282,4.13604,6.88234,3 L0,3 L0,1 L4.5,1 Z M16,5 L16,5 C16,3.89543,15.1046,3,14,3 L13,3 L13,1 L14,1 C16.2091,1,18,2.79086,18,5 L18,5 L20,5 L17,8 L14,5 L16,5 Z M4,15 C4,16.1046,4.89543,17,6,17 L7,17 L7,19 L6,19 C3.79086,19,2,17.2091,2,15 L0,15 L3,12 L6,15 L4,15 Z',
  upload:
    'M11,4.83157 L11,15 L9,15 L9,4.82528 L5.75421,8.07107 L4.34,6.65685 L9.99685,1 L15.6537,6.65685 L14.2395,8.07107 L11,4.83157 Z M3,17 L17,17 L17,19 L3,19 L3,17 Z',
  viewMore: 'M17,9 L12,4 L17,4 L17,9 Z M3,11 L8,16 L3,16 L3,11 Z',
  comment:
    'M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z',
  withdraw:
    'M13,18 L15,16 L18,16 L18,4 L13,4 L7,4 L2,4 L2,16 L5.02995,16 L7,18 L2,18 C0.895431,18,-3.08818e-16,17.1046,-4.44089e-16,16 L-4.44089e-16,4 C-5.7936e-16,2.89543,0.895431,2,2,2 L18,2 C19.1046,2,20,2.89543,20,4 L20,16 C20,17.1046,19.1046,18,18,18 L13,18 Z M7,6 L7,9 L2,9 L2,6 L7,6 Z M13,6 L18,6 L18,9 L13,9 L13,6 Z M11.0016,13.9976 L13.5004,11.4988 L15,12.9984 L9.99843,18 L5,13.0016 L6.50039,11.5012 L9.00157,14.0024 L9.00157,6 L11.0016,6 L11.0016,13.9976 Z',
};
