import React, { useMemo, useCallback } from 'react';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { isSameDay } from 'react-dates';
import {
  NewInput,
  RadioButtonsField,
  NewInputField,
  ConfirmationContent,
  DatePickerInputField,
  MomentDateField,
  Caption,
} from 'imdshared';
import { useTranslation } from 'react-i18next';
import { ProcessFieldMeta } from 'imddata/providers';
import { useCalendar, useCreateEntity } from 'imddata';
import PhoneInput from 'react-phone-number-input/input';
import { CallRequestIcon } from './CallRequestIcon';

// react-dates/src/utils/isBeforeDay
function isBeforeDay(a: unknown, b: unknown) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

const CallRequestFinalizedWrappper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CallRequestIconStyled = styled(CallRequestIcon)`
  width: 128px;
  display: block;
  align-self: center;
`;

const Wrapper = styled.div`
  padding: 0px 24px 16px;
`;

const RadioButtonsFieldStyled = styled(RadioButtonsField)`
  margin: 8px 0 0 0;
  display: flex;
  & > [role='radio'] {
    margin-right: 24px;
  }
`;

const ContactTimeFieldWrapper = styled.div`
  margin: 16px 0;
`;
const DateFieldWrapper = styled.div`
  margin: 8px 0 0 0;
`;

const AVAILABLE_DAY_OFFSET = 5;

const InputWithRef = React.forwardRef((props: any, ref) => (
  <NewInput inputRef={ref} {...props} />
));

const PhoneField = ({ input, meta, ...props }: any) => {
  return (
    <ProcessFieldMeta hasValue={input && !!input.value} meta={meta}>
      {({ error, warning }) => (
        <PhoneInput
          errorText={error}
          warningText={warning}
          addInternationalOption={true}
          inputComponent={InputWithRef}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          onChange={input.onChange}
          {...props}
        />
      )}
    </ProcessFieldMeta>
  );
};

const CallRequestDatePicker = (
  props: React.ComponentProps<typeof DatePickerInputField>
) => {
  const { data } = useCalendar();

  const isDayBlocked = useCallback(
    (day) => {
      if (!data) return true;
      const { dates, firstAvailable } = data;
      const curDay = day.format('YYYY-MM-DD');
      const optionDay = dates && dates[curDay];
      const firstAvailableDay = moment(firstAvailable.date).add(
        AVAILABLE_DAY_OFFSET,
        'day'
      );

      return (
        isSameDay(day, moment()) ||
        isBeforeDay(day, firstAvailableDay) ||
        (optionDay && !optionDay.isAvailable)
      );
    },
    [data?.dates]
  );
  return <DatePickerInputField {...props} isDayBlocked={isDayBlocked} />;
};

type TimeRangeOption = {
  value: number;
  text: string;
};

const CallRequestFormFields = ({
  timeRangesOptions,
}: {
  timeRangesOptions: TimeRangeOption[];
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Field
        name="phoneNumber"
        component={PhoneField}
        floatingLabelText={t('phone-number')}
      />
      <DateFieldWrapper>
        <MomentDateField
          name="contactDate"
          component={CallRequestDatePicker}
          floatingLabelText={t('call-request-contact-date')}
          helperText={t('call-request-contact-date-helperText')}
        />
      </DateFieldWrapper>
      <ContactTimeFieldWrapper>
        <Caption>{t('call-request-contact-time')}</Caption>
        <Field
          name="contactTime"
          options={timeRangesOptions}
          component={RadioButtonsFieldStyled}
        />
      </ContactTimeFieldWrapper>
      <Field
        name="message"
        floatingLabelText={t('call-request-customer-message-label')}
        multiline={true}
        component={NewInputField}
      />
    </Wrapper>
  );
};

function CallRequestWindow({
  onRequestClose,
  subject = 'call-request',
  submitSucceeded,
  submitting,
  submitFailed,
  handleSubmit,
  pristine,
  valid,
  form,
  ...props
}: InjectedFormProps<Values, Props> & Props) {
  const { t } = useTranslation();
  const { createEntry } = useCreateEntity({ entity: 'contactRequests' });
  const currentDay = moment();
  const currentTimezone = currentDay.tz();

  const ranges = useMemo(
    () =>
      currentTimezone
        ? [
            [
              moment
                .tz('2019-12-18 10:00', 'Europe/Berlin')
                .year(currentDay.year())
                .tz(currentTimezone),
              moment
                .tz('2019-12-18 13:00', 'Europe/Berlin')
                .year(currentDay.year())
                .tz(currentTimezone),
            ],
            [
              moment
                .tz('2013-11-18 14:00', 'Europe/Berlin')
                .year(currentDay.year())
                .tz(currentTimezone),
              moment
                .tz('2013-11-18 17:30', 'Europe/Berlin')
                .year(currentDay.year())
                .tz(currentTimezone),
            ],
          ]
        : [],
    [currentTimezone]
  );

  const onConfirm = useCallback(
    handleSubmit(({ phoneNumber, message, contactTime, contactDate }) => {
      const [fromTime, toTime] = ranges[contactTime];
      const from = fromTime
        .dayOfYear(moment(contactDate).dayOfYear())
        .toISOString(true);
      const to = toTime
        .dayOfYear(moment(contactDate).dayOfYear())
        .toISOString(true);
      createEntry({
        formId: form,
        data: {
          event: subject,
          payload: {
            timezone: currentTimezone,
            from,
            to,
            message,
            phoneNumber,
          },
        },
      });
    }),
    [handleSubmit, createEntry]
  );

  const timeRangesOptions = useMemo(
    () =>
      ranges.map(([from, to], idx) => {
        return {
          text: `${t('between')} ${from.format('LT')}-${to.format('LT')}`,
          value: idx,
        };
      }),
    [t, ranges]
  );

  const message = submitSucceeded
    ? t('call-request-received-message')
    : submitFailed
      ? t('call-request-error-message')
      : t('call-request-form-message');
  return (
    <ConfirmationContent
      onRequestClose={onRequestClose}
      cancelLabel={submitSucceeded ? t('close') : t('cancel')}
      onConfirm={submitSucceeded || submitFailed ? null : onConfirm}
      loading={submitting}
      disabled={pristine || !valid}
    >
      {!(submitSucceeded || submitFailed) && (
        <>
          <div style={{ margin: '24px 24px 16px' }}>{message}</div>
          <CallRequestFormFields
            {...props}
            timeRangesOptions={timeRangesOptions}
          />
        </>
      )}
      {(submitFailed || submitSucceeded) && (
        <CallRequestFinalizedWrappper>
          <CallRequestIconStyled />
          <div style={{ margin: '24px 24px 16px' }}>{message}</div>
        </CallRequestFinalizedWrappper>
      )}
    </ConfirmationContent>
  );
}

const FORM_ID = 'callRequestForm';

type Values = {
  phoneNumber?: string;
  contactTime: number;
  message?: string;
  contactDate?: Moment;
};

type Props = {
  subject: string;
  onRequestClose: () => void;
};

const Form = reduxForm<Values, Props>({
  form: FORM_ID,
  validate: (values) => {
    const errors: any = {};

    if (!values.phoneNumber) {
      errors.phoneNumber = 'required';
    }
    return errors;
  },
  submitAsSideEffect: true,
})(CallRequestWindow);

export default function CallRequestWindowConnected(props: Props) {
  const { data } = useCalendar();
  const initialValues = useMemo(() => {
    if (!data?.firstAvailable || !data?.dates) return null;

    const { dates } = data;
    let start = moment().add(AVAILABLE_DAY_OFFSET, 'day');
    // eslint-disable-next-line
    while (true) {
      const key = start.format('YYYY-MM-DD');
      if (!dates[key] || dates[key].isAvailable) {
        return {
          contactTime: 0,
          contactDate: start,
        };
      }
      start = start.add(1, 'day');
    }
  }, [data?.firstAvailable]);

  return initialValues ? (
    <Form initialValues={initialValues} {...props} />
  ) : null;
}
