import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const gridGapsCss = css`
  grid-gap: 8px;
`;

export const Grid = styled(motion.div)<{ columns?: number }>`
  display: grid;
  grid-template-columns: 1fr;
  ${gridGapsCss}

  @supports not (display: grid) {
    > * {
      // margin-bottom: 24px;
    }
  }

  ${({ columns }) => {
    if (!columns || columns >= 3) {
      return `
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1152px) {
    grid-template-columns: repeat(3, 1fr);
  }

  `;
    }
    return '';
  }}

  ${({ columns }) => {
    if (!columns || columns >= 4) {
      return `
  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
  `;
    }
    return '';
  }}
`;

Grid.defaultProps = {
  columns: 4,
};
