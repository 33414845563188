import React from 'react';
import { Button } from 'imdshared';
import styled from '@emotion/styled';

const TermsActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const AcceptTermsButton = (
  props: React.ComponentProps<typeof Button>
) => {
  return (
    <TermsActions>
      <Button
        primary={true}
        {...props}
        testId={props.testId + '--enabled'}
        disabled={props.disabled}
      />
    </TermsActions>
  );
};
