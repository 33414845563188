//
import React, { useCallback } from 'react';
import { set, lensPath, without } from 'ramda';
import { connect } from 'react-redux';
import { actions as uiActions } from 'imddata/actionTypes/ui/selection';
import { Button } from 'imdshared';
import { updateEntity as updateEntityAction } from 'imddata/actionTypes/request';
import { SelectedTracksList } from 'components';

// TODO: Deprecate
const TracksManager = ({
  updateSelection,
  updateEntity,
  trackIds,
  nestingPath,
  requiredFields,
  validate,
  isTrackValid,
  targetName,
  isNotAllowed,
  removeTrackButtonLabel,
}) => {
  const updateTracks = useCallback(
    (newTracks) => {
      updateEntity(nestingPath.entity, {
        id: nestingPath.entityId,
        query: { with: 'tracks' },
        data: set(
          lensPath(nestingPath.path),
          newTracks.map((trackId) => ({
            trackId,
            ...(nestingPath.wrapperData || {}),
          })),
          {}
        ),
      });
    },
    [nestingPath]
  );

  return (
    <>
      <SelectedTracksList
        virtualized={false}
        requiredFields={requiredFields}
        additionalValidation={validate}
        isValid={isTrackValid}
        trackIds={trackIds}
        nestingPath={nestingPath}
        targetName={targetName}
        hideReleaseName={true}
        isDisabled={isNotAllowed}
        onChange={updateTracks}
        renderActions={({ selectedTracks }) => (
          <div>
            <Button
              onClick={() => {
                const newTracks = without(selectedTracks, trackIds);
                updateTracks(newTracks);
                updateSelection({
                  entity: 'tracks',
                  selected: [],
                });
              }}
              style={{ width: '100%' }}
              position="center"
              text={removeTrackButtonLabel}
            />
          </div>
        )}
      />
    </>
  );
};

export default connect(null, {
  updateSelection: uiActions.updateSelection,
  updateEntity: updateEntityAction,
})(TracksManager);
