import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ValuesGrid } from 'imdshared/src/Next/ReleaseDetails';
import { accentThemes, AmpIconBase } from '@imus/base-ui';
import { H4, BodyS, ContentS } from '@imus/services-ui/src/Text';

const PriceRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > ${ContentS} {
    display: inline-flex;
    align-items: center;
  }
`;

const IncludedBlock = styled.div`
  ${accentThemes.tertiary}
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  svg path {
    fill: var(--accent);
  }

  ${H4} {
    margin-bottom: 16px;
  }
  & > ${ValuesGrid} {
    border: none !important;
  }
  & > * {
    width: 100%;
  }
`;

const StrikeThroughPrice = styled.span`
  color: var(--fg-3, rgba(0, 0, 0, 0.35));
  text-decoration-line: line-through;
`;

const AmpText = styled.span`
  color: var(--accent);
`;

export const PriceBreakdown = ({
  items,
}: {
  items: {
    label: string;
    amps?: number | undefined;
    basePrice?: string;
    price: string;
  }[];
}) => {
  const { t } = useTranslation();

  return (
    <IncludedBlock>
      <H4>{t('what-is-included')}</H4>
      <ValuesGrid>
        {items.map(({ label, amps, basePrice, price }, idx) => (
          <PriceRow key={idx}>
            <BodyS>{label}</BodyS>
            <ContentS>
              {amps ? (
                <StrikeThroughPrice>{basePrice}</StrikeThroughPrice>
              ) : (
                price
              )}
              {amps ? (
                <>
                  &nbsp;
                  <AmpIconBase width={16} height={16} />
                  <AmpText>{amps}</AmpText>
                </>
              ) : (
                ''
              )}
            </ContentS>
          </PriceRow>
        ))}
      </ValuesGrid>
    </IncludedBlock>
  );
};
