/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useContext, useCallback } from 'react';
import type { ScaleConfig } from '@visx/scale';
import type { AxisScaleOutput } from '@visx/axis';
import { ParentSize } from '@visx/responsive';
import type { AxisProps } from '@visx/xychart/lib/components/axis/Axis';
import {
  buildChartTheme,
  Axis,
  BarSeries,

  // no animated equivalents
  XYChart,
} from '@visx/xychart';
import { ChartsContext } from './ChartsContextProvider';
import { CircleGrid } from './CircleGrid';
import { createAxisLabelProps } from './styles';
import {
  TooltipValue,
  TooltipLegendRow,
  TooltipLegendGrid,
  Tooltip,
} from './Tooltip';

export type Datum = {
  value: number;
  id: string;
  label: string;
  collapsed?: Array<Datum>;
  hidden?: boolean;
};

const theme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  colors: ['#B8569C'],
  tickLength: 16,
});

const xAccessor = (d: Datum) => d.id;
const yAccessor = (d: Datum) => d.value;

const verticalAxisLabelProps: AxisProps['tickLabelProps'] = (_value, index) => {
  return {
    ...createAxisLabelProps(index),
    dx: -16,
    textAnchor: 'start',
  };
};

const xConfig: ScaleConfig<AxisScaleOutput, any, any> = {
  type: 'band',
  paddingOuter: 0.4,
  paddingInner: 0.65,
};
const yConfig: ScaleConfig<AxisScaleOutput, any, any> = { type: 'linear' };

type Props = {
  verticalTicksNum?: number;
  fill?: string;
  data: Array<Datum>;
  selected?: string | null;
  disabled?: boolean;
};

type ChartProps = Props & {
  width: number;
  height: number;
};

const MARGIN = { top: 16, left: 98, right: 36, bottom: 48 + 16 };

const disabledData = [10, 9, 7, 5, 4, 3, 2].map((value) => ({
  value,
  id: `${value}`,
  label: '',
}));

function BarChart({
  data: propsData,
  fill = '#B8569C',
  height,
  verticalTicksNum = 4,
  width,
  selected,
  disabled,
}: ChartProps) {
  const data = useMemo(() => {
    if (disabled) {
      return disabledData;
    }

    if (!propsData) return disabledData;

    return propsData.filter((v) => !v.hidden);
  }, [propsData, disabled, disabledData]);

  const colorAccessor = useCallback(
    (d: Datum) => {
      if (disabled) return '#ccc';
      if (!!selected?.length && d.id !== selected) return '#aaaaaa';
      return fill;
    },
    [selected, disabled]
  );
  const { percentageFormatter, streamsFormatter } = useContext(ChartsContext);

  const total = propsData?.reduce((acc, v) => acc + yAccessor(v), 0);

  const tickToPercentage = useCallback(
    (tickString: string | number) => {
      if (disabled) return '';
      const tickValue = Number(tickString);
      return percentageFormatter.format(tickValue / total);
    },
    [disabled, total, percentageFormatter]
  );

  // @ts-ignore
  const bottomAxisLabelProps: AxisProps['tickLabelProps'] = useCallback(
    (value: string, index: number) => {
      return {
        ...createAxisLabelProps(index),
        ...(selected && selected === value
          ? {
              fontSize: '16px',
              fontWeight: '500',
              fill,
            }
          : {}),
        width: 16,
        textAnchor: 'middle',
        verticalAnchor: 'middle',
      };
    },
    [selected, fill]
  );
  const getLabel = useCallback(
    (id) => (disabled ? '' : data.find((d) => d.id === id)?.label),
    [data, disabled]
  );
  const numTicks = data.length;
  return (
    <XYChart
      margin={MARGIN}
      theme={theme}
      xScale={xConfig}
      yScale={yConfig}
      width={width}
      height={height}
    >
      <CircleGrid numTicks={numTicks} verticalTicksNum={verticalTicksNum} />
      <BarSeries
        colorAccessor={colorAccessor}
        dataKey="value"
        data={data}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
      />
      <Axis
        numTicks={numTicks}
        hideTicks={true}
        hideAxisLine={true}
        orientation="bottom"
        tickFormat={getLabel}
        top={height - MARGIN.bottom + 4}
        tickLabelProps={bottomAxisLabelProps}
      />
      <Axis
        tickFormat={tickToPercentage}
        orientation="left"
        numTicks={verticalTicksNum}
        hideAxisLine={true}
        hideTicks={true}
        tickLength={48}
        left={MARGIN.left}
        tickLabelProps={verticalAxisLabelProps}
      />

      {!disabled && (
        <Tooltip<Datum>
          unstyled={true}
          applyPositionStyle={true}
          showVerticalCrosshair={true}
          snapTooltipToDatumX={true}
          detectBounds={true}
          snapTooltipToDatumY={true}
          showSeriesGlyphs={true}
          renderTooltip={({ tooltipData }) => {
            const { value, collapsed, label } = tooltipData?.nearestDatum
              ?.datum || {
              value: 0,
              group: '',
            };
            const share = tickToPercentage(value);

            return (
              <>
                <TooltipValue>{label}</TooltipValue>
                <br />
                <br />
                <TooltipValue>
                  {streamsFormatter.format(value)} ({share})
                </TooltipValue>
                {collapsed ? (
                  <TooltipLegendGrid>
                    {collapsed.map((c) => (
                      <TooltipLegendRow
                        key={c.label}
                        type="dot"
                        value={`${streamsFormatter.format(
                          c.value
                        )} (${tickToPercentage(c.value)})`}
                        label={c.label}
                      />
                    ))}
                  </TooltipLegendGrid>
                ) : null}
              </>
            );
          }}
        />
      )}
    </XYChart>
  );
}

export default function ResponsiveBarChart(props: Props) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <BarChart width={width} height={height} {...props} />
      )}
    </ParentSize>
  );
}
