import { action } from '../base';
import { Query, EntityIdentifier } from '../types';

type ReduxMeta = any;

export const REFRESH_ENTITY = '@request/REFRESH_ENTITY';
export const LOAD_ENTITY = '@request/LOAD_ENTITY';
export const DELETE_ENTITY = '@request/DELETE_ENTITY';
export const BATCH_DELETE_ENTITY = '@request/BATCH_DELETE_ENTITY';
export const BATCH_UPDATE_ENTITY = '@request/BATCH_UPDATE_ENTITY';
export const UPDATE_ENTITY = '@request/UPDATE_ENTITY';
export const CREATE_ENTITY = '@request/CREATE_ENTITY';

export type UpdateEntityParams = {
  id?: number | string | Array<number | string>;
  formId?: string;
  componentId?: string;
  query?: Record<string, any>;
  data: Record<string, unknown>;
};

export type UpdateEntityAction = {
  type: '@request/LOAD_ENTITY';
  payload: UpdateEntityParams & {
    entityKey: EntityIdentifier;
  };
};

export type RefreshEntityParams = {
  id?: number | Array<number>;
  queryHash?: string;
};

export type LoadEntityParams = {
  id?: number | string | Array<number | string>;
  queryHash?: string;
  query?: Query;
  filter?: Record<string, unknown>;
  isSingle?: boolean;
};

export type DeleteEntityParams = {
  formId?: string;
  id?: number | string | Array<number | string>;
  query?: Query;
  queryHash?: string;
  data?: any;
};

export type BatchUpdateEntityPayload = {
  id: Array<number | string>;
  data: Record<string, unknown>;
  entityKey: EntityIdentifier;
};

export type BatchDeleteEntityPayload = {
  id: Array<number | string>;
  entityKey: EntityIdentifier;
};

export type LoadEntityPayload = LoadEntityParams & {
  entityKey: EntityIdentifier;
};

export type LoadEntityAction = {
  type: '@request/LOAD_ENTITY';
  meta: ReduxMeta;
  payload: LoadEntityPayload;
};

export type DeleteEntityAction = {
  type: '@request/DELETE_ENTITY';
  meta: ReduxMeta;
  payload: DeleteEntityParams & {
    entityKey: EntityIdentifier;
  };
};

export type BatchDeleteEntityAction = {
  type: '@request/BATCH_DELETE_ENTITY';
  meta: ReduxMeta;
  payload: BatchDeleteEntityPayload;
};

export type BatchUpdateEntityAction = {
  type: '@request/BATCH_UPDATE_ENTITY';
  meta: ReduxMeta;
  payload: BatchUpdateEntityPayload;
};

export type CreatePayload = {
  formId?: string;
  data: Record<string, unknown>;
  query?: Query;
  componentKey?: string;
  id?: string | number | Array<string | number>;
};

export const refreshEntity = (
  entityKey: EntityIdentifier,
  payload: any = {}
) => ({ ...action(REFRESH_ENTITY, { entityKey, ...payload }), entityKey });

export const loadEntity = (
  entityKey: EntityIdentifier,
  payload: LoadEntityParams = {},
  meta?: ReduxMeta
): LoadEntityAction => action(LOAD_ENTITY, { ...payload, entityKey }, meta);

export const deleteEntity = (
  entityKey: EntityIdentifier,
  payload: DeleteEntityParams = { id: -1 },
  meta?: ReduxMeta
): DeleteEntityAction => action(DELETE_ENTITY, { ...payload, entityKey }, meta);

export const batchDeleteEntity = (
  payload: BatchDeleteEntityPayload,
  meta?: ReduxMeta
): BatchDeleteEntityAction => action(BATCH_DELETE_ENTITY, payload, meta);

export const batchUpdateEntity = (
  payload: BatchUpdateEntityPayload,
  meta?: ReduxMeta
): BatchUpdateEntityAction => action(BATCH_UPDATE_ENTITY, payload, meta);

export function updateEntity(
  entityKey: EntityIdentifier,
  payload: UpdateEntityParams,
  meta?: ReduxMeta
) {
  return action(UPDATE_ENTITY, { entityKey, ...payload }, meta);
}

export function createEntity(
  entityKey: EntityIdentifier,
  payload: CreatePayload,
  meta?: ReduxMeta
) {
  return action(CREATE_ENTITY, { entityKey, ...payload }, meta);
}
