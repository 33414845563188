// @ts-ignore
import { EventTypes } from 'redux-segment';
import action from '../base/action';
import createRequestActions from '../base/createRequestActions';

export const actionTypes = {
  loggedUser: {
    fetch: {
      SUCCESS: '@user/FETCH_SUCCESS',
      REQUEST: '@user/FETCH_REQUEST',
      FAILURE: '@user/FETCH_FAILURE',
    },
  },
};

export const RESET_LOGGED_USER = '@user/RESET_LOGGED_USER';
export const LOAD_LOGGED_USER = '@user/LOAD_LOGGED_USER';

const mapSegmentLogin = ({ response }: any) => {
  if (response && response.result?.customerId) {
    const user = response.entities.users[response.result.userId];
    const { admin } = response.result;
    const trackingId = admin ? admin.trackingId : user?.trackingId;

    const adminTraits = admin
      ? {
          impersonating: {
            customerId: response.result.customerId,
            user: response.result.id,
            email: user?.email,
          },
        }
      : {};
    return {
      eventType: EventTypes.identify,
      eventPayload: {
        userId: trackingId,
        traits: {
          userId: response.result.id,
          customerId: response.result.customerId,
          email: admin ? admin.email : user?.email,
          appVersion: __VERSION__,
          ...adminTraits,
        },
      },
    };
  }

  return EventTypes.identify;
};

export const entityActions = {
  loggedUser: {
    fetch: createRequestActions(
      actionTypes.loggedUser.fetch,
      {
        success: mapSegmentLogin,
      },
      'fetch'
    ),
  },
};

export const loadLoggedUser = (
  { signup }: { signup: false | 'widget' | 'app' } = { signup: false }
) => {
  return action(LOAD_LOGGED_USER, { signup });
};

export const actions = {
  loadLoggedUser,
};
