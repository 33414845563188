//

import { AsyncActionTypes } from './asyncCrudActionTypeFactory';
import { RequestType } from './constants';

type AbortFunc = (payload: {
  payload?: Record<string, unknown>;
  meta?: Record<string, unknown>;
}) =>
  | {
      entityKey?: string;
      requestType?: RequestType;
      requestStatus: null;
      type: string;
      meta?: Record<string, unknown>;
      payload: Record<string, unknown>;
    }
  | never;

export type FactoryEntityActions = {
  abort: AbortFunc;
  request: (d: {
    payload?: Record<string, unknown>;
    meta?: Record<string, unknown>;
  }) => {
    entityKey?: string;
    requestType?: RequestType;
    requestStatus: 'request';
    type: string;
    meta?: Record<string, unknown>;
    payload: Record<string, unknown>;
  };
  success: (d: {
    response?: Record<string, unknown>;
    rawResponse?: Record<string, unknown>;
    responseDetails?: Record<string, unknown>;
    payload?: Record<string, unknown>;
    meta?: Record<string, unknown>;
  }) => {
    entityKey?: string;
    requestType?: RequestType;
    requestStatus: 'success';
    type: string;
    meta?: Record<string, unknown>;
    payload: Record<string, unknown>;
  };
  failure: (d: {
    error?: unknown;
    statusCode: number;
    payload?: Record<string, unknown>;
  }) => {
    entityKey?: string;
    requestType?: RequestType;
    requestStatus: 'failure';
    type: string;
    meta?: Record<string, unknown>;
    payload: Record<string, unknown>;
  };
};

export default function createRequestActions(
  types: AsyncActionTypes,
  segment?: any, // Probably remove that
  requestType?: RequestType,
  entityKey?: string
): FactoryEntityActions {
  const abort = types.ABORT
    ? ({ payload, meta } = { payload: {}, meta: {} }) => ({
        entityKey,
        requestType,
        requestStatus: null,
        type: types.ABORT,
        payload: payload || {},
        meta,
      })
    : () => {
        throw new Error(`no ABORT type specified${types.REQUEST}`);
      };
  return {
    // @ts-ignore
    abort,
    request: ({ payload, meta } = {}) => ({
      entityKey,
      requestType,
      requestStatus: 'request',
      type: types.REQUEST,
      payload: payload || {},
      meta,
    }),
    success: ({
      payload,
      response,
      responseDetails,
      rawResponse,
      meta = {},
    } = {}) => ({
      entityKey,
      meta:
        segment && segment.success
          ? {
              ...meta,
              analytics: segment.success({
                payload,
                response,
                responseDetails,
              }),
            }
          : meta,
      type: types.SUCCESS,
      requestType,
      requestStatus: 'success',
      payload: payload || {},
      response,
      responseDetails,
      rawResponse,
    }),
    failure: ({ payload, error, statusCode } = { statusCode: -1 }) => ({
      entityKey,
      type: types.FAILURE,
      requestStatus: 'failure',
      requestType,
      payload: payload || {},
      statusCode,
      error,
    }),
  };
}
