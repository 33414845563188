import { mergeDeepLeft } from 'ramda';

const reduceWithQuery = (state, queryHash, data) => {
  if (!queryHash) {
    return mergeDeepLeft(
      {
        data,
      },
      state
    );
  }
  return {
    ...state,
    searchHistory: mergeDeepLeft(
      {
        [queryHash]: data,
      },
      state.searchHistory
    ),
  };
};

export default reduceWithQuery;
