//
import {
  createS3FileUploader,
  factoryEntityApi,
  requestSagaCreator,
} from '../base';
import callApi from '../callApi';
import * as schemas from '../schemas';
import { entities } from '../actionTypes';

const audio = {
  confirm: requestSagaCreator(
    entities.tracks.actions.audio.confirm,
    ({ id }, impersonate) => {
      return callApi({
        impersonate,
        endpoint: `tracks/${id}/audio`,
        options: { method: 'POST' },
      });
    }
  ),
  delete: requestSagaCreator(
    entities.tracks.actions.audio.delete,
    ({ id }, impersonate) => {
      return callApi({
        impersonate,
        endpoint: `tracks/${id}/audio`,
        options: { method: 'DELETE' },
      });
    }
  ),
  upload: requestSagaCreator(
    entities.tracks.actions.audio.upload,
    ({ id, data }, impersonate) => {
      return callApi({
        impersonate,
        camelize: false,
        endpoint: `tracks/${id}/audio/s3`,
        options: { method: 'POST', data },
      });
    }
  ),
};

const uploadAndConfirm = createS3FileUploader({
  uploadApiSaga: audio.upload,
  confirmApiSaga: audio.confirm,
  actions: entities.tracks.actions.audio,
  types: entities.tracks.types.audio,
});

export default {
  ...factoryEntityApi({
    endpoint: 'tracks',
    entityKey: 'tracks',
    schema: schemas.track,
    actions: entities.tracks.actions,
    normalizers: {
      fetch: schemas.normalizeWithSales,
    },
    bySchemas: {
      artists: schemas.artist,
      releases: schemas.release,
    },
  }),
  audio: {
    ...audio,
    uploadAndConfirm,
  },
};
