//
import { take, takeLatest, fork } from 'redux-saga/effects';
import { refreshAuthRequest } from 'imddata/base';
import qs from 'qs';
import { LOAD_LOGGED_USER, actionTypes } from 'imddata/actionTypes/users';
import { api } from 'imddata';

const signupReferrerTrackOptions = {
  integrations: {
    All: true,
    'Google Analytics': false,
  },
};

// load user unless it is cached
function* loadLoggedUser(action) {
  const { payload } = action;

  const signup = payload?.signup;

  yield fork(api.users.getCurrentProfile);

  try {
    if (signup) {
      const { response } = yield take(actionTypes.loggedUser.fetch.SUCCESS);
      const user = response.entities.users[response.result.userId];

      if (window && window.analytics) {
        // TODO: remove extra SPS
        if (user?.sps?.referrer) {
          window.analytics.page(
            'Track Signup Referrer',
            null,
            {
              referrer: user?.sps?.referrer,
            },
            signupReferrerTrackOptions
          );
        } else {
          const sps = user?.sps?.initialParams;
          if (sps?.length && document && document.location.href) {
            const data = sps.reduce(
              (acc, { key, value }) => {
                if (key === 'referrer_hostname') return acc;
                if (key === 'referrer') {
                  return {
                    ...acc,
                    referrer: value,
                  };
                }
                return {
                  ...acc,
                  params: {
                    ...acc.params,
                    [key]: value,
                  },
                };
              },
              {
                referrer: '',
                params: {},
              }
            );

            const currentUrl = new URL(document.location.href);
            const search = qs.stringify(data.params);

            window.analytics.page(
              'Track Signup Referrer',
              null,
              {
                referrer: data.referrer,
                hostname: currentUrl.hostname,
                path: currentUrl.pathname,
                search: `?${search}`,
                url: `${currentUrl.hostname + currentUrl.pathname}?${search}`,
              },
              signupReferrerTrackOptions
            );
          }
        }
        window.analytics.track('FT Signed up', {
          signupWith: signup,
        });
      }
    }
  } catch (err) {
    window.analytics.track('FT Signup Tracking Failed', {});
  }
}

function* initAuth() {
  yield takeLatest(LOAD_LOGGED_USER, loadLoggedUser);
  yield fork(refreshAuthRequest);
}

export default function* runAuth() {
  yield fork(initAuth);
}
