import type { EntityModels } from '../../../../types';
import type { NestDefinition } from '../../base';
import type { ArtistNested } from './artists';
import { defaultArtistNest } from './artists';
import type { ContributorNested } from './contributors';
import { defaultContributorNest } from './contributors';

type Artist = EntityModels.Nest<
  EntityModels.TrackArtist,
  { artist: ArtistNested }
>;

type Contributor = EntityModels.Nest<
  EntityModels.TrackContributor,
  { contributor: ContributorNested }
>;
type Publisher = EntityModels.Nest<
  EntityModels.TrackPublisher,
  { publisher: EntityModels.Publisher }
>;

type Audio = EntityModels.Nest<
  EntityModels.TrackAudio,
  { file: EntityModels.File }
>;

type Release = EntityModels.Nest<
  EntityModels.TrackRelease,
  { release: EntityModels.Release }
>;

export type TrackNested = EntityModels.Nest<
  EntityModels.Track,
  {
    contributors: Contributor[];
    nonPerformingContributors: Contributor[];
    performers: Contributor[];
    publishers: Publisher[];
    artists: Artist[];
    file: EntityModels.File;
    namesNormalized: EntityModels.TrackName[];
    defaultNameNormalized: EntityModels.TrackName;
    audios: Audio[];
    releases: Release[];
  }
>;

export const defaultTrackNest: NestDefinition = [
  {
    key: 'namesNormalized',
    entity: 'trackNames',
    nest: [
      {
        key: 'revisions',
        entity: 'trackNames',
      },
    ],
  },
  {
    key: 'defaultNameNormalized',
    entity: 'trackNames',
  },
  {
    key: 'namesNormalized',
    entity: 'trackNames',
  },
  {
    collect: true,
    key: 'file',
    getKey: (entry?: EntityModels.Track) => entry?.fileId,
    entity: 'files',
  },
  {
    key: 'audios',
    entity: 'trackAudios',
    nest: [
      {
        collect: true,
        key: 'file',
        getKey: (entry?: EntityModels.TrackAudio) => entry?.fileId,
        entity: 'files',
      },
    ],
  },
  {
    key: 'releases',
    entity: 'trackReleases',
    nest: [
      {
        key: 'release',
        getKey: (entry?: EntityModels.TrackRelease) => entry?.releaseId,
        entity: 'releases',
      },
    ],
  },
  {
    key: 'artists',
    entity: 'trackArtists',
    nest: [
      {
        collect: true,
        key: 'artist',
        entity: 'artists',
        getKey: (entry?: EntityModels.TrackArtist) => entry?.artistId,
        nest: defaultArtistNest,
      },
    ],
  },
  {
    key: 'nonPerformingContributors',
    entity: 'trackContributors',
    nest: [
      {
        collect: true,
        getKey: (entry?: EntityModels.TrackContributor) => entry?.contributorId,
        key: 'contributor',
        entity: 'contributors',
        nest: defaultContributorNest,
      },
    ],
  },
  {
    key: 'performers',
    entity: 'trackContributors',
    nest: [
      {
        collect: true,
        getKey: (entry?: EntityModels.TrackContributor) => entry?.contributorId,
        key: 'contributor',
        entity: 'contributors',
        nest: defaultContributorNest,
      },
    ],
  },
  {
    key: 'contributors',
    entity: 'trackContributors',
    nest: [
      {
        collect: true,
        getKey: (entry?: EntityModels.TrackContributor) => entry?.contributorId,
        key: 'contributor',
        entity: 'contributors',
        nest: defaultContributorNest,
      },
    ],
  },
  {
    key: 'publishers',
    entity: 'trackPublishers',
    nest: [
      {
        collect: true,
        getKey: (entry?: EntityModels.TrackPublisher) => entry?.publisherId,
        key: 'publisher',
        entity: 'publishers',
      },
    ],
  },
];
