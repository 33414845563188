import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { AlertBox, Button } from 'imdui';

type Props = {
  wasConfirmed: boolean;
  wasUsed: boolean;
  isConfirmationRequested: boolean;
  confirmationSent: boolean;
  updating?: boolean;
  updated?: boolean;
  setConfirmationRequested: (_: boolean) => void;
  updateEntry: (_: any) => void;
};

const StyledButton = styled(Button)`
  margin-top: 8px;
`;

const ResendConfirmation: React.FC<Props> = ({
  wasConfirmed,
  wasUsed,
  isConfirmationRequested,
  updated,
  confirmationSent,
  updating,
  setConfirmationRequested,
  updateEntry,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {!wasConfirmed && !wasUsed && (
        <AlertBox text={t('payout-address-awaiting-email-confirmation')} />
      )}

      {!wasConfirmed && (
        <StyledButton
          testId="ResendConfirmationButton"
          text={
            isConfirmationRequested && updated && confirmationSent
              ? t('payout-address-confirmation-sent')
              : t('resend-confirmation-email')
          }
          disabled={updating || isConfirmationRequested}
          onClick={() => {
            setConfirmationRequested(true);
            updateEntry({
              data: { confirmationSent: false },
            });
          }}
        />
      )}
    </div>
  );
};

export default ResendConfirmation;
