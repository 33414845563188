import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Card,
  H2,
  TextFormatted,
  Button,
  ActionGrid,
  Content,
  LoadingIndicator,
  Caption,
} from 'imdui';
import {
  ConfirmWithValueWindow,
  DeliveryForm,
  RequestChangesWithHelpForm,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import type { EntityModels } from 'imddata';
import {
  useUpdateEntity,
  useBundle,
  useCreateEntity,
  useEntryProvider,
} from 'imddata';
import moment from 'moment';

const Wrapper = styled.div`
  display: grid;
  gap: 24px;
  ${H2} {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 16px;
  }
`;

const canAllowTakeDown = <
  T extends { status: EntityModels.DeliveryBundle['status'] },
>(
  b?: T,
  r?: EntityModels.Release
) =>
  b &&
  r &&
  (b.status === 'ready' || b.status === 'accepted') &&
  r.deliveryStatus !== 'taken_down';

export default function EditMusicDistribution() {
  const { t } = useTranslation();
  const { id: releaseId } = useParams<{ id: string }>();
  const release = useEntryProvider<EntityModels.Release>({
    entity: 'releases',
    id: releaseId,
  });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { entry: bundle } = useBundle({
    passive: !release?.currentDeliveryBundleId,
    // @ts-ignore
    id: release?.currentDeliveryBundleId,
  });
  const { createEntry: createReleaseAction, request: releaseActionRequest } =
    useCreateEntity({
      entity: 'releaseActions',
    });
  const { updateEntry: updateBundle, request: bundleUpdateRequest } =
    useUpdateEntity({
      entity: 'deliveryBundles',
      // @ts-ignore
      id: release?.currentDeliveryBundleId,
    });
  // useEffect(() => {
  //   if (releaseActionRequest.created || bundle?.status === 'canceled') {
  //     history.push(`/library/releases/${releaseId}`);
  //   }
  // }, [releaseActionRequest.created, bundle?.status]);

  if (!bundle || !release || bundle.countries === undefined) {
    return (
      <Wrapper>
        <LoadingIndicator size="large" />
      </Wrapper>
    );
  }
  const takeDownContext =
    release?.deliveryStatus === 'delivered' ? 'delivered' : 'not-delivered';
  return (
    <Wrapper>
      <Card style={{ padding: '24px' }}>
        <ActionGrid>
          <RequestChangesWithHelpForm
            releaseId={releaseId}
            barcode={bundle.barcode}
          />
          {canAllowTakeDown(bundle, release) && (
            <>
              <div>
                <Content>
                  {t('music-distribution-takedown-title', {
                    context: takeDownContext,
                  })}
                </Content>
                <Caption>
                  {t('music-distribution-takedown-short-desc', {
                    context: takeDownContext,
                  })}
                </Caption>
              </div>
              <div>
                <Button
                  size="small"
                  onClick={() => {
                    setIsConfirmationOpen(true);
                  }}
                  text={t('take-down', { context: takeDownContext })}
                />
              </div>
            </>
          )}
        </ActionGrid>
      </Card>

      <Card>
        <DeliveryForm
          releaseId={releaseId}
          disabled={true}
          bundleId={bundle.id}
        />
      </Card>
      <ConfirmWithValueWindow
        confirmText={
          releaseActionRequest.created || bundle.status == 'canceled'
            ? t('requested')
            : t('confirm')
        }
        confirmPlaceholder={t('confirm-with-release-title')}
        confirmValue={release.title}
        title={t('music-distribution-takedown-title')}
        loading={
          !!releaseActionRequest.creating || !!bundleUpdateRequest.updating
        }
        loaded={releaseActionRequest.created || bundle.status == 'canceled'}
        isOpen={isConfirmationOpen}
        close={() => {
          setIsConfirmationOpen(false);
        }}
        onConfirm={() => {
          if (bundle.status === 'accepted') {
            createReleaseAction({
              data: {
                action: 'takedown/request',
                releaseIds: [releaseId],
              },
            });
          } else if (bundle.status === 'ready') {
            updateBundle({
              data: {
                status: 5,
              },
            });
          }
        }}
      >
        <TextFormatted
          style={{ marginBottom: '24px' }}
          text={t('music-distribution-takedown-description', {
            context: moment(bundle.createdAt).isBefore('2023-09-29')
              ? 'legacy'
              : takeDownContext,
          })}
        />
      </ConfirmWithValueWindow>
    </Wrapper>
  );
}
