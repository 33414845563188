import { Button, Icons } from 'imdui';
import React, {
  memo,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { LayoutContext } from '../../../ScreenLayout/contexts';

const ClearButton = styled(Button)`
  width: 100%;
  box-shadow: 0 2px 20px -4px rgba(0, 0, 0, 0.15);
`;

const Wrapper = styled.div<{
  show: boolean;
  bottom: number;
}>`
  display: grid;
  gap: 8px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  padding-bottom: ${({ bottom }) => `${bottom}px`};
  transform: translateX(${({ show }) => (show ? 0 : 100)}%);

  z-index: 1;
  position: fixed;
  min-width: 100px;
  right: 20px;
  bottom: 24px;
  transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
`;

type SideWindowClosingFunc = (e?: any) => void;

type ChildrenRenderProps = {
  isOpen: boolean;
  close: SideWindowClosingFunc;
};

type Props = {
  label: string;
  isSelectionActive: boolean;
  onClearSelection?: SideWindowClosingFunc;
  children: (props: ChildrenRenderProps) => React.ReactNode;
  isAdaptive?: boolean;
  hideOnLarge?: boolean;
  onOpen?: (isOpen: boolean) => void;
};

const AdaptiveSelectSideWindow = ({
  label,
  hideOnLarge = true,
  isSelectionActive,
  onClearSelection,
  children,
  isAdaptive = true,
  onOpen,
}: Props) => {
  const { t } = useTranslation();

  const {
    breakpointsFit: { large },
    safeConstraints: { bottom },
  } = useContext(LayoutContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isSelectionActive) {
      if (onClearSelection) {
        onClearSelection();
      }
      setIsOpen(false);
    }
  }, [isSelectionActive]);

  const openSideWindow = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const closeSideWindow = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    // if (large) {
    setIsOpen(isSelectionActive);
    return;
    // }
    //
    // setIsOpen(false);
  }, [isSelectionActive]);

  const childrenProps = useMemo(
    () => ({
      isOpen,
      close:
        onClearSelection && (!isAdaptive || large)
          ? onClearSelection
          : closeSideWindow, // TODO: Probably  best to rename to discard
    }),
    [
      isSelectionActive,
      large,
      isAdaptive,
      isOpen,
      closeSideWindow,
      onClearSelection,
    ]
  );
  useEffect(() => {
    if (onOpen) {
      onOpen(isOpen);
    }
  }, [isOpen]);

  return (
    <>
      <Wrapper
        bottom={bottom}
        show={!hideOnLarge || (isSelectionActive && !large)}
      >
        <ClearButton
          icon={Icons.actions.edit}
          text={label}
          primary={true}
          onClick={openSideWindow}
        />

        {onClearSelection && (
          <ClearButton
            size="small"
            text={t('clear-selection')}
            icon={Icons.actions.clearList}
            onClick={onClearSelection}
          />
        )}
      </Wrapper>

      {children(childrenProps)}
    </>
  );
};

export default memo(AdaptiveSelectSideWindow);
