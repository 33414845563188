import { ReturnNavigation } from 'components/atoms';
import {
  Body,
  ComponentIcons,
  Button,
  IconButton,
  HelpWindowContext,
  Centered,
  LoadingIndicator,
  FieldCardAction,
  H4,
  OverlineText,
} from 'imdui';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ArtistPageTourForm } from './ArtistPageTourForm';
import { ArtistPageAboutForm } from './ArtistPageAboutForm';
import { ArtistPagePressForm } from './ArtistPagePressForm';
import { ArtistPageAppearanceForm } from './ArtistPageAppearanceForm';
import { ArtistPageReleasesForm } from './ArtistPageReleasesForm';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import type { FormProps, FormValues, TabId } from './types';
import {
  ExceededPublishedLimitDialog,
  ArtistWysiwygOverlay,
} from './components';
import { useArtistPageForm } from './useAritstPageForm';
import { usePublishArtistPage } from './usePublishArtistPage';
import { TemplateLanguageField } from '../../ReleasePageEditor/components/TemplateSideEditor/GeneralForm';
import { ReleasePageEditor } from '../../ReleasePageEditor';
import { accentThemes, BodyS, ContentM } from '@imus/base-ui';
import { useRouteMatch } from 'react-router';
import { BrandIndicatorContext, BrandIndicator } from '@imus/services-ui';
import { useCustomerFeature } from 'imddata';
import moment from 'moment';
import { ShareWindow } from '@imus/artist-page-template';
import { CustomLinkWindowForm } from './CustomLinkWindowForm';

const GlobalSettingsForm = () => {
  const { t } = useTranslation();
  const showHelpWindow = useContext(HelpWindowContext);
  return (
    <>
      <div style={{ height: 1, background: 'var(--outline-var)' }} />

      <Field
        name="locale"
        label={t('language')}
        onClickHelp={() => {
          showHelpWindow(t('page-language'), t('page-helptext-language'));
        }}
        component={TemplateLanguageField}
      />
    </>
  );
};

const ArtistPageForm = reduxForm<FormValues, FormProps>({})(({
  tab,
  artistPageId,
  form,
  id,
}) => {
  switch (tab) {
    case 'tour':
      return <ArtistPageTourForm artistPageId={artistPageId} />;
    case 'about':
      return <ArtistPageAboutForm id={id} />;
    case 'press':
      return <ArtistPagePressForm id={id} />;
    case 'releases':
      return <ArtistPageReleasesForm id={id} />;
    case 'appearance':
      return <ArtistPageAppearanceForm form={form} id={id} />;
    default: {
      return <GlobalSettingsForm />;
    }
  }
});

const Wrapper = styled.div`
  padding: 32px;
  display: grid;
  gap: 32px;
  ${accentThemes.secondary}
  ${ReturnNavigation} {
    margin: 0;
  }
`;

const useArtistPageOpenedEvent = (artistId: number) => {
  const matchOrder = useRouteMatch<{ product: string; productId: string }>(
    '/order/:product/:productId'
  );
  const matchLibrary = useRouteMatch<{ place: string }>('/library/:place');
  useEffect(() => {
    if (window.analytics && (matchLibrary || matchOrder)) {
      window.analytics.track('FT Artist Page Editor Opened', {
        artistId,
        section: matchOrder ? 'order' : `library/${matchLibrary?.params.place}`,
        product: matchOrder ? matchOrder.params.product : undefined,
        productId: matchOrder ? matchOrder.params.productId : undefined,
      });
    }
    return () => {
      if (window.analytics && (matchLibrary || matchOrder)) {
        window.analytics.track('FT Artist Page Editor Closed', {
          artistId,
          section: matchOrder
            ? 'order'
            : `library/${matchLibrary?.params.place}`,
          product: matchOrder ? matchOrder.params.product : undefined,
          productId: matchOrder ? matchOrder.params.productId : undefined,
        });
      }
    };
  }, []);
};

export function ArtistPageEditor({ artistId }: { artistId: number }) {
  useArtistPageOpenedEvent(artistId);
  const unbranded = useCustomerFeature('artist-hub-pro');
  const { t } = useTranslation();
  const [selectedTab, setTab] = useState<TabId | null>(null);
  const [showLimitReachedForm, setShowLimitReachedForm] = useState(false);

  const activeArtistId = useRef(artistId);
  const brandIndicatorText = !unbranded
    ? t('subscribe-to-publish-these-changes')
    : false;
  useEffect(() => {
    if (activeArtistId.current !== artistId) {
      setTab(null);
      activeArtistId.current = artistId;
    }
  }, [artistId]);

  const showHelp = useContext(HelpWindowContext);

  const handleLayoutNavigation = useCallback((e, tab) => {
    e.preventDefault();
    e.stopPropagation();
    setTab(tab);
  }, []);

  const form = `ArtistPageEditor-${artistId}`;
  const { change, initialValues, artistPageId, artistPage } =
    useArtistPageForm(artistId);

  const {
    publish,
    published,

    limit,
    loading,
    exceededLimit,
    artistPagesToResolve,
  } = usePublishArtistPage(artistId, artistPageId);

  useEffect(() => {
    if (published) {
      setShowLimitReachedForm(false);
    }
  }, [published]);

  const [selectedReleasePageId, setSelectedReleasePageId] = useState<
    string | null
  >(null);

  const [generating, setGenerating] = useState(false);
  const timeout = useRef<number>();
  useEffect(() => {
    if (!artistPage) return;
    if (artistPage.status === 'generating') {
      setGenerating(true);
      return;
    }

    if (Date.now() - new Date(artistPage.updatedAt).valueOf() > 1000 * 60) {
      setGenerating(false);
      return;
    }
    if (artistPage.status === 'generated') {
      setGenerating(true);
      timeout.current = window.setTimeout(() => {
        setGenerating(false);
      }, 1000 * 60);
    }
  }, [artistPage?.status, artistPage?.updatedAt]);

  const handleChange = useCallback<typeof change>(
    (values, dispatch, meta, prevValues) => {
      if (prevValues.name) {
        setGenerating(true);
        window.clearTimeout(timeout.current);
      }
      change(values, dispatch, meta, prevValues);
    },
    [change]
  );

  const [openShare, setOpenShare] = useState(false);
  const [openCustomUrlWindow, setOpenCustomUrlWindow] = useState(false);

  return (
    <BrandIndicatorContext.Provider value={brandIndicatorText}>
      <ShareWindow
        isOpen={openShare}
        close={() => setOpenShare(false)}
        url={artistPage?.url}
      />
      {artistPage?.url && (
        <CustomLinkWindowForm
          id={artistPageId}
          pageUrl={artistPage?.url}
          isOpen={openCustomUrlWindow}
          close={() => setOpenCustomUrlWindow(false)}
        />
      )}
      <Wrapper>
        {selectedTab === null ? (
          <>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <OverlineText
                size="large"
                style={{ marginBottom: '0px' }}
                label={t('publish')}
                onClickHelp={() => {
                  showHelp(t('publish'), t('artist-page-publish-helptext'));
                }}
              ></OverlineText>
              <FieldCardAction
                primary
                action={
                  <div style={{ display: 'flex', gap: '4px' }}>
                    {published && (
                      <Button
                        primary={published}
                        appearance={published ? 'fill' : 'stroke'}
                        size="small"
                        text={t('share')}
                        showLoading={published && !artistPage?.wasGenerated}
                        disabled={
                          !artistPage ||
                          !published ||
                          (published && !artistPage?.wasGenerated)
                        }
                        onClick={() => {
                          setOpenShare(true);
                        }}
                      />
                    )}

                    <Button
                      size="small"
                      primary={!published}
                      appearance={!published ? 'fill' : 'stroke'}
                      text={published ? t('unpublish') : t('publish')}
                      onClick={() => {
                        if (!published && exceededLimit > 0) {
                          setShowLimitReachedForm(true);
                        } else if (publish) {
                          setGenerating(true);
                          publish();
                        }
                      }}
                    />
                  </div>
                }
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}
                >
                  <ContentM>
                    {published
                      ? t('page-is-published')
                      : t('publish-not-published')}
                  </ContentM>
                  <BodyS secondary>
                    {t('last-updated-at', {
                      defaultValue: 'Last updated {{time}}',
                      time: moment(artistPage?.updatedAt).fromNow(),
                    })}
                  </BodyS>
                </div>
              </FieldCardAction>
            </div>

            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <H4>{t('customization')}</H4>
              <div>
                <FieldCardAction
                  icon={brandIndicatorText && <BrandIndicator />}
                  action={
                    <ComponentIcons.ChevronRight
                      style={{ marginLeft: 'auto' }}
                    />
                  }
                  onClick={() => setTab('appearance')}
                >
                  <Body>{t('appearance')}</Body>
                </FieldCardAction>
              </div>

              <div>
                <FieldCardAction
                  icon={brandIndicatorText && <BrandIndicator />}
                  action={
                    <Button
                      text={t('set-up')}
                      appearance="stroke"
                      onClick={() => setOpenCustomUrlWindow(true)}
                      size="small"
                    />
                  }
                >
                  <Body>{t('named-url')}</Body>
                </FieldCardAction>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <H4>{t('content')}</H4>
              {(['about', 'releases', 'tour', 'press'] as const).map((tab) => (
                <div key={tab}>
                  <FieldCardAction
                    icon={
                      brandIndicatorText &&
                        (tab === 'tour' || tab === 'press') ? (
                        <BrandIndicator />
                      ) : null
                    }
                    onClick={() => setTab(tab)}
                    action={
                      <ComponentIcons.ChevronRight
                        style={{ marginLeft: 'auto' }}
                      />
                    }
                  >
                    <Body>{t(tab)}</Body>
                  </FieldCardAction>
                </div>
              ))}
            </div>
          </>
        ) : (
          <ReturnNavigation onClick={() => setTab(null)} text={t(selectedTab)}>
            <IconButton
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                showHelp(
                  t(`${selectedTab}`),
                  t(`artist-page-${selectedTab}-helptext`)
                );
              }}
            >
              <ComponentIcons.HelpIcon />
            </IconButton>
          </ReturnNavigation>
        )}
        {initialValues && artistPage ? (
          // Always render form once values are set
          <ArtistPageForm
            onChange={handleChange}
            id={artistId}
            artistPageId={artistPage.id}
            tab={selectedTab}
            form={form}
            initialValues={initialValues}
          />
        ) : (
          // Render form loading if values are not ready
          <Centered>
            <LoadingIndicator />
          </Centered>
        )}
      </Wrapper>
      <ArtistWysiwygOverlay
        status={
          artistPage
            ? artistPage?.wasGenerated
              ? generating && published
                ? 'generating'
                : 'generated'
              : 'not-generated'
            : null
        }
        onReleaseClick={(_rid, { releasePageId }) => {
          setSelectedReleasePageId(releasePageId);
        }}
        form={form}
        onNavigation={handleLayoutNavigation}
        page={selectedTab === 'appearance' ? 'about' : selectedTab}
      />
      {selectedReleasePageId && (
        <ReleasePageEditor
          id={selectedReleasePageId}
          linksOnly={true}
          onClose={() => {
            setSelectedReleasePageId(null);
          }}
        />
      )}

      <ExceededPublishedLimitDialog
        limit={limit}
        exceededLimit={exceededLimit}
        publish={publish}
        loading={loading}
        artistPagesToResolve={artistPagesToResolve}
        isOpen={showLimitReachedForm}
        onClose={() => {
          setShowLimitReachedForm(false);
        }}
      />
    </BrandIndicatorContext.Provider>
  );
}
