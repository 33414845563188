import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, Centered, LoadingIndicator, Body, theme } from 'imdui';

type LoaderProps = {
  key?: string;
  style?: any;
};

export const EmptyLoader: React.FC<LoaderProps> = () => (
  <Block padding={[20, 0, 20, 0]}>
    <Centered>
      <LoadingIndicator size="large" />
    </Centered>
  </Block>
);

export const Loader: React.FC<LoaderProps> = ({ key, style = {} }) => {
  const { t } = useTranslation();

  return (
    <div key={key} style={style}>
      <Centered>
        <Block direction="row">
          <LoadingIndicator size="small" />

          <Body style={{ color: theme.foreground.secondary, marginLeft: 20 }}>
            {t('more-items-loading')}
          </Body>
        </Block>
      </Centered>
    </div>
  );
};
