import { getSocialHandlerPrefix } from '@imus/artist-page-template';
import type { SocialProviders } from '@imus/artist-page-template';
import type { ReduxState } from 'imddata';
import { useSubscriptionUpsell, SelectField, InputField } from 'imdshared';
import {
  HelpWindowContext,
  SubscriptionFeatureGateButton,
  Button,
} from 'imdui';
import { useMemo, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { FormSubmitHandler } from 'redux-form';
import {
  reduxForm,
  formValueSelector,
  Field,
  FormSection,
  FieldArray,
} from 'redux-form';
import { isURL } from 'validator';
import { canPlayUrl, validateRequired, validateURL } from '../utils';
import { ArtistImagesField } from './ArtistImagesField';
import { TrackSelectField } from './TrackSelectField';
import { useCustomerArtistPageLimits } from './useCustomerAritstPageLimits';
import { GalleryIdField } from './GalleryIdField';
import type { FormValues } from '../types';
import { TrackCardLimitReached } from './TrackCardLimitReachedContext';
import { VariantPicker } from './VariantPicker';
import GalleryIcon from './images/gallery-icon.svg';
import TrackIcon from './images/track-icon.svg';
import VideoEmbedIcon from './images/video-embed-icon.svg';

type Media = FormValues['media'][number];
type MediaLink = FormValues['mediaLinks'][number];
type MediaType = Media['type'];
type MediaLinkType = MediaLink['type'];
type MediaTypeOption = {
  value: MediaType;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
};
type MediaLinkTypeOption = {
  value: MediaLinkType;
  label: string;
  disabled?: boolean;
};

type SocialTypeOption = { value: SocialProviders; label: string };

const validateVideoURL = (value?: string) => {
  const err = validateRequired(value) || validateURL(value);
  if (err) return err;
  return value && canPlayUrl(value) ? '' : 'not-supported-video-url';
};
const galleryAccept = {
  'image/jpeg': [],
  'image/png': [],
};

export const useMediaLinkTypes = () => {
  const { t } = useTranslation();
  return useMemo<MediaLinkTypeOption[]>(
    () =>
      (
        [
          { value: 'social', label: t('social-media') },
          { value: 'custom-link', label: t('custom-link') },
        ] as const
      ).map((v) => ({
        ...v,
        // disabled:
        //   (artistPageLimits?.about?.allowedMediaTypes?.indexOf(v.value) || 1) <
        //   0,
      })),
    [t]
  );
};
export const useMediaTypes = () => {
  // const artistPageLimits = useCustomerArtistPageLimits();
  const { t } = useTranslation();
  return useMemo<MediaTypeOption[]>(
    () =>
      (
        [
          {
            icon: <GalleryIcon />,
            value: 'image-gallery',
            label: t('image-gallery'),
          },
          { icon: <TrackIcon />, value: 'track', label: t('audio-player') },
          {
            icon: <VideoEmbedIcon />,
            value: 'video-embed',
            label: t('video-player'),
          },
        ] as const
      ).map((v) => ({
        ...v,
        // disabled:
        //   (artistPageLimits?.about?.allowedMediaTypes?.indexOf(v.value) || 1) <
        //   0,
      })),
    [t]
  );
};

export const MediaForm = reduxForm<
  Media,
  { onSubmit: FormSubmitHandler<Media>; submitText: string }
>({ form: 'AddMediaForm' })(({
  handleSubmit,
  change,
  initialValues,
  submitText,
  form,
  onSubmit,
}) => {
  const trackCardLimitReached = useContext(TrackCardLimitReached);
  const artistPageLimits = useCustomerArtistPageLimits();
  const selectFormValues = useMemo(() => formValueSelector(form), [form]);
  const type: MediaTypeOption['value'] = useSelector((state: ReduxState) =>
    selectFormValues(state, 'type')
  );

  const { t } = useTranslation();
  const mediaTypeData = useMediaTypes();

  const { open: openUpsell } = useSubscriptionUpsell();
  const showHelp = useContext(HelpWindowContext);
  const submitHandler = useCallback<FormSubmitHandler<Media>>(
    (v: Media, ...rest) => {
      if (window.analytics) {
        window.analytics.track('FT Artist Page Media Card Added', {
          type: v.type,
          ...(v.type === 'video-embed' || v.type === 'track'
            ? v.data
            : v.type === 'image-gallery'
              ? {
                imageCount: v.data.gallery?.length,
                galleryId: v.data.artistGalleryId,
              }
              : {}),
        });
      }
      onSubmit(v, ...rest);
    },
    [onSubmit]
  );
  return (
    <>
      {!!artistPageLimits.about.allowedMediaTypes?.length && (
        <SubscriptionFeatureGateButton
          style={{ width: '100%', marginBottom: '24px' }}
          action={t('upgrade')}
          label={t('unlock-more-media-types')}
          onClick={() => {
            openUpsell({
              analytics: { detail: 'artist-page' },
              section: 'promo-tools',
              feature: 'artist-hub-pre-save',
            });
          }}
        />
      )}

      <div style={{ marginBottom: '24px' }}>
        <Field
          name="type"
          disabled={!!initialValues?.type}
          label={t('media-type')}
          placeholder={t('media-type')}
          values={mediaTypeData}
          onChange={() => {
            change('data', {});
          }}
          component={VariantPicker}
        ></Field>
      </div>
      <FormSection name="data">
        {type === 'video-embed' && (
          <Field
            name="url"
            label={t('video-player-url')}
            component={InputField}
            validate={validateVideoURL}
            onClickHelp={() => {
              showHelp(t('video-player-url'), t('video-url-helptext'));
            }}
          />
        )}
        {type === 'image-gallery' && (
          <>
            <Field name="artistGalleryId" component={GalleryIdField} />
            <FieldArray
              name="gallery"
              accept={galleryAccept}
              rerenderOnEveryChange={true}
              component={ArtistImagesField}
            />
          </>
        )}
        {type === 'track' && (
          <Field
            name="trackId"
            label={t('track')}
            disabled={trackCardLimitReached && !initialValues?.type}
            validate={validateRequired}
            component={TrackSelectField}
          />
        )}
      </FormSection>
      <div style={{ marginTop: '24px' }}>
        <Button
          position="center"
          text={submitText}
          disabled={!type}
          onClick={handleSubmit(submitHandler)}
        />
      </div>
    </>
  );
});

export const MediaLinkForm = reduxForm<
  MediaLink,
  { onSubmit: FormSubmitHandler<MediaLink>; submitText: string }
>({ form: 'AddMediaForm' })(({
  handleSubmit,
  change,
  initialValues,
  submitText,
  form,
  onSubmit,
}) => {
  const artistPageLimits = useCustomerArtistPageLimits();
  const selectFormValues = useMemo(() => formValueSelector(form), [form]);
  const type: MediaLinkTypeOption['value'] = useSelector((state: ReduxState) =>
    selectFormValues(state, 'type')
  );

  const socialProvider: SocialProviders = useSelector((state: ReduxState) =>
    selectFormValues(state, 'data.name')
  );
  const { t } = useTranslation();
  const mediaTypeData = useMediaLinkTypes();

  const validateRequiredUrl = useCallback((v: string) => {
    return validateRequired(v) || validateURL(v);
  }, []);
  const { open: openUpsell } = useSubscriptionUpsell();
  const socialTypesData: SocialTypeOption[] = useMemo(
    () =>
      (
        [
          {
            value: 'twitter',
          },
          {
            value: 'youtube',
          },
          {
            value: 'bandcamp',
          },
          {
            value: 'facebook',
          },
          {
            value: 'instagram',
          },
          {
            value: 'soundcloud',
          },
        ] as const
      ).map(({ value }) => ({ value, label: t(value) })),
    []
  );
  const submitHandler = useCallback<FormSubmitHandler<MediaLink>>(
    (v: MediaLink, ...rest) => {
      if (window.analytics) {
        window.analytics.track('FT Artist Page Media Card Added', {
          type: v.type,
          ...(v.type === 'custom-link'
            ? { urlLabel: v.data.name, url: v.data.url }
            : v.type === 'social'
              ? { socialProvider: v.data.name, handler: v.data.id }
              : {}),
        });
      }
      onSubmit(v, ...rest);
    },
    [onSubmit]
  );
  return (
    <>
      {!!artistPageLimits.about.allowedMediaTypes?.length && (
        <SubscriptionFeatureGateButton
          style={{ width: '100%', marginBottom: '24px' }}
          action={t('upgrade')}
          label={t('unlock-more-media-types')}
          onClick={() => {
            openUpsell({
              analytics: { detail: 'artist-page' },
              section: 'promo-tools',
              feature: 'artist-hub-pre-save',
            });
          }}
        />
      )}
      <Field
        name="type"
        disabled={!!initialValues?.type}
        label={t('media-type')}
        placeholder={t('media-type')}
        data={mediaTypeData}
        onChange={() => {
          change('data', {});
        }}
        component={SelectField}
      ></Field>
      <FormSection name="data">
        {type === 'custom-link' && (
          <>
            <Field
              name="name"
              label={t('custom-link-name')}
              component={InputField}
              validate={validateRequired}
            />
            <Field
              name="url"
              label={t('custom-link-url')}
              component={InputField}
              validate={validateRequiredUrl}
            />
          </>
        )}
        {type === 'social' && (
          <>
            <Field
              name="name"
              label={t('social-type')}
              placeholder={t('social-type')}
              component={SelectField}
              validate={validateRequired}
              data={socialTypesData}
            />
            <Field
              name="id"
              label={t('social-id')}
              placeholder={t('social-id')}
              // TODO: remove @ from soundcloud, facebook, bandcamp
              format={(v: string) => {
                const prefix = getSocialHandlerPrefix(
                  socialProvider as SocialProviders,
                  v
                );
                if (!v) return '';
                return prefix + v;
              }}
              parse={(v: string) => {
                if (v && isURL(v)) {
                  try {
                    const url = new URL(v);
                    if (url.hostname.includes('bandcamp')) {
                      return url.hostname.split('.')[0];
                    }
                    let path = url.pathname.substring(1);
                    if (path[path.length - 1] === '/') {
                      path = path.substring(0, path.length - 1);
                    }
                    return path.replace(/[@]/g, '');
                  } catch (e) {
                    console.error(e);
                  }
                  return v;
                }
                if (!v) return '';
                return v.replace('@', '');
              }}
              component={InputField}
              validate={validateRequired}
            />
          </>
        )}
      </FormSection>
      <div style={{ marginTop: '24px' }}>
        <Button
          position="center"
          text={submitText}
          disabled={!type}
          onClick={handleSubmit(submitHandler)}
        />
      </div>
    </>
  );
});
