import { useState, useLayoutEffect } from 'react';

export default () => {
  const { innerWidth: width } = window || { width: 1280 };

  const [breakpointsFit, setBreakpoints] = useState({
    xxSmall: width >= 0,
    xSmall: width >= 360,
    small: width >= 600,
    medium: width >= 840,
    large: width >= 1024,
    xLarge: width >= 1280,
    xxLarge: width >= 1440,
  });

  useLayoutEffect(() => {
    let requestedFrame = null;
    let timeout = null;

    const performCleanup = () => {
      if (requestedFrame) {
        cancelAnimationFrame(requestedFrame);
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };

    const updateBreakpoints = () => {
      const { innerWidth: currentWidth } = window;
      performCleanup();
      requestedFrame = requestAnimationFrame(() => {
        setBreakpoints({
          xxSmall: currentWidth >= 0,
          xSmall: currentWidth >= 360,
          small: currentWidth >= 600,
          medium: currentWidth >= 840,
          large: currentWidth >= 1024,
          xLarge: currentWidth >= 1280,
          xxLarge: currentWidth >= 1440,
        });
      });
    };

    const throttleDragToggleNavDrawer = () => {
      timeout = setTimeout(updateBreakpoints, 750);
    };

    window.addEventListener('resize', throttleDragToggleNavDrawer);

    return () => {
      performCleanup();
      window.removeEventListener('resize', throttleDragToggleNavDrawer);
    };
  });

  return breakpointsFit;
};
