import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CenterWrapper, Headline } from 'imdui';

export const TopWrapper = styled(CenterWrapper)`
  z-index: 1;
  padding-top: 40px;

  > div > ${Headline} {
    margin-bottom: 24px;
  }
`;

export const descriptionCloudStyle = css`
  margin-bottom: 24px;
`;

export const childrenWrapperStyle = css`
  padding-bottom: 24px;
  z-index: 0;
`;
