import styled from '@emotion/styled';
import {
  getBaseSubscriptionId,
  getSubscriptionCadence,
  useSubscriptionManagement,
} from 'components/logic';
import {
  ConfirmInstantUpgradeWindow,
  useFeatureList,
} from 'components/subscriptions';
import type { TFunction } from 'i18next';
import type { EntityModels, Nil } from 'imddata';
import { nil, useCurrentCustomer, useEntries } from 'imddata';
import { Card, Content, useSubscriptionUpsell } from 'imdshared';
import {
  Caption as BodySmall,
  Button,
  ComponentIcons,
  FieldCardAction,
  FieldCardSection,
  H4,
  LinkButton,
  OverlineText,
  TextFormatted,
  Window,
} from 'imdui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import {
  secondaryTheme,
  ChevronRightIcon,
  AmpStrokeIcon,
  accentThemes,
} from '@imus/base-ui';
import { ConfirmEmailButton, SubscriptionCard } from 'components';
import type { SubscriptionId } from 'imddata/types/entities';
import { useHistory } from 'react-router-dom';
import { Settings } from './Settings';

const AmpsRow = styled.div`
  ${accentThemes.tertiary}
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  ${H4} {
    display: inline-flex;
    align-items: center;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const AmpsSection = ({ value }: { value?: number }) => {
  return (
    <div>
      <AmpsRow>
        <H4>Amps</H4>
        <H4>
          <LinkButton
            to="/account?openAmps=true"
            primary={true}
            text={
              <>
                <AmpStrokeIcon /> {value || 0}
              </>
            }
          />
        </H4>
      </AmpsRow>
    </div>
  );
};

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Grid = styled.div`
  ${secondaryTheme}
  display: grid;
  gap: 32px;
  @media screen and (min-width: 960px) {
    grid-template-columns: minmax(240px, 360px) minmax(400px, 1fr);
  }
`;

const SubsOverviewCard = styled(Card)`
  display: grid;
  padding: 24px;
  gap: 24px;
`;

const ConfirmEmailButtonStyled = styled(ConfirmEmailButton)``;

const formatPaymentMethod = (
  paymentMethod: EntityModels.CustomerPaymentMethod,
  t: TFunction
): string => {
  if (paymentMethod.paymentMethodId === 'card') {
    return `${t('credit-card')} (•••• ${paymentMethod.data.lastFour})`;
  }

  if (paymentMethod.paymentMethodId === 'paypal') {
    return `${t('paypal')} (${paymentMethod.description})`;
  }
  return 'notfound';
};

const shouldShowUpgradeButton = (sid?: SubscriptionId) => {
  if (!sid) return true;
  switch (sid) {
    case 'tier-1':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'tier-3':
      return false;
    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return true;
  }
};

const isLegacySub = (sid: SubscriptionId) => {
  switch (sid) {
    case 'tier-1':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'tier-3':
    case 'trial-tier-2':
      return false;

    case 'trial-monthly':
    case 'trial-yearly':
    case 'music-analytics':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return true;
  }
};

type DowngradeMode =
  | null
  | 'downgrade'
  | 'trial-end'
  | 'renewal-cancellation'
  | 'legacy-deprecation';

const getDowngradeMode = (
  currentSid: SubscriptionId | 'free',
  renewToId?: SubscriptionId | null,
  renewalEnabled?: boolean
): DowngradeMode => {
  if (currentSid === 'free') return null;

  if (!renewalEnabled) {
    return 'renewal-cancellation';
  }
  if (!renewToId) return null;

  if (currentSid.startsWith('trial')) return 'trial-end';

  if (renewToId.startsWith('trial')) return null;

  if (isLegacySub(currentSid) && !isLegacySub(renewToId)) {
    return 'legacy-deprecation';
  }
  if (
    isLegacySub(currentSid) &&
    isLegacySub(renewToId) &&
    getBaseSubscriptionId(currentSid) === getBaseSubscriptionId(renewToId)
  )
    return null;

  return 'downgrade';
};

export default function Profile() {
  const { open: openUpsell } = useSubscriptionUpsell();
  const { t } = useTranslation();
  const { entry: customer } = useCurrentCustomer();
  const history = useHistory();

  const {
    entries: paymentMethods,
    refresh: refreshPaymentMethods,
    request: { loading: loadingPayments },
  } = useEntries<EntityModels.CustomerPaymentMethod>({
    entity: 'customerPaymentMethods',
  });
  useEffect(() => {
    refreshPaymentMethods();
  }, []);

  const features = useFeatureList();
  const [openSettings, setOpenSettings] = useState(false);
  const [selectedCadenceSub, setSelectedCadenceSub] = useState<
    | {
        mode: 'renew_to_different';
        subscriptionId: EntityModels.SubscriptionId;
      }
    | {
        subscriptionId: Nil;
        mode: 'clear_renew_to';
      }
  >();
  const {
    currentSubscription,
    prices,
    grouppedSubscriptions: subscriptions,
    ordering,
    renewalEnabled,
    toggleRenewal,
    selectSubscription,
  } = useSubscriptionManagement();

  const {
    subscriptionId: sid,
    renewAt: periodEnd,
    renewToId,
  } = currentSubscription || {
    renewToId: null,
    renewAt: null,
    subscriptionId: 'free',
  };

  useEffect(() => {
    setSelectedCadenceSub(undefined);
  }, [sid]);

  const id = getBaseSubscriptionId(sid);

  // Future or current cadence
  const currentCadence =
    sid === 'free' ? null : getSubscriptionCadence(renewToId || sid);

  const downgradeMode = getDowngradeMode(sid, renewToId, renewalEnabled);

  const nextCadence = currentCadence === 'yearly' ? 'monthly' : 'yearly';

  const nextSubCadenceId = `${getBaseSubscriptionId(
    renewToId || sid
  )}-${nextCadence}`;

  const nextCadenceSub = subscriptions
    ?.find((s) => s.id === getBaseSubscriptionId(renewToId || sid))
    ?.subscriptions?.find((s) => s.subscriptionId === nextSubCadenceId);

  const nextCadenceSubPrice = nextCadenceSub
    ? prices?.[nextCadenceSub.subscriptionId]
    : undefined;

  const currentSubPrice =
    prices?.[currentSubscription ? currentSubscription.subscriptionId : 'free'];

  const defaultRecurring = paymentMethods?.find(
    (e) => e.isRecurringDefault && e.status !== 'deleted'
  );

  const creditsValue = customer?.credits?.find(
    (c) => c.currencyId === 'amp'
  )?.amount;

  const showUpgradeButton = shouldShowUpgradeButton(
    currentSubscription?.subscriptionId
  );

  if (!customer) return null;

  const cardSid = customer.subscriptionId || 'free';

  const renewalDate = periodEnd ? moment(periodEnd).format('LL') : null;
  const renewalText = renewalDate
    ? `${
        customer.renewSubscriptions
          ? t('subscription-renewal-on')
          : t('subscription-ends-on')
      } ${renewalDate}`
    : undefined;

  console.log(renewToId, sid, downgradeMode);

  return (
    <>
      <Helmet>
        <title>{t('page-title-subscription')}</title>
      </Helmet>
      <Grid
        data-test-id={id !== 'free' ? 'UserSubscribed' : 'UserNotSubscribed'}
      >
        <FirstColumn>
          <SubscriptionCard
            price={currentSubPrice?.price}
            subscriptionId={cardSid}
            features={features.short[cardSid]}
          >
            <div>
              {!showUpgradeButton ? (
                <Button
                  onClick={() => {
                    openUpsell();
                  }}
                  size="small"
                  text={t('show-more')}
                />
              ) : (
                <Button
                  primary={true}
                  size="small"
                  text={t('upgrade')}
                  onClick={() => {
                    openUpsell({ analytics: { detail: 'profile' } });
                  }}
                />
              )}
            </div>
          </SubscriptionCard>
          <SubsOverviewCard>
            <AmpsSection value={creditsValue} />
          </SubsOverviewCard>
        </FirstColumn>

        <Card>
          <FieldCardSection data-test-id="Profile">
            <ConfirmEmailButtonStyled />
            <div>
              <FieldCardAction
                onClick={() => {
                  history.push('/account/profile');
                }}
                action={<ChevronRightIcon />}
              >
                <div>
                  <Content>{t('profile-details')}</Content>
                  <br />
                  <BodySmall>
                    {customer.firstName + ' ' + customer.lastName}
                  </BodySmall>
                </div>
              </FieldCardAction>
              <FieldCardAction
                onClick={() => {
                  setOpenSettings(true);
                }}
                action={<ChevronRightIcon />}
              >
                <div>
                  <Content>{t('application-settings')}</Content>
                  <br />
                  <BodySmall>{t('application-settings-desc')}</BodySmall>
                </div>
              </FieldCardAction>
            </div>
          </FieldCardSection>
          <FieldCardSection data-test-id="CurrentSubscription">
            {downgradeMode && (
              <FieldCardAction
                primary={true}
                action={
                  // Deprecation is not actionable
                  downgradeMode !== 'legacy-deprecation' && (
                    <Button
                      position="center"
                      primary={true}
                      appearance="fill"
                      onClick={() => {
                        switch (downgradeMode) {
                          case 'trial-end':
                          case 'downgrade':
                            selectSubscription({
                              subscriptionId: nil,
                              mode: 'clear_renew_to',
                            });
                            break;
                          case 'renewal-cancellation':
                            toggleRenewal();
                        }
                      }}
                      text={t('cancel-downgrade', { context: downgradeMode })}
                    />
                  )
                }
              >
                <div>
                  <Content>
                    {t('you-will-be-downgraded-to', {
                      context: downgradeMode,
                      nextPrice: renewToId ? prices?.[renewToId]?.price : '',
                      defaultValue:
                        'You will be downgraded to {{subscription}}',
                      subscription:
                        renewalEnabled && renewToId
                          ? t(renewToId).toUpperCase()
                          : t('free', { context: 'plan' }),
                    })}
                  </Content>
                  <BodySmall>
                    <TextFormatted
                      text={t('you-will-be-downgraded-at', {
                        context: downgradeMode,
                        nextPrice: renewToId ? prices?.[renewToId]?.price : '',
                        defaultValue:
                          'You will be automatically switched to a lower tier on {{renewalDate}}',
                        renewalDate,
                      })}
                    />
                  </BodySmall>
                </div>
              </FieldCardAction>
            )}
            <div>
              <OverlineText label={t('payment-methods')} />
              <FieldCardAction
                onClick={() => {
                  history.push('/account/payment-methods');
                }}
                action={<ChevronRightIcon />}
              >
                <div>
                  <Content>
                    {loadingPayments ? (
                      <>&nbsp;</>
                    ) : !defaultRecurring ? (
                      t('no-default-payment-method')
                    ) : (
                      formatPaymentMethod(defaultRecurring, t)
                    )}
                  </Content>
                </div>
              </FieldCardAction>
            </div>

            <div>
              <OverlineText label={t('update-plan')} />
              <FieldCardAction
                action={
                  <Button
                    onClick={() => {
                      openUpsell();
                    }}
                    text={t('change-plan')}
                  />
                }
              >
                <div>
                  <Content>{t(sid, { context: 'plan' })}</Content>
                  <br />
                  <BodySmall>{renewalText || t('update-plan-desc')}</BodySmall>
                </div>
              </FieldCardAction>
            </div>
          </FieldCardSection>

          <FieldCardSection>
            <div>
              <Button
                onClick={() => {
                  history.push('/signout');
                }}
                position="center"
                text={t('sign-out')}
                iconLeft={ComponentIcons.Leave}
              />
            </div>
          </FieldCardSection>
        </Card>
      </Grid>
      <Window
        title={t('application-settings')}
        isOpen={openSettings}
        close={() => setOpenSettings(false)}
      >
        <div style={{ padding: '0 24px 24px' }}>
          <Settings />
        </div>
      </Window>

      <ConfirmInstantUpgradeWindow
        title={t('confirm-cadence-switch', { context: nextCadence })}
        onConfirm={() => {
          if (selectedCadenceSub) {
            selectSubscription(selectedCadenceSub);
            setSelectedCadenceSub(undefined);
          }
        }}
        loading={!!ordering}
        isOpen={!!selectedCadenceSub}
        close={() => {
          setSelectedCadenceSub(undefined);
        }}
        message={t('confirm-cadence-switch-message', {
          context: nextCadence,
          chargeAt: moment(periodEnd).format('LL'),
          price:
            selectedCadenceSub?.subscriptionId === nil
              ? currentSubPrice?.priceFormatted
              : nextCadenceSubPrice?.priceFormatted,
        })}
      />
    </>
  );
}
