import { useSelector } from 'react-redux';
import { useCreateEntity } from './base';

export const useResendConfirmationEmail = ({ userId } = {}) => {
  const storeId = useSelector((state) => {
    return state.auth?.profile?.userId;
  });

  const { createEntry: resendConfirmationEmail, request } = useCreateEntity({
    query: { id: userId || storeId },
    entity: 'emailConfirmation',
  });
  return {
    resendConfirmationEmail,
    request,
  };
};
