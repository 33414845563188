import React from 'react';
import { Circle } from '@visx/shape';

import { Axis, Grid } from '@visx/xychart';

const tickComponent: React.ComponentProps<typeof Axis>['tickComponent'] = ({
  x,
  y,
}) => <Circle fill="#ccc" cx={x + 0.75} cy={y - 1.5} r={2} />;

export const CircleGrid = ({
  numTicks,
  verticalTicksNum,
  top = 0,
}: {
  numTicks: number;
  verticalTicksNum?: number;
  top?: number;
}) => {
  return (
    <Grid top={top} rows={false} columns={true} numTicks={numTicks}>
      {({ lines }) =>
        lines.map(({ from }) => (
          <Axis
            key={from.x}
            orientation="left"
            numTicks={verticalTicksNum}
            tickLength={1}
            tickComponent={tickComponent}
            hideTicks={true}
            hideAxisLine={true}
            left={from.x}
          />
        ))
      }
    </Grid>
  );
};
