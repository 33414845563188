import React, { memo, useState, useEffect, useCallback } from 'react';
import type { EntityModels } from 'imddata';
import { useEntries, useQuery } from 'imddata';
import { take } from 'ramda';
import type { DropzoneOptions } from 'react-dropzone';
import styled from '@emotion/styled';
import { useInView } from 'react-intersection-observer';
import { Placeholder, TableWrapper, ClickableCover } from './styles';
// import CoverUploader from '../../../CoverUploader';

type ImagePreviewProps = {
  image: any;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>, image: any) => void;
};

const DEFAULT_IMAGES_LIST: never[] = [];

const SearchWrap = styled.div`
  overflow-y: auto;
  height: 288px;
`;
const SearchGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
`;

/**
 * Image preview item
 */
const ImagePreview: React.FC<ImagePreviewProps> = ({
  image,
  selected,
  onClick,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { width, height, urls, user } = image;

    if (!selected) {
      onClick(e, { width, height, urls, user });
    }
  };

  return (
    <ClickableCover
      selected={selected}
      image={image.urls.thumb}
      onClick={handleClick}
    >
      <span />
    </ClickableCover>
  );
};

export type UnsplashImage = {
  width: number;
  height: number;
  urls: {
    thumb: string;
    raw: string;
  };
  user: {
    links: {
      html: string;
    };
    name: string;
  };
};

/**
 * Table with image previews for artwork selector
 */
const BackgroundSelector: React.FC<{
  onDrop: DropzoneOptions['onDrop'];
  selectedImage: UnsplashImage | undefined;
  onSelectImage: (image: UnsplashImage) => void;
  enableSearch?: boolean;
  searchString?: string;
}> = ({
  onSelectImage,
  enableSearch,
  searchString = '',
  // onDrop,
  selectedImage,
}) => {
  const [imagesList, setImagesList] =
    useState<UnsplashImage[]>(DEFAULT_IMAGES_LIST);

  const { query, queryHash } = useQuery({
    query: { query: enableSearch ? searchString : undefined, perPage: 20 },
  });

  const {
    entries,
    loadMore,
    request: { loading, hasMorePages },
  } = useEntries<EntityModels.CoverArtworkTemplate>({
    query,
    queryHash,
    entity: 'coverArtworkTemplates',
  });

  useEffect(() => {
    if (entries.length) {
      setImagesList(take(12, entries));
    }
  }, [entries]);

  const handleSelectImage = useCallback(
    (e, { width, height, urls, user }) => {
      if (user) {
        onSelectImage({ width, height, urls, user });
      }
    },
    [onSelectImage]
  );

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!loading && inView && hasMorePages && loadMore && entries) {
      loadMore();
    }
  }, [inView, loading, hasMorePages, loadMore]);

  if (enableSearch) {
    return (
      <SearchWrap>
        <SearchGrid>
          {entries.map((image) => (
            <ImagePreview
              key={image.urls.thumb}
              image={image}
              selected={
                !!selectedImage && selectedImage.urls.thumb === image.urls.thumb
              }
              onClick={handleSelectImage}
            />
          ))}
          <div ref={ref} />
        </SearchGrid>
      </SearchWrap>
    );
  }

  return (
    <TableWrapper>
      {/* 
        <CoverUploader css={buttonStyle} onDrop={onDrop}>
          <UploadIcon />
        </CoverUploader> */}
      {imagesList?.length
        ? imagesList.map((image) => (
            <ImagePreview
              key={image.urls.thumb}
              image={image}
              selected={
                !!selectedImage && selectedImage.urls.thumb === image.urls.thumb
              }
              onClick={handleSelectImage}
            />
          ))
        : new Array(12).fill(0).map((_v, i) => <Placeholder key={i} />)}
    </TableWrapper>
  );
};

export default memo(BackgroundSelector);
