import React, { useContext, useState, useCallback } from 'react';
import { css } from '@emotion/react';
import { Window, Button } from 'imdui';
import { AppContext } from 'imddata';

type Props = React.ComponentProps<typeof Button> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const deleteButtonStyle = css`
  margin: 20px;
`;

const DeleteAudioButton: React.FC<Props> = ({ onClick, ...props }) => {
  const app = useContext(AppContext);

  const [open, setOpen] = useState(false);

  const handleClick = useCallback((e: any) => {
    onClick(e);
    setOpen(false);
  }, []);

  const handleButtonClick = useCallback((e: any) => {
    if (app === 'admin') {
      setOpen(true);
      return;
    }
    onClick(e);
  }, []);

  return (
    <>
      <Window
        title="Confirm Audio Delete"
        style={{ minWidth: 420 }}
        isOpen={open}
        close={() => setOpen(false)}
      >
        <Button
          key="button"
          css={deleteButtonStyle}
          onClick={handleClick}
          text="Delete Audio"
        />
      </Window>

      <Button {...props} onClick={handleButtonClick} />
    </>
  );
};

export default DeleteAudioButton;
