import React, { useMemo, createContext } from 'react';
import { useCurrentLocale } from 'imdshared';

export const createFormatters = (locale?: string) => ({
  percentageFormatter: new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }),
  streamsFormatter: new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
  }),
});

export const ChartsContext = createContext(createFormatters('en'));

export const ChartsProvider: React.FC = ({ children }) => {
  const locale = useCurrentLocale();
  const formatters = useMemo(() => createFormatters(locale), [locale]);

  return (
    <ChartsContext.Provider value={formatters}>
      {children}
    </ChartsContext.Provider>
  );
};
