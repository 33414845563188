import React, { useContext, useMemo } from 'react';
import { ApplicationSettingsContext, useProductPage } from 'components';
import { useTranslation } from 'react-i18next';
import { type EntityModels } from 'imddata';
import { PriceBreakdown, ProductPriceContext } from '../../shared';
import { getOverviewItemPrice } from '../../shared/ProductWizard';
import { useProductPriceFormatted } from 'imdshared';

export const useOverviewItems = (
  overview: EntityModels.Overview | undefined
) => {
  return useMemo(
    () =>
      overview
        ? Object.keys(overview.groups).reduce<
            EntityModels.OverviewGroup['items']
          >((acc, groupKey) => {
            const group = overview.groups[groupKey];
            return [...acc, ...group.items];
          }, [])
        : [],
    [overview]
  );
};

export const useBreakdownItemsFromOverview = (
  items: { id: string; label: string }[]
) => {
  const { overview } = useContext(ProductPriceContext);
  const { t } = useTranslation();

  const priceCurrency = overview?.currency?.id || 'usd';

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: priceCurrency,
  });

  const overviewItems = useOverviewItems(overview);

  const getPriceText = (v: string | number) =>
    Number(v) === 0 ? t('free') : priceFormatter.format(Number(v));

  return useMemo<
    {
      label: string;
      amps?: number | undefined;
      basePrice?: string;
      price: string;
    }[]
  >(
    () =>
      items
        .map((i) => {
          const overviewItem = overviewItems.find((oi) => oi.id == i.id);
          if (!overviewItem) return null;
          const price = getPriceText(getOverviewItemPrice(overviewItem));

          const amps = overviewItem?.discounts.find(
            (d) => d.type === 'SUBSCRIPTION_CREDIT'
          )
            ? Number(overviewItem.ampValue)
            : 0;

          return {
            label: i.label,
            amps,
            basePrice: getPriceText(overviewItem.value),
            price,
          };
        })
        .filter((i): i is Exclude<typeof i, null> => i !== null),
    [items, overviewItems]
  );
};

export const MusicDistributionPriceBreakdown = ({
  deliveryTypeId,
  releaseTypeId,
  volumeCount,
}: {
  volumeCount?: number;
  deliveryTypeId: string;
  releaseTypeId: string;
}) => {
  const { t } = useTranslation();
  const {
    state: {
      data: { advancedSupportPurchase },
    },
  } = useProductPage();

  const items = useMemo(
    () => [
      {
        label: t(releaseTypeId),
        id: releaseTypeId,
      },
      {
        label: t(deliveryTypeId),
        id: deliveryTypeId,
      },
      { label: t('volumes'), id: 'volume' },
      {
        label: t('countries-change'),
        id: 'delivery-bundle-delivery-regions-customization',
      },
      {
        label: t('premium-label'),
        id: 'premium-label',
      },
      {
        label: t('playlist-pitching'),
        id: 'playlist-pitching',
      },
      { label: t('youtube-content-id'), id: 'youtubeci' },
    ],
    [releaseTypeId, deliveryTypeId, volumeCount]
  );

  const breakdownItems = useBreakdownItemsFromOverview(items);
  const supportPrice = useProductPriceFormatted('customer-support', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const finalItems: typeof breakdownItems = useMemo(
    () => [
      ...breakdownItems,
      ...(advancedSupportPurchase
        ? [
            {
              label: t('advanced-email-support'),
              id: 'customer-support',
              basePrice: supportPrice,
              price: supportPrice,
            },
          ]
        : []),
    ],
    [breakdownItems, advancedSupportPurchase]
  );

  return <PriceBreakdown items={finalItems} />;
};
