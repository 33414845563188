import React, { useContext, useCallback } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { ReduxState } from 'imddata';
import { callAuthApi } from 'imddata';
import { AppContext, useCurrentLocale, Button, BodySmall } from 'imdshared';
import type { Theme } from 'imdui';
import { FacebookIcon } from './FacebookIcon';
import { GoogleIcon } from './GoogleIcon';

const wrapperStyle = (theme: Theme) => css`
  text-align: center;
  margin-top: 16px;
  padding-top: 24px;
  box-shadow: inset 0 8px 0 0 ${theme.lights[1]};
`;

const bodySmallStyle = css`
  display: block;
  margin-bottom: 12px;
`;

const buttonStyle = css`
  width: 100%;
  justify-content: center;
  background-color: #3b5998;
`;

const googleStyle = css`
  width: 100%;
  margin-bottom: 8px;
  justify-content: center;
  background-color: '#4285F4';
`;

type Query = {
  enableGoogleAuth?: boolean;
};

function SocialSignin() {
  const app = useContext(AppContext);
  const locale = useCurrentLocale();
  const { t } = useTranslation();
  const location = useLocation();
  const query = camelizeKeys(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })
  ) as Query;
  const codes = useSelector((state: ReduxState) => state.ui.signup.codes);

  const getSocialAuthUrl = useCallback(
    (provider) => {
      callAuthApi
        .getSocialAuthUrl({
          provider,
          locale,
          affiliatePartnerToken: codes?.affiliate,
          referralToken: codes?.referral,
        })
        .then(({ response, error }: any) => {
          if (!error && response) {
            // eslint-disable-next-line no-restricted-globals
            if (window && window.location) {
              window.location.href = response.url;
            }
          }
        });
    },
    [locale, codes]
  );

  const getFacebookAuthUrl = useCallback(() => {
    getSocialAuthUrl('facebook');
  }, [getSocialAuthUrl]);

  const getGoogleAuthUrl = useCallback(() => {
    getSocialAuthUrl('google');
  }, [getSocialAuthUrl]);

  return (
    <div css={wrapperStyle}>
      <BodySmall css={bodySmallStyle}>{t('sign-in-using')}</BodySmall>

      {query.enableGoogleAuth || app === 'admin' ? (
        <Button
          css={googleStyle}
          appearance="fill"
          text={t('google')}
          iconLeft={GoogleIcon}
          onClick={getGoogleAuthUrl}
        ></Button>
      ) : null}
      {app === 'front' && (
        <Button
          css={buttonStyle}
          text={t('facebook')}
          iconLeft={FacebookIcon}
          appearance="fill"
          onClick={getFacebookAuthUrl}
        ></Button>
      )}
    </div>
  );
}

export default SocialSignin;
