// @ts-nocheck
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator, SecondaryButton } from 'imdui';
import { useQuery, useDeleteEntity } from 'imddata';

import useUpdateOverview from '../helpers/useUpdateOverview';

type CustomRendererProps = {
  onClickRevert: (e: React.MouseEvent) => void;
  deleting: boolean;
  deleted: boolean;
};

type Props = {
  changeId: string;
  attribute?: string;
  customRenderer?: React.FC<CustomRendererProps>;
};

const StyledSecondaryButton = styled(SecondaryButton)`
  font-size: 14px;
`;

/**
 * Component that allows to revert either attribute or whole change.
 * By default it renders the text button. With `customRenderer` prop you can render any visual element.
 */
const RevertButton: React.FC<Props> = ({
  changeId,
  attribute,
  customRenderer,
}) => {
  const { t } = useTranslation();

  const query = useMemo(
    () => (attribute ? { attributes: attribute } : null),
    [attribute]
  );

  const { queryHash } = useQuery({ query });

  const {
    deleteEntry,
    request: { deleting, deleted },
  } = useDeleteEntity({
    entity: 'deliveryBundleUpdates',
    query,
    queryHash,
    id: changeId,
  });

  useUpdateOverview({ deleted });

  const handleClick = (): void => {
    deleteEntry({ queryHash, id: changeId });
  };

  if (customRenderer) {
    return customRenderer({ onClickRevert: handleClick, deleting, deleted });
  }

  return (
    <StyledSecondaryButton
      active={true}
      onClick={handleClick}
      className={deleted ? 'disabled' : undefined}
    >
      {!deleting && t('revert')}

      {deleting && <LoadingIndicator />}
    </StyledSecondaryButton>
  );
};

export default RevertButton;
