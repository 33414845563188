//

const requestReducerFactory =
  (stateCreator) => (asyncActions) => (state, innerAction, itemState) => {
    switch (innerAction.type) {
      case asyncActions.REQUEST:
        return stateCreator.request(innerAction, itemState);
      case asyncActions.SUCCESS:
        return stateCreator.success(innerAction, itemState);
      case asyncActions.FAILURE:
        return stateCreator.failure(innerAction, itemState);
      default:
        return {};
    }
  };

export default requestReducerFactory;
