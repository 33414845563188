import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from '../../../helpers';
import {
  SelectField,
  LanguageSelectorField,
  FieldUpdatable,
} from '../../../fields';

const SelectIsInstrumental = createSelector('isInstrumental');

const AudioLanguageField: React.FC<any> = ({
  showHelpWindow,
  handleTrackField,
  form,
  requiredFields,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <SelectIsInstrumental form={form}>
      {(checked: boolean) =>
        !checked ? (
          <FieldUpdatable
            name="audioLanguageId"
            testId="audioLanguageId"
            required={
              requiredFields && requiredFields.includes('audioLanguageId')
            }
            floatingLabelText={t('audio-language')}
            helperText={t('track-helptext-short-audio-language')}
            onClickHelp={showHelpWindow(
              t('language'),
              t('track-helptext-audio-language')
            )}
            {...props}
            component={LanguageSelectorField}
            onChange={(event, value) => {
              handleTrackField('audioLanguageId')(event, value);
            }}
          />
        ) : (
          <SelectField
            {...{
              ...props,
              floatingLabelText: t('audio-language'),
              helperText: t('track-helptext-short-audio-language'),
              onClickHelp: showHelpWindow(
                t('language'),
                t('track-helptext-audio-language')
              ),
            }}
            input={{
              value: 'no-lang',
            }}
            disabled={true}
            data={[{ value: 'no-lang', label: t('no-audio-language') }]}
          />
        )
      }
    </SelectIsInstrumental>
  );
};

export default AudioLanguageField;
