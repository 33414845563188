//
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createJoinTagField } from '../../helpers';
import JoinTags from '../JoinTags';
import EditItemForm from './EditItemForm';

const data = {
  tags: [],
};

const PublisherJoin = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <JoinTags
      allowDuplicates={false}
      renderForm={EditItemForm}
      data={data}
      draggable={false}
      placeholder={t('type-name', { defaultValue: 'Type name' })}
      idKey="publisherId"
      addLabel={t('add-publisher')}
      createLabel={t('create-publisher')}
      label={t('add-publisher')}
      floatingLabelText={t('add-publisher')}
      entity="publishers"
      {...props}
    />
  );
};

export const PublisherJoinField = createJoinTagField(
  PublisherJoin,
  'publishers'
);

export default PublisherJoin;
