/* eslint-disable jsx-a11y/accessible-emoji */

import React, { useContext } from 'react';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { curveMonotoneX } from '@visx/curve';
import type { AxisScaleOutput } from '@visx/axis';
import type { ScaleConfig } from '@visx/scale';
import { ParentSize } from '@visx/responsive';
import { buildChartTheme, Axis, LineSeries, XYChart } from '@visx/xychart';
import type {
  RenderTooltipParams,
  TooltipProps,
} from '@visx/xychart/lib/components/Tooltip';
import { CircleGrid } from './CircleGrid';
import { ChartsContext } from './ChartsContextProvider';
import { lineColors, createAxisLabelProps } from './styles';
import {
  TooltipLegendGrid,
  TooltipLegendRow,
  TooltipValue,
  Tooltip,
} from './Tooltip';

const theme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  colors: lineColors,
  tickLength: 2,
});
const getFormattedDateTime = (dateTime: Date) =>
  dateTime.toLocaleDateString(moment.locale(), {
    month: 'short',
    day: 'numeric',
  });

const defaultFormatDate = (
  d: string,
  index: number,
  values: Array<{ value: string; index: number }>
) => {
  const date = moment(d);
  const day = date.date();
  const month = date.month();
  const prevDate = index !== 0 ? values[index - 1].value : null;
  if (
    day === 1 ||
    index === 0 ||
    (prevDate && moment(prevDate).month() !== month)
  ) {
    return getFormattedDateTime(date.toDate());
  }
  return date.format('DD');
};

export const defaultConfig: {
  x: ScaleConfig<AxisScaleOutput, any, any>;
  y: ScaleConfig<AxisScaleOutput, any, any>;
} = {
  x: {
    type: 'band',
  },
  y: { type: 'linear' },
};

export const disabledConfig: {
  x: ScaleConfig<AxisScaleOutput, any, any>;
  y: ScaleConfig<AxisScaleOutput, any, any>;
} = {
  x: {
    type: 'band',
  },
  y: { type: 'linear', domain: [0, 8000] },
};

type Datum = {
  date: Moment | string;
  value: number | null;
  label: string;
};

export type Props<D extends Datum = Datum> = {
  getMaxHorizontalTicks?: (w: number) => number;
  curve?: React.ComponentProps<typeof LineSeries>['curve'];
  renderTooltip?: TooltipProps<D>['renderTooltip'];
  data: { [key: string]: Array<D> } | null;
  verticalTicksNum?: number;
  selected?: string;
  disabled?: boolean;
  formatDate?: typeof defaultFormatDate;
  tickFormatValue?: React.ComponentProps<typeof Axis>['tickFormat'];
};

type ChartProps<D extends Datum> = Props<D> & {
  width: number;
  height: number;
};

const MARGIN = { top: 24, left: 98, right: 36, bottom: 48 + 24 };

const dateAccessor = (d: Datum) => {
  if (typeof d.date === 'string') {
    return d.date;
  }
  return d.date.format('YYYY-MM-DD');
};

const valueAccessor = (d: Datum) => {
  return d.value;
};

const defaultRenderTooltip = <D extends Datum>({
  tooltipData,
  colorScale,
}: RenderTooltipParams<D>) => {
  const { streamsFormatter } = useContext(ChartsContext);
  const date =
    (tooltipData?.nearestDatum?.datum &&
      getFormattedDateTime(
        moment.utc(dateAccessor(tooltipData?.nearestDatum?.datum)).toDate()
      )) ||
    'No date';
  return (
    <>
      <TooltipValue>{date}</TooltipValue>

      <TooltipLegendGrid>
        {/** temperatures */}
        {Object.keys(tooltipData?.datumByKey ?? {})
          .filter((d) => d)
          .map((dataKey) => {
            const emptyDatum = { label: '', value: 0 };
            const { label, value } =
              tooltipData?.datumByKey[dataKey]?.datum || emptyDatum;

            if (value === null) {
              return '';
            }

            return (
              <TooltipLegendRow
                key={dataKey}
                label={label}
                value={streamsFormatter.format(value)}
                color={colorScale?.(dataKey)}
              />
            );
          })}
      </TooltipLegendGrid>
    </>
  );
};

const disabledData: { [key: string]: Array<Datum> } = {
  total: [
    {
      date: '2021-01-02',
      label: '',
      value: 3000,
    },
    {
      date: '2021-01-03',
      label: '',
      value: 5000,
    },
    {
      date: '2021-01-04',
      label: '',
      value: 3000,
    },
    {
      date: '2021-01-05',
      label: '',
      value: 4000,
    },
    {
      date: '2021-01-06',
      label: '',
      value: 2000,
    },
    {
      date: '2021-01-07',
      label: '',
      value: 5000,
    },
    {
      date: '2021-01-08',
      label: '',
      value: 6000,
    },
  ],
};

const emptyTick = () => '';

const disabledTheme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  colors: ['#ccc'],
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  tickLength: 2,
});

// const MissingLineDataProvider = ({ children, xAccessor, yAccessor, data }) => {
//   const { xScale, yScale } = useContext(DataContext);
//
//   const dataRegistry = useMemo(
//     () => ({
//       registerData: () => {},
//       unregisterData: () => {},
//       get: () => ({ data, xAccessor, yAccessor }),
//     }),
//     [data, xAccessor, yAccessor]
//   );
//
//   return (
//     <DataContext.Provider
//       value={{
//         dataRegistry,
//         theme: disabledTheme,
//         xScale,
//         yScale,
//       }}
//     >
//       {children}
//     </DataContext.Provider>
//   );
// };
//
//           <MissingLineDataProvider
//             yAccessor={totalAccessor}
//             xAccessor={dateAccessor}
//             data={interpolatedData}
//           >
//             <LineSeries
//               enableEvents={false}
//               className="visx-xychart-line"
//               dataKey="totalInterpolated"
//               data={interpolatedData}
//               strokeWidth={3}
//               strokeDasharray="5"
//               stroke="#ccc"
//               yAccessor={totalAccessor}
//               xAccessor={dateAccessor}
//               curve={curve}
//             />
//           </MissingLineDataProvider>

const defaultGetMaxHorizontalTicks = (width: number) =>
  width < 400 ? 8 : width < 700 ? 15 : 30;

function LineChart<D extends Datum = Datum>({
  width,
  data: propsData,
  verticalTicksNum = 8,
  selected,
  formatDate = defaultFormatDate,
  renderTooltip = defaultRenderTooltip,
  curve = curveMonotoneX,
  disabled,
  height,
  tickFormatValue = undefined,
  getMaxHorizontalTicks = defaultGetMaxHorizontalTicks,
}: ChartProps<D>) {
  const maxHorizontalTicks = getMaxHorizontalTicks(width);
  const data = disabled ? disabledData : propsData;

  // const interpolatedData = useMemo(
  //   () =>
  //     data
  //       .map((d, idx) => {
  //         if (
  //           d.value === 'null' ||
  //           (data[idx + 1] && data[idx + 1].value === 'null') ||
  //           (data[idx - 1] && data[idx - 1].value === 'null')
  //         ) {
  //           return d;
  //         }
  //         return { ...d, value: 'shitshow' };
  //       })
  //       .map((d, idx) =>
  //         d.value === 'null' && idx > 0
  //           ? { ...d, value: data[idx - 1].value }
  //           : d
  //       ),
  //   [data]
  // );
  return (
    <XYChart
      theme={disabled ? disabledTheme : theme}
      xScale={defaultConfig.x}
      yScale={disabled ? disabledConfig.y : defaultConfig.y}
      margin={MARGIN}
      height={height}
      width={width}
    >
      <CircleGrid
        numTicks={maxHorizontalTicks}
        verticalTicksNum={verticalTicksNum}
      />
      {data
        ? Object.keys(data).map((id: string) =>
            data[id] ? (
              <LineSeries
                key={id}
                className="visx-xychart-line"
                dataKey={id}
                data={data[id]}
                strokeWidth={
                  Object.keys(data).length === 1 ? 3 : selected === id ? 4 : 2
                }
                yAccessor={valueAccessor}
                xAccessor={dateAccessor}
                curve={curve}
              />
            ) : null
          )
        : null}
      <Axis
        tickFormat={disabled ? emptyTick : formatDate}
        hideTicks={true}
        numTicks={Math.min(
          Math.max(...Object.values(data || {}).map((d) => d.length)),
          maxHorizontalTicks
        )}
        hideAxisLine={true}
        orientation="bottom"
        top={height - MARGIN.bottom + 24}
        tickLabelProps={(_value, index) => {
          return {
            ...createAxisLabelProps(index),
            textAnchor: 'middle',
            verticalAnchor: 'middle',
            width: 2,
          };
        }}
      />
      <Axis
        orientation="left"
        numTicks={verticalTicksNum}
        hideAxisLine={true}
        hideTicks={true}
        tickFormat={disabled ? emptyTick : tickFormatValue}
        tickLength={48}
        left={MARGIN.left - 16}
        tickLabelProps={(_value, index) => {
          return {
            ...createAxisLabelProps(index),
            textAnchor: 'start',
            // dx: -16,
          };
        }}
      />

      {!disabled && (
        <Tooltip<D>
          showVerticalCrosshair={true}
          snapTooltipToDatumX={true}
          snapTooltipToDatumY={true}
          showSeriesGlyphs={true}
          unstyled={true}
          applyPositionStyle={true}
          renderTooltip={renderTooltip}
        />
      )}
    </XYChart>
  );
}
export default function ResponsiveLineChart<D extends Datum>(props: Props<D>) {
  return (
    <ParentSize debounceTime={300}>
      {({ width, height }) => (
        <LineChart width={width} height={height} {...props} />
      )}
    </ParentSize>
  );
}
