import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '../../../text';
import { Icon } from '../../icon';
import { appear, pulse } from '../../shared/keyframes';

const focusAnim = ({ focused }: { focused: boolean }) => {
  if (focused) {
    return css`
      ${appear} ease-in-out 0.2s forwards,
        ${pulse} 1.5s 0.2s ease-in-out infinite;
    `;
  }
  return 'none';
};

export const Wrapper = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > ${Body} {
    margin-left: 16px;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    animation: ${focusAnim};
  }
`;

export const StyledIcon = styled(Icon)<{
  checked: boolean;
  subchecked?: boolean;
}>`
  position: absolute;
  top: -2px;
  left: -2px;
  fill: ${({ theme, checked, subchecked }): string =>
    checked || subchecked ? 'white' : theme.foreground.primary};
`;

export const StyledInput = styled.input<{
  checked: boolean;
  subchecked?: boolean;
  tint?: string;
}>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px
    ${({ theme, tint }): string => {
      return tint || theme.foreground.primary;
    }};
  background-color: ${({ theme, checked, subchecked, tint }): string => {
    if (checked || subchecked) {
      return tint || theme.foreground.primary;
    }
    return 'transparent';
  }};
  transition: all 0.1s ease-in-out;
  cursor: pointer;
`;
