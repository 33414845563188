//
import React, { useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as LibraryScreens from './screens';
import { useTranslation } from 'react-i18next';
import { PageNavigation } from 'imdshared';
import { PageTemplate } from 'components';

const LibraryRoot: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();

  const routes = useMemo(
    () => [
      {
        to: 'artists',
        text: 'artists',
        paths: ['artists', 'artists/:artistId/:tab'],
      },
      { to: 'releases', text: 'releases' },
      { to: 'tracks', testId: 'NavItem-Library-Tracks', text: 'tracks' },
    ],
    []
  );

  return (
    <PageTemplate title={t('library')}>
      <PageNavigation match={match} routes={routes} />
      <Switch>
        <Route
          exact={true}
          path={`${match.url}`}
          render={() => <Redirect to={`${match.url}/artists`} />}
        />

        <Route path={`${match.url}/tracks`} component={LibraryScreens.Tracks} />

        <Route
          path={`${match.url}/artists`}
          component={LibraryScreens.Artists}
        />

        <Route
          path={`${match.url}/publishers`}
          component={LibraryScreens.Publishers}
        />

        <Route
          path={`${match.url}/channels`}
          component={LibraryScreens.Channels}
        />

        <Route
          path={`${match.url}/contributors`}
          component={LibraryScreens.Contributors}
        />

        <Route
          path={`${match.url}/releases/:id/edit`}
          component={LibraryScreens.EditRelease}
        />

        <Route
          path={`${match.url}/releases/:id`}
          component={LibraryScreens.Release}
        />

        <Route
          path={`${match.url}/releases`}
          component={LibraryScreens.Releases}
        />
      </Switch>
    </PageTemplate>
  );
};

export default LibraryRoot;
