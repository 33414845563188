import React, { Component } from 'react';
import { css } from '@emotion/react';
import { Button, Icons, TracksUploader } from 'imdshared';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { TracksQueryBuilder, LibraryHeaderTools } from 'components/molecules';
import { createEmptyTrack } from 'imddata/actionTypes/tracks';
import GlobalTracksList from '../GlobalTracksList';

// TODO Move it in Modules

// const GROUP_BY_OPTIONS = [
//   { id: 'create_date', label: 'Date' },
//   { id: 'releases', label: 'Release' },
//   { id: 'artists', label: 'Artist' },
//   { divider: true },
//   { id: null, label: 'Do not group' },
// ];

const uploaderButtonStyle = css`
  width: inherit;
  justify-content: inherit;
`;

class TracksSearch extends Component {
  static defaultProps = {
    languageId: 'en',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultSearchQuery !== prevState.defaultSearchQuery) {
      return {
        defaultSearchQuery: nextProps.defaultSearchQuery,
        searchQuery: {
          ...nextProps.defaultSearchQuery,
          ...prevState.searchQuery,
        },
      };
    }

    return null;
  }

  state = {
    groupBy: 'create_date',
    filters: [],
    searchQuery: {
      perPage: 30,
    },
  };

  onChangeGroupBy = (e, value) => {
    this.setState({ groupBy: value });
  };

  onChangeFiltering = ({ value, searchQuery }) => {
    this.setState({
      filters: value,
      searchQuery: this.props.defaultSearchQuery
        ? { ...this.props.defaultSearchQuery, ...searchQuery }
        : searchQuery,
    });
  };

  render() {
    const {
      selectedTracks,
      disabledTracks,
      hideCreateTrack,
      hideUploadTrack,
      onChangeSelection,
      leftChildren,
      stickyTopThreshold,
      respectTopConstraint,
      isDisabled,
      noGrouping,
    } = this.props;

    const { groupBy, filters, searchQuery } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <LibraryHeaderTools
              renderQueryBuilder={TracksQueryBuilder}
              key="header"
              components={{ Left: leftChildren }}
              stickyTopThreshold={stickyTopThreshold}
              respectTopConstraint={respectTopConstraint}
              value={filters}
              onChangeFiltering={this.onChangeFiltering}
            >
              {!hideUploadTrack && (
                <TracksUploader
                  releaseId={null}
                  volumeNumber={null}
                  inline={true}
                >
                  <Button
                    onClick={() => {
                      // fix requiring on click for styles?
                    }}
                    icon={Icons.actions.upload}
                    text={t('import')}
                    css={uploaderButtonStyle}
                  />
                </TracksUploader>
              )}

              {!hideCreateTrack && (
                <Button
                  testId="Library-CreateTrack"
                  icon={Icons.actions.add}
                  text={t('create-empty')}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                    });
                    this.props.createEmptyTrack({
                      data: {},
                    });
                  }}
                />
              )}
            </LibraryHeaderTools>

            <GlobalTracksList
              key="tracks"
              scrollElement={this.props.scrollElement}
              onChangeSelection={onChangeSelection}
              selectedTracks={selectedTracks}
              disabledTracks={disabledTracks}
              isDisabled={isDisabled}
              groupBy={noGrouping ? null : groupBy}
              filters={filters}
              searchQuery={searchQuery}
            />
          </>
        )}
      </Translation>
    );
  }
}

export default connect(null, { createEmptyTrack })(TracksSearch);
