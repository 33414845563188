//
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { CustomerCommisionRate, DeliveryForm } from 'components';
import styled from '@emotion/styled';
import { Card } from 'imdui';
import { BodyL, BodyM } from '@imus/services-ui/src/Text';
import { ProductTwoColumnsWrapper, useSubscriptionUpsell } from 'imdshared';

const InfoColumn = styled.div``;

const WelcomeCard = styled(Card)`
  padding: 32px;
  flex: 400px;
  align-items: flex-start;
  display: grid;
  gap: 12px;
  ${BodyM} {
    color: var(--fg-2);
  }
`;

const CommissionCard = styled(Card)`
  align-items: flex-start;
  padding: 32px;
  flex: 400px;
`;

const DeliveryWrapper = styled.div``;

const Delivery = ({
  form,
  bundleId,
  releaseId,
}: {
  form?: string;
  bundleId: string;
  releaseId: number | string;
}) => {
  const { t } = useTranslation();
  const { open: openUpsell } = useSubscriptionUpsell();

  return (
    <div>
      <Helmet>
        <title>{t('page-title-music-distribution-delivery')}</title>
      </Helmet>

      <ProductTwoColumnsWrapper>
        <InfoColumn>
          <WelcomeCard secondary={true}>
            <BodyL>{t('welcome-to-music')}</BodyL>
            <BodyM>{t('welcome-to-music-desc')}</BodyM>
          </WelcomeCard>

          <CommissionCard secondary={true}>
            <CustomerCommisionRate
              onClickSubscribe={() => {
                openUpsell({
                  analytics: { detail: 'commission' },
                  section: 'music-distribution',
                });
              }}
            />
          </CommissionCard>
        </InfoColumn>
        <DeliveryWrapper>
          <DeliveryForm
            // @ts-ignore
            form={form}
            bundleId={bundleId}
            releaseId={releaseId}
          />
        </DeliveryWrapper>
      </ProductTwoColumnsWrapper>
    </div>
  );
};

export default Delivery;
