//
import React from 'react';
import { MenuItem } from 'imdui';
import { css } from '@emotion/react';

const Menu = ({
  value,
  data: { keys, entries } = { keys: [], entries: {} },
  onChange,
}) => (
  <div
    role="menu"
    css={css`
      padding: 10px 0;
      overflow: auto;
    `}
  >
    {keys.map((k) => {
      const item = entries[k];
      const selected = value === item.value;

      return (
        <MenuItem
          isSelected={selected}
          disabled={item.disabled}
          isSelectable={value !== null}
          onClick={() => onChange(item.value)}
          key={k}
          data={item}
        />
      );
    })}
  </div>
);

export default Menu;
