/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import styled from '@emotion/styled';
import { H3, CloseButton } from 'imdui';

const Wrapper = styled.div`
  border-bottom: 1px solid var(--outline-var);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 16px 24px;
`;

type Props = {
  children?: React.ReactNode;
  onClickClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
};

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex: 1;

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

export default function SideEditorHeader({
  label,
  children,
  onClickClose,
}: Props) {
  return (
    <Wrapper>
      <Row>
        <H3>{label}</H3>
        {onClickClose && (
          <CloseButton
            tabIndex={-1}
            testId="SideEditor-Close"
            onClick={onClickClose}
          />
        )}
      </Row>
      {children ? (
        <BottomRow>
          <TabsWrapper>{children}</TabsWrapper>
        </BottomRow>
      ) : null}
    </Wrapper>
  );
}
