import React from 'react';
import styled from '@emotion/styled';
import { BodySmall, LoadingIndicator, themeLight } from 'imdui';

const PaddedCard = styled.div`
  padding: 16px;
`;

export const ProductLoader = ({
  testId,
  loadingText,
  failedText,
}: {
  testId?: string;
  loadingText?: string;
  failedText?: string;
}) => (
  <div data-test-id={testId} style={{ marginTop: '30px' }}>
    {!failedText && (
      <PaddedCard>
        <LoadingIndicator size="large" />

        {loadingText && (
          <BodySmall style={{ marginTop: '8px', display: 'block' }}>
            {loadingText}
          </BodySmall>
        )}
      </PaddedCard>
    )}

    {failedText && (
      <PaddedCard>
        <BodySmall style={{ color: themeLight.state.error }}>
          {failedText}
        </BodySmall>
      </PaddedCard>
    )}
  </div>
);
