//
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { setAuth } from 'imddata/utils/auth';
import { loadLoggedUser } from 'imddata/actionTypes/users';

export default function TokenAuth() {
  const dispatch = useDispatch();
  useEffect(() => {
    const { signup, ...query } = camelizeKeys(
      // eslint-disable-next-line no-restricted-globals
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    ) as qs.ParsedQs;

    if (query && Object.values(query).length) {
      // TODO: fix AuthToken definition and add a check for it
      // @ts-ignore
      setAuth(query);
      dispatch(loadLoggedUser({ signup: signup ? 'widget' : false }));
    }
  }, []);

  return null;
}
