import React from 'react';
import styled from '@emotion/styled';

const ScreenWrapper = styled.div`
  position: relative;
  max-width: 100%;
`;

interface Props {
  children: React.ReactNode;
  testId?: string;
}

export default function Container({ children, testId }: Props): JSX.Element {
  return <ScreenWrapper data-test-id={testId}>{children}</ScreenWrapper>;
}
