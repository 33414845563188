import React, { useMemo, useEffect, useRef } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useCurrentLocale } from 'imdshared';
import config from 'imdconfig';
import type { TemplateData } from '@imus/release-page-template';
import { Layout, ShopsList } from '@imus/release-page-template';

const releaseCoverExample = {
  url: 'https://images.unsplash.com/photo-1546347386-b4a2fb657cad?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw0MTkxfDB8MXxjb2xsZWN0aW9ufDR8OTYwNzM0N3x8fHx8Mnx8MTYxNzY5NjI2OA&ixlib=rb-1.2.1&q=85',
};

export const ReleasePageTemplatePreview: React.FC<{
  coverUrl: string;
  availableShops: string[];
  title: string;
  displayArtist: string;
  isPremium: boolean;
  isBranded?: boolean;
}> = ({
  title = 'Example Title',
  displayArtist = 'Example Artist',
  coverUrl = releaseCoverExample.url,
  availableShops,
  isBranded,
  isPremium,
}) => {
  const { i18n } = useTranslation();
  const locale = useCurrentLocale();
  const data: TemplateData = useMemo(
    () => ({
      free: !isPremium,
      branded: !!isBranded,
      context: {},
      meta: {
        url: '',
        title: '',
        description: '',
      },
      release: {
        barcode: '',
        title,
        displayArtist,
        cover: { url: coverUrl, extension: '' },
        services: availableShops.map((shopId) => ({
          isVisible: true,
          name: shopId,
          url: '',
        })),
      },
      general: {
        locale,
        title,
        background: [
          {
            url: coverUrl,
            extension: '',
          },
        ],
      },
      config: {
        assetsUrl: config.releaseTemplateBaseUrl,
      },
    }),
    [locale, isPremium, availableShops, isBranded]
  );

  const templateI18nRef = useRef(i18n.cloneInstance());
  useEffect(() => {
    if (locale) {
      templateI18nRef.current.changeLanguage(locale);
      templateI18nRef.current.loadNamespaces('artist-hub');
      templateI18nRef.current.setDefaultNamespace('artist-hub');
    }
  }, [locale]);

  return (
    <I18nextProvider i18n={templateI18nRef.current}>
      <Layout data={data}>
        <ShopsList data={data}></ShopsList>
      </Layout>
    </I18nextProvider>
  );
};
