import styled from '@emotion/styled';

export const NavStatusDot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 6px;
  border: 2px solid var(--background, #e5e2e0);
  background: var(--accent, #00a743);
  position: absolute;
  right: 0px;
  top: 0px;
`;
