import styled from '@emotion/styled';
import Clickable from './Clickable';

export const CardOption = styled(Clickable)`
  border-radius: 0;
  padding: 24px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  gap: 16px;
  grid-template-columns: auto 1fr auto auto;
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    &:before {
      position: absolute;
      content: '';
      height: 1px;
      width: calc(100% - 48px);
      background: var(--fg-4, rgba(0, 0, 0, 0.1));
      z-index: 2;
      top: 100%;
      left: 24px;
      right: 0;
    }
  }
`;
