//
import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { setSubmitFailed, stopSubmit } from 'redux-form';
import { types, setCoverInStore } from '../actionTypes/releases';
import dataURIToBlob from '../utils/dataURIToBlob';
import * as api from '../api';
import callApi from '../callApi';

function* uploadAndSetCover(id, { fullsize, thumbnail }) {
  try {
    yield put(setCoverInStore(id, thumbnail));
    yield call(api.releases.cover.uploadAndConfirm, {
      payload: {
        originalFileName: fullsize.name || 'imd',
        file: fullsize instanceof Blob ? fullsize : dataURIToBlob(fullsize),
        id: [id],
      },
    });
  } catch (error) {
    yield put(setCoverInStore(id, undefined));
  }
}
function* uploadCoverAsync(action) {
  const {
    payload: { id, fullsize, thumbnail },
  } = action;
  yield call(uploadAndSetCover, id, { fullsize, thumbnail });
}

function* validateBarcode(action) {
  const {
    payload: { value, form, releaseId },
  } = action;
  if (!value || value.length === 0) {
    yield call(api.releases.update, {
      id: releaseId,
      formId: form,
      data: { customBarcode: '' },
    });
    return;
  }

  if (value && value.length <= 11) return;

  const { response } = yield callApi({
    selectData: (json) => json,
    endpoint: `barcodes/check/${value}`,
    options: { method: 'GET' },
  });

  if (response && response.data.isAvailable && response.data.isValid) {
    yield call(api.releases.update, {
      id: releaseId,
      formId: form,
      data: { customBarcode: value },
    });

    return;
  }

  const isValid = response ? response.data.isValid : false;
  const isAvailable = response ? response.data.isAvailable : false;

  const message = !isValid
    ? 'barcode-not-valid'
    : !isAvailable
      ? 'this-barcode-is-not-available'
      : null;

  yield put(
    stopSubmit(form, {
      customBarcode: message,
    })
  );
  yield put(setSubmitFailed(form, ['customBarcode']));
}

export default function* requestRoot() {
  yield takeEvery([types.UPLOAD_COVER], uploadCoverAsync);
  yield takeLatest([types.VALIDATE_BARCODE], validateBarcode);
}
