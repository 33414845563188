import { useMemo, useEffect, useCallback, useState } from 'react';
import download from 'downloadjs';
import { useCreateEntity, useEntryProvider } from './base';

export const useStartDownload = ({ entity, id, preventDownloadDialog }) => {
  const entry = useEntryProvider({ entity, id });
  const {
    createEntry,
    createdId: downloadId,
    request,
  } = useCreateEntity({ entity: 'downloads' });
  const [downloadStarted, setDownloadStarted] = useState(false);
  const downloadEntry = useEntryProvider({
    entity: 'downloads',
    id: downloadId,
  });

  const startDownload = useCallback(
    (data = {}) => {
      if (request.loading || downloadStarted) return;
      if (entry?.downloadUrl && id) {
        download(entry?.downloadUrl);
        return;
      }
      setDownloadStarted(true);

      createEntry({
        data: {
          entity,
          ...data,
        },
      });
    },
    [entry?.downloadUrl, request.loading]
  );

  useEffect(() => {
    if (downloadEntry?.downloadUrl && downloadStarted) {
      setDownloadStarted(false);
      if (!preventDownloadDialog) {
        download(downloadEntry?.downloadUrl);
      }
    }
  }, [!!downloadEntry?.downloadUrl]);

  const finalRequest = useMemo(() => {
    return { loading: request.creating || downloadStarted };
  }, [request.creating, downloadStarted]);

  return { startDownload, request: finalRequest };
};
