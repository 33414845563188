//
import * as R from 'ramda';
import { REFRESH_ENTITY } from '../../actionTypes/request';
import { entities } from '../../actionTypes';
import combineReducers from '../../base/combineReducers';
import * as reducers from './customReducers';
import { deleteKey, mergeKeys, generateKeysOnCreate } from './utils';

const initState = {
  keys: [],
  newItemsKeys: [],
  entities: {},
  indexedData: {},
  searchHistory: {},
};

const initialEntityState = Object.keys(entities).reduce(
  (acc, key) => ({
    ...acc,
    [key]: reducers[key] ? reducers[key](undefined, '@@INIT') : initState,
  }),
  {}
);

const updateTempTrackId = ({ trackId, tempId }, releaseTracks, l) => {
  const releaseTrack = R.find(
    R.propEq(trackId, 'trackId'),
    R.values(releaseTracks)
  );
  return l.map((volume) => {
    const tempIndex = R.findIndex(R.equals(tempId), volume);
    if (tempIndex !== -1) {
      return R.set(R.lensIndex(tempIndex), releaseTrack.id, volume);
    }
    return volume;
  });
};

const isTemp = (id) => typeof id === 'string' && id.slice(0, 6) === '[TEMP]';

const concatReleases = (action) => (k, l, r) => {
  const { releaseTracks } = action.response.entities;
  const { tempTrackIds, data } = action.payload;

  if (k === 'volumes') {
    if (tempTrackIds && l.length) {
      return tempTrackIds.reduce((acc, tempTrackId) => {
        return updateTempTrackId(tempTrackId, releaseTracks, acc);
      }, l);
    }
    if (action?.meta?.sockets) {
      if (l) {
        const hasTemp = l.reduce((acc, vol) => acc || vol.find(isTemp), false);
        if (hasTemp) return l;
      }
      // if (l) {
      //   const onlyTemp = l.map((vol) => vol.filter(isTemp));
      //   const volumesCount = new Array(
      //     onlyTemp.length > r.length ? onlyTemp.length : r.length
      //   ).fill(null);
      //   const val = volumesCount.map((_, index) =>
      //     onlyTemp[index] ? [...(r[index] || []), ...onlyTemp[index]] : r[index]
      //   );
      //   return val;
      // }
      return r;
    }
    return data && data.volumes ? r : l || r;
  }
  return r;
};

const getEntityMerger = (entityKey, action) => {
  switch (entityKey) {
    case 'releases':
      return R.mergeDeepWithKey(concatReleases(action));
    case 'customerPaymentMethods': {
      return (stored, responseEntries) => {
        if (action.payload?.id && responseEntries.undefined) {
          return R.mergeDeepRight(stored, {
            [action.payload.id]: responseEntries?.undefined,
          });
        }
        return R.mergeDeepRight(stored, responseEntries);
      };
    }
    default:
      return R.mergeDeepRight;
  }
};

const resetTargets = (merged, response) => {
  return R.mergeWithKey(
    (k, l, r) => {
      if (k === 'targets') {
        return r;
      }
      return l;
    },
    merged,
    response
  );
};

const mergeEntries = (storedEntities, responseEntities, entityKey, action) => {
  if (!responseEntities) return storedEntities;
  const ids = Object.keys(responseEntities);
  if (!ids.length) return storedEntities;
  return {
    ...storedEntities,
    ...ids.reduce(
      (acc, id) => ({
        ...acc,
        [id]: resetTargets(
          getEntityMerger(entityKey, action)(
            storedEntities[id],
            responseEntities[id]
          ),
          responseEntities[id]
        ),
      }),
      {}
    ),
  };
};

const refresh = (entityState, { payload: { queryHash } }) => {
  if (queryHash)
    return R.mergeRight(entityState, {
      ...entityState,
      searchHistory: {
        ...entityState.searchHistory,
        [queryHash]: { keys: [] },
      },
    });
  return R.mergeRight(entityState, {
    ...entityState,
    keys: [],
  });
};
const reorder = (entityState, { payload: { newKeys } }) => {
  return R.mergeRight(entityState, {
    keys: newKeys,
  });
};

const updateLocalEntry = (entityState, { payload }) => {
  if (Array.isArray(payload)) {
    return R.reduce(
      (state, { id, data }) =>
        R.over(
          R.lensPath(['entities', id]),
          (entry) =>
            entry ? resetTargets(R.mergeDeepRight(entry, data), data) : entry,
          state
        ),
      entityState,
      payload
    );
  }
  const { id, data } = payload;

  if (!R.view(R.lensPath(['entities', id]), entityState)) return entityState;
  return R.over(
    R.lensPath(['entities', id]),
    (entry) => (entry ? R.mergeDeepLeft(data)(entry) : entry),
    entityState
  );
};
function isPlainObject(obj) {
  if (typeof obj !== 'object' || obj === null) return false;

  let proto = obj;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(obj) === proto;
}

const updateEntries = (entityState, action, related) => {
  const {
    requestType,
    payload = {},
    responseDetails = {},
    response = {},
    meta = {},
  } = action;
  const { tempId, queryHash } = payload;

  const entityKey = related || action.entityKey;

  const dataLens = queryHash
    ? R.lensPath(['searchHistory', queryHash])
    : R.lensPath([]);

  const responseEntries = response.entities[entityKey] || {};

  const updatedResponseEntries = R.compose(
    R.map((value) => {
      if (meta.sockets) {
        return R.omit([
          'uploadStatus',
          'uploadStatusText',
          'uploadStatusMessage',
          'uploadStatusHint',
          'uploadStatusCode',
          'previewStatus',
          'previewStatusText',
          'previewStatusMessage',
          'previewStatusHint',
          'previewStatusCode',
        ])(value);
      }
      return value;
    }),
    R.pickBy(({ updatedAt }, key) => {
      const { updatedAt: storeUpdatedAt } = entityState.entities[key] || {};

      if (
        updatedAt &&
        storeUpdatedAt &&
        new Date(updatedAt).getTime() < new Date(storeUpdatedAt).getTime()
      ) {
        // console.log(
        //   'hit outdated',
        //   key,
        //   entityKey,
        //   updatedAt,
        //   storeUpdatedAt,
        //   entry,
        //   storeEntry
        // );
        return false;
      }
      return true;
    })
  )(responseEntries);

  const newEntries = mergeEntries(
    entityState.entities,
    updatedResponseEntries,
    entityKey,
    action
  );

  const data = R.view(dataLens, entityState);

  const newItemsKeys =
    requestType === 'create' && !related
      ? generateKeysOnCreate(action, data)?.keys
      : entityState.newItemsKeys;

  const updateTempEntry =
    requestType === 'create' && tempId && response.result
      ? R.compose(
          R.over(
            R.lensPath(['entities', response.result]),
            R.set(R.lensPath(['tempId']), tempId)
          ),
          R.over(
            R.lensPath(['entities', tempId]),
            R.set(R.lensPath(['created']), response.result)
          )
        )
      : R.identity;

  return R.compose(
    updateTempEntry,
    R.set(R.lensPath(['entities']), newEntries),
    (value) => {
      if (related) return value;

      if (
        response.result &&
        (isPlainObject(response.result) || isPlainObject(response.result[0]))
      ) {
        const lens =
          payload.id || payload.componentKey
            ? R.lensPath(['indexedData', payload.componentKey || payload.id])
            : payload.queryHash
              ? R.lensPath(['searchHistory', payload.queryHash, 'data'])
              : R.lensPath(['data']);
        return R.set(
          lens,
          payload.entityKey === 'ordersOverview'
            ? response.result.data
            : response.result,
          value
        );
      }

      const newData =
        (requestType === 'update' && !payload.queryHash) || related
          ? data
          : requestType === 'create'
            ? generateKeysOnCreate(action, data)
            : mergeKeys({ payload, responseDetails, response, meta }, data);
      return R.set(dataLens, newData, value);
    },
    (value) =>
      related ? value : R.set(R.lensPath(['newItemsKeys']), newItemsKeys, value)
  )(entityState);
};

const createTempEntry = (entityState, action) => {
  const {
    payload: { tempId, data },
  } = action;
  if (R.findIndex(R.equals(tempId), entityState.keys) >= 0) return entityState;
  const addTemp = R.over(R.lensProp('keys'), R.append(tempId));
  const addTempKeys = R.over(R.lensProp('newItemsKeys'), R.prepend(tempId));
  const addTempData = R.set(R.lensPath(['entities', tempId]), {
    ...data,
    id: tempId,
    temporary: true,
  });
  return R.compose(addTempData, addTemp, addTempKeys)(entityState);
};

const markEntryDeleted = (entityState, { payload: { id, entityKey } }) => {
  if (entityKey !== 'userNotifications' && (id === undefined || id === null)) {
    return initState;
  }
  return R.compose(
    R.over(R.lensPath(['entities', id]), (entry) =>
      entityKey !== 'userNotifications'
        ? {
            ...entry,
            deletedAt: Date.now(),
            deletedFromApi: true,
          }
        : entry
    ),
    (value) =>
      entityKey === 'userNotifications'
        ? value
        : R.compose(
            R.over(R.lensProp('keys'), (keys) => deleteKey(id, keys)),
            R.over(R.lensProp('newItemsKeys'), (keys) => deleteKey(id, keys))
          )(value)
  )(entityState);
};

const removeTempEntry = (entityState, action) => {
  const {
    payload: { tempId },
  } = action;
  if (R.findIndex(R.equals(tempId), entityState.keys) < 0) return entityState;
  const deleteKeys = R.over(R.lensProp('keys'), (keys) =>
    deleteKey(tempId, keys)
  );
  const deleteNewItemsKeys = R.over(R.lensProp('newItemsKeys'), (keys) =>
    deleteKey(tempId, keys)
  );
  return R.compose(deleteKeys, deleteNewItemsKeys)(entityState);
};

const setFilters = (entityData, { responseDetails: { filtersData } }) => {
  if (!filtersData || !filtersData.entities?.filters) {
    return entityData;
  }
  return {
    ...entityData,
    filters: {
      keys: filtersData.result,
      entries: filtersData.entities.filters,
    },
  };
};

function mainEntityReducer(state, action) {
  if (!action.payload) return state;

  const { entityKey, payload, response, requestType, requestStatus } = action;
  const { id } = payload;
  const entityState = state[entityKey] || initState;

  const setEntityState = (updater) => {
    return {
      ...state,
      [entityKey]: updater(entityState, action),
    };
  };

  const updateEntriesFromResponse = () => {
    if (!response || !response.entities) return {};
    return Object.keys(response.entities).reduce(
      (acc, key) =>
        key
          ? {
              ...acc,
              [key]: updateEntries(state[key] || initState, action, key),
            }
          : acc,
      {}
    );
  };

  // Handle old generic refresh entity type
  if (action.type === REFRESH_ENTITY) {
    if (id) return state;
    return setEntityState(refresh);
  }

  if (!requestType) return state;

  switch (requestType) {
    case 'reorder':
      return setEntityState(reorder);

    case 'updateLocal':
      return setEntityState(updateLocalEntry);

    case 'update':
    case 'create':
    case 'fetch': {
      if (
        requestType === 'create' &&
        requestStatus === 'failure' &&
        payload?.tempId
      ) {
        return setEntityState(removeTempEntry);
      }
      if (
        requestType === 'create' &&
        requestStatus === 'request' &&
        payload?.tempId
      ) {
        return setEntityState(createTempEntry);
      }
      if (!response || !response.entities || requestStatus !== 'success')
        return state;
      return {
        ...state,
        ...updateEntriesFromResponse(),
        [entityKey]: setFilters(
          updateEntries(state[entityKey] || initState, action),
          action
        ),
      };
    }
    case 'delete': {
      if (requestStatus === 'success') {
        return {
          ...state,
          ...updateEntriesFromResponse(),
          ...setEntityState(markEntryDeleted),
        };
      }
      return state;
    }
    default:
      return state;
  }
}

const specificReducer = combineReducers(reducers);

function tempTracksReducer(state, action) {
  switch (action.type) {
    case entities.tracks.types.create.SUCCESS: {
      const { tempId, nestingPath } = action.payload;
      const { result } = action.response;
      if (!nestingPath) {
        return state;
      }

      const {
        entityId,
        wrapperEntity,
        entityIdKey,
        wrapperData = {},
      } = nestingPath;

      return R.compose(
        R.set(R.lensPath([wrapperEntity, 'entities', tempId]), {
          ...wrapperData,
          [entityIdKey]: entityId,
          id: result,
          trackId: result,
          temp: false,
          track: result,
        })
      )(state);
    }
    case entities.tracks.types.create.REQUEST: {
      const { tempId, nestingPath } = action.payload;
      if (!nestingPath) {
        return state;
      }
      const {
        entityId,
        wrapperEntity,
        entityIdKey,
        wrapperData = {},
      } = nestingPath;
      return R.compose(
        R.set(R.lensPath([wrapperEntity, 'entities', tempId]), {
          ...wrapperData,
          [entityIdKey]: entityId,
          id: tempId,
          trackId: tempId,
          temp: true,
          track: tempId,
        })
      )(state);
    }
    default:
      return state;
  }
}

function tempTrackEntityReducer(state, action) {
  const { nestingPath } = action?.payload || {};
  const entityUpdateFailedCase =
    (nestingPath &&
      entities[nestingPath.entity] &&
      entities[nestingPath.entity].types.update.FAILURE) ||
    '[[[not-supported-imdddata]]]';
  switch (action.type) {
    case entities.tracks.types.create.REQUEST: {
      const { tempId } = action.payload;
      if (!nestingPath) {
        return state;
      }
      return R.compose(
        R.over(
          R.lensPath([
            nestingPath.entity,
            'entities',
            nestingPath.entityId,
            ...nestingPath.path,
          ]),
          (wrapperIds) => {
            if (!wrapperIds) {
              return [tempId];
            }
            if (R.includes(tempId, wrapperIds)) return wrapperIds;

            return R.append(tempId)(wrapperIds);
          }
        )
      )(state);
    }
    case entityUpdateFailedCase:
    case entities.tracks.types.create.FAILURE: {
      const { tempId, tempTrackIds } = action.payload;
      const updateIds = R.over(
        R.lensPath([
          nestingPath.entity,
          'entities',
          nestingPath.entityId,
          ...nestingPath.path,
        ])
      );
      if (!nestingPath) {
        return state;
      }
      if (tempTrackIds) {
        return updateIds((wrapperIds) => {
          if (!wrapperIds) {
            return [];
          }
          const ids = tempTrackIds.map(R.prop('tempId'));
          return R.without(ids, wrapperIds);
        }, state);
      }
      return updateIds((wrapperIds) => {
        if (!wrapperIds) {
          return [];
        }
        if (R.includes(tempId, wrapperIds))
          return R.without([tempId], wrapperIds);
        return wrapperIds;
      }, state);
    }
    default:
      return state;
  }
}

export default function requestReducer(state = initialEntityState, action) {
  return R.compose(
    (result) => specificReducer(result, action),
    (result) => tempTracksReducer(result, action),
    (result) => tempTrackEntityReducer(result, action),
    (result) => mainEntityReducer(result, action)
  )(state);
}
