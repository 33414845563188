//
import React, { useMemo } from 'react';
import { useLanguages } from 'imddata';
import type { WrappedFieldProps } from 'redux-form';
import SelectField from '../SelectField';
import type { DropdownMenuBaseProps } from '../DropdownMenu';

const LanguageSelector = (
  props: DropdownMenuBaseProps<{ label: string; value: number }, false> & {
    valueOverride?: string;
    input: Partial<WrappedFieldProps['input']>;
    meta: Partial<WrappedFieldProps['meta']>;
  }
) => {
  const {
    entries,
    request: { loading },
  } = useLanguages();

  const data = useMemo(() => {
    if (entries) {
      return entries.map((language) => ({
        value: language.id,
        label: language.label,
      }));
    }

    return [];
  }, [entries]);
  return <SelectField {...props} isLoading={loading} data={data} />;
};

export default LanguageSelector;
