import { mergeDeepRight } from 'ramda';
import { types } from '../../actionTypes/ui/selection';

const initialState = {
  artists: [],
  contributors: [],
  tracks: [],
  publishers: [],
};

export type UiSelectionState = {
  artists: (number | string)[];
  contributors: (number | string)[];
  tracks: (number | string)[];
  publishers: (number | string)[];
};

// TODO type action
const selection = (state: UiSelectionState = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_SELECTION: {
      const { entity, selected } = action.payload;
      return mergeDeepRight(state, {
        [entity]: selected,
      });
    }
    case types.CLEAR_SELECTION: {
      return initialState;
    }

    default:
      return state;
  }
};

export default selection;
