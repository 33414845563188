//
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Caption, Clickable, ComponentIcons, IconButton, Content } from 'imdui';
import { css } from '@emotion/react';
import { createFilter } from 'react-select';
import { useDrop, useDrag } from 'react-dnd';
import DragHandler from '../../../DragHandler';
import DropdownMenu from '../../../../fields/DropdownMenu';
import type {
  JoinTagDataTag,
  JoinTagDataTagOption,
  JoinTagValue,
} from '../../types';

const iconStyles = css`
  align-self: center;
`;

const itemStyles = css(`
  border-radius: 0;
    display: inline;
    text-align: left;
    word-break: break-all
`);

const RowWrapper = styled.div`
  margin: 0 16px;
  display: flex;
  gap: 16px;
  width: 100%;
  border-bottom: 1px solid var(--outline-var);
`;

const ItemWrapper = styled.div`
  display: grid;
  flex: 1 1 240px;
  grid-template-columns: minmax(0, 1fr) max-content max-content;
`;

const DropdownMenuStyled = styled(DropdownMenu)`
  flex: 176px;
  flex-grow: 0;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0 !important;

  input {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    width: 176px;
  }
`;

const ItemContents = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px;
`;

const TextColumn = styled.div`
  flex: 1;

  > * {
    display: block;
  }

  > ${Content} {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    overflow: hidden;
    font-weight: 500;
  }
`;

export const JoinTagItemWrapper = styled.div<{
  invalid?: boolean;
  isOver: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 48px;
  background-color: var(--bg-3);
  overflow: hidden;
  ${({ invalid }) =>
    invalid
      ? `
${ItemWrapper} {
border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--state-negative, #F00);
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.03) 0%, rgba(255, 0, 0, 0.03) 100%), var(--surface-bg-3, #F4F4F4);
}

`
      : ''}

  > * {
    visibility: ${({ isOver }) => (isOver ? 'hidden' : 'initial')};
  }

  &:last-of-type {
    ${RowWrapper} {
      border-bottom: none;
    }
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: dashed 2px ${({ theme }) => theme.lights[2]};
    pointer-events: none;
    box-sizing: border-box;
    opacity: ${({ isOver }) => (isOver ? 1 : 0)};
  }
`;

const CustomWrapper: React.FC = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

const getValue = (dropdown: DropdownDataEntry, value: Record<string, any>) => {
  return dropdown.getValue
    ? dropdown.getValue(value[dropdown.key])
    : value[dropdown.key];
};

const baseFilter = createFilter({});

const filterDeprecated = (option: JoinTagDataTagOption, input: string) =>
  // TODO fix react-select incompat
  // @ts-ignore
  !option.data.deprecated && baseFilter(option, input);

export type DropdownDataEntry = {
  key: JoinTagDataTag['key'];
  label: JoinTagDataTag['label'];
  getValue?: JoinTagDataTag['getValue'];
  isDisabled?: JoinTagDataTag['isDisabled'];

  values: {
    keys: JoinTagDataTag['order'];
    entries: JoinTagDataTag['options'];
  };
};

type DragObject = {
  index: number;
  value: any;
};

const DRAG_TYPE = 'item';

export default function JoinTagItem({
  dropdownData,
  style,
  value,
  values,
  index,
  isDisabled,
  onChange,
  onClickDelete,
  onDropItem,
  onMoveItem,
  onClickItem,
  children,
  disabled,
  type,
}: {
  children?: React.ReactNode;
  type?: string;
  style?: React.CSSProperties;
  isOver?: boolean;
  onClickItem?: (e: unknown, v: JoinTagValue) => void;
  onClickDelete?: (i: number, v: JoinTagValue, e: unknown) => void;
  onChange?: (
    i: number,
    k: string,
    v: string,
    meta: Partial<JoinTagValue>
  ) => void;
  onMoveItem?: (d: { from: number; to: number; item: DragObject }) => void;
  onDropItem?: (d: { from: number; to: number }) => void;
  isDisabled?: (
    d: DropdownDataEntry,
    i: number,
    index: number,
    finalValue: string
  ) => boolean;
  index: number;
  values?: JoinTagValue[];
  dropdownData?: DropdownDataEntry[];
  disabled?: boolean;
  value: any;
}) {
  const [, dragHandler, dragPreview] = useDrag<DragObject, void, void>(
    () => ({
      type: DRAG_TYPE + type,
      item: {
        value,
        index,
      },
    }),
    [type, index, value]
  );
  const [{ isOver }, dropHander] = useDrop<
    DragObject,
    void,
    { isOver: boolean }
  >(
    () => ({
      accept: DRAG_TYPE + type,
      drop: (item, m) => {
        const hoverIndex = index;
        const dragIndex = item.index;

        if (dragIndex === hoverIndex) return;
        if (onDropItem && m.canDrop()) {
          onDropItem({ from: dragIndex, to: hoverIndex });
        }
      },
      hover: (item) => {
        const hoverIndex = value.id;
        const dragIndex = item.value.id;

        if (!onMoveItem || dragIndex === hoverIndex) return;

        onMoveItem({ from: dragIndex, to: hoverIndex, item });

        // eslint-disable-next-line no-param-reassign
        item.index = hoverIndex;
      },
      collect: (m) => ({ isOver: m.isOver() }),
    }),
    [onDropItem, onMoveItem]
  );

  const Wrapper = value.custom ? CustomWrapper : Clickable;

  const handler = useMemo<React.RefCallback<HTMLDivElement>>(
    () =>
      onMoveItem || onDropItem
        ? (el) => {
          dragPreview(el);
          dropHander(el);
        }
        : (el) => el,
    [onMoveItem, onDropItem]
  );
  return (
    <JoinTagItemWrapper
      style={style}
      key={index}
      data-test-id={`joinTagItem-${index}`}
      ref={handler}
      invalid={value.invalid}
      isOver={isOver}
    >
      <RowWrapper>
        {dropdownData?.map((dropdown, i) => {
          const finalValue = getValue(dropdown, value);

          const finalData = dropdown.values.keys.reduce<
            (JoinTagDataTagOption & { disabled?: boolean })[]
          >((acc, valueKey) => {
            const option = dropdown.values.entries[valueKey];

            return [
              ...acc,
              {
                ...option,
                icon:
                  option.icon && typeof option.icon === 'function'
                    ? option.icon({ value })
                    : option.icon,
                disabled:
                  dropdown.isDisabled && values
                    ? dropdown.isDisabled({ value, option, values })
                    : disabled,
              },
            ];
          }, []);

          return (
            // @ts-ignore
            <DropdownMenuStyled
              // @ts-ignore
              filterOption={filterDeprecated}
              key={dropdown.key || i}
              testId={dropdown.key}
              className={
                isDisabled && isDisabled(dropdown, i, index, finalValue)
                  ? 'disabled'
                  : ''
              }
              backgroundColor="transparent"
              // TODO fix dropdown def?
              data={finalData}
              value={finalValue}
              // @ts-ignore
              onChange={(
                newValue: JoinTagDataTagOption,
                option: JoinTagDataTagOption
              ) => {
                // @ts-ignore
                const { label, value: entryValue, ...meta } = option;
                if (onChange) {
                  // @ts-ignore
                  onChange(index, dropdown.key, newValue, meta);
                }
              }}
            />
          );
        })}

        <ItemWrapper>
          <Wrapper
            css={itemStyles}
            onClick={
              onClickItem
                ? (event) => {
                  event.stopPropagation();
                  if (onClickItem && !value.custom) {
                    onClickItem(event, value);
                  }
                }
                : undefined
            }
          >
            <ItemContents>
              <TextColumn>
                <Content>{value.label}</Content>

                {value.sublabel && <Caption>{value.sublabel}</Caption>}
              </TextColumn>
            </ItemContents>
          </Wrapper>
          {children}

          {onClickDelete && (
            <IconButton
              disabled={disabled}
              tabIndex={0}
              onClick={(e) => onClickDelete(index, value, e)}
              style={{
                width: '32px',
                height: '32px',
                marginRight: onMoveItem || onDropItem ? '0' : '4px',
              }}
              css={iconStyles}
            >
              <ComponentIcons.Remove />
            </IconButton>
          )}

          {(onMoveItem || onDropItem) && (
            <DragHandler
              ref={disabled ? null : (el) => dragHandler(el)}
              // @ts-ignore
              css={iconStyles}
            />
          )}
        </ItemWrapper>
      </RowWrapper>
    </JoinTagItemWrapper>
  );
}
