//
import { reduceWithQuery } from '../../../../reducerFactories';
import { entities as entitiesTypes } from '../../../../actionTypes';

const initialState = {
  summary: {},
  lastStatement: {},
  availableStatements: [],
  statements: {
    data: {
      keys: [],
      entities: {},
    },
  },
  releases: {
    searchHistory: {},
  },
  tracks: {
    searchHistory: {},
  },
};

function salesReducer(state, action) {
  switch (action.type) {
    case entitiesTypes.releases.types.fetch.SUCCESS: {
      const {
        payload: { queryHash },
      } = action;
      if (!action.response || !action.response.sales) return state;
      return {
        ...state,
        releases: reduceWithQuery(
          state.releases,
          queryHash,
          action.response.sales
        ),
      };
    }
    case entitiesTypes.tracks.types.fetch.SUCCESS: {
      const {
        response,
        payload: { queryHash },
      } = action;
      if (!response || !response.sales) return state;
      return {
        ...state,
        tracks: reduceWithQuery(state.tracks, queryHash, response.sales),
      };
    }
    default:
      return state;
  }
}

export default function logs(state = initialState, action) {
  return [salesReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
