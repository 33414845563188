import styled from '@emotion/styled';

export const NavListHeader = styled.div`
  position: sticky 16px;
  display: flex;
  background-color: #fafafa;
  box-shadow: inset 0 -1px 0 0 #dddddd;
  padding: 0 16px;
  margin-bottom: 8px;
`;
