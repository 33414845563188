import type { CustomerSubscriptionUpgrade, EntityModels, Nil } from 'imddata';
import {
  useCurrentCustomerSubscription,
  useCreateOrder,
  useUpdateEntity,
  useCustomerPrices,
  useCurrentCustomer,
  useCustomerSubscriptionState,
  useCreateEntity,
  useEntries,
} from 'imddata';
import { useDispatch } from 'react-redux';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApplicationSettingsContext } from 'imdshared';
import type { SubscriptionId } from 'imddata/types/entities';
import { useFeature } from 'imdfeature';

const subscriptionIds: string[] = [
  'professional-unlimited',
  'music-analytics',
  'professional-unlimited-analytics',
];

const getPendingInvoice = (
  errorData?: Record<string, unknown>
): number | undefined => {
  return errorData?.invoiceId &&
    Array.isArray(errorData.invoiceId) &&
    errorData.invoiceId.length > 0 &&
    typeof errorData.invoiceId[0] === 'number'
    ? errorData.invoiceId[0]
    : undefined;
};

export const getBaseSubscriptionId = (sid: SubscriptionId | 'free') => {
  switch (sid) {
    case 'tier-1-yearly':
    case 'tier-1-monthly':
    case 'tier-1':
      return 'tier-1';
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
      return 'tier-2';
    case 'tier-3':
      return 'tier-3';
    case 'trial-tier-2':
    case 'trial-monthly':
    case 'trial-yearly':
      return 'trial';
    default:
      return sid;
  }
};

export const getSubscriptionCadence = (
  sid: SubscriptionId
): 'yearly' | 'monthly' => {
  switch (sid) {
    case 'music-analytics':
    case 'tier-1-monthly':
    case 'trial-monthly':
    case 'tier-2-monthly':
      return 'monthly';

    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
    case 'tier-1':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'tier-3':
    case 'trial-tier-2':
    case 'trial-yearly':
    case 'tier-1-yearly':
    case 'tier-2-yearly':
    case 'vip':
      return 'yearly';
  }
};

type SubmitData =
  | { mode: 'paid'; orderId: string | number; subscriptionId: string }
  | { mode: 'paid_pro_rated'; orderId: string | number; subscriptionId: string }
  | {
    mode: 'paid_discounted';
    orderId: string | number;
    subscriptionId: string;
  }
  | { mode: 'instant'; subscriptionId: string }
  | { mode: 'instant_upgrade'; subscriptionId: string }
  | { mode: 'legacy_upgrade'; subscriptionId: string }
  | { mode: 'renew_to_different'; subscriptionId: string }
  | { mode: 'cancel' }
  | { mode: 'downgrade'; subscriptionId: string };

type Prices = Record<
  SubscriptionId | 'free',
  | {
    cadence: 'yearly' | 'monthly';
    price: string;
    originalPrice: string | null;
    priceFormatted: string;
    value: number;
  }
  | undefined
>;
export type SubscriptionSubmitHandler = (d: SubmitData) => void;

export const useSubscriptionManagement = ({
  onSubmit,
}: {
  onSubmit?: SubscriptionSubmitHandler;
} = {}) => {
  const currentSubscription = useCurrentCustomerSubscription();
  const dispatch = useDispatch();
  const { entry: customer } = useCurrentCustomer();
  const {
    data: subscriptionsData,
    refresh,
    request: { loading: stateLoading },
  } = useCustomerSubscriptionState();
  const { entries: subscriptions } = useEntries<EntityModels.Subscription>({
    entity: 'subscriptions',
  });

  const {
    createEntry: createStateAction,
    request: { created: upgraded, creating: upgrading },
  } = useCreateEntity({
    entity: 'customerSubscriptionStateAction',
  });

  useEffect(() => {
    refresh();
    dispatch(loadLoggedUser());
  }, [upgraded]);

  const {
    data: dataPrices,
    request: { loaded: priceLoaded },
  } = useCustomerPrices();

  const pricePlan =
    dataPrices?.result?.forcedPricePlan || dataPrices?.result?.defaultPricePlan;

  const [selected, setSelected] = useState<
    | {
      subscriptionId: EntityModels.SubscriptionId;
      mode: CustomerSubscriptionUpgrade['mode'];
    }
    | { mode: 'clear_renew_to'; subscriptionId: Nil }
  >();

  const { t } = useTranslation();
  const history = useHistory();

  const {
    createOrder,
    request: { created, id: orderId, creating, errorData },
  } = useCreateOrder({ entity: 'subscriptions' });

  useEffect(() => {
    if (!currentSubscription) {
      if (selected) {
        createOrder({ id: selected.subscriptionId });
      }
    }
    if (currentSubscription && subscriptionsData && selected) {
      switch (selected.mode) {
        case 'paid_discounted':
        case 'paid_pro_rated':
        case 'paid':
          createOrder({ id: selected.subscriptionId });
          break;
        case 'instant':
        case 'instant_upgrade':
        case 'legacy_upgrade':
        case 'renew_to_different':
        case 'downgrade':
        case 'clear_renew_to':
          createStateAction({
            data: {
              action: 'upgrade',
              subscriptionId: selected.subscriptionId,
            },
          });
      }
    }
  }, [selected, !!subscriptionsData]);

  const { numberFormatLocale } = useContext(ApplicationSettingsContext);

  const subsWithPrices = useMemo(() => {
    const subs = subscriptionsData?.options
      .sort(
        (a, b) =>
          subscriptionIds.indexOf(a.subscriptionId) -
          subscriptionIds.indexOf(b.subscriptionId)
      )
      .map((s) => {
        const yearlySub = subscriptionsData.options.find(
          (s2) =>
            s2.subscriptionId ===
            `${getBaseSubscriptionId(s.subscriptionId)}-yearly`
        );
        const monthlySub = subscriptionsData.options.find(
          (s2) =>
            s2.subscriptionId ===
            `${getBaseSubscriptionId(s.subscriptionId)}-monthly` ||
            s2.subscriptionId === `${getBaseSubscriptionId(s.subscriptionId)}`
        );

        return {
          id: getBaseSubscriptionId(s.subscriptionId),
          subscriptions: [
            ...(yearlySub ? [yearlySub] : []),
            ...(monthlySub ? [monthlySub] : []),
          ],
        };
      });
    return subs?.filter((obj, index, arr) => {
      return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === index;
    });
  }, [numberFormatLocale, dataPrices, priceLoaded, t, subscriptionsData]);

  const pendingInvoiceId = getPendingInvoice(errorData);

  useEffect(() => {
    if (created && onSubmit) {
      onSubmit({
        mode: 'paid',
        orderId,
        subscriptionId: selected?.subscriptionId as string,
      });
      return;
    }
    if (created || (!!errorData?.orderId && !pendingInvoiceId)) {
      history.push('/order/overview');
    }
  }, [created, errorData, pendingInvoiceId]);

  useEffect(() => {
    if (upgraded) {
      if (onSubmit && selected) {
        switch (selected.mode) {
          case 'clear_renew_to':
          case 'paid_discounted':
          case 'paid_pro_rated':
          case 'paid':
            break;
          case 'instant':
          case 'instant_upgrade':
          case 'legacy_upgrade':
          case 'renew_to_different':
          case 'downgrade':
            onSubmit({
              mode: selected.mode,
              subscriptionId: selected?.subscriptionId as string,
            });
        }
      } else {
        history.push('/account/subscriptions');
      }
    }
  }, [upgraded]);

  const {
    updateEntry,
    request: { updating },
  } = useUpdateEntity({
    entity: 'customers',
    id: customer?.id,
  });

  const toggleRenewal = useCallback(() => {
    updateEntry({
      data: {
        renewSubscriptions: !customer?.renewSubscriptions,
      },
    });
  }, [customer?.renewSubscriptions]);

  const [promoEnabled] = useFeature({ featureKey: 'promo-subs' });

  const prices: Prices | undefined = useMemo(() => {
    const subscriptionPrices =
      dataPrices?.entities?.products.subscriptions.items;

    if (!subscriptionPrices || !subscriptions) return undefined;

    console.log(dataPrices);
    return subscriptionPrices.reduce<Prices>((acc, subId) => {
      const { duration: recurringPace } = subscriptions.find(
        (s) => s.id === subId
      ) || { duration: 1 };

      const { price, priceCurrency } = dataPrices.entities?.productItems?.[
        subId
      ]?.price
        ? dataPrices.entities.productItems[subId].price
        : {
          price: null,
          priceCurrency: 'eur',
        };

      const regularPrice =
        dataPrices.entities?.productItems?.[subId]?.price?.regular;

      const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: 'currency',
        currency: priceCurrency,
      });

      const priceFormatted = priceFormatter.format(
        Number(regularPrice ? regularPrice.value : price)
      );

      const cadence = recurringPace === 12 ? 'year' : 'month';
      return {
        ...acc,
        free: {
          cadence: 'yearly',
          value: 0,
          price: priceFormatter.format(0),
          originalPrice: null,
          priceFormatted: t('subscription-price-with-cadence', {
            defaultValue: '{{price}} per {{cadence}}',
            price: priceFormatter.format(0),
            context: '',
            cadence: '',
            promoPrice: '',
          }),
        },
        [subId]: {
          cadence: `${cadence}ly`,
          value: Number(price),
          originalPrice: regularPrice
            ? priceFormatter.format(Number(regularPrice.value))
            : null,
          price: priceFormatter.format(Number(price)),
          priceFormatted: t('subscription-price-with-cadence', {
            defaultValue: '{{price}} per {{cadence}}',
            price: priceFormatted,
            context: cadence,
            cadence: t(cadence),
          }),
        },
      };
    }, {} as Prices);
  }, [
    subscriptions,
    dataPrices,
    numberFormatLocale,
    currentSubscription,
    promoEnabled,
    pricePlan,
  ]);

  return {
    currentSubscription,
    prices,
    subscriptionOptions: subscriptionsData?.options,
    grouppedSubscriptions: subsWithPrices,
    pendingInvoiceId,
    renewalEnabled: customer?.renewSubscriptions,
    toggleRenewal,
    updatingRenewal: updating,
    ready: priceLoaded && !stateLoading,
    ordering: creating || upgrading,
    selectedSubscription: selected,
    selectSubscription: setSelected,
  };
};
