import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { callAuthApi } from 'imddata';
import { setAuth } from 'imddata/utils/auth';
import { useLocation, useParams } from 'react-router-dom';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { LoadingIndicator, Centered } from 'imdshared';
import { FormWrapper } from '../../components';
import { useSignupCodes } from '../../shared';

type State = { affiliatePartnerToken?: string; referralToken?: string };

export default function SignupSocial() {
  const { driver } = useParams<{ driver: string }>();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const codes = useSignupCodes();
  const dispatch = useDispatch();
  useEffect(() => {
    const query = camelizeKeys(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    ) as { state?: string; code: string };
    const state = camelizeKeys(
      query.state ? JSON.parse(query.state) : {}
    ) as State;
    callAuthApi
      .signupSocial({
        provider: driver,
        code: query.code,
        affiliatePartnerToken: state?.affiliatePartnerToken || codes.affiliate,
        referralToken: state?.referralToken || codes.referral,
        state,
      })
      .then((result) => {
        if ('response' in result) {
          setAuth(result.response as any);
          dispatch(loadLoggedUser({ signup: false }));
        }
        if ('error' in result) {
          setErrorMessage(result.error.message);
        }
      });
  }, []);

  return !errorMessage ? (
    <Centered style={{ width: '100%', height: '100vh' }}>
      <LoadingIndicator size="large" />
    </Centered>
  ) : (
    <FormWrapper loggedIn={false}>
      <div style={{ padding: '20px' }}>{errorMessage}</div>
    </FormWrapper>
  );
}
