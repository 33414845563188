export const i18nLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt'];

const prefix =
  process.env.TARGET_ENV !== 'production' && process.env.TARGET_ENV
    ? `(${process.env.TARGET_ENV.toUpperCase()}): `
    : '';

const imdfrontMeta = {
  title: 'imdfront',
  description: '',
  head: {
    titleTemplate: `${prefix}%s`,
  },
};

const imdadminMeta = {
  title: 'imdadmin',
  description: '',
  head: {
    titleTemplate: `${prefix}%s`,
    meta: [
      { name: 'description', content: '' },
      { charset: 'utf-8' },
      { property: 'og:site_name', content: 'imdadmin' },
      { property: 'og:locale', content: 'en-US' },
      { property: 'og:title', content: 'imdadmin' },
      { property: 'og:description', content: '' },
      { property: 'og:card', content: 'summary' },
      { property: 'og:image:width', content: '500' },
      { property: 'og:image:height', content: '500' },
    ],
  },
};

const helpscoutTokens = {
  pt: '149cdadba4c991c2',
  br: '149cdadba4c991c2',
  de: '4685fdf445d7e694',
  es: '3d7d46b4147d61cf',
  fr: '06c31d15e1e04fb4',
  it: 'a61de70a4ccc19f2',
  en: '1fa5240ebc0e5b51',
  delivery: 'ea66b786750bb61f',
  legal: '8eb6f4ae5f0471c2',
};

const beaconKeys = {
  pt: '0bd208f0-7597-4a53-b5e5-49cc7b5c0543',
  de: '89f03d94-7e60-49a4-a52d-4bd5fc36c932',
  es: '8247d8c3-c306-4b91-9273-791a88711b1c',
  fr: '98a6e9ed-e867-4532-9095-1ebf34a53d0d',
  it: 'e06a5d4c-f258-4593-80a1-3ea4d54d9cc6',
  en: 'd7a244ac-0431-46ee-9555-024985191a62',
};

// const dummyHelpscout = {
//   pt: 'dummy',
//   br: 'dummy',
//   de: 'dummy',
//   es: 'dummy',
//   fr: 'dummy',
//   it: 'dummy',
//   en: 'dummy',
//   delivery: 'dummy-delivery',
// };
//
const config = {
  imdfront: {
    app: imdfrontMeta,
    appUrl: process.env.CUSTOMER_APP_URL,
    publicPaymentKey: process.env.ADYEN_ORIGIN_KEY,
  },
  imdadmin: {
    app: imdadminMeta,
    appUrl: process.env.ADMIN_APP_URL,
  },
  releaseTemplateBaseUrl:
    process.env.RELEASE_TEMPLATE_BASE_URL ||
    'https://music.imusician.pro/a/assets/release-page-template-v2',
  paymentEnv: process.env.TARGET_ENV === 'production' ? 'live' : 'test',
  beaconKeys,
  helpscoutTokens: helpscoutTokens,
  assetsUrl: process.env.ASSETS_URL,
  contentUploads: process.env.CONTENT_UPLOADS,
  featureFlagKey: process.env.OPTIMIZELY_KEY,
  apiUrl: process.env.API_URL,
  socketUrl: process.env.SOCKET_URL,
  socketPath: process.env.SOCKET_PATH,
  smsUrl: process.env.SMS_URL,
  nisUrl: process.env.NIS_URL,
  kratosUrl: process.env.KRATOS_URL,
  shortLinksUrl: process.env.SLS_URL,
  coverArtworkServiceUrl: process.env.CAS_URL,
};

export default config;
