import React, { forwardRef } from 'react';
import createInputWithStyles from './createInputWithStyles';

const InputWithStyles = createInputWithStyles('input');

type InputProps = React.ComponentPropsWithoutRef<'input'> & {
  testId?: string;
  multiline?: boolean;
  status?: 'warning' | 'error';
  rows?: number;
};

const BaseInput = forwardRef<HTMLInputElement, InputProps>(
  ({ testId, multiline, rows = 4, className, disabled, ...props }, ref) => {
    return (
      <InputWithStyles
        // @ts-ignore
        ref={ref}
        data-test-id={testId}
        as={multiline ? 'textarea' : undefined}
        rows={rows}
        disabled={disabled}
        className={className}
        {...props}
      />
    );
  }
);

BaseInput.displayName = 'BaseInputWithRef';

export default BaseInput;
