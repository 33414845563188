//
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { generateIdLens } from '../base';

const nameObjLens = (languageId) => R.lensPath(['names', languageId]);

export const mapEntries = createSelector(
  (entries) => R.identity(entries),
  (entries, keys) => R.identity(keys),
  (entries, keys) => keys.map((key) => entries[key])
);

export const getTranslatableNameObj = (entry, languageId) => {
  const nameObj = R.view(nameObjLens(languageId), entry);
  if (R.isNil(nameObj)) {
    return null;
  }
  return nameObj;
};

export const getTranslatableNameFallbackObj = (entry) => {
  if (!entry?.names) return null;
  if (entry.names.default || entry.defaultName)
    return entry.names.default || entry.defaultName;

  const fallbackLanguageId = Object.keys(entry.names)[0];

  if (R.isNil(fallbackLanguageId)) {
    return null;
  }
  const fallback = R.view(nameObjLens(fallbackLanguageId), entry);
  return fallback;
};

export const getTranslatableName = (entry, languageId, key = 'name') => {
  if (!entry) return '';
  // if (languageId) {
  //
  //   const nameObj = getTranslatableNameObj(entry, languageId);
  //
  //   if (!R.isNil(nameObj)) {
  //     return nameObj[key];
  //   }
  // }
  if (entry && entry.temporary) {
    return '';
  }

  const fallback =
    entry.defaultNameNormalized && entry.defaultNameNormalized[key]
      ? entry.defaultNameNormalized
      : getTranslatableNameFallbackObj(entry);

  return fallback ? fallback[key] : '';
};

export const getName = (entry, languageId) => {
  if (!entry) return '';
  return entry.names
    ? getTranslatableName(entry, languageId, 'name')
    : entry.name;
};

export const getRequestDataLens = (storeKey, id) =>
  R.compose(R.lensPath([storeKey]), generateIdLens(id));

export const selectRequestData = (key) => (state, id) => {
  return R.view(getRequestDataLens(key, id), state.requests.entities);
};

export const selectEntityData = (entity) => (state, selectedEntityId) => {
  const entityLens = Array.isArray(entity)
    ? R.lensPath(['entities', ...entity])
    : R.lensPath(['entities', entity, 'entities']);

  if (R.isNil(selectedEntityId)) {
    return R.view(entityLens, state);
  }
  if (selectedEntityId !== null && selectedEntityId !== undefined) {
    if (Array.isArray(selectedEntityId)) {
      return R.view(R.compose(entityLens, R.lensPath(selectedEntityId)), state);
    }
    return R.view(R.compose(entityLens, R.lensPath([selectedEntityId])), state);
  }
  return undefined;
};
