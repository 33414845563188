import { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../types';
import { useCreateEntity, useEntryProvider } from '../base';
import { useEntry, useEntries } from '../hooks';
import { useQuery } from '../useQueryHash';
import { defaultInstantMasteringNest, InstantMasteringNested } from './nests';

const defaultInstantMasteringQuery = { with: 'tracks' };

export function useInstantMastering({
  nest = defaultInstantMasteringNest,
  query = defaultInstantMasteringQuery,
  ...props
}) {
  return useEntry<InstantMasteringNested>({
    entity: 'instantMasterings',
    query,
    nest,
    ...props,
  });
}

export const useInstantMasteringTrackIds = (id: number | string) => {
  const bundle = useEntryProvider<InstantMasteringNested>({
    id,
    entity: 'instantMasterings',
    nest: defaultInstantMasteringNest,
  });

  return useMemo(
    () => bundle?.tracks?.map(({ trackId }) => trackId) || [],
    [bundle?.tracks]
  );
};

const defaultInProgressMasteringsQuery = {
  ...defaultInstantMasteringQuery,
  perPage: 5,
  'filter.status': 'pending,previewing,paid',
};

export function useInProgressInstantMasterings({
  nest = defaultInstantMasteringNest,
  query = defaultInProgressMasteringsQuery,
  ...props
} = {}) {
  const { queryHash } = useQuery({ query });
  const result = useEntries<InstantMasteringNested>({
    entity: 'instantMasterings',
    query,
    queryHash,
    nest,
    ...props,
  });

  useEffect(() => {
    result.refresh();
  }, []);

  return result;
}

export function useInstantMasteringRestart({ id }: { id: number }) {
  const canBeRestarted = useSelector((state: ReduxState) => {
    const track = state.entities.instantMasteringTracks.entities[id];
    return track && track.status === 'failed';
  });
  const { createEntry, requestStoreKey, request } = useCreateEntity({
    entity: 'instantMasteringTrackActions',
  });

  const restart = useCallback(
    (action, meta) => {
      createEntry(
        {
          id,
          data: { action: 'restart' },
        },
        meta
      );
    },
    [id, createEntry]
  );

  return {
    canBeRestarted,
    restart,
    request,
    requestStoreKey,
  };
}
