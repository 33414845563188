import React from 'react';
import { Body, palette } from 'imdshared';
import type { EntityModels } from 'imddata';

const InvoiceStatus: React.FC<{ status: EntityModels.Invoice['status'] }> = ({
  status,
  children,
}) => {
  let statusColor;

  if (status === 'paid') {
    statusColor = palette.main.normal;
  } else if (status === 'canceled') {
    statusColor = palette.error;
  }
  return <Body style={{ color: statusColor }}>{children}</Body>;
};
export default InvoiceStatus;
