import React from 'react';
import ReactDOM from 'react-dom';
import createStore from 'redux/createStore';
import rootSaga from 'redux/sagas';
import * as smoothscroll from 'smoothscroll-polyfill';
import { AppLogicProvider } from 'imdshared';
import initI18n from 'imdshared/src/i18n';
import App from './screens/App';

initI18n();
smoothscroll.polyfill();

const { store, sagaMiddleware, persistor } = createStore();
sagaMiddleware.run(rootSaga);

// oldListenters(store);

const disableEmotionWarnings = () => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  /* eslint-disable no-console */
  const log = console.error.bind(console);
  console.error = (...args) => {
    /* eslint-enable no-console */
    if (
      args.indexOf('The pseudo class') &&
      args.indexOf(
        'is potentially unsafe when doing server-side rendering. Try changing it to'
      )
    ) {
      return;
    }
    log(...args);
  };
};
disableEmotionWarnings();
const rootEl = document.getElementById('app');
// eslint-disable-next-line
ReactDOM.render(
  <AppLogicProvider store={store} persistor={persistor}>
    <App />
  </AppLogicProvider>,
  rootEl
);
