import { createContext } from 'react';

export const ImpersonateContext = createContext<
  { customerId: null; userId: null } | { customerId: number; userId: number }
>({
  customerId: null,
  userId: null,
});

ImpersonateContext.displayName = 'ImpersonateContext';
