import React from 'react';
import { useTranslation } from 'react-i18next';
import { BadCaseTemplate } from 'components';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BadCaseTemplate
      title={t('not-found-title')}
      description={t('not-found-description')}
      imageSrc="/images/badcase/404.svg"
    />
  );
};

export default NotFound;
