import { keyframes, css } from '@emotion/react';

const keyframesPulse = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0.35 }
  100% { opacity: 1 }
`;

export const pulsateLoadingAnimation = css`
  animation-duration: 1.5s;
  animation-name: ${keyframesPulse};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;
