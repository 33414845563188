import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import {
  INVALIDATE_AUTH,
  constants as userConstants,
} from 'imddata/actionTypes/auth';
import { persistReducer } from 'redux-persist';
import { entities as entitiesTypes } from 'imddata/actionTypes';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import type { EntityModels, ReduxState as BaseReduxState } from 'imddata';
import { reducers } from 'imddata';
import type { ReduxUiState } from 'redux/reducers/ui';
import ui from 'redux/reducers/ui';

import { types as impersonateConstants } from 'redux/actions/ui/impersonate';

export type ReduxState = EntityModels.Nest<
  BaseReduxState,
  { ui: ReduxUiState }
>;

const persistConfig = {
  key: 'dev',
  storage,
  whitelist: ['features'],
};

const devPersisted = persistReducer(persistConfig, reducers.dev);

const {
  users: {
    types: { update: updateUsersType },
  },
} = entitiesTypes;

const createRootReducer = () =>
  combineReducers({
    dev: devPersisted,
    form: formReducer,
    requests: combineReducers({ entities: reducers.requests }),
    entities: reducers.entities,
    auth: reducers.auth,
    ui,
  });

// @ts-ignore
export default (history) => {
  // @ts-ignore
  const rootReducer = createRootReducer(history);
  // @ts-ignore
  return (state, action) => {
    if (action.type === updateUsersType.SUCCESS) {
      // @ts-ignore
      const { rawResponse: { item } = {} } = action;
      const locale = state.auth && state.auth.data && state.auth.data.locale;
      if (locale !== item.locale) {
        return rootReducer(
          {
            ...state,
            requests: undefined,
          },
          action
        );
      }
      return rootReducer(state, action);
    }
    if (action.type === impersonateConstants.SET_IMPERSONATION) {
      return rootReducer(
        {
          ...state,
          form: undefined,
          requests: undefined,
          entities: undefined,
        },
        action
      );
    }
    if (
      action.type === userConstants.RESET_LOGGED_USER ||
      action.type === INVALIDATE_AUTH
    ) {
      // pass undefined as a previous state so all reducers will be reinitialized
      return rootReducer(
        {
          ...state,
          form: undefined,
          requests: undefined,
          entities: undefined,
          auth: undefined,
        },
        action
      );
    }

    return rootReducer(state, action);
  };
};
