//
import React from 'react';
import styled from '@emotion/styled';
import TableRow from '../TableRow';

const TableContentWrapper = styled.tbody``;

const TableContent = ({ items, fields, children, onClickRow }) => (
  <TableContentWrapper>
    {children ||
      (items
        ? items.map((item, index) => (
            <TableRow
              key={item.id}
              index={index}
              borderBottom={index !== items.length - 1}
              item={item}
              fields={fields}
              onClick={onClickRow}
            />
          ))
        : null)}
  </TableContentWrapper>
);

export default TableContent;
