import styled from '@emotion/styled';

export const StepDivider = styled.div`
  min-width: 5px;
  height: 1px;
  align-self: center;
  display: none;
  @media (min-width: 1200px) {
    display: block;
    min-width: 20px;
    flex: 40px;
    flex-grow: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    min-width: 40px;
  }
`;

export const NumberWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`;
