//
import { camelize } from 'humps';
import { types } from '../../../../actionTypes/prices';

const initialState = {
  data: {},
};

function pricesCustomerReducer(state, action) {
  switch (action.type) {
    case types.pricesCustomer.SUCCESS:
      return {
        ...state,
        data: {
          ...action.response,
          result: {
            ...action.response.result,
            defaultPricePlan: camelize(action.response.result.defaultPricePlan),
            forcedPricePlan: camelize(action.response.result.forcedPricePlan),
          },
        },
      };
    default:
      return state;
  }
}

export default function logs(state = initialState, action) {
  return [pricesCustomerReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
