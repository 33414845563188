/* eslint-disable jsx-a11y/click-events-have-key-events */
//
import type { MouseEventHandler } from 'react';
import React, { useState, useMemo, useRef } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import styled from '@emotion/styled';
import { DOMNodeContext } from 'imdui';
import type { ReduxState } from 'imddata';
import type { BaseProps } from '../AudioPlayer';
import DefaultAudioPlayer from '../AudioPlayer';
import DropObserver from '../DropObserver';
import { LayoutContext, NavDrawerContext } from './contexts';
import { useBreakpoints, useNavDrawerToggler } from './hooks';

const DropObserverStyled = styled(DropObserver)`
  width: 100%;
`;
const Scrim = styled.div`
  z-index: 100;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(229, 229, 229, 0.5);
  transition: opacity 0.2s ease-in-out;
`;

const NavDrawerWrapper = styled.div<{ isLayoutCollapsed: boolean }>`
  z-index: ${({ isLayoutCollapsed }) => (isLayoutCollapsed ? '1000' : '99')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  transition: transform 0.1s cubic-bezier(0.23, 1, 0.32, 1);
`;

const PlayerWrapper = styled.div`
  z-index: 99;
  position: fixed;
  display: flex;
  left: 0;
  right: 0;
`;

const SideWindowNode = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const TopBarWrapper = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
`;

const AppRefreshWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
`;

const ContentWrapper = styled.div`
  container-name: template-sizer;
  container-type: normal;
  position: relative;
`;

const { FILE } = NativeTypes;

const accepts = [FILE];

type Props = {
  banner?: React.ReactNode;
  components: {
    NavDrawer?: React.FC<Record<string, never>>;
    TopBar?: React.FC<Record<string, never>>;
    AudioPlayer?: React.FC<BaseProps>;
  };
  onContentClick?: MouseEventHandler<HTMLDivElement>;
  playerPosition?: 'bottom' | 'top';
  isCollapsible?: boolean;
  children: React.ReactNode;
};

const ScreenLayout = ({
  playerPosition = 'bottom',
  components: { NavDrawer, TopBar, AudioPlayer = DefaultAudioPlayer } = {},
  onContentClick,
  children,
  banner,
  isCollapsible = true,
}: Props) => {
  const isPlayerOpen = useSelector((state: ReduxState) => {
    return (
      state.ui.player &&
      state.ui.player.playState &&
      state.ui.player.playState !== 'stop'
    );
  });

  const contentNode = useRef();
  const dropdownNode = useRef();
  const sideWindowNode = useRef();

  const [bottomConstraint] = useState(0);

  const contextPropsDomNode = useMemo(
    () => ({
      contentNode,
      dropdownNode,
      sideWindowNode,
    }),
    [contentNode, dropdownNode, sideWindowNode]
  );

  const breakpointsFit = useBreakpoints();

  const { height: bannerHeight = 0, ref: bannerRef } = useResizeDetector({
    handleWidth: false,
  });

  const { openNavDrawer, hideNavDrawer, closeNavDrawer, isDrawerOpen } =
    useNavDrawerToggler(isCollapsible ? breakpointsFit.medium : false);

  const contextPropsLayout = useMemo(() => {
    const isLayoutCollapsed =
      !NavDrawer || (isCollapsible ? !breakpointsFit.medium : false);

    return {
      isLayoutCollapsed,
      isDrawerOpen,
      breakpointsFit,

      appRefreshZoneHeight: bannerHeight,
      safeConstraints: {
        top:
          (isPlayerOpen && playerPosition === 'top' ? 80 : 0) +
          (isLayoutCollapsed && TopBar ? 64 : 0) +
          bannerHeight,
        bottom:
          (playerPosition === 'bottom' && isPlayerOpen ? 80 : 0) +
          (breakpointsFit.small ? bottomConstraint : 0),
        left: !isLayoutCollapsed && isDrawerOpen ? 216 : 0,
        right: 0,
      },
    };
  }, [
    isDrawerOpen,
    bottomConstraint,
    isCollapsible,
    breakpointsFit,
    isPlayerOpen,
    playerPosition,
    bannerHeight,
  ]);

  const { isLayoutCollapsed } = contextPropsLayout;

  const contextPropsNavDrawer = useMemo(
    () => ({
      hide: hideNavDrawer,
      isDrawerOpen,
      isCollapsible,
      open: openNavDrawer,
      close: closeNavDrawer,
    }),
    [closeNavDrawer, openNavDrawer, isDrawerOpen, isCollapsible]
  );

  const navDrawerStyles = useMemo(
    () => ({
      top: bannerHeight,
      transform: !isDrawerOpen ? 'translateX(-216px)' : undefined,
      boxShadow: !isDrawerOpen ? 'none' : undefined,
    }),
    [isDrawerOpen, bannerHeight]
  );

  // const onNavBarResize = useCallback(
  //   (width, height) => {
  //     setBottomConstraint(height);
  //   },
  //   [setBottomConstraint]
  // );

  // const app = useContext(AppContext);
  // const isAdmin = app === 'admin';

  return (
    <DropObserverStyled accepts={accepts}>
      <NavDrawerContext.Provider value={contextPropsNavDrawer}>
        <LayoutContext.Provider value={contextPropsLayout}>
          <DOMNodeContext.Provider
            // @ts-ignore
            value={contextPropsDomNode}
          >
            <>
              <div
                // @ts-ignore
                ref={dropdownNode}
              />

              <ContentWrapper
                onClick={onContentClick}
                // @ts-ignore
                ref={contentNode}
                style={{
                  paddingBottom:
                    isPlayerOpen && playerPosition === 'bottom' ? '64px' : 0,
                  paddingTop:
                    bannerHeight + (isLayoutCollapsed && TopBar ? 64 : 0),
                  marginLeft:
                    isDrawerOpen && !isLayoutCollapsed && NavDrawer ? 216 : 0,
                }}
              >
                {children}

                <AppRefreshWrapper
                  // @ts-ignore
                  ref={bannerRef}
                >
                  {banner}
                </AppRefreshWrapper>

                {TopBar && isLayoutCollapsed && (
                  <TopBarWrapper
                    style={{
                      top: bannerHeight,
                    }}
                  >
                    <TopBar />
                  </TopBarWrapper>
                )}

                {AudioPlayer && (
                  <PlayerWrapper
                    style={{
                      pointerEvents: isPlayerOpen ? 'all' : 'none',
                      zIndex: isPlayerOpen ? 101 : -1,
                      top: playerPosition === 'top' ? 0 : undefined,
                      bottom:
                        playerPosition === 'bottom'
                          ? breakpointsFit.small
                            ? bottomConstraint
                            : 0
                          : undefined,
                      left: NavDrawer && isDrawerOpen && 216,
                    }}
                  >
                    <AudioPlayer
                      position={0}
                      offset={bottomConstraint}
                      playerPosition={playerPosition}
                    />
                  </PlayerWrapper>
                )}
              </ContentWrapper>
              <SideWindowNode
                // @ts-ignore
                ref={sideWindowNode}
              />

              <Scrim
                tabIndex={-1}
                style={{
                  opacity: isLayoutCollapsed && isDrawerOpen ? 1 : 0,
                  pointerEvents: isLayoutCollapsed && isDrawerOpen && 'all',
                }}
                onClick={closeNavDrawer}
              />

              {NavDrawer && (
                <NavDrawerWrapper
                  isLayoutCollapsed={isLayoutCollapsed}
                  style={navDrawerStyles}
                >
                  <NavDrawer />
                </NavDrawerWrapper>
              )}
            </>
          </DOMNodeContext.Provider>
        </LayoutContext.Provider>
      </NavDrawerContext.Provider>
    </DropObserverStyled>
  );
};

export default ScreenLayout;
