import React from 'react';
import styled from '@emotion/styled';
import { Content, Caption } from 'imdui';
import type { EntityModels } from 'imddata';

const RightSection = styled.span`
  align-items: baseline;
  flex-direction: column;
  display: flex;
`;

const Wrapper = styled.div`
  ${Content} {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    word-break: break-word;
  }
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  gap: 12px;
  align-self: center;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    margin-left: auto;
    flex-shrink: 1;
    ${Caption} {
      margin-right: 12px;
      color: ${({ theme }) => theme.darks[4]};
    }
  }
`;

type PaypalScheme = {
  scheme: EntityModels.CustomerPaymentMethodPaypal['paymentMethodId'];
  caption?: string;
  description?: string;
  data: EntityModels.CustomerPaymentMethodPaypal['data'];
};

type CardScheme = {
  scheme: EntityModels.CustomerPaymentMethodCard['paymentMethodId'];
  caption?: string;
  description?: string;
  data: EntityModels.CustomerPaymentMethodCard['data'];
};

type NewCardScheme = {
  scheme: 'new-card';
  caption?: string;
  description?: string;
  data: { name?: string };
};

type Props = PaypalScheme | CardScheme | NewCardScheme;

const formatDate = (expiryMonth: number, expiryYear: number) => {
  return `${expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth}/${expiryYear
    .toString()
    .slice(-2)}`;
};

const supportedCards = [
  'paypal',
  'diners',
  'discover',
  'visa',
  'mir',
  'maestro',
  'mc',
  'amex',
];

export const getBrandIconUrl = (brand = '') => {
  let mappedname = brand?.toLowerCase();
  switch (mappedname) {
    case 'mastercard':
      mappedname = 'mc';
      break;
    case 'diners club':
      mappedname = 'diners';
      break;
    default:
      break;
  }

  if (supportedCards.indexOf(mappedname) >= 0) {
    return `/images/payment-methods/${mappedname}.svg`;
  }
  console.log('Brand Image Not Found: ' + brand);
  return `/images/payment-methods/card.svg`;
};

export const CustomerPaymentMethod = (p: Props) => {
  if (p.scheme === 'paypal') {
    return (
      <Wrapper>
        <span>
          <img src={getBrandIconUrl('paypal')} alt="paypal" height={32} />
        </span>
        <RightSection>
          <Content>{p.description}</Content>
        </RightSection>
      </Wrapper>
    );
  }
  if (p.scheme === 'card') {
    const { data, caption } = p;
    if (!data) return null;
    const brandUrlSvg = getBrandIconUrl(data?.brand || data?.name);
    return (
      <Wrapper>
        <div>
          {caption ? <Caption>{caption}</Caption> : null}
          <img src={brandUrlSvg} alt={data.name} height={32} />
        </div>
        <RightSection>
          <Content>{`**** ${data.lastFour}`}</Content>
          {data.holderName ? (
            <div>
              <Caption>
                {data.holderName} &bull;{' '}
                {data.expiryYear && data.expiryMonth
                  ? formatDate(
                      Number(data.expiryMonth),
                      Number(data.expiryYear)
                    )
                  : '??/??'}
              </Caption>
            </div>
          ) : null}
        </RightSection>
      </Wrapper>
    );
  }
  if (p.scheme === 'new-card') {
    const { data, description, caption } = p;
    const brandUrlSvg = getBrandIconUrl(data?.name);
    return (
      <Wrapper>
        <div>
          {caption ? <Caption>{caption}</Caption> : null}
          <img src={brandUrlSvg} alt={data?.name} height={32} />
        </div>
        <RightSection>
          <Content>{description}</Content>
        </RightSection>
      </Wrapper>
    );
  }
  return null;
};
