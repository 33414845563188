//
import * as R from 'ramda';
import {
  generateIdLens,
  reducerFactoryCreator,
  requestReducerFactory,
} from '../base';

export const deleteStateCreator = {
  success: () => ({
    deleting: false,
    deleted: Date.now(),
    errors: null,
    errorMessage: null,
    error: null,
  }),
  request: () => ({
    deleting: true,
  }),
  failure: (action) => ({
    deleting: false,
    statusCode: action && action.statusCode,
    deleteErrorMessage: action && action.error && action.error.message,
    deleteErrors: action && action.error && action.error.errors,
  }),
};

export const handleActionReducerFactory =
  requestReducerFactory(deleteStateCreator);

export default function deleteRequestReducerFactory(deleteAction) {
  const innerReducer = handleActionReducerFactory(deleteAction);
  const reducerFactory = reducerFactoryCreator(innerReducer);
  const queryHashStateReducer = reducerFactory((innerAction) => {
    const generalLens = R.lensPath([
      'searchHistory',
      innerAction.payload.queryHash,
    ]);
    if (innerAction.payload.id) {
      return R.compose(generateIdLens(innerAction.payload.id), generalLens);
    }
    return generalLens;
  });
  const genericReducer = reducerFactory();
  const singleIdReducer = reducerFactory((innerAction) =>
    generateIdLens(innerAction.payload.id)
  );
  return (state, action) => {
    switch (action.type) {
      case deleteAction.REQUEST:
      case deleteAction.SUCCESS:
      case deleteAction.FAILURE: {
        const { id, queryHash } = action.payload;

        if (queryHash) {
          return queryHashStateReducer(state, action);
        }
        if (id) {
          return singleIdReducer(state, action);
        }

        return genericReducer(state, action);
      }
      default:
        return state;
    }
  };
}
