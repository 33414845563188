//
import moment from 'moment';
import { types } from '../../../../actionTypes/deliveryDates';

const initialState = {
  data: {},
};

function deliveryDatesReducer(state, action) {
  switch (action.type) {
    case types.fetch.SUCCESS: {
      const dates = action.response?.dates?.reduce((acc, value) => {
        return {
          ...acc,
          [moment.utc(value.date).format('YYYY-MM-DD')]: value,
        };
      }, {});
      const firstAvailable = action.response?.dates?.find(
        ({ isAvailable }) => !!isAvailable
      );
      return {
        ...state,
        data: {
          ...action.response,
          dates,
          firstAvailable,
        },
      };
    }
    default:
      return state;
  }
}

export default function logs(state = initialState, action) {
  return [deliveryDatesReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
