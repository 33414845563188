import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import type { CellMeasurerCache, ListRowProps } from 'react-virtualized';
import { CellMeasurer } from 'react-virtualized';
import { SimpleInfiniteList } from 'imdshared';
import type { EntityModels } from 'imddata';
import NotificationsWindowHeader from '../NotificationsWindowHeader';
import EmptyNotifications from './EmptyNotifications';
import renderNotification from '../renderNotification';

const rowRenderer = ({
  onItemClick,
  item: entry,
  index,
  key,
  parent,
  style,
  cache,
}: ListRowProps & {
  item: EntityModels.UserNotification;
  onItemClick?: (
    e: React.MouseEvent,
    item?: { id: string | number; hasDetailView: boolean }
  ) => void;
  cache: CellMeasurerCache;
}) => (
  <CellMeasurer
    cache={cache}
    columnIndex={0}
    key={key}
    parent={parent}
    rowIndex={index}
  >
    <div style={style}>
      {renderNotification({ entry, onClick: onItemClick })}
    </div>
  </CellMeasurer>
);

const ListWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
`;

const ListContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  padding-top: 64px;
  overflow-x: hidden;
  overflow-y: auto;
`;

type Props = {
  data?: EntityModels.UserNotification[];
  onItemClick: (e: React.MouseEvent, data: any) => void;
  onClickClear?: (e: React.MouseEvent) => void;
  onClickClose: (e: React.MouseEvent) => void;
  cache: CellMeasurerCache;
  load: () => void;
  hasMorePages: boolean;
  loading: boolean;
  isListEmpty: boolean;
};

const NotificationsList: React.FC<Props> = ({
  onItemClick,
  onClickClear,
  cache,
  load,
  hasMorePages,
  loading,
  data,
  onClickClose,
  isListEmpty,
}) => {
  useEffect(() => {
    cache.clearAll();
  }, [data]);

  const rowRendererWithCache = useCallback(
    (props: ListRowProps & { item: EntityModels.UserNotification }) =>
      rowRenderer({ ...props, cache }),
    []
  );

  return (
    <ListWrapper>
      <NotificationsWindowHeader
        onClickClear={onClickClear}
        onClickClose={onClickClose}
      />

      <ListContainer>
        {isListEmpty ? (
          <EmptyNotifications />
        ) : (
          <SimpleInfiniteList
            data={data}
            hasNextPage={hasMorePages}
            loadNextPage={load}
            autoHeight={true}
            loading={loading}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            onItemClick={onItemClick}
            // @ts-ignore
            rowRenderer={rowRendererWithCache}
          />
        )}
      </ListContainer>
    </ListWrapper>
  );
};

export default NotificationsList;
