const adyenDataParams = [
  ['MD', 'mD'],
  ['TermUrl', 'termUrl'],
  ['PaReq', 'paReq'],
];

export function postPayment(
  path: string,
  params: Record<string, string>,
  method = 'post'
) {
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  // Adyen specific

  for (const [adyenKey, storeKey] of adyenDataParams) {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', adyenKey);
    hiddenField.setAttribute('value', params[storeKey]);

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
}
