import { actionChannel, take, put, call, fork } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { types } from '../../actionTypes/tracks';
import { entities } from '../../actionTypes';
import * as api from '../../api';

function* handleUploadChannel(channel) {
  while (true) {
    const {
      payload: { audio, id: trackId },
      meta,
    } = yield take(channel);
    try {
      yield call(api.tracks.audio.uploadAndConfirm, {
        payload: {
          file: audio,
          id: [trackId],
        },
        meta,
      });
    } catch (error) {
      yield put(
        entities.tracks.actions.updateLocalEntry({
          id: trackId,
          data: {
            localFilePreview: null,
          },
        })
      );
      yield put(
        entities.tracks.actions.audio.upload.failure({
          payload: {
            id: trackId,
            error,
          },
        })
      );
    }
  }
}

export default function* uploadQueueChannel() {
  const buffer = buffers.expanding();
  const channel = yield actionChannel(types.ADD_TO_UPLOAD_QUEUE, buffer);

  yield fork(handleUploadChannel, channel);
  yield fork(handleUploadChannel, channel);
  yield fork(handleUploadChannel, channel);
}
