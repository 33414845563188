import React, { useCallback, useMemo, useState } from 'react';
import OverlayContext from '../../context/OverlayContext';

interface Props {
  children: React.ReactNode;
  contextComponent?: typeof OverlayContext;
}

export default function OverlayController({
  contextComponent: ContextComponent = OverlayContext,
  children,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback((): void => {
    setIsOpen(true);
  }, []);

  const close = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [isOpen]
  );

  return (
    <ContextComponent.Provider value={value}>
      {children}
    </ContextComponent.Provider>
  );
}
