import { createContext } from 'react';

export type ReleaseFormContextValue = {
  requiredFields: string[];
  releaseNameId?: number;
  showCoverRequirementText?: boolean;
  editableFields: Record<string, boolean | undefined>;
  releaseId: string | number;
  form: string;
};

export const ReleaseFormContext = createContext<ReleaseFormContextValue>({
  editableFields: {},
  showCoverRequirementText: false,
  requiredFields: [],
  form: '',
  releaseId: -1,
  releaseNameId: -1,
});
