import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeedbackErrorComponent, TextFormatted } from 'imdshared';

type AppErrorType = { eventId: string };

const AppError = ({ error: errorObj }: { error: AppErrorType }) => {
  const { t } = useTranslation();
  return (
    <FeedbackErrorComponent
      error={errorObj}
      heading={t('app-error')}
      buttonText={t('app-error-send-feedback')}
    >
      <TextFormatted text={t('app-error-contact-details')} />
    </FeedbackErrorComponent>
  );
};

export default AppError;
