//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, theme } from 'imdui';
import { AppBanner } from './AppBanner';
// @ts-ignore
import assetSrc from './assets/refresh-page.png';

const StyledFixedNotice = styled(AppBanner)`
  box-shadow: inset 0 -2px 0 0 ${theme.state.warning};
`;

export const AppBannerOutdated = () => {
  const { t } = useTranslation();

  return (
    <StyledFixedNotice
      asset={<img alt="outdated-img" src={assetSrc} />}
      title={t('outdated-window-title')}
      text={t('outdated-window-text')}
    >
      <Button
        text={t('reload-now')}
        onClick={() => {
          window.location.reload();
        }}
      />
    </StyledFixedNotice>
  );
};
