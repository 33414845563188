import styled from '@emotion/styled';
import React from 'react';

type Props = {
  leftChildren?: React.ReactChild;
  rightChildren?: React.ReactChild;
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 32px;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > button:not(:last-child) {
    margin-right: 8px;
  }
`;

const FormToolbar: React.FC<Props> = ({ leftChildren, rightChildren }) => {
  return (
    <Container>
      <Wrapper>{leftChildren}</Wrapper>
      <Wrapper>{rightChildren}</Wrapper>
    </Container>
  );
};

export default FormToolbar;
