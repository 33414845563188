import React from 'react';
import { Icons, Text } from '@imus/services-ui';
import { shopTextStyle } from './styles';
import Image from 'next/image';
import { ArtistAvatarPlaceholder } from '@imus/base-ui';
import type { ReleasePageArtist } from '../types';
import { css } from '@emotion/react';

type Props = ReleasePageArtist;

export const avatarStyle = css`
  z-index: 1;
  width: 60px;
  object-fit: cover;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  background-color: var(--bg-1);
  border: 1px solid var(--fg-4);

  @container artist-template-sizer (width > 1024px) {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
  }
`;

const artistStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;

  ::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    background-color: var(--fg-4);

    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    pointer-events: none;
  }

  svg {
    fill: var(--fg-1);
    transition: transform 0.15s ease-in-out;
  }

  :hover {
    > svg {
      transform: translateX(8px);
    }
    &::before {
      opacity: 1;
    }
  }
`;
const ArtistItem: React.FC<Props> = ({
  title: name,
  pageKey: pageId,
  avatar,
}) => {
  // i18next-extract-disable-next-line
  return (
    <a
      css={artistStyle}
      target="_blank"
      rel="noreferrer"
      href={pageId ? '/artist/' + pageId : '#'}
    >
      {avatar?.url ? (
        <Image
          alt={name}
          css={avatarStyle}
          src={avatar?.url}
          width={140}
          height={140}
        />
      ) : (
        <ArtistAvatarPlaceholder css={avatarStyle} />
      )}

      <div css={shopTextStyle}>
        <Text.H4>{name}</Text.H4>
      </div>

      <Icons.Icon icon="chevron" />
    </a>
  );
};

export default ArtistItem;
