//
import React from 'react';
import styled from '@emotion/styled';
import { Body } from 'imdui';

const InteractiveTableRowWrapper = styled.tr`
  transition: ${(props) =>
    props.isClickable ? 'background-color 0.1s' : 'none'};

  :hover {
    background-color: ${(props) =>
      props.isClickable ? 'rgba(0, 0, 0, 0.05)' : 'none'};
  }
  :active {
    background-color: ${(props) =>
      props.isClickable ? 'rgba(0, 0, 0, 0.1)' : 'none'};
  }

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const defaultRowRenderer = ({ fields, item }) =>
  fields.map(({ dataKey, id, textAlign, cellRenderer, getData }, i) => {
    const value = getData ? getData(item) : item[dataKey];

    return (
      <td key={`${id || dataKey}`} style={{ textAlign }}>
        {!cellRenderer ? (
          <Body>{value}</Body>
        ) : (
          cellRenderer({
            key: `${id || dataKey}-${i}`,
            rowData: item,
            cellData: value,
          })
        )}
      </td>
    );
  });

const TableRow = ({
  item,
  rowRenderer,
  fields,
  onClick = null,
  className,
  style,
  testId,
}) => (
  <InteractiveTableRowWrapper
    data-test-id={testId}
    isClickable={!!onClick}
    className={className}
    style={style}
    onClick={(e) => {
      if (onClick) {
        onClick(e, item.id);
      }
    }}
  >
    {rowRenderer
      ? rowRenderer({ fields, item })
      : defaultRowRenderer({ fields, item })}
  </InteractiveTableRowWrapper>
);

export default TableRow;
