import React from 'react';
import {
  ArtistSideEditor,
  ReleaseEditor,
  RequestChangesWithHelpForm,
  ReturnNavigation,
} from 'components';
import { ActionGrid, Card, FormToolbar, LinkButton } from 'imdui';
import { DeleteReleaseButton } from 'imdshared';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { ReduxState } from 'imddata';

type Props = {
  match: {
    params: {
      id: string | number;
    };
  };
};

const EditRelease = ({ match: { params } }: Props) => {
  const releaseId = params.id;
  const { t } = useTranslation();
  const barcode = useSelector((state: ReduxState) => {
    const release = state.entities.releases.entities[releaseId];
    return release?.barcodes?.[0]?.value;
  });

  const registered = useSelector((state: ReduxState) => {
    return state.entities.releases.entities[releaseId]?.registeredAt;
  });

  return (
    <>
      <ReturnNavigation
        to={`/library/releases/${releaseId}`}
        text={`${t('release')} / ${t('edit')}`}
      />

      {barcode && registered && (
        <Card style={{ padding: '24px', margin: '0 0 24px 0' }}>
          <ActionGrid>
            <RequestChangesWithHelpForm
              releaseId={releaseId}
              barcode={barcode + ''}
            />
          </ActionGrid>
        </Card>
      )}
      <ReleaseEditor releaseId={releaseId}>
        <FormToolbar
          leftChildren={<DeleteReleaseButton id={releaseId} />}
          rightChildren={
            <LinkButton
              to={`/library/releases/${releaseId}`}
              testId="save-release"
              primary={true}
              text={t('save')}
            />
          }
        />
      </ReleaseEditor>

      <ArtistSideEditor isAdaptive={false} />
    </>
  );
};

export default EditRelease;
