import config from 'imdconfig';
import callApi from '../callApi';

function getGAToken() {
  return new Promise((resolve) => {
    if (!window.ga || typeof window.ga !== 'function') {
      resolve(null);
      return;
    }
    const timeout = setTimeout(() => {
      resolve(null);
    }, 1000);
    window.ga((tracker) => {
      clearTimeout(timeout);
      if (!tracker || typeof tracker.get !== 'function') {
        resolve(null);
      }
      resolve(tracker.get('clientId'));
    });
  });
}

const apiCalls = {
  newsletterSignup(data) {
    return callApi({
      external: true,
      endpoint: `${config.nisUrl}/subscribe`,
      options: { method: 'POST', data },
    });
  },
  async signup(data) {
    const gaToken = await getGAToken();
    return callApi({
      endpoint: 'signup',
      query: { google_analytics_client_id: gaToken },
      options: { method: 'POST', data },
    });
  },
  async login(data) {
    const loginCreds = {
      grant_type: 'password',
      client_id: process.env.APP_NAME === 'imdadmin' ? 2 : 1,
      client_secret: 'not-really-a-secret',
      ...data,
    };
    const gaToken = await getGAToken();
    return callApi({
      endpoint: 'oauth/token',
      query: {
        google_analytics_client_id: gaToken,
      },
      options: { method: 'POST', data: loginCreds },
    });
  },
  signOut(data) {
    return callApi({
      endpoint: 'signout',
      options: { method: 'POST', data },
    });
  },
  verifyEmail(data) {
    return callApi({
      endpoint: 'verify_email',
      options: { method: 'POST', data },
    });
  },
  async signupSocial({
    provider,
    referralToken,
    code,
    affiliatePartnerToken,
    state,
  }) {
    const gaToken = await getGAToken();
    return callApi({
      endpoint: `social/${provider}`,
      query: { google_analytics_client_id: gaToken },
      options: {
        method: 'POST',
        data: {
          client_id: process.env.APP_NAME === 'imdadmin' ? 2 : 1,
          clientSecret: 'not-really-a-secret',
          affiliatePartnerToken,
          referralToken,
          code,
          state,
        },
      },
    });
  },
  getSocialAuthUrl({ provider, locale, referralToken, affiliatePartnerToken }) {
    return callApi({
      endpoint: `social/url/${provider}`,
      query: {
        client_id: process.env.APP_NAME === 'imdadmin' ? 2 : 1,
        affiliatePartnerToken,
        referralToken,
        locale,
      },
      options: { method: 'GET' },
    });
  },
  resetPassword(data) {
    return callApi({
      endpoint: 'reset_password',
      options: { method: 'POST', data },
    });
  },
  deleteProfile(token) {
    return callApi({
      endpoint: `/customers/deletion/${token}`,
      options: { method: 'GET' },
    });
  },
  resetPasswordRequest(data) {
    return callApi({
      endpoint: 'reset_password/request',
      options: { method: 'POST', data },
    });
  },
};

export default apiCalls;
