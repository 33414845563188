import type { EntityModels } from '../../../types';
import type { NestDefinition } from '../base';
import { useEntry, useEntries } from '../hooks';
import { useQuery } from '../useQueryHash';
import type { DBNested } from './nests';
import { defaultBundleNest } from './nests';

export const defaultBundleQuery = {
  with: 'tracks,releases,countries,shops',
};

const nestShop: NestDefinition = [
  {
    key: 'shops',
    entity: 'deliveryBundleShops',
  },
];

export const useBundleShops = ({
  id,
  passive,
}: {
  id: string | number;
  passive?: boolean;
}) => {
  const { entry } = useEntry<
    EntityModels.Nest<
      EntityModels.DeliveryBundle,
      { shops: EntityModels.DeliveryBundleShop[] }
    >
  >({
    id,
    entity: 'deliveryBundles',
    passive,
    nest: nestShop,
  });

  return entry?.shops;
};

const nestTracks: NestDefinition = [
  {
    key: 'tracks',
    entity: 'deliveryBundleTracks',
  },
];

export const useBundleTracks = ({
  id,
  passive,
}: {
  id: string | number;
  passive?: boolean;
}) => {
  const { entry } = useEntry<
    EntityModels.Nest<
      EntityModels.DeliveryBundle,
      { tracks: EntityModels.DeliveryBundleTrack[] }
    >
  >({
    id,
    entity: 'deliveryBundles',
    passive,
    nest: nestTracks,
  });

  return entry?.tracks;
};

export function useBundle({
  nest = defaultBundleNest,
  query = defaultBundleQuery,
  ...props
}: Omit<Parameters<typeof useEntry>[0], 'entity'>) {
  return useEntry<DBNested>({
    entity: 'deliveryBundles',
    query,
    nest,
    ...props,
  });
}
// TODO: [REMOVE_BUNDLE_STATUSES] remove after next API deployments
const latestBundleQuery = {
  ...defaultBundleQuery,
  'filter.status': 'open,draft',
  'filter.type': 'releases',
  perPage: 1, // TODO: set to 1 when API is fixed
  sortBy: 'id,desc',
};

const nestBundleRelease: NestDefinition = [
  {
    entity: 'deliveryBundleReleases',
    key: 'releases',
  },
];

export function useLatestOpenBundles({
  query = latestBundleQuery,
  ...props
} = {}) {
  const { queryHash } = useQuery({ query });
  return useEntries<
    EntityModels.Nest<
      EntityModels.DeliveryBundle,
      { releases: EntityModels.DeliveryBundleRelease[] }
    >
  >({
    nest: nestBundleRelease,
    entity: 'deliveryBundles',
    query,
    queryHash,
    ...props,
  });
}

export function useBundles({
  nest = defaultBundleNest,
  query = defaultBundleQuery,
  ...props
}: Omit<Parameters<typeof useEntries>[0], 'entity'> = {}) {
  return useEntries<DBNested>({
    entity: 'deliveryBundles',
    query,
    nest,
    ...props,
  });
}
