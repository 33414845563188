import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ComponentIcons, HelpWindowContext, IconButton, Tooltip } from 'imdui';
import { useResizeDetector } from 'react-resize-detector';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  SubscriptionAction,
  SubscriptionCard,
  SubscriptionCardFeature,
  SubscriptionName,
} from 'components/molecules';
import { useFeatureList } from './useFeatureList';
import type { TFunction } from 'i18next';
import type { SubscriptionId } from 'imddata/types/entities';
import { useSubscriptionSelectionManager } from './SubscriptionSelectionContext';
import { ReturnToPuHeader } from './ReturnToPuHeader';
import { ContentM, ContentS } from '@imus/services-ui/src/Text';
import { splitEvery, update } from 'ramda';
import { AddIcon, DARK_MODE_COLORS, H3, RemoveIcon } from '@imus/base-ui';
import Background from './bg.svg';
import { mapSubscriptionIdToTheme } from 'components/molecules/SubscriptionCard';

const Contents = styled.div`
  ${DARK_MODE_COLORS}
  background-color: var(--background);
  & * {
    font-family: Inter, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-feature-settings: 'case', 'cv11';
  }
`;

const Heading = styled.div`
  color: var(--on-surface, #f6f3f1);
  text-align: center;
  font-feature-settings:
    'ss01' on,
    'cv11' on;
  font-family: Barlow;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 112.5% */
  text-transform: uppercase;
`;
const StyledBackground = styled(Background)`
  content: '';
  bottom: -80px;
  width: 100%;
  height: 566px;
  position: absolute;
`;

const SubscriptionGrid = styled.div<{ count: number; width: number }>`
  ${DARK_MODE_COLORS}
  overflow: hidden;
  position: relative;
  margin: 0 6px;
  border-radius: 16px;

  ${SubscriptionCard} {
    border-radius: 9px;
    height: 100%;
  }
  min-height: ${312 + 80}px;
  display: grid;
  gap: 16px;

  align-items: center;
  justify-content: center;
  padding: ${({ width }) => (width < 640 ? '16px' : '56px')};

  grid-template-columns: ${({ count, width }) =>
    width < 640
      ? '1fr'
      : width < 1000
        ? '244px 244px'
        : new Array(count).fill('244px').join(' ')};
  ${Heading} {
    grid-column: 1/-1;
    justify-content: center;
    text-align: center;
    padding: ${({ width }) => (width < 640 ? '32px 0' : '0 0 48px 0')};
    h2 {
      letter-spacing: -1.2px;
      font-weight: 700;
      font-size: 40px;
      line-height: 44px;
    }
  }
`;

const FeatureHeader = styled.div`
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  & > * {
    width: 100%;
  }
`;

const Gap = styled.span`
  grid-column: 1 / -1;
`;
const FeatureSection = styled(H3)`
  font-size: 16px;
  padding: 40px 0 16px 24px;

  @media (min-width: 1024px) {
    padding: 40px 0 16px 32px;
  }
  font-weight: 700;
  &:first-of-type {
    padding-top: 0;
  }
`;
const FeatureName = styled(ContentM) <{ hasTip?: boolean }>`
  display: inline-flex;
  padding: 16px 16px 16px 24px;
  @media (min-width: 1024px) {
    padding: 16px 16px 16px 32px;
  }
  align-items: center;
  min-height: 72px;
  span {
    display: inline-flex;
    border-bottom: ${({ hasTip }) => (hasTip ? '1px dashed #f24180' : 'none')};
  }
`;
const FeatureValue = styled(ContentS) <{
  subscriptionId: SubscriptionId | 'free';
}>`
  ${({ subscriptionId }) => mapSubscriptionIdToTheme(subscriptionId)}
  padding: 16px;
  min-height: 16px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: 1px solid var(--fg-4);

  flex-direction: column;
  & svg.check,
  & svg.check path {
    fill: ${({ subscriptionId }) =>
    subscriptionId === 'free' ? 'var(--fg-2)' : 'var(--accent)'}; //;
  }
  & svg {
    max-height: 20px;
  }
`;

const HelpIconContainer = styled.span`
  margin-left: auto;
`;

const StyledIconButton = styled(IconButton) <{
  warning?: boolean;
  error?: boolean | string;
}>`
  width: 20px;
  height: 20px;
  svg {
    height: 20px;
    width: 20px;
  }
`;

const FeatureGrid = styled.div<{ count: number }>`
  display: grid;
  margin: 0 auto;
  padding: 0px 0px 128px 0px;
  grid-template-columns: ${({ count }) =>
    new Array(count + 1).fill('1fr').join(' ')};

  @media (min-width: 1024px) {
    padding: 0px 40px 128px 40px;
  }
  ${FeatureSection} {
    font-size: 20px;
    width: 100%;
  }

  ${FeatureName} {
    font-weight: 600;
  }
`;

const sectionOrder = ['music-distribution', 'support', 'extra'];

// const renderShortFeature = (f: any, idx: number) => <span key={idx}>{f}</span>;

const getActionText = (
  currentSubscriptionId: SubscriptionId | undefined,
  subId: SubscriptionId,
  t: TFunction
) => {
  return currentSubscriptionId === subId
    ? t('active')
    : currentSubscriptionId
      ? t('upgrade')
      : t('subscribe');
};

const TIER_2_OPTIONS: SubscriptionId[] = [
  'tier-2-2',
  'tier-2-5',
  'tier-2-15',
  'tier-2-25',
];

const Tier2Artisthandler = ({
  id = 'tier-2-2',
  options = TIER_2_OPTIONS,
  onChange,
}: {
  options: SubscriptionId[];
  id: SubscriptionId;
  onChange: (id: SubscriptionId) => void;
}) => {
  const nextItem = options[options.indexOf(id) + 1];
  const prevItem = options[options.indexOf(id) - 1];
  const { t } = useTranslation();
  const [, count] = /tier-2-(\d+)/.exec(id) || [0];
  return (
    <SubscriptionCardFeature
      value={t('artists-included', {
        defaultValue: '{{count}} artists',
        count: Number(count),
      })}
    >
      <div>
        <IconButton
          style={{ marginRight: '8px' }}
          icon={() => <RemoveIcon fill="var(--accent)" />}
          disabled={!prevItem}
          onClick={() => onChange(prevItem)}
          inline={true}
        />
        <IconButton
          inline={true}
          icon={() => <AddIcon fill="var(--accent)" />}
          disabled={!nextItem}
          onClick={() => onChange(nextItem)}
        />
      </div>
    </SubscriptionCardFeature>
  );
};

const SubsTable = ({
  subscriptionIds,
  freeHandler,
  freeActionText,
}: {
  subscriptionIds: ('free' | SubscriptionId)[];
  freeHandler: () => void;
  freeActionText: string;
}) => {
  const { t, i18n } = useTranslation();
  const features = useFeatureList();
  const showHelp = useContext(HelpWindowContext);
  const hasFree = !!subscriptionIds.find((s) => s === 'free');
  const subsCount = subscriptionIds.length;

  const sids: SubscriptionId[] = subscriptionIds.filter(
    (s) => s !== 'free'
  ) as SubscriptionId[];

  const { currentSubscription, subscriptionOptions, handleSubscription } =
    useSubscriptionSelectionManager();
  return (
    <FeatureGrid count={subsCount}>
      <div></div>
      {hasFree && (
        <FeatureHeader>
          <SubscriptionName subscriptionId="free">
            {t('free', { context: 'plan' })}
          </SubscriptionName>
          <SubscriptionAction
            subscriptionId="free"
            disabled={!currentSubscription}
            size="small"
            text={freeActionText}
            onClick={freeHandler}
          />
        </FeatureHeader>
      )}

      {sids.map((sid) => {
        const handler = subscriptionOptions?.find(
          (s) => s.subscriptionId === sid
        );
        return (
          <FeatureHeader key={sid}>
            <SubscriptionName subscriptionId={sid}>{t(sid)}</SubscriptionName>
            <SubscriptionAction
              size="small"
              appearance="stroke"
              disabled={currentSubscription?.subscriptionId === sid}
              primary={handler?.mode !== 'downgrade'}
              subscriptionId={sid}
              text={
                handler?.mode === 'downgrade'
                  ? t('downgrade')
                  : getActionText(currentSubscription?.subscriptionId, sid, t)
              }
              onClick={() => handleSubscription(handler)}
            />
          </FeatureHeader>
        );
      })}

      {sectionOrder.map((section, idx) => {
        return (
          <>
            {idx !== 0 ? <Gap /> : undefined}
            <FeatureSection>{t(section)}</FeatureSection>
            {subscriptionIds.map((_, idx) => (
              <div key={idx}></div>
            ))}
            {features.full
              .filter((f) => f.section === section)
              .map((f) => {
                const tip =
                  f.tip && i18n.exists(f.tip)
                    ? t(f.tip)
                    : i18n.exists(f.label + '-subscription-tip')
                      ? t(f.label + '-subscription-tip')
                      : undefined;

                return (
                  <>
                    <FeatureName>
                      <span>{t(f.label)}</span>

                      {tip && (
                        <HelpIconContainer
                          className="upgrade-tooltip"
                          data-tooltip-content={tip}
                        >
                          <StyledIconButton
                            onClick={() => {
                              showHelp(t(f.label), tip);
                            }}
                            tabIndex={-1}
                          >
                            <ComponentIcons.HelpIcon />
                          </StyledIconButton>
                        </HelpIconContainer>
                      )}
                    </FeatureName>

                    {hasFree && (
                      <FeatureValue subscriptionId={'free'}>
                        {f.values.free}
                      </FeatureValue>
                    )}
                    {sids.map((sid) => (
                      <FeatureValue key={sid} subscriptionId={sid}>
                        {
                          // @ts-ignore
                          f.values[sid]
                        }
                      </FeatureValue>
                    ))}
                  </>
                );
              })}
          </>
        );
      })}
    </FeatureGrid>
  );
};

const isRecommended = (
  currentSid: SubscriptionId | 'free',
  sid: SubscriptionId
) => {
  switch (currentSid) {
    case 'tier-1':
    case 'tier-2-2':
    case 'tier-2-5':
    case 'tier-2-15':
    case 'tier-2-25':
    case 'tier-3':
      return false;

    case 'music-analytics':
    case 'trial-monthly':
    case 'trial-yearly':
    case 'tier-1-yearly':
    case 'tier-1-monthly':
      return sid === 'tier-1';

    case 'vip':
    case 'professional-unlimited':
    case 'professional-unlimited-analytics':
      return sid === 'tier-3';

    case 'trial-tier-2':
    case 'tier-2-yearly':
    case 'tier-2-monthly':
    case 'free':
      return sid.startsWith('tier-2');
  }
};

export const SubscriptionUpgrade = ({
  recommendedSubscriptionId,
  subscriptionIds: propsSubsIds = ['free', 'tier-1', 'tier-2-2', 'tier-3'],
}: {
  recommendedSubscriptionId?: SubscriptionId;
  subscriptionIds?: (SubscriptionId | 'free')[];
}) => {
  const [subscriptionIds, setSubscriptionIds] = useState(
    () => propsSubsIds.filter((s) => s !== 'free') as SubscriptionId[]
  );
  const hasFree = propsSubsIds.indexOf('free') >= 0;
  const { t } = useTranslation();
  const features = useFeatureList();

  const { width = 640, ref } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 500,
    handleWidth: true,
  });
  const {
    prices,
    selectedSubscription,
    currentSubscription,
    subscriptionOptions,
    ordering,
    renewalEnabled,
    handleSubscription,
  } = useSubscriptionSelectionManager();

  // const currentPrice = currentSubscription
  //   ? prices?.[currentSubscription.subscriptionId]
  //   : undefined;

  // const amplifyPlus = subscriptions
  //   ?.find((g) => g.id === 'tier-2')
  //   ?.subscriptions.find((s) => s.subscriptionId === 'tier-2-yearly');

  const freeActionText =
    currentSubscription && renewalEnabled
      ? t('downgrade')
      : currentSubscription && !renewalEnabled
        ? t('downgrade')
        : t('active');

  const freeHandler =
    renewalEnabled && currentSubscription
      ? () => handleSubscription({ mode: 'cancel' })
      : () => null;

  const subsCount = (hasFree ? 1 : 0) + subscriptionIds.length;
  const sids: (SubscriptionId | 'free')[] = useMemo(
    () => (hasFree ? ['free', ...subscriptionIds] : subscriptionIds),
    [subscriptionIds]
  );

  const tier2Options = useMemo(() => {
    if (!currentSubscription?.subscriptionId) return TIER_2_OPTIONS;

    const idx = TIER_2_OPTIONS.indexOf(currentSubscription.subscriptionId);

    return idx >= 0 ? TIER_2_OPTIONS.slice(idx) : TIER_2_OPTIONS;
  }, [currentSubscription?.subscriptionId]);

  useEffect(() => {
    if (!currentSubscription?.subscriptionId) return;

    const currentTier2Level = TIER_2_OPTIONS.indexOf(
      currentSubscription.subscriptionId
    );

    const tier2PositionInCurrentOffering = subscriptionIds.findIndex((sid) =>
      sid.startsWith('tier-2')
    );

    const offeredTier2Level = tier2PositionInCurrentOffering
      ? TIER_2_OPTIONS.indexOf(subscriptionIds[tier2PositionInCurrentOffering])
      : 100; // Set 100 to avoid placing T2 offering when there is none

    if (currentTier2Level > offeredTier2Level) {
      setSubscriptionIds(
        update(
          tier2PositionInCurrentOffering,
          TIER_2_OPTIONS[currentTier2Level],
          subscriptionIds
        )
      );
    }
  }, [currentSubscription?.subscriptionId]);

  return (
    <Contents ref={ref}>
      <ReturnToPuHeader />
      <SubscriptionGrid count={subsCount} width={width}>
        <StyledBackground />
        <Heading>{t('choose-subscription-plan-for-you')}</Heading>
        {hasFree && (
          <SubscriptionCard
            subscriptionId="free"
            price={prices?.free?.price}
            features={features.short.free}
            onClick={freeHandler}
            actionText={freeActionText}
          />
        )}
        {subscriptionIds.map((sid, index) => {
          const handler = subscriptionOptions?.find(
            (s) => s.subscriptionId === sid
          );
          return (
            <SubscriptionCard
              recommendedText={
                isRecommended(
                  currentSubscription?.subscriptionId || 'free',
                  sid
                ) || sid === recommendedSubscriptionId
                  ? t('recommended')
                  : sid === 'trial-tier-2'
                    ? t('free-trial')
                    : ''
              }
              key={sid}
              subscriptionId={sid}
              testId={`SubscriptionCard-${sid.charAt(0).toUpperCase() + sid.slice(1)}`}
              loading={ordering && selectedSubscription?.subscriptionId === sid}
              onClick={() => handleSubscription(handler)}
              features={
                sid.startsWith('tier-2')
                  ? features.short['tier-2']
                  : features.short[sid]
              }
              price={prices?.[`${sid}`]?.price}
              originalPrice={prices?.[`${sid}`]?.originalPrice}
              actionPrimary={handler?.mode !== 'downgrade'}
              actionText={
                handler?.mode === 'downgrade'
                  ? t('downgrade')
                  : getActionText(currentSubscription?.subscriptionId, sid, t)
              }
              featureChildren={
                sid.startsWith('tier-2') ? (
                  <Tier2Artisthandler
                    id={sid}
                    options={tier2Options}
                    onChange={(newId) => {
                      setSubscriptionIds(update(index, newId, subscriptionIds));
                    }}
                  />
                ) : undefined
              }
            ></SubscriptionCard>
          );
        })}
      </SubscriptionGrid>

      <div>
        <Heading style={{ margin: '56px 0' }}>{t('compare-features')}</Heading>
        {width < 640 ? (
          sids.map((sid) => (
            <SubsTable
              key={sid}
              freeActionText={freeActionText}
              freeHandler={freeHandler}
              subscriptionIds={[sid]}
            />
          ))
        ) : width < 1000 ? (
          splitEvery(2, sids).map((sids, idx) => (
            <SubsTable
              key={idx}
              freeActionText={freeActionText}
              freeHandler={freeHandler}
              subscriptionIds={sids}
            />
          ))
        ) : (
          <SubsTable
            freeActionText={freeActionText}
            freeHandler={freeHandler}
            subscriptionIds={sids}
          />
        )}
      </div>
      <Tooltip
        openOnClick={false}
        clickable={false}
        anchorSelect=".upgrade-tooltip"
      />
    </Contents>
  );
};
