import React from 'react';
import styled from '@emotion/styled';
import EntitledGroup from '../EntitledGroup';

interface Props {
  groupKey: string;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 24px 24px;
`;

const ChipsGroup: React.FC<Props> = ({ groupKey, children }) => {
  return (
    <EntitledGroup groupKey={groupKey}>
      <Wrapper>{children}</Wrapper>
    </EntitledGroup>
  );
};

export default ChipsGroup;
