import { find } from 'ramda';
import { hasEmoji, validateArtistRoles } from '../../../helpers';
import type { TFunction } from 'i18next';

type Tag = { role: string };

const hasMainArtist = find((tag: Tag) => tag && tag.role === 'main');
const hasLyricist = find((tag: Tag) => tag && tag.role === 'lyricist');

const upperCaseWord = (locale: string) => (text: string) =>
  text.charAt(0).toLocaleUpperCase(locale) +
  text.substring(1).toLocaleLowerCase(locale);

function toTitleCase(str: string, locale: string) {
  return str.replace(/\w\S*/g, upperCaseWord(locale));
}

function toSentanceCase(str: string, locale: string) {
  return str.toLocaleLowerCase(locale).replace(/\w\S*/, upperCaseWord(locale));
}

const validateName = (
  name: { title?: string; languageId: string },
  t: TFunction
) => {
  if (!name.title) return null;
  switch (name.languageId) {
    case 'en':
      return name.title !== toTitleCase(name.title, name.languageId)
        ? t('must-be-title-case', {
            context: name.languageId,
            locale: name.languageId,
          })
        : null;
    case 'pt':
      return name.title !== toTitleCase(name.title, name.languageId)
        ? t('must-be-title-case', {
            context: name.languageId,
            locale: name.languageId,
          })
        : null;
    case 'es':
      return name.title !== toTitleCase(name.title, name.languageId)
        ? t('must-be-title-case', {
            context: name.languageId,
            locale: name.languageId,
          })
        : null;
    case 'fr':
      return name.title !== toSentanceCase(name.title, name.languageId)
        ? t('must-be-sentence-case', {
            context: name.languageId,
            locale: name.languageId,
          })
        : null;
    case 'it':
      return name.title !== toSentanceCase(name.title, name.languageId)
        ? t('must-be-sentence-case', {
            context: name.languageId,
            locale: name.languageId,
          })
        : null;

    default:
      return null;
  }
};

export const createWarn =
  (t: TFunction) =>
  (values: any): any => {
    const errors: any = {};

    errors.names = values.names.map((n: any) => {
      const error = validateName(n, t);
      return error
        ? {
            title: error,
            languageId: t('double-check-track-title-language', {
              context: n.languageId,
            }),
          }
        : {};
    });

    return errors;
  };

export const validate = (
  values: any,
  {
    additionalValidation,
    targets,
    targets: {
      hasAllMetadata: {
        conditions: {
          hasCorrectCustomIsrc,
          customIsrcNotRegistered,
          hasUniqueCustomIsrc,
        } = {
          customIsrcNotRegistered: undefined,
          hasUniqueCustomIsrc: undefined,
          hasCorrectCustomIsrc: undefined,
        },
      },
    } = { hasAllMetadata: { conditions: {} } },
    ...props
  }: any
) => {
  const errors = additionalValidation
    ? additionalValidation(values, { targets, ...props })
    : {};

  const instrumentalError =
    values.isInstrumental &&
    (hasLyricist(values.contributors || []) || values.hasTraditionalLyricist)
      ? 'instrumental-track-doesnt-have-lyricist'
      : null;

  const explicitError =
    values.isInstrumental && values.hasExplicitContent
      ? 'instrumental-can-not-have-explicit-lyrics'
      : null;

  // hasUniqueCustomIsrc && hasUniqueCustomIsrc.status === 'warning'
  //       ? 'isrc-exists-in-library'
  //       :
  const uniqueIsrcError =
    hasUniqueCustomIsrc &&
    hasUniqueCustomIsrc.status === 'warning' &&
    hasUniqueCustomIsrc?.customData?.replacementId
      ? 'isrc-already-exist-in-library'
      : null;

  const alreadyRegisteredIsrc =
    customIsrcNotRegistered && customIsrcNotRegistered.status === 'failed'
      ? 'isrc-already-taken'
      : null;

  const correctCustomIsrcError =
    hasCorrectCustomIsrc && hasCorrectCustomIsrc.status === 'failed'
      ? 'incorrect-isrc'
      : null;

  errors.customIsrc = values.customIsrc
    ? correctCustomIsrcError || uniqueIsrcError || alreadyRegisteredIsrc
    : null;

  errors.contributors = instrumentalError || errors.contributors;
  errors.hasExplicitContent = explicitError;

  errors.artists =
    (values.artists?.length && !hasMainArtist(values.artists)
      ? 'add-main-artist'
      : validateArtistRoles(values)) || errors.artists;

  errors.publishers =
    values.publishers?.length === 0 &&
    values.isCoverVersion &&
    !values.hasNoPublishers
      ? 'required'
      : errors.publishers;

  errors.names = !values.names?.[0]?.title
    ? [{ title: 'required' }]
    : hasEmoji(values.names?.[0]?.title)
      ? [{ title: 'emojis-not-allowed' }]
      : errors.names;

  errors.copyrightText =
    values.copyrightText && hasEmoji(values.copyrightText)
      ? 'emojis-not-allowed'
      : errors.copyrightText;

  return errors;
};

export { default as AudioLanguageField } from './AudioLanguageField';
