//
import type { ChangeEvent } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body } from 'imdshared';

const Wrapper = styled.div`
  display: flex;
  margin-right: 32px;

  ${Body} {
    white-space: nowrap;
    color: ${(props) => props.theme.foreground.primary};
    margin-top: 12px;
    margin-right: 16px;
  }
`;

const StyledInput = styled.input`
  width: 56px;
  height: 36px;
  margin-top: 4px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  background-color: #fafafa;
  transition: box-shadow 0.1s ease-in-out;

  :focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.6);
  }

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const UnitsNumber = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Body>{t('number-of-units')}</Body>

      <StyledInput value={value} onChange={onChange} />
    </Wrapper>
  );
};

export default UnitsNumber;
