export default function concatBuffer() {
  let actionKeys: string[] = [];
  let mergedActions: Record<string, any[]> = {};

  const put = (action: { id: string | number; actions: any }) => {
    const { id, actions } = action;
    const key = id.toString() || 'undefined';

    if (!mergedActions[key]) {
      actionKeys.push(key);
      mergedActions = {
        ...mergedActions,
        [key]: actions || [action],
      };
    } else {
      mergedActions = {
        ...mergedActions,
        [key]: actions
          ? [...mergedActions[key], ...actions]
          : [...mergedActions[key], action],
      };
    }
  };

  // eslint-disable-next-line
  const take = () => {
    if (actionKeys.length !== 0) {
      const actionKey: string = actionKeys.shift() as string;

      const actions: unknown[] = mergedActions[actionKey];
      delete mergedActions[actionKey];
      return actions;
    }
  };

  const flush = () => {
    const allActions = actionKeys.reduce<any[][]>(
      (accActions, actionKey) => [...accActions, mergedActions[actionKey]],
      []
    );
    actionKeys = [];
    mergedActions = {};

    return allActions;
  };

  const isEmpty = (): boolean => actionKeys.length === 0;

  return {
    isEmpty,
    take,
    flush,
    put,
    size: actionKeys.length,
  };
}
