//
import React, { useCallback } from 'react';
import { flip, adjust, compose, without, reduce, concat, map } from 'ramda';
import { EventTypes } from 'redux-segment';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'imddata/actionTypes/ui/selection';
import { useTranslation } from 'react-i18next';
import {
  TracksUploader,
  ReleaseTracksList as BaseReleaseTracksList,
  Icons,
} from 'imdshared';
import { ComponentIcons, Button, OptionTab } from 'imdui';
import { useUpdateEntity } from 'imddata';
import TracksBrowserDialog from '../TracksBrowserDialog';
import TrackSideEditor from '../TrackSideEditor';

const mapTrackToData = map(compose(map((trackId) => ({ trackId }))));

const tracksFromVolumes = (volumes) => {
  return reduce((acc, volume) => concat(acc, volume), [], volumes);
};

export const VolumeTab = ({ disabled, testId, onClick }) => (
  <OptionTab
    style={{ padding: '0 8px' }}
    testId={testId}
    disabled={disabled}
    onChange={onClick}
  >
    <ComponentIcons.Plus />
  </OptionTab>
);

const Extra = ({
  displayedFields,
  canModifyTracks,
  disabled,
  volumes,
  onHideTracksBrowser,
  showTracksBrowser,
  selectedVolume,
  selectedTracks,
  onCloseWindow,
  releaseId,
  additionalValidation,
  requiredFields,
}) => {
  const { t } = useTranslation();
  const { updateEntry } = useUpdateEntity({
    entity: 'releases',
    query: { with: 'tracks' },
    id: releaseId,
  });
  return (
    <>
      <TracksBrowserDialog
        key="browser"
        isOpen={showTracksBrowser}
        disabledTracks={tracksFromVolumes(volumes)}
        onClose={onHideTracksBrowser}
        onTracksSelected={(newTracks) => {
          const resultedTracks = adjust(
            selectedVolume,
            flip(concat)(newTracks),
            volumes
          );
          updateEntry(
            {
              data: {
                volumes: mapTrackToData(resultedTracks),
              },
            },
            {
              analytics: {
                eventType: EventTypes.track,
                eventPayload: {
                  event: 'FT Include Tracks from library',
                  properties: {
                    releaseId,
                    addedTracks: newTracks,
                    resultedTracks,
                  },
                },
              },
            }
          );
          onHideTracksBrowser();
        }}
      />

      <TrackSideEditor
        key="editor"
        selected={selectedTracks}
        displayedFields={displayedFields}
        additionalValidation={additionalValidation}
        requiredFields={requiredFields}
        disabled={disabled}
        renderActions={() => (
          <div>
            <Button
              iconLeft={Icons.actions.remove}
              position="center"
              text={
                selectedTracks.length > 1
                  ? t('remove-tracks-from-release')
                  : t('remove-track-from-release')
              }
              style={{ width: '100%' }}
              disabled={!canModifyTracks || disabled || !selectedTracks.length}
              onClick={() => {
                onCloseWindow();

                updateEntry({
                  data: {
                    volumes: mapTrackToData(
                      adjust(selectedVolume, without(selectedTracks), volumes)
                    ),
                  },
                });
              }}
            />
          </div>
        )}
        onClose={onCloseWindow}
      />
    </>
  );
};

const renderTracksUploader = (props) => <TracksUploader {...props} />;

const noSelected = [];

const ReleaseTracksList = ({ displayedFields, ...props }) => {
  const selectedTracks = useSelector(
    (state) => state.ui.selection.tracks || noSelected
  );
  const dispatch = useDispatch();
  const onUpdateSelection = useCallback(
    (...args) => dispatch(actions.updateSelection(...args)),
    []
  );
  const onClearSelection = useCallback(
    (...args) => dispatch(actions.clearSelection(...args)),
    []
  );
  return (
    <BaseReleaseTracksList
      renderAddItem={VolumeTab}
      {...props}
      selectedTracks={selectedTracks}
      renderTracksUploader={renderTracksUploader}
      onClearSelection={onClearSelection}
      onUpdateSelection={onUpdateSelection}
    >
      {(innerProps) => (
        <Extra displayedFields={displayedFields} {...innerProps} />
      )}
    </BaseReleaseTracksList>
  );
};

export default ReleaseTracksList;
