import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { InjectedFormProps, ConfigProps } from 'redux-form';
import { FieldArray, reduxForm, Form } from 'redux-form';
import type { EntityModels } from 'imddata';
import { useArtist, useUpdateEntity } from 'imddata';
import {
  Centered,
  LoadingIndicator,
  HelpWindowContext,
  FieldGroup,
} from 'imdui';
import FieldUpdatable from '../../../fields/FieldUpdatable';
import { NewInputField } from '../../../fields/InputField';
import NamesField from '../../NamesField';
import ArtistIdField from '../../ArtistIdField';
import { hasEmoji } from '../../../helpers';

type Props = {
  children?: React.ReactNode;
  name: string;
  id: number;
};

const TRANSLATABLE_FIELDS = [
  {
    name: 'name',
    label: 'artist-name',
    longHelp: 'artist-helptext-name',
    testId: 'artist-name',
  },
];

type FormData = Partial<{
  names: EntityModels.ArtistName[];
  shopArtistIds: EntityModels.Artist['shopArtistIds'];
  isniNumber: string;
  defaultLanguageId: string;
}>;

const SingleEditForm = ({
  name,
  form,
  children,
  id,
}: Props & InjectedFormProps<FormData, Props>) => {
  const { t } = useTranslation();

  const showHelpWindow = useContext(HelpWindowContext);

  const { updateEntry } = useUpdateEntity({ entity: 'artists', id });

  const handleUpdate = useCallback(
    (data) => {
      updateEntry(
        {
          formId: form,
          data,
        },
        { debounce: true }
      );
    },
    [updateEntry, form]
  );

  const handleIsniClickHelp = useCallback(() => {
    showHelpWindow(t('artist-isni-number'), t('artist-helptext-isni-number'));
  }, []);

  const handleIsniChange = useCallback(
    (_, value?: any) => {
      updateEntry(
        {
          formId: form,
          data: { isniNumber: value },
        },
        { debounce: true }
      );
    },
    [form]
  );

  return (
    <Form data-test-id="artist-single-edit-form">
      <FieldGroup>
        <FieldArray
          name="names"
          entity="artistNames"
          // @ts-ignore
          component={NamesField}
          languageLabelPrefix="artist"
          fieldsData={TRANSLATABLE_FIELDS}
          onChange={handleUpdate}
        />
      </FieldGroup>

      <FieldGroup>
        <ArtistIdField
          name="shopArtistIds"
          queryString={name}
          onChange={(event, value) => {
            updateEntry({
              formId: form,
              data: { shops: value },
            });
          }}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldUpdatable
          name="isniNumber"
          label={t('artist-isni-number')}
          helperText={t('artist-helptext-short-isni-number')}
          onClickHelp={handleIsniClickHelp}
          onChange={handleIsniChange}
          component={NewInputField}
        />
      </FieldGroup>
      {children}
    </Form>
  );
};

const validate = (values: FormData) => {
  const errors: any = {};
  errors.names = !values.names?.[0]?.name
    ? [{ name: 'required' }]
    : hasEmoji(values.names?.[0]?.name)
      ? [{ name: 'emojis-not-allowed' }]
      : undefined;

  return errors;
};

const ArtistEditReduxForm = reduxForm<FormData, Props>({
  validate,
  getFormState: (state) => state.form,
})(SingleEditForm);

const ArtistEditReduxFormWithData = ({
  form,
  id,
  ...props
}: { id: number | string; children?: React.ReactNode } & ConfigProps<
  FormData,
  Props
>) => {
  const {
    request: { loaded },
    entry,
  } = useArtist({ id });

  const initialValues = useMemo<FormData>(
    () =>
      entry
        ? {
          names: entry.namesNormalized?.sort(
            (a, b) =>
              new Date(a.createdAt).getDate() -
              new Date(b.createdAt).getDate()
          ),
          isniNumber: entry.isniNumber,
          defaultLanguageId: entry.defaultLanguageId,
          shopArtistIds: Array.isArray(entry.shopArtistIds)
            ? {}
            : entry.shopArtistIds,
        }
        : {},
    [entry]
  );
  if (!loaded || !entry) {
    return (
      <Centered>
        <LoadingIndicator />
      </Centered>
    );
  }
  return (
    <ArtistEditReduxForm
      id={Number(id)}
      key={form}
      form={form}
      initialValues={initialValues}
      name={entry.defaultName?.name || ''}
      {...props}
    />
  );
};

export default ArtistEditReduxFormWithData;
