//
import * as R from 'ramda';
import {
  generateIdLens,
  reducerFactoryCreator,
  requestReducerFactory,
} from '../base';

const setPending = (updater) => (value) =>
  R.over(
    R.lensProp('pendingUpdate'),
    R.compose(updater, R.defaultTo([]))
  )(value);

export const updateStateCreator = {
  success: () => ({
    updating: false,
    updated: Date.now(),
    errors: null,
    errorMessage: null,
    error: null,
  }),
  request: () => ({
    updating: true,
  }),
  failure: (action) => ({
    updating: false,
    statusCode: action && action.statusCode,
    errorMessage: action && action.error && action.error.message,
    errors: action && action.error && action.error.errors,
  }),
};

export const handleActionReducerFactory =
  requestReducerFactory(updateStateCreator);

export default function updateRequestReducerFactory(updateAction, getItemId) {
  const innerReducer = handleActionReducerFactory(updateAction);
  const reducer = reducerFactoryCreator(innerReducer)((innerAction) => {
    if (innerAction.payload.componentId) {
      return R.lensPath([
        'awaitingComponents',
        innerAction.payload.componentId,
      ]);
    }
    const itemId = getItemId
      ? getItemId(innerAction)
      : R.view(R.lensPath(['payload', 'id']), innerAction);
    return generateIdLens(itemId);
  });
  return (state, action) => {
    switch (action.type) {
      case updateAction.REQUEST:
      case updateAction.SUCCESS:
      case updateAction.FAILURE: {
        const pendingUpdates = action.payload?.id
          ? [action.payload.id]
          : Array.isArray(action.payload?.data)
            ? action.payload.data.reduce(
                (acc, { id }) =>
                  Array.isArray(id) ? [...acc, ...id] : [...acc, id],
                []
              )
            : null;

        const pendingUpdater = pendingUpdates
          ? action.type === updateAction.REQUEST
            ? setPending(R.union(pendingUpdates))
            : setPending(R.without(pendingUpdates))
          : R.identity;

        return pendingUpdater(reducer(state, action));
      }
      default:
        return state;
    }
  };
}
