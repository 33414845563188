//
import { action } from '../base';

export const types = {
  SET_COVER_IN_STORE: '@releases/SET_COVER_IN_STORE',
  VALIDATE_BARCODE: '@releases/VALIDATE_BARCODE',
  ADD_EMPTY_VOLUME: '@releases/ADD_EMPTY_VOLUME',
  COVER_THUMBNAIL_CREATED: '@releases/COVER_THUMBNAIL_CREATED',
  UPDATE_COVER_STATUS: '@releases/UPDATE_COVER_STATUS',
  UPLOAD_RELEASE_WITH_RELATED_DATA:
    '@releases/UPLOAD_RELEASE_WITH_RELATED_DATA',
  UPLOAD_COVER: '@releases/UPLOAD_COVER',
};

export const setCoverInStore = (id: number, cover: unknown) =>
  action(types.SET_COVER_IN_STORE, { id, cover });

export const validateBarcode = (payload: unknown) =>
  action(types.VALIDATE_BARCODE, payload);

export const addCover = (
  id: number,
  coverTypeName: string,
  downloadUrl: string
) => action(types.COVER_THUMBNAIL_CREATED, { id, coverTypeName, downloadUrl });

export const updateCoverStatus = (payload: unknown) =>
  action(types.UPDATE_COVER_STATUS, payload);

export const addEmptyVolume = ({
  releaseId,
  volumeId,
}: {
  releaseId: number;
  volumeId: number;
}) => action(types.ADD_EMPTY_VOLUME, { releaseId, volumeId });

export const uploadReleaseWithRelatedData = (
  payload: unknown,

  meta: any
) => action(types.UPLOAD_RELEASE_WITH_RELATED_DATA, payload, meta);

export const uploadCover = (payload: unknown, meta?: any) =>
  action(types.UPLOAD_COVER, payload, meta);

export const actions = {
  uploadCover,
  uploadReleaseWithRelatedData,
  validateBarcode,
};
