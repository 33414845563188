//
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body } from 'imdui';

const TableHeaderWrapper = styled.thead`
  top: 0px;
`;

const CellWrapper = styled.th`
  font-weight: 400;
  ${Body} {
    font-weight: 400;
    color: var(--fg-2);
  }
`;

const TableHeader = ({ fields }) => {
  const { t } = useTranslation();

  return (
    <TableHeaderWrapper>
      <tr>
        {fields.map(({ dataKey, id, label, textAlign }) => (
          <CellWrapper key={`${id || dataKey}`} style={{ textAlign }}>
            <Body>{t(label)}</Body>
          </CellWrapper>
        ))}
      </tr>
    </TableHeaderWrapper>
  );
};

export default TableHeader;
