/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import type { RouteComponentProps } from 'react-router-dom';
import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom';
import { AutosizeGrid, Card } from 'imdui';
import { simplifyBundleStatus, useRelease } from 'imddata';
import styled from '@emotion/styled';
import {
  ArtistSideEditor,
  ContributorSideEditor,
  PublisherSideEditor,
  ReleaseTracksList,
  ReleaseDetails,
  ReturnNavigation,
  ReleaseGridCard,
  MusicDistributionInfo,
  MusicDistributionAction,
  StaticReleasePageAction,
  StaticReleasePageInfo,
} from 'components';
import { EditMusicDistribution } from './screens';

const collect = { currentDeliveryBundle: true };

const ReleaseDetailsStyled = styled(Card)`
  padding: 24px;
  grid-column: 1 / -1;
`;
const ReleaseTracksListStyled = styled(ReleaseTracksList)`
  grid-column: 1 / -1;
`;

const ReleasePageTemplate: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('page-title-library-release')}</title>
      </Helmet>
      <AutosizeGrid minWidth={520} maxColumnCount={2} gap={24}>
        {children}
      </AutosizeGrid>

      <ContributorSideEditor isAdaptive={false} />

      <ArtistSideEditor isAdaptive={false} />

      <PublisherSideEditor isAdaptive={false} />
    </>
  );
};

const displayedFields = {
  copyrightText: true,
  publishers: false,
  performers: true,
  audioLanguageId: false,
};

const Release: React.FC<RouteComponentProps<{ id: string }>> = ({
  match: {
    path,
    url,
    params: { id },
  },
}) => {
  const { t } = useTranslation();

  const {
    entry: release,
    request: { failed, loaded },
    refresh,
  } = useRelease({ id, collect });

  useEffect(() => {
    refresh();
  }, []);

  const disabled = !release;

  if (failed) {
    return <Redirect to="/library/releases" />;
  }
  if (!release) {
    return (
      <ReleasePageTemplate>
        <ReleaseDetailsStyled />
      </ReleasePageTemplate>
    );
  }

  const fieldUpdateRequest = release.states?.updateRequest?.find(
    (s) => s.status === 'requested'
  );

  const inReview = !!fieldUpdateRequest?.id;
  const simpleStatus = simplifyBundleStatus(
    release?.currentDeliveryBundleStatus,
    inReview,
    release?.deliveryStatus
  );

  const inDeliverySettings = useRouteMatch(`${path}/delivery`);
  const inRoot = useRouteMatch({ path, exact: true });

  return (
    <>
      <ReturnNavigation
        text={
          inDeliverySettings
            ? `${t('release')} / ${t('edit-music-distribution')}`
            : t('release')
        }
        to={inDeliverySettings ? url : '/library/releases'}
      />
      <>
        <ReleasePageTemplate>
          <ReleaseDetailsStyled>
            <ReleaseDetails editable={!!inRoot} release={release} />
          </ReleaseDetailsStyled>

          <Switch>
            <Route
              path={`${path}`}
              exact={true}
              render={() => (
                <>
                  <ReleaseGridCard
                    title={t('music-distribution')}
                    loading={
                      !loaded ||
                      !!(
                        !release?.currentDeliveryBundle &&
                        release?.currentDeliveryBundleId
                      )
                    }
                    action={<MusicDistributionAction release={release} />}
                  >
                    <MusicDistributionInfo
                      releaseId={release.id}
                      releaseDeliveryStatus={release.deliveryStatus}
                      bundle={release?.currentDeliveryBundle}
                      status={simpleStatus}
                    />
                  </ReleaseGridCard>

                  <ReleaseGridCard
                    title={t('release-page')}
                    action={
                      <StaticReleasePageAction
                        releasePageState={release.states?.releasePages}
                        releaseId={release.id}
                      />
                    }
                  >
                    <StaticReleasePageInfo
                      releasePageState={release?.states?.releasePages}
                    />
                  </ReleaseGridCard>
                  {/*
                  // TODO: Type ReleaseTracksList
                // @ts-ignore */}
                  <ReleaseTracksListStyled
                    displayedFields={displayedFields}
                    disabled={disabled}
                    hideReleaseName={true}
                    releaseId={id}
                  />
                </>
              )}
            />

            <Route
              path={`${path}/delivery`}
              render={() => {
                if (!release.currentDeliveryBundleId) {
                  return <Redirect to={url} />;
                }
                return (
                  <div style={{ gridColumn: 'span 2' }}>
                    <EditMusicDistribution />
                  </div>
                );
              }}
            />

            <Route path={`${path}`} render={() => <Redirect to={url} />} />
          </Switch>
        </ReleasePageTemplate>
      </>
    </>
  );
};

export default Release;
