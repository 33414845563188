//
import { entities } from '../../../../actionTypes';
import uploadFileRequestReducerFactory from '../../../../reducerFactories/uploadFileRequestReducerFactory';
// import { REFRESH_ENTITY } from '../../../../actionTypes/request';

import {
  entityRequestReducerFactory,
  requestInitalState,
} from '../../../../reducerFactories';

const initialState = {
  ...requestInitalState(),
  items: {
    loaded: false,
    items: {},
  },
};

const fileReducer = uploadFileRequestReducerFactory(
  entities.salesImports.types.file
);

const salesImports = entityRequestReducerFactory({
  entityKey: 'salesImports',
  fetchActions: [entities.salesImports.types.fetch],
  createActions: [entities.salesImports.types.create],
  updateActions: [entities.salesImports.types.update],
});

export default function salesImportsRequestReducer(
  state = initialState,
  action
) {
  return [salesImports, fileReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
