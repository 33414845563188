// @flow
import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Subheading, Caption } from 'imdshared';
import { BlockTemplate } from 'components';

const ContentWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 16px;
  min-height: 96px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  ${Caption} {
    display: block;
    margin-bottom: 8px;
    color: ${(props) => props.theme.foreground.secondary};
  }
`;

const priceRenderer: React.FC = ({ children }) => (
  <Subheading data-test-id="totalRevenue">{children}</Subheading>
);

export const TotalOverview: React.FC<{
  translationPrefix: string;
  tokenUsedCount: number;
  revenue: React.ReactNode;
}> = ({ revenue, translationPrefix, tokenUsedCount = 0 }) => {
  const { t } = useTranslation();

  return (
    <BlockTemplate title={t(`${translationPrefix}-overview-title`)}>
      <ContentWrapper>
        <div>
          <Caption>{t(`${translationPrefix}-total`)}</Caption>
          {priceRenderer({ children: revenue })}
        </div>

        <div>
          <Caption>{t(`${translationPrefix}-user-count`)}</Caption>
          <Subheading data-test-id="usedCount">{tokenUsedCount}</Subheading>
        </div>
      </ContentWrapper>
    </BlockTemplate>
  );
};
