import React, { createContext, useCallback, useContext, useState } from 'react';
import { camelize } from 'humps';
import { useSettings, useIsAdmin } from 'imddata';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  CenterWrapper,
  Headline,
  DescriptionCloud as DescriptionCloudStyled,
  IconsCollection,
} from 'imdui';
import {
  TopWrapper,
  descriptionCloudStyle,
  childrenWrapperStyle,
} from './styles';

type Props = {
  title?: React.ReactNode;
  descriptionKey?: string;
  children: React.ReactNode;

  headerClassName?: string;
  className?: string;
};

export const DescriptionCloud = ({
  descriptionKey,
  hasToolbar,
}: {
  descriptionKey: string;
  hasToolbar?: boolean;
}) => {
  const { settings, updateSettings } = useSettings();
  const { t } = useTranslation();

  const [noticeHidden, setNoticeHidden] = useState<boolean>(
    !!settings?.notices?.[camelize(descriptionKey)]?.viewed
  );

  const handleClickHideDescription = () => {
    if (descriptionKey) {
      setNoticeHidden(true);
      updateSettings({
        notices: {
          ...(settings?.notices || {}),
          [camelize(descriptionKey)]: { viewed: true },
        },
      });
    }
  };

  if (noticeHidden) return null;

  return (
    <DescriptionCloudStyled
      css={hasToolbar && descriptionCloudStyle}
      text={t(descriptionKey)}
      actionButtonText={t('got-it')}
      onClickAction={handleClickHideDescription}
    />
  );
};

export const useIntroPage = ({ pageId }: { pageId: string }) => {
  const { settings, updateSettings } = useSettings();
  const isAdmin = useIsAdmin();

  const [hidden, setHidden] = useState<boolean>(
    () => isAdmin || !!settings?.introPages?.[pageId]?.viewed
  );

  const close = useCallback(() => {
    setHidden(true);
    updateSettings({
      introPages: {
        ...(settings?.introPages || {}),
        [pageId]: { viewed: true },
      },
    });
  }, []);

  const open = useCallback(() => {
    setHidden(false);
  }, []);

  return {
    open,
    close,
    isOpen: !hidden,
  };
};

export const IntroPageContext = createContext<{ open?: () => void }>({});

const PageTemplate: React.FC<Props> = ({
  title,
  children,
  headerClassName,
  className,
}) => {
  const { open } = useContext(IntroPageContext);
  return (
    <>
      {title ? (
        <TopWrapper className={headerClassName}>
          <Headline>
            {title}
            {open && !!title && (
              <IconButton
                newIcon={IconsCollection.info}
                onClick={open}
                iconProps={{ size: 16 }}
                tabIndex={-1}
              />
            )}
          </Headline>
        </TopWrapper>
      ) : (
        <TopWrapper />
      )}

      <CenterWrapper css={childrenWrapperStyle} className={className}>
        {children}
      </CenterWrapper>
    </>
  );
};

export default PageTemplate;
