import React, {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Dropdown, Button, DateRangeController } from 'imdshared';
import type { Moment } from 'moment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ClickableButton } from 'imdui';
import type { FocusedInputShape } from 'react-dates';
import { isSameDay } from 'react-dates';
import { InputCalendarIcon as CalendarIcon } from '@imus/base-ui';

const formatDateRange = (
  startDate: Moment,
  endDate: Moment | null,
  focusedInput: FocusedInputShape | null,
  hoveredDate: Moment | null
) => {
  return (
    <>
      <span
        style={
          focusedInput === 'endDate' ? { fontWeight: 'normal' } : undefined
        }
      >
        {(focusedInput === 'startDate'
          ? hoveredDate || startDate
          : startDate
        ).format('MMM DD')}
      </span>{' '}
      &ndash;{' '}
      <span
        style={
          focusedInput === 'startDate'
            ? { fontWeight: 'normal' }
            : focusedInput === 'endDate'
              ? { width: '50px', display: 'inline-block' }
              : undefined
        }
      >
        {(focusedInput === 'endDate'
          ? hoveredDate || endDate
          : endDate
        )?.format('MMM DD') || <>To</>}
      </span>
    </>
  );
};

const LAST_DAYS_OPTIONS = [7, 14, 28];

export const createLastDaysOptions = (endDate: Moment) =>
  LAST_DAYS_OPTIONS.map((daysBefore) => {
    const dayBeforeStart = moment(endDate).subtract(daysBefore - 1, 'days');
    return {
      length: daysBefore,
      endDate,
      startDate: dayBeforeStart,
    };
  });

const ButtonRangeRow = styled.div`
  display: flex;
  padding: 8px 8px 16px;
  & > ${ClickableButton} {
    flex: 1;
    margin: 0 8px 0 8px;
    background-color: transparent;
    justify-content: center;
  }
`;

export type Range = {
  endDate: Moment | null;
  startDate: Moment | null;
};
type Props = {
  disabled: boolean;
  onChange: (d: Range) => void;
  value: Range;
  availableRange: Range | null;
};

const DateRangeButton: React.FC<Props> = ({
  disabled,
  onChange,
  availableRange,
  value = { endDate: null, startDate: null },
}) => {
  const [opened, setOpened] = useState(false);
  const [hoveredDate, setHoveredDate] = useState<Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const collapse = useCallback(() => {
    setFocusedInput(null);
    setHoveredDate(null);
    setOpened(false);
  }, []);

  const [{ endDate, startDate }, setDates] = useState<{
    startDate: null | Moment;
    endDate: null | Moment;
  }>(() => value);
  useEffect(() => {
    setDates(value);
  }, [value]);
  const open = useCallback(() => {
    setFocusedInput('startDate');
    setOpened(true);
  }, [endDate, startDate]);
  const ref = useRef<HTMLDivElement>();

  const onDatesChange = useCallback(
    (d) => {
      // Always unset endAt if was set before
      if (
        startDate &&
        endDate &&
        !isSameDay(d.startDate, startDate) &&
        isSameDay(d.endDate, endDate)
      ) {
        setDates({ startDate: d.startDate, endDate: null });
        return;
      }
      if (onChange) {
        onChange(d);
      }
      setDates(d);
    },
    [endDate, startDate]
  );

  const lastDaysOptions = useMemo(
    () =>
      availableRange && availableRange.endDate
        ? createLastDaysOptions(availableRange.endDate)
        : [],
    [availableRange?.endDate]
  );

  const renderCalendarInfo = useCallback(() => {
    if (!availableRange) return null;
    if (availableRange.endDate === null) return null;
    return (
      <ButtonRangeRow>
        {lastDaysOptions.map(({ length, startDate: dayBeforeStart }) => {
          const isActive =
            endDate &&
            availableRange.endDate &&
            startDate &&
            isSameDay(availableRange.endDate, endDate) &&
            isSameDay(dayBeforeStart, startDate);
          return (
            <Button
              key={length}
              text={`Last ${length} days`}
              disabled={!!isActive}
              onClick={() => {
                const v = {
                  startDate: dayBeforeStart,
                  endDate: availableRange.endDate,
                };
                setDates(v);
                onChange(v);
                collapse();
              }}
            />
          );
        })}
      </ButtonRangeRow>
    );
  }, [endDate, onChange, startDate, availableRange]);

  const { t } = useTranslation();

  const text = t('select-dates');
  const buttonText = startDate
    ? formatDateRange(startDate, endDate, focusedInput, hoveredDate)
    : text;

  const isOutsideRange = useCallback(
    (day: Moment) => {
      if (
        !availableRange ||
        !availableRange.startDate ||
        !availableRange.endDate
      )
        return true;

      return !day.isBetween(
        availableRange.startDate,
        availableRange.endDate,
        'day',
        '[]'
      );
    },
    [availableRange]
  );
  return (
    <>
      <Button
        disabled={disabled}
        iconLeft={CalendarIcon}
        text={buttonText}
        onClick={open}
        ref={ref}
      />
      {opened && (
        <Dropdown
          // @ts-ignore
          anchorEl={ref}
          maxWidthFix={true}
          onClickOutside={collapse}
        >
          {() => (
            <DateRangeController
              initialVisibleMonth={() => availableRange?.endDate || moment()}
              key="test"
              isOutsideRange={isOutsideRange}
              onOutsideClick={collapse}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              onDatesChange={onDatesChange}
              startDate={startDate}
              onClose={collapse}
              endDate={endDate}
              renderCalendarInfo={renderCalendarInfo}
              minimumNights={0}
            />
          )}
        </Dropdown>
      )}
    </>
  );
};

export default DateRangeButton;
