import type { EntityModels } from '../../../../types';
import type { NestDefinition } from '../../base';
import type { ArtistNested } from './artists';
import { defaultArtistNest } from './artists';

import type { TrackNested } from './tracks';
import { defaultTrackNest } from './tracks';

export const nestReleaseBundles: NestDefinition = [
  {
    key: 'deliveryBundles',
    entity: 'releaseDeliveryBundles',
  },
];

export type ReleaseWithBundlesNested = EntityModels.Nest<
  EntityModels.Release,
  {
    deliveryBundles: EntityModels.ReleaseDeliveryBundle[];
  }
>;

export const nestReleaseCovers: NestDefinition = [
  {
    key: 'defaultNameNormalized',
    entity: 'releaseNames',
  },
  {
    key: 'covers',
    entity: 'releaseCovers',
    nest: [
      {
        collect: true,
        key: 'file',
        getKey: (entry?: EntityModels.ReleaseCover) => entry?.fileId,
        entity: 'files',
      },
    ],
  },
];

export type ReleaseWithCoversNested = EntityModels.Nest<
  EntityModels.Release,
  {
    defaultNameNormalized: EntityModels.ReleaseName;
    covers: EntityModels.Nest<
      EntityModels.ReleaseCover,
      { file: EntityModels.File }
    >[];
  }
>;

export type ReleaseNested = EntityModels.Nest<
  EntityModels.Release,
  {
    artists: EntityModels.Nest<
      EntityModels.ReleaseArtist,
      { artist: ArtistNested }
    >[];
    label: EntityModels.Label;
    genre: EntityModels.Genre;
    currentDeliveryBundle?: EntityModels.DeliveryBundle;
    defaultNameNormalized: EntityModels.ReleaseName;
    namesNormalized: EntityModels.ReleaseName[];
    covers: EntityModels.Nest<
      EntityModels.ReleaseCover,
      { file: EntityModels.File }
    >[];
    volumes: Array<
      Array<
        EntityModels.Nest<EntityModels.ReleaseTrack, { track: TrackNested }>
      >
    >;
  }
>;

export type ReleasesNested = EntityModels.Nest<
  EntityModels.Release,
  {
    label: EntityModels.Label;
    currentDeliveryBundle: EntityModels.DeliveryBundle;
    defaultNameNormalized: EntityModels.ReleaseName;
    namesNormalized: EntityModels.ReleaseName[];
    covers: EntityModels.Nest<
      EntityModels.ReleaseCover,
      { file: EntityModels.File }
    >[];
  }
>;

export const createReleaseNest = (
  collect: {
    currentDeliveryBundle?: boolean;
    covers?: boolean;
    tracks?: boolean;
    artists?: boolean;
  } = {}
): NestDefinition => [
  {
    key: 'label',
    getKey: (entry?: EntityModels.Release) => entry?.labelId,
    entity: 'labels',
    // collect: true, // filter.ids doesn't work
  },
  {
    key: 'genre',
    getKey: (entry?: EntityModels.Release) => entry?.genreId,
    entity: 'genres',
  },
  {
    key: 'currentDeliveryBundle',
    getKey: (entry?: EntityModels.Release) => entry?.currentDeliveryBundleId,
    entity: 'deliveryBundles',
    collect: collect.currentDeliveryBundle,
  },
  {
    key: 'defaultNameNormalized',
    entity: 'releaseNames',
  },
  {
    key: 'artists',
    entity: 'releaseArtists',
    nest: [
      {
        collect: collect.artists,
        getKey: (entry?: EntityModels.ReleaseArtist) => entry?.artistId,
        entity: 'artists',
        key: 'artist',
        nest: defaultArtistNest,
      },
    ],
  },
  {
    entity: 'releaseTracks',
    key: 'volumes',
    nest: [
      {
        getKey: (entry?: EntityModels.ReleaseTrack) => entry?.trackId,
        collect: collect.tracks,
        entity: 'tracks',
        key: 'track',
        nest: defaultTrackNest,
      },
    ],
  },
  {
    key: 'covers',
    entity: 'releaseCovers',
    nest: [
      {
        collect: collect.covers,
        key: 'file',
        getKey: (entry?: EntityModels.ReleaseCover) => entry?.fileId,
        entity: 'files',
      },
    ],
  },
];

export const listNest = createReleaseNest({
  covers: true,
  tracks: false,
});

export const defaultReleaseNest = createReleaseNest({
  artists: true,
  covers: true,
  tracks: true,
  currentDeliveryBundle: true,
});
