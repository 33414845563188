import styled from '@emotion/styled';

export const ProductTwoColumnsWrapper = styled.div`
  flex: 400px;
  flex-grow: 100;
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
  gap: 32px;
  flex-direction: row-reverse;
  & > *:nth-child(2) {
    flex: 400px;
    flex-grow: 100;
  }
  & > *:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex: 400px;
    flex-grow: 1;
    align-items: flex-start;
  }
`;
