import { Body, PageLayer, Content, Button } from 'imdui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import config from 'imdconfig';
import type { EntityModels } from 'imddata';
import { useInView } from 'react-intersection-observer';
import {
  useIsAdmin,
  useCurrentCustomer,
  useSettings,
  useEntries,
  useQuery,
} from 'imddata';
import { useCurrentLocale } from 'imdshared';
import moment from 'moment';

const NotesLayer = styled(PageLayer)`
  max-width: 768px;
  background: #fafafa;
`;

const Note = styled.div`
  padding: 16px;
  background: #fff;
  border-radius: ${({ theme }) => theme.component.radius};
  display: grid;
  gap: 24px;
`;
const NotesWrapper = styled.div`
  flex: 1;
  max-width: 512px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  gap: 16px;
`;

const NoteEntry = styled.div`
  display: grid;
  position: relative;
  gap: 8px;
  &:first-child {
    ${Content} {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.15px;
      margin-bottom: 8px;
    }
  }
  ${Content} {
    font-weight: 700;
  }
  img {
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 16px;
  }
`;
const CloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  gap: 16px;
  margin-top: auto;

  position: sticky;
  height: 120px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 2;
`;

// Query with lang
// On close store last_viewed_note_at date to customer settings
// Show if last_viewed_release_note_at date < last received release note created_at
// if last_viewed_release_note_at is undefined fallback to (customer.created_at + 1day) date to avoid showing release notes to newcomers

export default function ReleaseNotesLayer() {
  const { entry: customer } = useCurrentCustomer();
  const { settings, updateSettings } = useSettings();
  const { t } = useTranslation();
  const locale = useCurrentLocale();
  const isAdmin = useIsAdmin();
  const { query: releaseNotesQuery, queryHash: releaseNotesQueryHash } =
    useQuery({
      query: { locale },
    });
  const { entries: releaseNotes } = useEntries<EntityModels.ReleaseNote>({
    entity: 'releaseNotes',
    query: releaseNotesQuery,
    queryHash: releaseNotesQueryHash,
  });

  const releaseNotesSorted = useMemo(() => {
    return releaseNotes
      ?.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
      .filter((a) =>
        new Date(a.date).valueOf() <= new Date().valueOf() &&
        settings.lastViewedReleaseNoteDate
          ? new Date(a.date).valueOf() >
            new Date(settings.lastViewedReleaseNoteDate).valueOf()
          : true
      );
  }, [releaseNotes]);

  const [isOpened, setIsOpened] = useState(() => {
    return false;
  });

  useEffect(() => {
    if (!customer || !releaseNotesSorted?.length || isAdmin) return;

    const [firstNote] = releaseNotesSorted;

    const lastViewed = settings.lastViewedReleaseNoteDate
      ? moment(settings.lastViewedReleaseNoteDate)
      : moment(customer.createdAt).add(1, 'day');

    if (lastViewed.valueOf() < moment(firstNote.date).valueOf()) {
      setIsOpened(true);
    }
  }, [!!releaseNotesSorted?.length, isAdmin]);

  const handleClose = useCallback(
    (type: 'outside' | 'x-button' | 'event' | 'action' | undefined) => {
      if (window && window.analytics) {
        window.analytics.track('FT Release Notes close', { event_type: type });
      }
      setIsOpened(false);
      if (releaseNotesSorted.length) {
        updateSettings({
          lastViewedReleaseNoteDate: releaseNotesSorted[0].date,
        });
      }
    },
    [releaseNotesSorted]
  );

  const handleWindowClose = useCallback(
    (_e, type: 'outside' | 'x-button' | 'event' | undefined) => {
      handleClose(type);
    },
    [handleClose]
  );

  const handleActionClose = useCallback(() => {
    handleClose('action');
  }, [handleClose]);

  const sentScroll = useRef(false);

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (window?.analytics && !sentScroll.current && inView) {
      window.analytics.track('FT Release Notes scrolled to bottom');
      sentScroll.current = true;
    }
  }, [inView]);

  return (
    <>
      <NotesLayer
        title={t('release-notes')}
        isOpen={isOpened}
        closeOnLocation={true}
        lockClickOutside={false}
        close={handleWindowClose}
      >
        <NotesWrapper>
          {releaseNotesSorted.map((rn) => (
            <Note key={rn.id}>
              {rn.entries.map((entry, idx) => (
                <NoteEntry key={idx}>
                  {entry.title && <Content>{entry.title}</Content>}
                  {entry.body && <Body>{entry.body}</Body>}
                  {entry.image && (
                    <img
                      alt={''}
                      src={config.contentUploads + '/' + entry.image}
                    ></img>
                  )}
                </NoteEntry>
              ))}
            </Note>
          ))}
          <div ref={ref} />

          <CloseWrapper>
            <Button
              position="center"
              style={{ width: '100%' }}
              primary={true}
              onClick={handleActionClose}
              text={t('got-it').toUpperCase()}
            />
          </CloseWrapper>
        </NotesWrapper>
      </NotesLayer>
    </>
  );
}
