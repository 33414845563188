import styled from '@emotion/styled';
import { Caption, H3 } from '../../../text';
import { Chip } from '../../button/Chip';
import SVGIcon from '../../icon/SVGIcon';
import Card from '../Card';

export const CardContainer = styled(Card)`
  margin-top: 16px;
  overflow: hidden;
`;

export const TopBar = styled.div<{
  collapsed: boolean;
}>`
  padding: 8px 16px 8px 24px;
  min-height: 48px;
  box-shadow: 0 1px 0 0 ${({ theme }): string => theme.lights[2]};
  cursor: pointer;
  align-items: center;
  display: flex;
  flex: 1;
  background-color: ${({ collapsed }): string =>
    collapsed ? 'white' : '#fafafa'};

  > ${Caption} {
    display: block;
    margin: 4px 0;
  }
`;

export const InfoRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const TextRow = styled.div`
  flex: 1;
  overflow: hidden;

  > ${H3} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > ${H3}:first-child {
    margin-right: 6px;
    color: ${({ theme }): string => theme.foreground.disabled};
  }

  > ${H3}:last-child {
    flex: 1;
  }
`;

export const StyledChip = styled(Chip)`
  > span {
    margin: 0 8px;
  }
`;

export const ExpandIcon = styled(SVGIcon)<{
  collapsed: boolean;
}>`
  margin-left: 8px;
  transform: rotate(${({ collapsed }): number => (collapsed ? 0 : 180)}deg);
`;
