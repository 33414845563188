import React from 'react';
import { Button } from 'imdshared';
import { footerStyle } from '../../../../shared';

const PayButton = ({
  label,
  onCancel,
  ...props
}: {
  label: string;
  onCancel?: () => void;
} & React.ComponentProps<typeof Button>) => {
  return (
    <div css={footerStyle}>
      <Button {...props} text={label} />
    </div>
  );
};

export default PayButton;
