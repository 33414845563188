import { css } from '@emotion/react';

export const subheadingStyle = css`
  margin-bottom: 32px;
  span {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
  }
`;

export const sectionStyle = css`
  margin: 16px;
  padding-bottom: 16px;
  box-shadow: inset 0 -1px 0 0 var(--outline-var);

  @media (min-width: 1020px) {
    &:last-child {
      box-shadow: none;
    }
  }

  @media screen and (min-width: 400px) {
    margin: 32px 32px;
    padding-bottom: 32px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const artworkSection = css`
  padding: 16px 12px;
  @media screen and (min-width: 400px) {
    padding: 24px 32px 24px;
  }
`;

export const fieldStyle = css`
  margin-bottom: 24px;
`;

export const isCompilationStyle = css`
  margin-top: -8px;
`;

export const displayArtistStyle = css`
  display: block;
  margin-bottom: 8px;
`;
