import styled from '@emotion/styled';

const SecondaryButton = styled.button<{
  active?: boolean;
}>`
  border-radius: 4px;
  height: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ active }): number => (active ? 500 : 400)};
  text-decoration: ${({ active }): string => (active ? 'none' : 'underline')};
  color: ${({ theme, active }): string =>
    active ? theme.accent.green : theme.foreground.secondary};
  transition: color 0.1s ease-in-out;

  :hover {
    color: ${({ theme, active }): string =>
      active ? theme.accent.green : theme.foreground.primary};
  }
`;

export default SecondaryButton;
