import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import styled from '@emotion/styled';
import { DropContext } from './DropContext';

const activeStyle = `
  background-color: rgba(229, 229, 229, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const DropObserverOverlay = styled.div<{ isActive?: boolean }>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  ${({ isActive }) => isActive && activeStyle}
`;

export default function DropObserver({
  children,
  accepts,
  className,
  style,
}: {
  children: React.ReactNode;
  accepts: string[];
  className?: string;
  style?: React.CSSProperties;
}) {
  const container = useRef<HTMLDivElement | null>(null);
  const hasDropListeners =
    !!container.current && container.current.children.length > 0;

  const [{ isOver }, dropHandler] = useDrop(
    () => ({
      accept: accepts,
      collect: (m) => ({ isOver: m.isOver() }),
    }),
    [accepts]
  );
  return (
    <DropContext.Provider value={container.current}>
      <div ref={dropHandler} className={className} style={style}>
        {children}

        <DropObserverOverlay
          isActive={hasDropListeners && isOver}
          ref={container}
        />
      </div>
    </DropContext.Provider>
  );
}
