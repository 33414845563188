import React from 'react';
import SelectField from '../SelectField';
import type { FieldProps } from '../SelectField';

const CURRENT_YEAR = new Date().getFullYear() + 1;
const YEARS: { value: number; label: string }[] = [];

for (let i = 0; i < 100; i += 1) {
  YEARS.push({ value: CURRENT_YEAR - i, label: (CURRENT_YEAR - i).toString() });
}

const YearSelectorField = (props: Omit<FieldProps, 'data'>) => {
  return <SelectField {...props} data={YEARS} />;
};

export default YearSelectorField;
