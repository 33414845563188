/* eslint-disable jsx-a11y/click-events-have-key-events */
//
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { palette } from '../../helpers';

const Version = styled.span`
  font-weight: 700;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  z-index: 1000000;
  position: fixed;
  right: 50%;
  min-width: 200px;
  border-radius: 2px 2px 0 0;
  padding: 4px 8px;
  color: white;
  font-size: 10px;
  transform: translate(50%);
  line-height: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
`;

const VersionLabel = ({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => {
  const [isHidden, setIsHidden] = useState(false);

  if (!__DEVELOPMENT__) {
    return null;
  }

  return (
    <Wrapper
      className={className}
      style={{
        backgroundColor: palette.dark.xDark,
        ...style,
        opacity: isHidden ? 0 : 1,
      }}
      onClick={() => {
        setIsHidden(!isHidden);
      }}
    >
      stage:{' '}
      <span
        style={{
          marginRight: 5,
          color: palette.warning,
          fontWeight: 'bold',
        }}
      >
        {process.env.TARGET_ENV}
      </span>
      <br />
      version: <Version>{__VERSION__}</Version>
    </Wrapper>
  );
};

export default VersionLabel;
