import { useEffect, useState } from 'react';
import type { ReduxState } from 'imddata';
import { useCustomerFeature } from 'imddata';
import {
  Button,
  BuyOrSubOffer,
  Caption,
  Content,
  TextFormatted,
  useCurrentLocale,
  useProductPriceFormatted,
} from 'imdshared';
import { useTranslation } from 'react-i18next';
import { prefillBeaconForDelivery } from './prefillBeaconForDelivery';
import { ContinueWindow } from '../ContinueWindow';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { H4 } from 'imdui';
import { BodyS } from '@imus/services-ui/src/Text';
import { useInitBeacon, useInitFastPayment } from 'components/hooks';

export const RequestChangesWithHelpForm = ({
  releaseId,
  barcode,
}: {
  releaseId: string | number;
  barcode: string;
}) => {
  const hasFeature = useCustomerFeature('post-registration-names-editing');
  const hasBoughtFeature = useSelector(
    (state: ReduxState) =>
      state.entities.releases.entities[releaseId]?.modificationsEnabled
  );
  const canRequest = hasFeature || hasBoughtFeature;
  const locale = useCurrentLocale();
  const price = useProductPriceFormatted('release-modifications');
  const [decideBuy, setDecideBuy] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [openConfirmWindow, setOpenConfirm] = useState(false);
  const location = useLocation();
  const { init } = useInitFastPayment({
    productId: 'release-modifications',
    successPath: location.pathname,
  });
  const { init: initBeacon } = useInitBeacon();
  useEffect(() => {
    if (hasBoughtFeature) {
      initBeacon('fast');
      return () => {
        initBeacon();
      };
    }
  }, [hasBoughtFeature]);
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Content>{t('request-changes-to-delivery')}</Content>
        <Caption>{t('request-changes-to-delivery-short-desc')}</Caption>
      </div>
      <div>
        <ContinueWindow
          isOpen={openWindow}
          disabled={!decideBuy}
          onCancel={() => {
            setOpenWindow(false);
          }}
          onContinue={() => {
            setOpenWindow(false);
            init({ data: { releaseId } });
          }}
          title={t('request-changes-to-delivery')}
        >
          <BuyOrSubOffer
            subscribeContext={{ analytics: { detail: 'delivery-changes' } }}
            price={price}
            title={t('unlock-request-changes-to-delivery')}
            description={
              <TextFormatted
                text={t('unlock-request-changes-to-delivery-desc')}
              />
            }
            onDecideSubscribe={() => {
              setOpenWindow(false);
            }}
            onDecideBuy={setDecideBuy}
            decideBuy={decideBuy}
          />
        </ContinueWindow>

        <ContinueWindow
          isOpen={openConfirmWindow}
          onCancel={() => {
            setOpenConfirm(false);
          }}
          onContinue={() => {
            setOpenConfirm(false);
            if (window.Beacon) {
              window.Beacon('reset');
              window.Beacon('toggle');
              window.Beacon('navigate', '/ask/message');
              window.Beacon('prefill', {
                subject: t('request-changes-to-delivery-subject'),
                text: t('request-changes-to-delivery-text'),
                fields: prefillBeaconForDelivery({
                  locale,
                  barcode,
                }),
              });
            }
          }}
          title={t('request-changes-to-delivery')}
        >
          <div>
            <H4 style={{ marginBottom: '4px' }}>
              {t('request-changes-to-delivery')}
            </H4>
            <BodyS>
              <TextFormatted
                text={t('unlock-request-changes-to-delivery-confirmation')}
              />
            </BodyS>
          </div>
        </ContinueWindow>
        <Button
          size="small"
          onClick={() => {
            if (!canRequest) {
              setOpenWindow(true);
              return;
            }
            setOpenConfirm(true);
          }}
          text={t('request-change')}
        />
      </div>
    </>
  );
};
