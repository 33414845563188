/* eslint-disable react/jsx-no-undef */
import styled from '@emotion/styled';
import { getBaseSubscriptionId, useSubscriptionManagement } from 'components';
import { useCurrentCustomer, noop } from 'imddata';
import type { ReduxState } from 'imddata';
import { useCurrentLocale } from 'imdshared';
import { Button, WindowHeader } from 'imdui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formValueSelector, isDirty } from 'redux-form';
import { Title, Header, Container, Divider } from './styles';
import { getBeaconPrefill } from 'components/subscriptions/CancelSubscription/getBeaconPrefill';
import type { TFunction } from 'i18next';
import moment from 'moment';
import type { SubscriptionId } from 'imddata/types/entities';
import { CancelFlowAmps } from './components/CancelFlowAmps';
import type { CancelEvent, Page } from './types';
import { CancelFlowProducts } from './components/CancelFlowProducts';
import type { FormData } from './components/CancelFlowFeedback';
import {
  CancelFlowFeedback,
  FEEDBACK_FORM,
} from './components/CancelFlowFeedback';
import { CancelFlowSuccess } from './components/CancelFlowSuccess';

const submitFeedbackData = ({ otherText, reasons }: FormData, t: TFunction) => {
  const data = {
    customReason: otherText,
    ...(reasons?.reduce(
      (acc, r, idx) =>
        r
          ? {
              ...acc,
              [`reason-${idx}`]: t(`cancel-subscription-reason-${idx}-label`),
            }
          : acc,
      {}
    ) || {}),
  };

  if (window.analytics) {
    window.analytics.track('FT Subscription Cancellation Feedback', data);
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 512px;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0 auto;
`;

const Actions = styled.div`
  display: flex;
  max-width: 512px;
  width: 100%;
  margin-top: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  & > * {
    max-width: 256px;
    flex: 1;
  }
`;

export const CancelFlow = ({
  onClose,
  downgradeToId = null,
}: {
  onClose: (meta?: CancelEvent) => void;
  downgradeToId: SubscriptionId | null;
}) => {
  const { t } = useTranslation();
  const { currentSubscription, toggleRenewal, selectSubscription } =
    useSubscriptionManagement({ onSubmit: noop });

  const { entry: customer } = useCurrentCustomer();
  const firstName = customer?.firstName;
  const creditsValue = customer!.credits?.find(
    (c) => c.currencyId === 'amp'
  )?.amount;

  const [page, setPage] = useState<Page>(() => {
    switch (currentSubscription?.subscriptionId) {
      case 'tier-2-yearly':
      case 'tier-2-monthly':
        return downgradeToId === 'tier-1' ? 'success' : 'products';

      default:
        return 'products';
    }
  });

  const selectFormValues = useMemo(() => formValueSelector(FEEDBACK_FORM), []);

  const feedbackValues: FormData = useSelector((state: ReduxState) =>
    selectFormValues(state, 'otherText', 'reasons')
  );

  const dirty: boolean = useSelector((state: ReduxState) =>
    isDirty(FEEDBACK_FORM)(state)
  );

  useEffect(() => {
    if (page === 'success' && downgradeToId) {
      selectSubscription({
        subscriptionId: downgradeToId,
        mode: 'downgrade',
      });
    }
  }, [page, downgradeToId]);

  const handleContinue = useCallback(() => {
    switch (page) {
      case 'products': {
        if (downgradeToId) {
          setPage('success');
        } else {
          setPage('amp');
        }
        break;
      }
      case 'amp': {
        setPage('feedback');
        break;
      }
      case 'feedback': {
        setPage('success');
        toggleRenewal();
        submitFeedbackData(feedbackValues, t);
        break;
      }
      case 'success': {
        console.log('should not happen');
      }
    }
  }, [page, feedbackValues]);

  const locale = useCurrentLocale();

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('reset');
      window.Beacon('prefill', {
        subject: t('need-help-with-subscription-subject', ''),
        text: t('need-help-with-subscription-text', ''),
        fields: getBeaconPrefill(locale),
      });
      window.Beacon('navigate', '/ask/message/');
    }
  }, []);

  if (!currentSubscription) return null;
  const { renewAt: periodEnd } = currentSubscription || {
    renewAt: null,
  };
  const renewalDate = periodEnd ? moment(periodEnd).format('LL') : null;

  const renewalText = renewalDate
    ? `${t('subscription-ends-on')} ${renewalDate}`
    : undefined;

  const subVariant = getBaseSubscriptionId(downgradeToId || 'free');

  const texts = downgradeToId
    ? {
        title: t('downgrade'),
        action: t('downgrade'),
        ampsTitle: t('your-amps-will-not-accumulate'),
        ampsText: t('your-amps-will-not-accumulate-text'),
        successText: t('soon-you-will-be-downgraded', {
          requestedSubscription: t(subVariant, { context: 'plan' }),
        }),
      }
    : {
        title: t('downgrade'),
        action: t('downgrade'),
        ampsTitle: t('your-amps-will-be-lost'),
        ampsText: t('your-amps-will-be-lost-text'),
        successText: t('soon-you-will-be-downgraded', {
          requestedSubscription: t(subVariant, { context: 'plan' }),
        }),
      };

  return (
    <>
      <Container>
        <WindowHeader>
          <Header style={{ width: '100%', marginLeft: '32px' }}>
            <Title>{texts.title}</Title>
          </Header>
          <Divider />
        </WindowHeader>

        <Wrapper>
          {page === 'amp' && (
            <CancelFlowAmps
              currentSubscriptionId={currentSubscription.subscriptionId}
              text={texts.ampsText}
              value={creditsValue || 0}
              title={texts.ampsTitle}
            />
          )}
          {page === 'products' && (
            <CancelFlowProducts
              firstName={firstName}
              currentSubscriptionId={currentSubscription.subscriptionId}
              downgradeToId={downgradeToId}
              onClose={onClose}
              page={page}
            />
          )}
          {page === 'feedback' && <CancelFlowFeedback firstName={firstName} />}
          {page === 'success' && (
            <CancelFlowSuccess
              firstName={firstName}
              downgradeToId={downgradeToId}
              successText={texts.successText}
              renewalText={renewalText}
              renewalDate={renewalDate}
            />
          )}
        </Wrapper>
        <Actions>
          {page === 'success' ? (
            <Button
              position="center"
              text={t('close')}
              onClick={() => {
                onClose();
              }}
            ></Button>
          ) : (
            <>
              <Button
                position="center"
                text={t('cancel')}
                onClick={() => {
                  onClose({
                    requestedSubscription: subVariant,
                    page,
                    action: 'cancel',
                  });
                }}
              ></Button>
              <Button
                position="center"
                disabled={page === 'feedback' && !dirty}
                text={page === 'feedback' ? texts.action : t('continue')}
                onClick={handleContinue}
                primary={true}
              ></Button>
            </>
          )}
        </Actions>
      </Container>
    </>
  );
};
