import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { set, lensPath } from 'ramda';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, FieldArray, Form } from 'redux-form';
import type { EntityModels } from 'imddata';
import { useContributor, useUpdateEntity } from 'imddata';
import {
  Centered,
  LoadingIndicator,
  HelpWindowContext,
  FieldGroup,
} from 'imdui';
import FieldUpdatable from '../../../fields/FieldUpdatable';
import NewInputField from '../../../fields/InputField';
import NamesField from '../../NamesField';
import { decamelize } from 'humps';

const TRANSLATABLE_FIELDS = [
  {
    name: 'firstName',
    label: 'contributor-first-name',
    shortHelp: 'contributor-helptext-short-first-name',
    longHelp: 'contributor-helptext-first-name',
    testId: 'contributor-first-name',
  },
  {
    name: 'middleName',
    label: 'contributor-middle-name',
    shortHelp: 'contributor-helptext-short-middle-name',
    longHelp: 'contributor-helptext-middle-name',
    testId: 'contributor-middle-name',
  },
  {
    name: 'lastName',
    label: 'contributor-last-name',
    shortHelp: 'contributor-helptext-short-last-name',
    longHelp: 'contributor-helptext-last-name',
    testId: 'contributor-last-name',
  },

  {
    name: 'artistName',
    label: 'contributor-artist-name',
    shortHelp: 'contributor-helptext-short-artist-name',
    longHelp: 'contributor-helptext-artist-name',
    testId: 'contributor-artist-name',
  },
];

type Props = {
  contributorId: number;
};

type FormData = {
  isniNumber: string;
  names: EntityModels.ContributorName[];
};

const SingleEditForm = ({
  form,
  touch,
  contributorId,
}: Props & InjectedFormProps<FormData, Props>) => {
  const { t } = useTranslation();

  const { updateEntry } = useUpdateEntity({
    entity: 'contributors',
    id: contributorId,
  });

  const handleContributorField = (key: string) => (event: any, value: any) => {
    updateEntry({ formId: form, data: { [key]: value } }, { debounce: true });
  };

  const handleUpdate = (data: any) => {
    updateEntry(
      {
        formId: form,
        data,
      },
      { debounce: true }
    );
  };

  useEffect(() => {
    touch('names[0].lastName');
    touch('names[0].firstName');
    touch('names[0].middleName');
    touch('names[0].artistName');
    touch('isniNumber');
  }, []);

  return (
    <HelpWindowContext.Consumer>
      {(showHelpWindow) => (
        <Form data-test-id="contributor-single-edit-form">
          <FieldGroup>
            <FieldArray
              name="names"
              entity="contributorNames"
              component={NamesField}
              languageLabelPrefix="contributor"
              fieldsData={TRANSLATABLE_FIELDS}
              onChange={handleUpdate}
            // @ts-ignore
            />
          </FieldGroup>

          <FieldGroup>
            <FieldUpdatable
              name="isniNumber"
              component={NewInputField}
              label={t('isni-number')}
              // @ts-ignore
              onChange={handleContributorField('isniNumber')}
              helperText={t('contributor-helptext-short-isniNumber')}
              onClickHelp={() => {
                showHelpWindow(
                  t('isni-number'),
                  t('contributor-helptext-isniNumber')
                );
              }}
            />
          </FieldGroup>
        </Form>
      )}
    </HelpWindowContext.Consumer>
  );
};
//
// @ts-ignore
const reduceNameValidation = (name) => (acc, key) => {
  if (!name[key]) {
    return {
      ...acc,
      [key]:
        decamelize(key, {
          separator: '-',
        }) + '-required',
    };
  }
  return acc;
};

// @ts-ignore
const reduceCapsValidation = (name) => (acc, key) => {
  if (name[key] && name[key] === name[key].toUpperCase()) {
    return {
      ...acc,
      [key]: 'contributor-caps-name-forbidden',
    };
  }
  return acc;
};
type ErrorType = Partial<Record<keyof Omit<FormData, 'names'>, string>> & {
  names: EntityModels.ContributorName[];
};

export const validateContributorForm = (values: FormData) => {
  const errors: ErrorType = { names: [] };

  errors.names = values.names?.reduce<EntityModels.ContributorName[]>(
    (acc, name, index) => {
      return set(
        lensPath([index]),
        {
          ...['firstName', 'middleName', 'lastName'].reduce(
            reduceCapsValidation(name),
            {}
          ),
          ...['firstName', 'lastName'].reduce(reduceNameValidation(name), {}),
        },
        acc
      );
    },
    []
  );

  return errors;
};

const reduceLowercase =
  (name: EntityModels.ContributorName) =>
    (
      acc: EntityModels.ContributorName,
      key: 'firstName' | 'middleName' | 'lastName'
    ): any => {
      if (!name[key]) return acc;

      const first = name[key]?.charAt(0);

      if (first === first?.toLowerCase() && first !== first?.toUpperCase()) {
        return {
          ...acc,
          [key]: 'warn-lowercase',
        };
      }
      return {
        ...acc,
        [key]: undefined,
      };
    };

export const warnContributorForm = (values: FormData) => {
  const warnings: ErrorType = { names: [] };
  warnings.names = values.names?.reduce<EntityModels.ContributorName[]>(
    (acc, name, index) => {
      return set(
        lensPath([index]),
        {
          // @ts-ignore
          ...[
            'firstName',
            'middleName',
            'lastName',
            // @ts-ignore
          ].reduce<EntityModels.ContributorName>(reduceLowercase(name), {}),
        },
        acc
      );
    },
    []
  );
  return warnings;
};

const ContributorEditReduxForm = reduxForm<FormData, Props>({
  // @ts-ignore
  validate: validateContributorForm,
  // @ts-ignore
  warn: warnContributorForm,
  // @ts-ignore
})(SingleEditForm);

const ContributorEditReduxFormWithData = ({
  form,
  id,
  ...props
}: {
  id: number;
  form?: string;
}) => {
  const { entry } = useContributor({ id });

  const initialValues = useMemo<FormData>(
    () =>
      entry
        ? {
          isniNumber: entry.isniNumber,
          names: entry.namesNormalized?.sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() -
              new Date(b.createdAt).valueOf()
          ),
          defaultLanguageId: entry.defaultLanguageId,
        }
        : {
          isniNumber: '',
          names: [],
          defaultLanguageId: '',
        },
    [entry]
  );

  if (!entry) {
    return (
      <Centered>
        <LoadingIndicator />
      </Centered>
    );
  }

  return (
    <ContributorEditReduxForm
      contributorId={id}
      form={form}
      initialValues={initialValues}
      {...props}
    />
  );
};

export default ContributorEditReduxFormWithData;
