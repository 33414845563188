//
import { entities } from '../../../../actionTypes';
import { actionTypes } from '../../../../actionTypes/users';

const initialState = {
  searchHistory: {},
  data: {},
};

function settingReducer(state, action) {
  switch (action.type) {
    case entities.settings.types.fetch.SUCCESS:
    case entities.settings.types.update.SUCCESS:
      return {
        data: action.response,
      };

    case actionTypes.loggedUser.fetch.SUCCESS: {
      return {
        data:
          (action.rawResponse.item && action.rawResponse.item.settings) ||
          state.data,
      };
    }
    default:
      return state;
  }
}

export default function logs(state = initialState, action) {
  return [settingReducer].reduce(
    (accState, reducerFn) => reducerFn(accState, action),
    state
  );
}
