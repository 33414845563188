const values = [
  // { value: 'conductor' },
  { value: 'violin-fiddle' },
  { value: 'alto' },
  { value: 'baritone' },
  { value: 'bass' },
  { value: 'bassoon' },
  { value: 'cello' },
  { value: 'clarinet' },
  { value: 'engineer' },
  { value: 'flute' },
  { value: 'french-horn' },
  { value: 'harmonica' },
  { value: 'harp' },
  { value: 'harpsichord' },
  { value: 'mezzo-soprano' },
  { value: 'oboe' },
  { value: 'organ' },
  { value: 'saxophone' },
  { value: 'soprano' },
  { value: 'string-bass' },
  { value: 'tenor' },
  { value: 'trombone' },
  { value: 'trumpet' },
  { value: 'tuba' },
  { value: 'viola' },
  { value: 'horn' },
  { value: 'drum-programming' },
  { value: 'backing-vocals' },
  { value: 'mixer' },
  { value: 'speaker' },
  { value: 'other-instrument' },
  { value: 'classical-artist' },
  { value: 'bass-baritone' },
  { value: 'bass-viol' },
  { value: 'countertenor' },
  { value: 'boy-soprano' },
  { value: 'zither' },
  { value: 'double-bass' },
  { value: 'viola-d-amore' },
  { value: 'twelve-string-guitar' },
  { value: 'acoustic-guitar' },
  { value: 'alto-saxophone' },
  { value: 'alto-solo' },
  { value: 'banjo' },
  { value: 'baritone-saxophone' },
  { value: 'bass-clarinet' },
  { value: 'bass-trombone' },
  { value: 'bongos' },
  { value: 'cajon' },
  { value: 'choir-master' },
  { value: 'chorus' },
  { value: 'classical-guitar' },
  { value: 'clavier' },
  { value: 'congas' },
  { value: 'cornet' },
  { value: 'dj' },
  { value: 'djembe' },
  { value: 'electric-guitar' },
  { value: 'first-violin' },
  { value: 'flugelhorn' },
  { value: 'fretless-electric-bass-guitar' },
  { value: 'guitar-technician' },
  { value: 'hammond-organ' },
  { value: 'harmony-vocals' },
  { value: 'kora' },
  { value: 'lead-guitar' },
  { value: 'lead-vocals' },
  { value: 'librettist' },
  { value: 'mandolin' },
  { value: 'mastering-engineer' },
  { value: 'music-director' },
  { value: 'pedal-steel-guitar' },
  { value: 'piccolo' },
  { value: 'production-assistant' },
  { value: 'rhodes-piano' },
  { value: 'rhythm-guitar' },
  { value: 'second-violin' },
  { value: 'soprano-saxophone' },
  { value: 'sound-engineer' },
  { value: 'steel-guitar' },
  { value: 'tabla' },
  { value: 'tambourine' },
  { value: 'tenor-saxophone' },
  { value: 'timbales' },
  { value: 'timpani' },
  { value: 'ukulele' },
  { value: 'vocal-adaptation' },
  { value: 'vocal-engineer' },
  { value: 'vocal-ensemble' },
  { value: 'vocal-solo' },
  { value: 'conductor' },
  { value: 'programming', orderNumber: 1 },
  { value: 'singer', orderNumber: 2 },
  { value: 'featuring-vocals', orderNumber: 3 },
  { value: 'guitar', orderNumber: 4 },
  { value: 'bass-guitar', orderNumber: 5 },
  { value: 'drums', orderNumber: 6 },
  { value: 'piano', orderNumber: 7 },
  { value: 'keyboards', orderNumber: 8 },
  { value: 'percussion', orderNumber: 9 },
];
export const roles = values
  .sort((a, b) => a.value.localeCompare(b.value))
  .sort(({ orderNumber: a = Infinity }, { orderNumber: b = Infinity }) => a - b)
  .map(({ value }) => ({
    label: `performer-label-${value}`,
    value: value.replace(/-/g, '_'),
  }));
