import React, { useMemo, useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import type { EntityModels } from 'imddata';
import {
  useReleaseCovers,
  useEntityFileRequestProvider,
  AppContext,
} from 'imddata';
import ReactImageMagnify from '@blacklab/react-image-magnify';

import { ArtworkImage, createOverlayContext, Window } from 'imdui';
import { Status } from './components';
import type { TFunction } from 'i18next';

const artworkWrapperStyle = css`
  position: relative;
  border-radius: 4px;
  aspect-ratio: 1 / 1;
  max-height: 100%;

  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
`;

const maximizeArtworkWrapperStyle = css`
  z-index: 1;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

const StyledWindow = styled(Window)`
  aspect-ratio: 1/1;
  max-width: 800px;
  // display: flex;
`;

export const getLoaderLabel = <
  T extends {
    uploadStatus: EntityModels.UploadStatus | null;
    availableCovers: Record<string, string>;
  },
>({
  release,
  fileUpload,
  t,
}: {
  fileUpload?: { uploading: boolean; progress?: number };
  release: T;
  t: TFunction;
}): string => {
  if (fileUpload && fileUpload.uploading) {
    return `${t('uploading')} ${fileUpload.progress || '0'}%`;
  }
  if (
    release &&
    (!release.availableCovers || !Object.keys(release.availableCovers).length)
  ) {
    if (release.uploadStatus === 'finished') {
      return t('generating-thumbnails');
    }

    if (release.uploadStatus === 'confirmed') {
      return t('checking-cover');
    }
  }
  return '';
};

export const MaximaizeArtworkOverlayContext = createOverlayContext();
MaximaizeArtworkOverlayContext.displayName = 'MaximizeArtworkOverlayContext';

type Props = {
  expandable?: boolean;
  releaseId?: number | string;
  hideStatusText?: boolean;
  shadow?: 'small' | 'medium' | 'large';
};

const NoHint = () => null;

const LoadingArtwork: React.FC<Props> = ({
  expandable = false,
  releaseId,
  hideStatusText,
  shadow,
}) => {
  const app = useContext(AppContext);
  const { t } = useTranslation();
  const { entry: release } = useReleaseCovers({
    id: releaseId ?? undefined,
    passive: true,
  });
  const fileUpload = useEntityFileRequestProvider({
    entity: 'releases',
    id: releaseId,
  });

  const src = useMemo(() => {
    if (!release?.availableCovers) {
      return {
        local: release?.inMemoryCover,
      };
    }

    return { local: release?.inMemoryCover, ...release.availableCovers };
  }, [release?.availableCovers, release?.inMemoryCover]);

  if (!release) {
    return (
      <div data-test-id="LoadingArtwork" css={artworkWrapperStyle}>
        <ArtworkImage />
      </div>
    );
  }

  const loaderText = getLoaderLabel({ release, fileUpload, t });

  const isIndeterminateStatus =
    release.uploadStatus === 'confirmed' || release.uploadStatus === 'finished';

  return (
    <div data-test-id="LoadingArtwork" css={artworkWrapperStyle}>
      {app === 'admin' && expandable ? (
        <MaximaizeArtworkOverlayContext.Consumer>
          {({ open }) => (
            <div
              tabIndex={0}
              css={maximizeArtworkWrapperStyle}
              role="button"
              onClick={open}
            >
              <ArtworkImage src={src} />
            </div>
          )}
        </MaximaizeArtworkOverlayContext.Consumer>
      ) : (
        <ArtworkImage src={src} shadow={shadow} />
      )}

      {loaderText && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Status
            progress={fileUpload ? fileUpload.progress : 0}
            indeterminate={isIndeterminateStatus}
            loaderText={loaderText}
            hideStatusText={hideStatusText}
          />
        </div>
      )}

      {app === 'admin' &&
        release.availableCovers?.small &&
        release.availableCovers?.export && (
          <StyledWindow
            contextComponent={MaximaizeArtworkOverlayContext}
            hideTitleBar={true}
          >
            <ReactImageMagnify
              shouldUsePositiveSpaceLens={true}
              activationInteractionHint="hover"
              hintComponent={NoHint}
              magnifyContainerProps={{
                scale: 2,
                width: 800,
                height: 800,
                // @ts-ignore
                isLazyLoaded: false,
              }}
              imageProps={{
                src: release.availableCovers?.small,
              }}
              magnifiedImageProps={{
                src: release.availableCovers?.export,
                width: 3000,
                height: 3000,
              }}
              portalProps={{ placement: 'over' }}
            />
          </StyledWindow>
        )}
    </div>
  );
};

export default function ReleaseArtwork(props: Props & { loading?: boolean }) {
  if (!props.releaseId) {
    return (
      <div data-test-id="LoadingArtwork" css={artworkWrapperStyle}>
        <ArtworkImage loading={props.loading} />
      </div>
    );
  }

  return <LoadingArtwork {...props} />;
}
