//
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Query, EntityModels } from 'imddata';
import { useQuery, useEntries } from 'imddata';
import QueryBuilderInput from '../../atoms/QueryBuilderInput';

const useSearchInputQuery = (searchInput?: string) => {
  return useMemo<Query | undefined>(
    () =>
      searchInput && searchInput.length > 2
        ? { query: searchInput }
        : undefined,
    [searchInput]
  );
};

const EMPTY_RESULT: never[] = [];

export type InputQuery = { searchInput?: string };

export const useArtistsOptions = ({ searchInput }: InputQuery) => {
  const { t } = useTranslation();
  const query = useSearchInputQuery(searchInput);
  const { queryHash } = useQuery(query);
  const {
    entries: artists,
    request: { loading },
  } = useEntries<EntityModels.Artist>({ entity: 'artists', query, queryHash });

  const options = useMemo(() => {
    if (!query || !artists) return EMPTY_RESULT;
    return artists.map((artist) => {
      return {
        id: artist.id,
        value: `artist${artist.id}`,
        label: artist.defaultName?.name || `${artist.id}`,
        prefix: t('artist'),
        queryKey: 'filter.artists',
      };
    });
  }, [artists, !!query]);

  return {
    loading,
    options,
  };
};

const useContributorsOptions = ({ searchInput }: InputQuery) => {
  const { t } = useTranslation();
  const query = useSearchInputQuery(searchInput);
  const { queryHash } = useQuery(query);
  const {
    entries: contributors,
    request: { loading },
  } = useEntries<EntityModels.Contributor>({
    entity: 'contributors',
    query,
    queryHash,
  });

  const options = useMemo(() => {
    if (!query || !contributors) return EMPTY_RESULT;
    return contributors.map((contributor) => {
      return {
        id: contributor.id,
        value: `contributor${contributor.id}`,
        label: contributor.defaultName?.name || `${contributor.id}`,
        prefix: t('contributor'),
        queryKey: 'filter.contributors',
      };
    });
  }, [contributors, !!query]);

  return {
    loading,
    options,
  };
};

const usePublishersOptions = ({ searchInput }: InputQuery) => {
  const { t } = useTranslation();
  const query = useSearchInputQuery(searchInput);
  const { queryHash } = useQuery(query);
  const {
    entries: publishers,
    request: { loading },
  } = useEntries<EntityModels.Publisher>({
    entity: 'publishers',
    query,
    queryHash,
  });
  const options = useMemo(() => {
    if (!query || !publishers) return EMPTY_RESULT;
    return publishers.map((publisher) => {
      return {
        id: publisher.id,
        value: `publisher${publisher.id}`,
        label: publisher.name,
        prefix: t('publisher'),
        queryKey: 'filter.publishers',
      };
    });
  }, [publishers, !!query]);

  return {
    loading,
    options,
  };
};

const GENRES_QUERY = {
  'filter.is_main_genre': 1,
};

export const useGenresOptions = ({ searchInput }: InputQuery) => {
  const { t } = useTranslation();
  const query = useSearchInputQuery(searchInput);
  const {
    entries: genres,
    request: { loading },
  } = useEntries<EntityModels.Genre>({
    entity: 'genres',
    query: GENRES_QUERY,
  });

  const options = useMemo(() => {
    if (!query || !genres) return EMPTY_RESULT;
    return genres.map((genre) => {
      return {
        id: genre.id,
        value: `genre${genre.id}`,
        label: genre.name,
        prefix: t('genre'),
        queryKey: 'filter.genres',
      };
    });
  }, [genres, !!query]);

  return {
    loading,
    options,
  };
};

export const useDeliveryOptions = () => {
  const { t } = useTranslation();
  const deliveryStatuses = useMemo(
    () => [
      {
        id: '0,4',
        value: `delivery-status-0,4`,
        label: t('not-delivered'),
      },
      {
        id: '1,2,3',
        value: `delivery-status-1,2,3`,
        label: t('in-delivery'),
      },
      {
        id: '5,9',
        value: `delivery-status-5,9`,
        label: t('delivered'),
      },
      {
        id: '6,7',
        value: `delivery-status-6,7`,
        label: t('in-take-down'),
      },
      {
        id: '8',
        value: `delivery-status-8`,
        label: t('taken-down'),
      },
    ],
    []
  );
  const options = useMemo(
    () =>
      deliveryStatuses.map((status) => {
        return {
          ...status,
          prefix: t('delivery-status'),
          queryKey: 'filter.delivery_status',
        };
      }),
    []
  );

  return {
    loading: false,
    options,
  };
};

type BuilderProps = React.ComponentProps<typeof QueryBuilderInput>;

export type Props = {
  value: BuilderProps['value'];
  onChange: BuilderProps['onChange'];
};

export default function TracksQueryBuilder({ value, onChange }: Props) {
  const [searchInputQuery, setSearchInputQuery] = useState<InputQuery>({});
  const { query, queryHash, updateQuery } = useQuery();
  useEffect(() => {
    setSearchInputQuery({ searchInput: `${query?.query || ''}` });
  }, [queryHash]);
  const { options: deliveryOptions } = useDeliveryOptions();
  const { options: publishersOptions, loading: publishersLoading } =
    usePublishersOptions(searchInputQuery);
  const { options: artistsOptions, loading: artistsLoading } =
    useArtistsOptions(searchInputQuery);
  const { options: contributorsOptions, loading: contributorsLoading } =
    useContributorsOptions(searchInputQuery);

  const { options: genresOptions, loading: genresLoading } =
    useGenresOptions(searchInputQuery);

  const loading =
    genresLoading || contributorsLoading || artistsLoading || publishersLoading;

  const options = useMemo(
    () => [
      ...deliveryOptions,
      ...contributorsOptions,
      ...genresOptions,
      ...artistsOptions,
      ...publishersOptions,
    ],
    [contributorsOptions, genresOptions, artistsOptions, publishersOptions]
  );

  return (
    <QueryBuilderInput
      value={value}
      loading={loading}
      options={options}
      onChange={onChange}
      query={query}
      onInputChange={updateQuery}
    />
  );
}
