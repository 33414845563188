import styled from '@emotion/styled';
import { BodyL, H3 } from '@imus/services-ui/src/Text';
import { H1 } from 'imdui';

export const TextBlockWrapper = styled.div`
  text-align: center;
  display: grid;
  ${H1} {
    margin-bottom: 4px;
  }
  gap: 8px;
  ${BodyL} {
    color: var(--fg-2, rgba(0, 0, 0, 0.65));
  }
`;

export const TextBlock = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <TextBlockWrapper>
      <H3>{title}</H3>
      <BodyL>{subtitle}</BodyL>
    </TextBlockWrapper>
  );
};
