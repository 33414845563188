import styled from '@emotion/styled';
import { accentThemes, AmpIconBase } from '@imus/base-ui';
import { Window, Card, WindowCloseButton } from '@imus/services-ui';
import { getBaseSubscriptionId } from 'components';
import { useInitFastPayment } from 'components/hooks';
import type { EntityModels } from 'imddata';
import {
  useCurrentCustomer,
  useCurrentCustomerSubscription,
  useCustomerPrices,
  useEntry,
  useProductPrice,
} from 'imddata';
import { ApplicationSettingsContext, useSubscriptionUpsell } from 'imdshared';
import {
  BodySmall,
  Button,
  ButtonText,
  Centered,
  Clickable,
  DOMNodeContext,
  H4,
  LoadingIndicator,
} from 'imdui';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// TODO: move to lib
const WindowHeader = styled.div`
  display: flex;
  padding: 30px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  position: relative;
  border-bottom: 1px solid var(--fg-4, rgba(0, 0, 0, 0.1));
  background: var(--bg-3, #f4f4f4);
  button {
    position: absolute;
    right: 32px;
    top: 30px;
  }
`;
const AmpsText = styled.span`
  color: var(--fg-1, rgba(0, 0, 0, 0.95));
  text-align: center;
  font-family: 'Barlow', 'Inter';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.3px;
`;
const WindowContent = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  align-self: stretch;
`;
const BigAmpChip = styled(Card)`
  display: flex;
  min-width: 270px;
  padding: 16px 8px;
  border-radius: 20px;
  align-items: center;
`;

const AmpCell = styled.div`
  flex: 1;
  padding: 0 8px;
  min-width: 136px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  &:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const AmpBlock = styled.div`
  margin-bottom: 8px;
  display: grid;
  gap: 16px;
`;

const AmpCount = styled.span<{ active?: boolean }>`
  color: ${({ active }) =>
    active ? 'var(--accent, #F343AD);' : 'var(--fg-1, rgba(0, 0, 0, 0.95))'};
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 114.286% */
  letter-spacing: -2px;
`;

const AmpButton = styled(Clickable)`
  margin-left: auto;
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  ${ButtonText} {
    text-transform: uppercase;
  }
  border: 1px solid var(--accent);
  cursor: pointer;
  background: var(--surface-bg-1, #fff);
`;

const AmpPackageCard = styled(Card)`
  text-align: left;
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  ${BodySmall} {
    color: var(--foreground-fg-2, rgba(0, 0, 0, 0.65));
  }
  ${H4} {
    display: inline-flex;
    align-items: center;
    svg path {
      fill: #222;
    }
  }
  & > div:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
const AmpCard = styled(Card)`
  ${accentThemes.tertiary}
`;

const SpendAmpsButton = styled(Button)``;

const PackagesList = styled.div`
  display: flex;
  max-width: 320px;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;
const AmpPackage = ({
  productId,
  ampValue,
  label,
}: {
  productId: EntityModels.ProductId;
  ampValue: number;
  label: string;
}) => {
  const { init } = useInitFastPayment({ productId, successPath: '/account' });

  const { price } = useProductPrice(productId);
  const { numberFormatLocale } = useContext(ApplicationSettingsContext);
  if (!price) return null;
  const priceFormatter = new Intl.NumberFormat(numberFormatLocale, {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: price.priceCurrency,
  });

  return (
    <AmpPackageCard>
      <div>
        <H4>
          <AmpIconBase fill="#222" height={16} width={16} />
          {ampValue}
        </H4>

        <BodySmall>{label}</BodySmall>
      </div>
      <AmpButton
        onClick={() => {
          init({});
        }}
      >
        <ButtonText size="small">
          {priceFormatter.format(Number(price.price))}
        </ButtonText>
      </AmpButton>
    </AmpPackageCard>
  );
};

const AmpCountBlock = () => {
  const { t } = useTranslation();
  const customerSubscription = useCurrentCustomerSubscription();
  const { entry: tier2Yearly } = useEntry<EntityModels.Subscription>({
    entity: 'subscriptions',
    id: 'tier-2-yearly',
  });
  const monthlyCredits =
    customerSubscription?.subscription?.monthlyCredits || 0;
  const canReceiveCredits = monthlyCredits > 0;
  const { entry: customer } = useCurrentCustomer();
  if (!customer) return null;
  const creditsValue = customer.credits.find(
    (c) => c.currencyId === 'amp'
  )?.amount;
  const credits = monthlyCredits || tier2Yearly?.monthlyCredits;
  return (
    <AmpBlock>
      <H4>{t('your-rewards')}</H4>
      <BigAmpChip>
        <AmpCell>
          <AmpCount>{creditsValue || 0}</AmpCount>
          <BodySmall>{t('now')}</BodySmall>
        </AmpCell>
        <AmpCell>
          <AmpCount active={true}>+{credits}</AmpCount>
          <BodySmall>
            {canReceiveCredits
              ? t('next-month')
              : t('reward-with-subscription', {
                  defaultValue: 'with Amplify+',
                })}
          </BodySmall>
        </AmpCell>
      </BigAmpChip>
    </AmpBlock>
  );
};

export const AmpsTeaserWindow = ({
  isOpen,
  close,
}: Omit<React.ComponentProps<typeof Window>, 'children'>) => {
  useEffect(() => {
    if (isOpen && window.analytics) {
      window.analytics.track('FT AMPS teaser Opened');
    }
  }, [isOpen]);
  const { dropdownNode } = useContext(DOMNodeContext);
  const { t } = useTranslation();
  const customerSubscription = useCurrentCustomerSubscription();

  const { open } = useSubscriptionUpsell();
  const history = useHistory();

  const baseSubId = getBaseSubscriptionId(
    customerSubscription?.subscriptionId || 'free'
  );

  // const canBuyCredits = baseSubId === 'tier-1' || baseSubId === 'tier-2';
  const canBuyCredits = false;

  const {
    request: { loaded: pricesLoaded },
  } = useCustomerPrices();

  const upgradeButton = (
    <Button
      primary={true}
      size="large"
      onClick={() => {
        open({ analytics: { detail: 'amp' } });
        if (window.analytics) {
          window.analytics.track('FT AMPS teaser Opened subscriptions upsell');
        }
        if (close) {
          close();
        }
      }}
      text={customerSubscription ? t('upgrade') : t('subscribe')}
      style={{ width: '100%' }}
      position={'center'}
    />
  );

  return (
    <Window
      hideClose={true}
      isOpen={isOpen}
      close={close}
      node={dropdownNode?.current}
    >
      <AmpCard style={{ overflow: 'hidden' }}>
        <WindowHeader>
          <AmpsText>Amps</AmpsText>
          <WindowCloseButton onClick={close} />
        </WindowHeader>
        <WindowContent>
          <AmpCountBlock />
          {canBuyCredits ? (
            <>
              <H4>{t('get-more-amps')}</H4>
              {!pricesLoaded && (
                <Centered>
                  <LoadingIndicator />
                </Centered>
              )}
              <PackagesList>
                <AmpPackage
                  productId="subscription-credit-1400"
                  ampValue={1400}
                  label={t('amp-package-1')}
                />
                <AmpPackage
                  productId="subscription-credit-2000"
                  ampValue={2000}
                  label={t('amp-package-2')}
                />
                <AmpPackage
                  productId="subscription-credit-4800"
                  ampValue={4800}
                  label={t('amp-package-3')}
                />
              </PackagesList>
              {baseSubId === 'tier-1' ? (
                upgradeButton
              ) : (
                <SpendAmpsButton
                  position="center"
                  primary={true}
                  size="large"
                  text={t('spend-amps')}
                  onClick={() => {
                    if (close) {
                      close();
                    }
                    history.push('/products');
                  }}
                />
              )}
            </>
          ) : (
            <>
              {!customerSubscription ? (
                upgradeButton
              ) : (
                <SpendAmpsButton
                  position="center"
                  primary={true}
                  size="large"
                  text={t('spend-amps')}
                  onClick={() => {
                    if (close) {
                      close();
                    }
                    history.push('/products');
                  }}
                />
              )}
            </>
          )}
        </WindowContent>
      </AmpCard>
    </Window>
  );
};
