import React, { useCallback, useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DropdownInteractive } from 'imdshared';
import { IconButton } from 'imdui';
import { Portal } from 'react-portal';
import { DetailsIcon } from '@imus/base-ui';

const IconButtonStyled = styled(IconButton)`
  margin-left: 12px;
  top: -1px;
  right: -4px;
`;

const PriceIcon = ({ children }: { children: React.ReactNode }) => {
  const renderFontFaceButton = useCallback(
    ({ /* value, label, displayValue, */ onClick }) => {
      return (
        <IconButtonStyled inline={true} onClick={onClick}>
          <DetailsIcon />
        </IconButtonStyled>
      );
    },
    []
  );
  return (
    <DropdownInteractive
      render={renderFontFaceButton}
      renderDropdown={({ style }) => (
        <div style={{ ...style, padding: '16px' }}>{children}</div>
      )}
    />
  );
};

export const PriceIconPortal = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [node, setNode] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    setNode(document.getElementById('PriceCloud-HelpIcon'));
  });

  return (
    <Portal node={node}>
      <PriceIcon>{children}</PriceIcon>
    </Portal>
  );
};
