import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Window, Input } from 'imdui';
import { useTranslation } from 'react-i18next';

const ConfirmationContent = styled.div`
  max-width: 540px;
  margin: 0 32px 32px;
`;

const ConfirmRow = styled.div`
  display: flex;
  align-items: center;
`;
const ConfirmButton = styled.div`
  margin-left: 16px;
`;

export function ConfirmWithValueWindow({
  isOpen,
  close,
  onConfirm,
  children,
  confirmValue,
  confirmPlaceholder,
  loading,
  loaded,
  title,
  confirmText,
}: {
  confirmValue: string;
  confirmPlaceholder: string;
  title: string;
  loading: boolean;
  loaded?: boolean;
  children: React.ReactNode;
  confirmText?: string;
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
}) {
  const [value, setValue] = useState('');
  const { t } = useTranslation();
  return (
    <Window title={title} isOpen={isOpen} close={close}>
      <ConfirmationContent>
        {children}
        {onConfirm && (
          <ConfirmRow>
            <Input
              style={{ margin: 0 }}
              placeholder={confirmPlaceholder}
              disabled={loading || loaded}
              name="confirmValue"
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
            />
            <ConfirmButton>
              <Button
                primary="error"
                showLoading={loading}
                disabled={
                  loaded || loading || confirmValue?.trim() !== value?.trim()
                }
                text={confirmText || t('confirm')}
                onClick={onConfirm}
              />
            </ConfirmButton>
          </ConfirmRow>
        )}
      </ConfirmationContent>
    </Window>
  );
}
