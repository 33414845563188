import React, { useMemo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';
import type { WrappedFieldArrayProps, FieldArrayFieldsProps } from 'redux-form';
import { formValueSelector, FieldArray, Field, FormSection } from 'redux-form';
import { useSelector } from 'react-redux';
import type { EntityModels, ReduxState } from 'imddata';
import { useCustomerFeature } from 'imddata';
import {
  DragHandler,
  InputField,
  CheckboxField,
  useSubscriptionUpsell,
  EnabledField,
} from 'imdshared';
import {
  LoadingIndicator,
  HelpWindowContext,
  Input,
  IconButton,
  FieldGroup,
  Button,
  Caption,
  Icons,
  SubscriptionFeatureGate,
  OverlineText,
  Content,
  Body,
  ComponentIcons,
} from 'imdui';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import { useTheme } from '@emotion/react';

const ServiceWrapper = styled.div<{ hovered: boolean }>`
  padding: 20px 0px;
  margin: 0 24px;

  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ hovered }) => (hovered ? 'rgba(0,0,0,0.16)' : '#fff')};
  &:last-of-type {
    box-shadow: none;
  }
`;

const ServiceTopRow = styled.div`
  display: flex;
  align-items: center;
`;

const ServiceIconRow = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    fill: #333;
    width: 120px;
    height: 60px;
  }
`;

const DragHandlerStyled = styled(DragHandler)`
  flex: 0;
  width: 20px;
  flex-grow: 0;
  margin-left: auto;
`;

const CustomUrlSection = styled.div`
  margin-top: 8px;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;

  a {
    color: ${({ theme }) => theme.foreground.primary};
    text-decoration: underline;
  }
`;

const DRAG_TYPE = 'release-page-service';

// const validateCallToAction = (value) => (!value ? 'required' : '');
type ServiceFormValue = {
  isVisible: boolean;
  customUrl: string;
  url?: string;
  isCustom?: boolean;
  preSaveAvailable?: boolean;
  name: string;
};

type DragObject = {
  index: number;
  name: string; // shop name
};

const validateURL = (value?: string) => {
  if (!value) return false;
  return isURL(value, {
    protocols: ['https', 'http'],
    require_protocol: true,
    require_valid_protocol: true,
  })
    ? ''
    : 'invalid-url';
};

const CustomButtonRow = styled.div`
  display: flex;
  gap: 16px;
  margin: 24px;
`;

const ServiceRow = ({
  index,
  service,
  shopsStatus,
  fields,
}: {
  shopsStatus: EntityModels.ReleasePage['shopsStatus'];
  index: number;
  service: string;
  fields: FieldArrayFieldsProps<ServiceFormValue>;
}) => {
  const { t } = useTranslation();
  // const showHelpWindow = useContext(HelpWindowContext);

  const {
    customUrl: storedCustomUrl,
    name: shopName,
    preSaveAvailable,
    isCustom,
    url,
    isVisible,
  } = fields.get(index);

  const scanStarted = shopsStatus !== 'none';
  const isScanning = shopsStatus === 'scanning' || shopsStatus === 'requested';

  const notFoundUrl = !isScanning && !url && scanStarted;
  const notFoundUrlError = notFoundUrl && !storedCustomUrl && isVisible;

  const [displayInput, setDisplayInput] = useState(false);

  const [, dragHandler] = useDrag<DragObject, void, null>(
    () => ({
      type: DRAG_TYPE,
      item: {
        index,
        name: shopName,
      },
    }),
    [index, shopName]
  );

  const [dropProps, dropHandler] = useDrop<
    DragObject,
    void,
    { hovered: boolean }
  >(
    () => ({
      accept: DRAG_TYPE,
      drop: (hoverItem) => {
        if (hoverItem.name !== shopName) {
          fields.move(hoverItem.index, index);
        }
      },
      collect: function collect(monitor) {
        return {
          hovered: monitor.isOver(),
        };
      },
    }),
    [index, shopName]
  );

  const handler = useMemo<React.RefCallback<HTMLDivElement>>(
    () => (el) => {
      dragHandler(el);
      dropHandler(el);
    },
    []
  );
  const [customUrl, setCustomUrl] = useState(storedCustomUrl);
  const theme = useTheme();

  return (
    <ServiceWrapper hovered={dropProps.hovered} ref={handler}>
      <ServiceTopRow>
        {!isCustom && (
          <Field
            name={`${service}.isVisible`}
            testId={`ReleasePageShop-Checkbox-${shopName}`}
            text={
              <Content style={{ marginLeft: '16px' }}>{t(shopName)}</Content>
            }
            component={CheckboxField}
          />
        )}
        {isCustom && (
          <>
            <IconButton
              style={{
                left: '-8px',
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
                marginRight: '0px',
              }}
              icon={Icons.actions.remove}
              onClick={() => {
                fields.remove(index);
              }}
            />
            <ServiceIconRow>
              <Content>{shopName}</Content>
            </ServiceIconRow>
          </>
        )}
        <div
          style={{
            margin: '0 0 0 auto',
            alignItems: 'center',
            display: 'flex',
            position: 'relative',
          }}
        >
          {!isCustom && (
            <IconButton
              style={{
                width: '32px',
                height: '32px',
              }}
              icon={Icons.actions.edit}
              iconProps={{
                color: notFoundUrlError ? theme.state.error : '#333',
              }}
              onClick={() => {
                setDisplayInput(!displayInput);
              }}
            />
          )}
          <DragHandlerStyled ref={dragHandler} />
        </div>
      </ServiceTopRow>

      {customUrl && !displayInput && (
        <div>
          <Caption style={{ color: 'rgba(0,0,0,0.5)' }}>
            {t('release-page-custom-url')}
          </Caption>
        </div>
      )}
      {preSaveAvailable && !notFoundUrlError && !displayInput && (
        <div>
          <Caption style={{ color: 'rgba(0,0,0,0.5)' }}>
            {t('available-for-presave')}
          </Caption>
        </div>
      )}

      {notFoundUrlError && !displayInput && (
        <div>
          <Caption style={{ color: theme.state.error }}>
            {t('no-link-found')}
          </Caption>
        </div>
      )}
      {(isCustom || displayInput) && (
        <CustomUrlSection>
          {!url && isScanning && !isCustom && (
            <div style={{ display: 'flex', width: '100%' }}>
              <Body>{t('scanning-for-shops')}</Body>
              <LoadingIndicator style={{ margin: '0 0 0 auto' }} />
            </div>
          )}
          {url && (
            <div>
              <Body>{t('this-shop-has-auto-detected-link')}</Body>
              <br />
              <Caption>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </Caption>
            </div>
          )}
          {notFoundUrl && !isCustom && (
            <div>
              <Body>{t('no-link-found')}</Body>
            </div>
          )}
          {notFoundUrlError && (
            <div style={{ display: 'flex', width: '100%' }}>
              <Body style={{ color: theme.state.error }}>
                {t('no-link-found-please-override')}
              </Body>

              <ComponentIcons.Error
                fill={theme.state.error}
                style={{ margin: '0 0 0 auto' }}
              />
            </div>
          )}

          <InputField
            label={t('release-page-custom-url')}
            style={{ width: '100%', margin: '0' }}
            input={{
              value: customUrl,
              onChange: (e) => {
                setCustomUrl(e.target.value);
              },
            }}
            meta={{
              error: validateURL(customUrl)
                ? t(validateURL(customUrl) || '')
                : undefined,
            }}
          />
          <CustomButtonRow style={{ margin: '0', justifyContent: 'center' }}>
            <Button
              size="small"
              text={t('keep-auto-link')}
              onClick={() => {
                fields.splice(index, 1, {
                  ...fields.get(index),
                  customUrl: '',
                });
                setCustomUrl('');
                setDisplayInput(false);
              }}
            />

            <Button
              disabled={!!validateURL(customUrl) || !customUrl}
              size="small"
              text={t('save-custom-link')}
              onClick={() => {
                fields.splice(index, 1, {
                  ...fields.get(index),
                  customUrl,
                });
                setDisplayInput(false);
              }}
            />
          </CustomButtonRow>
        </CustomUrlSection>
      )}
      {/* <Field */}
      {/*   onClickHelp={() => { */}
      {/*     showHelpWindow( */}
      {/*       t('release-page-call-to-action'), */}
      {/*       t('release-page-helptext-call-to-action') */}
      {/*     ); */}
      {/*   }} */}
      {/*   validate={validateCallToAction} */}
      {/*   name={`${service}.callToAction`} */}
      {/*   label={t('call-to-action')} */}
      {/*   component={NewInputField} */}
      {/* /> */}
    </ServiceWrapper>
  );
};

const CustomLinksWrapper = styled.div`
  padding: 24px;
  background: #fff;
`;

const renderServices = ({
  fields,
  shopsStatus,
}: WrappedFieldArrayProps<ServiceFormValue> & {
  shopsStatus: EntityModels.ReleasePage['shopsStatus'];
}) => {
  const { t } = useTranslation();
  const [shopName, setShopName] = useState<string>('');
  const [shopUrl, setShopUrl] = useState<string>('');
  const [openCustom, setOpenCustom] = useState(false);
  return (
    <>
      {fields.map((service, index) => (
        <ServiceRow
          shopsStatus={shopsStatus}
          service={service}
          index={index}
          key={fields.get(index).name}
          fields={fields}
        />
      ))}
      {!openCustom && (
        <CustomButtonRow>
          <Button
            text={t('add-custom')}
            size="small"
            onClick={() => {
              setOpenCustom(true);
            }}
          />
        </CustomButtonRow>
      )}
      {openCustom && (
        <CustomLinksWrapper>
          <Input
            label={t('shop-name')}
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
          />
          <Input
            label={t('shop-url')}
            value={shopUrl}
            onChange={(e) => setShopUrl(e.target.value)}
          />
          <CustomButtonRow style={{ margin: 0 }}>
            <Button
              text={t('add')}
              size="small"
              onClick={() => {
                fields.push({
                  isCustom: true,
                  name: shopName,
                  customUrl: shopUrl,
                  isVisible: true,
                });
                setShopUrl('');
                setShopName('');
                setOpenCustom(false);
              }}
            />
            <Button
              text={t('cancel')}
              size="small"
              onClick={() => {
                setOpenCustom(false);
              }}
            />
          </CustomButtonRow>
        </CustomLinksWrapper>
      )}
    </>
  );
};

const validateServices = (values: Array<ServiceFormValue>) => {
  if (!values || !values.length) return 'required';
  if (!values.find((value) => value.isVisible === true)) return 'required';

  return null;
};

export type ReleaseFormData = {
  releaseAt?: string;
  services: Array<ServiceFormValue>;
  presave: boolean;
};

const ReleaseForm = ({
  form,
  releasePageId,
}: {
  form: string;
  releasePageId: string;
}) => {
  const { t } = useTranslation();
  const selector = useMemo(() => formValueSelector(form), [form]);

  const presaveFeatureEnabled = useCustomerFeature('artist-hub-pre-save');

  const legacyPremium = useSelector((state: ReduxState) => {
    return state.entities.releasePages.entities[releasePageId]?.isPremium;
  });

  const released = useSelector((state: ReduxState) => {
    const release = selector(state, 'release') as ReleaseFormData;
    const released =
      release.releaseAt && new Date(release.releaseAt).valueOf() < Date.now();
    return released;
  });

  const presaveCapable = useSelector((state: ReduxState) => {
    const release = selector(state, 'release') as ReleaseFormData;
    const released =
      release.releaseAt && new Date(release.releaseAt).valueOf() < Date.now();
    return (
      !released &&
      !!release.services.find((s) => s.preSaveAvailable && s.isVisible)
    );
  });

  const shopsStatus: EntityModels.ReleasePage['shopsStatus'] = useSelector(
    (state: ReduxState) => {
      return (
        state.entities.releasePages.entities[releasePageId]?.shopsStatus ||
        'none'
      );
    }
  );

  const { open: openUpsell } = useSubscriptionUpsell();
  const showHelpWindow = useContext(HelpWindowContext);

  return (
    <>
      <FormSection data-test-id="TemplateSideEditor-ReleaseForm" name="release">
        <FieldGroup style={{ boxShadow: 'none' }}>
          <OverlineText
            style={{ margin: 0 }}
            label={t('services')}
            size="large"
            onClickHelp={() => {
              showHelpWindow(t('services'), t('services-description'));
            }}
          />

          {(presaveFeatureEnabled || legacyPremium) && !released && (
            <Field
              name="presave"
              description={t('presave-description')}
              label={t('enable-presave')}
              component={EnabledField}
              style={{ marginTop: '24px' }}
              disabled={!presaveCapable}
              caption={!presaveCapable ? t('enable-presave-shop') : undefined}
            />
          )}

          {!(presaveFeatureEnabled || legacyPremium) && (
            <SubscriptionFeatureGate
              action={t('upgrade')}
              style={{ marginTop: '24px' }}
              title={t('unlock-presaves-upsell')}
              onActionClick={() => {
                openUpsell({
                  analytics: { detail: 'release-page' },
                  section: 'promo-tools',
                  feature: 'artist-hub-pre-save',
                });
              }}
            />
          )}
        </FieldGroup>
        <FieldArray<
          { shopsStatus: EntityModels.ReleasePage['shopsStatus'] },
          ServiceFormValue
        >
          name="services"
          validate={validateServices}
          shopsStatus={shopsStatus}
          component={renderServices}
        />
      </FormSection>
    </>
  );
};

export default ReleaseForm;
