//
import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { useCurrentUser } from 'imddata';
import { head, trim, tail, join, split } from 'ramda';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { createEntity } from 'imddata/actionTypes/request';
import { FieldGroup, FormToolbar, Button, HelpWindowContext } from 'imdui';
import { NewInputField } from '../../../fields';
import * as ContributorForms from '../../ContributorForms';

const mapContributorStringToForm = ({ name }: { name: string }) => {
  if (!name) return {};
  const parts = split(' ', trim(name));
  if (parts.length === 2) {
    return {
      firstName: parts[0],
      lastName: parts[1],
    };
  }
  const firstName = head(parts);
  const middleName = head(tail(parts));
  const lastName = tail(tail(parts)).length ? join(' ', tail(tail(parts))) : '';

  return {
    firstName,
    middleName,
    lastName,
  };
};

const Form = ({
  impersonate,
  createContributor,
  submitting,
  handleSubmit,
  onSaved,
  form,
  initialize,
  actionLabel,
  initialValues,
  testPrefix = 'contributor',
}: any) => {
  const { t } = useTranslation();

  const { entry: user } = useCurrentUser();
  const languageId = user?.defaultLanguageId;

  useEffect(() => {
    initialize({
      ...initialValues,
      names: [mapContributorStringToForm(initialValues)],
    });
  }, []);

  const submitForm = useCallback(
    (values) => {
      const {
        names: [{ artistName, firstName, lastName, middleName }],
      } = values;
      return new Promise((resolve) => {
        createContributor(
          {
            formId: form,
            data: {
              defaultLanguageId: languageId,
              names: [
                { artistName, firstName, lastName, middleName, languageId },
              ],
            },
          },
          {
            impersonate,
            resolve: (data: any) => {
              onSaved({
                result: data.result,
                entities: {
                  items: data.entities.contributors,
                  names: data.entities.contributorsNames,
                },
              });
              resolve(true);
            },
          }
        );
      });
    },
    [onSaved, createContributor]
  );

  const showHelp = useContext(HelpWindowContext);

  // TODO: [tabStripeClassName] Deprecate and remove, duplicate style in ContributorJoin/EditItemForm
  return (
    <form
      data-test-id={`new-${testPrefix}-form`}
      onSubmit={handleSubmit(submitForm)}
    >
      <div>
        <FieldGroup style={{ paddingBottom: '24px' }}>
          <Field
            autoFocus={true}
            name="names[0].firstName"
            testId={`${testPrefix}-first-name`}
            label={t('first-name')}
            component={NewInputField}
          />

          <Field
            name="names[0].middleName"
            testId={`${testPrefix}-middle-name`}
            label={t('middle-name')}
            helperText={t('optional')}
            component={NewInputField}
          />

          <Field
            name="names[0].lastName"
            testId={`${testPrefix}-last-name`}
            label={t('last-name')}
            component={NewInputField}
          />
        </FieldGroup>
        <FieldGroup>
          <Field
            label={t('artist-name')}
            name="names[0].artistName"
            helperText={t('optional-only-for-producer')}
            onClickHelp={() => {
              showHelp(t('artist-name'), t('artist-name-helptext'));
            }}
            component={NewInputField}
          />
        </FieldGroup>
      </div>

      <FormToolbar
        rightChildren={
          <Button
            primary={true}
            data-test-id={`add-new-${testPrefix}`}
            type="submit"
            text={actionLabel || t('add-contributor')}
            showLoading={submitting}
          />
        }
      />
    </form>
  );
};

export default connect(null, {
  // @ts-ignore
  createContributor: (...args) => createEntity('contributors', ...args),
})(
  reduxForm({
    form: 'contributorCreateForm',
    // @ts-ignore
    warn: ContributorForms.warnContributorForm,
    // @ts-ignore
    validate: ContributorForms.validateContributorForm,
  })(Form)
);
