import React from 'react';
import { RadioButton } from 'imdui';
import type { WrappedFieldProps } from 'redux-form';

type Props = {
  input: WrappedFieldProps['input'];
  className?: string;
  style?: React.CSSProperties;
  options: { value: string | number | boolean; text: string }[];
};

export default function RadioButtonsField({
  input,
  className,
  style,
  options,
}: Props) {
  return (
    <div role="radiogroup" className={className} style={style}>
      {options.map((option) => (
        <RadioButton
          key={option.value.toString()}
          selected={option.value === input.value}
          onClick={() => {
            if (input && input.onChange) {
              input.onChange(option.value);
            }
          }}
          text={option.text}
        />
      ))}
    </div>
  );
}
