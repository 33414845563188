//
import { entities } from '../../../actionTypes';
import { entityRequestReducerFactory } from '../../../reducerFactories';
import { types as pricesTypes } from '../../../actionTypes/prices';
import { types as deliveryDatesTypes } from '../../../actionTypes/deliveryDates';

export { default as releases } from './releases';
export { default as tracks } from './tracks';
export { default as filesManager } from './filesManager';
export { default as salesImports } from './salesImports';

export const payouts = entityRequestReducerFactory({
  entityKey: 'payouts',
  fetchActions: [entities.payouts.types.fetch],
  createActions: [
    entities.payouts.types.create,
    entities.payoutRequest.types.create,
  ],
  updateActions: [entities.payouts.types.update],
});

export const deliveryDates = entityRequestReducerFactory({
  entityKey: 'deliveryDates',
  fetchActions: [deliveryDatesTypes.fetch],
});

export const prices = entityRequestReducerFactory({
  entityKey: 'prices',
  fetchActions: [pricesTypes.overviewByRegion],
});

export const pricesCustomer = entityRequestReducerFactory({
  entityKey: 'pricesCustomer',
  fetchActions: [pricesTypes.pricesCustomer],
});

export const salesStatements = entityRequestReducerFactory({
  entityKey: 'salesStatements',
  fetchActions: [entities.salesStatements.types.fetch],
});
