import styled from '@emotion/styled';

export const cardBoxShadow =
  '0px 2px 6px -2px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1)';

const Card = styled.div<{ secondary?: boolean }>`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  border-radius: 20px;
  background: ${({ secondary }) =>
    secondary
      ? 'var(--surface-container-low)'
      : 'var(--surface-container-medium)'};

  /* Shadow/Card */
  box-shadow:
    0px 2px 6px -2px rgba(0, 0, 0, 0.05),
    0 0 0 1px var(--outline-var);
`;

export default Card;
