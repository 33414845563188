import React, { useMemo, useCallback, useContext } from 'react';
import moment from 'moment';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card } from 'imdui';
import styled from '@emotion/styled';
import type { ShopsFieldValue } from 'imdshared';
import { GenreSelectField, HelpWindowContext } from 'imdshared';
import type { EntityModels, ReduxState } from 'imddata';
import { useCustomerFeatureRollout, useEntriesProvider } from 'imddata';
import { updateEntity as updateEntityAction } from 'imddata/actionTypes/request';
import { ShippingField } from './ShippingField';
import type { Values } from './types';
import { BundleReleaseContext } from './hooks';
import {
  PlaylistPitchingToggle,
  SpotifyPitchToggle,
} from './SpotifyPitchToggle';
import { YoutubeContentIdToggle } from './YoutubeContentIdToggle';
import { CountryFieldCard, ShopsFieldCard } from './SideEditorFields';
import { useCountryRegionsValue } from 'imdshared/src/fields/DeliveryRegionsField';

const Grid = styled.div`
  display: grid;
  padding: 32px;
  gap: 32px;
  & > * {
    min-width: 0;
  }
`;

const Section = styled.div`
  border-bottom: 1px solid var(--fg-4);
  padding-bottom: 32px;
  &:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
  & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const DatesBlock = styled.div``;

type Props = {
  disabled?: boolean;
  form?: string;
  releaseId: string | number;
  bundleId: string | number;
};

type ConnectedProps = Props & {
  shopTargetsValid: boolean;
  updateEntity: typeof updateEntityAction;
};

const pitchingStyle = { marginTop: '16px', width: '100%' };

function Delivery({
  touch,
  disabled: formDisabled,
  updateEntity,
  bundleId,
  change,
  releaseId,
}: ConnectedProps & InjectedFormProps<Values, ConnectedProps>) {
  const { t } = useTranslation();

  const genres = useEntriesProvider<EntityModels.Genre>({ entity: 'genres' });

  const onChangeGenre = useCallback(
    (_event, id, previousId) => {
      const oldGenreHandler = genres.entities[previousId]?.handler;
      const newGenreHandler = genres.entities[id]?.handler;
      console.log(oldGenreHandler, newGenreHandler);
      if (oldGenreHandler !== newGenreHandler) {
        change('shops', { shops: [] });
        change('enableYouTubeContentId', false);
        change('enableEditorialPlaylistPitching', false);
        change('releaseAt', null);
        change('releaseAt', null);

        updateEntity(
          'deliveryBundles',
          {
            query: {
              with: 'countries',
            },
            id: bundleId,
            data: {
              shops: [],
              deliverAt: null,
              releaseAt: null,
              enableYouTubeContentId: false,
            },
          },
          { debounce: true }
        );
      }
      updateEntity(
        'releases',
        {
          id: releaseId,
          data: {
            genreId: id,
          },
        },
        { debounce: true }
      );
    },
    [updateEntity, releaseId]
  );

  const onChangeCountries = useCallback(
    (countries) => {
      touch('countries');
      change('countries', countries);

      updateEntity(
        'deliveryBundles',
        {
          query: {
            with: 'countries',
          },
          id: bundleId,
          data: { countries },
        },
        { debounce: true }
      );
    },
    [updateEntity, touch, bundleId]
  );

  const { onCheckAll: checkAllCountries } = useCountryRegionsValue(
    undefined,
    onChangeCountries
  );

  const onDecideBuyCountries = useCallback(
    (v) => {
      updateEntity(
        'deliveryBundles',
        {
          id: bundleId,
          data: { allowPaymentForCountriesCustomization: v },
        },
        { debounce: true }
      );
    },
    [updateEntity, touch, bundleId]
  );

  const onChangeShops = useCallback(
    (event, shopsData) => {
      const hasYouTube = shopsData.shops.find(
        (v: { shopId: string }) => v.shopId === 'you_tube'
      );
      if (!hasYouTube) {
        change('enableYouTubeContentId', false);
      }
      updateEntity(
        'deliveryBundles',
        {
          id: bundleId,
          data: !hasYouTube
            ? { ...shopsData, enableYouTubeContentId: false }
            : shopsData,
        },
        { debounce: true }
      );
    },
    [updateEntity, touch, bundleId]
  );

  const bunldeReleaseId = useMemo(
    () => ({ bundleId, releaseId }),
    [bundleId, releaseId]
  );

  const deliveryDatesFeature = useCustomerFeatureRollout({
    feature: 'custom-delivery-dates',
    rolloutKey: 'tiered-subs',
    fallback: true,
  });

  const showHelpWindow = useContext(HelpWindowContext);

  // TODO: may be use for error signalling when customer selects label under label and here

  // Handle shop limit

  const onChangeYouTubeContentId = useCallback((event, value) => {
    if (value) {
      checkAllCountries(event, true);
    }
    updateEntity(
      'deliveryBundles',
      {
        id: bundleId,
        data: {
          enableYouTubeContentId: value,
        },
      },
      { debounce: true }
    );
  }, []);

  const onChangePitching = useCallback((_event, value) => {
    updateEntity(
      'deliveryBundles',
      {
        id: bundleId,
        data: {
          pitching: value,
        },
      },
      { debounce: true }
    );
  }, []);

  const onChangePlaylistPitching = useCallback((_event, value) => {
    change('pitching', { shops: [] });
    updateEntity(
      'deliveryBundles',
      {
        id: bundleId,
        data: {
          pitching: { shops: [] },
          enableEditorialPlaylistPitching: value,
        },
      },
      { debounce: true }
    );
  }, []);

  return (
    <Card>
      <BundleReleaseContext.Provider value={bunldeReleaseId}>
        <Grid>
          <Section>
            <Field
              name="genreId"
              onChange={onChangeGenre}
              disabled={formDisabled}
              // @ts-ignore
              testId="genreId"
              mode="grid"
              component={GenreSelectField}
            />
          </Section>

          <Section>
            <Field
              name="shops"
              disabled={formDisabled}
              onChange={onChangeShops}
              // @ts-ignore
              onClickHelp={() => {
                showHelpWindow(t('shops'), t('shops-helperText'));
              }}
              component={ShopsFieldCard}
            />
            <DatesBlock>
              <Field
                name="enableYouTubeContentId"
                style={{ marginTop: '16px', width: '100%' }}
                disabled={formDisabled}
                testId="EnableYouTubeContentId"
                onChange={onChangeYouTubeContentId}
                label={t('enable-youtube-content-id')}
                description={t('enable-youtube-content-id-description')}
                component={YoutubeContentIdToggle}
              />
            </DatesBlock>
          </Section>

          <Section>
            <DatesBlock>
              <Field
                label={t('digital-release-date')}
                name="deliverAt"
                disabled={formDisabled}
                releaseId={releaseId}
                component={ShippingField}
                onClickHelp={(event: any) => {
                  event.stopPropagation();
                  event.preventDefault();
                  showHelpWindow(
                    t('digital-release-date'),
                    t('digital-release-date-helpertext', {
                      context: deliveryDatesFeature
                        ? 'custom-delivery-dates'
                        : '',
                    })
                  );
                }}
              />
              <Field
                name="pitching"
                style={pitchingStyle}
                disabled={formDisabled}
                onChange={onChangePitching}
                // @ts-ignore
                onClickHelp={(event: any) => {
                  event.stopPropagation();
                  event.preventDefault();
                  showHelpWindow(
                    t('spotify-pitching'),
                    t('spotify-pitching-helpertext')
                  );
                }}
                component={SpotifyPitchToggle}
              />

              <Field
                name="enableEditorialPlaylistPitching"
                style={pitchingStyle}
                disabled={formDisabled}
                onChange={onChangePlaylistPitching}
                // @ts-ignore
                label={t('playlist-pitching')}
                description={t('playlist-pitching-desc')}
                onClickHelp={(event: any) => {
                  event.stopPropagation();
                  event.preventDefault();
                  showHelpWindow(
                    t('playlist-pitching'),
                    t('playlist-pitching-helpertext')
                  );
                }}
                component={PlaylistPitchingToggle}
              />
            </DatesBlock>
          </Section>
          <Section>
            <Field
              name="countries"
              disabled={formDisabled}
              onDecideBuy={onDecideBuyCountries}
              component={CountryFieldCard}
              onChange={onChangeCountries}
            />
          </Section>
        </Grid>
      </BundleReleaseContext.Provider>
    </Card>
  );
}

const SHOP_CONDITIONS: Array<
  keyof EntityModels.DeliveryBundle['targets']['order']['conditions']
> = ['traxsourceWithCorrectGenre', 'beatportWithCorrectGenre'];

const hasShopConditionsMet = (
  conditions: EntityModels.DeliveryBundle['targets']['order']['conditions']
) =>
  SHOP_CONDITIONS.reduce(
    (acc, key) => acc && (conditions[key] ? conditions[key].isMet : true),
    true
  );
const validate = (values: Values, { shopTargetsValid }: ConnectedProps) => {
  const errors: Partial<Record<keyof Values, string | null>> = {};
  errors.deliverAt = values.deliverAt ? null : 'required';
  errors.deliverAt = values.releaseAt ? null : 'required';
  errors.genreId = values.genreId ? null : 'required';
  errors.shops = values.shops?.shops?.find((s) => !!s.status)
    ? !shopTargetsValid
      ? 'invalid-shop-selection'
      : null
    : 'required';
  errors.shops =
    errors.shops ||
    (values.shops.shops.find((s) => s.shopId === 'shazam') &&
    !values.shops.shops.find((s) => s.shopId === 'apple_music')
      ? 'shazam-requires-apple-music'
      : null);
  errors.countries = values.countries?.find((c) => !!c.status)
    ? null
    : 'required';

  // errors.promotionalText =
  //     ? 'promotional-text-character-limit'
  //     : null;

  return errors;
};

const emptyPitching = { shops: [] };

const emptyCountries: never[] = [];

export default connect(
  (state: ReduxState, { releaseId, bundleId, form: propsForm }: Props) => {
    const {
      deliveryBundleShops: { entities: deliveryBundleShops },
      deliveryBundles: { entities },
    } = state.entities;
    const form = propsForm || `deliveryBundle:${bundleId}`;
    const bundle = entities[bundleId];
    if (!bundle) {
      throw new Error('Rendering DeliveryForm without loaded DeliveryBundle');
    }
    const {
      pitching,
      pricePlanId,
      otherShopsStreaming,
      otherShopsDownloads,
      enableEditorialPlaylistPitching,
      allowPaymentForAdditionalVolumes,
      allowPaymentForCountriesCustomization,
      preSalesAt,
      shops = [],
      countries = [],
      deliverAt,
      enableYouTubeContentId,
      releaseAt,
      targets: {
        order: { conditions },
      },
    } = bundle;

    const shopsValue = shops.reduce<ShopsFieldValue>(
      (acc, deliveryBundleShopId) => {
        const deliveryBundleShop = deliveryBundleShops[deliveryBundleShopId];
        if (!deliveryBundleShop) {
          return acc;
        }
        return {
          otherShopsStreaming,
          otherShopsDownloads,
          shops: [
            ...acc.shops,
            {
              shopId: deliveryBundleShop.shopId,
              status: deliveryBundleShop.status,
            },
          ],
        };
      },
      { shops: [], otherShopsDownloads: false, otherShopsStreaming: false }
    );

    const release = state.entities.releases.entities[releaseId];

    if (!release) {
      throw new Error('Rendering DeliveryForm without loaded Release');
    }

    const { originalReleaseDate, genreId } = release;

    const hasPreSales = !!preSalesAt;

    const values: Values = {
      enableYouTubeContentId,
      preSalesAt: preSalesAt ? moment(preSalesAt) : null,
      releaseAt: releaseAt ? moment(releaseAt) : null,
      hasPreSales,
      genreId,
      enableEditorialPlaylistPitching,
      allowPaymentForCountriesCustomization,
      allowPaymentForAdditionalVolumes,
      pitching: pitching || emptyPitching,
      // promotionalText: defaultName.promotionalText,
      originalReleaseDate,
      countries: countries || emptyCountries,
      shops: shopsValue,
      pricePlanId,
      deliverAt: deliverAt ? moment(deliverAt) : null,
    };

    const shopTargetsValid = hasShopConditionsMet(conditions);
    return {
      form,
      shopTargetsValid,
      initialValues: values,
    };
  },
  {
    updateEntity: updateEntityAction,
  }
)(
  reduxForm<Values, ConnectedProps, any>({
    validate,
  })(Delivery)
);
