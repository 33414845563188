//
import React, { useMemo } from 'react';
import { useEntries } from 'imddata';
import SelectField from '../SelectField';

const query = { perPage: 300 };

const sortCountries = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

const CountrySelector = (props) => {
  const { entries } = useEntries({ query, entity: 'countries' });
  const data = useMemo(() => {
    if (!entries) return [];
    return entries.sort(sortCountries).map(({ name, id }) => ({
      value: id,
      label: name,
    }));
  }, [entries]);

  return <SelectField {...props} data={data} />;
};

export default CountrySelector;
