//
import * as R from 'ramda';

const createFileLens = (itemId) => R.lensPath(['items', itemId.toString()]);

export const fileUploadLens = (itemId) =>
  R.lensPath(['items', itemId.toString(), 'fileUpload']);

const uploadFileRequestReducerFactory =
  (types) =>
  (
    state = {
      items: {},
    },
    action
  ) => {
    switch (action.type) {
      case types.updateProgress: {
        const lens = createFileLens(action.payload.id);
        return R.over(
          lens,
          R.mergeDeepLeft({
            fileUpload: {
              progress: action.payload.progress,
            },
          }),
          state
        );
      }
      case types.upload.ABORT: {
        const lens = createFileLens(action.payload.id);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                progress: 0,
                uploading: false,
                failed: false,
                uploaded: false,
                error: null,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      case types.upload.REQUEST: {
        const lens = createFileLens(action.payload.id);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                failed: false,
                progress: 0,
                uploading: true,
                uploaded: false,
                error: null,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      case types.upload.SUCCESS: {
        const lens = createFileLens(action.payload.id);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                fileId: action.response.file_id,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      case types.upload.FAILURE:
      case types.confirm.FAILURE: {
        const lens = createFileLens(action.payload.id);
        console.log('CONFIRM/UPLOAD Failure', action);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                uploading: false,
                failed: true,
                uploaded: false,
                error: action.error,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      case types.delete.SUCCESS: {
        const lens = createFileLens(action.payload.id);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                uploading: false,
                uploaded: false,
                failed: false,
                deleted: true,
                error: null,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      case types.confirm.SUCCESS: {
        const lens = createFileLens(action.payload.id);
        console.log('CONFIRM SUCCESS', action);
        return R.over(
          lens,
          R.compose(
            R.mergeDeepLeft({
              fileUpload: {
                id: action.response?.result,
                failed: false,
                uploading: false,
                uploaded: true,
                deleted: false,
                error: null,
              },
            }),
            R.defaultTo({})
          ),
          state
        );
      }
      default:
        return state;
    }
  };

export default uploadFileRequestReducerFactory;
