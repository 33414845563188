/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Icon, IconsCollection, Content } from 'imdui';
import {
  ItemWrapper,
  wrapperStyle,
  ProgressBar,
  progressStyle,
  GapWrapper,
  gapStyle,
  itemsWrapperStyle,
} from './styles';

type Props = {
  steps: string[];
  completed?: boolean;
};

const PaymentStepItem: React.FC<{ label: string; selected: boolean }> = ({
  label,
  selected,
}) => (
  <ItemWrapper selected={selected}>
    <Icon d={IconsCollection.diamond} />
    <Content>{label}</Content>
  </ItemWrapper>
);

/**
 * Progress bar that gives customer a hint what's next.
 * NB: only MOCKING real progress
 */
const PaymentProgressIndicator: React.FC<Props> = ({
  steps,
  completed = false,
}) => {
  const gapsCount = steps.length - 1;
  const gapArray = Array(gapsCount).fill(0);

  return (
    <div css={wrapperStyle}>
      <ProgressBar fullyCompleted={completed && gapsCount < 2}>
        <div css={progressStyle} />

        <GapWrapper completed={completed}>
          {gapArray.map((_, i) => (
            <div key={i} css={gapStyle} />
          ))}
        </GapWrapper>

        <div />
      </ProgressBar>

      <div css={itemsWrapperStyle}>
        {steps.map((s, i) => (
          <PaymentStepItem
            key={s}
            label={s}
            selected={i < (completed ? 2 : 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default PaymentProgressIndicator;
