import { useEffect, useMemo } from 'react';
import type { Values } from './types';
import { EnabledField } from 'imdshared';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import type { ReduxState } from 'imddata';

export const YoutubeContentIdToggle = ({
  ...props
}: React.ComponentProps<typeof EnabledField>) => {
  const { form } = props.meta;
  const selector = useMemo(() => formValueSelector(form), [form]);
  const shops: Values['shops'] = useSelector((state: ReduxState) =>
    selector(state, 'shops')
  );

  const hasYouTube =
    shops?.shops?.find((s) => s.shopId === 'you_tube')?.status === 'deliver';

  useEffect(() => {
    if (!hasYouTube && props.input.value) {
      // TODO: may be we need to unset it?
      // Do not change spotify shop info

      props.input.onChange(false);
    }
  }, [hasYouTube]);

  return <EnabledField {...props} disabled={!hasYouTube || props.disabled} />;
};
