export default function mapEcommerceProperties(item, idKey) {
  return {
    [idKey]: item.id,
    value: item.totalValueEur,
    currency: 'EUR',
    products: item.items
      ? item.items.map(
          ({
            name,
            productId,
            subscriptionId,
            releaseTypeId,
            description,
            quantity,
            valueEur,
          }) => ({
            name,
            description,
            releaseTypeId,
            productId,
            subscriptionId,
            quantity,
            value: valueEur,
          })
        )
      : undefined,
  };
}
