//
import { fork } from 'redux-saga/effects';
import { runDataSagas } from 'imddata';
import payouts from './payouts';
import authRoot from './auth';
import orders from './orders';

export default function* root() {
  yield fork(authRoot);
  yield fork(runDataSagas, [payouts, orders], []);
}
