import type { Accept, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect, Fragment } from 'react';
import { Button, Caption, FieldCard, Window } from 'imdui';
import { JoinTagItem } from 'imdshared/src/Next/JoinTags/JoinTagsList';
import { JoinTagAddButton } from 'imdshared/src/Next/JoinTags';
import { WindowWrapper } from './WindowWrapper';
import { JoinTagItemWrapper } from 'imdshared/src/Next/JoinTags/JoinTagsList/JoinTagItem';
import { css } from '@emotion/react';
import type { PressFile } from '@imus/artist-page-template';
import { InputField, useSingelFileUploadManager } from 'imdshared';
import { useEntityActions, useUpdateEntity } from 'imddata';

type ArtistCollectionFieldProps = {
  artistId: number;
  defaultDescription: string;
  accept: Accept;
  dragType?: string;
  addText: string;
};

const buttonWrapperStyle = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;
`;

type FieldValue = PressFile & { id?: string };

const ArtistFileForm = reduxForm<
  FieldValue,
  { submitText: string; onSubmit: (v: FieldValue) => void }
>({
  form: 'ArtistFile',
})(({ submitText, initialValues, handleSubmit, onSubmit }) => {
  const { t } = useTranslation();

  if (!initialValues.id) {
    return null;
  }
  return (
    <>
      <Field
        name="description"
        component={InputField}
        label={t('description')}
      />
      <Button
        text={submitText}
        onClick={handleSubmit(onSubmit)}
        type="submit"
      />
    </>
  );
});

const FileUploader = ({
  file,
  artistId,
  onUploaded,
  onFailed,
}: {
  artistId: number;
  file: File;
  onUploaded: (result: any, id?: string) => void;
  onFailed: () => void;
}) => {
  const { request, upload, preview } = useSingelFileUploadManager({
    handlerData: { artistId, confirmPayload: { description: file.name } },
    handler: 'artistFile',
  });
  useEffect(() => {
    upload(file);
  }, []);
  useEffect(() => {
    if (request.failed) {
      onFailed();
      return;
    }
    if (request.uploaded) {
      onUploaded(preview, request.id as string);
    }
  }, [request.failed, request.uploaded]);

  return null;
};

export const ArtistFilesField = ({
  fields,
  accept,
  dragType,
  addText,
  defaultDescription,
  artistId,
}: WrappedFieldArrayProps<FieldValue> & ArtistCollectionFieldProps) => {
  const [formState, setFormState] = useState<null | 'add' | number>(null);
  const editing = typeof formState === 'number';
  const [uploadQueue, setUploadQueue] = useState<{ id: string; file: File }[]>(
    []
  );

  const [failedQueue, setFailedQueue] = useState<
    { id: string; file: File; errors: FileRejection['errors'] }[]
  >([]);
  const { updateEntry } = useUpdateEntity({
    entity: 'artistFiles',
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[], failedFiles: FileRejection[]) => {
      if (acceptedFiles.length) {
        setUploadQueue((uq) => [
          ...uq,
          ...acceptedFiles.map((file) => ({ id: uuidv1(), file })),
        ]);
      }
      if (failedFiles.length) {
        setFailedQueue((uq) => [
          ...uq,
          ...failedFiles.map(({ file, errors }) => ({
            id: uuidv1(),
            file,
            errors,
          })),
        ]);
      }
    },
    []
  );

  const { remove: deleteFile } = useEntityActions('filesManager');

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 5242880,
    onDrop: handleDrop,
    accept,
  });
  const { t } = useTranslation();
  return (
    <>
      <input {...getInputProps()} />
      <FieldCard style={{ padding: 0, gap: 0, overflow: 'hidden' }}>
        {fields.map((f, index) => {
          const v = fields.get(index);
          if (!v) return null;
          return (
            <>
              <JoinTagItem
                style={{
                  minHeight: '56px',
                }}
                key={index}
                index={index}
                value={{
                  label: v.description || defaultDescription,
                  sublabel: v.file?.filename || '',
                }}
                onClickItem={() => {
                  setFormState(index);
                }}
                type={dragType}
                onClickDelete={() => {
                  deleteFile({
                    id: v.id,
                    data: {
                      handlerData: {
                        artistId,
                        artistFileId: v.id,
                      },
                      handler: 'artistFile',
                    },
                  });
                  fields.remove(index);
                }}
                onDropItem={
                  dragType ? ({ from, to }) => fields.swap(from, to) : undefined
                }
              />
            </>
          );
        })}

        {uploadQueue.map((f, index) => {
          return (
            <Fragment key={f.id + '-item'}>
              <JoinTagItem
                style={{
                  minHeight: '56px',
                }}
                index={index}
                value={{
                  label: defaultDescription,
                  sublabel: t('uploading'),
                }}
              />
              <FileUploader
                key={f.id + '-uploader'}
                artistId={artistId}
                file={f.file}
                onUploaded={({ size }, id) => {
                  const nameParts = f.file.name.split('.');
                  setUploadQueue((uq) => uq.filter((uf) => uf.id !== f.id));
                  fields.push({
                    description: '',
                    id,
                    file: {
                      ...f.file,
                      extension: nameParts[nameParts.length - 1],
                      access: '',
                      size,
                      filename: f.file?.name || '',
                    },
                  });
                }}
                onFailed={() => {
                  setUploadQueue((uq) => uq.filter((uf) => uf.id !== f.id));
                }}
              />
            </Fragment>
          );
        })}

        {failedQueue.map((f, index) => {
          return (
            <JoinTagItem
              key={f.id + '-item'}
              style={{
                minHeight: '56px',
              }}
              index={index}
              onClickDelete={() => {
                setFailedQueue((fq) => fq.filter((ff) => ff.id !== f.id));
              }}
              value={{
                label: f.file.name,
                sublabel: (
                  <Caption style={{ color: 'red' }}>
                    {t(f.errors[0]?.code)}
                  </Caption>
                ),
              }}
            />
          );
        })}
        <JoinTagItemWrapper isOver={false}>
          <div css={buttonWrapperStyle}>
            <JoinTagAddButton
              icon={undefined}
              text={addText}
              size="small"
              onClick={getRootProps().onClick}
            />
          </div>
        </JoinTagItemWrapper>
      </FieldCard>
      <Window
        lockClickOutside={true}
        title={t('press-file')}
        isOpen={formState !== null}
        close={() => setFormState(null)}
      >
        <WindowWrapper>
          <ArtistFileForm
            submitText={editing ? t('save') : t('add')}
            initialValues={editing ? fields.get(formState) : {}}
            onSubmit={(values: FieldValue) => {
              if (editing) {
                updateEntry(
                  {
                    data: { description: values.description },
                    query: { artistId },
                    id: values.id,
                  },
                  { debounce: true }
                );
                fields.splice(formState, 1, values);
                setFormState(null);
              }
            }}
          />
        </WindowWrapper>
      </Window>
    </>
  );
};
