import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { Centered, LoadingIndicator } from 'imdshared';
import { useReleases, useQuery } from 'imddata';
import { AutosizeGrid } from 'imdui';
import { useInView } from 'react-intersection-observer';
import { ReleaseGridCell } from './components';

type Props = {
  searchQuery: any;
};

const loadingIndicatorStyle = css`
  margin-top: 48px;
`;

const ReleaseGrid: React.FC<Props> = ({ searchQuery }) => {
  const { query, queryHash } = useQuery({ query: searchQuery });
  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useReleases({ query, queryHash });

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!loading && inView && hasMorePages && loadMore && entries) {
      loadMore();
    }
  }, [inView, loading, hasMorePages, loadMore]);

  return (
    <>
      <AutosizeGrid gap={24} minWidth={150} maxColumnCount={6}>
        {entries.map((release) => (
          <ReleaseGridCell key={release.id} item={release} />
        ))}
      </AutosizeGrid>
      <div ref={ref} />

      {loading && (
        <Centered css={loadingIndicatorStyle}>
          <LoadingIndicator size="large" />
        </Centered>
      )}
    </>
  );
};

export default ReleaseGrid;
