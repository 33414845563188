//
import React, { useMemo, useCallback, Component } from 'react';
import { useTranslation, Translation } from 'react-i18next';
import { filter, union, without, find, append } from 'ramda';
import type { EntityModels, EntityIdentifier } from 'imddata';
import {
  useTrack,
  useEntryProvider,
  useEntriesProvider,
  useEntityFileRequestProvider,
} from 'imddata';

import { getTrackIsrc } from '../../helpers';
import InfiniteList from '../InfiniteList';
import type {
  InfiniteListProps,
  InfiniteListRowRenderer,
} from '../InfiniteList';
import Track from '../Track';
import TrackDraggable from '../Track/TrackDraggable';
import GroupHeader from './GroupHeader';

type Track = Exclude<ReturnType<typeof useTrack>['entry'], null>;

type Selection = (number | string)[];

const groupHeaderTitleRenderer = (item: GroupEntry) =>
  item && item.title ? item.title : '';

export const checkGroup = (
  selectedTracks: Selection,
  tracks: Selection,
  selected: Selection
) =>
  selected ? without(tracks, selectedTracks) : union(tracks, selectedTracks);

export const getDisplayTitle = (
  title: string,
  workTitle: string | undefined,
  isClassical: boolean
) => {
  if (isClassical && workTitle) {
    return `${workTitle}: ${title}`;
  }

  return title;
};

type TrackRowProps = {
  hideCheckbox?: boolean;
  children?: React.ReactNode;
  hideReleaseName?: boolean;
  showIsrc?: boolean;
  trackId: number | string;
  index: number;
  displayTrackNumber?: (t: Track, index?: number) => number;
  isSelected: (t: Track) => boolean;
  isDisabled: (t: Track) => boolean;
  isVerifying?: (t: Track) => boolean;
  isValid: (t: Track, uploading?: boolean) => boolean;
  isTrackInContentId?: (id: number) => boolean;
  onCheck?: (e: any, track: Track, checked: boolean) => void;
  onClick?: (e: any, track: Track, checked: boolean) => void;
  onMove?: (from: number, to: number) => void;
  onDrop?: (changed: boolean, newPosition: number) => void;
  style?: React.CSSProperties;
};

export const ConnectedTrackRow = ({
  hideCheckbox,
  children,
  trackId,
  index,
  displayTrackNumber,
  isSelected,
  isValid,
  isDisabled,
  isVerifying,
  hideReleaseName,
  isTrackInContentId,
  onCheck,
  onClick,
  onMove,
  onDrop,
  style,
  showIsrc,
}: TrackRowProps) => {
  const { t } = useTranslation();
  const { entry: track } = useTrack({ passive: true, id: trackId });

  const fileUpload = useEntityFileRequestProvider({
    entity: 'tracks',
    id: track?.id,
  });
  if (!track)
    return (
      // @ts-ignore
      <Track
        style={style}
        hideReleaseName={hideReleaseName}
        hideCheckbox={hideCheckbox}
        disabled={true}
      />
    );
  const title = track.defaultNameNormalized?.title || track.title;
  const workTitle = track.defaultNameNormalized?.workTitle || track.workTitle;
  const version = track.defaultNameNormalized?.version || track.version;
  const { isClassical } = track;
  const artistName =
    track.defaultNameNormalized?.displayArtist || track.displayArtist;

  const displayTitle = getDisplayTitle(title, workTitle, isClassical);

  const checked = isSelected(track);
  return (
    <TrackDraggable
      showIsrc={showIsrc}
      testId={`track-${index}${isDisabled(track) ? '-disabled' : '-ready'}`}
      style={style}
      isMastered={track.isMastered}
      isInContentId={
        track.isInContentId ||
        (isTrackInContentId && isTrackInContentId(track.id))
      }
      index={index}
      trackId={track.id}
      trackName={displayTitle}
      version={version}
      artistName={artistName}
      number={displayTrackNumber ? displayTrackNumber(track, index) : undefined}
      valid={isValid(track, fileUpload && fileUpload.uploading)}
      verifying={
        (isVerifying && isVerifying(track)) ||
        track.uploadStatus === 'confirmed' ||
        track.previewStatus === 'pending'
      }
      disabled={isDisabled(track)}
      hideCheckbox={hideCheckbox}
      errorMessage={
        fileUpload && fileUpload.failed
          ? // TODO: fix fileupload def
            // @ts-ignore
            fileUpload.error?.message
            ? // @ts-ignore
              t(fileUpload.error.message)
            : t('upload-failed')
          : undefined
      }
      progressUploading={
        fileUpload && fileUpload.uploading
          ? fileUpload.progress < 2
            ? 2
            : fileUpload.progress
          : 0
      }
      releaseName={
        track && track.releases
          ? track.releases.reduce((acc, trackRelease, i) => {
              if (!trackRelease) return acc;
              const releaseName = trackRelease.release?.defaultName?.title;
              if (!releaseName) return acc;
              return `${acc}${i > 0 ? ' / ' : ''}${releaseName}`;
            }, '')
          : ''
      }
      isrc={getTrackIsrc(track)}
      hideReleaseName={hideReleaseName}
      checked={checked}
      onCheck={(e: any) => {
        if (onCheck) {
          onCheck(e, track, checked);
        }
      }}
      onClick={(e: any) => {
        if (onClick) {
          onClick(e, track, checked);
        }
      }}
      onMove={onMove}
      onDrop={onDrop}
    >
      {children}
    </TrackDraggable>
  );
};

function EntityHeader({
  style,
  disabled,
  item,
  checked,
  subchecked,
  onCheck,
}: {
  onCheck: (e: any) => void;
  checked: boolean;
  disabled: boolean;
  subchecked: boolean;
  item: EntityGroup;
  style?: React.CSSProperties;
}) {
  const entry = useEntryProvider<any>({
    entity: item.entity,
    id: item.id,
  });
  return (
    <GroupHeader
      {...{
        disabled,
        title: entry ? entry.title || entry.name : item.id,
        checked,
        subchecked,
        onCheck,
        style,
      }}
    />
  );
}

function GroupRenderer({
  selectedTracks,
  style,
  isSelected,
  isDisabled,
  item,
  onCheckGroup,
}: {
  selectedTracks?: Selection;
  style?: React.CSSProperties;
  isSelected: TrackRowProps['isSelected'];
  isDisabled: TrackRowProps['isDisabled'];
  item: GroupEntry;
  onCheckGroup: TrackListProps['onCheckGroup'];
}) {
  const { t } = useTranslation();
  const { entries: tracks } = useEntriesProvider<EntityModels.Track>({
    entity: 'tracks',
    ids: item.ids,
  });
  const { checked, subchecked } = useMemo(
    () =>
      tracks?.reduce(
        (acc, track) => {
          return {
            // TODO: fix isSelected def
            // @ts-ignore
            checked: isSelected(track) && acc.checked,
            // @ts-ignore
            subchecked: isSelected(track) || acc.subchecked,
          };
        },
        { checked: true, subchecked: false }
      ) || {},
    [selectedTracks, tracks, isSelected]
  );

  const selectableTracks = useMemo(
    () =>
      tracks
        ? tracks
            .filter((track) => {
              // TODO: fix isSelected def
              // @ts-ignore
              return track && !isDisabled(track);
            })
            .map((track) => track.id)
        : [],
    [tracks, isDisabled]
  );

  const handleCheck = useCallback(
    (e: any) => {
      if (onCheckGroup) {
        onCheckGroup(e, selectableTracks, checked);
      }
    },
    [onCheckGroup, selectableTracks, checked]
  );

  if ('entity' in item) {
    return (
      <EntityHeader
        item={item}
        checked={checked}
        disabled={selectableTracks.length === 0}
        subchecked={subchecked}
        onCheck={handleCheck}
        style={style}
      />
    );
  }

  return (
    <GroupHeader
      {...{
        testId: item.key,
        title:
          item.key === 'new-group'
            ? t('tracks')
            : groupHeaderTitleRenderer(item),
        checked,
        disabled: selectableTracks.length === 0,
        subchecked,
        onCheck: handleCheck,
        style,
      }}
    />
  );
}

type TrackListProps = {
  targetName?: keyof EntityModels.Track['targets'];
  isValid: (t: Track, d: null, uploading: boolean | undefined) => boolean;
  selectedTracks?: Selection;
  disabledTracks?: Selection;
  isDisabled?: (t: Track) => boolean;
  onChangeSelection?: (tracks: Selection) => void;
  showIsrc?: boolean;
  onClickTrack?: (
    e: any,
    id: number,
    selected: boolean,
    fullSelection: Selection
  ) => void;
  onCheckGroup?: (
    e: any,
    id: Selection,
    selected: boolean,
    fullSelection?: Selection
  ) => void;
  onCheckTrack?: (
    e: any,
    id: number,
    selected: boolean,
    selectedTracks: Selection
  ) => void;
  trackRenderer?: (p: TrackRowProps) => React.ReactElement<any, any> | null;
  onDrop?: TrackRowProps['onDrop'];
  onMove?: TrackRowProps['onMove'];
  isVerifying?: TrackRowProps['isVerifying'];
  hideCheckbox?: TrackRowProps['hideCheckbox'];
  isTrackInContentId?: TrackRowProps['isTrackInContentId'];
  hideReleaseName?: TrackRowProps['hideReleaseName'];
  displayTrackNumber?: TrackRowProps['displayTrackNumber'];
  trackChildren?: React.ReactNode;
};

type GroupEntry = BasicGroup | EntityGroup;

type BasicGroup = {
  key: string;
  ids: Selection;
  type: 'group-title';
  title: string;
};
type EntityGroup = BasicGroup & {
  entity: EntityIdentifier;
  id: number | string;
};

type EntryObject = { id: number | string };

type DataEntry = string | number | GroupEntry | EntryObject;

class TracksListRenderer extends Component<
  TrackListProps & InfiniteListProps<DataEntry>
> {
  static defaultProps = {
    trackRenderer: ConnectedTrackRow,
  };

  isSelected: TrackRowProps['isSelected'] = ({ id, tempId }) => {
    const { selectedTracks } = this.props;

    if (selectedTracks) {
      return !!find(
        (trackId) => trackId === id || trackId === tempId,
        selectedTracks
      );
    }
    return false;
  };

  isValid: TrackRowProps['isValid'] = (track, uploading) => {
    const { targetName, isValid } = this.props;
    if (isValid) return isValid(track, null, uploading);
    if (!track) return true;
    if (targetName && track.targets) {
      return track.targets[targetName] && track.targets[targetName].isMet;
    }
    return true;
  };

  isDisabled: TrackRowProps['isDisabled'] = (track) => {
    const { isDisabled, disabledTracks } = this.props;
    if (isDisabled) return isDisabled(track);

    if (disabledTracks) {
      return !!find((id) => id === track.id, disabledTracks);
    }
    return false;
  };

  handleCheckTrack: TrackRowProps['onCheck'] = (e, track, selected) => {
    const { onCheckTrack, selectedTracks, onChangeSelection } = this.props;
    const trackArray = selectedTracks || [];
    if (onCheckTrack) {
      return onCheckTrack(e, track.id, !selected, trackArray);
    }
    if (onChangeSelection) {
      onChangeSelection(
        selected
          ? filter((id) => id !== track.id, trackArray)
          : append(track.id, trackArray)
      );
    }
    return null;
  };

  handleClickTrack: TrackRowProps['onClick'] = (e, track, selected) => {
    const { onClickTrack, selectedTracks, onChangeSelection } = this.props;
    const trackArray = selectedTracks || [];
    if (onClickTrack) {
      return onClickTrack(e, track.id, !selected, trackArray);
    }
    if (onChangeSelection) {
      onChangeSelection(selected ? [] : [track.id]);
    }
    return null;
  };

  handleCheckGroup = (e: any, tracks: Selection, selected: boolean) => {
    const { onCheckGroup, selectedTracks, onChangeSelection } = this.props;
    const trackArray = selectedTracks || [];
    if (onCheckGroup) {
      return onCheckGroup(e, tracks, !selected, trackArray);
    }
    if (onChangeSelection) {
      onChangeSelection(
        selected ? without(tracks, trackArray) : union(tracks, trackArray)
      );
    }
    return null;
  };

  RowRenderer: InfiniteListRowRenderer<DataEntry> = ({
    item,
    style,
    index,
  }) => {
    if (!item) return null;
    const {
      showIsrc,
      hideReleaseName,
      trackChildren,
      trackRenderer,
      hideCheckbox,
      displayTrackNumber,
      isTrackInContentId,
      isVerifying,
      selectedTracks,
    } = this.props;
    if (
      item &&
      typeof item === 'object' &&
      'type' in item &&
      item.type === 'group-title'
    ) {
      return (
        <GroupRenderer
          selectedTracks={selectedTracks}
          style={style}
          isSelected={this.isSelected}
          isDisabled={this.isDisabled}
          onCheckGroup={this.handleCheckGroup}
          item={item}
        />
      );
    }

    const trackId = typeof item === 'object' ? (item as EntryObject).id : item;

    // TODO: fix wrong type of groupheader being asserted
    if (!trackId) return null;

    return trackRenderer!({
      onClick: this.handleClickTrack,
      onCheck: this.handleCheckTrack,
      onMove: this.props.onMove,
      onDrop: this.props.onDrop,
      isValid: this.isValid,
      isDisabled: this.isDisabled,
      isSelected: this.isSelected,
      isVerifying,
      hideCheckbox,
      isTrackInContentId,
      children: trackChildren,
      hideReleaseName,
      showIsrc,
      displayTrackNumber,
      trackId,
      index,
      style,
    });
  };

  render() {
    const {
      data,
      hasNextPage,
      loadNextPage,
      scrollElement,
      selectedTracks,
      disabledTracks,
      metaProps,
      virtualized = true,
      ...props
    } = this.props;
    if (!data) return null;
    if (!virtualized) {
      if (!data.length) {
        return props.noRowsRenderer ? (
          <props.noRowsRenderer emptyPlaceholder={props.emptyPlaceholder} />
        ) : (
          props.emptyPlaceholder
        );
      }
      return data.map((item, index) => (
        // @ts-ignore
        <this.RowRenderer
          style={{
            height: props.getRowHeight
              ? props.getRowHeight({ index, row: item })
              : '56px',
          }}
          key={`${index}`}
          item={item}
          index={index}
        />
      ));
    }
    return (
      <Translation>
        {(t) => (
          <InfiniteList<DataEntry>
            data={data}
            scrollElement={scrollElement}
            hasNextPage={hasNextPage}
            loadNextPage={loadNextPage}
            emptyPlaceholder={t('no-tracks')}
            {...props}
            rowRenderer={this.RowRenderer}
            selectedTracks={selectedTracks}
            disabledTracks={disabledTracks}
          />
        )}
      </Translation>
    );
  }
}

export default TracksListRenderer;
