import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ArtworkImage, CardSection, Caption, Body, Content } from 'imdui';
import type { DBNested, UseFieldUpdateRequestsEntry } from 'imddata';
import { useReleaseCovers } from 'imddata';
import { Chip, AlertBox } from 'imdshared';

const InformationWrapper = styled.div`
  padding: 24px;
`;

const AdminNotesCaption = styled(Caption)`
  margin: 12px 0;
  display: block;
`;

const ArtworkImageStyled = styled(ArtworkImage)`
  max-width: 64px;
  max-height: 64px;
  margin-right: 32px;
`;

const ContextRow = styled.div`
  display: flex;
`;
const ReleaseInfoColumn = styled.div`
  flex: 0.7;
`;
const RequestAtColumn = styled.div`
  flex: 0.3;
`;

const ReleaseWithContextDetails = ({
  className,
  style,
  entry,
  releaseId,
}: {
  entry: UseFieldUpdateRequestsEntry;
  releaseId?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  const { entry: release } = useReleaseCovers({ id: releaseId, passive: true });
  const { t } = useTranslation();

  if (!releaseId) return null;

  return (
    <div className={className} style={style}>
      <ContextRow>
        <ArtworkImageStyled src={release?.availableCovers?.xSmall} />
        <ReleaseInfoColumn>
          <Caption>{t('release-info')}</Caption>
          <br />
          {release?.defaultNameNormalized?.title}
        </ReleaseInfoColumn>
        <RequestAtColumn>
          <Caption>{t('requested-at')}</Caption>
          <br />
          {entry ? moment(entry.requestedAt).format('L') : null}
        </RequestAtColumn>
        <div>
          <Caption>{t('status')}</Caption>
          <br />
          <Chip>
            <Content>{t(entry?.status)}</Content>
          </Chip>
        </div>
      </ContextRow>
    </div>
  );
};

const ReleaseWithContextDetailsStyled = styled(ReleaseWithContextDetails)`
  border-bottom: ${(props) =>
    props.entry?.adminMessage ? '1px solid #e1e1e1' : ''};
  padding-bottom: ${(props) => (props.entry?.adminMessage ? '24px' : '')};
`;

const FieldUpdateRequestInformation = ({
  entry,
  bundle,
}: {
  entry: UseFieldUpdateRequestsEntry;
  bundle?: DBNested;
}) => {
  const { t } = useTranslation();
  const releaseId = bundle?.releases[0]?.releaseId;

  return (
    <>
      {(releaseId || entry.adminMessage) && (
        <CardSection
          collapsible={false}
          title={<Content>{t('field-update-request-information')}</Content>}
        >
          <InformationWrapper>
            <ReleaseWithContextDetailsStyled
              entry={entry}
              releaseId={releaseId}
            />
            {entry.adminMessage && (
              <>
                <AdminNotesCaption>{t('notes-from-admin')}</AdminNotesCaption>
                <Body>
                  {entry.adminMessage.replace(/{{[^}]*}}/g, '').trim()}
                </Body>
              </>
            )}
          </InformationWrapper>
        </CardSection>
      )}

      {entry.status === 'requested' && (
        <CardSection
          collapsible={false}
          title={<Content>{t('update-requests')}</Content>}
        >
          <InformationWrapper>
            <AlertBox
              style={{ marginTop: '12px' }}
              type={AlertBox.Type.warning}
              text={t('you-have-fields-requiring-feedback')}
            />
          </InformationWrapper>
        </CardSection>
      )}
    </>
  );
};

export default FieldUpdateRequestInformation;
