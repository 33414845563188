import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import { DevComponent } from 'imdshared';
import { useSelector } from 'react-redux';
import {
  OptimizelyFeature,
  useFeature as useOptimizelyFeature,
} from '@optimizely/react-sdk';
import type { ReduxState } from 'imddata';

const DEMO_VARS = {};

const EMERGENCY_OVERRIDE: any = {
  'promo-subs': true,
  payg: true,
  'tiered-subs': true,
  'revenue-splits': true,
  'referral-system': true,
  'subs-v2': true,
  onboarding: true,
  'field-update-request-check-system': true,
};

export const useFeature = ({ featureKey }: { featureKey: string }) => {
  const devmode = useSelector((state: ReduxState) => state.dev.devmode);
  const devFeatures = useSelector((state: ReduxState) => state.dev.features);
  const dummyHook = useMemo(() => {
    if (devmode && devFeatures?.[featureKey] !== undefined) {
      return () => [devFeatures[featureKey], true, true, true];
    }

    if (EMERGENCY_OVERRIDE[featureKey] !== undefined) {
      return () => [EMERGENCY_OVERRIDE[featureKey], true, true, true];
    }

    return undefined;
  }, [devFeatures]);

  const featureHook = dummyHook || useOptimizelyFeature;
  return featureHook(featureKey, {
    autoUpdate: true,
  });
};

export default function Feature(
  props: ComponentProps<typeof OptimizelyFeature>
) {
  return (
    <DevComponent>
      {(devmode: boolean | string) => {
        if (devmode && devmode !== 'ci') {
          return props.children(true, DEMO_VARS, true, false);
        }
        return <OptimizelyFeature autoUpdate={true} {...props} />;
      }}
    </DevComponent>
  );
}
