import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Scrim = styled(motion.div)`
  z-index: 104;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(229, 229, 229, 0.5);
`;

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.2);
  cursor: default;

  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 400px) {
    width: 400px;
  }
`;
