import React from 'react';
import styled from '@emotion/styled';
import { useResizeDetector } from 'react-resize-detector';
import { LoadingIndicator, AlertBox } from 'imdui';

const RENDERED_HEIGHT = 720;
const RENDERED_WIDTH = 1280;

const NaturalScale = styled.div<{ scaleFactor: number }>`
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0 0;
  overflow: hidden;
  background: #f2f2f2;

  box-sizing: border-box;

  transform: scale(${(props) => props.scaleFactor});
  width: ${RENDERED_HEIGHT}px;
  height: ${RENDERED_WIDTH}px;
  & > * {
    width: 100% !important;
    height: 100% !important;
  }
  @media (min-width: 1024px) {
    width: ${RENDERED_WIDTH}px;
    height: ${RENDERED_HEIGHT}px;
  }
`;

const DownscaleWrapper = styled.div<{
  widthValue: number;
  heightValue: number;
}>`
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    width: ${(props) => props.widthValue}px;
    height: ${(props) => props.heightValue}px;
  }
  height: ${(props) => props.widthValue}px;
  width: ${(props) => props.heightValue}px;
  & > * {
    z-index: 1;
  }
`;

const Wrap = styled.div`
  display: grid;
  overflow: hidden;
  align-items: center;
  flex: 744px;
  flex-shrink: 0;
  gap: 24px;
  justify-content: center;
`;

export const ReleasePageTemplateScaler = ({
  children,
  loadingText,
  errorText,
  className,
  style,
}: {
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  loadingText?: string | boolean;
  errorText?: string;
  chipText?: string;
  onChipClick?: React.MouseEventHandler;
}) => {
  const { width = 640, ref } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 500,
    handleWidth: true,
  });

  const scaledHeight = Math.ceil(
    Math.min((Math.ceil(width) / 16) * 9, RENDERED_HEIGHT)
  );
  const scale = Math.round(1000 * (scaledHeight / RENDERED_HEIGHT)) / 1000;

  return (
    <Wrap ref={ref}>
      <DownscaleWrapper
        className={className}
        style={style}
        widthValue={Math.ceil(width)}
        heightValue={scaledHeight}
      >
        <NaturalScale scaleFactor={scale}>{children}</NaturalScale>
        {loadingText ? (
          <LoadingIndicator size="large" />
        ) : errorText ? (
          <AlertBox type={AlertBox.Type.error} title={errorText} />
        ) : null}
      </DownscaleWrapper>
    </Wrap>
  );
};
