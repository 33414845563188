//
import React, { PureComponent } from 'react';
import update from 'immutability-helper';
import { equals, find } from 'ramda';
import TracksListRenderer, { checkGroup } from '../TracksListRenderer';
import Header from './Header';

class DraggableTracksLst extends PureComponent {
  static getDerivedStateFromProps({ items }, state) {
    if (!equals(items, state.savedItems)) {
      return {
        items,
        savedItems: items,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = { items: props.items, savedItems: props.items };
  }

  handleTrackDrop = (isPositionChanged /* , dropIndex */) => {
    if (isPositionChanged && this.props.onChangeTracksOrder) {
      this.props.onChangeTracksOrder(this.state.items);
    }
  };

  handleTrackMove = (dragIndex, hoverIndex) => {
    this.setState((state) => {
      let tracks = state.items;
      const dragTrack = tracks[dragIndex];
      tracks = update(tracks, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragTrack],
        ],
      });
      return { items: tracks };
    });
  };

  render() {
    const { columns, disableDrag, selectedTracks, isLoading, ...props } =
      this.props;
    const { items } = this.state;
    const isTracksListEmpty = !isLoading && (!items || !items.length);
    const areAllTracksChecked =
      !isLoading && items && items.length === selectedTracks.length;

    return (
      <div>
        <Header
          onCheck={() =>
            this.props.onChangeSelection(
              checkGroup(items, selectedTracks, areAllTracksChecked)
            )
          }
          checked={areAllTracksChecked && selectedTracks.length > 0}
          subchecked={!areAllTracksChecked && selectedTracks.length > 0}
          disableCheckbox={
            find((value) => /TEMP/.test(value), items) || isTracksListEmpty
          }
          disableDrag={disableDrag}
          columns={columns}
          hideCheckbox={props.hideCheckbox}
        />

        <TracksListRenderer
          isLoading={isLoading}
          targetName={this.props.targetName}
          onMove={disableDrag ? undefined : this.handleTrackMove}
          onDrop={disableDrag ? undefined : this.handleTrackDrop}
          displayTrackNumber={
            columns.number ? (track, index) => index + 1 : false
          }
          hideReleaseName={!columns.album}
          data={items}
          onChangeSelection={this.props.onChangeSelection}
          selectedTracks={selectedTracks}
          {...props}
        />
      </div>
    );
  }
}

export default DraggableTracksLst;
