import actions from './actions';
import arrows from './arrows';
import categories from './categories';
import music from './music';
import playback from './playback';
import selection from './selection';
import states from './states';

export default {
  music,
  playback,
  actions,
  selection,
  arrows,
  states,
  categories,
};
