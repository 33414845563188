import { AppTitle, H1, TextFormatted } from 'imdui';
import { TextBlockWrapper } from './TextBlock';
import { useTranslation } from 'react-i18next';
import { DashboardCard } from 'components';
import { InfoContentCard } from './InfoContentCard';
import { BodyL } from '@imus/services-ui/src/Text';

export const CancelFlowSuccess = ({
  firstName,
  downgradeToId,
  successText,
  renewalText,
  renewalDate,
}: {
  firstName?: string;
  downgradeToId: string | null;
  successText?: string;
  renewalText?: string;
  renewalDate: string | null;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1535_72)">
          <rect x="4" y="2" width="80" height="80" rx="40" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.9142 30.5858C60.6953 31.3668 60.6953 32.6332 59.9142 33.4142L39.9142 53.4142C39.1332 54.1953 37.8668 54.1953 37.0858 53.4142L28.0858 44.4142C27.3047 43.6332 27.3047 42.3668 28.0858 41.5858C28.8668 40.8047 30.1332 40.8047 30.9142 41.5858L38.5 49.1716L57.0858 30.5858C57.8668 29.8047 59.1332 29.8047 59.9142 30.5858Z"
            fill="#82BD32"
          />
          <rect
            x="4.5"
            y="2.5"
            width="79"
            height="79"
            rx="39.5"
            stroke="#82BD32"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_1535_72"
            x="0"
            y="0"
            width="88"
            height="88"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="2"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_1535_72"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1535_72"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1535_72"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      <TextBlockWrapper>
        <H1>
          {t('all-set', {
            firstName,
            context: firstName ? 'with-first-name' : '',
          })}
        </H1>
        <BodyL>{successText}</BodyL>
      </TextBlockWrapper>
      <InfoContentCard>
        <div>
          <AppTitle>{renewalText}</AppTitle>
          <DashboardCard.Text>
            <TextFormatted
              text={t('you-can-still-use-your-plan-until', {
                renewalDate,
              })}
            />
          </DashboardCard.Text>
        </div>
        {!downgradeToId && (
          <div>
            <AppTitle>{t('we-still-want-to-be-helpful')}</AppTitle>
            <DashboardCard.Text>
              {t('we-still-want-to-be-helpful-text')}
            </DashboardCard.Text>
          </div>
        )}
      </InfoContentCard>
    </>
  );
};
