import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState, Query } from '../../../types';
import filterQuery from '../../../utils/filterQuery';
import { useFetchEntity } from '../base';
import { useQuery } from '../useQueryHash';

export type TrendEntry = { value: number | null; date: Moment };

type StreamLine = Array<TrendEntry>;

const mapTrendLine = (
  streams: Array<number> | Array<[number, number]>,
  [from, to]: [Moment, Moment]
): {
  fromTo: [Moment, Moment];
  accountable: StreamLine;
  nonAccountable: null | StreamLine;
} => {
  const unified = streams.map((s) => (Array.isArray(s) ? s : [s, null]));

  return {
    fromTo: [from, to],
    nonAccountable: unified.map(([, value], idx) => ({
      value,
      date: moment(from).add(idx, 'days'),
    })),
    accountable: unified.map(([value], idx) => ({
      value,
      date: moment(from).add(idx, 'days'),
    })),
  };
};

export type TrendLine<T> = T & {
  streams: {
    fromTo: [Moment, Moment];
    accountable: Array<TrendEntry>;
    nonAccountable: Array<TrendEntry> | null;
  };
};

type Output<T> = {
  request: {
    loading: boolean;
    loaded: boolean;
    failed: boolean;
  };

  trendLine?: TrendLine<T> | undefined;
  trendLines?: Array<TrendLine<T>> | undefined;
  refresh: () => void;
};

type Props = {
  query?: Record<string, string | number | null | undefined>;
  passive?: boolean;
  entity:
    | 'trendsShops'
    | 'trendsDevices'
    | 'trendsBasic'
    | 'trendsSummary'
    | 'trendsGenders'
    | 'trendsTopTracks'
    | 'trendsSources'
    | 'trendsCountries';
};

export const useTrends = <T>({
  query: passedQuery,
  passive,
  entity,
}: Props): Output<T> => {
  const query = useMemo<Query>(
    () => (passedQuery ? filterQuery(passedQuery) : {}),
    [passedQuery]
  );
  const { queryHash } = useQuery({ query });

  const { request, refresh } = useFetchEntity({
    passive,
    query,
    queryHash,
    entity,
  });

  const trends = useSelector((state: ReduxState) => {
    const entityData = state.entities[entity];
    if (queryHash) {
      // TODO is it possible?
      // @ts-ignore
      return entityData.searchHistory[queryHash]?.data;
    }
    return entityData.data;
  });

  const multi = Array.isArray(trends);

  const data = useMemo(() => {
    if (!trends) return undefined;
    if (multi) {
      return trends
        .filter((t) => !!t.streams)
        .map(({ streams: { fromTo, values }, ...t }) => {
          const from = moment(fromTo[0]);
          const to = moment(fromTo[1]);

          return { ...t, streams: mapTrendLine(values, [from, to]) };
        });
    }

    if (!trends.streams) return undefined;
    return {
      ...trends,
      streams: mapTrendLine(trends.streams.values, [
        moment(trends.streams.fromTo[0]),
        moment(trends.streams.fromTo[1]),
      ]),
    };
  }, [trends]);

  if (multi) {
    return { request, refresh, trendLines: data };
  }

  return { request, refresh, trendLine: data };
};

export const useTrendsSummary = () => {
  // TODO figure out underlying ReduxState issue
  // @ts-ignore
  const { trends: summary, ...data } = useTrends({
    entity: 'trendsSummary',
  });
  return {
    summary,
    ...data,
  };
};
