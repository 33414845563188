import React from 'react';
import styled from '@emotion/styled';
import {
  AppIcon,
  NavDrawerContext,
  IconButton,
  Icons,
  Badge,
  Bar,
} from 'imdshared';
import { NotificationsCountProvider, OrdersCountProvider } from 'components';

const TopBarContainer = styled(Bar)`
  position: relative;
  padding-left: 8px;
  user-select: none;
`;

const AppIconStyled = styled(AppIcon)`
  margin: 0 8px;
  pointer-events: none;
`;

const NotificationsBadge = styled(Badge)<{
  notificationsCount: number;
  ordersCount: number;
}>`
  margin-left: 4px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${({ theme, notificationsCount }) =>
    notificationsCount > 0 ? theme.state.error : theme.accent.green};

  ::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-out;

    box-shadow: inset 0 0 0
      ${({ notificationsCount, ordersCount }) =>
        notificationsCount > 0 && ordersCount > 0 ? 2 : 0}px
      ${({ theme }) => theme.accent.green};
  }
`;

const MobileBar: React.FC = () => (
  <NotificationsCountProvider>
    {(notificationsCount: number) => (
      <OrdersCountProvider>
        {(ordersCount: number) => (
          <NavDrawerContext.Consumer>
            {({ open }) => (
              <TopBarContainer>
                <IconButton
                  icon={Icons.categories.menu}
                  onClick={(e) => {
                    e.stopPropagation();
                    open();
                  }}
                />

                <AppIconStyled />

                <NotificationsBadge
                  notificationsCount={notificationsCount}
                  ordersCount={ordersCount}
                  // Prioritize the notification count if both counts are available
                  count={
                    notificationsCount > 0 ? notificationsCount : ordersCount
                  }
                />
              </TopBarContainer>
            )}
          </NavDrawerContext.Consumer>
        )}
      </OrdersCountProvider>
    )}
  </NotificationsCountProvider>
);

export default MobileBar;
