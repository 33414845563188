import { css } from '@emotion/react';
import React, { PureComponent } from 'react';
import ErrorLine from './components/ErrorLine';
import Stroke from './components/Stroke';

// TODO: KILL THIS SON OF A BITCH

type Props = {
  children?: React.ReactNode;
  error?: boolean;
  card?: boolean;
  borderRadius?: number;
  alignItems?: any;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  direction?: 'row' | 'column';
  flex?: number;
  flexFlow?: string;
  padding?: any[];
  margin?: any[];
  backgroundColor?: string;
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  overflowHidden?: boolean;
  testId?: string;
};

const calcBorderRadius = (card: boolean, borderRadius: number | string) => {
  if (borderRadius === 0) {
    return null;
  }

  if (borderRadius) {
    return borderRadius;
  }

  if (card) {
    return 8;
  }

  return null;
};

const calcDecor = (
  card: boolean,
  borderTop: boolean,
  borderRight: boolean,
  borderBottom: boolean,
  borderLeft: boolean
) => {
  const allShadows = [
    borderTop && 'inset 0 1px rgba(0, 0, 0, 0.1)',
    borderRight && 'inset -1px 0 rgba(0, 0, 0, 0.1)',
    borderBottom && 'inset 0 -1px rgba(0, 0, 0, 0.1)',
    borderLeft && 'inset 1px 0 rgba(0, 0, 0, 0.1)',
    card && '0 12px 24px -5px rgba(0, 0, 0, 0.06)',
  ];
  const shadows = allShadows.filter((s) => !!s);
  const str = shadows.reduce((acc, cur, i) => {
    if (i === 0) {
      return cur;
    }
    return `${acc}, ${cur}`;
  }, '');
  return str;
};

/**
 * Allows to provide either pixel or string (e.g. 'auto') values depending on the type of the
 * value provided.
 */
const pxNoPx = (value: number | string) => {
  if (typeof value === 'number') {
    return `${value}px`;
  }
  return value;
};

/**
 * Basic component for rendering any meaningful content inside of it.
 */
class Block extends PureComponent<Props> {
  /**
   * The component that can extend the Block styling by rendering outline around it.
   */
  static Stroke = Stroke;

  /* Another optional Block style extender.
   * Shows the red line along the left side of the Block indicating that an error exists
   * in the context of the Block's children group.
   */
  static ErrorLine = ErrorLine;

  render() {
    const {
      children,
      card = false,
      borderRadius,
      width = null,
      height = null,
      minWidth = null,
      maxWidth = null,
      alignItems = null,
      flex = null,
      flexFlow,
      direction = 'column',
      padding = null,
      margin = null,
      backgroundColor = null,
      borderTop = false,
      borderRight = false,
      borderBottom = false,
      borderLeft = false,
      overflowHidden = false,
      testId,
      // @ts-ignore
      className,
    } = this.props;

    return (
      <div
        data-test-id={testId}
        className={className}
        // @ts-ignore
        css={css({
          display: 'flex',
          flexShrink: 0,
          position: 'relative',
          transition: 'background-color 0.1s',
          flexDirection: direction,
          flex,
          flexFlow,
          alignItems,
          /**
           * To use with <Block.Stroke .../> and <Block.ErrorLine .../> children
           */
          width,
          minWidth,
          maxWidth,
          height,
          /**
           * Block may also be a circle
           */
          // @ts-ignore
          borderRadius: calcBorderRadius(card, borderRadius),
          padding:
            padding &&
            `
            ${pxNoPx(padding[0])}
            ${pxNoPx(padding[1])}
            ${pxNoPx(padding[2])}
            ${pxNoPx(padding[3])}
          `,
          margin:
            margin &&
            `
            ${pxNoPx(margin[0])}
            ${pxNoPx(margin[1])}
            ${pxNoPx(margin[2])}
            ${pxNoPx(margin[3])}
          `,
          backgroundColor: card ? backgroundColor || 'white' : backgroundColor,
          boxShadow: calcDecor(
            card,
            borderTop,
            borderRight,
            borderBottom,
            borderLeft
          ),
          overflow: (card || overflowHidden) && 'hidden',
        })}
      >
        {children}
      </div>
    );
  }
}

export default Block;
