//
import { action } from '../base';

export const types = {
  UPLOAD_TRACKS: '@tracks/UPLOAD_TRACKS',
  CREATE_EMPTY_TRACK: '@tracks/CREATE_EMPTY_TRACK',
  ADD_TO_UPLOAD_QUEUE: '@tracks/ADD_TO_UPLOAD_QUEUE',
};

export const createEmptyTrack = (payload: unknown, meta: any) =>
  action(types.CREATE_EMPTY_TRACK, payload, meta);

export const uploadTracks = (
  { languageId, ...payload }: Record<string, unknown>,
  meta: any
) =>
  action(
    types.UPLOAD_TRACKS,
    {
      ...payload,
      languageId,
      audioLanguageId: languageId,
    },
    meta
  );

export const actions = {
  createEmptyTrack,
};
