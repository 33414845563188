import React, { useRef, useMemo, useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import { formValueSelector, getFormValues, reduxForm } from 'redux-form';
import { Centered, LoadingIndicator } from 'imdui';
import { SideWindow } from 'imdshared';
import type { TemplateData } from '@imus/release-page-template';
import { Layout, ShopsList, ArtistList } from '@imus/release-page-template';
import { SideEditorHeader, WysiwygTemplateContainer } from 'components/atoms';
import type { EntityModels } from 'imddata';
import { useCustomerFeatureRollout } from 'imddata';
import config from 'imdconfig';
import { TemplateSideEditor } from './components';
import { css } from '@emotion/react';
import type { AdjustedFormData } from './hooks';
import {
  useReleasePageData,
  useReleasePageFormData,
  useSaveMetadata,
} from './hooks';

export type { ReleasePage } from './hooks';
export {
  useReleasePage,
  useReleasePageFormData,
  useReleasePageData,
} from './hooks';

type ReleasePageProps = {
  linksOnly?: boolean;
  id: string;
  onClose: (isActive: boolean, releaseId: number | undefined) => void;
};

export type ReleasePagePageId = 'release' | 'artist' | 'tour' | 'press'; // TODO reexport from release-page-template

export const convertReleasePageTabToPageId = (
  tab: ReleasePagePageId,
  premium: boolean
): ReleasePagePageId => (premium === false ? 'release' : tab);

const mapApiFile = (apiFile: Partial<EntityModels.File>) => ({
  url: apiFile.downloadUrl || '',
  name: apiFile.fileName,
  size: apiFile.size,
  extension: apiFile.extension || '',
});

const NON_PREMIUM_SHOPS = [
  'spotify',
  'deezer',
  'applemusic',
  'itunes',
  'youtube',
];

export const ReleasePageTemplateRenderer: React.FC<{
  data: AdjustedFormData;
  type: PageType;
  isBranded?: boolean;
  hideNotFoundUrl?: boolean;
  linksOnly?: boolean;
}> = ({ data, hideNotFoundUrl, type, isBranded }) => {
  const premium = type === 'premium';
  const { i18n } = useTranslation();
  const templateI18nRef = useRef(i18n.cloneInstance());
  useEffect(() => {
    if (data?.general.locale) {
      templateI18nRef.current.changeLanguage(data?.general.locale);
      templateI18nRef.current.loadNamespaces('artist-hub');
      templateI18nRef.current.setDefaultNamespace('artist-hub');
    }
  }, [data?.general.locale]);
  const dataWithTranslations = useMemo<TemplateData | null>(() => {
    if (data) {
      if (!premium) {
        return {
          free: true,
          general: {
            locale: 'en',
          },
          context: {},
          meta: {
            url: '',
            title: '',
            description: '',
          },
          artistPages: data.artistPages,
          release: {
            ...data.release,
            barcode: '',
            services: NON_PREMIUM_SHOPS.map((name) => ({
              name,
              isVisible: true,
            })),
            cover: mapApiFile(data.release.cover),
          },
          config: {
            assetsUrl: config.releaseTemplateBaseUrl,
          },
          onNavigation: (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            event.stopPropagation();
          },
        };
      }
      if (premium) {
        return {
          free: false,
          ...data,
          branded: isBranded,
          context: {},
          meta: {
            url: '',
            title: '',
            description: '',
          },
          release: {
            ...data.release,
            services: data.release.services.map((s) => {
              const presave =
                data.release.presave &&
                s.preSaveAvailable &&
                new Date(data.release.releaseAt || '').valueOf() >= Date.now();
              const url = s.customUrl || s.url;
              const isVisible =
                s.isVisible && (!!url || !hideNotFoundUrl || presave);
              return {
                ...s,
                isVisible,
                url,
                presave,
              };
            }),
            barcode: '',
            cover: mapApiFile(data.release.cover),
          },
          general: {
            ...data.general,
            // TODO: remove when API  accepts multiple backgrounds
          },
          config: {
            assetsUrl: config.releaseTemplateBaseUrl,
          },
        };
      }
    }
    return null;
  }, [data, type, isBranded, hideNotFoundUrl]);
  if (dataWithTranslations === null)
    return (
      <Centered>
        <LoadingIndicator size="large" />
      </Centered>
    );

  return (
    <I18nextProvider i18n={templateI18nRef.current}>
      <Layout data={dataWithTranslations}>
        <ArtistList data={dataWithTranslations}></ArtistList>
        <ShopsList data={dataWithTranslations}></ShopsList>
      </Layout>
    </I18nextProvider>
  );
};

const ConnectedTemplateRenderer = ({
  form,
  ...props
}: {
  form: string;
  type: PageType;
  hideNotFoundUrl?: boolean;
  isBranded?: boolean;
}) => {
  const selector = useMemo<(state: any) => AdjustedFormData>(
    (): any => getFormValues(form),
    [form]
  );
  const data = useSelector<any, AdjustedFormData>(selector);
  return <ReleasePageTemplateRenderer {...props} data={data} />;
};

type ReleasePageEditorProps = {
  artistIds: Array<number>;
  id: string;
};

function ReleasePageEditorInner({
  id,
  form,
  artistIds,
}: InjectedFormProps<AdjustedFormData, ReleasePageEditorProps> &
  ReleasePageEditorProps) {
  return (
    <>
      <TemplateSideEditor id={id} form={form} artistIds={artistIds} />
    </>
  );
}

const ReleasePageEditorForm = reduxForm<
  AdjustedFormData,
  ReleasePageEditorProps
>({
  submitAsSideEffect: true,
  // enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
  persistentSubmitErrors: true,
})(ReleasePageEditorInner);

const emptyArtist: never[] = [];
type PageType = 'free' | 'premium';

export const ReleasePageEditor = ({
  id,
  onClose,
  linksOnly = false,
}: ReleasePageProps) => {
  const {
    loaded,
    release,
    updateShops,
    artists,
    releasePageShops,
    releasePage,
  } = useReleasePageData(id);

  const form = `release-page-template.release${id}`;
  const selector = useMemo(() => formValueSelector(form), [form]);

  useSaveMetadata({
    id,
    legacyPremium: releasePage?.isPremium,
    updateShops,
    form,
    formSelector: selector,
  });

  const initialValues = useReleasePageFormData({
    artists,
    release,
    releasePage,
    releasePageShops,
    loaded,
  });

  const artistHubPro = useCustomerFeatureRollout({
    feature: 'artist-hub-pro',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });
  const artistHubProUnbranded = useCustomerFeatureRollout({
    feature: 'artist-hub-pro-unbranded',
    rolloutKey: 'tiered-subs',
    fallback: false,
  });

  const artistIds = useMemo(
    () => release?.artists.map(({ artistId }) => artistId) || emptyArtist,
    [release?.artists]
  );

  const { t } = useTranslation();

  const isActive = releasePage?.isActive;
  const releaseId = releasePage?.releaseId;

  const scanStarted = releasePage?.shopsStatus !== 'none';
  const isScanning =
    releasePage?.shopsStatus === 'scanning' ||
    releasePage?.shopsStatus === 'requested';
  const hideUrl = !isScanning && scanStarted;
  const label = t('customize-release-page');

  return (
    <>
      <SideWindow
        testId="TemplateSideEditor"
        isOpen={true}
        css={css`
          display: flex;
          flex-direction: column;
          overflow: hidden;
        `}
      >
        <SideEditorHeader
          label={label}
          onClickClose={
            releasePage
              ? () => {
                  onClose(!!isActive, releaseId);
                }
              : undefined
          }
        ></SideEditorHeader>
        {releasePage && initialValues ? (
          <ReleasePageEditorForm
            id={id}
            form={form}
            initialValues={initialValues}
            artistIds={artistIds}
          />
        ) : (
          <Centered>
            <LoadingIndicator />
          </Centered>
        )}
      </SideWindow>
      <WysiwygTemplateContainer
        status={releasePage?.isOutdated ? 'generating' : null}
        style={linksOnly ? { background: 'rgba(0,0,0,0.2)' } : undefined}
        width={linksOnly ? '768px' : undefined}
        height={linksOnly ? '100%' : undefined}
        containerName="template-sizer"
      >
        <ConnectedTemplateRenderer
          form={form}
          hideNotFoundUrl={hideUrl}
          type={
            artistHubProUnbranded || artistHubPro || releasePage?.isPremium
              ? 'premium'
              : 'free'
          }
          isBranded={!artistHubProUnbranded && !releasePage?.isPremium}
        />
      </WysiwygTemplateContainer>
    </>
  );
};
