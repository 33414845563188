import { useEffect } from 'react';
import { useInvoice } from 'imddata';
import { integrations, mapEcommerceProperties } from 'helpers/tracking';

export const useInvoicePaymentInitiated = (invoiceId: number) => {
  const { entry: invoice } = useInvoice({ id: invoiceId });
  const hasInvoice = !!invoice;

  useEffect(() => {
    if (hasInvoice && window && window.analytics) {
      window.analytics.track(
        'FT Invoice Payment Initiated',
        { ...mapEcommerceProperties(invoice, 'invoiceId') },
        {
          integrations,
        }
      );
    }
  }, [hasInvoice]);
};
