import styled from '@emotion/styled';
import React from 'react';

type StrokeProps = {
  thickness?: number;
  color?: string;
};

const StrokeStyled = styled.div<StrokeProps>`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow: inset 0 0 0 ${({ thickness }) => thickness}px
    ${({ theme, color }) => color || theme.foreground.primary};
  transition: all 0.2s;
`;

export default function Stroke({ thickness = 2, color }: StrokeProps) {
  return <StrokeStyled thickness={thickness} color={color} />;
}
