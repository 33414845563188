import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { EntityModels, ReduxState } from 'imddata';
import { useCreateEntity, useCurrentUser, useEntry } from 'imddata';
import type { InjectedFormProps } from 'redux-form';
import { formValueSelector, reduxForm, Field } from 'redux-form';

import { Button, FormToolbar, HelpWindowContext } from 'imdui';
import NewInputField from '../../../fields/InputField';
import ArtistIdField from '../../ArtistIdField';

type Props = {
  onSaved: (v: { result: string | number }) => void;
  onClose: () => void;
  impersonate?: Record<string, string>;
};

type FormData = {
  name: string;
  shopArtistIds: any;
};

// const DUPLICATE_ARTIST_CODE = 1309;

const Form = ({
  form,
  onClose,
  handleSubmit,
  submitting,
  onSaved,
  impersonate,
}: InjectedFormProps<FormData, Props> & Props) => {
  const selector = useMemo(() => formValueSelector('artistEditForm'), []);
  const name = useSelector((state: ReduxState) => selector(state, 'name'));

  const { t } = useTranslation();
  const { entry: user } = useCurrentUser();
  const [requestDuplicateConfirmation, setRequestDuplicateConfirmation] =
    React.useState('');
  const { request, createEntry: createArtist } = useCreateEntity({
    entity: 'artists',
  });

  const { entry: artist } = useEntry<EntityModels.Artist>({
    entity: 'artists',
    passive: !request.id,
    id: request.id,
  });

  useEffect(() => {
    if (artist) {
      onSaved({ result: artist.id });
    }
  }, [!!artist]);

  useEffect(() => {
    if (requestDuplicateConfirmation !== name) {
      setRequestDuplicateConfirmation('');
    }
  }, [name]);

  useEffect(() => {
    if (request.error) {
      setRequestDuplicateConfirmation(name);
    }
  }, [request.error]);

  const submitForm = useCallback(
    (values) => {
      createArtist(
        {
          formId: form,
          data: {
            defaultLanguageId: user?.defaultLanguageId,
            checkForSimilarNames: true,
            forceUseName: !!requestDuplicateConfirmation,
            shops: ArtistIdField.parseValue(values.shopArtistIds),
            names: [{ name: values.name, languageId: user?.defaultLanguageId }],
          },
        },
        {
          impersonate,
        }
      );
    },
    [form, impersonate, requestDuplicateConfirmation]
  );
  const showHelp = useContext(HelpWindowContext);

  const existingArtist = request?.error?.items?.[0];

  return (
    <form data-test-id="new-artist-form" onSubmit={handleSubmit(submitForm)}>
      <div style={{ padding: '0 32px 0px' }}>
        <Field
          autoFocus={true}
          name="name"
          label={t('artist-name')}
          component={NewInputField}
          errorText={
            requestDuplicateConfirmation
              ? t('artist-with-similar-name-exist')
              : ''
          }
          onClickHelp={() => {
            showHelp(t('artist-name'), t('artist-helptext-name'));
          }}
        />
        <ArtistIdField name="shopArtistIds" queryString={name} />
      </div>

      <FormToolbar
        rightChildren={
          <>
            {requestDuplicateConfirmation ? (
              <Button
                primary={true}
                onClick={
                  existingArtist
                    ? () => {
                      onSaved({ result: existingArtist?.artistId });
                    }
                    : onClose
                }
                text={`${t('use-existing-artist')}: ${existingArtist.name}`}
              />
            ) : (
              <Button
                primary={requestDuplicateConfirmation ? 'error' : true}
                data-test-id="add-new-artist"
                type="submit"
                showLoading={submitting || request.creating}
                text={
                  requestDuplicateConfirmation
                    ? t('confirm-duplicate')
                    : t('add-artist')
                }
              />
            )}
          </>
        }
      />
    </form>
  );
};

const validate = (values: FormData) => {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'required';
  }
  return errors;
};

const FormConnected = reduxForm<FormData, Props>({
  form: 'artistEditForm',
  enableReinitialize: true,
  validate,
})(Form);

const FormWrapper = (props: Props & { initialValues?: Partial<FormData> }) => {
  const initialValues = useMemo(() => {
    return {
      ...(props.initialValues ? props.initialValues : { name: '' }),
      shopArtistIds: { spotify: { id: null }, appleMusic: { id: null } },
    };
  }, [props.initialValues]);

  return <FormConnected {...props} initialValues={initialValues} />;
};

export default FormWrapper;
