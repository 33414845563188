//
import React, { useEffect, useCallback, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFeature } from 'imdfeature';
import {
  ContributorSideEditor,
  ArtistSideEditor,
  PublisherSideEditor,
  EntityList,
  ReleaseEditor,
  PageTemplate,
  SelectedTracksList,
} from 'components';
import {
  useTracks,
  FieldUpdateRequestContext,
  useFieldUpdateRequestDeliveryBundleChanges,
} from 'imddata';
import { Card, AlertBox, Checkbox, CardSection, Body, Content } from 'imdui';
import {
  Button,
  ExtendedFieldsColumn,
  ConfirmationWindow,
  NewInputField,
  LoadingIndicator,
  validation,
} from 'imdshared';
import { FieldUpdateRequestInformation } from './components';

const StyledReleaseEditor = styled(ReleaseEditor)`
  box-shadow: none;
  border-radius: 0;
  margin: none;
  padding: 0;
`;

type FormProps = {
  wasSubmitted?: boolean;
  issueCountMet?: boolean;
  targetMet?: boolean;
  updating?: boolean;
  title: string;
  updateRequest: ReturnType<
    typeof useFieldUpdateRequestDeliveryBundleChanges
  >['updateRequest'];
};
type FormData = { customerMessage: string };

const displayedFields = {
  copyrightText: true,
  performers: true,
  audioLanguageId: true,
  publishers: true,
};

const FeedbackForm = reduxForm<FormData, FormProps, string>({
  form: 'fur-feedback-form',
})(({
  updating,
  targetMet,
  title,
  issueCountMet,
  wasSubmitted,
  handleSubmit,
  form,
  updateRequest,
}) => {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isReportOpen, setReportOpen] = useState(false);
  useEffect(() => {
    if (wasSubmitted && isOpenDialog) {
      setIsOpenDialog(false);
    }
  }, [wasSubmitted]);
  const onSubmit = useCallback(
    ({ customerMessage }) => {
      updateRequest({
        data: {
          customerMessage: isReportOpen ? customerMessage : undefined,
          status: 'submitted',
        },
        form,
      });
    },
    [isReportOpen]
  );

  const disabled = !issueCountMet || !targetMet;
  const errorMessage = !targetMet
    ? t('delivery-contains-invalid-fields')
    : !issueCountMet
      ? t('mark-every-issue-to-submit')
      : null;
  return (
    <>
      <ConfirmationWindow
        confirmTestId="FieldUpdateRequest-confirm"
        title={title}
        confirmLabel={t('confirm-and-accept')}
        onRequestClose={() => {
          setIsOpenDialog(false);
        }}
        isOpen={isOpenDialog}
        onConfirm={handleSubmit(onSubmit)}
        message={t('field-update-request-terms-and-conditions')}
      >
        <div style={{ margin: '16px 24px' }}>
          <Checkbox
            name="enable-customer-message"
            testId="FieldUpdateRequest-enable-customer-message"
            text={t('enable-reply-to-field-update-request')}
            checked={isReportOpen}
            onCheck={(e, checked) => setReportOpen(checked)}
          />
          {isReportOpen && (
            <Field
              testId="FieldUpdateRequest-customerMessage"
              name="customerMessage"
              floatingLabelText={t('customer-notes-for-admin')}
              multiline={true}
              acceptValidation={false}
              component={NewInputField}
            />
          )}
        </div>
      </ConfirmationWindow>
      {wasSubmitted && (
        <Card style={{ marginTop: '16px', padding: '24px' }}>
          <Field
            disabled={true}
            testId="FieldUpdateRequest-customerMessage"
            name="customerMessage"
            floatingLabelText={t('customer-notes-for-admin')}
            multiline={true}
            acceptValidation={false}
            component={NewInputField}
          />
        </Card>
      )}
      {!wasSubmitted && (
        <div style={{ display: 'flex', marginTop: '24px', gap: '8px' }}>
          {errorMessage && (
            <AlertBox
              style={{ maxHeight: 40, padding: '0 16px' }}
              type={AlertBox.Type.error}
              text={errorMessage}
            />
          )}
          <Button
            disabled={updating || disabled}
            showLoading={updating}
            primary={true}
            testId="FieldUpdateRequest-submit"
            text={t('submit')}
            style={{
              marginLeft: 'auto',
            }}
            onClick={() => {
              setIsOpenDialog(true);
            }}
          />
        </div>
      )}
    </>
  );
});

const FieldUpdateRequest = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [enableIssueCheck] = useFeature({
    featureKey: 'field-update-request-check-system',
  });
  const data = useFieldUpdateRequestDeliveryBundleChanges({
    id,
    enableIssueCheck,
  });
  const {
    updateRequest,
    entry,
    request: { loading, loaded },
    counter,
    checkedCounter,
    entityIds,
    isTrackValid,
    isArtistValid,
    bundle,
    wasResolvedByAdmins,
    updateRequestState: { updating },
  } = data;

  const { entries: tracks } = useTracks({ ids: entityIds.tracks });

  if (loading || !entry || !loaded) {
    return (
      <PageTemplate>
        <LoadingIndicator size="large" />
      </PageTemplate>
    );
  }

  if (wasResolvedByAdmins) {
    return (
      <PageTemplate title={entry.title}>
        <CardSection
          title={<Content>{t('update-requests')}</Content>}
          collapsible={false}
        >
          <div style={{ padding: '24px' }}>
            <Body>{t('was-resolved-by-admin')}</Body>
          </div>
        </CardSection>
      </PageTemplate>
    );
  }

  const formatChipText = (entity: keyof typeof checkedCounter) => {
    return `${
      enableIssueCheck ? `${checkedCounter[entity]} ${t('checked')} / ` : ''
    }${counter[entity]} ${t('request', {
      count: counter[entity],
    })}`;
  };

  return (
    <FieldUpdateRequestContext.Provider value={data}>
      <PageTemplate title={entry.title}>
        <FieldUpdateRequestInformation entry={entry} bundle={bundle} />
        {entityIds.releases.map((releaseId) => {
          return (
            <CardSection
              key={releaseId}
              testId={`FieldUpdateRequest-release-card-${releaseId}`}
              chipText={formatChipText('releases')}
              title={<Content>{t('release')}</Content>}
              chipState="error"
              collapsible={false}
            >
              <StyledReleaseEditor
                // TODO: fix additionalValidation prop  def and may be nuke it somehow
                requiredFields={
                  validation.musicDistribution.releaseForm.requiredFields
                }
                form={`fur_release:${releaseId}`}
                releaseId={releaseId}
                showCoverRequirementText={true}
                fieldsComponent={
                  <ExtendedFieldsColumn
                    displayGenre={true}
                    displayPromotionalText={true}
                  />
                }
              />
            </CardSection>
          );
        })}
        {!!tracks?.length && (
          <CardSection
            chipText={formatChipText('tracks')}
            title={<Content>{t('tracks')}</Content>}
            chipState="error"
            collapsible={false}
          >
            <SelectedTracksList
              displayedFields={displayedFields}
              hideTrackNumber={true}
              hideEditors={true}
              draggable={false}
              virtualized={false}
              hideToolbar={true}
              isValid={isTrackValid}
              trackIds={entityIds.tracks}
              hideReleaseName={true}
              canUpload={false}
            />
          </CardSection>
        )}
        {!!entityIds.artists?.length && (
          <CardSection
            chipText={formatChipText('artists')}
            title={<Content>{t('artists')}</Content>}
            chipState="error"
            collapsible={false}
          >
            <EntityList
              virtualized={false}
              isValid={isArtistValid}
              ids={entityIds.artists}
              entity="artists"
            />
          </CardSection>
        )}
        {!!entityIds.contributors?.length && (
          <CardSection
            chipText={formatChipText('contributors')}
            title={<Content>{t('contributors')}</Content>}
            chipState="error"
            collapsible={false}
          >
            <EntityList
              virtualized={false}
              isValid={() => false}
              ids={entityIds.contributors}
              entity="contributors"
            />
          </CardSection>
        )}
        {!!entityIds.publishers?.length && (
          <CardSection
            chipText={formatChipText('publishers')}
            title={<Content>{t('publishers')}</Content>}
            chipState="error"
            collapsible={false}
          >
            <EntityList
              virtualized={false}
              isValid={() => false}
              ids={entityIds.publishers}
              entity="publishers"
            />
          </CardSection>
        )}
        <FeedbackForm
          title={entry?.title}
          updating={updating}
          updateRequest={updateRequest}
          targetMet={
            bundle?.releases[0]?.release?.targets?.addToDelivery?.isMet ||
            bundle?.targets?.order?.isMet
          }
          wasSubmitted={entry.status !== 'requested'}
          issueCountMet={
            !enableIssueCheck || counter.total - checkedCounter.total === 0
          }
          initialValues={{ customerMessage: entry.customerMessage }}
          form={`fieldUpdateRequest:${entry.id}`}
        />

        <ContributorSideEditor isAdaptive={false} />

        <ArtistSideEditor isAdaptive={false} />

        <PublisherSideEditor isAdaptive={false} />
        <div style={{ padding: 100 }} />
      </PageTemplate>
    </FieldUpdateRequestContext.Provider>
  );
};

export default function FieldUpdateRequestRoute() {
  const { id } = useParams<{ id: string }>();
  return <FieldUpdateRequest id={id} />;
}
