//
import * as R from 'ramda';

export const generateKey = (entityKey, id) => {
  if (typeof entityKey === 'string') {
    return `${entityKey}:${
      id !== undefined && id !== null ? id.toString() : ''
    }`;
  }
  return entityKey.toString() + (id ? id.toString() : '');
};

/**
 * mergeBuffer implements Buffer type for using in actionChannel of redux-saga
 * mergeBuffer is used for batching one type of reccuring action based on
 * entityKey that could be a string or an array AND id.
 * entityKey and id is used for computing key for storing and merging actions.
 *
 * @returns {Buffer}
 */

const merger = R.mergeDeepRight;

export default function mergeBuffer() {
  let actionKeys = [];
  let mergedActions = {};

  const put = (action) => {
    const { entityKey, id, formId, data } = action.payload;
    const multiedit = action.meta && action.meta.multiedit;
    const fallbackId = formId || '_no_id_';
    const key = generateKey(entityKey, id || fallbackId);

    if (!mergedActions[key]) {
      actionKeys.push(key);
      mergedActions = {
        ...mergedActions,
        [key]: action,
      };
    } else {
      mergedActions = {
        ...mergedActions,
        [key]: {
          ...mergedActions[key],
          payload: {
            ...mergedActions[key].payload,
            data: multiedit
              ? {
                  id: data.id,
                  payload: mergedActions[key].payload.data
                    ? merger(
                        mergedActions[key].payload.data.payload,
                        data.payload
                      )
                    : data.payload,
                }
              : merger(mergedActions[key].payload.data, data),
          },
        },
      };
    }
  };

  // eslint-disable-next-line
  const take = ()                      => {
    if (actionKeys.length !== 0) {
      const actionKey = actionKeys.shift();
      const action = mergedActions[actionKey];
      delete mergedActions[actionKey];
      return action;
    }
  };

  const flush = () => {
    const allActions = actionKeys.reduce(
      (accActions, actionKey) => [...accActions, mergedActions[actionKey]],
      []
    );
    actionKeys = [];
    mergedActions = {};
    return allActions;
  };

  const isEmpty = () => actionKeys.length === 0;

  return {
    isEmpty,
    take,
    flush,
    put,
    size: actionKeys.length,
  };
}
