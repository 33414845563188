import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import type { Moment } from 'moment';
import moment from 'moment';
import 'react-dates/initialize';
import type {
  RenderMonthProps,
  DayPickerRangeControllerShape,
  DayPickerSingleDateControllerShape,
  ModifiersShape,
} from 'react-dates';
import {
  DayPickerRangeController,
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from 'react-dates';
import { noop } from 'imddata';
import { IconButton, IconsCollection } from 'imdui';
import YearSelectorField from '../../fields/YearSelectorField';
import DropdownMenu from '../../fields/DropdownMenu';
import DayRenderer from './DayRenderer';

const NavPrev = <IconButton newIcon={IconsCollection.chevronLeft} />;
const NavNext = <IconButton newIcon={IconsCollection.chevronRight} />;

const CURRENT_YEAR = new Date().getFullYear();
const YEARS: Array<{ value: null | number; text: string }> = [
  { value: null, text: '' },
];

for (let i = 0; i < 100; i += 1) {
  YEARS.push({ value: CURRENT_YEAR - i, text: `${CURRENT_YEAR - i}` });
}

const MonthWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0 24px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

type Option = {
  value: number;
  label: string;
};

const renderMonthElement: RenderMonthProps['renderMonthElement'] = ({
  month,
  onMonthSelect,
  onYearSelect,
}) => (
  <MonthWrapper data-test-id="YearMonthSelector">
    <DropdownMenu<Option, false>
      testId="MonthSelector"
      // @ts-ignore
      value={month.month()}
      data={moment.months().map((label, value) => ({ label, value }))}
      acceptValidation={false}
      style={{ width: 128 }}
      // TODO: fix once dropdown API def is fixed and bypassed as react-select def
      // @ts-ignore
      onChange={(value: string) => {
        onMonthSelect(month, value);
      }}
    />

    <YearSelectorField
      testId="YearSelector"
      // @ts-ignore
      value={month.year()}
      acceptValidation={false}
      style={{ width: 80 }}
      onChange={(value: string) => {
        onYearSelect(month, value);
      }}
    />
  </MonthWrapper>
);

function DateSingleController(
  props: Omit<DayPickerSingleDateControllerShape, 'renderMonthText'>
) {
  return (
    <DayPickerSingleDateController
      renderMonthElement={renderMonthElement}
      hideKeyboardShortcutsPanel={true}
      firstDayOfWeek={1}
      daySize={38}
      horizontalMonthPadding={16}
      navPrev={NavPrev}
      navNext={NavNext}
      {...props}
    />
  );
}

export function DateRangeController(
  props: DayPickerRangeControllerShape & {
    onHoveredDate?: (d: Moment | null) => void;
  }
) {
  const [hoveredDate, setHoveredDate] = useState<Moment | null>(null);
  const renderDayContents = useCallback(function HoverableDate(
    day: Moment,
    modifiers: ModifiersShape
  ) {
    return (
      <DayRenderer day={day} modifiers={modifiers} onHovered={setHoveredDate} />
    );
  }, []);
  useEffect(() => {
    if (props.onHoveredDate) {
      props.onHoveredDate(hoveredDate);
    }
  }, [hoveredDate]);

  return (
    <DayPickerRangeController
      renderDayContents={renderDayContents}
      hideKeyboardShortcutsPanel={true}
      firstDayOfWeek={1}
      daySize={38}
      horizontalMonthPadding={16}
      navPrev={NavPrev}
      navNext={NavNext}
      numberOfMonths={2}
      onOutsideClick={noop}
      onPrevMonthClick={noop}
      onNextMonthClick={noop}
      {...props}
    />
  );
}
const DefaultDayRenderer: DayPickerRangeControllerShape['renderDayContents'] = (
  day,
  modifiers
) => <DayRenderer day={day} modifiers={modifiers} />;

DateSingleController.defaultProps = {
  // example props for the demo
  date: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: DefaultDayRenderer,
  isDayBlocked: () => false,
  isOutsideRange: (day: Moment) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick: noop,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  onPrevMonthClick: noop,
  onNextMonthClick: noop,

  // internationalization
  monthFormat: 'MMMM YYYY',
};

export default DateSingleController;
