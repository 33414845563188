import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Feature } from 'imdfeature';
import { Redirect } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import type { EntityModels } from 'imddata';
import { useEntries, useCreateEntity, useDeleteEntity } from 'imddata';
import { InfiniteList, BlockTemplate } from 'components';
import type { InfiniteListRowRenderer } from 'imdshared';
import {
  Window,
  NewInputField,
  NavListItem,
  Icons,
  Button,
  IconButton,
} from 'imdshared';
import styled from '@emotion/styled';

const DeleteButton = styled(IconButton)`
  box-shadow: inset 0 0 0 1px #ececec;
  background-color: #fafafa;
  width: 40px;
  height: 40px;
`;

const renderChannelRow: InfiniteListRowRenderer<EntityModels.Channel> = ({
  style,
  key,
  item,
}) => {
  const { createdAt, thumbnailFile, id, displayName, status } = item;

  const {
    deleteEntry,
    request: { deleting },
  } = useDeleteEntity({ entity: 'channels', id });

  return (
    <NavListItem
      thumbnailUrl={thumbnailFile?.downloadUrl}
      key={key}
      title={displayName}
      subtitle={status}
      createdAt={moment(createdAt).format('L')}
      style={style}
    >
      <DeleteButton
        onClick={() => {
          deleteEntry();
        }}
        disabled={deleting}
        icon={Icons.actions.trash}
      />
    </NavListItem>
  );
};

const NewChannelForm = reduxForm<
  { channelId: string },
  { closeForm: () => void }
>({
  form: 'newChannel',
  validate: (values) => {
    const errors: { channelId?: string } = {};
    if (!values.channelId) {
      errors.channelId = 'required';
    } else if (values.channelId.substring(0, 2) !== 'UC') {
      errors.channelId = 'start-channel-with-UC';
    }
    return errors;
  },
})(({ submitting, closeForm, error, form, handleSubmit }) => {
  const { t } = useTranslation();
  const {
    createEntry,
    request: { created },
  } = useCreateEntity({ entity: 'channels' });
  const onSubmit = useCallback(
    (data) => createEntry({ data, formId: form }),
    [form, createEntry]
  );

  useEffect(() => {
    if (created) {
      closeForm();
    }
  }, [created]);

  return (
    <div style={{ margin: '24px' }}>
      <Field
        name="channelId"
        autoFocus={true}
        props={{
          errorText: error,
          floatingLabelText: t('channel-id'),
          helperText: t('channel-id-short-helper-text'),
        }}
        component={NewInputField}
      />

      <Button
        primary={true}
        type="submit"
        disabled={submitting}
        onClick={handleSubmit(onSubmit)}
        text={t('add-channel')}
      />
    </div>
  );
});

const Channels: React.FC = () => {
  const { t } = useTranslation();

  const {
    entries,
    loadMore,
    request: { hasMorePages, loading },
  } = useEntries<EntityModels.Channel>({ entity: 'channels' });

  const [isNewChannelWindowOpened, setIsNewChannelWindowOpened] =
    useState(false);

  const closeForm = useCallback(() => setIsNewChannelWindowOpened(false), []);

  return (
    <>
      <>
        <BlockTemplate
          padded={false}
          title={t('your-channels')}
          action={
            <Button
              text={t('add-channel')}
              icon={Icons.actions.add}
              onClick={() => setIsNewChannelWindowOpened(true)}
            />
          }
        >
          <InfiniteList
            data={entries}
            hasNextPage={hasMorePages}
            loadNextPage={loadMore}
            loading={loading}
            rowRenderer={renderChannelRow}
            emptyPlaceholder={t('no-channels')}
            getRowHeight={() => 80}
          />
        </BlockTemplate>
      </>

      <Window
        isOpen={isNewChannelWindowOpened}
        close={closeForm}
        title={t('add-new-channel-window')}
      >
        <NewChannelForm closeForm={closeForm} />
      </Window>
    </>
  );
};

const ChannelPage: React.FC = (props) => (
  <Feature feature="channel-monetization">
    {(isEnabled) =>
      isEnabled ? <Channels {...props} /> : <Redirect to="/not-found" />
    }
  </Feature>
);

export default ChannelPage;
