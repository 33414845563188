import { FormSection, reduxForm, FieldArray, Field } from 'redux-form';
import {
  EnabledRowField,
  Item,
  ItemCollection,
  ArtistCollectionField,
  WindowWrapper,
} from './components';
import { useTranslation } from 'react-i18next';
import type { FormSubmitHandler } from 'redux-form';
import { Fragment, useMemo, useState } from 'react';
import { Window, Button, Content, TextFormatted } from 'imdui';
import {
  BuyOrSubOffer,
  DatePickerInputField,
  InputField,
  MomentDateField,
} from 'imdshared';
import type { TourPageEvent } from '@imus/artist-page-template';
import moment from 'moment';
import { validateRequired, validateURL } from './utils';
import type { ArtistCollectionFieldProps } from './components';
import { createMaxLengthValidation } from 'helpers/validation';
import { css } from '@emotion/react';
import { ImageField } from './components/ImageField';
import { useCustomerFeature } from 'imddata';

const TourEventForm = reduxForm<
  TourPageEvent,
  { onSubmit: FormSubmitHandler<TourPageEvent>; submitText: string }
>({ form: 'AddTourEventForm' })(({ handleSubmit, submitText, onSubmit }) => {
  const { t } = useTranslation();

  const validateName = useMemo(
    () => createMaxLengthValidation(200, t, true),
    []
  );
  const validateLocation = useMemo(
    () => createMaxLengthValidation(1000, t, true),
    []
  );
  return (
    <>
      <MomentDateField
        floatingLabelText={t('tour-date')}
        placeholder={t('tour-date')}
        validate={validateRequired}
        name="date"
        component={DatePickerInputField}
        isOutsideRange={() => false}
      />
      <Field
        name="name"
        component={InputField}
        label={t('tour-date-name')}
        placeholder={t('tour-name')}
        validate={validateName}
      />
      <Field
        name="location"
        placeholder={t('tour-location')}
        label={t('tour-location')}
        validate={validateLocation}
        component={InputField}
      />
      <Field
        name="url"
        validate={validateURL}
        placeholder={t('tour-link')}
        label={t('tour-link')}
        component={InputField}
      />
      <Button
        icon={undefined}
        text={submitText}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
});

const formatValueToItem = (f: TourPageEvent) => ({
  label: moment(f.date).format('L'),
});

const sortDates = (a: TourPageEvent, b: TourPageEvent) =>
  new Date(b.date).valueOf() - new Date(a.date).valueOf();

const tourHightlightRowStyle = css`
  height: 56px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 0 16px;
`;

export function BaseTourForm({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <FormSection name="tour" component={Fragment}>
      <ItemCollection>
        <Item>
          <Field
            name="enabled"
            title={t('enable-page')}
            component={EnabledRowField}
          />
        </Item>
      </ItemCollection>

      {children}

      <FieldArray<ArtistCollectionFieldProps<TourPageEvent>, TourPageEvent>
        name="events"
        formatValueToItem={formatValueToItem}
        formComponent={TourEventForm}
        addText={t('add-tour-date')}
        sort={sortDates}
        component={ArtistCollectionField}
      />
    </FormSection>
  );
}

export function ArtistPageTourForm({ artistPageId }: { artistPageId: string }) {
  const { t } = useTranslation();
  const [tourHighlightFormOpened, setTourHighlightFormOpened] = useState(false);
  const feature = useCustomerFeature('artist-hub-pro');
  return (
    <>
      {!feature && (
        <BuyOrSubOffer
          style={{ marginBottom: 0 }}
          subscribeContext={{ analytics: { detail: 'artist-page' } }}
          title={t('these-settings-will-not-apply-to-published-version')}
          description={
            <TextFormatted
              text={t('subscribe-to-unlock-customization-options')}
            />
          }
        />
      )}
      <BaseTourForm>
        <ItemCollection>
          <Item>
            <Field
              name="splash.enabled"
              title={t('tour-highlight')}
              component={EnabledRowField}
            />
          </Item>
          <Item css={tourHightlightRowStyle}>
            <Field
              name="splash.title"
              component={({ input }: any) => (
                <Content>{input.value || t('tour-description')}</Content>
              )}
            />
            <Button
              size="small"
              text={t('change')}
              onClick={() => {
                setTourHighlightFormOpened(true);
              }}
            />
          </Item>
        </ItemCollection>
        <Window
          title={t('tour')}
          isOpen={tourHighlightFormOpened}
          close={() => {
            setTourHighlightFormOpened(false);
          }}
        >
          <WindowWrapper>
            <Field
              name="splash.image"
              component={ImageField}
              handler="artistPageTourSplashImage"
              actionText={t('set-tour-header')}
              handlerId={artistPageId}
            />
            <div style={{ padding: '8px' }} />
            <Field
              name="splash.title"
              label={t('tour-name')}
              component={InputField}
            />
            <Field
              name="splash.description"
              label={t('tour-description')}
              component={InputField}
            />
            <Field
              name="splash.url"
              label={t('tour-url')}
              validate={validateURL}
              component={InputField}
            />

            <Button
              text={t('save')}
              onClick={() => {
                setTourHighlightFormOpened(false);
              }}
            />
          </WindowWrapper>
        </Window>
      </BaseTourForm>
    </>
  );
}
