import React, { useEffect, useCallback, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, useRouteMatch, useParams } from 'react-router-dom';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { BlockTemplate } from 'components';
import { useEntityActions } from 'imddata';
import { EditPayoutAddressForm, AddPayoutAddressForm } from 'imdshared';

const useConfirmAddress = ({
  token,
  id,
}: {
  token: null | string;
  id: string | null;
}) => {
  // TODO URGENT REDO TO CONFIRM
  const { create } = useEntityActions('payoutAddressesActions');
  useEffect(() => {
    if (token && id) {
      create({
        id,
        data: { token, action: 'confirm' },
      });
    }
  }, [!!token]);
};

function EditAddress({ id }: { id: string | number }) {
  const [deleted, setDeleted] = useState(false);
  const onDelete = useCallback(() => setDeleted(true), []);
  return (
    <>
      {deleted && <Redirect to="/account/billing" />}
      <EditPayoutAddressForm
        onDelete={onDelete}
        disabled={true}
        key="form"
        id={id}
      />
    </>
  );
}

function AddAdress({ id }: { id: 'bank' | 'paypal' }) {
  const [createdEntityId, setCreatedEntityId] = useState<
    number | string | null
  >(null);

  return (
    <>
      <AddPayoutAddressForm
        onCreate={setCreatedEntityId}
        key="form"
        payoutMethodId={id}
      />

      {createdEntityId && (
        <Redirect to={`/account/billing/${createdEntityId}`} />
      )}
    </>
  );
}

const NEW_PAYOUT_KEYS: [['new-bank', 'bank'], ['new-paypal', 'paypal']] = [
  ['new-bank', 'bank'],
  ['new-paypal', 'paypal'],
];

const getPayoutAddressType = (
  id: string | number
): 'bank' | 'paypal' | null => {
  const [, payoutAddressType] = NEW_PAYOUT_KEYS.find(([key]) => key === id) || [
    null,
    null,
  ];

  return payoutAddressType;
};

const isNewAddress = (id: string | number) => getPayoutAddressType(id) !== null;

export const getFormLabel = (id: string, t: TFunction) => {
  if (id) {
    if (isNewAddress(id)) {
      return t(id);
    }
    return `${t('payout-account')} #${id}`;
  }
  return '';
};

export default function PayoutAddressForm({ match }: RouteComponentProps) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const payoutAddressType = getPayoutAddressType(id);
  const tokenMatch = useRouteMatch<{ token: string }>(`${match.path}/:token`);
  const { token } = tokenMatch?.params || { token: null };
  useConfirmAddress({ id: payoutAddressType ? null : id, token });

  return (
    <BlockTemplate padded={false} title={getFormLabel(id, t)}>
      {payoutAddressType ? (
        <AddAdress id={payoutAddressType} />
      ) : (
        <EditAddress id={id} />
      )}
    </BlockTemplate>
  );
}
