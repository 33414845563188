//
import * as artists from './artists';
import * as contributors from './contributors';
import * as performers from './performers';

export default {
  performers,
  artists,
  contributors,
};
