import accounting from 'accounting';
import React from 'react';
import { Content } from '../../../text';

type CurrecnyProps = {
  value: number | string;
  symbol?: string;
  thousand?: string;
  decimal?: string;
  prependSymbol?: boolean;
  upsell?: boolean;
  format?: string;
};

type Props<P> = P &
  CurrecnyProps & {
    render?: React.FC<{ children: React.ReactElement }>;
    className?: string;
    style?: any;
  };

const formatter = (props: CurrecnyProps) => {
  const value = accounting.formatMoney(
    props.value,
    props.symbol,
    2,
    props.thousand,
    props.decimal,
    props.symbol ? (props.prependSymbol ? '%s %v' : '%v %s') : '%v'
  );
  if (props.upsell) {
    return `(${value})`;
  }
  return value;
};

const defaultRender: React.FC<{
  className: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}> = ({ style, className, children }) => (
  <Content className={className} style={style}>
    {children}
  </Content>
);

type CurrencyFC<P = any> = React.FC<P & Props<P>>;

const Currency: CurrencyFC = ({
  render,
  value,
  symbol,
  thousand,
  decimal,
  format,
  prependSymbol,
  upsell,
  ...props
}) => {
  const finalRender = render || defaultRender;
  const children = format
    ? format
        .replace(
          ':value',
          formatter({
            value,
            thousand,
            decimal,
            symbol: '',
          })
        )
        .replace(/ /g, '\u00a0')
    : formatter({
        value,
        symbol: symbol && symbol.toUpperCase(),
        thousand,
        decimal,
        prependSymbol,
        upsell,
      });
  return finalRender({
    children,
    ...props,
  });
};

Currency.defaultProps = {
  render: defaultRender,
  symbol: '',
  thousand: ' ',
  decimal: '.',
  prependSymbol: false,
  upsell: false,
};

export default Currency;
