import styled from '@emotion/styled';

export const Badge = styled.span<{ primary?: boolean }>`
  position: relative;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;

  background: ${(props) =>
    props.primary ? 'var(--accent, #004d63)' : 'transparent'};

  &:after {
    top: 0;
    left: 0;
    content: '';
    box-sizing: border-box;
    position: absolute;
    transition: background-color 0.1s;
    border-radius: inherit;
    background-color: transparent;
    height: 100%;
    width: 100%;
  }

  color: ${(props) =>
    props.primary
      ? 'var(--on-accent, #004d63)'
      : 'var(--on-surface-var, #353534)'};
  text-align: center;
  font-feature-settings: 'ss01' on;

  /* Text/S/Content */
  font-family: Barlow;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.325px;

  :hover::after {
    background-color: ${({ onClick }) =>
      onClick
        ? 'var(--state-hover-on-surface, rgba(28, 28, 27, 0.08))'
        : 'transparent'};
  }
`;
