import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { getTranslatableName } from 'imddata/selectors';
import type { useRelease, EntityModels } from 'imddata';
import { uniq } from 'ramda';
import {
  Subheading,
  Headline,
  LinkButton,
  SVGIcon,
  Icons,
  theme,
  Caption,
  H3,
  H2,
} from 'imdui';
import LoadingArtwork from '../LoadingArtwork';

export const getReleaseBarcode = ({
  customBarcode,
  barcodes,
}: {
  customBarcode?: string;
  barcodes?: EntityModels.Release['barcodes'];
}) =>
  uniq(
    [
      ...(customBarcode
        ? [
            {
              value: customBarcode,
              type:
                customBarcode.length === 13
                  ? 'ean13'
                  : customBarcode.length === 12
                    ? 'upca'
                    : '',
            },
          ]
        : []),
      ...(barcodes || []),
    ].map(
      ({ value, type }) =>
        `${type === 'ean13' ? 'EAN ' : type === 'upca' ? 'UPC ' : ''}${value}`
    )
  ).join(', ');

const TextWrapper = styled.div<{ hasColumns: boolean }>`
  display: grid;
  align-self: flex-start;
  min-height: 0;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (min-width: 1280px) {
    grid-template-columns: ${({ hasColumns }) =>
      hasColumns ? '1fr 1fr' : '1fr'};
  }
`;

const Heading = styled.div`
  display: grid;
  gap: 16px;
  align-self: flex-end;
  position: relative;
  grid-template-columns: minmax(0, 100%) max-content;
  ${H3} {
    font-weight: 500;
    align-self: flex-end;
  }

  & > * {
    display: inline-flex;
    align-items: center;
  }
`;

const CopyrightWrapper = styled.span`
  max-width: 300px;
  display: inline-block;
  & > span {
    svg {
      position: relative;
      top: 2px;
    }
  }
`;

export const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: 8px;

  & > * {
    display: inline-flex;
    align-items: center;
  }

  ${Headline},
  ${Subheading} {
    word-break: break-word;
    display: inline-block;
  }
`;

const Wrapper = styled.div<{ compact?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  position: relative;
  width: 100%;
  gap: 24px;

  @media (min-width: 1024px) {
    grid-template-columns: 200px minmax(0, 1fr);
    grid-template-rows: auto;
  }
`;

type Release = Exclude<ReturnType<typeof useRelease>['entry'], null>;

type LoadedProps = {
  release: Release; // TODO: Add release model when it is ready
  editable?: boolean;
  className?: string;
  style?: any;
  compact?: boolean;
  children?: React.ReactNode;
};
function ReleaseDetailsLoaded({
  editable = false,
  release,
  className,
  compact,
  style,
  children,
}: LoadedProps) {
  const { t } = useTranslation();

  const displayArtist = getTranslatableName(release, '', 'displayArtist');

  const releaseArtist = displayArtist;

  const {
    title,
    version,
    label,
    // releaseTypeId,
    // tracksCount,
    id,
    genre,
    customBarcode,
    barcodes,
    copyrightText,
    copyrightYear,
  } = release;

  const barcodeString = getReleaseBarcode({ customBarcode, barcodes });
  return (
    <Wrapper
      compact={compact}
      style={style}
      className={className}
      data-test-id="ReleaseDetails"
    >
      <LoadingArtwork key="artwork" releaseId={release.id} />

      <TextWrapper hasColumns={!!children}>
        <ValuesGrid>
          <Heading>
            <H2 style={{ marginBottom: '4px' }} data-test-id="release-title">
              {`${title} ${version ? `(${version})` : ''}`}
            </H2>
            {editable && (
              <LinkButton
                size="small"
                text={t('edit')}
                to={`/library/releases/${id}/edit`}
              />
            )}
          </Heading>

          <Caption data-test-id="release-artist">{releaseArtist}</Caption>
          <Caption data-test-id="release-genre">{genre?.name}</Caption>
          <Caption data-test-id="release-label">{label?.name}</Caption>
          <CopyrightWrapper>
            <span>
              <SVGIcon
                d={Icons.music.p}
                size={16}
                color={theme.foreground.secondary}
              />
              &nbsp;
              <Caption data-test-id="release-copyright">
                {`${copyrightYear || ''} ${copyrightText}`}
              </Caption>
            </span>
          </CopyrightWrapper>
          {barcodeString ? <Caption>{barcodeString}</Caption> : null}
        </ValuesGrid>
        {children}
      </TextWrapper>
    </Wrapper>
  );
}

function ReleaseDetailsLoading({
  className,
  style,
  compact,
}: {
  className?: string;
  style?: any;
  compact?: boolean;
}) {
  return (
    <Wrapper
      style={style}
      compact={compact}
      className={className}
      data-test-id="ReleaseDetails"
    >
      <LoadingArtwork loading={true} />
      <div />
    </Wrapper>
  );
}

type Props = {
  release?: Release; // TODO: Add release model when it is ready
  editable?: boolean;
  className?: string;
  style?: any;
  children?: React.ReactNode;
};

export default function ReleaseDetails({ release, ...props }: Props) {
  if (!release) {
    return <ReleaseDetailsLoading {...props} />;
  }

  return <ReleaseDetailsLoaded release={release} {...props} />;
}
