import styled from '@emotion/styled';
import { AppTitle, Card } from 'imdui';

const FEEDBACK_WIDTH = 400;

export const InfoContentCard = styled(Card)`
  width: 100%;
  max-width: ${FEEDBACK_WIDTH}px;
  ${AppTitle} {
    margin-bottom: 4px;
  }
  padding: 0 24px;
  & > div {
    padding: 24px 0px;
    border-bottom: 1px solid var(--fg-4, rgba(0, 0, 0, 0.1));
    gap: 4px;
    &:last-child {
      border-bottom: none;
    }
  }
`;
