import { createContext } from 'react';

interface OverlayControl {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const defaultValue: OverlayControl = {
  isOpen: false,
  open: (): void => undefined,
  close: (): void => undefined,
};

export const createOverlayContext = (): React.Context<OverlayControl> =>
  createContext(defaultValue);

const OverlayContext = createOverlayContext();

OverlayContext.displayName = 'OverlayContext';

export default OverlayContext;
