import { reverse, filter } from 'ramda';
import constants from '../Next/constants';

const {
  artists: { staticRoles },
} = constants;

const validateArtistRoles = (values) => {
  if (values.artists && values.artists.length > 1) {
    const artists = filter((artist) => !staticRoles[artist.role])(
      values.artists
    );
    for (let i = 1; i < artists.length; i += 1) {
      if (artists[i].role === 'main' && artists[i - 1].role !== 'main') {
        return 'main-artists-mix-error';
      }
    }

    const reversed = reverse(artists);
    for (let i = 1; i < reversed.length; i += 1) {
      if (
        reversed[i].role === 'presented_by' &&
        reversed[i - 1].role !== 'presented_by'
      ) {
        return 'presented-by-artists-mix-error';
      }
    }
  }

  return '';
};

export default validateArtistRoles;
