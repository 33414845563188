import { useSubscriptionManagement } from 'components/logic/useSubscriptionManagement';
import { CustomerSubscriptionUpgrade } from 'imddata';
import { createContext, useContext } from 'react';

type ContextType = Omit<
  ReturnType<typeof useSubscriptionManagement>,
  'selectSubscription' | 'pendingInvoiceId'
> & {
  handleSubscription: (
    s?: CustomerSubscriptionUpgrade | { mode: 'cancel' }
  ) => void;
  // @deprecated
  createHandleSubscription: (
    s?: CustomerSubscriptionUpgrade | { mode: 'cancel' }
  ) => () => void;
};

export const SubscriptionSelectionContext = createContext<ContextType | null>(
  null
);

export const useSubscriptionSelectionManager = (): ContextType => {
  const v = useContext(SubscriptionSelectionContext);
  if (v === null) {
    throw new Error(
      'Can not access SubscriptionSelectionContext outside of SubscriptionSelectionManager'
    );
  }

  return v;
};
