import React from 'react';
import styled from '@emotion/styled';
import { Content, TextFormatted, SVGIcon, Icons, theme } from 'imdui';

const AlertNotificationStyled = styled.div`
  max-width: 644px;
  max-height: 240px;
  border-radius: 4px;
  border: solid 1px ${theme.foreground.secondary};
  padding: 15px;
  overflow: auto;
`;

const Header = styled.header`
  display: flex;
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;

type Props = {
  title: string;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
};

const AlertNotificationCard = ({ style, title, text, className }: Props) => (
  <AlertNotificationStyled style={style} className={className}>
    <Header>
      <SVGIcon d={Icons.states.warning} style={{ marginRight: 10 }} />

      <Content>{title}</Content>
    </Header>

    {text && (
      <Wrapper>
        <TextFormatted text={text} />
      </Wrapper>
    )}
  </AlertNotificationStyled>
);

export default AlertNotificationCard;
