//
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Card,
  AppContext,
  Block,
  Subheading,
  TabItemLink,
  AppIcon,
  CenterWrapper,
  palette,
} from 'imdshared';

import { VideoBackground } from '../../shared';

const StyledCenterWrapper = styled(CenterWrapper)`
  padding: 0 8px;
`;

const StyledCard = styled(Card)`
  max-width: 480px;
  margin: 72px auto 0 auto;

  @media (max-width: 375px) {
    margin: 8px 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 24px 24px;
`;

const TabsRow = styled.div`
  display: flex;
  padding: 0 24px;
  box-shadow: inset 0 -1px 0 0 ${({ theme }) => theme.component.border};
`;

const StyledAppIcon = styled(AppIcon)`
  margin-right: 16px;
`;

const FormWrapper = ({
  loggedIn,
  children,
}: {
  loggedIn: boolean;
  children: React.ReactChild;
}) => {
  const { t } = useTranslation();
  const app = useContext(AppContext);

  const { state } = useLocation<{ from?: { pathname: string } }>();

  return (
    <VideoBackground>
      <StyledCenterWrapper maxContentWidth={448}>
        <StyledCard>
          <Header>
            <StyledAppIcon />
            <Subheading>{app === 'admin' ? 'Admin Panel' : ''}</Subheading>
          </Header>

          {!loggedIn && app === 'front' && (
            <TabsRow>
              <TabItemLink
                exact={true}
                to="/signin"
                state={state}
                text={t('sign-in')}
              />
              <TabItemLink
                exact={true}
                to="/signup"
                state={state}
                text={t('sign-up')}
              />
            </TabsRow>
          )}

          <Block backgroundColor={palette.light.xLight}>{children}</Block>
        </StyledCard>
      </StyledCenterWrapper>
    </VideoBackground>
  );
};

export default FormWrapper;
