import React, { useCallback } from 'react';
import { Button } from 'imdui';
import DropdownInteractive from '../DropdownInteractive';
import { useDropdownData } from '../../fields/DropdownMenu';
import { ChevronDown } from 'imdui/src/components/icon/ComponentIcons';

export default function ButtonDropdown({
  renderDropdown,
  backgroundColor,
  icon,
  data,
  className,
  showLoading,
  floatingLabelText = null,
  placeholder = null,
  errorText = null,
  onBlur = () => {
    // test
  },
  style = null,
  getValue,
  getLabel,
  addString,
  ...props
}) {
  const dropdownData = useDropdownData({ getValue, getLabel, data });

  const renderButtonComponent = useCallback(
    ({ value, label, displayValue, ...other }) => {
      const valueLabel =
        dropdownData && dropdownData.entries && dropdownData.entries[value]
          ? dropdownData.entries[value].label
          : null;

      const titleText = valueLabel || label || value;

      return (
        <Button
          {...other}
          showLoading={showLoading}
          backgroundColor={backgroundColor}
          text={titleText}
          icon={other.icon || ChevronDown}
        />
      );
    },
    [showLoading, props.value, className]
  );

  return (
    <DropdownInteractive
      {...props}
      {...dropdownData}
      style={style}
      className={className}
      onBlur={onBlur}
      errorText={errorText}
      floatingLabelText={floatingLabelText}
      placeholder={placeholder}
      renderDropdown={renderDropdown}
      render={renderButtonComponent}
    />
  );
}
