import React, { Component } from 'react';
import { without, append } from 'ramda';
import type { Query, ReleasesNested } from 'imddata';
import { useQuery, useReleases } from 'imddata';
import type { InfiniteListRowRenderer } from 'imdshared';
import { InfiniteList } from 'imdshared';
import ReleaseListItem from '../ReleaseListItem';

type Props = {
  searchQuery?: Query;
  rowRenderer: InfiniteListRowRenderer<ReleasesNested>;
};

export const InfiniteReleasesList: React.FC<Props> = ({
  searchQuery,
  ...props
}) => {
  const { query, queryHash } = useQuery({ query: searchQuery });
  const {
    entries,
    loadMore,
    request: { loading, hasMorePages },
  } = useReleases({ query, queryHash });

  return (
    <InfiniteList<ReleasesNested>
      key="list"
      data={entries}
      hasNextPage={!!hasMorePages}
      loading={loading}
      loadNextPage={loadMore}
      getRowHeight={() => 64}
      {...props}
    />
  );
};

// const ConnectedReleasesList: React.FC<Props> = ({
//   selectedReleases: [],
//   searchQuery: {},
//   isCheckable: false,
// }) => {

// }

type Id = string | number;
type Selection = (string | number)[];

class ConnectedReleasesList extends Component<{
  onClickRelease: (e: MouseEvent, id: Id) => void;
  selectedReleases?: Selection;
  onChangeSelection?: (e: MouseEvent, s: Selection) => void;
  isCheckable?: boolean;
  searchQuery?: Query;
}> {
  static defaultProps = {
    selectedReleases: [],
    searchQuery: {},
    isCheckable: false,
  };

  handleReleaseClick = (itemId: Id) => (e: MouseEvent) => {
    const { onClickRelease } = this.props;

    if (onClickRelease) {
      onClickRelease(e, itemId);
    }
  };

  handleReleaseCheck = (itemId: Id) => (e: MouseEvent) => {
    const { onChangeSelection, selectedReleases } = this.props;
    e.stopPropagation();
    if (onChangeSelection && selectedReleases) {
      const checked = selectedReleases.find((st) => st === itemId);
      const modifier = checked ? without([itemId]) : append(itemId);
      onChangeSelection(e, modifier(selectedReleases));
    }
  };

  rowRenderer: InfiniteListRowRenderer<ReleasesNested> = ({
    item,
    key,
    style,
  }) => {
    const { isCheckable, selectedReleases } = this.props;

    if (!item) {
      return <div style={style} key={key} />;
    }

    return (
      <ReleaseListItem
        item={item}
        key={key}
        isCheckable={isCheckable}
        checked={!!selectedReleases?.find((st) => st === item.id)}
        onCheck={this.handleReleaseCheck(item.id)}
        onClick={this.handleReleaseClick(item.id)}
        style={style}
      />
    );
  };

  render() {
    const { searchQuery, ...props } = this.props;

    return (
      <InfiniteReleasesList
        rowRenderer={this.rowRenderer}
        searchQuery={searchQuery}
        {...props}
      />
    );
  }
}

export default ConnectedReleasesList;
