//
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AlertNotificationCard, Block } from 'imdshared';
import { Detector } from 'react-detect-offline';
import config from 'imdconfig';
import { FormWrapper } from '../../components';

export default function Maintenance() {
  const { t } = useTranslation();

  const OnlineCard = (
    <AlertNotificationCard
      title={t('maintenance-title')}
      text={t('maintenance-text')}
    />
  );
  const OfflineCard = (
    <AlertNotificationCard
      title={t('no-internet-connection-title')}
      text={t('no-internet-connection-text')}
    />
  );

  return (
    <FormWrapper loggedIn={true}>
      <>
        <Helmet>
          <title>{t('page-title-maintenance')}</title>
        </Helmet>
        <Block padding={[0, 20, 20, 20]}>
          <Block>
            <Detector
              polling={{
                url: config.imdfront.appUrl,
                timeout: 1000 * 10,
                enabled: true,
                interval: 1000,
              }}
              render={({ online }) => (online ? OnlineCard : OfflineCard)}
            />
          </Block>
        </Block>
      </>
    </FormWrapper>
  );
}
