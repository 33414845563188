import React, { useContext } from 'react';
import { Portal } from 'react-portal';
import { AnimatePresence } from 'framer-motion';
import { LayoutContext } from 'imdshared';
import NotificationsWindow from '../NotificationsWindow';
import { Scrim, Container } from './styles';
import transition from '../../transition';

type Props = {
  isOpen: boolean;
  onClickClose: (e: React.MouseEvent) => void;
};

const NotificationsWindowController: React.FC<Props> = ({
  isOpen,
  onClickClose,
}) => {
  const handleClose = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onClickClose(e);
  };

  const handleContainerClicks = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  const { appRefreshZoneHeight } = useContext(LayoutContext);

  return (
    <Portal
      node={document.getElementById('app') || document.getElementById('root')}
    >
      <AnimatePresence>
        {isOpen && (
          <Scrim
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={transition}
            style={{ top: appRefreshZoneHeight }}
            onClick={handleClose}
          >
            <Container
              initial={{ x: -256 }}
              animate={{ x: 0 }}
              exit={{ x: -256 }}
              transition={transition}
              style={{ top: appRefreshZoneHeight }}
              onClick={handleContainerClicks}
            >
              <NotificationsWindow onClickClose={handleClose} />
            </Container>
          </Scrim>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default NotificationsWindowController;
