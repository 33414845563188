import { actions } from 'imddata/actionTypes/ui/selection';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import type { ReduxState } from 'redux/reducers';
const noSelected: never[] = [];
export const useTrackSelection = () => {
  const selected = useSelector(
    ({ ui: { selection } }: ReduxState) => selection.tracks || noSelected
  );
  const dispatch = useDispatch();
  const cancelSelection = useCallback(() => {
    dispatch(
      actions.updateSelection({
        entity: 'tracks',
        selected: [],
      })
    );
    dispatch(
      actions.updateSelection({
        entity: 'contributors',
        selected: [],
      })
    );
    dispatch(
      actions.updateSelection({
        entity: 'publishers',
        selected: [],
      })
    );
  }, []);
  const changeSelection = useCallback((tracks: number[]) => {
    dispatch(
      actions.updateSelection({
        entity: 'tracks',
        selected: tracks,
      })
    );
  }, []);
  return {
    changeSelection,
    selected,
    cancelSelection,
  };
};
