import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { ReduxState } from 'redux/reducers';
import { createSelector } from 'reselect';

const defaultAppSettings = {
  numberFormatLocale: 'en',
  timezone: '',
};

const makeRouteStateSelect = () =>
  createSelector(
    (state: ReduxState) => state.auth.profile,
    (state: ReduxState) => state.ui.impersonate,
    (state: ReduxState) => state.entities.customers.entities,
    (state: ReduxState) => state.entities.users.entities,
    (state: ReduxState) => state.entities.countries.entities,
    (profile, impersonate, customers, users, countries) => {
      if (!profile) {
        return {
          isImpersonating: false,
          isAdmin: false,
          allowResourcesLocalization: false,
          appSettings: defaultAppSettings,
          featureFlagUser: undefined,
        };
      }

      const { isAdmin, admin, userId, currency, customerId } = profile;

      const isImpersonating =
        impersonate && impersonate.userId && impersonate.customerId;
      const user = users[userId] || admin;
      const statefulFallback = {
        allowResourcesLocalization: false,
        isImpersonating,
        isAdmin,
        appSettings: defaultAppSettings,
        featureFlagUser: undefined,
      };

      if (!customerId) {
        return statefulFallback;
      }

      const customer = customers[customerId];
      if (!customer || !user) {
        return statefulFallback;
      }
      const country = countries[customer.countryId];
      if (!country) return statefulFallback;
      return {
        isImpersonating,
        isAdmin,
        allowResourcesLocalization: customer.allowResourcesLocalization,
        appSettings: {
          locale: user.locale,
          timezone: user.timezone,
          numberFormatLocale: country.isoAlpha2?.toLowerCase(),
          dateFormat: user.dateFormatId,
        },

        featureFlagUser: {
          id: user.trackingId,
          attributes: {
            isAdmin: isAdmin || false,
            country: country.isoAlpha2,
            pricingRegion: country.priceRegionId,
            isImpersonating: isImpersonating || false,
            customerId,
            userId,
            currency: currency.id,
            subscription: customer.subscriptionId,
            timezone: user.timezone,
            language: user.locale,
            isVerified: user.isVerified,
          },
        },
      };
    }
  );

const useRouteState = () => {
  const selectRouteState = useMemo(makeRouteStateSelect, []);
  return useSelector(selectRouteState);
};

export default useRouteState;
