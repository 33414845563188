import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ReleasesNested } from 'imddata';
import { useReleases } from 'imddata';
import { Helmet } from 'react-helmet-async';
import { ReleaseQueryBuilder } from 'components';
import { InfiniteList } from 'imdshared';
import { useReportingQuery, ReportingPage } from '../../shared';
import ReleaseSalesRow from './ReleaseSalesRow';

const listStyle = {
  padding: '32px 0',
};

const withBarcodes = { with: 'barcodes' };

const getRowHeight = () => 256;

const List = () => {
  const { queryHash, query } = useReportingQuery();
  const {
    entries,
    loadMore,
    request: { loading, hasMorePages },
  } = useReleases({ query, queryHash });

  const renderItem = useCallback(
    ({ item, key, style }) => (
      <div style={style} key={key}>
        <ReleaseSalesRow release={item} queryHash={queryHash} />
      </div>
    ),
    [queryHash]
  );
  return (
    <InfiniteList<ReleasesNested>
      style={listStyle}
      key="list"
      data={entries}
      hasNextPage={!!hasMorePages}
      loading={loading}
      rowRenderer={renderItem}
      loadNextPage={loadMore}
      getRowHeight={getRowHeight}
    />
  );
};

const ReportingReleases = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('page-title-reporting-releases')}</title>
      </Helmet>

      <ReportingPage
        query={withBarcodes}
        renderQueryBuilder={ReleaseQueryBuilder}
      >
        <div>
          <List />
        </div>
      </ReportingPage>
    </div>
  );
};

export default ReportingReleases;
