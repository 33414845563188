import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { ReleasePageFieldsColumn } from 'imdshared';
import type { ReduxState } from 'imddata';
import {
  ArtistSideEditor,
  ReleaseEditor,
  useProductId,
  useProductPage,
} from 'components';
import { touch } from 'redux-form';

const requiredFields: Array<keyof Values> = [
  'title',
  'artists',
  'customBarcode',
  'originalReleaseDate',
  'pendingCover',
];

const Wrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
  position: relative;
  transition: background-color 0.1s;
  padding: 24px 0px 0px;
`;

type Values = {
  title: string;
  artists: unknown[];
  customBarcode: string;
  originalReleaseDate: string;
  pendingCover: string;
};

const createFormString = (releaseId: string | number) =>
  'ReleasePageReleaseForm-' + releaseId;

const useTouchFields = (releaseId: number | string) => {
  const form = createFormString(releaseId);
  const dispatch = useDispatch();

  const { state } = useProductPage();
  useEffect(() => {
    if (state?.steps?.[1]?.visited) {
      requiredFields.forEach((field) => dispatch(touch(form, field)));
    }
  }, [state?.steps?.[1]?.visited]);
};

export const ReleasePageReleaseForm = ({
  releaseId,
}: {
  releaseId: number;
}) => {
  const releasePageId = useProductId();

  const displayBarcodeInput = useSelector<ReduxState, boolean>(
    (state) =>
      state.entities.releases.entities[releaseId]?.deliveryStatus ===
      'not_delivered'
  );

  useTouchFields(releaseId);

  return (
    <>
      <div>
        <Wrapper>
          <ReleaseEditor
            fieldsComponent={
              <ReleasePageFieldsColumn
                releasePageId={releasePageId}
                displayBarcodeInput={displayBarcodeInput}
              />
            }
            artworkCopyrightFields={false}
            requiredFields={requiredFields}
            releaseId={releaseId}
            form={createFormString(releaseId)}
          />
        </Wrapper>
      </div>
      <ArtistSideEditor isAdaptive={false} />
    </>
  );
};
