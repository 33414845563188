import styled from '@emotion/styled';
import { EnabledField } from 'imdshared';
import { Card, H3, TextFormatted } from 'imdui';
import { useTranslation } from 'react-i18next';

const BarrierCard = styled(Card)`
  padding: 24px 32px;
  margin-top: 32px;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.accent.orange};
  ${H3} {
    margin-bottom: 16px;
  }
`;
const BarrierField = styled.div`
  max-width: 512px;
  margin-top: 16px;
`;

export const CidBarrierField = ({ input, meta = {}, count = 1 }: any) => {
  const { t } = useTranslation();

  return (
    <BarrierCard>
      <H3>{t('make-sure-tracks-eligible')}</H3>
      <TextFormatted text={t('cid-eligibility-list')} />
      <BarrierField>
        <EnabledField
          testId="ConfirmCidTracks"
          label={t('confirm-original-tracks-for-cid', {
            count,
          })}
          description={t('right-to-reject-order', { count })}
          // @ts-ignore
          meta={meta}
          // @ts-ignore
          input={input}
        />
      </BarrierField>
    </BarrierCard>
  );
};
