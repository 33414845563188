import React, { useContext, useCallback } from 'react';
import { css } from '@emotion/react';
import { EventTypes } from 'redux-segment';
import { useDispatch } from 'react-redux';
import { uploadTracks } from 'imddata/actionTypes/tracks';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ImpersonateContext } from 'imddata';
import DropzoneContainer from '../DropzoneContainer';

const containerStyle = css`
  border: none;
  display: inline-flex;
`;

type Props = {
  languageId?: string;
  trackId: number;
  genreId?: number;
  disableClick?: boolean;
  hideOverlay?: boolean;
  multiple?: boolean;
  nestingPath?: unknown;
  impersonate?: boolean;
  artists?: number[];
  message: string;
  children?: React.ReactChild;
  className?: string;
  style?: React.CSSProperties;
};

const acceptedMime = {
  'audio/wav': ['.wav'],
  'audio/wave': ['.wav'],
  'audio/x-wav': ['.wav'],
  'audio/x-pn-wav': ['.wav'],
};

// const nestingPath = {
//   path: ['volumes', 0],
//   wrapper: 'releaseTracks',
//   entity: 'releases'
// }

const TracksUploader: React.FC<Props> = ({
  hideOverlay,
  message,
  disableClick = false,
  children,
  artists,
  languageId,
  genreId,
  nestingPath,
  trackId,
  multiple = true,
  style,
  className,
}) => {
  const impersonate = useContext(ImpersonateContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleWaveFileDropped = useCallback(
    (files, uploadType) => {
      if (!files || !files.length) {
        return;
      }

      dispatch(
        uploadTracks(
          {
            trackId,
            languageId,
            nestingPath,
            genreId,
            artists,
            audios: files,
          },
          {
            impersonate,
            analytics: {
              eventType: EventTypes.track,
              eventPayload: {
                event: 'FT Uploading Tracks',
                properties: {
                  nestingPath,
                  uploadType,
                  trackId,
                  artists,
                  fileCount: files.length,
                },
              },
            },
          }
        )
      );
    },
    [nestingPath, artists, languageId, genreId, trackId, multiple]
  );

  const handleOverlayDrop = useCallback(
    (files) => {
      handleWaveFileDropped(files, 'dnd');
    },
    [handleWaveFileDropped]
  );

  const handleDialogDrop = useCallback(
    (files) => {
      handleWaveFileDropped(files, 'dialog');
    },
    [handleWaveFileDropped]
  );

  const { getRootProps, getInputProps } = useDropzone({
    noClick: disableClick,
    multiple,
    onDrop: handleDialogDrop,
    accept: acceptedMime,
  });

  return (
    <>
      {!hideOverlay && (
        <DropzoneContainer
          onDrop={handleOverlayDrop}
          multiple={multiple}
          accept={acceptedMime}
          key="overlay"
        >
          {({ isDragAccept }) => {
            if (isDragAccept) {
              return <div>{t('release-to-upload-tracks')}</div>;
            }

            if (message) {
              return `${message}`;
            }

            return `${t('upload-new-tracks')}`;
          }}
        </DropzoneContainer>
      )}

      {children ? (
        <div
          key="element"
          css={containerStyle}
          className={className}
          style={style}
          {...getRootProps()}
        >
          <input data-test-id="cover-dropzone-input" {...getInputProps()} />
          {children}
        </div>
      ) : null}
    </>
  );
};

export default TracksUploader;
