import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body, SVGIcon, Icons, theme } from 'imdshared';
import { UnavailableSales } from '../UnavailableSales';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 56px 16px 0 16px;
  grid-column: 1 / -1;

  svg {
    margin-right: 32px;
  }

  ${Body} {
    color: ${(props) => props.theme.foreground.secondary};
  }
`;

const EmptySalesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <UnavailableSales />
      <Wrapper data-test-id="EmptySalesPage">
        <SVGIcon
          d={Icons.categories.salesOverviewEmpty}
          size={96}
          color={theme.foreground.disabled}
        />

        <Body>{t('no-sales')}</Body>
      </Wrapper>
    </>
  );
};

export default EmptySalesPage;
