//
import { put } from 'redux-saga/effects';
import { types } from '../../actionTypes/tracks';

export default function* uploadAudioForSingleTrack({
  payload: { audios, trackId },
  meta,
}) {
  yield put({
    type: types.ADD_TO_UPLOAD_QUEUE,
    payload: {
      id: trackId,
      audio: audios[0],
    },
    meta,
  });
}
