import { types } from '../../actionTypes/dev';

const initState = {
  devmode: __DEVELOPMENT__,
  features: {},
  vars: {},
};

const devReducer = (state = initState, action) => {
  switch (action.type) {
    case types.TOGGLE_DEVMODE: {
      return {
        ...state,
        devmode:
          action.payload.devmode === undefined
            ? !state.devmode
            : action.payload.devmode,
      };
    }

    case types.SET_VARS: {
      return {
        ...state,
        vars: action.payload.vars,
      };
    }
    case types.UPDATE_FEATURES: {
      return {
        ...state,
        features: {
          ...action.payload.features,
        },
      };
    }

    default:
      return state;
  }
};

export default devReducer;
