import React, { useMemo } from 'react';
import { useRelease } from 'imddata';
import { ExtendedFieldsColumn } from './FieldsCollections';
import type { Props as FormProps } from './ReleaseForm';
import ConnectedReleaseForm from './ReleaseForm';
import { useFieldUpdatable } from '../../fields';
import type { ReleaseFormContextValue } from './ReleaseFormContext';
import { ReleaseFormContext } from './ReleaseFormContext';
import { hasEmoji, validateArtistRoles } from '../../helpers';

const useReleaseValues = (
  entry: ReturnType<typeof useRelease>['entry'],
  releaseAt?: string | null
) => {
  return useMemo(() => {
    if (!entry) return {};

    return {
      labelId: entry.labelId,
      genreId: entry.genreId,
      originalReleaseDate: entry.originalReleaseDate,
      copyrightYear: entry.copyrightYear || undefined,
      copyrightText: entry.copyrightText,
      coverCopyrightYear: entry.coverCopyrightYear || undefined,
      coverCopyrightText: entry.coverCopyrightText,
      catalogNumber: entry.catalogNumber,
      hasTracksWithVariousArtists: entry.hasTracksWithVariousArtists,
      customBarcode: entry.customBarcode,
      pendingCover: false,
      revenueSplits: entry.revenueSplits || [],
      title: entry.defaultNameNormalized?.title,
      version: entry.defaultNameNormalized?.version,
      promotionalText: entry.defaultNameNormalized?.promotionalText,
      releaseAt: entry.currentDeliveryBundle?.releaseAt, // For original release date
      languageId: entry.defaultNameNormalized?.languageId,
      artists: entry.artists
        ? entry.artists.map(({ artistId, role }) => ({ artistId, role }))
        : [],
    };
  }, [entry, releaseAt]);
};

type Props = {
  releaseId: string | number;
  form?: string;
  hideCoverUploader?: boolean;
  fieldsComponent?: FormProps['fieldsComponent'];
  artworkCopyrightFields?: FormProps['artworkCopyrightFields'];
  additionalValidation?: FormProps['additionalValidation'];
  showCoverRequirementText?: FormProps['showCoverRequirementText'];
  requiredFields?: ReleaseFormContextValue['requiredFields'];
  alwaysShowCoverFields?: boolean;
  children?: React.ReactNode;
};

const defaultRequiredFields: never[] = [];

export const validate = (values: any, { requiredFields }: FormProps) => {
  const errors: Record<string, string> = {};

  requiredFields?.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'required';
    }
  });
  errors.title = hasEmoji(values.title) ? 'emojis-not-allowed' : errors.title;
  errors.promotionalText = hasEmoji(values.promotionalText)
    ? 'emojis-not-allowed'
    : errors.promotionalText;
  errors.copyrightText = hasEmoji(values.copyrightText)
    ? 'emojis-not-allowed'
    : errors.copyrightText;

  // @ts-ignore
  errors.artists =
    !values.hasTracksWithVariousArtists &&
    requiredFields?.includes('artists') &&
    values.artists?.length === 0
      ? 'required'
      : validateArtistRoles(values);

  return errors;
};

export default function EditRelease({
  releaseId,
  artworkCopyrightFields = true,
  fieldsComponent = <ExtendedFieldsColumn />,
  requiredFields = defaultRequiredFields,
  form,
  ...props
}: Props) {
  const { entry, editableFields: propsEditableFields } = useRelease({
    id: releaseId,
  });
  const initialValues = useReleaseValues(entry);

  const { editable } = useFieldUpdatable({ fieldName: 'covers' });

  const editableFields = useMemo(() => {
    if (typeof editable === 'boolean') {
      return {
        ...(propsEditableFields || {}),
        coverUpload: editable,
      };
    }
    return propsEditableFields;
  }, [propsEditableFields, editable]);

  const contextValue = useMemo<ReleaseFormContextValue>(() => {
    return {
      form: form || `ReleaseForm-${releaseId}`,
      editableFields,
      requiredFields,
      releaseId,
      releaseNameId: entry?.defaultNameNormalized?.id,
    };
  }, [
    form,
    editableFields,
    requiredFields,
    entry?.defaultNameNormalized?.id,
    releaseId,
  ]);

  if (
    !entry ||
    entry.artists === undefined ||
    !entry.defaultNameNormalized?.id
  ) {
    return null;
  }
  return (
    <ReleaseFormContext.Provider value={contextValue}>
      <ConnectedReleaseForm
        artworkCopyrightFields={artworkCopyrightFields}
        fieldsComponent={fieldsComponent}
        form={contextValue.form}
        requiredFields={requiredFields}
        initialValues={initialValues}
        uploadStatus={entry.uploadStatus}
        validate={validate}
        {...props}
      />
    </ReleaseFormContext.Provider>
  );
}
