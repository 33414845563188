//
import React, { useCallback, useEffect, Suspense, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import config from 'imdconfig';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateEntity,
  useCurrentCustomer,
  useCurrentUser,
  useDebugMode,
  useIsAdmin,
  useSettings,
  useUpdateEntity,
} from 'imddata';
import { Tooltip } from 'imdui';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { actions as uiActions } from 'imddata/actionTypes/ui/selection';
import {
  ImpersonateContext,
  HelpWindowController,
  Centered,
  LoadingIndicator,
  VersionLabel,
  AllowResourcesLocalizationContext,
  ScreenLayout,
  SubscriptionUpsellContext,
  useSubscriptionUpsellManager,
  FastPaymentContext,
  useFastPaymentFlowManager,
  ApplicationSettingsContext,
} from 'imdshared';
import 'react-dates/initialize';

import { FeatureProvider, useFeature } from 'imdfeature';
import { ImpersonationNotice, ErrorBoundary } from 'components';
import {
  ProtectedRoute,
  Auth,
  useLoggedAuthRoutesMatch,
  Maintenance,
} from 'imdauth';

import type { ReduxState } from 'redux/reducers';
import {
  FieldUpdateRequests,
  NotFound,
  Revenue,
  PaymentGate,
  Devdebug,
  Reporting,
  Products,
  Order,
  Account,
  Library,
  Dashboard,
  AppCrash,
} from '../index';
import {
  ConnectedLayoutRoute,
  useRouteState,
  MobileBar as TopBar,
  NavDrawer,
} from './components';
import GlobalStyles from './GlobalStyles';
import { SubscribeLayer } from './SubscribeLayer';
import { FastPaymentLayer } from './FastPaymentLayer';
import ReleaseNotesLayer from './ReleaseNotesLayer';
import {
  useCustomerIoMessaging,
  useImpersonate,
  useLocaleHandling,
  useSegmentPageViewsTracking,
} from './hooks';
import { TakedownConfirmationDashboardModal } from './TakedownConfirmationDashboardModal';
import { ConnectedAppBanner } from './ConnectedAppBanner';
import { Onboarding } from './Onboarding/index';
import { WinbackYearlyWindow } from './WindbackYearlyWindow';

const components = { TopBar, NavDrawer };

const ScreenStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

const MIN_CUSTOMER_ID_FOR_ONBOARDING = Number(
  process.env.MIN_CUSTOMER_ID_FOR_ONBOARDING || '0'
);

const ConnectedScreenLayout: React.FC<
  React.ComponentProps<typeof ScreenLayout>
> = ({ children, ...props }) => {
  const { settings, updateSettings } = useSettings();
  const loggedIn = useSelector(({ auth: loggedUser }: ReduxState) =>
    loggedUser.data ? !!loggedUser.data.customerId : false
  );

  const { entry: customer } = useCurrentCustomer();
  const optimizelyUrl = useSelector(
    ({ auth }: ReduxState) => auth.profile?.extra?.optimizely?.url
  );
  const { entry: user } = useCurrentUser();
  const { createEntry: createArtist } = useCreateEntity({ entity: 'artists' });
  const [onboardingEnabled] = useFeature({ featureKey: 'onboarding' });
  const { updateEntry: updateCustomer } = useUpdateEntity({
    entity: 'customers',
  });

  const match = useLoggedAuthRoutesMatch();
  const subscriptionContextValue = useSubscriptionUpsellManager();
  const fastPaymentFlowContextValue = useFastPaymentFlowManager();
  const { allowResourcesLocalization, appSettings, featureFlagUser } =
    useRouteState();
  const dispatch = useDispatch();
  const hasSelected = useSelector(
    (state: ReduxState) =>
      !!state.ui.selection.contributors.length ||
      !!state.ui.selection.artists.length ||
      !!state.ui.selection.tracks.length ||
      !!state.ui.selection.publishers.length
  );
  const [hasViewedOnboarding, setHasViewedOnboarding] = useState(true);
  const [bypassOnboarding, setBypassOnboarding] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      setHasViewedOnboarding(!!settings.onboardingResult);
    }
  }, [loggedIn]);

  const handleScreenClick = useCallback(() => {
    if (hasSelected) {
      dispatch(uiActions.clearSelection());
    }
  }, [hasSelected]);

  const isAdmin = useIsAdmin();

  if (
    !isAdmin &&
    !hasViewedOnboarding &&
    onboardingEnabled &&
    !bypassOnboarding &&
    (customer?.id || 1) >= MIN_CUSTOMER_ID_FOR_ONBOARDING
  ) {
    return (
      <ErrorBoundary
        errorComponent={() => null}
        onCatch={() => {
          setBypassOnboarding(true);
        }}
      >
        <Onboarding
          onSubmit={(result) => {
            setHasViewedOnboarding(true);
            if (customer && user) {
              createArtist({
                data: {
                  defaultGenreId: result.genreId,
                  names: [
                    {
                      name: result.artistName,
                      languageId: user.defaultLanguageId,
                    },
                  ],
                },
              });
              updateCustomer({
                id: customer.id,
                data: {
                  defaultGenreId: result.genreId,
                  industryProfiles: result.userCategory,
                },
              });
            }
            updateSettings({
              hasViewedOnboarding: true,
              onboardingResult: result,
            });
          }}
        />
      </ErrorBoundary>
    );
  }

  if (!loggedIn || match) {
    // eslint-disable-next-line
    return <>{children}</>;
  }

  return (
    <FeatureProvider user={featureFlagUser} url={optimizelyUrl}>
      <ApplicationSettingsContext.Provider value={appSettings}>
        <AllowResourcesLocalizationContext.Provider
          value={!!allowResourcesLocalization}
        >
          <SubscriptionUpsellContext.Provider value={subscriptionContextValue}>
            <FastPaymentContext.Provider value={fastPaymentFlowContextValue}>
              <ScreenLayout
                {...props}
                onContentClick={handleScreenClick}
                banner={
                  <ErrorBoundary errorComponent={() => null}>
                    <ConnectedAppBanner />
                  </ErrorBoundary>
                }
              >
                {children}
                {loggedIn && (
                  <>
                    <ErrorBoundary errorComponent={() => null}>
                      <ReleaseNotesLayer />
                    </ErrorBoundary>
                    <WinbackYearlyWindow />
                    <SubscribeLayer />
                    <FastPaymentLayer />
                  </>
                )}
              </ScreenLayout>
            </FastPaymentContext.Provider>
          </SubscriptionUpsellContext.Provider>
        </AllowResourcesLocalizationContext.Provider>
      </ApplicationSettingsContext.Provider>
    </FeatureProvider>
  );
};

const loader = (
  <Centered style={{ width: '100%', height: '100vh' }}>
    <LoadingIndicator size="large" />
  </Centered>
);

const BackToDashboard = () => <Redirect to="/" />;

export default function App() {
  useSegmentPageViewsTracking();
  useCustomerIoMessaging();
  useLocaleHandling();
  useDebugMode();
  const impersonate = useImpersonate();

  const apiOutOfService = useSelector(
    (state: ReduxState) => state.auth && state.auth.failedToFetch
  );

  const loggedUserRequested = useSelector(
    (state: ReduxState) => state.auth && state.auth.isRequested
  );
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!loggedUserRequested && location.pathname !== '/token') {
      dispatch(loadLoggedUser());
    } else if (impersonate) {
      dispatch(loadLoggedUser());
    }
  }, [impersonate]);

  if (apiOutOfService) {
    return (
      <>
        <GlobalStyles />
        <Maintenance />
      </>
    );
  }

  if (!loggedUserRequested && location.pathname !== '/token') {
    return (
      <Centered style={{ width: '100%', height: '100vh' }}>
        <GlobalStyles />
        <LoadingIndicator size="large" />
      </Centered>
    );
  }

  return (
    <ScreenStyled id="test">
      <GlobalStyles />
      <Helmet {...config.imdfront.app.head} />

      <Tooltip
        openOnClick={false}
        clickable={false}
        anchorSelect=".imd-tooltip"
      />

      <VersionLabel style={{ bottom: 0 }} />

      {impersonate.userId && (
        <ImpersonationNotice
          id={impersonate.userId}
          email={impersonate.email}
          onClickClose={impersonate.onClear}
        />
      )}

      <ImpersonateContext.Provider value={impersonate}>
        <HelpWindowController>
          <ErrorBoundary errorComponent={AppCrash}>
            <Suspense fallback={loader}>
              <ConnectedScreenLayout
                key="screen-layout"
                components={components}
              >
                <Switch>
                  <ConnectedLayoutRoute
                    path="/account/invoices/:id/pay"
                    component={PaymentGate}
                  />

                  <ConnectedLayoutRoute
                    exact={true}
                    path="/"
                    component={Dashboard}
                  />

                  <ConnectedLayoutRoute
                    exact={true}
                    path="/dashboard"
                    component={BackToDashboard}
                  />

                  <ConnectedLayoutRoute
                    path="/takedown/:entity/:uuid"
                    component={TakedownConfirmationDashboardModal}
                  />

                  <ConnectedLayoutRoute path="/library" component={Library} />

                  <ConnectedLayoutRoute path="/account" component={Account} />

                  <ConnectedLayoutRoute path="/order" component={Order} />

                  <ConnectedLayoutRoute path="/products" component={Products} />

                  <ConnectedLayoutRoute path="/revenue" component={Revenue} />

                  <ConnectedLayoutRoute
                    path="/update-requests"
                    component={FieldUpdateRequests}
                  />

                  <ConnectedLayoutRoute
                    path="/reporting"
                    component={Reporting}
                  />

                  <Route
                    path="/downloads"
                    render={() => {
                      return <Redirect to="/account/downloads" />;
                    }}
                  />

                  <Route path="/devdebug" component={Devdebug} />

                  <ProtectedRoute
                    path="/not-found"
                    exact={true}
                    component={NotFound}
                  />

                  <Route path="/" component={Auth} />
                </Switch>
              </ConnectedScreenLayout>
            </Suspense>
          </ErrorBoundary>
        </HelpWindowController>
      </ImpersonateContext.Provider>
    </ScreenStyled>
  );
}
