import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body, SVGIcon, Icons } from 'imdui';

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const EmptyNotificationsBody = styled(Body)`
  display: block;
  margin: 36px 0 0 60px;
  color: ${({ theme }): string => theme.foreground.secondary};
`;

const EmptyNotificationsIcon = styled(SVGIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  fill: ${({ theme }): string => theme.lights[2]};
`;

const EmptyNotifications: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <EmptyNotificationsBody>{t('no-notifications')}</EmptyNotificationsBody>
      <EmptyNotificationsIcon d={Icons.categories.notifications} size={144} />
    </Container>
  );
};

export default EmptyNotifications;
