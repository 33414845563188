import React, { useMemo, useEffect, useLayoutEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import {
  useHistory,
  useParams,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { Portal } from 'react-portal';
import {
  useRelease,
  useEntryProvider,
  useEntityFileRequestProvider,
  useUpdateEntity,
  useCreateEntity,
} from 'imddata';
import type { ReleasePage as ReleasePageType } from 'components';
import {
  PageTemplate,
  ProductPageContext,
  ReleaseGridCard,
  StaticReleasePageInfo,
  ReleasePageEditor,
  useReleasePage,
  ProductRating,
} from 'components';
import { AutosizeGrid, Button, Card, H1, H3, LinkButton } from 'imdui';
import { useTranslation } from 'react-i18next';
import NavBar from '../../shared/NavBar';
import type { ProductPageContextType } from '../../shared/ProductWizard';
import {
  ProductWizard,
  useProductPageActions,
  ProductPriceProvider,
  useProductPage,
  useProductId,
} from '../../shared/ProductWizard';
import { ProductPriceContext } from '../../shared';
import { ReleasePageOverview, ReleasePageReleaseForm } from './screens';
import styled from '@emotion/styled';
import { ArtistHubIcon } from '@imus/base-ui';

/*
 * TODO:
 *
 * - Move order logic outside of ReleasePageEditor
 * - Refactor type def of ReleasePage to be inside EntityModels
 *
 * ### Standalone editor:
 * - Base validation on whether release is delivered
 * - Handle Presave availability (improve release page context maybe?)
 *
 *
 */

const RELEASE = 'release';
const OVERVIEW = 'overview';
const EDITOR = 'editor';

const ReleasePageManager = () => {
  const { changeStep } = useProductPageActions();
  const { id } = useProductPage();

  const releasePage = useEntryProvider<ReleasePageType>({
    entity: 'releasePages',
    id,
  });

  const releaseId = releasePage?.releaseId;
  const fileUpload = useEntityFileRequestProvider({
    entity: 'releases',
    id: releaseId,
  });
  const { request: updateRequest } = useUpdateEntity({
    entity: 'releases',
    id: releaseId,
  });
  const { entry: release } = useRelease({
    entity: 'releases',
    id: releaseId,
    passive: !releaseId,
  });

  const updating = updateRequest.updating || fileUpload?.uploading;
  const verifying =
    release?.uploadStatus === 'confirmed' ||
    (release?.uploadStatus === 'finished' && !release?.availableCovers?.export);

  const valid =
    release?.uploadStatus === 'finished' &&
    release?.title.length &&
    releasePage?.targets.isReadyToActivate.isMet;

  useEffect(() => {
    changeStep({
      stepId: RELEASE,
      payload: {
        valid,
        updating,
        verifying,
      },
    });
  }, [updating, verifying, valid]);

  return null;
};

const RELEASE_PAGE_STEPS = [
  { id: RELEASE, to: RELEASE, label: 'release' },
  { id: OVERVIEW, to: OVERVIEW, label: 'overview' },
];

const RELEASE_PAGE_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'release-page',
  title: 'release-page',
  initialData: {
    nextProduct: 'md',
  },
  steps: RELEASE_PAGE_STEPS,
  initialStepsState: RELEASE_PAGE_STEPS,
  entity: 'releasePages',
  overviewEntity: 'releasePagesOverview',
  StepperStateManager: ReleasePageManager,
  NavBarRenderer: function NavBarRenderer({ stepId, ...props }) {
    switch (stepId) {
      default:
        return <NavBar {...props} />;
    }
  },
  RedirectManager: function RedirectManager({ match }) {
    return <Redirect to={`${match.url}/${RELEASE}`} />;
  },
  StepRenderer: function StepsRenderer({ stepId, match, ...props }) {
    const id = useProductId();
    const releasePage = useEntryProvider<ReleasePageType>({
      entity: 'releasePages',
      id,
    });
    if (!releasePage) return null;

    const { releaseId } = releasePage;

    switch (stepId) {
      case RELEASE: {
        return <ReleasePageReleaseForm releaseId={releaseId} {...props} />;
      }
      case OVERVIEW: {
        // status previewing is handled inside Delivery
        return (
          <ReleasePageOverview
            editorUrl={`${match.url}/${EDITOR}`}
            legacyPremium={releasePage.isPremium}
            readyToActivate={releasePage.targets.isReadyToActivate.isMet}
            {...props}
          />
        );
      }
      default:
        return <span>{`${stepId} :not-found`}</span>;
    }
  },
};

const noPrice = {
  error: true,
  calculating: false,
  recalculate: () => {
    throw new Error('should not be called');
  },
};

const ThankYouCard = styled(Card)`
  flex-direction: column;
  display: flex;
  padding: 56px 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;
  ${H1} {
    margin-bottom: 16px;
  }
  ${H3} {
    color: rgba(0, 0, 0, 0.5);
  }
  & > svg {
    width: 80px;
    height: 80px;
  }
`;

function ReleasePage({ returnUrl }: { returnUrl: string }): React.ReactElement {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  return (
    <ReleasePageEditor
      id={id}
      onClose={(isActive, releaseId) => {
        history.push(
          isActive
            ? `/library/releases/${releaseId}`
            : returnUrl || `/library/releases/${releaseId}`
        );
      }}
    />
  );
}

export function ReleasePageWizard(props: RouteComponentProps) {
  const { id } = useParams<{ id: string }>();

  const value = useMemo(() => {
    return {
      id,
      ...RELEASE_PAGE_CONTEXT,
    };
  }, [id]);

  const { t } = useTranslation();

  const overviewMatch = useRouteMatch(`${props.match.url}/${OVERVIEW}`);

  const { entry: releasePage } = useReleasePage({
    id,
  });
  const [node, setNode] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    setNode(document.getElementById('next-product-step'));
  });

  const { createEntry: activateReleasePage, request } = useCreateEntity({
    entity: 'releasePageActions',
  });

  const isEditor = useRouteMatch(`${props.match.path}/${EDITOR}`);

  const [showThankYou, setShowThankYou] = useState(
    () => releasePage?.isActive && !isEditor
  );

  if (isEditor) {
    return (
      <ReleasePage {...props} returnUrl={`${props.match.url}/${OVERVIEW}`} />
    );
  }
  const history = useHistory();

  if (!releasePage) {
    return (
      <ProductPageContext.Provider value={value}>
        <ProductPriceProvider>
          <ProductWizard loading={true} {...props} />
        </ProductPriceProvider>
      </ProductPageContext.Provider>
    );
  }

  if (showThankYou) {
    return (
      <PageTemplate title={t('thank-you')}>
        <AutosizeGrid gap={24} maxColumnCount={2} minWidth={480}>
          <ThankYouCard>
            <ArtistHubIcon />
            <div>
              <H1>{t('release-page')}</H1>
              <H3>{t('release-page-generating')}</H3>
            </div>
            <Button
              primary={true}
              size="small"
              style={{ height: '40px' }}
              text={t('return-to-library')}
              onClick={() => {
                history.push(`/library/releases/${releasePage.releaseId}`);
              }}
            ></Button>
          </ThankYouCard>
          <ReleaseGridCard title={t('release-page')}>
            <StaticReleasePageInfo
              releasePageState={{
                isActive: true,
                isPremium: releasePage.isPremium,
                releasePageId: releasePage.id,
                status: releasePage.status,
                url: releasePage.url,
              }}
            />
          </ReleaseGridCard>
          <ProductRating product="release-page" />
        </AutosizeGrid>
      </PageTemplate>
    );
  }

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceContext.Provider value={noPrice}>
        <Switch>
          <Route path={props.match.path}>
            <ProductWizard loading={false} {...props} />
          </Route>
        </Switch>
        {releasePage.status === 'draft' && overviewMatch ? (
          <Portal node={node}>
            <Button
              primary={true}
              disabled={!releasePage.targets.isReadyToActivate.isMet}
              showLoading={request.creating}
              onClick={() => {
                activateReleasePage({
                  id,
                  data: { action: 'activate' },
                });
                setShowThankYou(true);
              }}
              text={t('activate')}
            />
          </Portal>
        ) : overviewMatch ? (
          <Portal node={node}>
            <LinkButton
              primary={true}
              to={`/library/releases/${releasePage.releaseId}`}
              text={t('return-to-library')}
            />
          </Portal>
        ) : null}
      </ProductPriceContext.Provider>
    </ProductPageContext.Provider>
  );
}
