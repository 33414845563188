import { useQuery, useEntries, useCreateEntity } from 'imddata';
import { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type { WrappedFieldProps } from 'redux-form';
import { change } from 'redux-form';
import type { ArtistGalleryNested } from '../useAritstPageForm';
import { nestGalleryFiles, mapGalleryImage } from '../useAritstPageForm';
import { ArtistIdContext } from './ArtistImagesField';

export const UsedGalleryIds = createContext<string[] | undefined>([]);

export const GalleryIdField = ({ input, meta }: WrappedFieldProps) => {
  const artistId = useContext(ArtistIdContext);
  const dispatch = useDispatch();
  const galleryIds = useContext(UsedGalleryIds);
  const { query, queryHash } = useQuery({
    query: { 'filter.artists': artistId, with: 'images' },
  });
  const {
    entries: galleries,
    request: { loaded, failed },
    nestLoadFinshed,
  } = useEntries<ArtistGalleryNested>({
    nest: nestGalleryFiles,
    entity: 'artistGalleries',
    query,
    queryHash,
  });
  const {
    createEntry: createGallery,
    request: { id, failed: failedToCreate },
  } = useCreateEntity({
    entity: 'artistGalleries',
  });
  console.log('1', galleries);
  useEffect(() => {
    if (!input.value && ((loaded && nestLoadFinshed) || failed)) {
      const emptyGallery = galleries.find((g) => g.images?.length === 0);

      const unusedGallery = galleryIds
        ? galleries.find((g) => galleryIds.indexOf(g.id) < 0)
        : null;
      if (unusedGallery) {
        input.onChange(unusedGallery.id);
        console.log(unusedGallery);
        dispatch(
          change(
            meta.form,
            'data.gallery',
            unusedGallery.images.map(mapGalleryImage)
          )
        );
      } else if (emptyGallery) {
        input.onChange(emptyGallery.id);
      } else {
        console.log('creating gallery');
        createGallery({ data: { artistId } });
      }
    }
  }, [loaded, artistId, galleryIds?.length, nestLoadFinshed]);

  useEffect(() => {
    if (id) {
      input.onChange(id);
    }
    if (failedToCreate) {
      input.onChange(null);
    }
  }, [id, failedToCreate]);
  return null;
};
