import React, {
  useRef,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import config from 'imdconfig';
import { useTranslation } from 'react-i18next';
import { EnabledField, useCurrentLocale } from 'imdshared';
import {
  createInputWithStyles,
  Content,
  themeLight,
  NewInput,
  LoadingIndicator,
} from 'imdui';
import MaskedInput from 'react-text-mask';
import styled from '@emotion/styled';
import { CustomerPaymentMethod, getBrandIconUrl } from 'components';
import type { EntityModels } from 'imddata';
import type { FieldProps } from './SecuredFormField';
import SecuredFormField, {
  HostedField,
  HelperTextStyled,
  StyledOverlineText,
} from './SecuredFormField';
import { NewPaymentOption, PaymentOption } from '../PaymentOption';
import * as cpf from './cpf';
import type { GatewayProps } from '../types';

const SecuredFormFieldStyled = styled(SecuredFormField)``;
const Row = styled.div`
  ${SecuredFormFieldStyled} {
    margin-bottom: 0px;
    &:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

const CardForm = styled.div<{ isHostedFieldsReady?: boolean }>`
  position: relative;
  filter: ${({ isHostedFieldsReady }) =>
    !isHostedFieldsReady ? 'blur(2px)' : 'none'};
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 100000;
`;

const GatewayStyled = styled.form<{ displayAsForm: boolean; checked: boolean }>`
  display: ${({ checked }) => (checked ? 'block' : 'none')};

  ${({ displayAsForm }) =>
    displayAsForm
      ? `
padding: 16px;
border-top: 1px solid rgba(0,0,0,0.1);
  ${Row} {
  display:  flex;
  margin-bottom: 16px;
    ${SecuredFormFieldStyled} {
      margin-bottom: 0;
    }
  }
  ${SecuredFormFieldStyled} {
    flex: 1;
    margin-bottom: 16px;
  }
    `
      : `

    height: 48px;
    ${CardForm} {
      height: 24px;
      align-items: center;
      display: inline-flex;
    }
  & > * {
    flex-direction: row-reverse;
    gap: 16px;
    display: flex;
    align-items: center;
  }
  ${HelperTextStyled},
  ${StyledOverlineText} {
    display: none;
  }
  ${HostedField},
  ${SecuredFormFieldStyled} {
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    box-shadow: none;
    background: transparent;
    pointer-events: none;
    height: 16px;
    display: inline-flex;
    align-items: center;
    position: relative;
    flex-grow: 0;
    & > div {
      width: auto;
      min-height: 0;
      line-height: 16px;
    }
  }
  ${Row} {
    display: inline-flex;
    margin-bottom: 0;
  }
  `};
`;

const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const StyledMaskedInput = createInputWithStyles(MaskedInput);

type SocialSecurityInputProps = Parameters<typeof NewInput>[0];

const renderSocialSecurityInput: SocialSecurityInputProps['renderInput'] = (
  props
) => (
  // @ts-ignore
  <StyledMaskedInput
    {...props}
    guide={false}
    placeholder="123.456.789-09"
    mask={CPF_MASK}
  />
);

const SocialSecurityInput = (props: SocialSecurityInputProps) => {
  return <NewInput {...props} renderInput={renderSocialSecurityInput} />;
};

type State = {
  values: {
    storePaymentMethod: boolean;
    encryptedCardNumber?: string;
    encryptedSecurityCode?: string;
    encryptedExpiryDate?: string;
    socialSecurityNumber?: string;
    holderName?: string;
  };
  isFormValid: boolean;
  isHostedFieldsReady: boolean;
  brand: undefined | string;
} & Record<FieldType, FieldProps>;

const getInitialCardState = (formData: {
  enforceStorePaymentMethod?: boolean;
  originKey?: string;
  allowSocialSecurityNumber?: boolean;
}): State => ({
  isHostedFieldsReady: false,
  isFormValid: false,
  holderName: { isValid: false },
  storePaymentMethod: { isValid: true },
  encryptedCardNumber: {},
  encryptedSecurityCode: {},
  encryptedExpiryDate: {},
  socialSecurityNumber: { isValid: true },
  brand: undefined,
  values: { storePaymentMethod: !!formData.enforceStorePaymentMethod },
});

const CHANGE_ENCRYPTED_FIELD = 'CHANGE_ENCRYPTED_FIELD';
const FOCUS_FIELD = 'FOCUS_FIELD';
const VALIDATE_FIELD = 'VALIDATE_FIELD';
const CHANGE_SOCIAL_SECURITY = 'CHANGE_SOCIAL_SECURITY';
const CHANGE_HOLDER_NAME = 'CHANGE_HOLDER_NAME';
const CHANGE_STORE_METHOD = 'CHANGE_STORE_METHOD';
const FIELDS_READY = 'FIELDS_READY';
const BRAND_DETECTED = 'BRAND_DETECTED';
// const AUTOCOMPLETE_ENCRYPTED_FIELDS = 'AUTOCOMPLETE_ENCRYPTED_FIELDS';

type FieldType =
  | 'holderName'
  | 'encryptedExpiryDate'
  | 'encryptedSecurityCode'
  | 'encryptedCardNumber'
  | 'storePaymentMethod'
  | 'socialSecurityNumber';

type FocusPayload = {
  focus: boolean;
  fieldType: FieldType;
};
type ValidatePayload = {
  errorI18n: string;
  error: string;
  fieldType: FieldType;
};

type Action =
  | {
      type: typeof CHANGE_ENCRYPTED_FIELD;
      payload: {
        isFormValid: boolean;
        values: Record<string, string>;
      };
    }
  | {
      type: typeof FIELDS_READY;
      payload: {
        isHostedFieldsReady: boolean;
      };
    }
  | {
      type: typeof FOCUS_FIELD;
      payload: FocusPayload;
    }
  | {
      type: typeof VALIDATE_FIELD;
      payload: ValidatePayload;
    }
  | {
      type: typeof CHANGE_STORE_METHOD;
      payload: {
        value: boolean;
      };
    }
  | {
      type: typeof BRAND_DETECTED;
      payload: {
        brand: string;
      };
    }
  | {
      type: typeof CHANGE_HOLDER_NAME;
      payload: {
        value: string;
      };
    }
  | {
      type: typeof CHANGE_SOCIAL_SECURITY;
      payload: {
        value: string;
      };
    };

type Reducer = (s: State, a: Action) => State;

const cardReducer: Reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_ENCRYPTED_FIELD: {
      const {
        payload: { isFormValid, values },
      } = action;
      return {
        ...state,
        values: { ...state.values, ...values },
        isFormValid,
      };
    }
    case BRAND_DETECTED: {
      return {
        ...state,
        brand: action.payload.brand,
      };
    }
    case FIELDS_READY: {
      return {
        ...state,
        isHostedFieldsReady: action.payload.isHostedFieldsReady,
      };
    }
    case FOCUS_FIELD: {
      const {
        payload: { focus, fieldType },
      } = action;
      return {
        ...state,
        [fieldType]: {
          ...state[fieldType],
          isFocused: focus,
          blurred: state[fieldType].isFocused && !focus,
        },
      };
    }
    case VALIDATE_FIELD: {
      const {
        payload: { errorI18n, error, fieldType },
      } = action;
      return {
        ...state,
        [fieldType]: {
          ...state[fieldType],
          hasError: !!error,
          error: errorI18n,
        },
      };
    }
    case CHANGE_STORE_METHOD: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        values: {
          ...state.values,
          storePaymentMethod: value,
        },
      };
    }
    case CHANGE_HOLDER_NAME: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        holderName: {
          ...state.holderName,
          isValid: !!value,
        },
        values: {
          ...state.values,
          holderName: value,
        },
      };
    }
    case CHANGE_SOCIAL_SECURITY: {
      const {
        payload: { value },
      } = action;
      return {
        ...state,
        socialSecurityNumber: {
          ...state.socialSecurityNumber,
          isValid: value ? cpf.isValid(value) : true,
        },
        values: {
          ...state.values,
          socialSecurityNumber: value,
        },
      };
    }
    default:
      return state;
  }
};

const smallStyled = {
  base: {
    color: themeLight.foreground.primary,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
  error: {
    color: themeLight.foreground.primary,
  },
  placeholder: {
    fontWeight: '400',
    color: themeLight.foreground.disabled,
  },
  validated: {
    color: themeLight.foreground.primary,
  },
};

const checkoutStyles = {
  base: {
    color: themeLight.foreground.primary,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
  error: {
    color: themeLight.foreground.primary,
  },
  placeholder: {
    fontWeight: '400',
    color: themeLight.foreground.disabled,
  },
  validated: {
    color: themeLight.foreground.primary,
  },
};

type Props = {
  checked: boolean;
  displayAsForm: boolean;
  formData: {
    enforceStorePaymentMethod?: boolean;
    originKey?: string;
    allowSocialSecurityNumber?: boolean;
  };
  onPaymentChange: GatewayProps['onPaymentChange'];
};

function AdyenCardForm({
  checked,
  displayAsForm,
  onPaymentChange,
  formData,
}: Props): React.ReactElement {
  const clientKey = config?.imdfront?.publicPaymentKey || formData.originKey;
  const { enforceStorePaymentMethod } = formData;
  const displaySocialSecurityNumber = formData.allowSocialSecurityNumber;

  const locale = useCurrentLocale();
  const { t } = useTranslation();
  const rootNode = useRef<HTMLFormElement>(null);
  const initialCardState = useMemo(
    () => getInitialCardState(formData),
    [formData]
  );
  const [
    {
      brand,
      holderName,
      socialSecurityNumber,
      isHostedFieldsReady,
      isFormValid,
      values,
      encryptedSecurityCode,
      encryptedExpiryDate,
      encryptedCardNumber,
    },
    dispatchCardEvent,
  ] = useReducer<Reducer>(cardReducer, initialCardState);

  const handleChangeHolderNameField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_HOLDER_NAME,
        payload: { value: event.target.value },
      });
    },
    []
  );

  const handleFocusHolderNameField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'holderName', focus: true },
    });
  }, []);

  const handleBlurHolderNameField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'holderName', focus: false },
    });
  }, []);

  const handleChangeSocialSecurityField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchCardEvent({
        type: CHANGE_SOCIAL_SECURITY,
        payload: { value: event.target.value },
      });
    },
    []
  );

  const handleFocusSocialSecurityField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'socialSecurityNumber', focus: true },
    });
  }, []);

  const handleBlurSocialSecurityField = useCallback(() => {
    dispatchCardEvent({
      type: FOCUS_FIELD,
      payload: { fieldType: 'socialSecurityNumber', focus: false },
    });
  }, []);

  const checkout = useMemo(() => {
    return new AdyenCheckout({
      styles: checkoutStyles,
      locale,
      environment: config.paymentEnv,
      clientKey,
      onChange: (payload: {
        isValid: boolean;
        data: { paymentMethod: any };
      }) => {
        const {
          isValid,
          data: {
            paymentMethod: { type, ...encryptedValues },
          },
        } = payload;
        dispatchCardEvent({
          type: CHANGE_ENCRYPTED_FIELD,
          payload: { values: encryptedValues, isFormValid: isValid },
        });
      },
      onConfigSuccess: ({
        iframesConfigured,
      }: {
        iframesConfigured: boolean;
      }) => {
        dispatchCardEvent({
          type: 'FIELDS_READY',
          payload: { isHostedFieldsReady: iframesConfigured },
        });
      },
      onError: (payload: ValidatePayload) =>
        dispatchCardEvent({ type: VALIDATE_FIELD, payload }),
      onFocus: (payload: FocusPayload) =>
        dispatchCardEvent({ type: FOCUS_FIELD, payload }),
      onBrand: (payload: { brand: string }) => {
        dispatchCardEvent({
          type: BRAND_DETECTED,
          payload: { brand: payload.brand },
        });
      },
      // onLoad: console.log,
      // onAutoComplete: console.log,
    });
  }, []);

  const mountedNode = useRef<null | unknown>(null);

  useEffect(() => {
    if (rootNode.current) {
      mountedNode.current = checkout
        .create('securedfields')
        .mount(rootNode.current);
    } else {
      throw new Error('No node found for credit card form');
    }
    return () => {
      checkout.remove(mountedNode.current);
      mountedNode.current = null;
    };
  }, []);

  const paymentNotReady =
    !socialSecurityNumber.isValid ||
    !isFormValid ||
    !isHostedFieldsReady ||
    !holderName.isValid;

  const handleChange = useCallback(() => {
    onPaymentChange({
      id: 'new-card',
      paymentMethod: 'card',
      isReady: !paymentNotReady,
      data: {
        paymentMethodId: 'card',
        data: values.socialSecurityNumber
          ? {
              ...values,
              socialSecurityNumber: cpf.strip(values.socialSecurityNumber),
            }
          : values,
      },
    });
  }, [paymentNotReady, values]);

  useEffect(() => {
    if (checked) handleChange();
  }, [paymentNotReady, values]);

  useEffect(() => {
    if (!displayAsForm && isHostedFieldsReady) {
      checkout.components[0].updateStyles(smallStyled);
    } else {
      checkout.components[0].updateStyles(checkoutStyles);
    }
  }, [displayAsForm, isHostedFieldsReady]);

  return (
    <NewPaymentOption selected={displayAsForm && checked}>
      {displayAsForm && (
        <PaymentOption onCheck={handleChange}>
          <CustomerPaymentMethod
            scheme="new-card"
            description={t('add-new-card')}
            data={{
              name: brand,
            }}
          />
        </PaymentOption>
      )}
      <GatewayStyled
        displayAsForm={displayAsForm}
        checked={checked}
        ref={rootNode}
        key="form"
        id="adyen-card"
      >
        <div>
          <div>
            {!displayAsForm && checked ? (
              <Content style={{ height: '24px', display: 'inline-block' }}>
                {t('add-new-card')}
              </Content>
            ) : null}
            {!isHostedFieldsReady && (
              <LoadingOverlay>
                <LoadingIndicator size="large" />
              </LoadingOverlay>
            )}
            <CardForm
              isHostedFieldsReady={isHostedFieldsReady}
              data-test-id={`HostedFields-${
                isHostedFieldsReady ? 'ready' : 'initializing'
              }`}
              className=".js-chckt-pm__pm-holder"
            >
              <input type="hidden" name="txvariant" value="card" />
              <SecuredFormFieldStyled
                style={{ minWidth: '120px' }}
                id="encryptedCardNumber"
                label={t('card-number')}
                field={encryptedCardNumber}
              />
              <Row>
                <SecuredFormFieldStyled
                  id="encryptedExpiryDate"
                  label={t('expiration-date')}
                  field={encryptedExpiryDate}
                />
                <SecuredFormFieldStyled
                  style={{ display: displayAsForm ? 'block' : 'none' }}
                  id="encryptedSecurityCode"
                  label={t('cvv')}
                  field={encryptedSecurityCode}
                />
              </Row>

              {displayAsForm ? (
                <NewInput
                  name="holderName"
                  testId="holderName"
                  style={{ marginBottom: '24px' }}
                  onBlur={handleBlurHolderNameField}
                  onFocus={handleFocusHolderNameField}
                  errorText={holderName.blurred && !holderName.isValid}
                  label={
                    checkout.modules.i18n.translations['creditCard.holderName']
                  }
                  placeholder={
                    checkout.modules.i18n.translations[
                      'creditCard.holderName.placeholder'
                    ]
                  }
                  value={values.holderName}
                  onChange={handleChangeHolderNameField}
                />
              ) : null}
              {displaySocialSecurityNumber && displayAsForm && (
                <SocialSecurityInput
                  onBlur={handleBlurSocialSecurityField}
                  onFocus={handleFocusSocialSecurityField}
                  errorText={
                    socialSecurityNumber.blurred &&
                    !socialSecurityNumber.isValid
                  }
                  helperText={t('social-security-number-helpertext-short')}
                  label={t('social-security-number')}
                  value={values.socialSecurityNumber}
                  onChange={handleChangeSocialSecurityField}
                />
              )}
              {displayAsForm && !enforceStorePaymentMethod && (
                <EnabledField
                  // @ts-ignore
                  meta={{}}
                  name="save-method"
                  label={t('save-payment-method')}
                  // @ts-ignore
                  input={{
                    value: values.storePaymentMethod,
                    onChange: (value) => {
                      dispatchCardEvent({
                        type: CHANGE_STORE_METHOD,
                        payload: {
                          value,
                        },
                      });
                    },
                  }}
                />
              )}
            </CardForm>
          </div>
          {!displayAsForm && checked ? (
            <img src={getBrandIconUrl(brand)} alt={brand} height={32} />
          ) : null}
        </div>
      </GatewayStyled>
    </NewPaymentOption>
  );
}

export default function AdyenCardGateway({
  paymentData,
  selectionActive,
  onPaymentChange,
  formData,
  customerPaymentMethods,
}: GatewayProps) {
  if (!formData?.originKey) return null;

  const activeEntry =
    !selectionActive &&
    paymentData.id &&
    paymentData.id !== 'new-card' &&
    customerPaymentMethods?.find(
      (v): v is EntityModels.CustomerPaymentMethodCard =>
        v.id === paymentData.id && v.paymentMethodId === 'card'
    );

  return (
    <>
      {selectionActive &&
        customerPaymentMethods
          ?.filter(
            (v): v is EntityModels.CustomerPaymentMethodCard =>
              v.paymentMethodId === 'card'
          )
          .map((data) => (
            <PaymentOption
              key={data.id}
              checked={paymentData.id === data.id}
              onCheck={() =>
                onPaymentChange({
                  paymentMethod: 'card',
                  id: data.id,
                  isReady: true,
                  data: { customerPaymentMethodId: data.id },
                })
              }
            >
              <CustomerPaymentMethod scheme="card" data={data.data} />
            </PaymentOption>
          ))}
      {activeEntry ? (
        <CustomerPaymentMethod scheme="card" data={activeEntry.data} />
      ) : null}
      <AdyenCardForm
        checked={!paymentData.id || paymentData.id === 'new-card'}
        displayAsForm={selectionActive}
        onPaymentChange={onPaymentChange}
        formData={formData}
      />
    </>
  );
}
