import React, { useRef, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { map } from 'ramda';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import type { EntityModels, OrdersOverviewNested } from 'imddata';
import { useEntries } from 'imddata';
import { RadioButton, Caption, Dropdown, Icons, Button } from 'imdshared';
import AddVoucherForm from '../AddVoucherForm';

// ???
// @ts-ignore
momentDurationFormatSetup(moment);

const DropdownCaption = styled(Caption)`
  text-transform: uppercase;
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-left: 12px;
`;

const VoucherStyled = styled(Button) <{ selected?: boolean }>`
  margin-bottom: 8px;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  box-shadow: ${({ selected, theme }) =>
    selected
      ? ` 0 0 0 2px ${theme.accent.orange};`
      : '0 0 0 1px rgba(0,0,0,0.1)'};
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const VoucherDataStyled = styled.div`
  text-align: left;
  width: 100%;
  padding: 12px;
`;
const VoucherMetaStyled = styled.div`
  margin-top: 4px;
  display: flex;
`;
const VoucherExpireStyled = styled(Caption)`
  margin-left: auto;
`;
const RadioButtonStyled = styled(RadioButton)`
  margin-left: 12px;
`;

type Selection = {
  voucherId: number;
  customerVoucherId: number;
};

function Voucher({
  name,
  selected,
  disabled,
  wasUsed,
  discount,
  code,
  expiresAt,
  onClick,
  voucherId,
  customerVoucherId,
}: VoucherType & {
  selected: boolean;
  onClick: (v: Selection) => void;
}) {
  const { t } = useTranslation();
  const handleOnClick = useCallback(() => {
    if (onClick) onClick({ customerVoucherId, voucherId });
  }, [voucherId, customerVoucherId, onClick]);
  const duration = moment.duration(moment(expiresAt).diff(moment())).format();

  return (
    <VoucherStyled
      onClick={handleOnClick}
      disabled={disabled}
      selected={selected}
    >
      <RadioButtonStyled selected={selected} disabled={disabled} />
      <VoucherDataStyled>
        <div>{name}</div>
        <VoucherMetaStyled>
          <Caption>
            {code} ({discount})
          </Caption>
          {expiresAt && (
            <VoucherExpireStyled>
              {t('expires-in')} {duration}
            </VoucherExpireStyled>
          )}
        </VoucherMetaStyled>

        {disabled ? (
          <div style={{ marginTop: '4px' }}>
            <Caption>{t('voucher-not-applicable')}</Caption>
          </div>
        ) : null}
        {wasUsed ? (
          <div style={{ marginTop: '4px' }}>
            <Caption>{t('voucher-will-be-applied-from-other-order')}</Caption>
          </div>
        ) : null}
      </VoucherDataStyled>
    </VoucherStyled>
  );
}

const VoucherListWrapper = styled.div``;

const VoucherListOverflow = styled.div`
  padding: 8px;
  max-height: 300px;
  overflow: auto;
`;
function VoucherList({
  vouchers,
  onChange,
  value,
}: {
  vouchers: VoucherType[];
  onChange: (v: Selection) => void;
  value: Selection | null;
}) {
  const { t } = useTranslation();
  return (
    <VoucherListWrapper>
      <DropdownCaption>{t('select-voucher')}</DropdownCaption>
      <VoucherListOverflow>
        {vouchers.map((voucher) => (
          <Voucher
            {...voucher}
            key={voucher.customerVoucherId}
            selected={value ? value.voucherId === voucher.voucherId : false}
            onClick={onChange}
          />
        ))}
      </VoucherListOverflow>
    </VoucherListWrapper>
  );
}

const DropdownFooter = styled.div`
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  background-color: #f7f7f7;
  padding: 8px;
`;

const FooterButton = styled(Button)`
  flex: 1;
  justify-content: center;
  &:first-of-type {
    margin-right: 8px;
  }
`;

function VoucherDropdown({
  onRequestClose,
  onRegisterVoucher,
  vouchers,
  onChange,
}: {
  onRequestClose: () => void;
  onRegisterVoucher: (v: EntityModels.CustomerVoucher) => void;
  vouchers: VoucherType[];
  onChange: (v: Selection | null) => void;
}) {
  const { t } = useTranslation();
  const [selectedVoucher, setSelectedVoucher] = useState<null | Selection>(
    null
  );
  const handleApplyVoucher = useCallback(() => {
    onChange(selectedVoucher);
  }, [onChange, selectedVoucher]);

  return (
    <>
      <AddVoucherForm onRegisterVoucher={onRegisterVoucher} />
      <VoucherList
        vouchers={vouchers}
        value={selectedVoucher}
        onChange={setSelectedVoucher}
      />
      <DropdownFooter>
        <FooterButton text={t('cancel')} onClick={onRequestClose} />
        <FooterButton
          disabled={!selectedVoucher}
          primary={true}
          text={t('apply-voucher')}
          onClick={handleApplyVoucher}
        />
      </DropdownFooter>
    </>
  );
}

const ICON_PROPS = { viewBox: '0 0 24 24', size: 20 };

function AddSelectButton(props: React.ComponentProps<typeof Button>) {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      text={t('add-select-voucher')}
      icon={Icons.categories.voucher}
      iconProps={ICON_PROPS}
    />
  );
}

type VoucherType = {
  // value: number;
  name: string;
  code: string;
  disabled: boolean;
  wasUsed: boolean;
  discount: string;
  expiresAt: string;
  voucherId: number;
  customerVoucherId: number;
};

export default function VoucherDropdownButton({
  overview,
  onChange,
  autoFocusDropdown = false,
  onRegisterVoucher,
}: {
  autoFocusDropdown?: boolean;
  overview: OrdersOverviewNested;
  onChange: (v: Selection | null) => void;
  onRegisterVoucher: (v: EntityModels.CustomerVoucher) => void;
}) {
  const anchor = useRef<HTMLDivElement>();
  const [dropdownOpened, setDropdownOpened] = useState(() => autoFocusDropdown);
  const closeDropdown = useCallback(() => setDropdownOpened(false), []);
  const openDropdown = useCallback(() => setDropdownOpened(true), []);

  const { entries } = useEntries<EntityModels.CustomerVoucher>({
    entity: 'customerVouchers',
  });
  const { availableVouchers } = overview;

  // All vouchers for selection
  const vouchers = useMemo<VoucherType[]>(
    () =>
      map((customerVoucher) => {
        const availableVoucher = availableVouchers?.find(
          ({ voucherId }) => voucherId === customerVoucher.voucherId
        );

        // voucher can be used once and should apply to one order at least to be selected
        const disabled =
          !availableVoucher ||
          !availableVoucher.orders.find(
            (orderVoucher) => orderVoucher && orderVoucher.applies
          );

        return {
          disabled,
          wasUsed: !!availableVoucher?.wasUsed,
          // value: customerVoucher.voucherId,
          customerVoucherId: customerVoucher.id,
          voucherId: customerVoucher.voucherId,
          code: customerVoucher.code,
          discount: customerVoucher.discount,
          expiresAt: customerVoucher.expiresAt,
          name: customerVoucher.name,
        };
      }, entries),
    [overview, entries]
  );

  const handleSelect = useCallback(
    (voucherId) => {
      if (onChange) {
        onChange(voucherId);
        closeDropdown();
      }
    },
    [onChange]
  );

  const handleRegisterVoucher = useCallback(
    (voucher) => {
      if (onRegisterVoucher) {
        onRegisterVoucher(voucher);
        closeDropdown();
      }
    },
    [onRegisterVoucher]
  );
  return (
    <div
      // @ts-ignore
      ref={anchor}
    >
      <AddSelectButton onClick={openDropdown} />

      {dropdownOpened && (
        <Dropdown
          // @ts-ignore
          anchorEl={anchor}
          fullWidth={false}
          maxWidthFix={true}
          onClickOutside={closeDropdown}
        >
          {() => (
            <VoucherDropdown
              onRegisterVoucher={handleRegisterVoucher}
              onRequestClose={closeDropdown}
              vouchers={vouchers}
              onChange={handleSelect}
            />
          )}
        </Dropdown>
      )}
    </div>
  );
}
