import React, { useContext, useMemo } from 'react';
import { DataContext, buildChartTheme, DataProvider } from '@visx/xychart';
import { LegendItem } from './LegendItem';
import { ChartHeader } from './ChartHeader';
import type { Props } from './LineChart';
import LineChart, { defaultConfig, disabledConfig } from './LineChart';
import { lineColors } from './styles';

const theme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  colors: lineColors,
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  tickLength: 2,
});

const disabledTheme = buildChartTheme({
  backgroundColor: '#f2f2f280',
  colors: ['#ccc'],
  gridColor: '#ccc',
  gridColorDark: '#ccc',
  tickLength: 2,
});

type TrendProps = Props & { title: string; tabs: React.ReactNodeArray };

const ChartWithLegend = ({
  disabled,
  tabs,
  title,
  selected,
  data,
}: TrendProps) => {
  const { colorScale } = useContext(DataContext);
  const legend = useMemo(
    () =>
      Object.keys(data || {})?.map((id) => (
        <LegendItem
          selected={selected === id}
          type="line"
          key={id}
          fill={colorScale?.(id)}
          label={data?.[id]?.[0]?.label || id}
        />
      )) || null,
    [data, colorScale, selected]
  );
  return (
    <>
      <ChartHeader
        tabs={tabs}
        title={title}
        legend={!disabled ? legend : null}
      />
      <LineChart
        disabled={disabled}
        verticalTicksNum={8}
        selected={selected}
        data={data}
      />
    </>
  );
};

export const TrendChart = (props: TrendProps) => (
  <DataProvider
    xScale={props.disabled ? disabledConfig.x : defaultConfig.x}
    yScale={props.disabled ? disabledConfig.y : defaultConfig.y}
    theme={props.disabled ? disabledTheme : theme}
  >
    <ChartWithLegend {...props} />
  </DataProvider>
);
