/* eslint-disable react/display-name */

import React, { useMemo, useCallback, Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCurrentUser,
  useCurrentCustomer,
  useEntityActions,
  useCustomerFeatureRollout,
} from 'imddata';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm, Field } from 'redux-form';
import { NewInputField, FormToolbar } from 'imdshared';
import { Card, Grid, HelpWindowContext } from 'imdui';
import { CountrySelectorField, ReturnNavigation } from 'components';
import styled from '@emotion/styled';
import { ProfileFormButtons, DeleteAccountButton } from './components';
import type { FormData } from './validate';
import { validateUserFields } from './validate';

const ColumnsWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;

  @media (min-width: 1024px) {
    flex-direction: row;

    > section {
      flex: 1;
    }

    > section + section {
      margin-left: 24px;
    }
  }
`;

const ProfileCard = styled(Card)`
  grid-column: 1 / -1;
  padding: 24px 0 0 0;
  @media (min-width: 1280px) {
    grid-column: span 3;
  }
`;

type Props = {
  customerId: number;
  userId: number;
  onSubmit: any;
};

const ProfileFormBase: React.FC<
  Props & InjectedFormProps<FormData, Props, string>
> = ({
  pristine,
  onSubmit,
  reset,
  handleSubmit,
  submitSucceeded,
  submitting,
}) => {
  const { t } = useTranslation();

  const support = useCustomerFeatureRollout({
    feature: 'customer-support',
    fallback: true,
    rolloutKey: 'tiered-subs',
  });

  const supportFaster = useCustomerFeatureRollout({
    feature: 'customer-support-faster',
    fallback: true,
    rolloutKey: 'tiered-subs',
  });

  const openHelpWindow = useContext(HelpWindowContext);

  return (
    <Grid columns={4}>
      <ProfileCard>
        <form data-test-id="ProfileForm" onSubmit={handleSubmit(onSubmit)}>
          <ColumnsWrapper>
            <section>
              <Field
                name="name"
                type="text"
                label={t('company-name')}
                component={NewInputField}
              />
              <Field
                name="vatNumber"
                type="text"
                label={t('vat-number')}
                component={NewInputField}
              />

              <Field
                name="email"
                type="email"
                // @ts-ignore
                label={t('email')}
                disabled={true}
                component={NewInputField}
                onClickHelp={() => {
                  openHelpWindow(
                    t('email'),
                    t('customer-email-helper-text', {
                      context:
                        support || supportFaster
                          ? 'with-support-feature'
                          : undefined,
                    })
                  );
                }}
              />

              <Field
                name="firstName"
                type="text"
                label={t('first-name')}
                component={NewInputField}
              />

              <Field
                name="lastName"
                type="text"
                label={t('last-name')}
                component={NewInputField}
              />
            </section>

            <section>
              <Field
                name="city"
                type="text"
                label={t('city')}
                component={NewInputField}
              />
              <Field
                name="address"
                type="text"
                label={t('address-line-1')}
                placeholder={t('address-line-1-placeholder')}
                component={NewInputField}
              />

              <Field
                name="address2"
                type="text"
                label={t('address-line-2')}
                placeholder={t('address-line-2-placeholder')}
                component={NewInputField}
              />
              <Field
                name="zip"
                type="text"
                label={t('zip')}
                component={NewInputField}
              />

              <Field
                name="addressCountryId"
                testId="country"
                type="text"
                floatingLabelText={t('country')}
                acceptValidation={false}
                component={CountrySelectorField}
                onClickHelp={() => {
                  openHelpWindow(
                    t('country'),
                    t('customer-address-country-helper-text')
                  );
                }}
              />
            </section>
          </ColumnsWrapper>

          <FormToolbar
            leftChildren={<DeleteAccountButton />}
            rightChildren={
              <ProfileFormButtons
                label={t('update')}
                reset={reset}
                pristine={pristine}
                submitting={submitting}
                submitted={submitSucceeded}
              />
            }
          />
        </form>
      </ProfileCard>
    </Grid>
  );
};

const ProfileForm = reduxForm<FormData, Props, string>({
  submitAsSideEffect: true,
  // @ts-ignore
  validate: (values: FormData) => ({
    customer: validateUserFields(values),
  }),
})(ProfileFormBase);

const formId = 'userAccount';

const CustomerProfileEdit: React.FC = () => {
  const { update: updateCustomers } = useEntityActions('customers');
  const { update: updateUsers } = useEntityActions('users');
  const { entry: user } = useCurrentUser();
  const { entry: customer } = useCurrentCustomer();
  const submit = useCallback(
    ({ id: customerId, countryId, email, ...customerValues }) => {
      if (user) {
        updateUsers({
          id: user.id,
          formId,
          data: {
            firstName: customerValues && customerValues.firstName,
            lastName: customerValues && customerValues.lastName,
          },
        });
        updateCustomers({
          id: customerId,
          formId,
          data: customerValues,
        });
      }
    },
    []
  );

  const initialValues = useMemo<Partial<FormData>>(
    () =>
      user && customer
        ? {
            id: customer.id,
            email: user.email,
            name: customer.name,
            vatNumber: customer.vatNumber,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address: customer.address,
            address2: customer.address2,
            city: customer.city,
            zip: customer.zip,
            countryId: customer.countryId,
            addressCountryId: customer.addressCountryId,
            cashCreditAutoDeduction: customer.cashCreditAutoDeduction,
          }
        : {},
    [customer]
  );
  const { t } = useTranslation();

  if (!customer || !user) return null;

  return (
    <>
      <ReturnNavigation text={t('profile-details')} to="/account" />
      <ProfileForm
        customerId={customer.id}
        userId={user.id}
        form={formId}
        onSubmit={submit}
        initialValues={initialValues}
      />
    </>
  );
};

export default CustomerProfileEdit;
