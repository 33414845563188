import styled from '@emotion/styled';
import { Clickable } from 'imdui';
import { css } from '@emotion/react';

export const buttonStyle = css`
  aspect-ratio: 1 / 1;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
`;

export const Placeholder = styled.div`
  ${buttonStyle}
  background: rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 100%;
`;

export const ClickableCover = styled(Clickable)<{
  image: string;
  selected?: boolean;
}>`
  background-image: url(${({ image }) => image});
  ${buttonStyle}
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;
  align-items: center;
`;
