import styled from '@emotion/styled';
import { Caption, Content } from '../../text';
import { ClickableButton } from '../button';

export const ActionGrid = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @media screen and (min-width: 960px) {
    grid-template-columns: 1fr minmax(196px, auto);
    grid-auto-flow: row;
  }
  ${Caption} {
    color: rgba(0, 0, 0, 0.5);
  }
  ${ClickableButton} {
    width: 100%;
  }
  & > div > ${Content} {
    display: block;
    margin-bottom: 4px;
  }

  & > div > ${Content}+* {
    margin-bottom: 4px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
