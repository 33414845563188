import React, { useCallback, useEffect, useMemo, useContext } from 'react';
import { decamelize } from 'humps';
import type { WrappedFieldArrayProps } from 'redux-form';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import {
  FieldGroup,
  Body,
  Caption,
  Card,
  Button,
  AlertBox,
  IconButton,
  Icon,
  Icons,
} from 'imdui';
import { uniq } from 'ramda';
import type { FieldUpdateRequestResourceType } from 'imddata';
import { EntityFormContext, FieldUpdateRequestContext } from 'imddata';
import styled from '@emotion/styled';
import { useDrag, useDrop } from 'react-dnd';
import DragHandler from '../../Next/DragHandler';
import { NewInputField } from '../InputField';

const FeedbackCard = styled(Card)`
  margin: 0 0 8px 0;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.lights[2]};

  &:hover {
    box-shadow:
      0 1px 3px -1px rgba(0, 0, 0, 0.1),
      inset 0 0 0 2px ${({ theme }) => theme.lights[4]};
  }
`;

const InputWrapper = styled(FieldGroup)<{ hovered: boolean }>`
  background-color: ${({ hovered }) => (hovered ? 'rgba(0,0,0,0.16)' : '#fff')};
`;
const InputRow = styled.div`
  display: flex;
  align-items: center;
`;
const DragHandlerStyled = styled(DragHandler)`
  flex: 0;
  flex-grow: 0;
  margin-left: auto;
`;

type Changes =
  | React.ContextType<typeof FieldUpdateRequestContext>['changes']
  | null;

export const findChangeEntry = (
  changes: Changes,
  resourceType: FieldUpdateRequestResourceType,
  resourceId: number,
  fieldName: string
) => {
  return changes?.[resourceType]?.[resourceId]?.find(
    (change) => change.name === fieldName
  );
};

const hasDiffirentErrors = (
  changes: Changes,
  resourceType: FieldUpdateRequestResourceType,
  resourceIds: Array<number> | number,
  fieldName: string
) => {
  if (!Array.isArray(resourceIds) || !changes) return false;

  const messages = uniq(
    resourceIds
      .map((rid) => findChangeEntry(changes, resourceType, rid, fieldName))
      .filter(Boolean)
      .map((change) => change?.message)
  );

  return messages.length > 1;
};

const getChangesCount = (
  changes: Changes,
  resourceType: FieldUpdateRequestResourceType,
  resourceIds: Array<number> | number,
  fieldName: string
) => {
  if (!Array.isArray(resourceIds))
    return findChangeEntry(changes, resourceType, resourceIds, fieldName)
      ? 1
      : 0;

  return resourceIds
    .map((rid) => findChangeEntry(changes, resourceType, rid, fieldName))
    .filter(Boolean).length;
};

type FeedbackValue = {
  message: string;
};

type DragObject = {
  type: 'fur-request';
  index: number;
};

const validateFeedback = (value: string) =>
  value === '' || !value ? 'required' : null;

const FeedbackRow = ({
  onMove,
  onRemove,
  name,
  index,
}: {
  onRemove: (a: number) => void;
  onMove: (a: number, b: number) => void;
  name: string;
  index: number;
}) => {
  const [, dragHandler] = useDrag<DragObject, void, null>(
    () => ({
      type: 'fur-request',
      item: {
        type: 'fur-request',
        index,
      },
    }),
    [name, index]
  );
  const [dropProps, dropHandler] = useDrop<
    DragObject,
    void,
    { hovered: boolean }
  >(
    () => ({
      accept: 'fur-request',
      drop: (hoverItem) => {
        if (hoverItem.index !== index) {
          onMove(hoverItem.index, index);
        }
      },
      collect: function collect(monitor) {
        return {
          hovered: monitor.isOver(),
        };
      },
    }),
    [name, index]
  );

  const handler = useMemo(
    () => (el: HTMLDivElement) => {
      dragHandler(el);
      dropHandler(el);
    },
    []
  );

  return (
    <InputWrapper hovered={dropProps.hovered} ref={handler}>
      <InputRow>
        <Field
          name={`${name}`}
          floatingLabelText={`Reason: #${index + 1}`}
          multiline={true}
          validate={validateFeedback}
          component={NewInputField}
        />
        <IconButton
          icon={Icons.actions.remove}
          onClick={() => {
            onRemove(index);
          }}
        />
        <DragHandlerStyled ref={dragHandler} />
      </InputRow>
    </InputWrapper>
  );
};

const IconStyled = styled(Icon)`
  min-width: 20px;
`;

const FeedbackBuilder = ({
  fields,
  reasonValues,
  userLocale,
}: {
  fields: WrappedFieldArrayProps['fields'];
  reasonValues: Array<{ userText: string; adminText: string }>;
  userLocale: string;
}) => {
  return (
    <div style={{ marginBottom: 16 }}>
      {' '}
      {userLocale &&
        reasonValues.map(({ userText, adminText }, idx) => {
          if (!userText) return null;
          return (
            <FeedbackCard
              key={idx}
              onClick={() => {
                fields.push({
                  message: userText,
                });
              }}
            >
              <IconStyled d={Icons.actions.add} />
              {adminText !== userText ? (
                <Body>
                  {adminText} / <Caption>{userText}</Caption>
                </Body>
              ) : (
                <Body>{adminText}</Body>
              )}
            </FeedbackCard>
          );
        })}
      <FeedbackCard
        onClick={() => {
          fields.push({
            message: '',
          });
        }}
      >
        <IconStyled d={Icons.actions.add} />
        <Body>Custom</Body>
      </FeedbackCard>
      {fields.map((name, index) => (
        <FeedbackRow
          key={`${name}.message`}
          name={`${name}.message`}
          index={index}
          onMove={fields.move}
          onRemove={fields.remove}
        />
      ))}
    </div>
  );
};

type FormData = {
  requests: Array<{ message: string }>;
};

type Props = {
  userLocale?: string;
  fieldName: string;
  onClose: () => void;
};

export const FeedbackRequestForm = reduxForm<FormData, Props>({
  form: 'feedbackRequestForm',
  submitAsSideEffect: true,
})(({
  userLocale,
  fieldName,
  onClose,
  submitting,
  submitSucceeded,
  valid,
  form,
  handleSubmit,
}) => {
  useEffect(() => {
    if (submitSucceeded) {
      onClose();
    }
  }, [submitSucceeded]);
  const { t, i18n } = useTranslation('translation');
  const adminT = i18n.getFixedT('en', 'admin');

  const dasherisedField = decamelize(fieldName, {
    separator: '-',
  });
  const apiField = decamelize(fieldName, { separator: '_' });

  const { entity: resourceType, id: resourceId } =
    useContext(EntityFormContext);

  if (typeof resourceType !== 'string') {
    throw new Error('Resource Type not set correctly for FUR request');
  }

  if (!resourceId) {
    throw new Error('Resource Id not set correctly for FUR request');
  }

  const furContext = useContext(FieldUpdateRequestContext);

  if (furContext.entry === null) {
    throw new Error('FUR context not initialized');
  }
  const { changes, changeFeedback } = furContext;

  const reasonValues = useMemo(() => {
    const res = [];
    const userTextOptions = {
      defaultValue: null,
      lng: userLocale,
      fallbackLng: userLocale,
    };
    const adminTextOptions = {
      defaultValue: '',
    };
    let idx = 0;
    let tranKey = `update-reason-${dasherisedField}-${idx + 1}`;
    while (
      i18n.exists(tranKey, { ns: 'translation' }) ||
      i18n.exists(tranKey, { ns: 'admin' })
    ) {
      res.push({
        adminText:
          adminT(tranKey, adminTextOptions) || t(tranKey, adminTextOptions),
        userText:
          adminT(tranKey, userTextOptions) || t(tranKey, userTextOptions), // Force to hide not supported by user language messages
      });
      idx += 1;
      tranKey = `update-reason-${dasherisedField}-${idx + 1}`;
    }
    return res;
  }, [t, userLocale]);

  const showOverwriteWarning = hasDiffirentErrors(
    changes,
    resourceType,
    resourceId,
    fieldName
  );

  const isDeletable =
    getChangesCount(changes, resourceType, resourceId, fieldName) > 0;

  const onSubmit = useCallback(
    ({ requests }) => {
      const data = {
        name: apiField,
        resourceType: `fur:${decamelize(resourceType)}`,
        message: requests !== null ? JSON.stringify({ requests }) : null,
      };
      if (Array.isArray(resourceId)) {
        for (const rid of resourceId) {
          const change = findChangeEntry(changes, resourceType, rid, fieldName);
          changeFeedback({
            form,
            id: change?.id,
            data: { ...data, resourceId: rid },
          });
        }
        return;
      }
      const change = findChangeEntry(
        changes,
        resourceType,
        resourceId,
        fieldName
      );

      changeFeedback({
        form,
        id: change?.id,
        data: { ...data, resourceId },
      });
    },
    [changeFeedback, apiField, resourceId, resourceType, changes, fieldName]
  );
  const onDelete = useCallback(() => {
    onSubmit({ requests: null });
  }, [onSubmit]);

  return (
    <div style={{ padding: '16px 32px' }}>
      {showOverwriteWarning && (
        <AlertBox title="You will overwrite other messages on that field" />
      )}
      <FieldArray<void, FeedbackValue>
        name="requests"
        reasonValues={reasonValues}
        userLocale={userLocale}
        validate={validateFeedback}
        // @ts-ignore
        component={FeedbackBuilder}
      />
      <div style={{ padding: '0 0 16px 0' }}>
        <Caption>User Locale: {userLocale}</Caption>
        <br />
        <Caption>
          Extra Translations can be added or edited as{' '}
          <code>update-reason-{dasherisedField}-#</code>
          Make sure they are numbered correctly without gaps starting from 1
        </Caption>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          type="button"
          disabled={!valid}
          primary={true}
          style={{ marginRight: '8px' }}
          showLoading={submitting}
          onClick={handleSubmit(onSubmit)}
          text="Submit"
        />
        {isDeletable && (
          <Button
            type="button"
            showLoading={submitting}
            onClick={onDelete}
            text="Remove"
          />
        )}
      </div>
    </div>
  );
});
