import React, { Component, useEffect } from 'react';
import { filter, find, append } from 'ramda';
import type { WindowScrollerProps } from 'react-virtualized';
import { DataRow, InfiniteList, DataRowColumn } from 'imdshared';
import { useInView } from 'react-intersection-observer';

export type DataRowListRendererProps<T> = {
  virtualized?: boolean;
  loading: boolean;
  disabled?: boolean;
  scrollElement?: WindowScrollerProps['scrollElement'];
  isDisabled?: (value: T) => boolean;
  isValid?: (value: T) => boolean;
  selectedEntries?: Array<number | string>;
  hasNextPage: boolean;
  onCheckEntry?: (
    e: React.MouseEvent,
    id: string | number,
    checked: boolean,
    v: Array<string | number>
  ) => void;
  onClickEntry?: (
    e: React.MouseEvent,
    id: string | number,
    checked: boolean,
    v: Array<string | number>
  ) => void;
  onChangeSelection?: (ids: Array<string | number>) => void;
  loadNextPage: () => void;
  data: Array<T>;
  selected: Array<string | number>;
  rowRenderer?: (p: DataRowProps<T>) => React.ReactNode;
};

export type DataRowProps<T> = {
  item: T;
  index: number;
  checked: boolean;
  onCheck: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: T,
    checked: boolean
  ) => void;
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: T,
    checked: boolean
  ) => void;
  style: React.CSSProperties;
  valid: boolean;
  disabled: boolean;
};

type RowRequirement = {
  defaultName?: { name: string };
  defaultNameNormalized?: { name: string } | number;
  name?: string;
  temporary?: boolean;
  id: string | number;
  type?: string;
};

function DataRowRenderer<T extends RowRequirement>({
  index,
  disabled,
  item: entry,
  rowRenderer,
  checked,
  onCheck,
  valid,
  style,
  onClick,
}: DataRowProps<T> & {
  rowRenderer?: (p: DataRowProps<T>) => any;
}) {
  if (!entry) return null;

  if (rowRenderer) {
    return rowRenderer({
      valid,
      disabled,
      item: entry,
      style,
      index,
      onCheck: (e) => {
        onCheck(e, entry, checked);
      },
      onClick: (e) => {
        onClick(e, entry, checked);
      },
      checked,
    });
  }

  return (
    <DataRow
      disabled={disabled}
      valid={valid}
      checked={checked}
      style={style}
      onCheck={(e) => {
        onCheck(e, entry, checked);
      }}
      onClick={(e) => {
        onClick(e, entry, checked);
      }}
      data={
        <DataRowColumn
          key="name"
          text={
            typeof entry?.defaultNameNormalized === 'object'
              ? entry?.defaultNameNormalized?.name
              : entry?.defaultName?.name || entry?.name
          }
        />
      }
    />
  );
}

const NO_STYLE = {};

const LoadMore = ({ onView }: { onView: () => void }) => {
  const { ref, inView } = useInView({});
  useEffect(() => {
    if (inView) onView();
  }, [inView]);
  return <div ref={ref} />;
};

class EntriesListRenderer<T extends RowRequirement> extends Component<
  DataRowListRendererProps<T>
> {
  isSelected(item?: T): boolean {
    if (!item) return false;
    const { selected } = this.props;
    if (selected) {
      return !!find((id) => id === item.id, selected);
    }
    return false;
  }

  isDisabled(entry: T): boolean {
    const { isDisabled } = this.props;
    if (isDisabled) return isDisabled(entry);

    return entry.temporary === true;
  }

  isValid(entry: T): boolean {
    const { isValid } = this.props;
    if (isValid) return isValid(entry);

    return true;
  }

  handleCheckEntry = (e: any, entry: T, checked: boolean): void => {
    const { onCheckEntry, selected, onChangeSelection } = this.props;
    const entryArray = selected || [];
    if (onCheckEntry) {
      onCheckEntry(e, entry.id, !checked, entryArray);
      return;
    }
    if (onChangeSelection) {
      onChangeSelection(
        checked
          ? filter((id) => id !== entry.id, entryArray)
          : append(entry.id, entryArray)
      );
    }
  };

  handleClickEntry = (e: any, entry: T, checked: boolean): void => {
    const { onClickEntry, selected, onChangeSelection } = this.props;
    const entryArray = selected || [];
    if (onClickEntry) {
      onClickEntry(e, entry.id, !checked, entryArray);
      return;
    }
    if (onChangeSelection) {
      onChangeSelection(checked ? [] : [entry.id]);
    }
  };

  rowRenderer = ({
    item,
    key,
    style,
    index,
  }: {
    item: T;
    key: string;
    style: React.CSSProperties;
    index: number;
  }) => {
    if (!item) return null;
    const { rowRenderer } = this.props;

    const checked = this.isSelected(item);
    const valid = this.isValid(item);
    const disabled = this.isDisabled(item);

    return (
      <DataRowRenderer
        style={style}
        key={key}
        index={index}
        valid={valid}
        rowRenderer={rowRenderer}
        item={item}
        checked={checked}
        disabled={disabled}
        onCheck={this.handleCheckEntry}
        onClick={this.handleClickEntry}
      />
    );
  };

  render() {
    const {
      data,
      hasNextPage,
      rowRenderer,
      loadNextPage,
      scrollElement,
      selected,
      disabled,
      virtualized = true,
      ...props
    } = this.props;
    if (!virtualized) {
      return (
        <>
          {data?.map((item, index) =>
            this.rowRenderer({
              item,
              index,
              key: `key:${index}`,
              style: NO_STYLE,
            })
          )}
          {hasNextPage && loadNextPage && <LoadMore onView={loadNextPage} />}
        </>
      );
    }
    return (
      <InfiniteList<T>
        data={data}
        selected={selected}
        disabled={disabled}
        scrollElement={scrollElement}
        hasNextPage={hasNextPage}
        loadNextPage={loadNextPage}
        {...props}
        rowRenderer={this.rowRenderer}
      />
    );
  }
}

export default EntriesListRenderer;
