import { createContext } from 'react';

export { FieldUpdateRequestContext } from './FieldUpdateRequestContext';
export { ImpersonateContext } from './ImpersonateContext';

export type FieldUpdateRequestResourceType =
  | 'releases'
  | 'publishers'
  | 'tracks'
  | 'artists'
  | 'releaseNames'
  | 'performers'
  | 'contributorNames'
  | 'trackNames'
  | 'nonPerformingContributors'
  | 'artistNames';

export const EntityFormContext = createContext<{
  entity?: FieldUpdateRequestResourceType;
  id: number | number[];
}>({ id: -1 });
EntityFormContext.displayName = 'EntityFormContext';

export const AppContext = createContext<'front' | 'admin'>('front');
AppContext.displayName = 'AppContext';
