import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Body, BodySmall } from 'imdshared';
import styled from '@emotion/styled';

type Props = {
  hideLink?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  value: string;
  onClick?: (e: React.MouseEvent, v: string) => void;
};

const HighlightRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.03);
  overflow: hidden;

  :hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  > ${BodySmall} {
    color: ${({ theme }) => theme.foreground.secondary};
    word-break: break-all;
  }
`;

const LinkToClipboard = styled(Body)`
  font-size: 14px;
  font-weight: 500;
`;

export const CopyToClipboardField: React.FC<Props> = ({
  hideLink,
  disabled = false,
  className,
  style,
  value,
  onClick,
}) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();

  const handleClick = useCallback(
    (e) => {
      if (disabled) return;
      copy(value);
      setClicked(true);
      if (onClick) {
        onClick(e, value);
      }
    },
    [value, onClick]
  );

  return (
    <HighlightRow
      onClick={handleClick}
      className={className + (disabled ? ' disabled' : '')}
      style={style}
    >
      {!disabled && (
        <LinkToClipboard data-test-id="copyToClipboard">
          {clicked ? t('copied') : t('copy-to-clipboard')}
        </LinkToClipboard>
      )}

      {!hideLink && <BodySmall data-test-id="tokenizedLink">{value}</BodySmall>}
    </HighlightRow>
  );
};
