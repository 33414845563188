import React, { useMemo } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { IntroPageContext, useIntroPage } from 'components';
import {
  FieldUpdateRequestsList,
  FieldUpdateRequest,
  FieldUpdateRequestIntroPage,
} from './screens';

const FieldUpdateRequestsPage = ({ match }: RouteComponentProps) => {
  const { open, isOpen, close } = useIntroPage({
    pageId: 'fieldUpdateRequests',
  });
  const introValue = useMemo(() => ({ open }), [open]);
  if (isOpen) {
    return <FieldUpdateRequestIntroPage onClose={close} />;
  }
  return (
    <IntroPageContext.Provider value={introValue}>
      <Switch>
        <Route
          path={match.path}
          exact={true}
          component={FieldUpdateRequestsList}
        />
        <Route path={`${match.path}/:id`} component={FieldUpdateRequest} />
      </Switch>
    </IntroPageContext.Provider>
  );
};

export default FieldUpdateRequestsPage;
