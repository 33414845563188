//
import { buffers } from 'redux-saga';
import {
  delay,
  actionChannel,
  take,
  put,
  call,
  select,
} from 'redux-saga/effects';
import {
  getFormSubmitErrors,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
  touch,
} from 'redux-form';
import { setAuth, getAuth } from '../utils/auth';
import {
  invalidate,
  REFRESH_AUTH,
  REFRESH_AUTH_RESULT,
} from '../actionTypes/auth';
import authApiCalls from '../api/authApiCalls';

/**
 *  requestSagaCreator is used for creating a saga for handling basic request logic.
 *  It returns generator function that accepts action payload to be sent to API call
 *  also it accepts meta data with two callbacks and user impersonaton object for admin.
 *
 * @returns {Generator}
 */

function* retrySaga(apiFn, payload, impersonate, retries) {
  for (let i = 0; i < retries + 1; i += 1) {
    const {
      response,
      error,
      originalResponse,
      shouldRetry,
      statusCode,
      responseDetails,
      rawResponse,
    } = yield call(apiFn, payload, impersonate);

    if (statusCode !== 500 && statusCode !== 502) {
      return {
        rawResponse,
        originalResponse,
        response,
        error,
        statusCode,
        responseDetails,
      };
    }

    if (
      i < retries &&
      (statusCode === 502 || (statusCode === 500 && shouldRetry))
    ) {
      yield delay(4000);
    } else {
      return { error, statusCode, responseDetails };
    }
  }

  return { error: { message: 'Invalid Retry' }, statusCode: 0 };
}

export function* refreshAuthRequest(/* loginCreds */) {
  const requestChan = yield actionChannel(REFRESH_AUTH, buffers.none());
  while (true) {
    const { refreshToken } = yield take(requestChan);
    const requestData = yield call(authApiCalls.login, {
      client_id: process.env.APP_NAME === 'imdadmin' ? 2 : 1,
      client_secret: 'not-really-a-secret',
      grantType: 'refresh_token',
      refreshToken,
    });
    if (requestData.response) {
      setAuth(requestData.response);
      yield put({
        type: REFRESH_AUTH_RESULT,
        payload: { hasRefreshToken: true },
      });
    } else {
      yield put({
        type: REFRESH_AUTH_RESULT,
        payload: { hasRefreshToken: false },
      });
    }
  }
}

export function* makeApiRequest(
  apiFn,
  { payload, meta: { impersonate: metaImpersonate, reject } = {} },
  retries = 0
) {
  const auth = getAuth();

  const uiImpersonate = yield select((state) => state.ui?.impersonate);

  const impersonate = uiImpersonate || metaImpersonate;

  if (
    auth &&
    auth.refreshToken &&
    auth.expireTimestamp &&
    +auth.expireTimestamp < new Date().getTime()
  ) {
    yield put({ type: REFRESH_AUTH, refreshToken: auth.refreshToken });
    const {
      payload: { hasRefreshToken },
    } = yield take(REFRESH_AUTH_RESULT);

    if (!hasRefreshToken) {
      yield put(invalidate());
      if (localStorage) {
        setAuth(null);
      }

      if (reject) reject({ _error: 'token-expired' });
      return {};
    }
  }

  if (payload && payload.formId) {
    yield put(startSubmit(payload.formId));
  }
  const result = yield call(retrySaga, apiFn, payload, impersonate, retries);
  return result;
}

const requestSagaCreator = (actions, apiFn, retries = 0) => {
  return function* fetch(
    payload = {},
    { resolve, reject, analytics, ...meta } = {}
  ) {
    yield put(actions.request({ payload, meta }));
    const { response, error, statusCode, responseDetails, rawResponse } =
      yield call(makeApiRequest, apiFn, { payload, meta }, retries);

    if (response) {
      yield put(
        actions.success({
          payload,
          response,
          responseDetails,
          rawResponse,
          meta,
        })
      );
      // if (payload && payload.formId) {
      //   yield put(clearSubmitErrors(payload.formId));
      // }
      if (resolve) resolve(response);

      if (payload && payload.formId) {
        const errors = yield select(getFormSubmitErrors(payload.formId));

        yield put(stopSubmit(payload.formId, errors));
        yield put(setSubmitSucceeded(payload.formId));
      }
      return response;
    }

    yield put(actions.failure({ payload, error, statusCode, meta }));
    if (statusCode === 401) {
      const isAuthenticated = yield select(
        (state) => state.auth && state.auth.userId
      );
      if (isAuthenticated) {
        yield put(invalidate());
        if (localStorage) {
          setAuth(null);
        }
      }
    }
    if (reject) reject(error);
    if (payload && payload.formId && error) {
      const { validationError, inputNames = [] } = error;
      if (Array.isArray(inputNames)) {
        yield put(touch(payload.formId, ...inputNames));
      }

      if (!reject) {
        yield put(stopSubmit(payload.formId, validationError || {}));
        if (Array.isArray(inputNames)) {
          yield put(setSubmitFailed(payload.formId, inputNames));
        }
      } else {
        reject(validationError);
      }
    }
    return {};
  };
};

export default requestSagaCreator;
