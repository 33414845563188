//
import { lensPath, set, view, unnest, find } from 'ramda';
import {
  delay,
  select,
  actionChannel,
  take,
  put,
  call,
} from 'redux-saga/effects';
import { selectEntityData } from '../../selectors';
import * as api from '../../api';

import concatBuffer from '../../base/concatBuffer';
import { UPDATE_RELEASE_TRACKS } from './types';

export default function* handleUpdateReleaseTracks() {
  const buffer = concatBuffer();
  const channel = yield actionChannel(UPDATE_RELEASE_TRACKS, buffer);

  while (true) {
    const result = yield take(channel);
    const actions = Array.isArray(result)
      ? result.actions
        ? result.actions
        : result
      : [result];
    const [firstAction] = actions;
    const {
      payload: { nestingPath },
      meta,
    } = firstAction;

    const { entity, entityId, wrapperEntity, path } = nestingPath;

    const entry = yield select((state) =>
      selectEntityData(entity)(state, entityId)
    );

    const releaseTracks = yield select(
      (state) => state.entities[wrapperEntity]?.entities || {}
    );

    const tempTrackIds = actions.map(({ payload: { trackId, tempId } }) => ({
      trackId,
      tempId,
    }));

    try {
      if (entity !== 'releases') {
        const trackWrapperIds = view(lensPath(path), entry);
        if (trackWrapperIds) {
          const trackWrappers = trackWrapperIds.map((id) => releaseTracks[id]);

          const hasTempTrack = find(({ temp }) => !!temp, trackWrapperIds);

          if (hasTempTrack) {
            yield delay(500);
            yield put({
              type: UPDATE_RELEASE_TRACKS,
              id: firstAction.id,
              actions,
            });
            // eslint-disable-next-line no-continue
            continue;
          }

          const data = set(
            lensPath(path),
            trackWrappers.map((wrapper) => ({
              trackId: wrapper.trackId,
              ...(nestingPath.wrapperData || {}),
            })),
            {}
          );
          yield call(api[entity].update, {
            query: { with: 'tracks' },
            id: entityId,
            tempTrackIds,
            nestingPath,
            data,
          });
        }
      }
      // Handle volume structure to avoid sending TEMP ids to API
      // Probably need to handle and unnest if path is Array.length > 1
      else if (entry.volumes) {
        const volumeWithWrappers = entry.volumes.map((volume) =>
          volume.map((releaseTrackId) => releaseTracks[releaseTrackId])
        );

        const hasTempTrack = find(
          ({ temp }) => !!temp,
          unnest(volumeWithWrappers)
        );

        if (hasTempTrack) {
          yield delay(500);
          yield put({
            type: UPDATE_RELEASE_TRACKS,
            id: firstAction.id,
            actions,
          });
          // TODO: refactor this to top to remove duplication
          // eslint-disable-next-line no-continue
          continue;
        }

        yield call(api.releases.update, {
          query: { with: 'tracks' },
          id: entityId,
          nestingPath,
          tempTrackIds,
          data: {
            volumes: volumeWithWrappers.map((volume) =>
              volume.map((releaseTrack) => ({ trackId: releaseTrack.trackId }))
            ),
          },
        });
      }
      if (meta && meta.resolve) {
        meta.resolve();
      }
    } catch (error) {
      console.error(error);
      if (meta && meta.reject) {
        meta.reject(error);
      }
    }
  }
}
