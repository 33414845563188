import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContributorForms } from 'imdshared';
import type { Props as EditorProps } from '../EntitySideEditor';
import { EntitySideEditor } from '../EntitySideEditor';

type Props = {
  isAdaptive?: EditorProps['isAdaptive'];
  warn?: EditorProps['warn'];
};

const components = { SingleForm: ContributorForms.Single };

const ContributorSideEditor: React.FC<Props> = ({ isAdaptive, warn }) => {
  const { t } = useTranslation();
  const label = t('selected-contributors');

  return (
    <EntitySideEditor
      warn={warn}
      entity="contributors"
      label={label}
      isAdaptive={isAdaptive}
      components={components}
    />
  );
};

export default ContributorSideEditor;
