//
import * as R from 'ramda';
import { genericApiActions } from './constants';
import action from './action';
import createRequestActions from './createRequestActions';

const unImplementedStub = {
  request: () => ({ type: 'UNIMPLEMENTED' }),
  success: () => ({ type: 'UNIMPLEMENTED' }),
  failure: () => ({ type: 'UNIMPLEMENTED' }),
};

/**
 * Generates CRUD Redux actions for entity
 *
 * @returns {FactoryEntityActions}
 */
export default function factoryEntityActions(genericTypes, entityKey) {
  return {
    reorder: ({ newKeys, ...payload }) => ({
      requestType: 'reorder',
      entityKey,
      ...action(genericTypes.reorder, { newKeys, ...payload }),
    }),
    updateLocalEntry: (payload) => ({
      requestType: 'updateLocal',
      entityKey,
      ...action(genericTypes.updateLocalEntry, payload),
    }),
    ...genericApiActions.reduce(
      (accActions, type) => {
        return R.set(
          R.lensProp(type),
          createRequestActions(genericTypes[type], null, type, entityKey),
          accActions
        );
      },
      {
        fetch: unImplementedStub,
        update: unImplementedStub,
        create: unImplementedStub,
        delete: unImplementedStub,
      }
    ),
  };
}
