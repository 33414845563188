import 'react-tooltip/dist/react-tooltip.css';
import { css } from '@emotion/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const tooltipStyle = css`
  max-width: 200px;

  z-index: 100000;
  background: var(--inverted-surface-container, #1c1c1b);
  padding: 8px 10px;

  border-radius: 4px;
  opacity: 1 !important;

  color: var(--inverted-on-surface, #f6f3f1) !important;
  font-feature-settings: 'ss01' on;
  /* Text/S/Regular */
  font-family: Inter;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  letter-spacing: 0.26px;
`;

export function Tooltip(props: React.ComponentProps<typeof ReactTooltip>) {
  return (
    <ReactTooltip noArrow={false} css={tooltipStyle} place="top" {...props} />
  );
}
