import React from 'react';
import { ProductLoader } from 'components';
import ProductScreen from '../ProductScreen';

interface Props {
  title: string;
  stepper: React.ReactNode;
  loadingText?: string;
  failedText?: string;
}

export default function ProductLoadingScreen({
  title,
  stepper,
  loadingText,
  failedText,
}: Props): JSX.Element {
  return (
    <ProductScreen title={title} stepper={stepper}>
      <ProductLoader loadingText={loadingText} failedText={failedText} />
    </ProductScreen>
  );
}
