import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { ConfirmationWindow, RadioButtonsField } from 'imdshared';
import {
  Window,
  IconButton,
  Button,
  LoadingIndicator,
  AppTitle,
  Clickable,
  Icons,
  Card,
} from 'imdui';
import {
  CustomerPaymentMethod,
  ReturnNavigation,
  useSubscriptionManagement,
} from 'components';
import { Helmet } from 'react-helmet-async';
import { StoreCardGate } from 'screens/Order/screens/PaymentGate/components/StorePaymentFlow';
import styled from '@emotion/styled';
import type { EntityModels } from 'imddata';
import {
  useEntry,
  useDeleteEntity,
  useUpdateEntity,
  useEntries,
  useCurrentCustomerSubscription,
} from 'imddata';

const MethodGrid = styled.div`
  display: grid;
  gap: 24px;
  ${AppTitle} {
    min-height: 64px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 1152px) {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`;

const MethodHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentMethod = ({
  className,
  style,
  paymentMethod: pm,
  onClick,
}: {
  paymentMethod: EntityModels.CustomerPaymentMethod;
  className?: string;
  style?: React.CSSProperties;
  onClick: (id: string) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(pm.id);
  }, [pm.id, onClick]);
  const { t } = useTranslation();
  return (
    <Clickable className={className} style={style} onClick={handleClick}>
      {/*
      //@ts-ignore */}
      <CustomerPaymentMethod
        scheme={pm.paymentMethodId}
        data={pm.data}
        description={pm.description}
        caption={pm.isRecurringDefault ? t('recurring-default') : undefined}
      />
    </Clickable>
  );
};

const PaymentMethodStyled = styled(PaymentMethod)`
  min-height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  // box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  &:last-of-type {
    box-shadow: none;
  }
`;

const FormContainer = styled.div`
  padding: 24px 32px;
`;

const ActionBar = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
`;

const StoreCardGateContainer = styled.div`
  max-width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 36px 36px 36px;
`;

export default function PaymentMethods() {
  const { t } = useTranslation();

  const { renewalEnabled } = useSubscriptionManagement();
  const activeSub = useCurrentCustomerSubscription();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    null | string
  >(null);
  const { entries, request, refresh } =
    useEntries<EntityModels.CustomerPaymentMethod>({
      entity: 'customerPaymentMethods',
    });
  const { entry: cardData } = useEntry<any>({
    entity: 'customerPaymentMethods',
    id: 'create/card',
    storeId: 'card',
  });
  useEffect(() => {
    refresh();
  }, []);

  const selectedPaymentMethod: EntityModels.CustomerPaymentMethod | undefined =
    entries?.find((d) => d.id === selectedPaymentMethodId);

  const { deleteEntry } = useDeleteEntity({ entity: 'customerPaymentMethods' });

  const { updateEntry } = useUpdateEntity({
    entity: 'customerPaymentMethodSetDefault',
  });

  const [addPaymentMethodId, setAddPaymentMethod] = useState<
    'paypal' | 'card' | null
  >(null);

  const paymentMethodData = useMemo(() => ({ data: cardData }), [cardData]);

  const handleCancel = useCallback(() => {
    setAddPaymentMethod(null);
  }, []);

  const limiteReached = entries?.length > 10;

  const recurringOptions = useMemo(
    () => [
      {
        text: t('set-default-recurring-payment-method'),
        value: true,
      },
    ],
    []
  );

  const [blockedLastMethodDeletion, setBlockedLastMethodDeletion] =
    useState(false);

  if (!request.loaded) return <LoadingIndicator size="large" />;

  const title =
    selectedPaymentMethod?.paymentMethodId === 'card'
      ? `**** ${selectedPaymentMethod?.data.lastFour}`
      : selectedPaymentMethod?.description;

  return (
    <>
      <Helmet>
        <title>{t('page-title-payment-methods')}</title>
      </Helmet>

      <ConfirmationWindow
        title={t('subscription-still-active')}
        cancelLabel={t('close')}
        message={t('cancel-subscription-to-delete-last-payment-method')}
        onRequestClose={() => {
          setSelectedPaymentMethodId(null);
          setBlockedLastMethodDeletion(false);
        }}
        isOpen={blockedLastMethodDeletion}
      />

      <Window
        title={t('add-payment-method')}
        close={handleCancel}
        isOpen={!!addPaymentMethodId}
      >
        <StoreCardGateContainer>
          {addPaymentMethodId && (
            <StoreCardGate
              paymentMethodData={paymentMethodData}
              paymentMethodId={addPaymentMethodId}
              onCancel={handleCancel}
            />
          )}
        </StoreCardGateContainer>
      </Window>

      <Window
        title={title}
        isOpen={!!selectedPaymentMethodId}
        close={() => setSelectedPaymentMethodId(null)}
      >
        <FormContainer>
          <Form
            initialValues={{
              isRecurringDefault: selectedPaymentMethod?.isRecurringDefault,
            }}
            onSubmit={({ isRecurringDefault }) => {
              if (isRecurringDefault) {
                updateEntry({ data: {}, id: selectedPaymentMethodId });
              }
            }}
          >
            {({ handleSubmit }) => (
              <>
                <Field<boolean>
                  name="isRecurringDefault"
                  options={recurringOptions}
                  // TODO: figure out how to integrate react-final-form
                  // @ts-ignore
                  component={RadioButtonsField}
                />
                <ActionBar>
                  <Button
                    text={t('delete')}
                    icon={Icons.actions.trash}
                    onClick={() => {
                      if (
                        renewalEnabled &&
                        activeSub &&
                        entries?.length === 1
                      ) {
                        setBlockedLastMethodDeletion(true);
                        return;
                      }
                      if (selectedPaymentMethodId) {
                        deleteEntry({ id: selectedPaymentMethodId });
                      }
                      setSelectedPaymentMethodId(null);
                    }}
                  />
                  <div>
                    <Button
                      onClick={() => {
                        setSelectedPaymentMethodId(null);
                      }}
                      text={t('cancel')}
                    />
                    <Button
                      primary={true}
                      style={{ marginLeft: '8px' }}
                      text={t('save')}
                      onClick={() => {
                        handleSubmit();
                        setSelectedPaymentMethodId(null);
                      }}
                    />
                  </div>
                </ActionBar>
              </>
            )}
          </Form>
        </FormContainer>
      </Window>

      <ReturnNavigation text={t('payment-methods')} to="/account" />
      <Card style={{ padding: '8px 24px 24px' }}>
        <MethodGrid>
          <div>
            <MethodHeader>
              <AppTitle>{t('credit-card')}</AppTitle>
              <IconButton
                icon={Icons.actions.add}
                disabled={limiteReached}
                onClick={() => {
                  setAddPaymentMethod('card');
                }}
              />
            </MethodHeader>
            {entries
              .filter(
                (d) => d.paymentMethodId === 'card' && d.status !== 'deleted'
              )
              .map((d) => (
                <PaymentMethodStyled
                  onClick={setSelectedPaymentMethodId}
                  key={d.id}
                  paymentMethod={d}
                />
              ))}
          </div>
          <div>
            <MethodHeader>
              <AppTitle>{t('paypal')}</AppTitle>
              <IconButton
                icon={Icons.actions.add}
                disabled={limiteReached}
                onClick={() => {
                  setAddPaymentMethod('paypal');
                }}
              />
            </MethodHeader>
            {entries
              .filter(
                (d) => d.paymentMethodId === 'paypal' && d.status !== 'deleted'
              )
              .map((d) => (
                <PaymentMethodStyled
                  onClick={setSelectedPaymentMethodId}
                  key={d.id}
                  paymentMethod={d}
                />
              ))}
          </div>
        </MethodGrid>
      </Card>
    </>
  );
}
