import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import type { ReactElement } from 'react';
import React, { Component } from 'react';
import { Portal } from 'react-portal';
// import AutoSizer from 'react-virtualized-auto-sizer';

import { DOMNodeContext } from '../../../context';

const dropdownContainerAppear = keyframes`
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
`;

const dropownContentAppear = keyframes` 
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
`;

const DropdownContainer = styled.div`
  z-index: 10000000;
  pointer-events: all;
  position: fixed;
  display: flex;
  flex-direction: column;
  max-height: 560px;
  animation: ${dropdownContainerAppear} 0.35s cubic-bezier(0.19, 1, 0.22, 1)
    forwards;
  will-change: transform;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid var(--outline-var, #c9c6c4);
  background: var(--surface-container-high, #fff);
  /* Shadow/3 */
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.06);

  & > * {
    opacity: 0.01;
    animation: ${dropownContentAppear} 0.25s forwards;
    animation-delay: 0.1s;
    transform-origin: inherit;
    will-change: opacity;
  }
`;

type RenderChildrenWithWidth = { width?: React.CSSProperties['width'] };

type Props = {
  style?: React.CSSProperties;
  anchorEl: React.RefObject<React.ElementRef<'div'>>;
  fullWidth?: boolean;
  maxWidthFix?: boolean;
  children: (props: RenderChildrenWithWidth) => ReactElement;
  onClickOutside: (e?: React.MouseEvent) => void;
  anchorOffset?: number;
  scrollElement: React.ElementRef<'div'> | null;
  contentNode?: React.RefObject<React.ElementRef<'div'>>;
  className?: string;
  renderInAnchor?: boolean;
  maxWidth?: React.CSSProperties['maxWidth'];

  coordinates?: { x?: number; y?: number };
  dropdownNode?: React.RefObject<React.ElementRef<'div'>>;
};

type State = {
  open?: boolean;
  top?: number | undefined;
  bottom?: number | undefined;
  left?: number | undefined;
  right?: number | undefined;
  width?: number | undefined | 'auto';
  maxWidth?: React.CSSProperties['maxWidth'];
  transformOrigin?: string;
};

const DropdownContext = React.createContext<React.ElementRef<'div'> | null>(
  null
);

DropdownContext.displayName = 'DropdownScrollContext';

export const DropdownScrollProvider = DropdownContext.Provider;

class Dropdown extends Component<Props, State> {
  static defaultProps = {
    maxWidth: 280,
    fullWidth: false,
  };

  scrollElement: React.ElementRef<'div'> | Window = window;

  requestedFrame: null | number = null;

  portalNode: React.RefObject<React.ComponentRef<typeof Portal>> =
    React.createRef();

  dropdown: React.RefObject<React.ElementRef<'div'>> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = this.calcPosition();
  }

  componentDidMount() {
    this.scrollElement = this.props.scrollElement
      ? this.props.scrollElement
      : this.scrollElement;
    this.scrollElement.addEventListener('resize', this.recalc);
    this.scrollElement.addEventListener('scroll', this.recalc);

    this.setState({ open: true }, () => {
      if (this.props.contentNode?.current) {
        this.props.contentNode.current.addEventListener(
          'click',
          this.handleOutsideMouseClick.bind(this),
          true
        );
      }
    });

    this.recalc();
  }

  componentWillUnmount() {
    if (this.requestedFrame) {
      cancelAnimationFrame(this.requestedFrame);
    }

    if (this.scrollElement) {
      this.scrollElement.removeEventListener('resize', this.recalc);
      this.scrollElement.removeEventListener('scroll', this.recalc);
    }

    if (this.props.contentNode?.current) {
      this.props.contentNode.current.removeEventListener(
        'click',
        this.handleOutsideMouseClick
      );
    }
  }

  handleOutsideMouseClick(e: MouseEvent & { target: any }) {
    const { anchorEl, onClickOutside } = this.props;
    const { open } = this.state;

    if (!open) return;

    const root =
      this.portalNode.current &&
      (this.portalNode.current.props.node ||
        // @ts-ignore TODO: figure out what is defualt node
        this.portalNode.current.defaultNode);
    if (!root) return;

    if (
      root?.contains(e.target) ||
      anchorEl.current?.contains(e.target) ||
      this.dropdown.current?.contains(e.target) ||
      (e.button && e.button !== 0)
    ) {
      return;
    }
    // don't allow parent component onClick event happen after this method
    e.stopPropagation();

    if (onClickOutside) {
      onClickOutside();
    }
  }

  recalc = () => {
    if (this.requestedFrame) {
      cancelAnimationFrame(this.requestedFrame);
    }
    this.requestedFrame = requestAnimationFrame(() => {
      this.setState({ ...this.calcPosition() });
    });
  };

  calcPosition = (): State => {
    const { maxWidth, anchorEl, coordinates, renderInAnchor, fullWidth } =
      this.props;
    if (renderInAnchor) return {};

    if (anchorEl.current) {
      const { bottom, top, left, right, width, height } =
        anchorEl.current.getBoundingClientRect();

      const dimensions = window || { innerWidth: 0, innerHeight: 0 };

      const { innerWidth, innerHeight } = dimensions;

      const RELATIVE_BOTTOM = innerHeight - bottom;

      const RELATIVE_RIGHT = innerWidth - right;

      // will create a dropdown growing to the top
      const TO_TOP = top > RELATIVE_BOTTOM;

      // console.log(contentCoords.bottom, innerHeight);
      // will create a dropdown growing to the left
      const TO_LEFT = left > RELATIVE_RIGHT;

      const leftPosition = coordinates?.x || (TO_LEFT ? undefined : left);
      const topPosition = coordinates?.y || (TO_TOP ? undefined : bottom - 0);

      return {
        top: topPosition,
        bottom: !topPosition ? RELATIVE_BOTTOM + height - 0 : undefined,
        left: leftPosition,
        right: !leftPosition ? RELATIVE_RIGHT : undefined,
        width: fullWidth ? width : undefined,
        maxWidth:
          typeof maxWidth === 'number'
            ? width < (maxWidth || 0)
              ? maxWidth
              : width
            : maxWidth,
        transformOrigin: `${TO_TOP ? 'bottom' : 'top'}`,
      };
    }
    return { top: 0, left: 0, width: 'auto' };
  };

  // eslint-disable-next-line
  focus() {
    this.dropdown.current?.focus();
  }

  render() {
    const {
      className,
      style = {},
      renderInAnchor,
      dropdownNode,
      children,
      anchorEl,
    } = this.props;

    const width = anchorEl.current?.getBoundingClientRect().width || 240;

    return (
      <Portal ref={this.portalNode} node={dropdownNode?.current}>
        <DropdownContainer
          ref={this.dropdown}
          className={className}
          onScrollCapture={(e) => {
            e.preventDefault();
          }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            ...this.state,
            ...style,

            maxWidth: this.props.maxWidthFix
              ? undefined
              : this.state.maxWidth &&
                  typeof this.state.maxWidth === 'number' &&
                  this.state.maxWidth < width
                ? width
                : this.state.maxWidth,
            position: renderInAnchor ? 'absolute' : 'fixed',
          }}
        >
          {children({
            width: this.state.maxWidth,
          })}
        </DropdownContainer>
      </Portal>
    );
  }
}

const DropdownWithContext = (
  props: Omit<Props, 'dropdownNode' | 'contentNode' | 'scrollElement'>
) => (
  <DOMNodeContext.Consumer>
    {({ contentNode, dropdownNode }) => (
      <DropdownContext.Consumer>
        {(scrollElement) => (
          <Dropdown
            contentNode={contentNode}
            dropdownNode={
              props.renderInAnchor && props.anchorEl
                ? props.anchorEl
                : dropdownNode
            }
            {...props}
            scrollElement={scrollElement}
          />
        )}
      </DropdownContext.Consumer>
    )}
  </DOMNodeContext.Consumer>
);

export default DropdownWithContext;
