//
import apiCalls from './authApiCalls';
import { requestSagaCreator } from '../base';
import { entityActions as authEntityActions } from '../actionTypes/auth';

export { default as apiCalls } from './authApiCalls';

export default {
  login: requestSagaCreator(authEntityActions.login, apiCalls.login),
  signOut: requestSagaCreator(authEntityActions.signOut, apiCalls.signOut),
};
