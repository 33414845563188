import { constants } from '../../actionTypes/ui/player';

export type UiPlayerState = {
  duration: number;
  position: number;
  trackId: number | null;
  audioFile: any;
  seek?: number;
  progress: string;
  totalTime: number;
  playState: 'play' | 'pause' | 'stop';
};

// TODO
type Action = any;
//
// Reducer
export default (
  state: UiPlayerState = {
    playState: 'stop',
    duration: 0,
    position: 0,
    trackId: null,
    audioFile: null,
    progress: '00:00',
    totalTime: 0,
  },
  action: Action
) => {
  switch (action.type) {
    case constants.PLAY:
      return {
        ...state,
        ...action.data,
        duration:
          state.trackId !== action.data.trackId
            ? action.data.duration
            : state.duration,
        position:
          action.data.position ||
          (state.trackId !== action.data.trackId ? 0 : state.progress),
        playState: 'play',
      };

    case constants.PAUSE:
      return { ...state, playState: 'pause' };

    case constants.STOP:
      return {
        ...state,
        position: 0,
        trackId: null,
        audioFile: null,
        playState: 'stop',
      };

    case constants.SET_BUFFERING:
      return { ...state, buffering: action.buffering };

    case constants.SEEK_TO:
      return {
        ...state,
        seek: action.seekTo,
        progress: action.seekTo,
      };

    case constants.SYNC:
      return {
        ...state,
        progress: action.progress,
        totalTime: action.totalTime,
      };

    case constants.CLEAR:
      return {};

    default:
      return state;
  }
};
