import { ContinueWindow } from 'components';
import {
  BuyOrSubOffer,
  EnabledField,
  HelpWindowContext,
  useProductPriceFormatted,
} from 'imdshared';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmailSupportToggle = ({
  onChange,
  value,
}: {
  onChange: (v: boolean) => void;
  value: boolean;
}) => {
  const [decideBuy, setDecideBuy] = useState(false);
  const showHelpWindow = useContext(HelpWindowContext);
  const [openWindow, setOpenWindow] = useState(false);
  const price = useProductPriceFormatted('customer-support');
  const { t } = useTranslation();
  return (
    <>
      <ContinueWindow
        isOpen={openWindow}
        disabled={!decideBuy}
        onCancel={() => {
          setOpenWindow(false);
        }}
        onContinue={() => {
          setOpenWindow(false);
          onChange(true);
        }}
        title={t('advanced-email-support')}
      >
        <BuyOrSubOffer
          subscribeContext={{ analytics: { detail: 'email-support' } }}
          price={price}
          title={t('enable-email-support')}
          description={t('unlock-email-support-desc')}
          onDecideSubscribe={() => {
            setOpenWindow(false);
          }}
          onDecideBuy={setDecideBuy}
          decideBuy={decideBuy}
        />
      </ContinueWindow>

      <EnabledField
        label={t('enable-email-support')}
        description={t('enable-email-support-desc')}
        onClickHelp={(event: any) => {
          event.stopPropagation();
          event.preventDefault();
          showHelpWindow(
            t('enable-email-support'),
            t('enable-email-support-helpertext')
          );
        }}
        // @ts-ignore
        meta={{}}
        // @ts-ignore
        input={{
          value,
          onChange: (v) => {
            if (v) {
              setOpenWindow(true);
            } else {
              onChange(false);
            }
          },
        }}
      />
    </>
  );
};
