import { nil } from './nil';

const { toString } = Object.prototype;
const datePrefix = ['_at', '_by'];

const _isFunction = function (obj) {
  return typeof obj === 'function';
};
const _isObject = function (obj) {
  return obj === Object(obj);
};
const _isArray = function (obj) {
  return toString.call(obj) === '[object Array]';
};
const _isDate = function (obj) {
  return toString.call(obj) === '[object Date]';
};
const _isRegExp = function (obj) {
  return toString.call(obj) === '[object RegExp]';
};
const _isBoolean = function (obj) {
  return toString.call(obj) === '[object Boolean]';
};

const isNil = function (obj) {
  return obj === nil;
};

export default function formatToAPI(obj) {
  if (_isBoolean(obj)) {
    return obj ? 1 : 0;
  }

  if (obj === null) {
    return '';
  }

  if (isNil(obj)) {
    return null;
  }

  if (!_isObject(obj) || _isDate(obj) || _isRegExp(obj) || _isFunction(obj)) {
    return obj;
  }

  let output;
  let i = 0;
  let l = 0;

  if (_isArray(obj)) {
    output = [];
    for (l = obj.length; i < l; i += 1) {
      output.push(formatToAPI(obj[i]));
    }
  } else {
    output = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (key && datePrefix.indexOf(key.substring(key.length - 3)) >= 0) {
          output[key] = isNil(obj[key]) ? null : obj[key];
        } else {
          output[key] = formatToAPI(obj[key]);
        }
      }
    }
  }
  return output;
}
